import React from "react";
import styled from "styled-components/macro";
import { Difficulty as TDifficulty } from "../../../queries/sources/__generated__/sourceQuery.graphql";
import { Trans } from "react-i18next";
import { difficultyToColor } from "../../../styled/Functions";

const Wrap = styled.div<{ difficulty: TDifficulty }>`
  background: ${({ theme, difficulty }) => difficultyToColor(difficulty, true)};
  color: white;
  font-size: ${({ theme }) => theme.duo.fontSize.smallest};
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 8px;
  font-weight: normal;
  line-height: 1.7em;
  border-bottom-left-radius: 4px;
`;

type Props = {
  difficulty: TDifficulty;
};

const Difficulty: React.FC<Props> = (p) => {
  return (
    <Wrap className="nofilter" {...p}>
      <Trans>{p.difficulty}</Trans>
    </Wrap>
  );
};

export default Difficulty;
