import { IconButton } from "@mui/material";
import React from "react";
import styled, { css } from "styled-components/macro";
import DialogWindow from "../../../components/other/DialogWindow";
import FilterIcon from "../../../icons/FilterIcon";
import CardsFilterIn from "./CardsFilterIn";
import { useViewerDecks } from "../../../queries/viewerQuery";
import StarIcon from "components/other/StarIcon";
import HintTooltip from "components/other/HintTooltip";
import { useGetState } from "components/ReduxProvider";
import { LoopFilter } from "queries/decks/__generated__/loopQuery.graphql";
import { Mutable } from "utility-types";
import { useSearchParams } from "react-router-dom";
import { LEARN } from "../MainRouter";
import { useEffectOnce } from "react-use";

export type TLoopFilterIds = Mutable<LoopFilter["filteredSIds"]>;
export type TLoopFilter = LoopFilter & { filteredSIds: TLoopFilterIds };

const IconWrap = styled.div`
  position: relative;
`;
const SFilterIcon = styled(FilterIcon)`
  fill: rgba(0, 0, 0, 0.54);
  width: 14px;
  height: 14px;
`;
const SStarIcon = styled(StarIcon)`
  top: 2px;
  right: 2px;
`;
const SIconButton = styled(IconButton)<{ $highlighted?: boolean }>`
  &&& {
    padding: 10px;
    ${({ $highlighted, theme }) =>
      $highlighted &&
      css`
        background: ${theme.duo.color.primary};
        box-shadow: 1px 1px 6px #0005;
        svg {
          fill: white;
        }
      `}
  }
`;

type Props = {};
const CardsFilter: React.FC<Props> = React.memo(() => {
  const { deck } = useViewerDecks();
  const [open, setOpen] = React.useState(false);
  const loopFilter = useGetState("loopFilter");

  React.useEffect(() => {
    setOpen(false);
  }, [loopFilter, setOpen]);

  const [searchParams] = useSearchParams();
  const popupKnown = searchParams.get(LEARN.popupKnownParam);
  useEffectOnce(() => {
    popupKnown && setOpen(true);
  });

  return (
    <>
      <HintTooltip
        name="cardFiltering"
        title="Filter out which vocabulary you want to learn"
        canOpen={Number(deck?.stats.total) > 10}
      >
        <IconWrap>
          <SStarIcon />
          <SIconButton onClick={() => setOpen(true)} $highlighted={!!loopFilter}>
            <SFilterIcon />
          </SIconButton>
        </IconWrap>
      </HintTooltip>

      <DialogWindow open={open} onClose={() => setOpen(false)} wide>
        <CardsFilterIn />
      </DialogWindow>
    </>
  );
});

export default CardsFilter;
