import React from "react";
import styled, { keyframes } from "styled-components/macro";
import { Trans } from "react-i18next";
import { TutorialFC } from "root/tutorial/TutorialContent";
import TutorialPointer from "../TutorialPointer";
import { useLibraryQuery } from "queries/sources/libraryQuery";
import { bottomPadding } from "tools/device";

const pulsingRotatedKF = keyframes`
  0%, 100% { transform: scale(1) rotate(95deg) translateY(-7px); }
  50% {  transform: scale(1.3) rotate(85deg) translateY(7px);}
`;

export const LibTutorialPointer = styled(TutorialPointer)`
  right: 52%;
  bottom: ${1 + bottomPadding}px;
  animation: ${pulsingRotatedKF} 1.5s ease-in-out infinite;
`;

const BreakLibrary: TutorialFC = () => {
  useLibraryQuery(); // preload library for next step (ChooseSet)

  return (
    <>
      <Trans parent="p">Go to the library by clicking the middle button.</Trans>

      <LibTutorialPointer />
    </>
  );
};

export default BreakLibrary;
