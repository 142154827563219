import React from "react";
import styled from "styled-components/macro";
import { flag } from "../../../../tools/langs";
import { sourceEditQuery } from "../__generated__/sourceEditQuery.graphql";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PublicIcon from "@mui/icons-material/Public";
import { Trans } from "react-i18next";
import SingleRow from "./SingleRow";

const Line = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
const GreyLine = styled(Line)`
  color: ${({ theme }) => theme.duo.color.textGrey};
`;
type Props = {
  source?: sourceEditQuery["response"]["node"];
  onClick: VoidFunction;
  openForm: boolean;
};

const TopRow: React.FC<Props> = ({ source, onClick, openForm }) => {
  return (
    <SingleRow icon={source?.private ? <LockOutlinedIcon /> : <PublicIcon />} onClick={onClick} isOpen={openForm}>
      <Trans parent="strong">Edit</Trans> <br />
      {source?.name} {flag(source?.lang)}
      <Line>{source?.text?.substr(0, 100)}</Line>
      <GreyLine>{source?.source}</GreyLine>
    </SingleRow>
  );
};

export default TopRow;
