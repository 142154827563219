import { ComponentProps } from "react";
import styled from "styled-components/macro";

const Svg = styled.svg`
  width: 26px;
  height: 26px;
`;

const Present = (props: ComponentProps<typeof Svg>) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width="511pt" height="511pt" viewBox="-45 0 511 511.999" {...props}>
    <path
      fill="#e22b2b"
      d="M256.54 173.77c0 25.367-20.563 45.93-45.93 45.93-25.364 0-45.926-20.563-45.926-45.93 0-25.364 20.562-45.926 45.925-45.926 25.368 0 45.93 20.562 45.93 45.926zm0 0"
    />
    <path
      fill="#f94141"
      d="M135.41 163.277C60.215 120.36 58.063 17.051 120.371 2.007c62.305-15.038 90.238 55.864 90.238 161.27v30.832M285.809 163.277c75.195-42.918 77.347-146.226 15.039-161.27-62.305-15.038-90.239 55.864-90.239 161.27v39.192"
    />
    <path
      fill="#e22b2b"
      d="M210.61 163.277c0-54.636 14.48-91.39 46.777-83.593s31.183 61.347-7.797 83.593l-17.926 17.93M171.629 163.277c-38.977-22.246-40.094-75.797-7.797-83.593 32.3-7.797 46.777 28.957 46.777 83.593l-3.656 28.743"
    />
    <path
      fill="#589ce0"
      d="M388.676 230.844H32.539v243.765C32.54 495.258 49.277 512 69.93 512h281.356c20.649 0 37.39-16.738 37.39-37.39zm0 0"
    />
    <path fill="#357fbc" d="M32.54 230.844h356.136v50.476H32.539zm0 0" />
    <path
      fill="#589ce0"
      d="M399.957 163.277H21.254C9.793 163.277.5 172.57.5 184.035v44.328c0 11.465 9.293 20.754 20.754 20.754h378.703c11.465 0 20.758-9.289 20.758-20.754v-44.328c0-11.465-9.293-20.758-20.758-20.758zm0 0"
    />
    <path fill="#f94141" d="M176.219 163.277h68.777V512H176.22zm0 0" />
    <path fill="#e22b2b" d="M176.219 249.125h68.777v32.195H176.22zm0 0" />
  </Svg>
);

export default Present;
