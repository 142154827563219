import { useEffect, useState } from "react";

interface TimedAction {
  delay: number;
  callback: () => void;
}

export const useTimedActions = (inputActions: TimedAction[]) => {
  const [actions, setActions] = useState<TimedAction[]>(inputActions);

  useEffect(() => {
    const timeouts = actions.map((action) => {
      return setTimeout(action.callback, action.delay);
    });

    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, [actions]);

  return { setActions };
};
