import { createGlobalStyle, css } from "styled-components/macro";

const filters = {
  default: css``,
  dark: css`
    html:not(img):not(video) {
      filter: invert(95%) hue-rotate(180deg) saturate(80%);
    }
    .nofilter {
      filter: invert(110%) hue-rotate(180deg) saturate(120%);
    }
    .MuiButton-root,
    .MuiFab-root {
      box-shadow: none;
    }
    .MuiPaper-root {
      box-shadow: 0 0 5px #0002;
    }
    .MuiBackdrop-root {
      background: #ddd9;
    }
    .gardenFloor {
      filter: brightness(0.3) saturate(2);
    }
  `,
  pink: css`
    html:not(img):not(video) {
      filter: hue-rotate(120deg);
    }
    .nofilter {
      filter: hue-rotate(-120deg);
    }
  `,
  sepia: css`
    html:not(img):not(video) {
      filter: brightness(98%) saturate(85%) hue-rotate(190deg) sepia(15%);
    }
    .nofilter {
      filter: saturate(115%) hue-rotate(-190deg);
    }
  `
};

export const GlobalStyle = createGlobalStyle`
html, body, #root {
  height: 100%;
}

${({ theme }) => {
  return filters[theme.duo.theme];
}}

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
  padding: 0;
  background: white;
  font-family: "Noto Sans", Arial, Helvetica, sans-serif;
  font-size: ${({ theme }) => theme.duo.fontSize.normal};
  line-height: 1.5em;
  color: ${({ theme }) => theme.duo.color.text};
  overscroll-behavior: contain;
}
h1, h2 {
  margin: 15px 0; 
  font-weight: ${({ theme }) => theme.duo.fontWeight.w400};
}
h1 {
  font-size: ${({ theme }) => theme.duo.fontSize.large};
  line-height: 1.43em;
}
h2 {
  font-size: ${({ theme }) => theme.duo.fontSize.medium};
  line-height: 1.43em;
}
*:focus {
  outline: none;
}     
hr {
  background: none;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.duo.color.grey};
  margin: 20px 0;
}
a {
  color: ${({ theme }) => theme.duo.color.primary};
  text-decoration: none;
}

.MuiButton-sizeLarge {
  padding: 11px 32px;
  font-size: 16px;
}

// remove iconButtons hover on touch devices
@media (pointer: coarse) {
  .MuiIconButton-root:hover {
    background-color: transparent;
  }
} 

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(0.8);
  }
  to {
    opacity: 0;
    transform: scale(3);
  }
}
@keyframes pulseBold {
  from {
    opacity: 1;
    transform: scale(0.8);
  }
  to {
    opacity: 0;
    transform: scale(6);
  }
}
`;
