import { useState, useEffect, useRef } from "react";

function useBooleanChangeTracker(value: boolean | undefined, n: number, limitMs: number): boolean {
  const [limitExceeded, setLimitExceeded] = useState<boolean>(false);
  // Ref to store the timestamps of each change
  const changeTimestampsRef = useRef<number[]>([]);
  const valueRef = useRef<boolean | undefined>(value);

  useEffect(() => {
    // Function to check and update the limit exceeded status
    const checkLimitExceeded = () => {
      const now = Date.now();
      // Filter out timestamps outside the current sliding window
      changeTimestampsRef.current = changeTimestampsRef.current.filter((timestamp) => now - timestamp <= limitMs);

      // Update limit exceeded status based on the number of changes
      setLimitExceeded(changeTimestampsRef.current.length > n);
    };

    // On value change, update the list of change timestamps
    if (value !== valueRef.current) {
      valueRef.current = value;
      changeTimestampsRef.current.push(Date.now());
      checkLimitExceeded();
    }
  }, [value, n, limitMs]);

  return limitExceeded;
}

export default useBooleanChangeTracker;
