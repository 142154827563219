import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { flagLabel } from "tools/langs";
import { AuthorQuery } from "./__generated__/AuthorQuery.graphql";
import { Tab, Tabs } from "@mui/material";
import SourceCard from "root/library/library/SourceCard";
import { LibLine, LibLineIn, LibTitle } from "root/library/library/styled";

const Wrap = styled.div`
  padding: 0 0 15px;
  margin: 0;
  text-align: center;
`;
const STabs = styled(Tabs)`
  background: white;
  border-bottom: 1px solid ${({ theme }) => theme.duo.color.grey};
  margin-bottom: 12px;
  .MuiTabs-flexContainer {
    padding: 0 15px;
  }
`;
const NoSources = styled.p`
  margin: 50px 20px;
  font-size: 90%;
  line-height: 1.4em;
  color: grey;
`;

type Props = {
  sources: NonNullable<NonNullable<AuthorQuery["response"]["userByName"]>["publicSources"]>;
};

const AuthorPublicSources: React.FC<Props> = ({ sources }) => {
  const { t } = useTranslation();
  const [tabLang, setTabLang] = React.useState(sources[0] && sources[0].lang);

  const sorted = sources.reduce(
    (acc, source) => {
      const kind = source.course ? "course" : source.kind;

      acc[source.lang] = acc[source.lang] || {};
      acc[source.lang][kind] = (acc[source.lang][kind] || []).concat(source);

      return acc;
    },
    {} as { [lang: string]: { [kind: string]: typeof sources } }
  );

  const titles = [
    ["course", t("Courses")],
    ["set", t("Sets of cards")],
    ["video", t("Videos")],
    ["article", t("Articles")]
  ];

  return (
    <Wrap>
      {sources.length ? (
        <>
          <STabs value={tabLang} onChange={(e, lang) => setTabLang(lang)} variant="scrollable">
            {Object.keys(sorted).map((lang) => (
              <Tab label={flagLabel(lang)} value={lang} key={lang} />
            ))}
          </STabs>
          <>
            {titles.map(
              ([kind, title]) =>
                sorted[tabLang][kind] &&
                sorted[tabLang][kind].length > 0 && (
                  <React.Fragment key={kind}>
                    <LibTitle>{title}</LibTitle>
                    <LibLine>
                      <LibLineIn>
                        {sorted[tabLang][kind].map((source) => (
                          <SourceCard source={source} key={source.id} />
                        ))}
                      </LibLineIn>
                    </LibLine>
                  </React.Fragment>
                )
            )}
          </>
        </>
      ) : (
        <Trans parent={NoSources}>The user did not create any public sources yet.</Trans>
      )}
    </Wrap>
  );
};

export default AuthorPublicSources;
