import React from "react";
import { Trans } from "react-i18next";
import { Tooltip, TooltipProps } from "@mui/material";
import styled from "styled-components/macro";
import { useViewerQuery } from "queries/viewerQuery";
import { useSeeHint } from "components/hint/Hint";
import EmojiObjects from "@mui/icons-material/EmojiObjects";

export const VOICEHINT = "voice";

const TitleWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 10px 5px;
  font-size: 14px;
  line-height: 1.5em;

  & svg {
    color: #f9f9f9;
    margin-right: 6px;
    font-size: 1.3rem;
  }
`;

type Props = {
  children?: React.ReactNode;
  title: string;
  canOpen: boolean;
  name: string;
  className?: string;
  placement?: TooltipProps["placement"];
};

export const HintTooltip: React.FC<Props> = React.memo(
  ({ children, title, canOpen, name, className, placement = "top" }) => {
    const { hintWasSeen } = useViewerQuery();
    const seeHint = useSeeHint();

    React.useEffect(() => {
      if (hintWasSeen(name) || !canOpen) return;

      function handleTouchAnywhere(event) {
        seeHint(name);
      }

      document.addEventListener("mousedown", handleTouchAnywhere);
      document.addEventListener("touchstart", handleTouchAnywhere);

      return () => {
        document.removeEventListener("mousedown", handleTouchAnywhere);
        document.removeEventListener("touchstart", handleTouchAnywhere);
      };
    });

    return (
      <Tooltip
        title={
          <TitleWrap>
            <EmojiObjects />
            <Trans parent="span">{title}</Trans>
          </TitleWrap>
        }
        placement={placement}
        arrow
        open={!hintWasSeen(name) && canOpen}
        PopperProps={{ className: `MuiTooltip-popper MuiTooltip-popperArrow ${className}` }}
      >
        <span>{children}</span>
      </Tooltip>
    );
  }
);

export default HintTooltip;
