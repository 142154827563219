/**
 * @generated SignedSource<<d108f6b28fc53d3b2301372882ed7e0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type createDeckMutation$variables = {
  back: string;
  front: string;
  name?: string | null;
};
export type createDeckMutation$data = {
  readonly createDeck: {
    readonly " $fragmentSpreads": FragmentRefs<"viewerQuery_decks">;
  } | null;
};
export type createDeckMutation = {
  response: createDeckMutation$data;
  variables: createDeckMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "back"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "front"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = [
  {
    "kind": "Variable",
    "name": "back",
    "variableName": "back"
  },
  {
    "kind": "Variable",
    "name": "front",
    "variableName": "front"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createDeckMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "createDeck",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "viewerQuery_decks"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "createDeckMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "createDeck",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Deck",
            "kind": "LinkedField",
            "name": "decks",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "front",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "back",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Stats",
                "kind": "LinkedField",
                "name": "stats",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "known",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unknown",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completed",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TtsVoice",
                "kind": "LinkedField",
                "name": "voices",
                "plural": true,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "abcc0c9d66417c1368508b725d915fe2",
    "id": null,
    "metadata": {},
    "name": "createDeckMutation",
    "operationKind": "mutation",
    "text": "mutation createDeckMutation(\n  $front: String!\n  $back: String!\n  $name: String\n) {\n  createDeck(front: $front, back: $back, name: $name) {\n    ...viewerQuery_decks\n    id\n  }\n}\n\nfragment viewerQuery_decks on User {\n  decks {\n    id\n    name\n    front\n    back\n    stats {\n      total\n      known\n      unknown\n      completed\n    }\n    voices {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6f8d5abeb587a9ab6a8f2607a5c131f3";

export default node;
