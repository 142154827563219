export interface SentenceData {
  sentence: string;
  index: number;
  input: string;
}

interface NodeData {
  node: Node;
  textContent: string | null;
  isInlined: boolean;
  isTextNode: boolean;
}

interface NodeWithOffset {
  node: NodeData;
  offset: number;
}

interface GetSelectSentenceResult {
  data: SentenceData;
  start: NodeWithOffset;
  end: NodeWithOffset;
}

export const getHint = (text: string) => {
  const ctx = window?.getSelection()?.anchorNode?.nodeValue;
  const sntcs = ctx?.split(new RegExp("(\\?|\\.|!)")) || [];
  const ind = sntcs?.findIndex((s) => s?.indexOf(text || "") !== -1);
  if (ind === -1) return undefined;
  const sentence = sntcs[ind] + (sntcs[ind + 1] && sntcs[ind + 1].length === 1 ? sntcs[ind + 1] : "");
  return sentence && sentence.length < 180 ? sentence.trim() : undefined;
};

export const getSelectedSentence = (parentNode, lang: string): GetSelectSentenceResult | undefined => {
  const selection = window.getSelection();
  if (!selection || selection.rangeCount === 0) return;

  const range = selection.getRangeAt(0);
  const selectedText = range.toString();
  if (!selectedText) return;

  const flatNodes = flattenNodes(parentNode);
  const fullText = flatNodes.map((node) => node.textContent).join("");
  const sentences = getSentences(fullText, lang);
  if (!sentences || sentences.length === 0) return;

  const textStart = mapNodeToFlatOffset(range.startContainer, range.startOffset, flatNodes);
  const textEnd = mapNodeToFlatOffset(range.endContainer, range.endOffset, flatNodes);
  if (!textStart || !textEnd) return;

  const sentence = sentences.find((s) => s.index <= textStart.offset && s.index + s.sentence.length >= textEnd.offset);
  if (!sentence) return;

  const sentenceStart = mapFlatOffsetToNode(flatNodes, sentence.index || 0);
  const sentenceEnd = mapFlatOffsetToNode(flatNodes, sentence.index + sentence.sentence.length);
  if (!sentenceStart || !sentenceEnd) return;

  const startTextNode = sentenceStart.node.isTextNode
    ? sentenceStart
    : findNearestTextNode(flatNodes, flatNodes.indexOf(sentenceStart.node), 1);
  const endTextNode = sentenceEnd.node.isTextNode
    ? sentenceEnd
    : findNearestTextNode(flatNodes, flatNodes.indexOf(sentenceEnd.node), -1);
  if (!startTextNode || !endTextNode) return;

  return { data: sentence, start: startTextNode, end: endTextNode };
};

const getSentences = (text: string, lang: string): SentenceData[] => {
  return isIntlSegmenterSupported(lang) ? getSentences_Intl(text, lang) : getSentences_Fallback(text);
};

const getSentences_Intl = (text: string, lang: string): SentenceData[] => {
  const segmenter = new Intl.Segmenter(lang, { granularity: "sentence" });
  const segments = segmenter.segment(text);
  return Array.from(segments).map((segment) => ({
    sentence: segment.segment.trim(),
    index: segment.index,
    input: text
  }));
};

const getSentences_Fallback = (text: string): SentenceData[] => {
  const sentencePattern = /[^.!?\n]+[.!?\n]+/g;
  const matches = text.match(sentencePattern) || [];

  let sentences: SentenceData[] = [];
  let currentIndex = 0;

  matches.forEach((sentence) => {
    const trimmedSentence = sentence.trim();
    if (trimmedSentence === "") return;
    const index = text.indexOf(trimmedSentence, currentIndex);
    currentIndex = index + trimmedSentence.length;
    sentences.push({ sentence: trimmedSentence, index, input: text });
  });

  return sentences;
};

const isIntlSegmenterSupported = (lang) => {
  return (
    typeof Intl.Segmenter === "function" &&
    typeof Intl.Segmenter.supportedLocalesOf === "function" &&
    Intl.Segmenter.supportedLocalesOf(lang).length > 0
  );
};

const mapFlatOffsetToNode = (textNodes: NodeData[], offset: number): NodeWithOffset | undefined => {
  let accumulatedLength = 0;
  for (const node of textNodes) {
    if (accumulatedLength + (node.textContent?.length || 0) >= offset) {
      return { node, offset: offset - accumulatedLength };
    }
    accumulatedLength += node.textContent?.length || 0;
  }
  return;
};

const mapNodeToFlatOffset = (node: Node, offset: number, textNodes: NodeData[]): NodeWithOffset | undefined => {
  let accumulatedLength = 0;
  for (const flatNode of textNodes) {
    if (flatNode.node === node) {
      return { node: flatNode, offset: accumulatedLength + offset };
    }
    accumulatedLength += flatNode.textContent?.length || 0;
  }
  return;
};

const findNearestTextNode = (
  textNodes: NodeData[],
  index: number,
  searchDir: -1 | 1 = 1
): NodeWithOffset | undefined => {
  let finded = textNodes[index];
  let offset = 0;
  while (!finded.isTextNode) {
    const nextIndex = textNodes.indexOf(finded) + searchDir;
    if (nextIndex < 0 || nextIndex >= textNodes.length) {
      return;
    }
    finded = textNodes[nextIndex];
    offset = searchDir > 0 ? 0 : finded.textContent?.length || 0;
  }
  return { node: finded, offset };
};

const flattenNodes = (node: Node): NodeData[] => {
  let textNodes: NodeData[] = [];
  function recurse(currentNode: Node) {
    if (currentNode.nodeType === 3) {
      // Node.TEXT_NODE
      textNodes.push({
        node: currentNode,
        textContent: currentNode.textContent,
        isInlined: true,
        isTextNode: true
      });
    } else {
      currentNode.childNodes.forEach((child) => recurse(child));
      textNodes.push({
        node: currentNode,
        textContent: isKnownInlineElement(currentNode) ? "" : "\n", // replace block elements with new line (it will divide sentences)
        isInlined: isKnownInlineElement(currentNode),
        isTextNode: false
      });
    }
  }
  recurse(node);
  return textNodes;
};

const isKnownInlineElement = (node: Node): boolean => {
  const inlineElements = [
    "span",
    "a",
    "img",
    "b",
    "i",
    "small",
    "strong",
    "sub",
    "sup",
    "button",
    "input",
    "label",
    "textarea"
  ];
  return inlineElements.includes((node as Element).tagName?.toLowerCase() || "");
};
