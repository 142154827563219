/**
 * @generated SignedSource<<efbbcba7a069e859c3899544b232eeb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VerifyMutation$variables = {};
export type VerifyMutation$data = {
  readonly resendVerification: boolean;
};
export type VerifyMutation = {
  response: VerifyMutation$data;
  variables: VerifyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "resendVerification",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "VerifyMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "201853a782efbbd59e9e0c98d5fd25cf",
    "id": null,
    "metadata": {},
    "name": "VerifyMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyMutation {\n  resendVerification\n}\n"
  }
};
})();

(node as any).hash = "96398a3abf87347437714bfbbe5028a3";

export default node;
