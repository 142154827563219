import React from "react";
import styled from "styled-components/macro";

const Svg = styled.svg`
  width: 41px;
  height: 41px;
  position: absolute;
  left: -2px;
  top: 62px;
`;

const Flower: React.FC<any> = (p) => (
  <Svg
    {...p}
    enableBackground="new 0 0 512 512"
    height="50"
    viewBox="0 0 512 512"
    width="50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m360.833 237.308c-1.164-1.483-2.417-2.92-3.784-4.286-10.838-10.838-25.62-15.322-39.724-13.461-1.861 14.105 2.624 28.887 13.461 39.724 8.372 8.372 19.098 12.935 30.047 13.727z"
        fill="#a2e62e"
      ></path>
      <path
        d="m359.833 268.921c-5.058-2.285-9.805-5.479-13.962-9.637-10.728-10.728-15.221-25.32-13.506-39.296-4.962-.934-10.042-1.088-15.04-.429-1.861 14.105 2.624 28.887 13.461 39.724 8.117 8.117 18.446 12.664 29.047 13.656z"
        fill="#97d729"
      ></path>
      <path
        d="m92.099 205.307c-1.164-1.483-2.417-2.919-3.784-4.286-10.837-10.838-25.619-15.322-39.724-13.461-1.861 14.105 2.624 28.887 13.461 39.724 8.372 8.372 19.098 12.935 30.047 13.727z"
        fill="#a2e62e"
      ></path>
      <path
        d="m91.099 236.774c-4.939-2.274-9.575-5.42-13.645-9.49-10.714-10.714-15.208-25.282-13.512-39.24-5.06-.988-10.249-1.157-15.351-.484-1.861 14.105 2.624 28.887 13.461 39.724 8.117 8.117 18.446 12.664 29.047 13.656z"
        fill="#97d729"
      ></path>
      <path
        d="m108.882 233.021c-1.367 1.367-2.62 2.803-3.784 4.286v35.704c10.949-.792 21.675-5.356 30.047-13.727 10.837-10.838 15.322-25.62 13.461-39.724-14.104-1.861-28.886 2.624-39.724 13.461z"
        fill="#a2e62e"
      ></path>
      <path
        d="m108.882 233.021c-.984.984-1.905 2.006-2.784 3.052v36.859c2.661-.253 5.305-.725 7.901-1.423v-23.1c2.311-5.596 5.736-10.84 10.284-15.388 6.917-6.917 15.444-11.234 24.373-12.977-.02-.161-.029-.323-.05-.484-14.104-1.861-28.886 2.624-39.724 13.461z"
        fill="#97d729"
      ></path>
      <path
        d="m80.766 459.411c-.673-58.394-10.368-109.698-24.918-141.044-.712-1.535-2.909-1.535-3.621 0-14.551 31.346-24.245 82.651-24.918 141.044z"
        fill="#a2e62e"
      ></path>
      <path
        d="m42.516 458.411c.664-49.851 7.904-94.459 19.118-125.798-1.828-5.109-3.758-9.877-5.786-14.246-.712-1.535-2.909-1.535-3.621 0-14.467 31.167-24.132 82.066-24.903 140.044z"
        fill="#97d729"
      ></path>
      <path
        d="m97.695 318.787c.246-.531.753-.572.903-.572s.657.041.903.572c2.346 5.053 4.546 10.6 6.597 16.565v-160.22c-2.299.942-4.838 1.45-7.506 1.45-2.664 0-5.198-.506-7.494-1.445v160.216c2.051-5.966 4.252-11.513 6.597-16.566z"
        fill="#8bc727"
      ></path>
      <g>
        <path
          d="m153.629 126.567c-3.58-6.202-10.706-10.36-20.54-12.136 16.726-2.978 22.994-12.431 22.994-21.377 0-12.097-10.059-20.223-19.454-20.223-7.175 0-14.353 4.108-20.819 11.767 3.4-9.429 3.369-17.699-.219-23.913-3.408-5.903-9.763-9.426-16.998-9.426-7.236 0-13.591 3.524-16.999 9.426-3.588 6.214-3.619 14.484-.219 23.913-6.466-7.66-13.644-11.768-20.819-11.768-9.396 0-19.457 8.126-19.457 20.224 0 8.931 6.248 18.37 22.917 21.362-6.057 1.085-11.042 3.083-14.894 5.979-5.172 3.888-8.021 9.354-8.021 15.391 0 12.097 10.059 20.223 19.454 20.223 7.175 0 14.353-4.108 20.819-11.767-3.4 9.429-3.369 17.699.219 23.913 3.408 5.903 9.763 9.427 16.999 9.427s13.591-3.524 16.998-9.427c3.588-6.214 3.619-14.484.219-23.913 6.466 7.659 13.643 11.766 20.819 11.767h.001c6.815 0 13.044-3.741 16.662-10.007 3.62-6.267 3.746-13.532.338-19.435z"
          fill="#fe73c5"
        ></path>
      </g>
      <g>
        <circle cx="98.599" cy="114.42" fill="#fed402" r="25.062"></circle>
      </g>
      <g>
        <path
          d="m91.876 114.42c0-10.604 6.589-19.662 15.892-23.323-2.841-1.118-5.932-1.739-9.17-1.739-13.841 0-25.062 11.221-25.062 25.062s11.221 25.062 25.062 25.062c3.238 0 6.329-.621 9.17-1.739-9.303-3.661-15.892-12.72-15.892-23.323z"
          fill="#fac600"
        ></path>
      </g>
      <path
        d="m125.328 459.411c-.673-58.394-10.368-109.698-24.918-141.044-.712-1.535-2.909-1.535-3.621 0-14.551 31.346-24.245 82.651-24.918 141.044z"
        fill="#a2e62e"
      ></path>
      <path
        d="m87.055 458.411c.664-49.866 7.909-94.485 19.129-125.826-1.825-5.098-3.753-9.852-5.775-14.216-.712-1.535-2.909-1.536-3.622-.001-14.467 31.167-24.131 82.065-24.902 140.043z"
        fill="#97d729"
      ></path>
      <path
        d="m170.551 459.411c-.946-40.28-10.619-75.576-25.017-97.194-.794-1.192-2.546-1.192-3.34 0-14.398 21.618-24.071 56.913-25.017 97.194z"
        fill="#a2e62e"
      ></path>
      <path
        d="m132.565 458.411c.922-33.969 8.057-64.317 18.982-85.818-1.896-3.731-3.902-7.206-6.013-10.376-.794-1.192-2.546-1.192-3.34 0-14.279 21.439-23.915 56.329-24.995 96.194z"
        fill="#97d729"
      ></path>
      <path
        d="m215.805 459.411c-.673-58.394-10.368-109.698-24.918-141.044-.712-1.535-2.909-1.535-3.621 0-14.551 31.346-24.245 82.651-24.918 141.044z"
        fill="#a2e62e"
      ></path>
      <path
        d="m177.516 458.411c.664-49.88 7.912-94.51 19.137-125.853-1.822-5.088-3.746-9.838-5.767-14.191-.712-1.535-2.909-1.535-3.621 0-14.467 31.167-24.132 82.066-24.903 140.044z"
        fill="#97d729"
      ></path>
      <g>
        <path
          d="m233.298 362.771c.189-.285.495-.448.837-.448s.648.164.838.448c2.37 3.558 4.593 7.469 6.662 11.68v-122.347c-2.3.942-4.838 1.451-7.506 1.451-2.664 0-5.197-.506-7.494-1.445v122.342c2.07-4.212 4.293-8.123 6.663-11.681z"
          fill="#8bc727"
        ></path>
        <g>
          <path
            d="m289.165 203.539c-3.58-6.202-10.706-10.36-20.54-12.136 16.726-2.978 22.994-12.431 22.994-21.377 0-12.097-10.059-20.223-19.454-20.223-7.175 0-14.353 4.108-20.819 11.767 3.4-9.429 3.369-17.699-.219-23.913-3.408-5.903-9.763-9.426-16.998-9.426-7.236 0-13.591 3.524-16.999 9.426-3.588 6.214-3.619 14.484-.219 23.913-6.466-7.659-13.644-11.767-20.819-11.767-9.396 0-19.457 8.126-19.457 20.224 0 8.931 6.248 18.37 22.917 21.362-6.057 1.085-11.042 3.083-14.894 5.979-5.172 3.888-8.021 9.354-8.021 15.391 0 12.097 10.059 20.223 19.454 20.223 7.175 0 14.353-4.108 20.819-11.767-3.4 9.429-3.369 17.699.219 23.913 3.408 5.903 9.763 9.427 16.999 9.427s13.591-3.524 16.998-9.427c3.588-6.214 3.619-14.484.219-23.913 6.466 7.659 13.643 11.766 20.819 11.767h.001c6.815 0 13.044-3.741 16.662-10.007 3.621-6.267 3.746-13.533.338-19.436z"
            fill="#925cb4"
          ></path>
        </g>
        <g>
          <circle cx="234.135" cy="191.392" fill="#fff" r="25.062"></circle>
        </g>
        <g>
          <path
            d="m227.876 191.392c0-10.516 6.479-19.513 15.661-23.232-2.904-1.176-6.076-1.83-9.401-1.83-13.841 0-25.062 11.221-25.062 25.062s11.221 25.062 25.062 25.062c3.326 0 6.498-.654 9.401-1.83-9.182-3.719-15.661-12.716-15.661-23.232z"
            fill="#dff6fd"
          ></path>
        </g>
      </g>
      <path
        d="m260.822 459.411c-.946-40.28-10.619-75.576-25.017-97.194-.794-1.192-2.546-1.192-3.34 0-14.398 21.618-24.071 56.913-25.017 97.194z"
        fill="#a2e62e"
      ></path>
      <path
        d="m222.565 458.411c.925-34.109 8.116-64.569 19.118-86.084-1.857-3.631-3.819-7.012-5.88-10.11-.793-1.192-2.545-1.189-3.338.002-14.279 21.439-23.914 56.328-24.995 96.193h15.095z"
        fill="#97d729"
      ></path>
      <path
        d="m306.227 459.411c-.673-58.394-10.368-109.698-24.918-141.044-.712-1.535-2.909-1.535-3.621 0-14.551 31.346-24.245 82.651-24.918 141.044z"
        fill="#a2e62e"
      ></path>
      <path
        d="m268.055 458.411c.663-49.792 7.887-94.353 19.079-125.687-1.84-5.151-3.783-9.957-5.825-14.357-.712-1.535-2.909-1.535-3.621 0-14.467 31.167-24.132 82.066-24.903 140.044z"
        fill="#97d729"
      ></path>
      <path
        d="m351.396 459.411c-.946-40.28-10.619-75.576-25.017-97.194-.794-1.192-2.546-1.192-3.34 0-14.398 21.618-24.071 56.913-25.017 97.194z"
        fill="#a2e62e"
      ></path>
      <path
        d="m313.104 458.411c.926-34.127 8.124-64.601 19.135-86.119-1.851-3.617-3.806-6.99-5.861-10.075-.794-1.192-2.546-1.192-3.34 0-14.279 21.439-23.915 56.329-24.995 96.194z"
        fill="#97d729"
      ></path>
      <path
        d="m366.43 318.787c.246-.531.753-.572.903-.572.149 0 .657.041.903.572 2.346 5.053 4.546 10.6 6.597 16.565v-160.221c-2.3.942-4.838 1.451-7.506 1.451-2.664 0-5.198-.506-7.494-1.445v160.215c2.051-5.966 4.251-11.512 6.597-16.565z"
        fill="#8bc727"
      ></path>
      <g>
        <path
          d="m422.363 126.567c-3.58-6.202-10.706-10.36-20.54-12.136 16.726-2.978 22.994-12.431 22.994-21.377 0-12.097-10.059-20.223-19.454-20.223-7.175 0-14.353 4.108-20.819 11.767 3.4-9.429 3.369-17.699-.219-23.913-3.408-5.903-9.763-9.426-16.998-9.426-7.236 0-13.591 3.524-16.999 9.426-3.588 6.214-3.619 14.484-.219 23.913-6.466-7.659-13.644-11.767-20.819-11.767-9.396 0-19.457 8.126-19.457 20.224 0 8.931 6.248 18.37 22.917 21.362-6.057 1.085-11.042 3.083-14.894 5.979-5.172 3.888-8.021 9.354-8.021 15.391 0 12.097 10.059 20.223 19.454 20.223 7.175 0 14.353-4.108 20.819-11.767-3.4 9.429-3.369 17.699.219 23.913 3.408 5.903 9.763 9.427 16.999 9.427s13.591-3.524 16.998-9.427c3.588-6.214 3.619-14.484.219-23.913 6.466 7.659 13.643 11.766 20.819 11.767h.001c6.815 0 13.044-3.741 16.662-10.007 3.621-6.268 3.746-13.533.338-19.436z"
          fill="#fac600"
        ></path>
      </g>
      <g>
        <circle cx="367.333" cy="114.42" fill="#fe93d3" r="25.062"></circle>
      </g>
      <g>
        <path
          d="m360 114.42c0-10.719 6.732-19.863 16.198-23.444-2.757-1.043-5.743-1.618-8.865-1.618-13.841 0-25.062 11.221-25.062 25.062s11.221 25.062 25.062 25.062c3.122 0 6.108-.575 8.865-1.618-9.466-3.582-16.198-12.725-16.198-23.444z"
          fill="#fe73c5"
        ></path>
      </g>
      <path
        d="m394.062 459.411c-.673-58.394-10.368-109.698-24.918-141.044-.712-1.535-2.909-1.535-3.621 0-14.551 31.346-24.246 82.651-24.918 141.044z"
        fill="#a2e62e"
      ></path>
      <path
        d="m356.055 458.411c.661-49.668 7.852-94.129 18.996-125.451-1.864-5.239-3.836-10.12-5.908-14.59-.712-1.536-2.909-1.536-3.622-.001-14.467 31.167-24.131 82.065-24.902 140.042z"
        fill="#97d729"
      ></path>
      <path
        d="m439.439 459.411c-.946-40.28-10.619-75.576-25.017-97.194-.794-1.192-2.546-1.192-3.34 0-14.398 21.618-24.071 56.913-25.017 97.194z"
        fill="#a2e62e"
      ></path>
      <path
        d="m401.104 458.411c.926-34.149 8.133-64.641 19.156-86.161-1.845-3.601-3.792-6.959-5.839-10.032-.794-1.192-2.546-1.192-3.34 0-14.279 21.439-23.915 56.329-24.995 96.194h15.018z"
        fill="#97d729"
      ></path>
      <path
        d="m484.692 459.411c-.673-58.394-10.368-109.698-24.918-141.044-.712-1.535-2.909-1.535-3.621 0-14.551 31.346-24.245 82.651-24.918 141.044z"
        fill="#a2e62e"
      ></path>
      <path
        d="m446.516 458.411c.663-49.796 7.888-94.36 19.081-125.694-1.839-5.148-3.781-9.952-5.823-14.35-.712-1.535-2.909-1.535-3.621 0-14.467 31.167-24.132 82.066-24.903 140.044z"
        fill="#97d729"
      ></path>
      <g>
        <path
          d="m504.5 473.411h-497c-4.142 0-7.5-3.358-7.5-7.5s3.358-7.5 7.5-7.5h497c4.143 0 7.5 3.358 7.5 7.5s-3.357 7.5-7.5 7.5z"
          fill="#8bc727"
        ></path>
      </g>
      <g>
        <path
          d="m456.531 227.076c-.723-.722-.723-1.894 0-2.616 7.861-7.861 8.067-16.95 2.588-22.429s-14.568-5.273-22.428 2.588c-.722.723-1.893.723-2.616 0-7.861-7.861-16.95-8.067-22.429-2.588s-5.273 14.568 2.588 22.429c.723.722.723 1.894 0 2.616-7.861 7.861-8.067 16.95-2.588 22.429s14.568 5.273 22.429-2.588c.722-.723 1.894-.723 2.616 0 7.861 7.861 16.95 8.067 22.429 2.588 5.478-5.48 5.272-14.568-2.589-22.429z"
          fill="#fe93d3"
        ></path>
      </g>
      <g>
        <g>
          <path
            d="m429.764 249.504c-5.479-5.479-5.273-14.568 2.588-22.428.723-.722.723-1.893 0-2.616-7.861-7.861-8.067-16.95-2.588-22.429.182-.182.379-.342.569-.511-6.862-4.666-14.06-4.115-18.687.511-5.479 5.479-5.273 14.568 2.588 22.429.723.722.723 1.894 0 2.616-7.861 7.861-8.067 16.95-2.588 22.428 4.626 4.626 11.825 5.178 18.687.512-.19-.17-.387-.329-.569-.512z"
            fill="#fe73c5"
          ></path>
        </g>
      </g>
      <g>
        <path
          d="m257.531 67.397c-.723-.722-.723-1.894 0-2.616 7.861-7.861 8.067-16.95 2.588-22.429s-14.568-5.273-22.428 2.588c-.722.723-1.894.723-2.616 0-7.861-7.861-16.95-8.067-22.429-2.588s-5.273 14.568 2.588 22.429c.723.722.723 1.894 0 2.616-7.861 7.861-8.067 16.95-2.588 22.429s14.568 5.273 22.429-2.588c.722-.723 1.894-.723 2.616 0 7.861 7.861 16.95 8.067 22.429 2.588 5.478-5.479 5.272-14.568-2.589-22.429z"
          fill="#fe93d3"
        ></path>
      </g>
      <g>
        <g>
          <path
            d="m231.764 89.826c-5.479-5.479-5.273-14.568 2.588-22.428.723-.722.723-1.894 0-2.616-7.861-7.861-8.067-16.95-2.588-22.428.044-.045.094-.081.139-.125-7.03-5.094-14.502-4.63-19.256.125-5.479 5.479-5.273 14.568 2.588 22.428.723.722.723 1.894 0 2.616-7.861 7.861-8.067 16.95-2.588 22.428 4.754 4.754 12.226 5.219 19.256.125-.046-.044-.095-.081-.139-.125z"
            fill="#fe73c5"
          ></path>
        </g>
      </g>
      <g>
        <path
          d="m198.648 309.058c-.723-.722-.723-1.894 0-2.616 7.861-7.861 8.067-16.95 2.588-22.428-5.479-5.479-14.568-5.273-22.429 2.588-.722.723-1.894.723-2.616 0-7.861-7.861-16.95-8.067-22.429-2.588s-5.273 14.568 2.588 22.428c.723.722.723 1.894 0 2.616-7.861 7.861-8.067 16.95-2.588 22.428s14.568 5.273 22.429-2.588c.722-.723 1.894-.723 2.616 0 7.861 7.861 16.95 8.067 22.429 2.588 5.479-5.478 5.273-14.567-2.588-22.428z"
          fill="#fed402"
        ></path>
      </g>
      <g>
        <g>
          <path
            d="m172.764 331.486c-5.479-5.479-5.273-14.568 2.588-22.428.723-.722.723-1.894 0-2.616-7.861-7.861-8.067-16.95-2.588-22.428.061-.061.128-.111.189-.17-7.01-5.043-14.45-4.569-19.189.17-5.479 5.479-5.273 14.568 2.588 22.428.723.722.723 1.894 0 2.616-7.861 7.861-8.067 16.95-2.588 22.428 4.739 4.739 12.179 5.214 19.189.17-.062-.059-.129-.109-.189-.17z"
            fill="#fac600"
          ></path>
        </g>
      </g>
    </g>
  </Svg>
);

export default Flower;
