import React from "react";
import styled from "styled-components/macro";
import { Trans, useTranslation } from "react-i18next";
import { Button, Dialog, DialogContentText, IconButton, Stack, Typography } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { MAX_FREE_AI_MESSAGES } from "../../../sharedJs__generated/constants";
import FormatNumber from "../../formatters/FormatNumber";

const SDialogContent = styled(DialogContent)`
  background-color: ${(p) => p.theme.duo.palette.white};
  padding: 32px 24px;
  height: 100%;
  overflow-y: auto;
  position: relative;
  display: flex;
  align-items: center;
`;

const SFormatNumber = styled(FormatNumber)`
  font-weight: bold;
`;

const Message = styled(Stack)`
  text-align: center;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const SDialogContentText = styled(DialogContentText)`
  color: ${(p) => p.theme.palette.text.primary};
  text-align: center;
`;

const SubscriptionWrap = styled.div`
  text-align: center;
`;

const BackButton = styled(IconButton)`
  position: absolute;
  right: 6px;
  top: 8px;
  padding: 5px;
`;

type Props = {
  onShowSubscriptionOptions: () => void;
  onClose: () => void;
};

const AiMessagesLimitDialog: React.FC<Props> = ({ onShowSubscriptionOptions, onClose }: Props) => {
  // a workaround for a simple component that does not require re-render in order to show possibly missing translations
  useTranslation();

  return (
    <Dialog open={true}>
      <SDialogContent>
        <BackButton onClick={onClose}>
          <CloseIcon />
        </BackButton>

        <Stack direction={"column"} spacing={4}>
          <Typography variant={"h5"} sx={{ textAlign: "center" }}>
            <Trans>Free version AI chat</Trans>
          </Typography>

          <Message direction={"column"} spacing={2}>
            <Trans parent={SDialogContentText}>
              Only <SFormatNumber value={MAX_FREE_AI_MESSAGES} /> <Bold>messages</Bold> per week can be used in the free
              version of the chat. This limit <Bold>resets every Monday</Bold>.
            </Trans>
            <Trans parent={SDialogContentText}>
              Use AI chat without restrictions, <Bold>upgrade</Bold> to a <Bold>Premium account</Bold> and get many
              other benefits.
            </Trans>
          </Message>

          <SubscriptionWrap>
            <Button variant="contained" size="small" onClick={onShowSubscriptionOptions}>
              <Trans>See options</Trans>
            </Button>
          </SubscriptionWrap>
        </Stack>
      </SDialogContent>
    </Dialog>
  );
};

export default AiMessagesLimitDialog;
