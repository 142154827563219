import React, { useImperativeHandle, forwardRef, useState, useEffect } from "react";
import { useRive, Fit, Layout, Alignment } from "@rive-app/react-canvas";
import Loader from "components/other/Loader";
import styled from "styled-components";
import { useTranslates } from "hooks/useTranslates";
import { useDeviceLang } from "hooks/deviceHooks";
import { useViewerDecks } from "queries/viewerQuery";

export const FEATURESHINT = "features";

const SLoader = styled(Loader)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

type TextRun = {
  text: string;
  id: string;
  translate?: "back" | "front" | "app";
};

export type Feature = {
  title: string;
  alignment: Alignment;
  rive: string;
  texts?: TextRun[];
};

type Props = {
  feature: Feature;
};

const FeaturesHintAnimation = forwardRef<{ startAnimation: () => void }, Props>(({ feature }, ref) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [shouldPlay, setShouldPlay] = useState(false); // State to queue animation start

  const { translatedFeature, isLoading: isLoadingTranslates } = useTranslatesForFeature(feature);

  const { rive, RiveComponent } = useRive({
    src: feature.rive,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: feature.alignment
    }),
    onLoad: () => {
      setIsLoaded(true);
    }
  });

  const playAnimation = React.useCallback(() => {
    if (!rive) return;
    if (translatedFeature?.texts) {
      for (const run of translatedFeature.texts) {
        if (run.text) {
          rive.setTextRunValue(run.id, run.text);
        }
      }
    }
    rive.play();
  }, [rive, translatedFeature?.texts]);

  useImperativeHandle(
    ref,
    () => ({
      startAnimation: () => {
        if (rive && isLoaded && !isLoadingTranslates) {
          playAnimation();
        } else {
          setShouldPlay(true); // Queue the animation start
        }
      }
    }),
    [rive, isLoaded, playAnimation, isLoadingTranslates]
  );

  useEffect(() => {
    if (rive && isLoaded && shouldPlay && !isLoadingTranslates) {
      playAnimation();
      setShouldPlay(false); // Reset the queue
    }
  }, [rive, isLoaded, shouldPlay, isLoadingTranslates, playAnimation]);

  return (
    <>
      <RiveComponent key={feature.rive} />
      {!isLoaded && <SLoader />}
    </>
  );
});

const useTranslatesForFeature = (feature: Feature) => {
  const { deck } = useViewerDecks();
  const { appLang } = useDeviceLang();
  const [translatedFeature, setTranslatedFeature] = React.useState<Feature | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const featuresBackRuns = feature.texts?.filter((t) => t.translate === "back") || [];
  const featuresFrontRuns = feature.texts?.filter((t) => t.translate === "front") || [];
  const featuresAppRuns = feature.texts?.filter((t) => t.translate === "app") || [];

  const { translates: translateApp, isLoadingTranslates: isLoadingTranslatesApp } = useTranslates(
    appLang,
    featuresAppRuns.map((t) => t.text)
  );
  const { translates: translateBack, isLoadingTranslates: isLoadingTranslatesBack } = useTranslates(
    deck?.back || "es",
    featuresBackRuns.map((t) => t.text)
  );
  const { translates: translateFront, isLoadingTranslates: isLoadingTranslatesFront } = useTranslates(
    deck?.front || "en",
    featuresFrontRuns.map((t) => t.text)
  );

  React.useEffect(() => {
    if (isLoadingTranslatesFront || isLoadingTranslatesBack || isLoadingTranslatesApp) {
      setIsLoading(true);
      return;
    }

    let actFrontIndex = 0;
    let actBackIndex = 0;
    let actAppIndex = 0;

    const translatedFeature = {
      ...feature,
      texts: feature.texts?.map((t) => {
        let translatedFeature = { ...t };
        switch (t.translate) {
          case "front":
            translatedFeature.text = translateFront[actFrontIndex++];
            break;
          case "back":
            translatedFeature.text = translateBack[actBackIndex++];
            break;
          case "app":
            translatedFeature.text = translateApp[actAppIndex++];
            break;
        }
        return translatedFeature;
      })
    };
    setTranslatedFeature(translatedFeature);
    setIsLoading(false);
  }, [
    isLoadingTranslatesFront,
    isLoadingTranslatesBack,
    isLoadingTranslatesApp,
    translateFront,
    translateBack,
    translateApp,
    feature
  ]);

  return { translatedFeature, isLoading };
};

export default FeaturesHintAnimation;
