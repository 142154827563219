/**
 * @generated SignedSource<<0d73a5edea162e318608fb0c4fba128a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type sortSCardMutation$variables = {
  backLang?: string | null;
  id: string;
  sort: number;
};
export type sortSCardMutation$data = {
  readonly sortSCard: {
    readonly sCards: ReadonlyArray<{
      readonly back: {
        readonly translated: boolean | null;
        readonly value: string;
      } | null;
      readonly front: string;
      readonly hint: string | null;
      readonly id: string;
    }>;
  };
};
export type sortSCardMutation = {
  response: sortSCardMutation$data;
  variables: sortSCardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "backLang"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  }
],
v4 = [
  {
    "kind": "Variable",
    "name": "backLang",
    "variableName": "backLang"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "front",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hint",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "translated",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "sortSCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "sortSCard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "SCard",
            "kind": "LinkedField",
            "name": "sCards",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SCardBack",
                "kind": "LinkedField",
                "name": "back",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "sortSCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "sortSCard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "SCard",
            "kind": "LinkedField",
            "name": "sCards",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SCardBack",
                "kind": "LinkedField",
                "name": "back",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "362c46ddc3ad923e1f5996f72ab9c25c",
    "id": null,
    "metadata": {},
    "name": "sortSCardMutation",
    "operationKind": "mutation",
    "text": "mutation sortSCardMutation(\n  $id: ID!\n  $sort: Int!\n  $backLang: String\n) {\n  sortSCard(id: $id, sort: $sort) {\n    sCards(backLang: $backLang) {\n      id\n      front\n      hint\n      back {\n        value\n        translated\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2d2049f95dc70e54d9a6f45eb21c4c6e";

export default node;
