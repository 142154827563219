import React from "react";
import { useEvent } from "react-use";
import { EVENT } from "tools/events";
import DialogWindow from "components/other/DialogWindow";
import { Button, Stack } from "@mui/material";
import { Trans } from "react-i18next";
import MammothIcon from "icons/MammothIcon";
import { LEARN } from "root/main/MainRouter";
import { useNavigate } from "react-router-dom";
import { useSetState } from "components/ReduxProvider";
import { TLoopFilter } from "root/main/learn/CardsFilter";
import { sourceQuery$data } from "queries/sources/__generated__/sourceQuery.graphql";
import { useViewerDecks } from "queries/viewerQuery";

type Props = {
  source: sourceQuery$data["sourceByUriOrId"];
};

const SetCardsFilterDialog: React.FC<Props> = ({ source }) => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const setLoopFilter = useSetState("loopFilter");
  const setSavedLoopFilter = useSetState("savedLoopFilter");
  const setLoopFilterDeckId = useSetState("loopFilterDeckId");
  const { deck } = useViewerDecks();

  const isPickedSome = !!source.sCards.find((c) => c.isInMyDeck === true);
  const isPickedAll = !source.sCards.find((c) => c.isInMyDeck === null);

  const startTest = () => {
    const loopFilter: TLoopFilter = {
      filteredSIds: [source.id],
      includeKnown: true,
      loopMode: "native",
      filterId: String(Math.floor(Math.random() * 1_000_000))
    };
    setLoopFilter(loopFilter);
    setLoopFilterDeckId(deck?.id);
    setSavedLoopFilter(loopFilter);

    navigate(LEARN.url());
  };

  useEvent(EVENT.testMeFromPickedCards, () => {
    if (isPickedAll) {
      startTest();
    } else {
      setOpen(true);
    }
  });

  return (
    <DialogWindow open={open} onClose={() => setOpen(false)}>
      <Stack alignItems="center" justifyContent="center">
        <MammothIcon />
      </Stack>

      {!isPickedSome ? (
        <>
          <Trans parent="p">You have to pick some cards to your deck first</Trans>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            OK
          </Button>
        </>
      ) : (
        <>
          <Trans parent="p">You didn't sort all cards yet, do you want to add more before you learn them?</Trans>
          <Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
            <Button variant="contained" onClick={() => setOpen(false)}>
              <Trans>Add more</Trans>
            </Button>
            <Button variant="text" onClick={startTest}>
              <Trans>Start learning</Trans>
            </Button>
          </Stack>
        </>
      )}
    </DialogWindow>
  );
};

export default SetCardsFilterDialog;
