import { useEnvironment } from "../graphql/environment";
import { useCallback, useState } from "react";
import { fetchStt } from "./stt/fetchStt";

type TranscriptionArgs = {
  audio: string;
  audioFormat?: string;
  languages?: string[];
  onTranscribed?: (text: string) => void;
};

type SttQueryResult = {
  isTranscribing: boolean;
  error: string | null;
  transcribe: ({ audio, audioFormat, languages, onTranscribed }: TranscriptionArgs) => void;
};

export const useSttQuery = (): SttQueryResult => {
  const environment = useEnvironment();
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const transcribe = useCallback(
    async ({ audio, audioFormat, languages, onTranscribed }: TranscriptionArgs) => {
      setIsTranscribing(true);
      setError(null);
      try {
        const data = await fetchStt(environment, { audio, audioFormat, languages });
        if (data) {
          const { text } = data;
          if (onTranscribed) {
            onTranscribed(text);
          }
        }
      } catch (err) {
        setError("Speech to text failed");
        //console.error("Error fetching STT data:", err);
      } finally {
        setIsTranscribing(false);
      }
    },
    [environment]
  );

  return { isTranscribing, error, transcribe };
};
