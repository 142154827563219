import React from "react";
import { Navigate, useNavigate, useParams } from "react-router";
import { graphql } from "babel-plugin-relay/macro";
import { useQuery } from "relay-hooks";
import Loader from "components/other/Loader";
import { AuthorQuery } from "./__generated__/AuthorQuery.graphql";
import AuthorTop from "./AuthorTop";
import { BackButton, HeaderLayout } from "../../components/header/Header";
import AuthorPublicSources from "./AuthorPublicSources";
import ShareIcon from "@mui/icons-material/Share";
import { IconButton } from "@mui/material";
import SharePopup from "../../components/other/SharePopup";
import { useViewerQuery } from "queries/viewerQuery";
import { Link } from "react-router-dom";
import { SmallDuoCards } from "styled/DuoCardsTitle";
import { AUTHOR, LIBRARY, MAIN } from "../RootRouter.config";

export const AuthorQL = graphql`
  query AuthorQuery($name: String!) {
    userByName(name: $name) {
      name
      bio
      picture
      affiliate
      xps {
        total
      }
      publicSources {
        id
        uri
        lang
        name
        kind
        course
        difficulty
        videoDuration
        textLength
        source
        top
        private
        mine
        avgRating
        image {
          url
          authorName
          authorApiName
        }
        rating {
          rating
          continueFrom
          picked
          dismissed
          finished
        }
        counts {
          total
          theories
        }
      }
    }
  }
`;

type Props = {};

const Author: React.FC<Props> = () => {
  const { viewer } = useViewerQuery();
  const params = useParams<{ name: string }>();
  const { data: authorData, isLoading } = useQuery<AuthorQuery>(AuthorQL, { name: params.name as string });
  const [shareOpen, setShareOpen] = React.useState(false);
  const navigate = useNavigate();

  const author = authorData?.userByName;

  if (isLoading) return <Loader />;

  if (!author) return <Navigate to="/" />;

  return (
    <HeaderLayout
      transparent
      right={
        <IconButton onClick={() => setShareOpen(true)}>
          <ShareIcon />
        </IconButton>
      }
      left={
        viewer ? (
          <BackButton onBack={() => navigate(MAIN.url(), { replace: true })} />
        ) : (
          <Link to={LIBRARY.url()}>
            <SmallDuoCards />
          </Link>
        )
      }
    >
      <AuthorTop author={author} />
      <AuthorPublicSources sources={author.publicSources} />

      {author.name && <SharePopup onClose={() => setShareOpen(false)} open={shareOpen} uri={AUTHOR.url(author.name)} />}
    </HeaderLayout>
  );
};

export default Author;
