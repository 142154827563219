import Flames from "components/fire/Flames";
import React from "react";
import { Trans } from "react-i18next";
import { useDebounce, useEvent } from "react-use";
import styled, { keyframes, css } from "styled-components/macro";
import { EVENT } from "tools/events";

export const DAILYXPS = 5;

const shakeKF = keyframes`
  10%, 90% {left: 2px; }
  25%, 75% {left: -4px;}
  50% {left: 4px;}
`;

const SFlames = styled(Flames)`
  display: inline-block;
  margin: 0px 7px -7px 6px;
`;
const Earn = styled.span<{ shake?: boolean }>`
  line-height: 1.7em;
  position: relative;
  ${({ shake }) =>
    shake &&
    css`
      animation: ${shakeKF} 400ms;
    `}
`;
const TopReward = styled.div`
  position: absolute;
  left: calc(50% - 6px);
  top: -30px;
  font-size: 20px;
`;

type Props = {
  inGrey?: boolean;
};

const DailyXps: React.FC<Props> = ({ inGrey }) => {
  const [expanded, setExpanded] = React.useState(!inGrey);
  const [shakeIt, setShakeIt] = React.useState(false);

  useDebounce(() => shakeIt && setShakeIt(false), 500, [shakeIt]);

  const expandDailyQuest = React.useCallback(() => {
    if (!expanded) {
      setExpanded(true);
    } else if (!shakeIt) {
      setShakeIt(true);
    }
  }, [expanded, shakeIt]);

  useEvent(EVENT.expandDailyQuest, expandDailyQuest);

  return expanded ? (
    <Earn shake={shakeIt}>
      <Trans>
        Earn at least
        <SFlames strength={4} xps={5} />
        today, <br /> so you can pet me
      </Trans>
      {!inGrey ? <TopReward>🧡</TopReward> : <>&nbsp;🧡</>}
    </Earn>
  ) : (
    <span onClick={() => setExpanded(true)}>
      +
      <SFlames strength={4} xps={5} />
    </span>
  );
};

export default DailyXps;
