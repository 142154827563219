import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router";

import { PROFILE } from "../../RootRouter.config";

type Props = {};

const ExitIconButton: React.FC<Props> = () => {
  const navigate = useNavigate();

  return (
    <IconButton
      sx={{
        position: "absolute",
        bottom: -5,
        right: 0
      }}
      onClick={() => navigate(PROFILE.url())}
    >
      <LogoutIcon />
    </IconButton>
  );
};

export default ExitIconButton;
