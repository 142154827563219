import React from "react";
import styled from "styled-components/macro";
import DialogWindow from "../other/DialogWindow";
import { useMutation } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { Trans } from "react-i18next";
import { Button } from "@mui/material";
import { TextField } from "../../styled/TextField";
import { PromoCodeDialogActivePromocodeMutation } from "./__generated__/PromoCodeDialogActivePromocodeMutation.graphql";
import { snackbar } from "tools/events";

const Wrap = styled.div``;
const Title = styled.h1<{ first?: boolean }>`
  font-size: 18px;
  font-weight: bold;
`;

const SDialogWindow = styled(DialogWindow)`
  z-index: 11000;
`;

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess?: VoidFunction;
};

const ActivatePromocodeQL = graphql`
  mutation PromoCodeDialogActivePromocodeMutation($affiliateName: String!) {
    activatePromocode(affiliateName: $affiliateName) {
      installReferrer
    }
  }
`;

const PromoCodeDialog: React.FC<Props> = ({ open, setOpen, onSuccess }) => {
  const [activatePromocode] = useMutation<PromoCodeDialogActivePromocodeMutation>(ActivatePromocodeQL);

  const [affiliateName, setAffiliateName] = React.useState<string>("");

  function submitPromocode(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!affiliateName) return;

    activatePromocode({
      variables: {
        affiliateName: affiliateName
      },
      onCompleted: () => {
        setOpen(false);
        snackbar(<Trans>Promo code was succesfuly applyed</Trans>);
        onSuccess?.();
      }
    });
  }

  return (
    <SDialogWindow
      open={!!open}
      onClose={() => setOpen(false)}
      title={
        <Title first>
          <Trans>Enter promo code</Trans>
        </Title>
      }
    >
      <Wrap>
        <form autoComplete="off" onSubmit={submitPromocode}>
          <TextField
            name="affiliateName"
            required
            type="text"
            value={affiliateName}
            onChange={({ target }) => setAffiliateName(target.value)}
          />
          <Button variant="contained" color="primary" type="submit" fullWidth>
            <Trans>Apply</Trans>
          </Button>
        </form>
      </Wrap>
    </SDialogWindow>
  );
};

export default PromoCodeDialog;
