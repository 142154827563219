/**
 * @generated SignedSource<<4ddc15be9345ab928faffb8ada8fec7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type viewerQuery_decks$data = {
  readonly decks: ReadonlyArray<{
    readonly back: string | null;
    readonly front: string;
    readonly id: string;
    readonly name: string | null;
    readonly stats: {
      readonly completed: number;
      readonly known: number;
      readonly total: number;
      readonly unknown: number;
    };
    readonly voices: ReadonlyArray<{
      readonly id: string;
    }>;
  }>;
  readonly " $fragmentType": "viewerQuery_decks";
};
export type viewerQuery_decks$key = {
  readonly " $data"?: viewerQuery_decks$data;
  readonly " $fragmentSpreads": FragmentRefs<"viewerQuery_decks">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "viewerQuery_decks",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Deck",
      "kind": "LinkedField",
      "name": "decks",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "front",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "back",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Stats",
          "kind": "LinkedField",
          "name": "stats",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "known",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unknown",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completed",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TtsVoice",
          "kind": "LinkedField",
          "name": "voices",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "1130226347a8f816a0c2f594702a3ade";

export default node;
