import { useGetState, useSetState } from "components/ReduxProvider";
import React from "react";
import {
  RequestInfoUpdateOptions,
  ConsentStatus,
  DebugGeography,
  PrivacyOptionsRequirementStatus,
  TrackingAuthorizationStatus
} from "components/cordova/ConsentTypes";
import * as Sentry from "@sentry/browser";
import { EVENT } from "tools/events";
import { useEvent } from "react-use";
import { isIos } from "tools/device";

const consent = window.consent;
const canUseNewAdmob = (window as any).appversion > 11909;

const ConsentInitializer: React.FC<{}> = () => {
  const trackingDeniedRedux = useGetState("trackingDenied");
  const setTrackingDenied = useSetState("trackingDenied");

  const canRequestAdsRedux = useGetState("canRequestAdmobAds");
  const setCanRequestAds = useSetState("canRequestAdmobAds");

  const doConsentProcess = React.useCallback(async () => {
    // docs: https://admob-plus.github.io/docs/cordova/consent
    // more info: https://developers.google.com/admob/android/privacy
    if (!consent) return;

    const isIosPlatform = isIos();
    const consentStatus: ConsentStatus = await consent.getConsentStatus();

    let iosTrackingStatus: TrackingAuthorizationStatus = TrackingAuthorizationStatus.notDetermined;
    let canRequestAds: boolean = true; // default to true, if using old admob

    if (canUseNewAdmob) {
      await requestConsentInfoUpdateIfNeeded(consentStatus);
      await consent.loadAndShowIfRequired(); // if user is in EAA it displays GDPR consent and then ATT popup, otherwise it displays pre-ATT popup instead of consent
      if (isIosPlatform) {
        iosTrackingStatus = await consent.trackingAuthorizationStatus(); // now we can check again if user has denied tracking
      }

      canRequestAds = await consent.canRequestAds();
    } else if (isIosPlatform) {
      // oldadmob and ios
      iosTrackingStatus = await consent.trackingAuthorizationStatus();
      if (!iosTrackingStatus) {
        iosTrackingStatus = await consent.requestTrackingAuthorization(); // show ATT popup
      }
    }

    // update trackingdenied
    const trackingDenied = iosTrackingStatus === TrackingAuthorizationStatus.denied;
    if (!!trackingDeniedRedux !== trackingDenied) {
      setTrackingDenied(trackingDenied);
    }

    // update canrequestads
    if (!!canRequestAdsRedux !== canRequestAds) {
      setCanRequestAds(canRequestAds);
      if (!canRequestAds) {
        Sentry.captureException(new Error("Ads not allowed"));
      }
    }
  }, [trackingDeniedRedux, setTrackingDenied, canRequestAdsRedux, setCanRequestAds]);

  useEvent(EVENT.consentCheck, doConsentProcess);

  return null;
};

const getDebugParams = (): RequestInfoUpdateOptions => ({
  testDeviceIds: [
    "E34DB6CCCB61991C73501F13D7434DC3" /*ANDROID tablet MT*/,
    "019443AD-62BA-41C9-9625-01E88053A27D" /*ios phone MT*/
  ], // look into android logcat or ios device logs for this device hash and add it to array
  debugGeography: DebugGeography.EEA // Europe Union
});

const requestConsentInfoUpdateIfNeeded = async (consentStatus: ConsentStatus) => {
  if (consentStatus === ConsentStatus.Unknown || consentStatus === ConsentStatus.Required) {
    await consent.requestInfoUpdate(process.env.NODE_ENV === "development" ? getDebugParams() : undefined);
  }
};

export const checkIfAdmobPrivacyOptionIsRequired = async (): Promise<boolean> => {
  if (!consent || !canUseNewAdmob) return false;

  const status = await consent.privacyOptionsRequirementStatus();
  return status === PrivacyOptionsRequirementStatus.Required;
};

export const showAdmobPrivacyForm = async () => {
  await consent.showPrivacyOptionsForm();
};

export default ConsentInitializer;
