import React from "react";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { Formik, Form, Field } from "formik";
import styled from "styled-components/macro";
import { FSelect } from "../../../styled/Select";
import { FTextField } from "../../../styled/TextField";
import { SourceKind } from "../LibraryRouter";
import { Kind, createSourceMutation } from "../../../queries/sources/__generated__/createSourceMutation.graphql";
import Loader from "components/other/Loader";
import { useEditSourceMutation, publicCheck } from "../../../queries/sources/editSource";
import { SOURCEFORM } from "../library/libraryUrls";
import { useDeviceLang } from "../../../hooks/deviceHooks";
import { Trans, useTranslation } from "react-i18next";
import { useViewerQuery, useViewerDecks } from "../../../queries/viewerQuery";
import { sourceEditQuery } from "./__generated__/sourceEditQuery.graphql";
import LangDropdowns, { MULTILNGOPTION } from "./components/LangDropdowns";
import { useEnvironment } from "../../../graphql/environment";
import { translateQuery } from "../../../queries/__generated__/translateQuery.graphql";
import { translateQL } from "../../../queries/translateQuery";
import { fetchQuery, useMutation } from "relay-hooks";
import { createSourceQL } from "../../../queries/sources/createSource";
import { clipboardToAnnotatedText } from "tools/strings";
import insertTextAtCursor from "insert-text-at-cursor";
import { useSearchParams, useNavigate } from "react-router-dom";
import { LabelTextWithLangLabel } from "tools/langs";

const SForm = styled(Form)`
  padding: 20px;
`;
const FTextFieldFix = ({ isSet, ...p }) => <FTextField {...p} />;
const TextArea = styled(FTextFieldFix)<{ isSet: boolean }>`
  textarea {
    min-height: ${({ isSet }) => (isSet ? 0 : 150)}px;
  }
`;
const SButton = styled(Button)`
  margin: 10px;
  width: calc(100% - 20px);
`;
const HideIfNew = styled.div<{ hide: boolean }>`
  max-height: ${({ hide }) => (hide ? 0 : 70)}px;
  overflow: hidden;
  transition: max-height 400ms;
`;

type Props = {
  source?: sourceEditQuery["response"]["node"];
  onSave: VoidFunction;
};

const SourceForm: React.FC<Props> = ({ source, onSave }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deviceLang } = useDeviceLang();
  const { viewer } = useViewerQuery();
  const { deck } = useViewerDecks();
  const [nextClicked, setNextClicked] = React.useState<boolean>(); // false=loading
  const environment = useEnvironment();
  const [createSource] = useMutation<createSourceMutation>(createSourceQL);
  const editSource = useEditSourceMutation();

  const [searchParams] = useSearchParams();
  const kind = source ? (source.kind as Kind) : (searchParams.get(SOURCEFORM.kindParam) as Kind);
  const id = searchParams.get("id");
  const isSet = kind === SourceKind.set;

  if (id && !source) return <Loader />;

  const handleSubmit = (values: typeof init) => {
    if (id) {
      editSource({ ...values, id });
    } else {
      createSource({
        variables: {
          ...values,
          langNative: values.langNative !== MULTILNGOPTION ? values.langNative : null,
          multiLang: values.langNative === MULTILNGOPTION,
          kind
        },
        onCompleted: ({ createSource }) => {
          navigate(SOURCEFORM.url({ id: createSource?.id }), { replace: true });
          isSet && createSource && navigate(SOURCEFORM.url({ id: createSource.id, action: "cards" }));
        }
      });
    }
    onSave();
  };
  const handleValidate = (values: typeof init) => {
    const err = publicCheck({ ...(source || { kind }), ...values }, t);
    if (err) {
      return { private: err };
    }
  };

  const pickingName = !id && kind === SourceKind.set && !nextClicked;
  const langNative = source?.id ? undefined : viewer?.admin ? MULTILNGOPTION : (deck?.back as string);
  const init = {
    name: source?.name || "",
    lang: source?.lang || deck?.front || deviceLang,
    langNative,
    nameNative: "",
    text: source?.text || "",
    difficulty: source?.difficulty || "moderate",
    source: source?.source || "",
    course: source?.course || false,
    contentTest: source?.contentTest?.active || false,
    duoLang: typeof source?.duoLang !== "undefined" ? source?.duoLang : true,
    private: typeof source?.private !== "undefined" ? source?.private : true,
    imageId: source?.imageId || null
  };

  return (
    <Formik initialValues={init} onSubmit={handleSubmit} validate={handleValidate} enableReinitialize>
      {({ values, setValues }) => {
        const handleNext = () => {
          if (values.langNative !== MULTILNGOPTION) {
            fetchQuery<translateQuery>(environment, translateQL, {
              from: values.nameNative as string,
              fromLang: values.langNative as string,
              toLang: values.lang as string
            }).subscribe({
              next: (data) => {
                setValues({ ...values, name: data.translate.translate });
                setNextClicked(true);
              }
            });
            setNextClicked(false);
          } else {
            setNextClicked(true);
          }
        };

        return (
          <>
            {/* <ImgPicker name="imageId" imageUrl={source?.image?.url} /> */}

            {/* TODO: OWN COMPONENT */}

            <SForm id="createsourceform">
              {/* TODO
              {isSet && (
                <FSelect
                  name="duoLang"
                  label={t("Set Type")}
                  onChange={() => {
                    alert("Sorry, Thematic are not ready yet.");
                    return false;
                  }}
                  options={[
                    { value: true, label: "Dual-language", icon: "💬" },
                    { value: false, label: "Thematic", icon: "💡" }
                  ]}
                  disabled={!!id}
                />
              )} */}
              <LangDropdowns disabled={!!id} values={values} withBackLang={isSet && values.duoLang && !id} />
              <HideIfNew hide={pickingName && values.langNative !== MULTILNGOPTION}>
                <FTextField
                  required
                  name="name"
                  label={<LabelTextWithLangLabel label={isSet ? t("Name") : t("Heading")} lang={values.lang} />}
                />
              </HideIfNew>
              {values.langNative && values.langNative !== MULTILNGOPTION && (
                <FTextField
                  required
                  // style={{ marginTop: "-15px" }}
                  name="nameNative"
                  label={<LabelTextWithLangLabel label={t("Name")} lang={values.langNative} />}
                />
              )}
              {pickingName ? (
                nextClicked === false ? (
                  <Loader />
                ) : (
                  <SButton
                    color="primary"
                    variant="contained"
                    onClick={handleNext}
                    disabled={!!values.langNative && values.langNative !== MULTILNGOPTION && !values.nameNative}
                  >
                    <Trans>Continue</Trans>
                  </SButton>
                )
              ) : (
                <>
                  <FSelect
                    name="difficulty"
                    label={t("Level")}
                    options={[
                      {
                        value: "beginner",
                        label: t("beginner")
                      },
                      {
                        value: "easy",
                        label: t("easy")
                      },
                      {
                        value: "moderate",
                        label: t("moderate")
                      },
                      {
                        value: "hard",
                        label: t("hard")
                      }
                    ]}
                  />

                  {id && source && viewer?.admin === 2 && (
                    <Field as={FormControlLabel} type="checkbox" name="course" control={<Checkbox />} label="Course" />
                  )}
                  {id && source && viewer?.admin === 2 && (
                    <Field
                      style={{ float: "right" }}
                      as={FormControlLabel}
                      type="checkbox"
                      name="contentTest"
                      control={<Checkbox />}
                      label="Content-Test"
                    />
                  )}

                  {!isSet && (
                    <TextArea
                      name="text"
                      label={isSet ? t("Optional Description") : t("Article")}
                      onPaste={(e) => {
                        if (!isSet) {
                          e.preventDefault();

                          const annotatedText = clipboardToAnnotatedText(e.clipboardData);
                          insertTextAtCursor(e.target, annotatedText);
                        }
                      }}
                      multiline
                      isSet={isSet}
                    />
                  )}
                  {!isSet && <FTextField name="source" label={t("Source")} helperText="https://..." />}

                  <SButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={
                      values.langNative === values.lang ||
                      !values.name ||
                      (!!values.langNative && values.langNative !== MULTILNGOPTION && !values.nameNative)
                    }
                  >
                    {!id ? t("Continue") : t("Save")}
                  </SButton>
                </>
              )}
            </SForm>
          </>
        );
      }}
    </Formik>
  );
};

export default SourceForm;
