/**
 * @generated SignedSource<<ab0647aca9f94111471faae0ee1ff62f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FlatIconEdit = {
  flatId: string;
  url: string;
};
export type editSCardMutation$variables = {
  back?: string | null;
  backLang?: string | null;
  deckId?: string | null;
  front: string;
  hint?: string | null;
  id: string;
  svg?: FlatIconEdit | null;
  synchronize?: boolean | null;
  theory?: string | null;
};
export type editSCardMutation$data = {
  readonly editSCard: {
    readonly back: {
      readonly translated: boolean | null;
      readonly value: string;
    } | null;
    readonly front: string;
    readonly hint: string | null;
    readonly id: string;
    readonly svg: {
      readonly flatId: string;
      readonly url: string;
    } | null;
    readonly theory: {
      readonly canEdit: boolean;
      readonly lang: string;
      readonly langNative: string;
      readonly sCardId: string;
      readonly theory: string;
      readonly theoryEn: string | null;
      readonly theoryIsChecked: boolean;
      readonly theoryNative: string;
      readonly theoryNativeIsChecked: boolean;
    } | null;
  } | null;
};
export type editSCardMutation = {
  response: editSCardMutation$data;
  variables: editSCardMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "back"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "backLang"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deckId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "front"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hint"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "svg"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "synchronize"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "theory"
},
v9 = [
  {
    "kind": "Variable",
    "name": "back",
    "variableName": "back"
  },
  {
    "kind": "Variable",
    "name": "backLang",
    "variableName": "backLang"
  },
  {
    "kind": "Variable",
    "name": "deckId",
    "variableName": "deckId"
  },
  {
    "kind": "Variable",
    "name": "front",
    "variableName": "front"
  },
  {
    "kind": "Variable",
    "name": "hint",
    "variableName": "hint"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "svg",
    "variableName": "svg"
  },
  {
    "kind": "Variable",
    "name": "synchronize",
    "variableName": "synchronize"
  },
  {
    "kind": "Variable",
    "name": "theory",
    "variableName": "theory"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "front",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hint",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "translated",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "langNative",
      "variableName": "backLang"
    }
  ],
  "concreteType": "SCardTheory",
  "kind": "LinkedField",
  "name": "theory",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sCardId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theoryIsChecked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theoryNative",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theoryNativeIsChecked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theoryEn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lang",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "langNative",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEdit",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flatId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "editSCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "SCard",
        "kind": "LinkedField",
        "name": "editSCard",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SCardBack",
            "kind": "LinkedField",
            "name": "back",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Upload",
            "kind": "LinkedField",
            "name": "svg",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v8/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "editSCardMutation",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "SCard",
        "kind": "LinkedField",
        "name": "editSCard",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SCardBack",
            "kind": "LinkedField",
            "name": "back",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v14/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Upload",
            "kind": "LinkedField",
            "name": "svg",
            "plural": false,
            "selections": [
              (v16/*: any*/),
              (v17/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e2fe664ff08dc480eda05de436d80e80",
    "id": null,
    "metadata": {},
    "name": "editSCardMutation",
    "operationKind": "mutation",
    "text": "mutation editSCardMutation(\n  $id: ID!\n  $front: String!\n  $hint: String\n  $back: String\n  $backLang: String\n  $theory: String\n  $svg: FlatIconEdit\n  $synchronize: Boolean\n  $deckId: ID\n) {\n  editSCard(id: $id, front: $front, hint: $hint, back: $back, backLang: $backLang, svg: $svg, theory: $theory, synchronize: $synchronize, deckId: $deckId) {\n    id\n    front\n    hint\n    back {\n      value\n      translated\n      id\n    }\n    theory(langNative: $backLang) {\n      sCardId\n      theory\n      theoryIsChecked\n      theoryNative\n      theoryNativeIsChecked\n      theoryEn\n      lang\n      langNative\n      canEdit\n    }\n    svg {\n      flatId\n      url\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "15a135bf9db51a6f9eede43e49ae06ec";

export default node;
