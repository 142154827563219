import React from "react";

type Props = {
  text: string;
  className?: string;
};

const TransVariable: React.FC<Props> = ({ text, className }) => {
  if (className) {
    return <span className={className}>{text}</span>;
  } else {
    return <>{text}</>;
  }
};

export default TransVariable;
