import React from "react";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useMatchMain } from "root/RootRouter";
import { windowReloadLimited } from "tools/windowReload";
import Splash from "root/auth/Splash";
import { getStoragedJsVersion, setStoragedJsVersion } from "tools/storageJsVersion";
import { useI18NextIsReady } from "tools/i18next";
import { useViewerQuery } from "queries/viewerQuery";
import { useLocation } from "react-router-dom";
import { ASSISTANT_HASH } from "../assistant/AssistantPopup";

// deletes outdated cached javascript
const deleteJsStorage = () => {
  const openRequest = indexedDB.open("jsDB");
  openRequest.onsuccess = function (event) {
    try {
      const db = (event.target as IDBOpenDBRequest).result;
      db.transaction(["jsStorage"], "readwrite").objectStore("jsStorage").put("js", undefined); // do not use .delete, just set to undefined, it's safer than delete
    } catch (e) {} // catch cases when javascript is not stored
  };
};

type Props = {
  children: React.ReactNode;
};
const ReleaseUpdater: React.FC<Props> = ({ children }) => {
  const mainRoute = useMatchMain({ end: true });
  const releaseId = useGetState("releaseId");
  const releaseIdPrev = getStoragedJsVersion();
  const needsUpdate = React.useRef<boolean>(false);
  const i18nIsReady = useI18NextIsReady();
  const { viewer } = useViewerQuery();
  const token = useGetState("token");
  const isReleaseReady: boolean = i18nIsReady && (!!viewer || !token);
  const reloadOnce = React.useRef(false);
  const setNeedsUpdate = useSetState("needsUpdate");
  const location = useLocation();
  const isAssistantOpen = location.hash === ASSISTANT_HASH;

  React.useEffect(() => {
    if (releaseId !== releaseIdPrev) {
      setStoragedJsVersion(releaseId);
    }
  }, [releaseId, releaseIdPrev]);

  // releaseIdPrev === "" - it is empty on first load in browser, so it's the latest version
  // releaseIdPrev.indexOf("anticache-") === 0 - it is anticache on first load as an app, so it's the latest version
  const isLatest = releaseIdPrev === "" || releaseIdPrev.indexOf("anticache-") === 0 || releaseId === releaseIdPrev;
  if (!isLatest) {
    needsUpdate.current = true;
    console.log(`Detected new releaseId, setting reload flag (old: ${releaseIdPrev}, new: ${releaseId})`);
  }

  const isReloading = needsUpdate.current && mainRoute && !isAssistantOpen;
  if (isReloading && !reloadOnce.current) {
    reloadOnce.current = true;
    windowReloadLimited(() => {}, {
      reason: "detected new releaseId - this should fade out once all users will update their releaseId in Redux"
    });
  }

  React.useEffect(() => {
    if (!isLatest) {
      setNeedsUpdate(true);
      deleteJsStorage();
    }
  });

  if (!isReleaseReady || isReloading) {
    return <Splash />;
  } else {
    return <>{children}</>;
  }
};

export default ReleaseUpdater;
