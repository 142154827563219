import React from "react";
import { useNetworkState } from "react-use";
import styled from "styled-components/macro";
import SignalWifiStatusbarConnectedNoInternet4Icon from "@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4";
import { devicePaddings } from "tools/device";

const { top } = devicePaddings();

const Wrap = styled.div`
  height: 100%;
`;
const NoNetwork = styled(SignalWifiStatusbarConnectedNoInternet4Icon)`
  color: #aaa;
  font-size: 12px;
  position: absolute;
  right: 5px;
  top: ${top + 5}px;
`;

const LayoutWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const network = useNetworkState();

  return (
    <Wrap>
      {/* 
        Pro interní sledování jak moc nefunguje ten indikátor network.online
         - zajímá mě hlavně jestli když se náhodou při startu tento indikátor objeví, tak jestli zmizí, nebo zůstane viset a nedá se tím pádem na něj moc spoléhat
         - do budoucna budeme chtít mít nějaký hook podle kterého s jistotou budeme vědět jestli jsme/nejsme online (například při tts načítání)
      */}
      {!network.online && <NoNetwork />}

      {children}
    </Wrap>
  );
  // return <Wrap><Maintenance /></Wrap>;
};

export default LayoutWrapper;
