import React from "react";
import styled from "styled-components/macro";
import CircularProgress from "@mui/material/CircularProgress";

const Wrap = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  size?: string | number;
  className?: string;
};

const Loader: React.FC<Props> = ({ size, className }) => {
  return (
    <Wrap className={className}>
      <CircularProgress size={size} />
    </Wrap>
  );
};

export default Loader;
