/**
 * @generated SignedSource<<9b5463287fd3d3116f4a4f1bd500faf5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Difficulty = "beginner" | "easy" | "hard" | "moderate" | "%future added value";
export type Kind = "article" | "set" | "video" | "%future added value";
export type recommendedSourcesQuery$variables = {
  deckId: string;
  langNative: string;
};
export type recommendedSourcesQuery$data = {
  readonly recommendedSources: ReadonlyArray<{
    readonly avgRating: number | null;
    readonly counts: {
      readonly theories: number;
      readonly total: number;
    } | null;
    readonly course: boolean;
    readonly difficulty: Difficulty;
    readonly id: string;
    readonly image: {
      readonly authorApiName: string;
      readonly authorName: string;
      readonly url: string;
    } | null;
    readonly kind: Kind;
    readonly mine: boolean;
    readonly name: string;
    readonly private: boolean;
    readonly rating: {
      readonly continueFrom: number | null;
      readonly dismissed: number;
      readonly finished: number | null;
      readonly picked: number;
      readonly rating: number | null;
    } | null;
    readonly source: string | null;
    readonly textLength: number;
    readonly top: number | null;
    readonly uri: string;
    readonly videoDuration: number | null;
  }>;
};
export type recommendedSourcesQuery = {
  response: recommendedSourcesQuery$data;
  variables: recommendedSourcesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deckId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "langNative"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "deckId",
    "variableName": "deckId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uri",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "langNative",
      "variableName": "langNative"
    }
  ],
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "course",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "private",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mine",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "difficulty",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoDuration",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "textLength",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "top",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avgRating",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authorName",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authorApiName",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rating",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "continueFrom",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "picked",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dismissed",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finished",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "SCounts",
  "kind": "LinkedField",
  "name": "counts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theories",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "recommendedSourcesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "recommendedSources",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Rating",
            "kind": "LinkedField",
            "name": "rating",
            "plural": false,
            "selections": [
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/)
            ],
            "storageKey": null
          },
          (v23/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "recommendedSourcesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "recommendedSources",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Rating",
            "kind": "LinkedField",
            "name": "rating",
            "plural": false,
            "selections": [
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v23/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1ed6b096f62cd3d73ccbcaa5e54adfc5",
    "id": null,
    "metadata": {},
    "name": "recommendedSourcesQuery",
    "operationKind": "query",
    "text": "query recommendedSourcesQuery(\n  $deckId: String!\n  $langNative: String!\n) {\n  recommendedSources(deckId: $deckId) {\n    id\n    uri\n    name(langNative: $langNative)\n    kind\n    course\n    private\n    mine\n    difficulty\n    source\n    videoDuration\n    textLength\n    top\n    avgRating\n    image {\n      url\n      authorName\n      authorApiName\n      id\n    }\n    rating {\n      rating\n      continueFrom\n      picked\n      dismissed\n      finished\n      id\n    }\n    counts {\n      total\n      theories\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4a0afa8b8f99b2305ae9405b778b6a28";

export default node;
