import React from "react";
import styled from "styled-components/macro";
import Row from "components/other/Row";
import { useSourceQuery } from "queries/sources/sourceQuery";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const exportTotxt = (text: string, filename: string) => {
  var a = document.createElement("a");
  a.download = filename;
  a.href = "data:application/json;charset=utf-8;," + encodeURIComponent(text);
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const SRow = styled(Row)`
  margin-top: 20px;
`;

type Props = {};

const ExportRow: React.FC<Props> = () => {
  const app = useSourceQuery("app_translates", { back: "es" });
  const mail = useSourceQuery("mail_translates", { back: "es" });

  const appCS = useSourceQuery("app_translates", { back: "cs" });
  const mailCS = useSourceQuery("mail_translates", { back: "cs" });

  let text = "";
  let textCS = "";
  let appValues: string[] = [];
  let appValuesCS: string[] = [];

  const firstReviewedApi = [...(app?.sCards || [])].reverse().findIndex((c) => !c.back?.translated);
  if (firstReviewedApi) {
    appValues = app?.sCards.slice(-firstReviewedApi).map((c) => c.front) || [];
    text += appValues?.join("\n") || "";
    const appValuesCS = appCS?.sCards.slice(-firstReviewedApi).map((c) => c.back?.value);
    textCS += appValuesCS?.join("\n") || "";
  }

  const firstReviewedMail = [...(mail?.sCards || [])].reverse().findIndex((c) => !c.back?.translated);
  if (firstReviewedMail > 0) {
    text +=
      mail?.sCards
        .slice(-firstReviewedMail)
        .map((c) => c.front)
        .filter((v) => !appValues?.includes(v))
        .join("\n") || "";
    textCS +=
      mailCS?.sCards
        .slice(-firstReviewedMail)
        .map((c) => c.back!.value)
        .filter((v) => !appValuesCS?.includes(v))
        .join("\n") || "";
  }

  return (
    <SRow
      onClick={() => {
        exportTotxt(text, "en.txt");
        exportTotxt(textCS, "cs.txt");
      }}
    >
      <FileUploadIcon /> Export
    </SRow>
  );
};

export default ExportRow;
