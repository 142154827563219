import { Button } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import { useMatch, useNavigate } from "react-router";
import styled from "styled-components/macro";
import DialogWindow from "../other/DialogWindow";
import { useViewerQuery, useViewerXps } from "../../queries/viewerQuery";
import { isChromeExtension } from "tools/device";
import PriceButton from "root/profile/subscription/PriceButton";
import { useMatchMain } from "root/RootRouter";
import Flames from "components/fire/Flames";
import { MAIN, PROFILE } from "../../root/RootRouter.config";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";

export const FLAMESMAX = 30;

const SFlames = styled(Flames)`
  margin: 35px 0 10px;
  transform: scale(1.2);
`;
const Strong = styled.strong`
  white-space: nowrap;
`;
const SButton = styled(Button)`
  margin: 15px 0 25px;
`;
const Italic = styled.p`
  font-style: italic;
  font-size: 80%;
  opacity: 0.5;
  line-height: 1.3em;
  position: relative;
  margin: -15px 0 15px;
`;

type Props = {};

const FlamesMaxDialog: React.FC<Props> = () => {
  const { xps } = useViewerXps();
  const { viewer, noPayments } = useViewerQuery();
  const navigate = useNavigate();
  const onMain = useMatchMain({ end: true });
  const onProfile = useMatch(PROFILE.url() + "/*");

  const reachedFlamesLimit = xps && xps.today - xps.todayExtra >= FLAMESMAX;

  const open = reachedFlamesLimit && viewer && !noPayments() && !viewer.subscription && !onMain && !onProfile;

  const handleClose = () => {
    navigate(MAIN.url(), { replace: true });
  };

  return (
    <DialogWindow open={!!open} onClose={handleClose} title={<SFlames strength={4} xps={FLAMESMAX} />}>
      <p>
        <Trans>
          You have reached the free version's daily limit of <Strong>{String(FLAMESMAX)} flames</Strong>
        </Trans>{" "}
        {!!xps?.todayExtra && "*"}
      </p>

      {!!xps?.todayExtra && (
        <Italic>
          * <Trans>We don't count flames from quest rewards</Trans>
        </Italic>
      )}

      <Trans parent="p">Subscribe to continue learning today or come back tomorrow.</Trans>

      {isChromeExtension() ? (
        <PriceButton notInBrowser />
      ) : (
        <>
          <SButton color="primary" variant="contained" onClick={() => navigate(SUBSCRIPTION.url())}>
            <Trans>Show me options</Trans>
          </SButton>
        </>
      )}
    </DialogWindow>
  );
};

export default FlamesMaxDialog;
