import { graphql } from "babel-plugin-relay/macro";

export const SortSCardQL = graphql`
  mutation sortSCardMutation($id: ID!, $sort: Int!, $backLang: String) {
    sortSCard(id: $id, sort: $sort) {
      sCards(backLang: $backLang) {
        id
        front
        hint
        back {
          value
          translated
        }
      }
    }
  }
`;
