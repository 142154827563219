import React from "react";
import { Stack } from "@mui/material";
import LinkExt from "components/other/LinkExt";
import FacebookIcon from "@mui/icons-material/Facebook";
import styled from "styled-components/macro";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";

const Icon = styled.span`
  margin: 0 15px;

  svg {
    font-size: 50px;
  }
`;

type Props = {
  url?: string;
  text?: string;
  hashtags?: string;
  className?: string;
};
const ShareButtons: React.FC<Props> = ({ url, text, hashtags, className }) => {
  url = url || "";
  text = text || "";
  hashtags = hashtags || "";

  return (
    <Stack direction="row" className={className} justifyContent="center" alignItems="center">
      <Icon>
        <LinkExt href={`https://www.facebook.com/sharer.php?u=${url}&quote=${text}`}>
          <FacebookIcon />
        </LinkExt>
      </Icon>
      <Icon>
        <LinkExt href={`https://twitter.com/intent/tweet?text=${text}&url=${url}&hashtags=${hashtags}`}>
          <TwitterIcon />
        </LinkExt>
      </Icon>
      <Icon>
        <LinkExt href={`mailto:?&body=${text}\n${url}`}>
          <EmailIcon />
        </LinkExt>
      </Icon>
    </Stack>
  );
};

export default ShareButtons;
