import { Button, Checkbox, Radio } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import LinkExt from "../../../../components/other/LinkExt";
import Row from "../../../../components/other/Row";
import { publicCheck, useEditSourceMutation } from "../../../../queries/sources/editSource";
import { sourceEditQuery } from "../__generated__/sourceEditQuery.graphql";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SingleRow from "./SingleRow";
import { useToggle } from "react-use";

const Wrap = styled.div`
  padding: 20px 18px 15px;
`;
const RadioGroup = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 1px 1px 8px #0002;
`;
const SButton = styled(Button)`
  margin: 18px 10px 8px;
  width: calc(100% - 20px);
`;
const SRow = styled(Row)`
  padding: 18px 10px;
`;
const Small = styled.div`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  color: ${({ theme }) => theme.duo.color.textGrey};
  line-height: 1.3em;
`;
const CheckLabel = styled.label`
  display: flex;
  align-items: center;
  margin: -6px 0 0;
  background: white;
  padding: 0 14px 18px 18px;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.4em;
  cursor: pointer;
`;

type Props = {
  source: NonNullable<sourceEditQuery["response"]["node"]>;
};

const SourcePublish: React.FC<Props> = ({ source }) => {
  const [prvt, setPrivate] = React.useState<boolean>(!!source.private);
  const { t } = useTranslation();
  const [agreed, setAgreed] = React.useState(source.private === false);
  const [open, toggleOpen] = useToggle(false);
  const editSource = useEditSourceMutation(() => toggleOpen());

  const handlePublish = () => {
    const updates = { id: source.id as string, private: prvt };
    let checkRes;
    if (prvt === false && source && (checkRes = publicCheck({ ...source, ...updates }, t))) {
      return alert(checkRes);
    }
    if (prvt && agreed) {
      setAgreed(false);
    }
    editSource(updates);
  };

  return (
    <>
      <SingleRow source={source} isOpen={open} onClick={toggleOpen} icon={<VisibilityIcon />}>
        <Trans parent="strong">Visibility</Trans> <br />
        {source.private ? <Trans>Private</Trans> : <Trans>Public</Trans>}
      </SingleRow>
      {open && (
        <Wrap>
          <RadioGroup>
            <SRow onClick={() => setPrivate(false)}>
              <Radio checked={prvt === false} color="primary" />
              <div>
                <Trans>Public</Trans>
                <Trans parent={Small}>Is placed in the public library and is reviewed by the community.</Trans>
              </div>
            </SRow>
            {prvt === false && (
              <CheckLabel>
                <Checkbox checked={agreed} onChange={() => setAgreed(!agreed)} color="primary" />
                <Trans parent="div">
                  I confirm the content is intended for the public and meets the{" "}
                  <LinkExt href="https://www.duocards.com/termsofuse.html">terms of use</LinkExt>
                </Trans>
              </CheckLabel>
            )}
            <SRow onClick={() => setPrivate(true)}>
              <Radio checked={prvt === true} color="primary" />
              <div>
                <Trans>Private</Trans>
                <Trans parent={Small}>Can be shared via link.</Trans>
              </div>
            </SRow>
          </RadioGroup>
          <SButton
            color="primary"
            variant="contained"
            onClick={handlePublish}
            disabled={prvt === undefined || (prvt === false && !agreed)}
          >
            <Trans>Save</Trans>
          </SButton>
        </Wrap>
      )}
    </>
  );
};

export default SourcePublish;
