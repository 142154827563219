import React from "react";
import styled from "styled-components/macro";
import { Trans } from "react-i18next";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";

const Wrap = styled.div`
  text-align: center;
`;

type Props = {};

const GrammarQuota: React.FC<Props> = () => {
  const navigate = useNavigate();

  return (
    <Wrap>
      <Trans parent="p">
        You have reached your daily quota for grammar. Come back tomorrow or subscribe for learning without limits!
      </Trans>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          navigate(SUBSCRIPTION.url());
        }}
      >
        <Trans>Subscribe</Trans>
      </Button>
    </Wrap>
  );
};

export default GrammarQuota;
