/**
 * @generated SignedSource<<00e3f0d9e8d5d5995b9d3c91bb54214b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CardsFilterInQuery$variables = {
  deckId: string;
  langNative?: string | null;
};
export type CardsFilterInQuery$data = {
  readonly deckSources: ReadonlyArray<{
    readonly source: {
      readonly counts: {
        readonly total: number;
      } | null;
      readonly id: string;
      readonly name: string;
      readonly rating: {
        readonly dismissed: number;
        readonly picked: number;
      } | null;
    } | null;
    readonly toLearn: number;
    readonly total: number;
  }>;
};
export type CardsFilterInQuery = {
  response: CardsFilterInQuery$data;
  variables: CardsFilterInQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deckId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "langNative"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "deckId",
    "variableName": "deckId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "toLearn",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "langNative",
      "variableName": "langNative"
    }
  ],
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "picked",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dismissed",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "SCounts",
  "kind": "LinkedField",
  "name": "counts",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CardsFilterInQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeckSource",
        "kind": "LinkedField",
        "name": "deckSources",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Source",
            "kind": "LinkedField",
            "name": "source",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Rating",
                "kind": "LinkedField",
                "name": "rating",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CardsFilterInQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeckSource",
        "kind": "LinkedField",
        "name": "deckSources",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Source",
            "kind": "LinkedField",
            "name": "source",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Rating",
                "kind": "LinkedField",
                "name": "rating",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f97f743ebfcaf289b411d6302b824ba2",
    "id": null,
    "metadata": {},
    "name": "CardsFilterInQuery",
    "operationKind": "query",
    "text": "query CardsFilterInQuery(\n  $deckId: ID!\n  $langNative: String\n) {\n  deckSources(deckId: $deckId) {\n    toLearn\n    total\n    source {\n      id\n      name(langNative: $langNative)\n      rating {\n        picked\n        dismissed\n        id\n      }\n      counts {\n        total\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c22ee998112658662a39a1d498022a2a";

export default node;
