import React from "react";
import styled from "styled-components/macro";
import { Button, TextField, InputAdornment, CircularProgress } from "@mui/material";
import DialogWindow from "../../../components/other/DialogWindow";
import SearchIcon from "@mui/icons-material/Search";
import { useDebounce } from "react-use";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { ImgPickerMutation } from "./__generated__/ImgPickerMutation.graphql";
import { imgHeaderFormat, unsplash } from "../../../tools/unsplash";
import { useTranslation } from "react-i18next";
import { sourceEditQuery } from "./__generated__/sourceEditQuery.graphql";
import { useEditSourceMutation } from "../../../queries/sources/editSource";
import Loader from "components/other/Loader";
import { devicePaddings } from "tools/device";

const { top } = devicePaddings();

export const ImgImg: React.FC<{ children: React.ReactNode; url?: string }> = ({ children, url }) => {
  return (
    <Wrap className="nofilter" style={url ? { backgroundImage: imgHeaderFormat(url) } : undefined}>
      {children}
    </Wrap>
  );
};

const setImageQL = graphql`
  mutation ImgPickerMutation($url: String!, $apiId: String!, $authorName: String!, $authorApiName: String!) {
    setImage(url: $url, apiId: $apiId, authorName: $authorName, authorApiName: $authorApiName)
  }
`;

const Wrap = styled.div`
  height: ${130 + top}px;
  padding-top: ${65 + top}px;
  text-align: center;
  background: #fafafa;
  background-position: center;
  background-size: cover;
  button  {
    color: white;
    font-weight: bold;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  }
`;
const SDialogWindow = styled(DialogWindow)`
  .MuiPaper-root {
    margin: 0;
  }
`;
const InDialog = styled.div`
  position: relative;
  height: 82vh;
  width: 90vw;
  max-width: 450px;
  overflow: auto;
  margin: 0 -14px -14px;
`;
const Loading = styled.div`
  background: #fff8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const Img = styled.div`
  width: calc(50% - 6px);
  height: 120px;
  margin: 3px;
  background-size: cover;
  background-position: center;
  float: left;
`;

type Props = {
  source?: sourceEditQuery["response"]["node"];
};

const ImgPicker: React.FC<Props> = ({ source }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [results, setResults] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [setImage] = useMutation<ImgPickerMutation>(setImageQL);
  const [url, setUrl] = React.useState(source?.image?.url);
  const [imageId, setImageId] = React.useState(source?.imageId);
  const [saving, setSaving] = React.useState(false);
  const editSource = useEditSourceMutation();

  React.useEffect(() => {
    if (source?.image?.url && !url) setUrl(source?.image?.url);
    if (source?.imageId && !imageId) setImageId(source?.imageId);
  }, [source, url, imageId]);

  React.useEffect(() => {
    if (open) {
      unsplash.photos.list({ page: 1, perPage: 30 }).then((json) => {
        setResults(json?.response?.results || []);
        setLoading(false);
      });
    }
  }, [open]);

  useDebounce(
    () => {
      if (search) {
        setLoading(true);
        unsplash.search.getPhotos({ query: search, page: 1, perPage: 30 }).then((json) => {
          setResults(json?.response?.results || []);
          setLoading(false);
        });
      }
    },
    600,
    [search]
  );

  const handlePick = (img) => {
    setImage({
      variables: {
        url: img.urls.raw,
        apiId: img.id,
        authorName: img.user.name,
        authorApiName: img.user.username
      },
      onCompleted: (res) => setImageId(res.setImage)
    });
    unsplash.photos.trackDownload({
      downloadLocation: img.links.download_location
    });
    setUrl(img.urls.raw);
    setOpen(false);
  };

  React.useEffect(() => {
    if (imageId && !saving && source && source.id && imageId !== source.imageId) {
      editSource({ id: source.id, imageId }).then(() => setSaving(false));
      setSaving(true);
    }
  }, [imageId, source, editSource, saving, setSaving]);

  return (
    <>
      <ImgImg url={url}>
        {saving ? (
          <Loader />
        ) : (
          <Button onClick={() => setOpen(true)} color="primary">
            {t("Pick Image")}
          </Button>
        )}
      </ImgImg>

      <SDialogWindow
        open={open}
        onClose={() => setOpen(false)}
        title={
          <TextField
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            autoFocus
            placeholder={t("Search") + " (in English)"}
          />
        }
      >
        <InDialog>
          {results.map((img) => (
            <Img key={img.id} style={{ backgroundImage: `url(${img.urls.thumb})` }} onClick={() => handlePick(img)} />
          ))}
          {loading && (
            <Loading>
              <CircularProgress />
            </Loading>
          )}
        </InDialog>
      </SDialogWindow>
    </>
  );
};

export default ImgPicker;
