import React from "react";
import styled from "styled-components/macro";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Trans, useTranslation } from "react-i18next";
import DialogWindow from "../../../components/other/DialogWindow";
import { TextField } from "../../../styled/TextField";
import { brown, brownDark, insetShadow } from "../../../components/Theory/theoryStyles";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { useSeeHint } from "../../../components/hint/Hint";
import { useViewerQuery } from "../../../queries/viewerQuery";

const THEORYHELP = "theoryHelp";

const TWrap = styled.div`
  position: relative;
`;
const THelp = styled.div`
  position: absolute;
  right: 5px;
  top: 6px;
  color: #0005;
  font-size: 11.5px;
  z-index: 1;
  svg {
    font-size: 15px;
  }
`;
const THelpWrap = styled.div`
  margin: -15px 5px 10px;
`;
const TheoryField = styled(TextField)`
  .MuiFilledInput-root {
    box-shadow: ${insetShadow};
  }
  .MuiFilledInput-underline:before {
    border-color: ${brown};
  }
`;
const Example = styled.div`
  margin: 5px -5px;
  padding: 7px 12px;
  text-align: left;
  border: 0.5px solid ${({ theme }) => theme.duo.color.textGrey};
  border-radius: 8px;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.4em;
  color: #555;
`;
const ButtonWrap = styled.div`
  display: flex;
`;
const TheoryButton = styled.div`
  display: flex;
  margin: -30px 0 10px auto;
  color: ${brownDark} !important;
  background: white;
  border: none;
  font-size: 11px;
  align-items: center;
  border-radius: 5px;
  padding: 2px 9px;
  cursor: pointer;
  box-shadow: 0.5px 0.5px 2px #0001;
  svg {
    font-size: 12px;
  }
`;

type Props = {
  values: { id?: string; theory?: string | null };
  onValuesChange: (values: { theory?: string }) => void;
};

const FormTheory: React.FC<Props> = ({ values, onValuesChange }) => {
  const { hintWasSeen } = useViewerQuery();
  const [openTHelp, setOpenThelp] = React.useState(false);
  const [openTheory, setOpenTheory] = React.useState(false);
  const seeHint = useSeeHint();
  const { t } = useTranslation();

  return (
    <>
      {(openTheory || (values.theory && !values.id)) && (
        <>
          <>
            <TWrap>
              <THelp>
                <HelpOutlineIcon onClick={() => setOpenThelp(true)} /> &nbsp;
                <DeleteForeverOutlinedIcon
                  onClick={() => {
                    setOpenTheory(false);
                    onValuesChange({ theory: undefined });
                  }}
                />
              </THelp>
              <DialogWindow
                open={openTHelp}
                onClose={() => {
                  setOpenThelp(false);
                  seeHint(THEORYHELP);
                }}
              >
                <THelpWrap>
                  <Trans parent="p">Theory should complement the curriculum.</Trans>
                  <Trans parent="p">
                    It's in english, so it can be easily translated to any other students' native language. Students
                    will decide in which language they want to see the theory.
                  </Trans>
                  <Trans parent="p">
                    Use "double quotes" for example phrases in target language and *stars* for bold text.
                  </Trans>
                  <Trans>Example</Trans>:
                  <Example>We use "going to" when we have *already decided* to do something.</Example>
                </THelpWrap>
              </DialogWindow>
            </TWrap>
            <TheoryField
              multiline
              autoFocus={!values.theory}
              name="theory"
              label={`${t("Theory")} (English)`}
              value={values.theory || ""}
              onChange={(e) =>
                onValuesChange({
                  theory: e.currentTarget.value
                })
              }
              inputProps={{ maxLength: 1000 }}
            />
          </>
        </>
      )}

      {!(openTheory || values.theory) && (
        <ButtonWrap>
          <TheoryButton
            onClick={(e) => {
              setOpenTheory(true);
              if (!hintWasSeen(THEORYHELP)) setOpenThelp(true);
              e.preventDefault();
            }}
          >
            <AssignmentIcon /> &nbsp;<Trans>Theory</Trans>
          </TheoryButton>
        </ButtonWrap>
      )}
    </>
  );
};

export default FormTheory;
