import React from "react";
import styled from "styled-components/macro";

const Svg = styled.svg`
  width: 27px;
  height: 27px;
  position: absolute;
  left: 112px;
  top: 115px;
`;

type Props = { className?: string };
const Frog: React.FC<Props> = ({ className }) => {
  return (
    <Svg
      className={`${className ? className : ""} frogSpecialClass`}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      enableBackground="new 0 0 512 512"
    >
      <path
        fill="#6DC82A"
        d="M215.452,269.943c-14.322-68.228-73.458-113.561-132.085-101.254
    c-58.626,12.307-94.542,77.593-80.22,145.821c8.84,42.113,34.757,75.494,67.085,91.992H46.174c-2.496-5.63-8.123-9.563-14.678-9.563
    c-8.869,0-16.059,7.189-16.059,16.059c0,8.87,7.189,16.059,16.059,16.059c0.141,0,0.279-0.018,0.42-0.021
    c0.139,0.006,0.276,0.021,0.417,0.021h127.405C164.786,429.056,227.578,327.708,215.452,269.943z"
      ></path>
      <path
        fill="#5EAC24"
        d="M215.452,269.943c-14.322-68.228-73.458-113.56-132.085-101.254
    c-6.322,1.327-12.378,3.273-18.137,5.77c7.848,73.413,24.565,175.477,59.722,246.921c-0.631,0.385-1.278,0.782-1.945,1.19
    c-3.77,2.307-7.462,4.591-10.51,6.486h47.241C164.786,429.056,227.578,327.708,215.452,269.943z"
      ></path>
      <path
        fill="#6DC82A"
        d="M508.853,314.508c14.322-68.228-21.593-133.514-80.22-145.821
    c-58.626-12.306-117.763,33.027-132.085,101.254c-12.126,57.766,50.665,159.113,55.714,159.113h127.405
    c0.14,0,0.277-0.015,0.417-0.021c0.141,0.003,0.278,0.021,0.42,0.021c8.868,0,16.059-7.189,16.059-16.059
    c0-8.868-7.189-16.059-16.059-16.059c-6.555,0-12.183,3.933-14.678,9.563h-24.058C474.096,390.003,500.013,356.621,508.853,314.508z
    "
      ></path>
      <path
        fill="#5EAC24"
        d="M296.547,269.943c14.322-68.228,73.458-113.56,132.085-101.254c6.322,1.327,12.378,3.273,18.137,5.77
    c-7.848,73.413-24.565,175.477-59.722,246.921c0.631,0.385,1.278,0.782,1.945,1.19c3.77,2.307,7.462,4.591,10.51,6.486H352.26
    C347.214,429.056,284.421,327.708,296.547,269.943z"
      ></path>
      <polygon fill="#4E901E" points="382.012,184.653 142.377,184.653 173.781,429.056 347.118,429.056 "></polygon>
      <path
        fill="#91DC5A"
        d="M358.904,429.521c69.425-124.756,73.661-347.258,73.661-347.258c0-35.871-29.079-64.95-64.95-64.95
    c-34.72,0-63.075,27.244-64.859,61.521h-93.513c-1.784-34.277-30.139-61.521-64.859-61.521c-35.871,0-64.95,29.079-64.95,64.95
    c0,0,6.782,234.078,73.661,347.258c0-1.287-36.831,21.865-36.831,21.865h41.975l-7.717,27.009l34.726-15.434l6.431,23.15
    l26.153-43.21c0,0,2.704-4.377,2.767-6.52c0.052-1.798-1.335-4.722-1.335-4.722c-12.03-34.578-70.849-218.209,36.748-218.209
    c110.305,0,49.753,182.381,36.935,217.846c0,0-1.557,3.907-1.557,5.086c0,2.196,2.785,6.535,2.785,6.535l26.144,43.194l6.431-23.15
    l34.726,15.434l-7.717-27.009h41.975C395.734,451.384,358.904,428.234,358.904,429.521z"
      ></path>
      <g>
        <circle fill="#5EAC24" cx="118.372" cy="450.961" r="8.574"></circle>
        <circle fill="#5EAC24" cx="150.949" cy="477.54" r="8.574"></circle>
        <circle fill="#5EAC24" cx="191.25" cy="486.113" r="8.574"></circle>
        <circle fill="#5EAC24" cx="319.924" cy="486.113" r="8.574"></circle>
        <circle fill="#5EAC24" cx="361.513" cy="477.54" r="8.574"></circle>
        <circle fill="#5EAC24" cx="395.45" cy="450.961" r="8.574"></circle>
      </g>
      <circle fill="#6DC82A" cx="144.469" cy="86.956" r="48.498"></circle>
      <path
        fill="#5EAC24"
        d="M144.471,124.986c-20.972,0-38.034-17.062-38.034-38.034s17.062-38.034,38.034-38.034
    s38.034,17.062,38.034,38.034S165.443,124.986,144.471,124.986z"
      ></path>
      <path
        fill="#57565C"
        d="M167.85,92.884h-46.758c-3.263,0-5.932-2.669-5.932-5.932l0,0c0-3.263,2.669-5.932,5.932-5.932
    h46.758c3.263,0,5.932,2.669,5.932,5.932l0,0C173.781,90.215,171.112,92.884,167.85,92.884z"
      ></path>
      <circle fill="#6DC82A" cx="367.459" cy="86.956" r="48.498"></circle>
      <path
        fill="#5EAC24"
        d="M367.462,124.986c-20.972,0-38.034-17.062-38.034-38.034s17.062-38.034,38.034-38.034
    c20.972,0,38.034,17.062,38.034,38.034S388.434,124.986,367.462,124.986z"
      ></path>
      <path
        fill="#57565C"
        d="M390.841,92.884h-46.758c-3.263,0-5.932-2.669-5.932-5.932l0,0c0-3.263,2.669-5.932,5.932-5.932
    h46.758c3.263,0,5.932,2.669,5.932,5.932l0,0C396.773,90.215,394.103,92.884,390.841,92.884z"
      ></path>
      <path
        fill="#6DC82A"
        d="M145.416,187.249c-7.534-0.085-21.957-4.358-23.258-19.922c-0.361-4.321,2.849-8.117,7.171-8.478
    c4.319-0.356,8.116,2.849,8.478,7.17c0.406,4.863,5.942,5.463,7.721,5.528H366.47c1.781-0.066,7.316-0.665,7.721-5.527
    c0.361-4.321,4.161-7.547,8.478-7.171c4.32,0.361,7.531,4.156,7.171,8.477c-1.3,15.565-15.724,19.838-23.168,19.923H145.416z"
      ></path>
      <g>
        <path
          fill="#5EAC24"
          d="M452.791,366.731c-1.875,0-3.755-0.667-5.256-2.021c-3.22-2.904-3.475-7.869-0.571-11.089
      c3.347-3.71,6.453-7.785,9.233-12.112c2.345-3.649,7.201-4.706,10.849-2.362c3.648,2.343,4.705,7.201,2.362,10.848
      c-3.24,5.042-6.868,9.8-10.784,14.142C457.073,365.857,454.936,366.731,452.791,366.731z"
        ></path>
        <path
          fill="#5EAC24"
          d="M478.126,315.91c-0.535,0-1.077-0.055-1.622-0.17c-4.244-0.891-6.961-5.053-6.07-9.297
      c4.826-22.989,2.111-46.082-7.643-65.025c-1.985-3.855-0.469-8.589,3.385-10.575c3.856-1.985,8.591-0.468,10.575,3.385
      c11.401,22.14,14.613,48.932,9.05,75.44C485.025,313.369,481.761,315.91,478.126,315.91z"
        ></path>
      </g>
    </Svg>
  );
};

export default Frog;
