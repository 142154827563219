import React from "react";
import styled from "styled-components/macro";
import InternAdSubscription from "./InternAdOverview";
import InternAdDoYouEnjoy from "./InternAdDoYouEnjoy";
import { shuffle } from "lodash";
import { useCounter, usePrevious } from "react-use";
import InternAdSets from "./InternAdSets";
import InternAdMotivation from "./InternAdMotivation";
import { useWriteAdName } from "./writeAdName";
import InternAdBlack from "./InternAdBlack";
import InternAdSunset from "./InternAdSunset";
import InternAdFlames from "./InternAdFlames";

const ads = [
  { name: "overview", Ad: InternAdSubscription },
  { name: "doYouEnjoy", Ad: InternAdDoYouEnjoy },
  { name: "sets", Ad: InternAdSets },
  { name: "motivation", Ad: InternAdMotivation },
  { name: "black", Ad: InternAdBlack },
  { name: "sunset", Ad: InternAdSunset },
  { name: "flames", Ad: InternAdFlames }
];

const Wrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: white;
`;

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

const InternalAds: React.FC<Props> = ({ open, onClose }) => {
  const adsOrdered = React.useMemo(() => shuffle(ads), []);

  const [adsCounter, { inc: incAdsCounter }] = useCounter();
  const prevOpen = usePrevious(open);
  const writeAdName = useWriteAdName();

  const adIndex = adsCounter % adsOrdered.length;
  const Ad = adsOrdered[adIndex].Ad;

  React.useEffect(() => {
    if (open && !prevOpen) {
      writeAdName(adsOrdered[adIndex].name);
    }
  }, [open, prevOpen, writeAdName, adsOrdered, adIndex]);

  const handleClose = () => {
    incAdsCounter();
    onClose();
  };

  if (!open) return null;

  return (
    <Wrap>
      <Ad onClose={() => handleClose()} />
    </Wrap>
  );
};

export default InternalAds;
