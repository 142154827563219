import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useNavigate, useLocation } from "react-router";
import { useMutation } from "relay-hooks";
import { useTranslation } from "react-i18next";
import { UnsubscribeMutation } from "./__generated__/UnsubscribeMutation.graphql";
import { snackbar } from "tools/events";

const UnsubscribeQL = graphql`
  mutation UnsubscribeMutation($id: String!) {
    unsubscribeUser(id: $id) {
      id
    }
  }
`;

type Props = {};

const Unsubscribe: React.FC<Props> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const queryParam = new URLSearchParams(location.search);
  const unsubscribeId = queryParam.get("unsubscribe");

  const [mutate, { loading }] = useMutation<UnsubscribeMutation>(UnsubscribeQL, {
    onCompleted: () => snackbar(t("You were unsubscribed"))
  });

  React.useEffect(() => {
    if (unsubscribeId && !loading) {
      mutate({ variables: { id: unsubscribeId } });
      navigate(location.pathname, { replace: true });
    }
  }, [mutate, unsubscribeId, loading, location, navigate]);

  return null;
};

export default Unsubscribe;
