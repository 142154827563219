import { graphql } from "babel-plugin-relay/macro";

export const EditTheories = graphql`
  mutation theoryEditMutation(
    $id: ID!
    $theory: String
    $theoryNative: String
    $backLang: String
    $langNative: String
  ) {
    editTheories(id: $id, theory: $theory, theoryNative: $theoryNative, backLang: $backLang) {
      id
      front
      hint
      back(langNative: $langNative) {
        value
        translated
      }
      theory(langNative: $langNative) {
        sCardId
        theory
        theoryIsChecked
        theoryNative
        theoryNativeIsChecked
        theoryEn
        lang
        langNative
        canEdit
      }
      svg {
        flatId
        url
      }
    }
  }
`;
