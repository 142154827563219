import { Button } from "@mui/material";
import { setInviteDialog } from "components/ReduxProvider";
import React from "react";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { VERIFYTRANS } from "root/other/Verify";
import styled from "styled-components/macro";
import { VERIFY } from "../RootRouter.config";

const SVerifyButton = styled(Button)`
  color: #f44336;
  border-color: #f44336;
  box-shadow: 0 0 8px #f4433666;
  margin: 10px 0;
`;

type Props = {};

const VerifyButton: React.FC<Props> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <SVerifyButton
      variant="outlined"
      onClick={(e) => {
        e.stopPropagation();
        navigate(VERIFY.url());
        dispatch(setInviteDialog(false));
      }}
    >
      <Trans>{VERIFYTRANS}</Trans>
    </SVerifyButton>
  );
};

export default VerifyButton;
