import React from "react";
import * as Sentry from "@sentry/browser";
import { useEffectOnce } from "react-use";

export const useInstallReferrer = () => {
  const [installReferrer, setInstallReferrer] = React.useState<string>("");

  useEffectOnce(() => {
    try {
      if (window.cordova?.plugins?.referrer?.get) {
        window.cordova.plugins.referrer
          .get()
          .then((referrer) => {
            if (referrer?.referrer) {
              setInstallReferrer(referrer.referrer);
            }
          })
          .catch((error) => {
            if (error?.error) {
              // error codes: https://github.com/byorty/cordova-install-referrer-api#error-codes
              setInstallReferrer(`error code: ${error.error}`);
            }
          });
      }
    } catch (e) {
      Sentry.captureException(new Error(`Cordova install referrer plugin call failed.`));
    }
  });

  return installReferrer;
};
