import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";
import DialogWindow from "../../../components/other/DialogWindow";
import UploadIcon from "@mui/icons-material/Upload";
import HintTooltip from "components/other/HintTooltip";
import { CATEGORYHINT } from "./FormMore";
import { useViewerQuery } from "queries/viewerQuery";

const Wrap = styled.div``;
const Butt = styled.a`
  font-size: 11px;
  color: ${({ theme }) => theme.duo.color.primary}55;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const SUploadIcon = styled(UploadIcon)`
  margin: 5px 0 -8px;
  font-size: 18px;
`;
const UploadTitleIcon = styled(UploadIcon)`
  font-size: 30px;
  margin: 18px 0 10px;
  color: ${({ theme }) => theme.duo.color.primary}aa;
`;

type Props = {};

const BatchImport: React.FC<Props> = () => {
  const [open, setOpen] = React.useState(false);
  const { hintWasSeen } = useViewerQuery();
  const categorySeenOnMount = React.useMemo(() => hintWasSeen(CATEGORYHINT), []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrap>
      <HintTooltip
        name="import"
        title={`Import the cards, simply by copy and paste the text into the first input.`}
        canOpen={categorySeenOnMount}
      >
        <Butt onClick={() => setOpen(true)}>
          <SUploadIcon />
          <Trans parent="span">Import</Trans>
        </Butt>
      </HintTooltip>

      <DialogWindow open={open} onClose={() => setOpen(false)} title={<UploadTitleIcon />}>
        <Trans parent="strong">Import the cards, simply by copy and paste the text into the first input.</Trans>
        <Trans parent="p">One line -&gt; one card</Trans>
      </DialogWindow>
    </Wrap>
  );
};

export default BatchImport;
