import { CardState } from "../../../queries/cards/__generated__/cardsQuery.graphql";
import { CardStateEnum } from "../../../sharedJs__generated/constants";

export const getCardStates = (
  t: (key: string) => string
): {
  label: string;
  value: CardState | null;
}[] => {
  return [
    {
      value: null,
      label: t("All")
    },
    {
      value: CardStateEnum.toLearn,
      label: t("To learn")
    },
    {
      value: CardStateEnum.known,
      label: t("Known")
    },
    {
      value: CardStateEnum.learned,
      label: t("Learned")
    }
  ];
};
