import React from "react";
import styled from "styled-components/macro";
import AddIcon from "@mui/icons-material/Add";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { useViewerQuery } from "queries/viewerQuery";
import { Trans } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import LibraryList from "./LibraryList";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LibraryLine from "./LibraryLine";
import { LibTitle } from "./styled";
import { useDebounce } from "react-use";

import { LIBRARY } from "../../RootRouter.config";
import { LibrarySources } from "queries/sources/libraryQuery";

const Wrap = styled.div<{ expanded: boolean; collapsed: boolean }>`
  opacity: ${({ collapsed }) => (collapsed ? 0 : 1)};
  max-height: ${({ expanded, collapsed }) =>
    // 1px => makes smoother transitioning because the component won't render from scratch
    expanded ? "auto" : collapsed ? 1 : 300}px;
  transition:
    max-height 400ms,
    opacity ${({ collapsed }) => (collapsed ? 300 : 800)}ms ease-in;
  overflow: hidden;
`;
const Bar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 10px 3px 0;
`;
const SChevronRightIcon = styled(ChevronRightIcon)<{ $active?: boolean }>`
  font-size: 20px;
  transform: rotate(${({ $active }) => ($active ? 90 : 0)}deg);
  transition: transform 300ms;
`;
const Right = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;
const QuickAdd = styled(Link)`
  color: ${({ theme }) => theme.duo.color.text};
  font-size: 15px;
  display: flex;
  align-items: center;
  padding: 6px;

  svg {
    font-size: 18px;
    margin-right: 2px;
  }
`;

type Props = {
  title: React.ReactNode;
  right?: React.ReactNode;
  onClick?: VoidFunction;
  sources?: LibrarySources;
  disabled?: boolean;
  id?: string;
  addNewLink?: string;
  expandOrCollapse?: boolean | null; // true = expanded list-view | false = collapse/hide | null/undefined = line-view
  isLatestVisited?: boolean;
};

const LibraryRow: React.FC<Props> = ({
  title,
  onClick: onClickRaw,
  sources,
  right,
  disabled,
  addNewLink,
  id,
  expandOrCollapse,
  isLatestVisited
}) => {
  const { hintWasSeen, viewer } = useViewerQuery();
  const [debouncedExOrCo, setDebouncedExOrCo] = React.useState(expandOrCollapse);
  useDebounce(() => setDebouncedExOrCo(expandOrCollapse), 200, [expandOrCollapse]);
  const [searchParams] = useSearchParams();
  const isSearching = searchParams.get(LIBRARY.searchParam);

  if (isSearching && !sources?.data?.sources.edges?.length) {
    return null;
  }

  const onClick = (sources?.data?.sources?.edges?.length || 0) > 2 ? onClickRaw : undefined;

  // keeping expanded (List) during transition for smooth scroll-recover
  const expanded = expandOrCollapse === true || debouncedExOrCo === true;

  return (
    <Wrap id={id} expanded={expanded} collapsed={expandOrCollapse === false}>
      <Bar>
        <LibTitle onClick={onClick}>
          {title} {onClick && <SChevronRightIcon $active={expandOrCollapse === true} />}
        </LibTitle>
        {(right || addNewLink) && (
          <Right>
            {right}
            {addNewLink && viewer && hintWasSeen(TUTORIALDONE) && !isSearching && (
              <QuickAdd to={addNewLink}>
                <AddIcon />
                <Trans>Add</Trans>
              </QuickAdd>
            )}
          </Right>
        )}
      </Bar>
      {expanded ? (
        <LibraryList sources={sources} isLatestVisited={isLatestVisited} />
      ) : (
        <LibraryLine sources={sources} disabled={disabled} isLatestVisited={isLatestVisited} />
      )}
    </Wrap>
  );
};

export default LibraryRow;
