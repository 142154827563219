import { isEqual } from "lodash";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled, { keyframes, css } from "styled-components/macro";
import { Button, Fade } from "@mui/material";
import { Portal } from "@mui/base";
import { TutorialFC } from "../TutorialContent";
import TutorialPointer from "../TutorialPointer";
import { CAPTIONSID } from "root/library/source/video/Captions";
import { usePrevious, useDebounce, useCounter } from "react-use";
import { useLocation, useNavigate } from "react-router";
import { SAVEWORDID, POPPEDWORDID } from "root/library/source/contextor/Word";
import LogoutIcon from "@mui/icons-material/Logout";
import { FIVEHINT } from "../../main/main/Main";
import { useSeeHint } from "../../../components/hint/Hint";
import { snackbar } from "tools/events";
import { useWindowSize } from "hooks/useWindowSize";
import useClickAway from "hooks/useClickAway";

const HOLDERID = "holderId";
export const VIDEO_TUTORIAL = "videoTutorial";

const longPulseKF = keyframes`
   0%, 100% {  transform: scale(1.3) translateY(5px);}
   30%, 70% { transform: scale(1) translateY(-5px); }
`;
const shakeKF = keyframes`
  10%, 90% {left: 2px; }
  25%, 75% {left: -4px;}
  50% {left: 4px;}
`;

const STutorialPointer = styled(TutorialPointer)`
  left: 40%;
  top: 57%;
  animation: ${longPulseKF} 2s ease-out infinite;
`;
const BackdropHolder = styled.div`
  pointer-events: none;
  position: absolute;
`;
const Strong = styled.span<{ shake: boolean }>`
  position: relative;
  font-weight: bold;
  ${({ shake }) =>
    shake &&
    css`
      animation: ${shakeKF} 500ms;
    `}
`;
const SButton = styled(Button)`
  bottom: -5px;
  left: 0;
  width: 100%;
  position: absolute;
  background: white;
  z-index: 2000;
`;

const Green = styled(Strong)`
  color: ${({ theme }) => theme.duo.color.green};
`;

export const LongTouch: TutorialFC = ({ setPosition, setVisibleId, position, visibleId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { width } = useWindowSize();
  const [holderCoords, setHolderCoords] = React.useState({
    top: 260,
    left: 5,
    width: width - 10,
    height: 240
  });
  const [pointerStyle, setPointerStyle] = React.useState<any>();
  const [shakeIt, setShakeIt] = React.useState(false);
  const [shakeIt2, setShakeIt2] = React.useState(false);
  const [clickCount, { inc: incClickCount }] = useCounter();
  const { t } = useTranslation();
  const seeHint = useSeeHint();

  const ref = React.useRef<any>();
  useClickAway(
    ref,
    () => {
      if (visibleId === POPPEDWORDID) {
        setShakeIt2(true);
      } else {
        setShakeIt(true);
        incClickCount();
      }
    },
    ["click"]
  );
  useDebounce(
    () => {
      shakeIt && setShakeIt(false);
      shakeIt2 && setShakeIt2(false);
    },
    500,
    [shakeIt, shakeIt2]
  );

  const prevHash = usePrevious(location.hash);
  React.useEffect(() => {
    if (location.hash !== `#first`) {
      navigate({ hash: `#first` });
    }
    if (prevHash === `#first` && location.hash !== `#first`) {
      setVisibleId(undefined);
    }
    const capt = document.getElementById(CAPTIONSID);
    if (capt) {
      const c = capt.getBoundingClientRect();
      setHolderCoords({
        top: c.top + 8,
        height: c.height - 150,
        left: c.left + 20,
        width: c.width - 40
      });
    }
  }, [location, navigate, prevHash, position, setPosition, setVisibleId]);

  React.useEffect(() => {
    if ((clickCount - 1) % 4 === 3)
      snackbar(t(`Hold your finger on a word instead of clicking on it.`), { severity: "warning" });
  }, [clickCount, t]);

  React.useEffect(() => {
    const iid = setInterval(() => {
      const capt = document.getElementById(SAVEWORDID);
      let newPointerStyle: any;
      if (capt) {
        setVisibleId(POPPEDWORDID);
        const c = capt.getBoundingClientRect();
        newPointerStyle = { top: c.top + 17, left: c.right - 40, zIndex: 1500 };
      } else {
        visibleId === POPPEDWORDID && setVisibleId(HOLDERID);
      }
      setPointerStyle((prev) => (!isEqual(prev, newPointerStyle) ? newPointerStyle : prev));
    }, 400);

    return () => clearInterval(iid);
  }, [visibleId, setVisibleId]);

  return (
    <>
      <Trans parent="p">
        <Strong shake={shakeIt}>Hold your finger</Strong> on a word and <Green shake={shakeIt2}>save</Green> it into
        your deck of cards.
      </Trans>
      {!visibleId && (
        <Button
          style={{ margin: "-4px 0 10px" }}
          onClick={() => {
            setVisibleId(HOLDERID);
            const el = document.getElementById(CAPTIONSID);
            el && (el.style.overflowY = "clip"); // disable scrolling
          }}
          color="primary"
          variant="contained"
        >
          OK
        </Button>
      )}

      {!!visibleId && (
        <>
          <Portal>
            <BackdropHolder ref={ref} id={HOLDERID} style={holderCoords} />
          </Portal>
          <STutorialPointer style={pointerStyle} />
          <Fade timeout={1500} in={clickCount > 3}>
            <SButton
              onClick={() => {
                seeHint(FIVEHINT);
                seeHint(VIDEO_TUTORIAL);
              }}
            >
              <LogoutIcon />
              <Trans parent="span">Skip this step</Trans>
            </SButton>
          </Fade>
        </>
      )}
    </>
  );
};

export default LongTouch;
