import React from "react";
import styled from "styled-components/macro";
import { IconButton } from "@mui/material";
import { useViewerDecks } from "queries/viewerQuery";
import { Kind } from "queries/sources/__generated__/sourceQuery.graphql";
import SourceView from "root/main/learn/SourceView";
import { useOpenCourseView } from "root/library/source/course/courseHooks";
import {
  faClapperboardPlay,
  faFileLines,
  faGraduationCap,
  faRectangleVerticalHistory
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SIconButton = styled(IconButton)<{ $mainScreenDesign: boolean; $isCourse: boolean; $fullOpacity?: boolean }>`
  svg {
    color: ${({ theme, $mainScreenDesign, $isCourse }) =>
      $mainScreenDesign ? "inherit" : $isCourse ? theme.duo.color.lightBlue : theme.duo.color.textGrey};
    opacity: ${({ $fullOpacity }) => ($fullOpacity ? "1" : "0.5")};
    width: 20px;
    height: 20px;
  }
`;
const CourseIcon = styled(FontAwesomeIcon)`
  transform: scale(1.1);
`;

type Props = {
  card: {
    id: string;
    source?: {
      kind: Kind;
      course: boolean;
    } | null;
    sourceId?: string | null;
    sCardId?: string | null;
  };
  fullOpacity?: boolean;
  mainScreenDesign?: boolean;
};
const CardSourceIcons: React.FC<Props> = ({ card, mainScreenDesign, fullOpacity }) => {
  const openCourseView = useOpenCourseView();
  const { deck } = useViewerDecks();
  const langNative: string | undefined = deck?.back || undefined;
  const sourceType: "course" | "manual" | Kind = card.source?.course
    ? "course"
    : card.source?.kind
      ? card.source?.kind
      : "manual";
  const [showSourceView, setShowSourceView] = React.useState(false);

  const handleClick = (e) => {
    e.stopPropagation();

    if (sourceType === "course" && deck?.back && card.sourceId && card.sCardId) {
      openCourseView(card.sourceId, deck.back, deck.front, card.sCardId);
    }

    if (["set", "video", "article"].includes(sourceType)) {
      setShowSourceView(true);
    }
  };

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  // don't show course icon if the source card has been already deleted from source
  if (sourceType === "course" && !card.sCardId) return null;

  return (
    <span onClick={handleStopPropagation}>
      <SIconButton
        $fullOpacity={fullOpacity}
        onClick={handleClick}
        $mainScreenDesign={!!mainScreenDesign}
        $isCourse={sourceType === "course"}
      >
        {sourceType === "video" && <FontAwesomeIcon icon={faClapperboardPlay} />}
        {sourceType === "article" && <FontAwesomeIcon icon={faFileLines} />}
        {sourceType === "course" && <CourseIcon icon={faGraduationCap} />}
        {sourceType === "set" && <FontAwesomeIcon icon={faRectangleVerticalHistory} />}
      </SIconButton>

      {showSourceView && (
        <SourceView
          sourceId={card.sourceId || undefined}
          langNative={langNative}
          onClose={() => setShowSourceView(false)}
        />
      )}
    </span>
  );
};

export default CardSourceIcons;
