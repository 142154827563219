import React from "react";
import styled from "styled-components/macro";
import DialogFullWindow from "../other/DialogFullWindow";
import CardForm from "../../root/main/card/CardForm";
import { CardValues } from "../ReduxProvider";
import { useCardEdit } from "../../queries/cards/cardEdit";
import { useViewerDecks } from "../../queries/viewerQuery";
import { useCardCreate } from "queries/cards/cardCreate";
import { Trans, useTranslation } from "react-i18next";
import { Checkbox, IconButton } from "@mui/material";
import { useQuery } from "relay-hooks";
import { CardSorterSourceQuery } from "../../root/main/card/__generated__/CardSorterSourceQuery.graphql";
import { sourceQL } from "../../root/main/card/CardSorter";
import { EVENT, snackbar } from "../../tools/events";
import { useEvent } from "react-use";
import { SEARCH, useSearchParamTools } from "tools/searchParams";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/pro-regular-svg-icons";
import { dispatchEvent } from "tools/events";

const Wrap = styled.div`
  padding: 25px 20px;
  position: relative;
`;
const CheckLabel = styled.label<{ synchronize: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  margin-top: -55px;
  font-size: 12px;
  line-height: 1.4em;
  cursor: pointer;
  opacity: ${({ synchronize }) => (synchronize ? 0.9 : 0.8)};
  color: ${({ theme, synchronize }) => (synchronize ? theme.duo.color.text : theme.duo.color.textGrey)};
  .MuiCheckbox-root {
    padding: 3px;
  }
`;
const SIconButton = styled(IconButton)`
  position: absolute;
  width: 40px;
  height: 40px;
  left: 10px;
  top: -35px;
  svg {
    width: 18px;
    height: 18px;
  }
`;

export type CardEditDialogDetail = CardValues;

const CardEditDialog: React.FC<{}> = (p) => {
  const [card, setCard] = React.useState<CardValues>();
  const cardEdit = useCardEdit();
  const { deck } = useViewerDecks();
  const { cardCreate } = useCardCreate();
  const { t } = useTranslation();
  const [synchronize, setSynchronize] = React.useState(true);
  const [formValues, setFormValues] = React.useState<CardValues>();
  const { navigateSearchParam, backSearchParam, getSearchParam } = useSearchParamTools();
  const handleOpen = (e: CustomEvent<CardEditDialogDetail>) => {
    setCard(e.detail);
    navigateSearchParam(SEARCH.editCard);
  };
  useEvent(EVENT.openCardEditDialog, handleOpen);

  const handleClose = () => {
    backSearchParam(SEARCH.editCard);
  };

  React.useEffect(() => {
    if (!getSearchParam(SEARCH.editCard) && card) {
      card?.cardEditCallback?.(); // used in contextor for deselect
      setCard(undefined);
      setFormValues(undefined);
    }
  }, [card, getSearchParam]);

  const { data: sourceData } = useQuery<CardSorterSourceQuery>(
    sourceQL,
    { sourceId: (formValues?.sourceId || card?.sourceId) as string, langNative: deck?.back },
    { skip: !card?.sourceId || !card?.sCardId }
  );

  const handleSave = (values: CardValues) => {
    if (!deck) return;

    if (values.id) {
      cardEdit({
        ...values,
        langBack: deck.back,
        synchronize: sourceData?.node?.mine ? synchronize : false
      } as CardValues & { id: string }).then(() => {
        snackbar(t("The card was edited"), { bottom: true, autoHide: 2000 });
      });
    } else {
      cardCreate({ ...values, langBack: deck.back, deckId: deck.id });
      snackbar(t("The card was saved"), { bottom: true, autoHide: 2000 });
    }

    handleClose();
  };

  if (!deck) return null;

  return (
    <DialogFullWindow open={!!card} onClose={handleClose} background>
      <Wrap>
        {card?.sCardId && sourceData?.node?.mine && (
          <CheckLabel synchronize={synchronize}>
            <Checkbox size="small" checked={synchronize} onChange={() => setSynchronize(!synchronize)} />
            <Trans parent="div">Synchronize to:</Trans> <strong> {sourceData?.node?.name}</strong>
          </CheckLabel>
        )}
        {card?.sCardId && !sourceData?.node?.mine && (
          <SIconButton
            onClick={() => {
              if (!card?.sCardId) return;
              dispatchEvent(EVENT.openReportIssueDialog, {
                type: "card",
                sCardId: card?.sCardId,
                sBackId: card?.sBackId,
                langBack: deck?.back
              });
            }}
          >
            <FontAwesomeIcon icon={faFlag} />
          </SIconButton>
        )}
        <CardForm
          key={card?.front}
          card={card}
          onSave={handleSave}
          onValuesChange={setFormValues}
          fromLang={deck.front}
          toLang={deck.back}
          checkForDuplicates={true}
        />
      </Wrap>
    </DialogFullWindow>
  );
};

export default CardEditDialog;
