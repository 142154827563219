import React from "react";
import styled from "styled-components/macro";
import { Fade } from "@mui/material";
import { Portal } from "@mui/base";
import { useDebounce } from "react-use";
import { devicePaddings } from "tools/device";
import useClickAway from "hooks/useClickAway";

const { top } = devicePaddings();

const TRANSDUR = 300;

const Wrap = styled.div<{ open: boolean }>`
  position: absolute;
  left: 0;
  top: ${top + 56}px;
  height: calc(100% - ${top + 56}px);
  width: 100%;
  pointer-events: ${({ open }) => (open ? "auto" : "none")};
  z-index: 10;
  overflow: hidden;
`;
const Cont = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px 20px;
  background: white;
  font-size: 95%;
  line-height: 1.4em;
  transition: all ${TRANSDUR}ms;
  max-height: 85%;
  overflow: auto;
`;
const BckDrop = styled.div`
  width: 100%;
  height: 100%;
  background: #0002;
  backdrop-filter: blur(2px);
`;

type Props = {
  children: React.ReactNode;
  open: boolean;
  onClose: VoidFunction;
  className?: string;
};

const RollingMenu: React.FC<Props> = ({ children, open, onClose, className }) => {
  // debounced open variables
  const [openDeb0, setOpenDeb0] = React.useState(false); // 1-tick after open
  React.useEffect(() => setOpenDeb0(open), [open]);
  const [openDeb300, setOpenDeb300] = React.useState(false); // TRANSDUR after open
  useDebounce(() => setOpenDeb300(open), TRANSDUR, [open]);

  const ref: React.RefObject<HTMLDivElement> = React.useRef(null);
  useClickAway(ref, () => open && openDeb300 && onClose(), ["click"]);

  const [height, setHeight] = React.useState(1000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    ref.current?.offsetHeight && setHeight(ref.current?.offsetHeight);
  });

  if (!open && !openDeb300) return null;

  return (
    <Portal>
      <Wrap open={open} className={className}>
        <Fade in={open} timeout={TRANSDUR}>
          <BckDrop />
        </Fade>

        <Cont ref={ref} style={{ top: openDeb0 ? 0 : -height - 5 }}>
          {children}
        </Cont>
      </Wrap>
    </Portal>
  );
};

export default RollingMenu;
