/**
 * @generated SignedSource<<aabd4e6e2b5f15fff49bcc9cb4e95920>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FlatIconEdit = {
  flatId: string;
  url: string;
};
export type cardEditMutation$variables = {
  back: string;
  front: string;
  hint?: string | null;
  id: string;
  langBack?: string | null;
  sCardId?: string | null;
  sourceId?: string | null;
  svg?: FlatIconEdit | null;
  synchronize?: boolean | null;
};
export type cardEditMutation$data = {
  readonly cardEdit: {
    readonly back: string;
    readonly front: string;
    readonly hint: string | null;
    readonly pronunciation: string | null;
    readonly sourceId: string | null;
    readonly svg: {
      readonly flatId: string;
      readonly url: string;
    } | null;
  } | null;
};
export type cardEditMutation = {
  response: cardEditMutation$data;
  variables: cardEditMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "back"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "front"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hint"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "langBack"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sCardId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "svg"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "synchronize"
},
v9 = [
  {
    "kind": "Variable",
    "name": "back",
    "variableName": "back"
  },
  {
    "kind": "Variable",
    "name": "front",
    "variableName": "front"
  },
  {
    "kind": "Variable",
    "name": "hint",
    "variableName": "hint"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "langBack",
    "variableName": "langBack"
  },
  {
    "kind": "Variable",
    "name": "sCardId",
    "variableName": "sCardId"
  },
  {
    "kind": "Variable",
    "name": "sourceId",
    "variableName": "sourceId"
  },
  {
    "kind": "Variable",
    "name": "svg",
    "variableName": "svg"
  },
  {
    "kind": "Variable",
    "name": "synchronize",
    "variableName": "synchronize"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "front",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "back",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hint",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flatId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pronunciation",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "cardEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "Card",
        "kind": "LinkedField",
        "name": "cardEdit",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Upload",
            "kind": "LinkedField",
            "name": "svg",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v6/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Operation",
    "name": "cardEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "Card",
        "kind": "LinkedField",
        "name": "cardEdit",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Upload",
            "kind": "LinkedField",
            "name": "svg",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              (v15/*: any*/),
              (v17/*: any*/)
            ],
            "storageKey": null
          },
          (v16/*: any*/),
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "724b57856644dd8c1cda18e56e1df95e",
    "id": null,
    "metadata": {},
    "name": "cardEditMutation",
    "operationKind": "mutation",
    "text": "mutation cardEditMutation(\n  $id: ID!\n  $sourceId: ID\n  $sCardId: ID\n  $front: String!\n  $back: String!\n  $hint: String\n  $svg: FlatIconEdit\n  $langBack: String\n  $synchronize: Boolean\n) {\n  cardEdit(id: $id, sourceId: $sourceId, sCardId: $sCardId, front: $front, back: $back, hint: $hint, svg: $svg, langBack: $langBack, synchronize: $synchronize) {\n    sourceId\n    front\n    back\n    hint\n    svg {\n      flatId\n      url\n      id\n    }\n    pronunciation\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "58c31c6c5c5683cd93f1ceacb799f42a";

export default node;
