import React from "react";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import HintDialog from "components/hint/HintDialog";
import MammothIcon from "icons/MammothIcon";
import { useGetState, useSetState } from "components/ReduxProvider";
const lightBulbKF = keyframes`
  0% { transform: scale(0.6); opacity: 0; }
  100% { transform: scale(1.1); opacity: 1; }`;

const LightBulbEmoji = styled.span`
  font-size: 16px; /* Adjust size as needed */
  animation: ${lightBulbKF} 2s infinite alternate;
  position: absolute;
  margin-left: -10px;
  margin-top: -10px;
`;

const MammothWrap = styled.div`
  margin-top: 30px;
`;

const SELECT_SENTENCE_HINT = "selectSentenceHint";

const SentenceHint: React.FC = () => {
  const { t } = useTranslation();

  const showSentenceHint = useGetState("showSentenceHint");
  const setShowSentenceHint = useSetState("showSentenceHint");

  if (!showSentenceHint) {
    return null;
  }

  return (
    <HintDialog
      name={SELECT_SENTENCE_HINT}
      emoji=""
      onClose={() => {
        setShowSentenceHint(false);
      }}
    >
      <MammothWrap>
        <MammothIcon />
        <LightBulbEmoji>💡</LightBulbEmoji>
      </MammothWrap>
      {t("You can also double tap on a sentence to select it.")}
    </HintDialog>
  );
};

export default SentenceHint;
