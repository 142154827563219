/**
 * @generated SignedSource<<d6b7356edad029d147fb93e9c86d7196>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type deleteUserMutation$variables = {};
export type deleteUserMutation$data = {
  readonly deleteUser: number;
};
export type deleteUserMutation = {
  response: deleteUserMutation$data;
  variables: deleteUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "deleteUser",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteUserMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "deleteUserMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6bfe39b6d3881d5a14e18aa86e83baf3",
    "id": null,
    "metadata": {},
    "name": "deleteUserMutation",
    "operationKind": "mutation",
    "text": "mutation deleteUserMutation {\n  deleteUser\n}\n"
  }
};
})();

(node as any).hash = "af39deb009be71837ec1747e8e684877";

export default node;
