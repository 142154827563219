import React from "react";
import { Trans } from "react-i18next";
import HintDialog from "../../../components/hint/HintDialog";
import OAuth, { OAuthButtonType } from "components/other/OAuth";
import styled from "styled-components/macro";

export const OAUTH_HINT = "oauthHint";

type Props = {};

const Title = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin-top: -20px;
  margin-bottom: 20px;
`;

const OAuthHint: React.FC<Props> = () => {
  return (
    <HintDialog name={OAUTH_HINT} withClose={true} noOk={true} emoji="">
      <Trans parent={Title}>Login options</Trans>
      <OAuth type={OAuthButtonType.CONNECT} />
      <Trans parent="p">
        Connect your account with Google or Apple so that you don't have to type your email and password every time you
        log in.
      </Trans>
    </HintDialog>
  );
};

export default OAuthHint;
