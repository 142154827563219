import React from "react";
import styled from "styled-components/macro";
import { Trans } from "react-i18next";

const Wrap = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const In = styled.div`
  max-width: 340px;
  margin-top: 25px;
  text-align: center;
`;

type Props = {};

const LoggedInChromeExtension: React.FC<Props> = () => {
  return (
    <Wrap>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Google_Chrome_icon_%28September_2014%29.svg"
        alt="Chrome icon"
        width="40"
        height="40"
      />
      <Trans parent={In}>You are now logged in Chrome extension, you can close this window.</Trans>
    </Wrap>
  );
};

export default LoggedInChromeExtension;
