import { useCanAdvertFriends } from "components/ads/useCanAdvertFriends";
import React from "react";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components/macro";
import Nofilter from "../../components/other/Nofilter";
import { setInviteDialog } from "../../components/ReduxProvider";
import { useViewerFriends } from "../../queries/viewerQuery";
import { viewerQuery_friends$data } from "../../queries/__generated__/viewerQuery_friends.graphql";
import FriendsList from "./FriendsList";

const FreeSub = styled.div`
  color: ${({ theme }) => theme.duo.color.green};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.3em;
  margin: 10px auto -10px;
  padding: 0 10px;
  text-align: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
`;
const Emj = styled(Nofilter)`
  font-size: 130%;
`;

type Props = {};

const Friends: React.FC<Props> = () => {
  const { friends } = useViewerFriends();
  const advertFriends = useCanAdvertFriends();
  const dispatch = useDispatch();

  if (!friends) return null;

  const extractFriends = (friends: viewerQuery_friends$data["friends"], hasOnly?: boolean) =>
    friends.edges
      .filter((edge) => (hasOnly === undefined ? true : edge.hasOnlyFewFriends === hasOnly))
      .map((edge) => edge.friend);

  return (
    <>
      {advertFriends && (
        <FreeSub onClick={() => dispatch(setInviteDialog(true))}>
          <Emj>✨</Emj> <Trans>Get up to 24 months of free Premium</Trans> <Emj>✨</Emj>
        </FreeSub>
      )}

      <FriendsList friends={extractFriends(friends)} atLeast3 />
    </>
  );
};

export default Friends;
