/**
 * @generated SignedSource<<f3142a12c6a110e76bc221fec19fd50d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useDBLogMutation$variables = {
  body?: string | null;
  name: string;
  text: string;
  ttlDays?: number | null;
};
export type useDBLogMutation$data = {
  readonly dblogMutation: string | null;
};
export type useDBLogMutation = {
  response: useDBLogMutation$data;
  variables: useDBLogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "body"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "text"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ttlDays"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "body",
        "variableName": "body"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "text",
        "variableName": "text"
      },
      {
        "kind": "Variable",
        "name": "ttlDays",
        "variableName": "ttlDays"
      }
    ],
    "kind": "ScalarField",
    "name": "dblogMutation",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useDBLogMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "useDBLogMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "08a7f0f4e50937c82bb78fcc2b8dc0da",
    "id": null,
    "metadata": {},
    "name": "useDBLogMutation",
    "operationKind": "mutation",
    "text": "mutation useDBLogMutation(\n  $name: String!\n  $text: String!\n  $body: String\n  $ttlDays: Int\n) {\n  dblogMutation(name: $name, text: $text, body: $body, ttlDays: $ttlDays)\n}\n"
  }
};
})();

(node as any).hash = "2493e317a9385348ebbc486ea283e352";

export default node;
