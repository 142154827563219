import React from "react";
import styled from "styled-components/macro";
import { css } from "styled-components";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ClearIcon from "@mui/icons-material/Clear";
import SortIcon from "@mui/icons-material/Sort";
import { useSearchParams } from "react-router-dom";
import RollingMenu from "../../../components/other/RollingMenu";
import { IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { Trans, useTranslation } from "react-i18next";
import { useSetFlag } from "../../../queries/users/setFlag";
import { useViewerQuery } from "../../../queries/viewerQuery";
import { SourcesSortEnum } from "../../../sharedJs__generated/constants";
import { MY_SORT_FLAG } from "../../../queries/users/userFlagsModel";
import { difficultyToColor } from "../../../styled/Functions";
import { Kind } from "../../../queries/sources/__generated__/sourceQuery.graphql";
import { LIBRARY } from "../../RootRouter.config";

const iconStyle = css`
  color: ${({ theme }) => theme.duo.color.textGrey};
  font-size: 1.5rem;
  pointer-events: none;
`;

const Form = styled.form`
  position: relative;
  top: -1px;
  left: -3px;
`;
const SearchInput = styled.input<{ $collapsed: boolean }>`
  border-radius: 50px;
  border: none;
  font-size: 15px;
  padding: ${({ $collapsed }) => ($collapsed ? "11px 0px" : "11px 36px 11px 40px")};
  width: ${({ $collapsed }) => ($collapsed ? "0px" : "300px")};
  background: ${({ $collapsed }) => ($collapsed ? "transparent" : "#f4f4f4")};
`;
const SIconButton = styled(IconButton)`
  position: relative;
  left: 14px;
  padding: 0;
`;
const SSearchIconButton = styled(IconButton)`
  position: absolute;
  right: 12px;
  top: 9px;
  padding: 0;
`;
const SClearIconButton = styled(IconButton)`
  position: absolute;
  left: 13px;
  top: 8px;
  padding: 0;
`;
const SManageSearchIcon = styled(ManageSearchIcon)`
  ${iconStyle};
  font-size: 2rem;
`;
const SSortIcon = styled(SortIcon)<{ sort?: string }>`
  ${iconStyle};
  color: ${({ sort }) => sort && difficultyToColor(sort.replace("First", ""), false)};
`;
const SSearchIcon = styled(SearchIcon)`
  ${iconStyle}
`;
const SClearIcon = styled(ClearIcon)`
  ${iconStyle};
  font-size: 22px;
`;
const In = styled.div`
  max-width: ${({ theme }) => theme.duo.maxWidth.narrow};
  margin: 0 auto;
`;
const SFormLabel = styled(FormLabel)`
  display: flex;
  flex-direction: row;
  justify-content: left;
`;

const SSpan = styled.span`
  display: inline-block;
  padding-left: 0.5rem;
`;

type Props = {
  onChangeExpanded: (value: boolean) => void;
};

const LibrarySearch: React.FC<Props> = ({ onChangeExpanded }) => {
  const { getFlag, viewer } = useViewerQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const initValue = searchParams.get(LIBRARY.searchParam);
  const [searchValue, setSearchValue] = React.useState<null | string>(initValue);
  const [isSearchOpen, setIsSearchOpen] = React.useState<boolean>(false);
  const [isRolloutOpen, setIsRolloutOpen] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const sortFlag = getFlag(MY_SORT_FLAG)?.value ?? SourcesSortEnum.popularity;
  const setFlag = useSetFlag();
  const kind = searchParams.get("kind") as Kind | "mine" | "last" | "course" | null;

  React.useEffect(() => {
    if (kind !== null && searchValue === null) {
      setIsSearchOpen(false);
      onChangeExpanded(false);
    }
  }, [kind, searchValue, onChangeExpanded]);

  const handleChange = React.useCallback(
    (value: null | string) => {
      setSearchValue(value);
      onChangeExpanded(value !== null);
    },
    [onChangeExpanded]
  );

  const handleClear = () => {
    setIsSearchOpen(false);
    setIsRolloutOpen(false);
    onChangeExpanded(false);
    handleChange(null);
    setSearchParams({}, { replace: true });
    (document.activeElement as HTMLElement).blur();
  };

  const handleFocus = () => {
    setIsRolloutOpen(false);
  };

  const handleSearch = (e?: React.FormEvent<HTMLFormElement>) => {
    setSearchParams(searchValue ? { [LIBRARY.searchParam]: searchValue } : {}, {
      replace: !!searchParams.get(LIBRARY.searchParam)
    });
    if (searchValue === "") {
      handleClear();
    }
    e?.preventDefault();
  };

  const handleIconOnClick = () => {
    setIsRolloutOpen(!isRolloutOpen && !!viewer);
    setIsSearchOpen(!isSearchOpen);
    onChangeExpanded(!isSearchOpen);
  };

  const handleSort = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFlag({ name: MY_SORT_FLAG, value: (event.target as HTMLInputElement).value });
    handleClear();
  };

  React.useEffect(() => {
    handleChange(searchParams.get(LIBRARY.searchParam));
  }, [searchParams, handleChange]);

  return (
    <>
      <Form onSubmit={handleSearch}>
        <SearchInput
          value={searchValue || ""}
          onChange={(e) => handleChange(e.target.value)}
          onFocus={handleFocus}
          $collapsed={!isSearchOpen}
        />
        <>
          <SIconButton onClick={handleIconOnClick}>
            {!isSearchOpen && (
              <>
                {sortFlag === SourcesSortEnum.popularity && <SManageSearchIcon />}
                {sortFlag !== SourcesSortEnum.popularity && (
                  <>
                    <SSearchIcon />
                    <SSortIcon sort={sortFlag} />
                  </>
                )}
              </>
            )}
          </SIconButton>
        </>
        {isSearchOpen && (
          <>
            <SClearIconButton onClick={handleClear}>
              <SClearIcon />
            </SClearIconButton>
            <SSearchIconButton onClick={() => handleSearch()}>
              <SSearchIcon />
            </SSearchIconButton>
          </>
        )}
      </Form>

      <RollingMenu open={isRolloutOpen} onClose={() => setIsRolloutOpen(false)}>
        <In>
          <FormControl>
            <SFormLabel id="demo-radio-buttons-group-label">
              <SSortIcon />
              <SSpan>
                <Trans>Sort by</Trans>
              </SSpan>
            </SFormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              value={sortFlag}
              onChange={handleSort}
            >
              <FormControlLabel
                value={SourcesSortEnum.popularity}
                control={<Radio />}
                label={t("Popularity - default")}
              />
              <FormControlLabel
                value={SourcesSortEnum.beginnerFirst}
                control={<Radio />}
                label={t("Beginner difficulty first")}
              />
              <FormControlLabel
                value={SourcesSortEnum.easyFirst}
                control={<Radio />}
                label={t("Easy difficulty first")}
              />
              <FormControlLabel
                value={SourcesSortEnum.moderateFirst}
                control={<Radio />}
                label={t("Moderate difficulty first")}
              />
              <FormControlLabel
                value={SourcesSortEnum.hardFirst}
                control={<Radio />}
                label={t("Hard difficulty first")}
              />
            </RadioGroup>
          </FormControl>
        </In>
      </RollingMenu>
    </>
  );
};

export default LibrarySearch;
