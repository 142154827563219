import React from "react";

// @ts-ignore
export const inAppBrowser = () => typeof cordova !== "undefined" && cordova.InAppBrowser;

type Props = {
  children: React.ReactNode;
  href: string;
  className?: string;
};

const LinkExt: React.FC<Props> = ({ href, className, children }) => {
  return (
    <a
      className={className}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      tabIndex={-1}
      onClick={(e) => {
        if (inAppBrowser()) {
          e.preventDefault();
          inAppBrowser().open(href, "_system");
        }
      }}
    >
      {children}
    </a>
  );
};

export default LinkExt;
