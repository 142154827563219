import React from "react";
import styled from "styled-components/macro";
import { Stack } from "@mui/material";
import { flag } from "tools/langs";

const SLang = styled.div<{ $active: boolean }>`
  padding: 12px;
  opacity: ${(p) => (p.$active ? 1 : 0.4)};
  cursor: pointer;
`;

type Props = {
  langCodes: string[];
  activeLang: string;
  onLanguageSwitch: (langCode: string) => void;
  className?: string;
};
const LanguageSwitcher: React.FC<Props> = ({ langCodes, activeLang, onLanguageSwitch, className }) => {
  const handleLangSwitch = (langCode: string) => {
    onLanguageSwitch(langCode);
  };

  return (
    <Stack direction="row" className={className}>
      {langCodes.map((langCode) => (
        <SLang $active={langCode === activeLang} onClick={() => handleLangSwitch(langCode)} key={langCode}>
          {flag(langCode)}
        </SLang>
      ))}
    </Stack>
  );
};

export default LanguageSwitcher;
