import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { cardEditMutation } from "./__generated__/cardEditMutation.graphql";

const mutation = graphql`
  mutation cardEditMutation(
    $id: ID!
    $sourceId: ID
    $sCardId: ID
    $front: String!
    $back: String!
    $hint: String
    $svg: FlatIconEdit
    $langBack: String
    $synchronize: Boolean
  ) {
    cardEdit(
      id: $id
      sourceId: $sourceId
      sCardId: $sCardId
      front: $front
      back: $back
      hint: $hint
      svg: $svg
      langBack: $langBack
      synchronize: $synchronize
    ) {
      sourceId
      front
      back
      hint
      svg {
        flatId
        url
      }
      pronunciation
    }
  }
`;

export const useCardEdit = () => {
  const [mutate] = useMutation<cardEditMutation>(mutation);

  return (variables: cardEditMutation["variables"]) => mutate({ variables });
};
