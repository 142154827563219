import React from "react";
import styled from "styled-components/macro";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { TheoryDeleteMutation } from "./__generated__/TheoryDeleteMutation.graphql";
import { useTranslation } from "react-i18next";

const deleteQl = graphql`
  mutation TheoryDeleteMutation($id: ID!, $backLang: String) {
    deleteTheory(id: $id, backLang: $backLang) {
      id
      theory(langNative: $backLang) {
        sCardId
      }
    }
  }
`;

const DeleteIcon = styled(DeleteForeverOutlinedIcon)`
  position: absolute;
  right: 6px;
  font-size: 18px;
  color: #aaa;
  margin-top: -20px;
`;

type Props = {
  sCardId: string;
  backLang: string;
};

const TheoryDelete: React.FC<Props> = ({ sCardId, backLang }) => {
  const [deleteTheory] = useMutation<TheoryDeleteMutation>(deleteQl);
  const { t } = useTranslation();

  return (
    <DeleteIcon
      onClick={() => window.confirm(t("Really delete?")) && deleteTheory({ variables: { id: sCardId, backLang } })}
    />
  );
};

export default TheoryDelete;
