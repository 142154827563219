import React from "react";
import styled from "styled-components/macro";
import { TextField } from "../../../../styled/TextField";
import { Trans } from "react-i18next";
import LangMenu from "../../../../components/LangMenu/LangMenu";
import { Select } from "../../../../styled/Select";
import { sourceEditQuery } from "../__generated__/sourceEditQuery.graphql";
import DialogWindow from "../../../../components/other/DialogWindow";
import { useTranslation } from "react-i18next";
import { LabelTextWithLangLabel, flag } from "../../../../tools/langs";
import { Button, Checkbox } from "@mui/material";
import { sourceEditQL } from "../sourceEditQuery";
import { useEnvironment } from "../../../../graphql/environment";
import { fetchQuery, useMutation } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { NativeCreateMutation } from "./__generated__/NativeCreateMutation.graphql";
import { useGetLangOptions } from "hooks/useGetLangTranslates";

export const nativeCreateQL = graphql`
  mutation NativeCreateMutation($sourceId: ID!, $nameNative: String!, $langNative: String!, $backsJson: String) {
    nativeCreate(sourceId: $sourceId, nameNative: $nameNative, langNative: $langNative, backsJson: $backsJson) {
      natives {
        langNative
        nameNative
      }
    }
  }
`;

const Note = styled.p``;
const CheckLabel = styled.label`
  display: flex;
  align-items: center;
  text-align: left;
  margin: -6px 0 0;
  padding: 5px 0 12px;
  font-size: 14px;
  line-height: 1.4em;
  cursor: pointer;
`;
const Hint = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const SButton = styled(Button)`
  &&& {
    margin-bottom: 15px;
  }
`;

type Values = {
  nameNative?: string;
  langNative?: string;
  backs?: { [key: string]: string };
};
type Props = {
  open: boolean;
  onClose: VoidFunction;
  source: sourceEditQuery["response"]["node"];
  onSubmit: (lang: string) => void;
};

const NativeCreate: React.FC<Props> = ({ source, open, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [values, setValues] = React.useState<Values>();
  const [agreed, setAgreed] = React.useState(false);
  const [reviewCards, setReviewCards] = React.useState(false);
  const environment = useEnvironment();
  const [translated, setTranslated] = React.useState<string>();
  const [nativeCreate] = useMutation<NativeCreateMutation>(nativeCreateQL);

  const langOptions = useGetLangOptions();

  React.useEffect(() => {
    if (source?.id && values?.langNative && translated !== values.langNative) {
      fetchQuery<sourceEditQuery>(environment, sourceEditQL, {
        id: source?.id as string,
        backLang: values?.langNative
      }).subscribe({
        next: (data) => {
          data?.node &&
            setValues({
              ...values,
              nameNative: data.node.name,
              backs: data.node.sCards?.reduce((res, c) => ({ ...res, [c.id]: c.back?.value }), {})
            });
        }
      });
      setTranslated(values.langNative);
    }
  }, [source, values, setValues, translated, setTranslated, environment]);

  if (!source || !source?.sCards) return null;

  const handlePublish = () => {
    if (values?.backs && Object.keys(values.backs).find((cId) => !(values.backs as any)[cId])) {
      return alert("You left some card translations empty.");
    }
    nativeCreate({
      variables: {
        langNative: values?.langNative as string,
        nameNative: values?.nameNative as string,
        sourceId: source.id as string,
        backsJson: JSON.stringify(values?.backs)
      },
      onCompleted: () => {
        onSubmit(values?.langNative as string);
        setValues(undefined);
        setReviewCards(false);
      }
    });
  };

  if (!source) return null;

  return (
    <DialogWindow
      open={open}
      onClose={() => {
        onClose();
        setValues(undefined);
        setReviewCards(false);
      }}
      wide
      background
    >
      {!reviewCards ? (
        <>
          <Trans parent={Note}>You are going to translate your Set to a new language.</Trans>
          <LangMenu
            onSelect={(langNative) => setValues({ ...values, langNative })}
            disabledLangs={[
              source.lang as string,
              ...((source.natives && source.natives?.map((n) => n.langNative)) || [])
            ]}
            title={
              <Select
                label={t("Language")}
                value={values?.langNative || ""}
                options={langOptions}
                style={{ pointerEvents: "none" }}
              />
            }
          />
          <TextField
            disabled={!translated}
            label={<LabelTextWithLangLabel label={t("Name")} lang={values?.langNative} />}
            value={values?.nameNative || ""}
            onChange={(e) => setValues({ ...values, nameNative: e.target.value })}
          />
          <SButton
            color="primary"
            variant="contained"
            onClick={() => setReviewCards(true)}
            disabled={!values?.nameNative}
          >
            Next
          </SButton>
        </>
      ) : (
        <>
          <Trans parent={Note}>Review all the cards below and fix machine translation mistakes</Trans>
          {source.sCards.map((card) => (
            <TextField
              key={card.id}
              label={card.front}
              helperText={<Hint>{card.hint}</Hint>}
              value={values?.backs ? values.backs[card.id] : ""}
              onChange={(e) =>
                setValues({
                  ...values,
                  backs: { ...(values?.backs || {}), [card.id]: e.target.value }
                })
              }
            />
          ))}

          <CheckLabel>
            <Checkbox checked={agreed} onChange={() => setAgreed(!agreed)} color="primary" />
            <Trans parent="div">Yes, I carefully reviewed translations of all cards.</Trans>
          </CheckLabel>
          <SButton color="primary" variant="contained" disabled={!agreed} onClick={handlePublish}>
            <Trans>Publish</Trans> {flag(values?.langNative)}
          </SButton>
        </>
      )}
    </DialogWindow>
  );
};

export default NativeCreate;
