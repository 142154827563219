import { ComponentProps } from "react";

const WandIcon = (props: ComponentProps<"svg">) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 128 128" {...props}>
    <path fill="#c35838" d="M34.534 87.872 79.867 42.54l5.58 5.58-45.334 45.332z" />
    <path fill="#a43a1f" d="M25.475 96.944a3.946 3.946 0 0 0 5.581 5.581l9.068-9.068-5.581-5.581z" />
    <path
      fill="#fec22d"
      d="M98.871 40.94a3.2 3.2 0 0 1-.465-2.935l2.239-6.6a3.192 3.192 0 0 0-4.045-4.05L90 29.594a3.2 3.2 0 0 1-2.935-.465l-5.592-4.169a3.192 3.192 0 0 0-5.1 2.6l.089 6.97a3.192 3.192 0 0 1-1.349 2.648L69.42 41.2a3.193 3.193 0 0 0 .9 5.655l6.657 2.069a3.191 3.191 0 0 1 2.1 2.1l2.069 6.657a3.192 3.192 0 0 0 5.655.9l4.025-5.691a3.192 3.192 0 0 1 2.648-1.349l6.97.089a3.192 3.192 0 0 0 2.6-5.1z"
    />
    <g fill="#d2f6ff">
      <path d="M61.5 30a1.75 1.75 0 0 0-1.75 1.75v3.083a1.75 1.75 0 0 0 3.5 0V31.75A1.75 1.75 0 0 0 61.5 30zM55.5 48.917a1.75 1.75 0 0 0-1.75 1.75v3.083a1.75 1.75 0 0 0 3.5 0v-3.083a1.75 1.75 0 0 0-1.75-1.75zM92.167 78a1.749 1.749 0 0 0-1.75 1.75v3.083a1.75 1.75 0 0 0 3.5 0V79.75a1.75 1.75 0 0 0-1.75-1.75zM95.75 58.18A1.75 1.75 0 0 0 94 59.93v3.083a1.75 1.75 0 0 0 3.5 0V59.93a1.75 1.75 0 0 0-1.75-1.75zM49.571 38.917h-2.1v-2.1a1.75 1.75 0 0 0-3.5 0v2.1h-2.1a1.75 1.75 0 1 0 0 3.5h2.1v2.1a1.75 1.75 0 0 0 3.5 0v-2.1h2.1a1.75 1.75 0 0 0 0-3.5zM84.313 70.311h-2.438v-2.437a1.75 1.75 0 0 0-3.5 0v2.437h-2.437a1.75 1.75 0 0 0 0 3.5h2.437v2.438a1.75 1.75 0 0 0 3.5 0v-2.438h2.438a1.75 1.75 0 1 0 0-3.5z" />
    </g>
  </svg>
);

export default WandIcon;
