import React from "react";
import styled from "styled-components/macro";
import { IconButton } from "@mui/material";
import { genderStyle } from "tools/genderStyle";
import Speech from "components/speech/Speech";
import { PickerCard } from "components/picker/Picker";
import { useViewerQuery, useViewerDecks } from "queries/viewerQuery";
import { FIVEHINT } from "root/main/main/Main";
import Theory from "components/Theory/Theory";
import { brown } from "components/Theory/theoryStyles";
import { useTtsQuery } from "queries/ttsQuery";
import TheoryWrapper from "components/Theory/TheoryWrapper";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { devicePaddings } from "tools/device";
import SpeechHintTooltip, { LONGPRESSHINT } from "components/picker/SpeechHintTooltip";
import { canTts } from "queries/tts/ttsTools";
import Pronunciation from "root/main/components/Pronunciation";
import FlatImg from "components/other/FlatImg";
import { sourceQuery$data } from "queries/sources/__generated__/sourceQuery.graphql";
import useLangNative from "hooks/useLangNative";
import { usePrevious } from "react-use";
import { useOpenCourseView } from "root/library/source/course/courseHooks";
import { useGetState } from "components/ReduxProvider";
import UpSize from "root/main/components/UpSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFlag, faGraduationCap } from "@fortawesome/pro-regular-svg-icons";
import { EVENT, dispatchEvent } from "tools/events";

const { bottom, top } = devicePaddings();

const percPos = (isInMy: boolean | null) => (isInMy === true ? "0%" : isInMy === false ? "71%" : "35%");

const Wrap = styled.div<{ isInMy: boolean | null; index: number; moving?: boolean }>`
  position: absolute;
  width: 86%;
  left: 7%;
  height: 29%;
  top: ${({ isInMy }) => percPos(isInMy)};
  border-radius: 9px;
  border: ${({ theme }) => theme.duo.border.grey};
  transform: ${({ index }) => (index === 2 ? "rotate(4deg)" : index === 3 ? "rotate(-3deg)" : "none")};
  background: white;
  transition: all ${({ moving }) => (moving ? "0" : `160`)}ms;
  z-index: ${({ moving, index, isInMy }) => (moving ? 51 : 50 - index - (isInMy !== null ? 10 : 0))};
  transform: ${({ isInMy, index }) =>
    (isInMy !== null ? "scale(0.75) " : "") + (index === 2 ? "rotate(3deg)" : index === 3 ? "rotate(-3deg)" : "")};
  pointer-events: ${({ index }) => (index !== 0 ? "none" : "auto")};
`;
const In = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  padding-right: 13px;
`;
const Front = styled.div`
  text-align: center;
  padding: 0 30px 0 10px;
  font-weight: bold;
  font-size: 110%;
`;
const Hint = styled.div`
  font-size: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 78%;
  text-align: center;
  color: ${({ theme }) => theme.duo.color.textGrey};
`;
const Top = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
`;
const SIconButton = styled(IconButton)`
  padding: 15px;
  margin-right: auto;
  svg {
    color: ${({ theme }) => theme.duo.color.textGrey};
    width: 12px;
    height: 12px;
  }
`;
const Translation = styled.div<{ blured: boolean }>`
  position: absolute;
  bottom: 6px;
  right: 11px;
  left: 11px;
  text-align: right;
  filter: blur(${({ blured }) => (blured ? 5 : 0)}px);
`;
const SFlatImg = styled(FlatImg)`
  justify-content: flex-end;
  margin: 0 -2px -1px 0;
`;
const Back = styled.div`
  padding: 0 0 0 12px;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  color: ${({ theme }) => theme.duo.color.textGrey};
  overflow: hidden;
  text-overflow: ellipsis;
`;
const TheoryIcon = styled(AssignmentIcon)`
  color: ${brown};
`;
const TheoryButton = styled(IconButton)`
  float: right;
`;
const SchoolIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.duo.color.lightBlue};
  width: 24px;
  opacity: 0.5;
`;

type Props = {
  sCard: PickerCard;
  source: sourceQuery$data["sourceByUriOrId"];
  lang: string;
  index: number;
  onSave: (sCard: PickerCard, added: boolean) => void;
  onDialog: (c: PickerCard) => void;
};

const PickCard: React.FC<Props> = ({ sCard, lang, index, onSave, onDialog, source }) => {
  const cardRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const [startY, setStartY] = React.useState(0);
  const [moving, setMoving] = React.useState(false);
  const { hintWasSeen } = useViewerQuery();
  const [firstUnblured, setFirstUnblured] = React.useState(false);
  const [openTheory, setOpenTheory] = React.useState<boolean>();
  const tts = useTtsQuery(lang);
  const { deck } = useViewerDecks();
  const [prevTts, setPrevTts] = React.useState("");
  const voiceOff = useGetState("voiceOff");
  const openCourseView = useOpenCourseView();
  const langNative = useLangNative();
  const isTop = !index && sCard.isInMyDeck === null;
  const indexPrev: number | undefined = usePrevious(index);
  const doOpenCourseView = (
    openTheoryByCardId: string | undefined = undefined,
    allowScrollAnimation: boolean = false,
    canShowTooMany: boolean = false
  ) => {
    openCourseView(source.uri, langNative, source.lang, openTheoryByCardId, allowScrollAnimation, canShowTooMany);
  };

  React.useEffect(() => {
    if (isTop && indexPrev === 1 && sCard.theory && source.course) {
      doOpenCourseView(undefined, true, true);
    }
  });

  React.useEffect(() => {
    isTop && setTimeout(() => setFirstUnblured(true), 1800);
  }, [setFirstUnblured, isTop]);

  React.useEffect(() => {
    if (
      isTop &&
      (openTheory === false || !sCard.theory) &&
      !voiceOff &&
      tts &&
      deck?.stats.total && // silent on first card (tutorial)
      prevTts !== sCard?.front
    ) {
      tts({ text: sCard?.front });
      setPrevTts(sCard?.front);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sCard, voiceOff, isTop, openTheory, deck?.stats.total, prevTts]);

  const getY = (e) => e.pageY || ((e.touches && e.touches[0]) || e.changedTouches[0]).pageY;
  const mvY = (e) => (!startY ? 0 : getY(e) - startY);

  const applyMove = (e) => {
    if (!cardRef.current) return;

    cardRef.current!.style.top = `calc(${percPos(sCard.isInMyDeck)} + ${mvY(e)}px)`;
    cardRef.current!.style.transform = `rotate(${mvY(e) / -30}deg)`;
  };
  const resetMove = () => {
    if (!cardRef.current) return;
    cardRef.current!.style.removeProperty("top");
    cardRef.current!.style.removeProperty("transform");
    setStartY(0);
    setMoving(false);
  };

  const handleStart = (e) => {
    setMoving(true);
    setStartY(getY(e));
  };
  const handleMove = (e) => {
    if (startY === 0) return;
    applyMove(e);
  };
  const handleEnd = (e) => {
    if (mvY(e) < -20) {
      onSave(sCard, true);
      resetMove();
    } else if (mvY(e) > 20) {
      onSave(sCard, false);
      resetMove();
    } else {
      if (sCard.isInMyDeck === null) {
        shake(e);
      } else {
        resetMove();
      }
    }
  };
  const shake = (e) => {
    if (!startY) return;
    setMoving(false);

    if (!e.target.closest("button")) {
      setTimeout(() => applyMove({ pageY: startY + 50 }), 0);
      setTimeout(() => applyMove({ pageY: startY - 50 }), 120);
    }

    setTimeout(resetMove, 240);
  };

  const svg = sCard?.svg;
  const theoryIsOpened = openTheory || (isTop && !!sCard.theory && !!deck?.stats.total && openTheory === undefined);

  return (
    <>
      <Wrap
        onTouchStart={handleStart}
        onMouseDown={handleStart}
        onTouchMove={handleMove}
        onMouseMove={handleMove}
        onTouchEnd={handleEnd}
        onMouseUp={handleEnd}
        onMouseLeave={handleEnd}
        isInMy={sCard.isInMyDeck}
        index={index}
        ref={cardRef}
        moving={moving}
      >
        {index <= 1 && (
          <In>
            <Top>
              {hintWasSeen(FIVEHINT) && (
                <>
                  {source.mine ? (
                    <SIconButton onClick={() => onDialog(sCard)}>
                      <FontAwesomeIcon icon={faEdit} />{" "}
                    </SIconButton>
                  ) : (
                    <SIconButton
                      onClick={() =>
                        dispatchEvent(EVENT.openReportIssueDialog, {
                          type: "card",
                          sCardId: sCard.id,
                          sBackId: sCard.back?.sBackId,
                          langBack: deck?.back
                        })
                      }
                    >
                      <FontAwesomeIcon icon={faFlag} />
                    </SIconButton>
                  )}
                </>
              )}
              {sCard.theory && (
                <TheoryButton
                  onClick={(e) => {
                    e.stopPropagation();
                    source.course ? doOpenCourseView(sCard.id) : setOpenTheory(true);
                  }}
                  onTouchStart={(e) => e.stopPropagation()}
                  onMouseDown={(e) => e.stopPropagation()}
                >
                  {source.course ? <SchoolIcon icon={faGraduationCap} /> : <TheoryIcon />}
                </TheoryButton>
              )}
            </Top>
            <Front>
              <SpeechHintTooltip
                canOpen={
                  index === 0 &&
                  sCard.isInMyDeck === null &&
                  Number(deck?.stats?.total) > 12 &&
                  !sCard.theory &&
                  canTts(lang)
                }
                name={LONGPRESSHINT}
              >
                <Speech text={sCard.front} lang={lang} small />
              </SpeechHintTooltip>
              <UpSize lang={lang} weightNormal>
                {genderStyle(sCard.front, lang)}
              </UpSize>
            </Front>
            <Pronunciation lang={lang}>{sCard.pronunciation}</Pronunciation>
            <Hint>{sCard.hint}</Hint>
            <Translation blured={sCard.isInMyDeck === null && (!!index || !firstUnblured)}>
              {svg && <SFlatImg img={svg} size={42} />}
              <Back>{sCard.back?.value}</Back>
            </Translation>
          </In>
        )}
      </Wrap>

      {sCard.theory && !source.course && (
        <TheoryWrapper
          open={theoryIsOpened}
          onClick={() => setOpenTheory(false)}
          style={{ height: `calc(100% + ${70 + bottom + top}px)`, top: -70 }}
        >
          <Theory theory={sCard.theory} title={sCard.front} onContinue={() => setOpenTheory(false)} />
        </TheoryWrapper>
      )}
    </>
  );
};

export default PickCard;
