import HintDialog from "components/hint/HintDialog";
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";

const Gold = styled.p`
  color: ${({ theme }) => theme.duo.color.gold};
  font-weight: bold;
`;

type Props = {};

const BySharing2: React.FC<Props> = () => {
  return (
    <HintDialog name="trySharing2" position="bottom" emoji={"👏"}>
      <Trans parent={Gold}>Awesome!</Trans>
      <Trans parent="p">Now you know how to translate and save words from anywhere.</Trans>
      <Trans parent="p">Don't forget to save the phrase if you want to learn it.</Trans>
    </HintDialog>
  );
};

export default BySharing2;
