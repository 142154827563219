import React from "react";
import { useRecommendedSources } from "components/RecommendedSources/recommendedSourcesQuery";
import { Trans } from "react-i18next";
import { LibLine, LibLineIn, LibTitle } from "root/library/library/styled";
import SourceCard from "root/library/library/SourceCard";
import Toggler from "components/other/Toggler";
import TransVariable from "components/other/TransVariable";
import styled from "styled-components/macro";
import { useViewerDecks } from "queries/viewerQuery";

const STransVariable = styled(TransVariable)`
  margin-left: 3px;
  color: ${(p) => p.theme.duo.color.green};
`;

type Props = {
  lang: string;
};
const RecommendedSources: React.FC<Props> = ({ lang }) => {
  const { decks } = useViewerDecks();
  const deck = decks?.find((d) => d.front === lang);
  const recommendedSources = useRecommendedSources(deck);

  if (!recommendedSources?.length) return null;

  const basedOn = recommendedSources[0];
  const basedOnName = basedOn.name.length > 30 ? basedOn.name.substring(0, 27).trim() + "..." : basedOn.name.trim();

  return (
    <Toggler show={true}>
      <LibTitle $justText>
        <Trans>
          Because you finished <STransVariable text={basedOnName} />, we recommend:
        </Trans>
      </LibTitle>
      <LibLine>
        <LibLineIn $center>
          {recommendedSources.map((source, i) =>
            i > 0 ? <SourceCard source={source} key={source.id} isLatestVisited /> : null
          )}
        </LibLineIn>
      </LibLine>
    </Toggler>
  );
};

export default RecommendedSources;
