/**
 * @generated SignedSource<<e43f6043f82967b5857c4c344e63a6fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AdminUserActionsLoginAsMutation$variables = {
  email: string;
};
export type AdminUserActionsLoginAsMutation$data = {
  readonly loginAs: {
    readonly deviceId: string | null;
    readonly token: string;
  } | null;
};
export type AdminUserActionsLoginAsMutation = {
  response: AdminUserActionsLoginAsMutation$data;
  variables: AdminUserActionsLoginAsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "email",
    "variableName": "email"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminUserActionsLoginAsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "loginAs",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminUserActionsLoginAsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "loginAs",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b0456ef29100a80a1ca230b74386e907",
    "id": null,
    "metadata": {},
    "name": "AdminUserActionsLoginAsMutation",
    "operationKind": "mutation",
    "text": "mutation AdminUserActionsLoginAsMutation(\n  $email: String!\n) {\n  loginAs(email: $email) {\n    token\n    deviceId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1f4b96a836459422a71f46e0d6a9517a";

export default node;
