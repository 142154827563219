import React from "react";
import { Paper } from "@mui/material";
import styled, { css } from "styled-components/macro";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const SPaper = styled(Paper)<{ color: StatsColor; $empty: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 31.2%;
  cursor: pointer;
  padding: 10px 0;
  height: 100%;
  color: ${(props) => props.theme.duo.color[props.color]};
  ${({ $empty }) =>
    $empty &&
    css`
      background: transparent;
      box-shadow: none;
      border: 1px solid white;
    `}
`;
const LargeText = styled.div`
  font-size: ${({ theme }) => theme.duo.fontSize.large};
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
`;
const SmallText = styled.div`
  margin-top: 3px;
  font-size: 90%;
  font-weight: ${({ theme }) => theme.duo.fontWeight.w400};
`;
const SHelpIcon = styled(HelpOutlineIcon)`
  font-size: 18px;
  position: relative;
  top: 3px;
`;

type StatsColor = "primary" | "green" | "gold";
interface StatsPropsType {
  text: string;
  num: number;
  color: StatsColor;
  onClick: VoidFunction;
  showHelpIcon?: boolean;
}

const StatsPaper: React.FC<StatsPropsType> = ({ text, num, color, onClick, showHelpIcon }) => {
  return (
    <SPaper color={color} onClick={onClick} $empty={!num}>
      <LargeText>{num}</LargeText>
      <SmallText>
        {text} {showHelpIcon && <SHelpIcon />}
      </SmallText>
    </SPaper>
  );
};

export default StatsPaper;
