import { TTheory } from "types/TheoryTypes";
import { useSelector } from "react-redux";
import { selectTheoryLang } from "components/ReduxProvider";
import { TSource } from "types/SourceTypes";
import { isCardSorted } from "components/picker/pickCardTools";

export function useTheoryPickedLang(theory: TTheory | undefined): string {
  const theoryLang = useSelector(selectTheoryLang);
  if (!theory) return "";

  const notUncheckedOriginal = !(
    theoryLang &&
    theoryLang[theory.lang] === theory.lang &&
    theory.lang &&
    !theory.theoryIsChecked &&
    !theory.canEdit
  );
  return (theoryLang && notUncheckedOriginal && theoryLang[theory.lang]) || theory.langNative;
}

export function theoryGetContent(theory: TTheory, pickedLang: string): string {
  return pickedLang === theory.langNative
    ? theory.theoryNative
    : pickedLang !== theory.lang && pickedLang === "en" && theory.theoryEn
      ? theory.theoryEn
      : theory.theory;
}

export function getTheoryFirstLine(theoryContent: string): string {
  return theoryContent
    .split(/\n/g)[0]
    .replace(/(^\s*\*?)|(:?\s*\*?\s*$)/g, "")
    .trim(); // remove whitspace and asterisk from the beginning and end of the line, and colon at the end
}

export function useGetActiveTheoryTitle(source: TSource | undefined): string {
  let activeTheory: TTheory | undefined;
  if (source) {
    for (let i = 0; i < source.sCards.length; i++) {
      const currentCard = source.sCards[i];
      const prevCard = source.sCards[i - 1];

      if (currentCard.theory) {
        activeTheory = currentCard.theory;
      }

      if (activeTheory && !isCardSorted(currentCard)) {
        if (currentCard.theory && prevCard && isCardSorted(prevCard)) activeTheory = currentCard.theory;
        break;
      }
    }
  }

  const theoryPickedLang = useTheoryPickedLang(activeTheory);
  let firstLine: string = "";
  if (theoryPickedLang && activeTheory) {
    const theoryContent = theoryGetContent(activeTheory, theoryPickedLang);
    firstLine = getTheoryFirstLine(theoryContent);
  }

  return firstLine || source?.name || "";
}
