import React, { FC, useState } from "react";
import styled from "styled-components/macro";
import DialogWindow from "../other/DialogWindow";
import { Checkbox, FormControl, Button, FormControlLabel, Divider } from "@mui/material";
import useReportIssue from "../../hooks/useReportIssue";
import { Trans } from "react-i18next";
import { useEvent } from "react-use";
import { EVENT } from "../../tools/events";
import { useDispatch } from "react-redux";
import { setTheoryLang } from "components/ReduxProvider";

export type ReportIssueOpenSettings = {
  type: "card" | "theory";
  sCardId: string;
  sBackId?: string | null;
  langBack?: string | null;
  theoryOptions?: {
    isAutoTrans?: boolean;
    theoryLang?: string;
    displaySwitchToEnglish?: boolean;
  };
};

const SDialogWindow = styled(DialogWindow)`
  .MuiPaper-root {
    width: calc(100% - 10px);
    padding: 5px;
    padding-bottom: 15px;
    text-align: left;
  }
`;

const SButton = styled(Button)`
  display: block;
  margin: 20px auto 0;
`;

const STitle = styled.div`
  text-align: center;
  font-size: 16px;
  margin-bottom: 15px;
  /* margin-top: -20px; */
  font-family: "Noto Sans", Arial, Helvetica, sans-serif;
`;

const SFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    text-align: left;
    font-size: 14px;
    font-family: "Noto Sans", Arial, Helvetica, sans-serif;
  }
`;

export enum CardReportReason {
  FOREIGN_SIDE_OF_CARD = "foreign language side of the card",
  NATIVE_SIDE_OF_CARD = "native language side of the card",
  USE_DIFFERENT_IMAGE = "use a different image",
  EXAMPLES_MISLEADING_OR_WRONG = "examples of usage are misleading or wrong",
  ADD_MORE_EXAMPLES = "add more examples of usage"
}

enum TheoryReportReason {
  MISTAKES_IN_THEORY = "there are mistakes in the theory",
  MISTAKES_IN_TRANSLATION = "there are mistakes in the translation",
  INSUFFICIENT_THEORY = "the theory is insufficient"
}

const Warn = styled.div`
  line-height: 1.25em;
  color: ${({ theme }) => theme.palette.warning.main};
`;

const SDivider = styled(Divider)`
  margin: 20px 0px;
`;

const ReportIssueDialog: FC = () => {
  const dispatch = useDispatch();
  const [selectedReasons, setSelectedReasons] = useState<string[]>([]);

  const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const reason = event.target.value;
    setSelectedReasons((prev) => (prev.includes(reason) ? prev.filter((r) => r !== reason) : [...prev, reason]));
  };

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [reportTarget, setReportTarget] = useState<ReportIssueOpenSettings | undefined>();
  const reportIssue = useReportIssue();

  const handleSubmit = () => {
    if (!reportTarget) return;

    reportIssue({
      sCardId: reportTarget.sCardId,
      sBackId: reportTarget.sBackId || null,
      langBack: reportTarget.langBack || null,
      reasons: selectedReasons
    });
    handleClose();
  };

  const handleSwitchTheoryToEnglish = () => {
    const theoryLang = reportTarget?.theoryOptions?.theoryLang;
    if (!reportTarget || !theoryLang) return;
    dispatch(setTheoryLang({ forLang: theoryLang, pickedLang: "en" }));
    handleClose();
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleOpenReportPopup = (e: CustomEvent<ReportIssueOpenSettings>) => {
    setReportTarget(e.detail);
    setDialogOpen(true);
  };
  useEvent(EVENT.openReportIssueDialog, handleOpenReportPopup);

  const reasons = reportTarget?.type === "theory" ? Object.values(TheoryReportReason) : Object.values(CardReportReason);

  return (
    <SDialogWindow open={dialogOpen} onClose={handleClose}>
      <FormControl component="fieldset">
        {reportTarget?.theoryOptions?.isAutoTrans && reportTarget.type === "theory" && (
          <>
            <Warn>
              <Trans>Theory text was auto-translated.</Trans>
              {reportTarget.theoryOptions?.displaySwitchToEnglish && (
                <SButton variant="contained" color="warning" disableElevation onClick={handleSwitchTheoryToEnglish}>
                  <Trans>Switch to English</Trans>
                </SButton>
              )}
            </Warn>
            <SDivider />
          </>
        )}

        <STitle>
          {reportTarget?.type === "theory" ? <Trans>What's wrong?</Trans> : <Trans>What can be improved?</Trans>}
        </STitle>

        {reasons.map((reason) => (
          <SFormControlLabel
            key={reason}
            value={reason}
            control={
              <Checkbox
                checked={selectedReasons.includes(reason)}
                onChange={handleReasonChange}
                style={{ width: "32px", height: "32px" }}
              />
            }
            label={<Trans>{reason}</Trans>}
          />
        ))}
      </FormControl>
      <SButton variant="contained" disableElevation color="primary" onClick={handleSubmit}>
        <Trans>Send to review</Trans>
      </SButton>
    </SDialogWindow>
  );
};

export default ReportIssueDialog;
