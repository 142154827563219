import React from "react";
import styled from "styled-components/macro";
import { sourceQuery$data } from "../../../queries/sources/__generated__/sourceQuery.graphql";
import { useTranslation } from "react-i18next";
import { AnimatedCheckbox } from "../../../styled/AnimatedChecbox";
import { useViewerQuery } from "../../../queries/viewerQuery";

const Wrap = styled.div`
  text-align: center;
  padding-bottom: 20px;
  position: relative;
  margin: 0 auto;
`;

type Props = {
  source?: NonNullable<sourceQuery$data["sourceByUriOrId"]>;
  myRef: any;
  finishSource: (boolean) => void;
};

const Finish: React.FC<Props> = ({ source, myRef, finishSource }) => {
  const { t } = useTranslation();
  const { viewer } = useViewerQuery();

  if (!viewer) return null;

  return (
    <Wrap ref={myRef}>
      <AnimatedCheckbox
        label={t("Finished?")}
        checked={!!source?.rating?.finished}
        handleOnClick={() => finishSource(!source?.rating?.finished)}
      />
    </Wrap>
  );
};

export default Finish;
