import React from "react";
import styled from "styled-components/macro";
import { Trans } from "react-i18next";
import { Button } from "@mui/material";
import Present from "icons/Present";
import { useNavigate } from "react-router";

import { MAIN } from "../../root/RootRouter.config";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
`;
const PresentWrap = styled.div`
  margin-left: auto;
  svg {
    margin: 5px 0 13px;
  }
`;
const SButton = styled(Button)`
  position: absolute;
  top: 51px;
  right: -6px;
`;

const LevelAchievement: React.FC = () => {
  const navigate = useNavigate();

  const handleReward = (e) => {
    navigate(MAIN.url());
    e.stopPropagation();
  };

  return (
    <Wrap>
      <div>
        <Trans>You just leveled up!</Trans>
        <SButton size="small" onClick={handleReward}>
          <Trans>Get a reward</Trans>
        </SButton>
      </div>
      <PresentWrap onClick={handleReward}>
        <Present />
      </PresentWrap>
    </Wrap>
  );
};

export default LevelAchievement;
