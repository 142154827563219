import { useCallback, useRef } from "react";
import { useViewerQuery, useViewerXps } from "queries/viewerQuery";
import { LIKEITHINT, LikeState } from "root/main/main/LikeIt";
import launchRating, { LaunchRatingResult } from "tools/launchRatings";
import { useSetFlag } from "queries/users/setFlag";
import { APP_RATING_FLAG, LIKE_IT_RESULT_FLAG } from "queries/users/userFlagsModel";

interface CheckInterval {
  checkDelay: number; // check interval in ms
  totalXp: number; // required total xp
  streak?: number; // required current streak
}

const DAY = 24 * 60 * 60 * 1000;
const MONTH = 30 * DAY;

const getAppRatingChecks = (wasUserSatisfied: boolean): CheckInterval[] => {
  const initialChecks = wasUserSatisfied // if user liked app show more frequent prompts
    ? [
        { checkDelay: DAY, totalXp: 35 },
        { checkDelay: MONTH, totalXp: 110, streak: 2 },
        { checkDelay: 6 * MONTH, totalXp: 300, streak: 3 }
      ]
    : [{ checkDelay: 3 * MONTH, totalXp: 300, streak: 3 }]; // delay more for dissatisfied users

  const restChecks = [
    { checkDelay: 6 * MONTH, totalXp: 600, streak: 3 },
    { checkDelay: 6 * MONTH, totalXp: 900, streak: 3 },
    { checkDelay: 6 * MONTH, totalXp: 1200, streak: 3 },
    { checkDelay: 6 * MONTH, totalXp: 1500, streak: 3 },
    { checkDelay: 6 * MONTH, totalXp: 1800, streak: 3 },
    { checkDelay: 6 * MONTH, totalXp: 2100, streak: 3 },
    { checkDelay: 6 * MONTH, totalXp: 2400, streak: 3 }
  ];

  return [...initialChecks, ...restChecks];
};

function checkIfRatingShouldBeDisplayed(
  currentTotalXps: number,
  currentStreak: number,
  lastPromptTimeMs: number,
  check: CheckInterval
): boolean {
  const { checkDelay, totalXp, streak } = check;

  const delayMet = Date.now() - lastPromptTimeMs >= checkDelay;
  const xpMet = currentTotalXps >= totalXp;
  const streakMet = !streak || streak > currentStreak;

  return delayMet && xpMet && streakMet;
}

const useAppRating = () => {
  const { viewer, whenWasHintSeen, getFlag } = useViewerQuery();
  const { xps } = useViewerXps();
  const setFlag = useSetFlag();
  const ratingLaunched = useRef(false);

  const shouldDisplayRating = useCallback(() => {
    if (!xps || !viewer) return [false];

    const wasUserSatisfied = getFlag(LIKE_IT_RESULT_FLAG)?.value === LikeState.sattisfied; // user has already liked the app
    const checks = getAppRatingChecks(wasUserSatisfied); // get checks based on user satisfaction, if user liked app show prompts earlier
    const appRatingFlag = getFlag(APP_RATING_FLAG); // store last check index

    const getLastCheckIndexFromFlag = (flag?: string | null) => {
      if (!flag) return -1;
      const index = parseInt(flag, 10);
      return isNaN(index) ? -1 : index;
    };

    const indexOfNextUnchecked = getLastCheckIndexFromFlag(appRatingFlag?.value) + 1;
    if (indexOfNextUnchecked >= checks.length) return [false]; // all checks were already performed

    // check last prompt time, our like dialog is shown before app rating, so we can use it as a first reference
    const lastPromptTime = appRatingFlag ? appRatingFlag.updated : whenWasHintSeen(LIKEITHINT);

    const shouldDisplay = checkIfRatingShouldBeDisplayed(
      xps.total,
      xps.currentStreak,
      lastPromptTime === Infinity ? 0 : lastPromptTime, // if not seen yet, use 0
      checks[indexOfNextUnchecked] // check only the next unchecked
    );

    return [shouldDisplay, indexOfNextUnchecked];
  }, [xps, viewer, getFlag, whenWasHintSeen]);

  const displayRatingIfNeeded = useCallback(() => {
    if (ratingLaunched.current) return;
    const [shouldDisplay, checkIndex] = shouldDisplayRating();
    if (shouldDisplay) {
      ratingLaunched.current = true;
      launchRating().then((result) => {
        if (result === LaunchRatingResult.displayed || result === LaunchRatingResult.dismissed) {
          setFlag({ name: APP_RATING_FLAG, value: checkIndex.toString() });
        }
      });
    }
  }, [ratingLaunched, shouldDisplayRating, setFlag]);

  return displayRatingIfNeeded;
};

export default useAppRating;
