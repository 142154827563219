import React from "react";
import styled from "styled-components";
import { Dialog, Button } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/MoodOutlined";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useSeeHint } from "../../../components/hint/Hint";
import { useViewerQuery } from "../../../queries/viewerQuery";
import { PROXY, logEvent } from "tools/analyticEvents";
import { snackbar } from "tools/events";
import { useSetFlag } from "queries/users/setFlag";
import { LIKE_IT_RESULT_FLAG } from "queries/users/userFlagsModel";
import { redirectToStore } from "../../../tools/device";
import { isLaunchRatingSupported } from "tools/launchRatings";
import { FEEDBACK } from "../../profile/ProfileRouter.config";

export const LIKEITHINT = "likeIt";

export enum LikeState {
  sattisfied = "sattisfied",
  dissatisfied = "dissatisfied",
  veryDissatisfied = "veryDissatisfied"
}

const Wrap = styled.div`
  padding: 15px 30px;
  text-align: center;
`;

const Icons = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 5px 10px 15px;
  p {
    margin: 7px 0;
  }
  svg {
    font-size: 42px;
  }
  svg:nth-child(1) {
    color: ${({ theme }) => theme.duo.color.red};
  }
  svg:nth-child(3) {
    color: ${({ theme }) => theme.duo.color.green};
  }
`;
const SButton = styled(Button)`
  margin: 5px;
  width: calc(100% - 10px);
`;

type Props = {};

const LikeIt: React.FC<Props> = () => {
  const [like, setLike] = React.useState<boolean>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const seeHint = useSeeHint();
  const { hintWasSeen } = useViewerQuery();
  const setFlag = useSetFlag();

  return (
    <Dialog open={!hintWasSeen(LIKEITHINT)}>
      <Wrap>
        {like === undefined && (
          <>
            <p>{t("How do you like this app so far?")}</p>
            <Icons>
              <SentimentVeryDissatisfiedIcon
                onClick={() => {
                  setLike(false);
                  setFlag({
                    name: LIKE_IT_RESULT_FLAG,
                    value: LikeState.veryDissatisfied
                  });
                }}
              />
              <SentimentDissatisfiedIcon
                onClick={() => {
                  setLike(false);
                  setFlag({
                    name: LIKE_IT_RESULT_FLAG,
                    value: LikeState.dissatisfied
                  });
                }}
              />
              <SentimentSatisfiedOutlinedIcon
                onClick={() => {
                  setLike(true);
                  logEvent(PROXY.user_liked_app); // before it tracked only if user was redirected to the store, do not know if it is still needed
                  setFlag({
                    name: LIKE_IT_RESULT_FLAG,
                    value: LikeState.sattisfied
                  });

                  if (isLaunchRatingSupported()) {
                    snackbar(t("Thank you for the feedback!"));
                    seeHint(LIKEITHINT);
                  }
                }}
              />
            </Icons>
          </>
        )}

        {like === true && !isLaunchRatingSupported() && (
          <>
            <p>{t("Would you mind to rate us on the App Store?")}</p>
            <SButton
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => {
                seeHint(LIKEITHINT);
                logEvent(PROXY.user_liked_app);
                redirectToStore();
              }}
            >
              OK
            </SButton>
            <SButton fullWidth size="small" onClick={() => seeHint(LIKEITHINT)}>
              {t("Maybe later")}
            </SButton>
          </>
        )}

        {like === false && (
          <>
            <p>{t("Do you want to leave us a feedback on what we can improve?")}</p>
            <SButton
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => {
                seeHint(LIKEITHINT);
                navigate(FEEDBACK.url());
              }}
            >
              {t("Send Feedback")}
            </SButton>
            <SButton fullWidth size="small" onClick={() => seeHint(LIKEITHINT)}>
              {t("Maybe later")}
            </SButton>
          </>
        )}
      </Wrap>
    </Dialog>
  );
};

export default LikeIt;
