import { selectPickedLang } from "../components/ReduxProvider";
import { useSelector } from "react-redux";
import { browserLang } from "../tools/device";
import { langs } from "../sharedJs__generated/langsList";

export const useDeviceLang = () => {
  const pickedLang = useSelector(selectPickedLang);

  const bLang = browserLang();
  const deviceLang = pickedLang || bLang;
  const appLang = pickedLang || (langs[bLang] ? bLang : "en");
  const ttsLang = langs[appLang].tts === false ? "en" : appLang;

  return { deviceLang, appLang, ttsLang, browserLang: bLang };
};
