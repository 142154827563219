import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { useNavigate } from "react-router";
import { passwordMutation } from "./__generated__/passwordMutation.graphql";
import { useTranslation } from "react-i18next";
import { snackbar } from "tools/events";
import { useSetState } from "components/ReduxProvider";
import { MAIN } from "../../root/RootRouter.config";

const mutation = graphql`
  mutation passwordMutation($newPassword: String!, $hash: String) {
    password(newPassword: $newPassword, hash: $hash) {
      token
    }
  }
`;

export const usePasswordMutation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const setToken = useSetState("token");

  const [mutate] = useMutation<passwordMutation>(mutation, {
    onCompleted: ({ password }) => {
      if (password) {
        setToken(password.token);
        snackbar(t("Your password was changed."));
        navigate(MAIN.url());
      }
    }
  });

  return (variables: passwordMutation["variables"]) => mutate({ variables });
};
