/**
 * @generated SignedSource<<cb9c09f1c577dba4fdaf818ef12baa91>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FriendDialogFamilyMutation$variables = {
  memberId: string;
  subscriptionId: string;
};
export type FriendDialogFamilyMutation$data = {
  readonly famemberDelete: {
    readonly subscriptions: ReadonlyArray<{
      readonly famembers: ReadonlyArray<{
        readonly id: string;
        readonly name: string | null;
        readonly picture: string | null;
        readonly xps: {
          readonly currentStreak: number;
          readonly today: number;
          readonly total: number;
        };
      }>;
    }>;
  };
};
export type FriendDialogFamilyMutation = {
  response: FriendDialogFamilyMutation$data;
  variables: FriendDialogFamilyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "memberId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subscriptionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "memberId",
    "variableName": "memberId"
  },
  {
    "kind": "Variable",
    "name": "subscriptionId",
    "variableName": "subscriptionId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "subscriptions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "famembers",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "picture",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Xps",
          "kind": "LinkedField",
          "name": "xps",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "today",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currentStreak",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FriendDialogFamilyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "famemberDelete",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FriendDialogFamilyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "famemberDelete",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6cf892992028a0b72d69594c5a89c8e7",
    "id": null,
    "metadata": {},
    "name": "FriendDialogFamilyMutation",
    "operationKind": "mutation",
    "text": "mutation FriendDialogFamilyMutation(\n  $memberId: ID!\n  $subscriptionId: ID!\n) {\n  famemberDelete(memberId: $memberId, subscriptionId: $subscriptionId) {\n    subscriptions {\n      famembers {\n        id\n        name\n        picture\n        xps {\n          total\n          today\n          currentStreak\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7954a7b4a5051f6bc9aabd8669199cd4";

export default node;
