import React from "react";
import { Trans } from "react-i18next";
import LinkExt from "components/other/LinkExt";
import styled from "styled-components/macro";

const SLabel = styled.label`
  display: block;
  margin: 10px 0;
  font-size: 10px;
  line-height: 1.4em;
  text-align: center;
  color: ${(p) => p.theme.duo.color.textGrey};

  a {
    white-space: nowrap;
    color: ${(p) => p.theme.duo.color.textGrey};
    text-decoration: underline;
  }
`;
const RegistrationAgreeText: React.FC = () => {
  return (
    <SLabel>
      <Trans parent="div">
        By registration you agree with{" "}
        <LinkExt href="https://www.duocards.com/privacypolicy.html">privacy policy</LinkExt> and{" "}
        <LinkExt href="https://www.duocards.com/termsofuse.html">terms of use</LinkExt>
      </Trans>
    </SLabel>
  );
};

export default RegistrationAgreeText;
