import { isNil, omitBy, merge } from "lodash";
import { useSearchParams } from "react-router-dom";

export const queryString = (object?: Object) => {
  if (!object) return ``;
  const search = new URLSearchParams(omitBy(object, isNil) as any).toString();
  return search ? `?${search}` : ``;
};

export const useUpdateSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (updatedParams: Object, options?: { replace?: boolean }) => {
    const urlParams = Object.fromEntries(new URLSearchParams(searchParams));
    const newParams = merge(urlParams, updatedParams);

    setSearchParams(omitBy(newParams, isNil), { replace: options?.replace });
  };
};
