import { Box, Link } from "@mui/material";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";

const Content = styled(Box)`
  display: inline-block;
  padding: 0 ${(p) => p.theme.spacing(2)};
  color: ${(p) => p.theme?.duo?.palette?.greyDark};
  font-size: 80%;
  margin-top: ${(p) => p.theme.spacing(1)};
`;

const SLink = styled(Link)`
  :link,
  :visited,
  :hover,
  :active {
    color: ${(p) => p.theme?.duo?.palette?.greyDark};
    text-decoration-color: ${(p) => p.theme?.duo?.palette?.greyDark};
  }
`;

const Message = styled.span`
  display: inline;
  font-weight: 500;
`;

type Props = {
  remainingMessages: number;
  onShowMoreInfo: () => void;
};

export function RemainingMessages({ remainingMessages, onShowMoreInfo }: Props) {
  return (
    <Content>
      <Trans parent={Message}>Remaining messages</Trans>: {remainingMessages} |{" "}
      <SLink
        href="#"
        underline="always"
        onClick={(e) => {
          e.preventDefault();
          onShowMoreInfo();
        }}
      >
        <Trans>more info</Trans>
      </SLink>
    </Content>
  );
}
