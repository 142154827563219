import { Trans } from "react-i18next";
import { useSubscriptions } from "./subscriptionHooks";
import { StoreName } from "./StoreName";
import styled from "styled-components/macro";
import { Button } from "@mui/material";
import { useViewerQuery } from "queries/viewerQuery";
import React from "react";
import { useGetState } from "components/ReduxProvider";
import { isBrowser } from "tools/device";
import { useDBLog } from "hooks/useDBLog";
import { SUBDBLOG } from "sharedJs__generated/constants";

const Footer = styled.footer`
  color: #999;
  font-size: 11px;
  line-height: 1.35em;
  margin: 12px 0 8px;
`;

const Restore = styled(Button)`
  font-size: 12px;
  font-weight: normal;
  display: block;
  margin: 5px auto;
  color: grey;
`;

export const SubscriptionFooter = () => {
  const { store } = useSubscriptions();
  const { viewer } = useViewerQuery();
  const purchasedOffer = useGetState("purchasedOffer");
  const dblog = useDBLog();
  const [clicked, setClicked] = React.useState(false);

  const restore = () => {
    store.restorePurchases();
    dblog(SUBDBLOG, `RESTORE PURCHASES clicked`, { purchasedOffer });
    setClicked(true);
  };

  if (isBrowser()) return null;

  return (
    <Footer>
      <Trans>Payment provider</Trans>: <StoreName /> Store
      <br />
      <Trans>Subscription can be cancelled anytime after purchase and you still get your Premium period.</Trans>
      {!clicked && (store?.restorePurchases as any) && !viewer?.subscription && (
        <Trans parent={Restore} onClick={restore}>
          Restore purchase
        </Trans>
      )}
    </Footer>
  );
};
