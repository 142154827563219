import React from "react";
import styled, { css } from "styled-components";
import LinkExt from "../components/other/LinkExt";
import { stripGens } from "sharedJs__generated/gens";

const P = styled.p<{ type?: "title" }>`
  ${({ type }) =>
    type === "title" &&
    css`
      font-weight: bold;
      margin-top: 35px;
    `}
`;
const Substitude = styled.span`
  position: relative;
  bottom: -2px;
`;

export const includesHighlighter = (text: string, highlighter: string, lang: string) => {
  const highlighters = highlighter ? [highlighter] : [];
  if (lang && highlighter) {
    const striped = stripGens(highlighter, lang);
    if (striped !== highlighter) highlighters.push(striped);
    highlighters.push(stripGens(highlighter, lang));
  }

  return highlighters.find((wordString) => wordString && text.toLowerCase().indexOf(wordString.toLowerCase()) !== -1);
};

const highlight = (text: string, highlighter?: string, lang?: string, hide?: boolean) => {
  if (!text) return null;
  if (!highlighter || !lang) return text;

  const indexdWord = includesHighlighter(text, highlighter, lang);
  if (!indexdWord) return text;

  const index = text.toLowerCase().indexOf(indexdWord.toLowerCase());
  if (index === -1) return text;

  return (
    <>
      {text.substr(0, index)}
      {hide ? <Substitude>____</Substitude> : <strong>{text.substr(index, indexdWord.length)}</strong>}
      {highlight(text.substr(index + indexdWord.length), highlighter, lang, hide)}
    </>
  );
};

type Options = {
  before?: React.ReactNode;
  highlighter?: string;
  lang?: string;
  Wrapper?: React.FC;
  hide?: boolean;
};
export const parser = (text: string | null, options?: Options) => {
  const { highlighter, Wrapper, before, lang, hide } = options || {};

  if (!text) return null;
  const paragraphs = text?.split(new RegExp("\\n\\s*\\n*"));
  return paragraphs.map((prgrph, i) => {
    let cleaned = prgrph;
    let type: "title" | undefined;
    if (prgrph.indexOf("# ") === 0) {
      cleaned = prgrph.substr(2);
      type = "title";
    }

    const Component = Wrapper || P;

    const lines = cleaned?.split(new RegExp("\\n"));

    return (
      <Component key={i} type={type}>
        {before && i === 0 && before}
        {lines.map((line, i) => (
          <React.Fragment key={i}>
            {highlight(line, highlighter, lang, hide)}
            <br />
          </React.Fragment>
        ))}
      </Component>
    );
  });
};

export const clipboardToAnnotatedText = (clipboardData): string => {
  const headingsTextR = /<h[1-6].*?>(.*?)<\/h[1-6]>/gis;
  const html = clipboardData.getData("text/html");
  let text = clipboardData.getData("text");

  const headingMatches: string[] = [...html.matchAll(headingsTextR)].map((match) => {
    return match[1];
  });

  headingMatches.forEach((headingText) => {
    text = text.replace(headingText, "# " + headingText);
  });

  return text;
};

export const removeDiacritics = (str: string) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[¿,.?!¡]/g, "");
};

// source: https://stackoverflow.com/a/63401250/844628
export const renderWithLinks = (str: string) => {
  const RE_URL = /((\w+:\/\/)|(www\.))\S+/g;
  let match;
  const results: any = [];
  let lastIndex = 0;
  while ((match = RE_URL.exec(str))) {
    const link = match[0];
    if (lastIndex !== match.index) {
      const text = str.substring(lastIndex, match.index);
      results.push(<React.Fragment key={results.length}>{text}</React.Fragment>);
    }
    results.push(
      <LinkExt key={results.length} href={link}>
        {link}
      </LinkExt>
    );
    lastIndex = match.index + link.length;
  }
  if (results.length === 0) {
    return str;
  }
  if (lastIndex !== str.length) {
    results.push(<React.Fragment key={results.length}>{str.substring(lastIndex)}</React.Fragment>);
  }
  return results;
};

// source: https://github.com/aceakash/string-similarity
// 0 = no match, 1 = perfect match
export const compareTwoStringsUsingDiceCoefficient = (first: string, second: string): number => {
  // Normalize the strings by removing spaces and converting to lowercase
  first = first.replace(/\s+/g, "").toLowerCase();
  second = second.replace(/\s+/g, "").toLowerCase();

  // Handle edge cases
  if (!first.length && !second.length) return 1; // Both strings are empty
  if (!first.length || !second.length) return 0; // One of the strings is empty
  if (first === second) return 1; // Strings are identical
  if (first.length === 1 && second.length === 1) return 0; // Both are single-character strings
  if (first.length < 2 || second.length < 2) return 0; // Either string is a single-character string

  // Create bigrams for the first string
  const firstBigrams = new Map<string, number>();
  for (let i = 0; i < first.length - 1; i++) {
    const bigram = first.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram)! + 1 : 1;
    firstBigrams.set(bigram, count);
  }

  // Count matching bigrams in the second string
  let intersectionSize = 0;
  for (let i = 0; i < second.length - 1; i++) {
    const bigram = second.substring(i, i + 2);
    const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram)! : 0;

    if (count > 0) {
      firstBigrams.set(bigram, count - 1);
      intersectionSize++;
    }
  }

  // Calculate the Dice Coefficient
  return (2.0 * intersectionSize) / (first.length + second.length - 2);
};
