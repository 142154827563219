import React from "react";
import styled from "styled-components/macro";
import { flagLabel } from "../../tools/langs";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch } from "react-redux";
import { setPickedLang } from "../ReduxProvider";
import { useDeviceLang } from "../../hooks/deviceHooks";
import { useViewerDecks } from "queries/viewerQuery";
import AppLangMenu from "../LangMenu/AppLangMenu";
import { isAppLangGenerated } from "sharedJs__generated/langsList";
import GeneratedAppLangDialog from "components/other/GeneratedAppLangDialog";

const Wrap = styled.div`
  opacity: 0.5;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.4em;
  text-align: center;
  margin: 10px 10px 10px;
`;
const Change = styled.div`
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  img {
    transform: scale(0.8);
    margin: 0 -2px;
  }
`;

type Props = {
  inTutorial?: boolean;
};

const AppLang: React.FC<Props> = ({ inTutorial }) => {
  const { appLang } = useDeviceLang();
  const dispatch = useDispatch();
  const { deck } = useViewerDecks();
  const [warningOpen, setWarningOpen] = React.useState(false);

  if (inTutorial && deck?.back && appLang === deck.back && !isAppLangGenerated(appLang)) return null;

  const handleSelectLang = (lang) => {
    dispatch(setPickedLang(lang));

    if (isAppLangGenerated(lang)) {
      setWarningOpen(true);
    }
  };

  return (
    <Wrap style={inTutorial ? { marginTop: -5 } : undefined}>
      <AppLangMenu
        title={
          <Change>
            {flagLabel(appLang)}
            <ArrowDropDownIcon style={{ width: 20 }} />
          </Change>
        }
        onSelect={handleSelectLang}
        value={appLang}
      />

      {warningOpen && <GeneratedAppLangDialog />}
    </Wrap>
  );
};

export default AppLang;
