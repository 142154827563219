import React from "react";
import styled from "styled-components/macro";
import { useEffectOnce } from "react-use";

const Wrap = styled.div<{ $atBottom: boolean }>`
  position: sticky;
  bottom: 0;
  padding: 20px;
  background: white;
  z-index: 5;
  border-top: 1px solid white;

  ${(p) =>
    !p.$atBottom &&
    `
    border-top: 1px solid ${p.theme.duo.color.grey};
    box-shadow: ${p.theme.duo.boxShadow.normal};
  `}
`;

type Props = {
  children: React.ReactNode;
  className?: string;
};

const StickyBottom: React.FC<Props> = ({ children, className }) => {
  const thisElement = React.useRef<HTMLDivElement>(null);
  const [atBottom, setAtBottom] = React.useState(true);

  function getScrollParent(node) {
    if (node == null) {
      return null;
    }

    if (node.scrollHeight > node.clientHeight) {
      return node;
    } else {
      return getScrollParent(node.parentNode);
    }
  }

  const checkScroll = () => {
    const scrollParent = getScrollParent(thisElement.current);
    if (scrollParent && scrollParent.scrollTop + scrollParent.offsetHeight >= scrollParent.scrollHeight) {
      setAtBottom(true);
    } else {
      scrollParent && setAtBottom(false);
    }
  };

  useEffectOnce(() => {
    const iId = setInterval(checkScroll, 300);
    return () => clearInterval(iId);
  });

  return (
    <Wrap $atBottom={atBottom} className={className} ref={thisElement}>
      {children}
    </Wrap>
  );
};

export default StickyBottom;
