import React from "react";
import styled from "styled-components/macro";
import DialogWindow from "../other/DialogWindow";
import { Trans } from "react-i18next";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { useGetState, useSetState } from "components/ReduxProvider";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";

const SButton = styled(Button)`
  margin-bottom: 20px !important;
`;

type Props = {};

const TooManyDecksDialog: React.FC<Props> = () => {
  const tooManyDecks = useGetState("tooManyDecks");
  const setTooManyDecks = useSetState("tooManyDecks");
  const navigate = useNavigate();

  return (
    <DialogWindow open={!!tooManyDecks} onClose={() => setTooManyDecks(false)}>
      <Trans>You can have only two decks in DuoCards Free.</Trans>
      <Trans parent="p">Subscribe to DuoCards Plus and have more.</Trans>
      <SButton
        color="primary"
        variant="contained"
        onClick={() => {
          setTooManyDecks(false);
          navigate(SUBSCRIPTION.url());
        }}
      >
        <Trans>Subscribe</Trans>
      </SButton>
    </DialogWindow>
  );
};

export default TooManyDecksDialog;
