import { Button, Grid } from "@mui/material";
import Flames, { CubeImg } from "components/fire/Flames";
import { useSeeHint } from "components/hint/Hint";
import { useSetState } from "components/ReduxProvider";
import React from "react";
import { Trans } from "react-i18next";
import { useEffectOnce } from "react-use";
import {
  STREAK_FREEZE_LIMIT,
  STREAK_FREEZE_LIMIT_SUBSCRIBER,
  STREAK_FREEZE_QUEST,
  STREAK_FREEZE_XPS
} from "sharedJs__generated/constants";
import styled from "styled-components/macro";
import { dispatchEvent, EVENT } from "tools/events";
import { strengthFn } from "../../../../components/fire/FireStats";
import { useEditUser } from "../../../../queries/users/editUser";
import { useViewerQuery } from "../../../../queries/viewerQuery";
import { useAnimateTexts } from "../../../../hooks/useAnimateTexts";

const Wrap = styled.div``;
const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px 0 4px;
`;
const SFlames = styled(Flames)`
  display: inline-block;
  transform: scale(1.2);
  margin: 0 0 0 10px;
  top: 5px;
`;
const SButton = styled(Button).attrs({ variant: "contained", color: "primary" })<{ $hide?: boolean }>`
  margin: 10px 0 4px;
  transition: all 2s;
  opacity: ${({ $hide }) => ($hide ? 0 : 1)};
`;
const SCubeImg = styled(CubeImg)`
  width: 50px;
  top: 0;
  left: 0;
`;
const SCubeWrap = styled.div`
  position: relative;
  line-height: 50px;
  width: 50px;
  text-align: center;
  font-weight: bold;
  margin: 0 auto 50px;
`;
const PDiv = styled.div`
  margin: 16px 0;
`;

type Props = {};

const StreakFreeze: React.FC<Props> = () => {
  const seeHint = useSeeHint();
  const setShowQuestsBackdrop = useSetState("showQuestsBackdrop");
  const editUser = useEditUser();
  const { viewer, noPayments, hintWasSeen } = useViewerQuery();
  const nonSubscriber = !viewer?.subscription && !noPayments();
  const [s0, s1, s2] = useAnimateTexts({
    delay: 1200,
    initialDelay: 1500,
    disable: hintWasSeen(STREAK_FREEZE_QUEST)
  });

  useEffectOnce(() => {
    setShowQuestsBackdrop(true);
    dispatchEvent(EVENT.updateMain);
  });

  return (
    <Wrap>
      <Icons>
        <Grid container>
          <Grid item xs={12}>
            <SCubeWrap>
              <SCubeImg coldness={5} className="nofilter" />
            </SCubeWrap>
          </Grid>
          <Grid item xs={12}>
            <Trans parent="p">Sometimes you just miss a learning day and you need to freeze your daily series.</Trans>
            <PDiv style={s0}>
              <Trans>
                <b>
                  Earn
                  <SFlames strength={strengthFn(1)} xps={1} noZZ /> and you will get your first freeze.
                </b>
              </Trans>
            </PDiv>
            <PDiv style={s1}>
              <Trans>
                Psst: normally it’s <SFlames strength={strengthFn(STREAK_FREEZE_XPS)} xps={STREAK_FREEZE_XPS} noZZ />
              </Trans>
            </PDiv>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Icons>
      <SButton
        style={s2}
        onClick={() => {
          setShowQuestsBackdrop(false);
          setTimeout(() => {
            seeHint(STREAK_FREEZE_QUEST);
            editUser({
              streakFreezeXps: STREAK_FREEZE_XPS - 1,
              streakFreeze: (nonSubscriber ? STREAK_FREEZE_LIMIT : STREAK_FREEZE_LIMIT_SUBSCRIBER) - 1
            });
            dispatchEvent(EVENT.updateMain);
          }, 1200);
        }}
      >
        <Trans>Got it</Trans>
      </SButton>
    </Wrap>
  );
};

export default StreakFreeze;
