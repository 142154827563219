import styled from "styled-components/macro";

export const BrandTitle = styled.h1`
  font-size: 48px;
  font-family: "Baloo Chettan 2", Arial, sans-serif;
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
  color: ${({ theme }) => theme.duo.color.primary};
  background: ${({ theme }) => theme.duo.color.themeGradient};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
