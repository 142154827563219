import React from "react";
import { configureStore } from "@reduxjs/toolkit";
import { Provider, useDispatch, useSelector } from "react-redux";
import { debounce, random } from "lodash";
import { XpWarnings } from "./fire/xpHooks";
import { TapPhases } from "root/tutorial/steps/TapCard";
import { browserLang, isBrowser } from "tools/device";
import { motivationGigs } from "root/main/main/quests/misc/motivationGigs";
import { getStoragedJsVersion } from "tools/storageJsVersion";
import { TLoopFilter } from "root/main/learn/CardsFilter";
import { CardData } from "dexieDB/dexie";
import { viewerQuery$data } from "queries/__generated__/viewerQuery.graphql";
import { viewerQuery_friends$data } from "queries/__generated__/viewerQuery_friends.graphql";
import { viewerQuery_xps$data } from "queries/__generated__/viewerQuery_xps.graphql";
import { viewerQuery_decks$data } from "queries/__generated__/viewerQuery_decks.graphql";
import { StoreId } from "root/profile/subscription/subscriptionIds";
import { SuggestionsTranslations } from "../sharedJs__generated/constants";

const defaultJpns = ["Hiragana", "Romaji"];

const LOGOUT = "LOGOUT";
const SET_TOKEN = "SET_TOKEN";
const SET_VOICEOFF = "SET_VOICEOFF";
const SET_ROMANOFF = "SET_ROMANOFF";
const PAIROFF = "PAIROFF";
const SET_PLANG = "SET_PLANG";
const AD = "AD";
const XP = "XP";
const XP_BURN = "XP_BURN";
const XP_COLDNESS = "XP_COLDNESS";
const XP_TOO_MANY_WARNING_SEEN = "XP_TOO_MANY_WARNING_SEEN";
const INVITE = "INVITE";
const THEME = "THEME";
const DEVICEID = "DEVICEID";
const JAPALPHABETH = "JAPALPHABETH";
const THEORYLANG = "THEORYLANG";
const LEARNLIMIT = "LEARNLIMIT";
const TAPPHASE_NEXT = "TAPPHASE_NEXT";
const QUESTS_ADDSRC = "QUESTS_ADDSRC";
const CAN_RECOMMEND = "CAN_RECOMMEND";
const ADD_CONNECTION_ISSUE = "ADD_CONNECTION_ISSUE";

export type CardValues = {
  id?: string;
  svg?: { flatId: string; url: string } | null;
  front: string;
  back: string;
  flipped?: boolean;
  hint?: string | null;
  theory?: string | null;
  sourceId?: string | null;
  sCardId?: string | null;
  sBackId?: string | null;
  cardEditCallback?: VoidFunction;
  noDeck?: boolean;
};
export type Theme = "default" | "dark" | "pink" | "sepia";
export type Subscriptions = { [storeId: string]: SubType };
export type SubType = {
  storeId: StoreId;
  price?: string;
  priceMicros: number;
  canPurchase: boolean /*...*/;
  countryCode?: string;
  currency: string;
};
export type Quests = {
  // deprecated
  addBySharing?: boolean;
  addChrome?: boolean;
};

export type SingleLangTranslationMap = {
  [key: string]: string;
};

export type AllLangsTranslationsMap = {
  [languageCode: string]: SingleLangTranslationMap;
};

export type ConnectionIssue = {
  message: string;
  time: number;
  networkData?: { [key: string]: any };
};

export type State = {
  token?: string;
  releaseId: string;
  deckId?: string;
  voiceOff?: boolean;
  romanOff?: boolean;
  pairingOff?: boolean;
  deckCreate?: { lang: string };
  pickedLang?: string;
  routeHistory?: { pathname?: string; search?: string; hash?: string }[];
  // false => error
  subscriptions?: false | Subscriptions;
  alreadyPurchasedBy?: { email: string; transactionId: string };
  adStack: number; // 0-100 (%)
  tooManyDecks?: boolean;
  xp: {
    stack: number; // 0-100 (%)
    strength: number; // -1 frozen, 0 grey sleeping, 1-5 size of the fire
    coldness: number; // 0 no ice cube, 1-5 times 20% opacity of ice cube
    overflowed: boolean;
    lastActivity?: number;
    warn?: XpWarnings;
    tooManyWarningSeen: boolean;
    fireWarningClicked?: boolean;
  };
  courseView: {
    sourceUriOrId: string;
    langNative: string; // what language should the source be translated into
    langLearning: string; // used to determine my deck to see how far I got in the course (I can have French deck but in Library I might have selected German)
    openTheoryByCardId?: string; // if we want to override which theory should be open by default
    allowScrollAnimation?: boolean; // we show some animations only when user finishes a theory block
    canShowTooMany?: boolean; // we recommend user to go to learn only when he finishes a theory block
  };
  inviteDialog?: boolean;
  libTopic?: string;
  theme?: Theme;
  deviceId?: string;
  deviceOs?: string;
  lastVoice?: { voiceId: string; text: string };
  japAlphabeth?: string[];
  theoryLang?: { [key: string]: string };
  learnLimit?: boolean;
  tutorialTapPhase?: string;
  motivationGigIndex: number;
  viewer?: viewerQuery$data["viewer"];
  decks?: viewerQuery_decks$data["decks"];
  xps?: viewerQuery_xps$data["xps"];
  friends?: viewerQuery_friends$data["friends"];
  quests?: Quests;
  lastGrammarLang?: string;
  loopFilter?: TLoopFilter;
  loopFilterDeckId?: string;
  savedLoopFilter?: TLoopFilter;
  connectionProblem?: boolean;
  loaderGlobal?: boolean;
  lastSeenTotalXp?: number;
  pickedFewCards?: boolean;
  questsExplained?: boolean;
  ownWordAdded?: boolean;
  grammarUsage?: {
    dateISO: string;
    views: number;
  };
  showQuestsBackdrop?: boolean;
  present?: boolean;
  storageSizeChecked?: number;
  needsUpdate?: boolean;
  pettingMemo?: boolean;
  canRecommend?: string[];
  questStartsOnXp?: { name: string; xp: number };
  ignoreShareAndChrome?: boolean;
  fcmId?: string | null;
  closeSale?: boolean;
  idbCardsData: CardData[];
  adminOrigin?: boolean;
  trackingDenied?: boolean;
  canRequestAdmobAds?: boolean;
  purchasedOffer?: { orderedId?: StoreId | null; finishedId?: StoreId | null };
  boughtAlready?: StoreId;
  langTranslates?: AllLangsTranslationsMap;
  orderOpened?: number;
  bottomCardActive?: boolean;
  rightSwipesCount?: number;
  suggestionsTranslations?: { [lang: string]: SuggestionsTranslations };
  showSentenceHint?: boolean;
  recentConnectionIssues?: ConnectionIssue[];
  noCardsDialogShown?: string;
  lastQuestXps?: number;
  questAiAssistant?: boolean;
  isAudioRecording?: boolean;
  logUIActive?: boolean;
  onboardingFeaturesScreenIndex?: number;
  fewMoreCardsClicked?: boolean;
  abtest_openLogin?: "on" | "off";
};

const persisted = localStorage.getItem("reduxState");
const fromStorage: State = persisted ? JSON.parse(persisted) : { pickedLang: browserLang() }; // setting default pickedLang only to new users who don't have redux in storage yet, we don't want to switch lang for existing users due to historical reasons
const initState: State = {
  adStack: fromStorage.token ? 40 : 0,
  xp: {
    stack: 0,
    strength: 0,
    coldness: 0,
    overflowed: false,
    tooManyWarningSeen: false
  },
  courseView: {
    sourceUriOrId: "",
    langNative: "",
    langLearning: ""
  },
  idbCardsData: [],
  motivationGigIndex: random(0, motivationGigs.length - 1),
  releaseId: getStoragedJsVersion()
};

// Generic selector
// NOTE: when using, define generic type as State['{stateName}']

export const useGetState = <K extends keyof State>(stateName: K) => {
  return useSelector((state: State) => state[stateName]);
};

// TODO: replace all simple selectors with the generic one

export const selectRomanOff = (state: State) => state.romanOff;
export const selectPairingOff = (state: State) => state.pairingOff;
export const selectPickedLang = (state: State) => state.pickedLang;
export const selectAdStack = (state: State) => state.adStack;
export const selectXp = (state: State) => state.xp;
export const selectInviteDialog = (state: State) => state.inviteDialog;
export const selectTheme = (state: State) => state.theme || "default";
export const selectDeviceId = (state: State) => state.deviceId;
export const selectJapAlphabeth = (state: State) =>
  state.japAlphabeth === undefined ? defaultJpns : state.japAlphabeth;
export const selectTheoryLang = (state: State) => state.theoryLang;
export const selectLearnLimit = (state: State) => state.learnLimit;
export const selectTutorialTapPhase = (state: State) => state.tutorialTapPhase;
export const selectMotivationGig = (state: State) => state.motivationGigIndex;
export const selectCanRecommend = (lang: string | undefined) => (state: State) =>
  state.canRecommend?.some((l) => l === lang);

// Generic setter
// NOTE: when using, define generic type as State['{stateName}']
export const useSetState = <K extends keyof State>(stateName: K) => {
  const dispatch = useDispatch();
  return React.useCallback((payload: State[K]) => dispatch({ type: stateName, payload }), [dispatch, stateName]);
};

// TODO: replace all simple setters with the generic one

export const logout = () => ({ type: LOGOUT });
export const setRomanOff = (payload: State["romanOff"]) => ({ type: SET_ROMANOFF, payload });
export const setPairingOff = (payload: State["pairingOff"]) => ({ type: PAIROFF, payload });
export const setPickedLang = (payload: State["pickedLang"]) => ({ type: SET_PLANG, payload });
export const incAdStack = (payload: number | null) => ({ type: AD, payload });
export const setXp = (payload: State["xp"]) => ({ type: XP, payload });
export const setXp_burn = (payload: { warn?: XpWarnings }) => ({ type: XP_BURN, payload });
export const setXpColdness = (payload: number) => ({ type: XP_COLDNESS, payload });
export const setXpTooManyWarningSeen = () => ({ type: XP_TOO_MANY_WARNING_SEEN });
export const setInviteDialog = (payload: State["inviteDialog"]) => ({ type: INVITE, payload });
export const setTheme = (payload: State["theme"]) => ({ type: THEME, payload });
export const setDeviceId = (payload: State["deviceId"]) => ({ type: DEVICEID, payload });
export const setJapAlphabeth = (payload: State["japAlphabeth"]) => ({
  type: JAPALPHABETH,
  payload
});
export const setTheoryLang = (payload: { forLang: string; pickedLang: string }) => ({
  type: THEORYLANG,
  payload
});
export const setLearnLimit = (payload: State["learnLimit"]) => ({ type: LEARNLIMIT, payload });
export const nextTutorialTapPhase = () => ({ type: TAPPHASE_NEXT });
export const setQuestAddSource = (name: keyof Quests) => ({ type: QUESTS_ADDSRC, payload: { name } });
export const setCanRecommend = (lang: string, canRecommend: boolean) => ({
  type: CAN_RECOMMEND,
  payload: { lang, canRecommend }
});
export const addConnectionIssue = (message: string, networkData?: { [key: string]: any }) => ({
  type: ADD_CONNECTION_ISSUE,
  payload: { message, networkData, time: Date.now() }
});

const rootReducer = (
  state: State = { ...fromStorage, ...initState },
  action: { type: string; payload: any }
): State => {
  switch (action.type) {
    case SET_TOKEN:
      return { ...state, token: action.payload };
    case SET_VOICEOFF:
      return { ...state, voiceOff: action.payload };
    case SET_ROMANOFF:
      return { ...state, romanOff: action.payload };
    case PAIROFF:
      return { ...state, pairingOff: action.payload };
    case SET_PLANG:
      return { ...state, pickedLang: action.payload };
    case AD:
      return { ...state, adStack: action.payload ? state.adStack + action.payload : 0 };
    case XP:
      return { ...state, xp: action.payload };
    case XP_COLDNESS:
      return {
        ...state,
        xp: {
          ...state.xp,
          coldness: action.payload > 5 ? 5 : action.payload < 0 ? 0 : action.payload
        }
      };
    case XP_BURN:
      let strength: number;
      let coldness: number;
      let stack = state.xp.stack;
      if (action.payload.warn) {
        strength = state.xp.strength > 1 ? state.xp.strength - 1 : 1;
        coldness = state.xp.coldness < 5 ? state.xp.coldness + 1 : 5;
        if (state.xp.coldness < 5 && coldness === 5) {
          stack = stack >= 20 ? stack - 20 : 0;
        }
      } else {
        coldness = 0;
        strength = state.xp.strength < 5 ? state.xp.strength + 1 : 5;
        strength === 0 && strength++; // skip 0
      }

      return {
        ...state,
        xp: {
          ...state.xp,
          warn: action.payload.warn,
          lastActivity: Date.now(),
          strength,
          stack,
          coldness
        }
      };
    case XP_TOO_MANY_WARNING_SEEN:
      return {
        ...state,
        xp: {
          ...state.xp,
          tooManyWarningSeen: true
        }
      };
    case INVITE:
      return { ...state, inviteDialog: action.payload };
    case THEME:
      return { ...state, theme: action.payload };
    case DEVICEID:
      return { ...state, deviceId: action.payload };
    case JAPALPHABETH:
      return { ...state, japAlphabeth: action.payload };
    case THEORYLANG:
      return {
        ...state,
        theoryLang: { ...state.theoryLang, [action.payload.forLang]: action.payload.pickedLang }
      };
    case LEARNLIMIT:
      return { ...state, learnLimit: action.payload };
    case TAPPHASE_NEXT:
      const phaseIndex = TapPhases.findIndex((p) => p === state.tutorialTapPhase);
      return { ...state, tutorialTapPhase: TapPhases[phaseIndex + 1] || state.tutorialTapPhase };
    case QUESTS_ADDSRC:
      return {
        ...state,
        quests: { ...state.quests, [action.payload.name]: true }
      };
    case CAN_RECOMMEND:
      const can = action.payload.canRecommend;
      const is = !!state.canRecommend?.includes(action.payload.lang);
      if (can === is) return state;
      return {
        ...state,
        canRecommend: can
          ? [...(state.canRecommend || []), action.payload.lang]
          : state.canRecommend?.filter((l) => l !== action.payload.lang)
      };
    case LOGOUT:
      // states which do persist logout
      return {
        ...initState,
        purchasedOffer: state.purchasedOffer,
        pickedLang: state.pickedLang,
        subscriptions: state.subscriptions,
        deviceId: isBrowser() ? undefined : state.deviceId,
        grammarUsage: state.grammarUsage,
        fcmId: state.fcmId,
        trackingDenied: state.trackingDenied,
        canRequestAdmobAds: state.canRequestAdmobAds,
        boughtAlready: state.boughtAlready,
        langTranslates: state.langTranslates
      };
    case ADD_CONNECTION_ISSUE:
      return {
        ...state,
        recentConnectionIssues: [...(state.recentConnectionIssues || []), action.payload].slice(-10) // keep only last 10 issues
      };

    // by default saves the payload into action.type directly
    default:
      return { ...state, [action.type]: action.payload };
  }

  // TODO: update all non-specific reducers to use default branch
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["subscriptions"],
        ignoredPaths: ["subscriptions"]
      }
    })
});

const debouncedLocalStoreUpdate = debounce(() => {
  if (!document.hasFocus()) return;
  const actualState = store.getState();
  // states which do not persist sessions
  const state: State = {
    ...actualState,
    deckCreate: undefined,
    routeHistory: undefined,
    // adStack - INITIALIZED
    tooManyDecks: undefined,
    // xp - INITIALIZED
    inviteDialog: undefined,
    learnLimit: undefined,
    tutorialTapPhase: undefined,
    // motivationGigIndex: - INITIALIZED
    courseView: initState.courseView,
    connectionProblem: undefined,
    loaderGlobal: undefined,
    pickedFewCards: undefined,
    loopFilter: undefined,
    loopFilterDeckId: undefined,
    showQuestsBackdrop: undefined,
    bottomCardActive: undefined,
    pettingMemo: undefined,
    ignoreShareAndChrome: undefined,
    needsUpdate: undefined,
    fcmId: undefined,
    closeSale: undefined,
    lastVoice: undefined,
    suggestionsTranslations: undefined,
    idbCardsData: [],
    // store only the finishedId over sessions because on session start is triggering handleVerifiedOrder()
    purchasedOffer: actualState.purchasedOffer?.finishedId
      ? { finishedId: actualState.purchasedOffer.finishedId }
      : undefined,
    orderOpened: undefined,
    showSentenceHint: undefined,
    recentConnectionIssues: undefined,
    onboardingFeaturesScreenIndex: undefined
  };
  localStorage.setItem("reduxState", JSON.stringify(state));
});

store.subscribe(debouncedLocalStoreUpdate);

const ReduxProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <Provider store={store}>{children}</Provider>;
};

export default ReduxProvider;
