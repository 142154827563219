/**
 * @generated SignedSource<<5bc0505b1ca6c185f45897d2468d7ba7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Difficulty = "beginner" | "easy" | "hard" | "moderate" | "%future added value";
export type Kind = "article" | "set" | "video" | "%future added value";
export type editSourceMutation$variables = {
  contentTest?: boolean | null;
  course?: boolean | null;
  difficulty?: Difficulty | null;
  duoLang?: boolean | null;
  id: string;
  imageId?: string | null;
  kind?: Kind | null;
  lang?: string | null;
  multiLang?: boolean | null;
  name?: string | null;
  private?: boolean | null;
  source?: string | null;
  text?: string | null;
  videoDuration?: number | null;
  videoStart?: number | null;
};
export type editSourceMutation$data = {
  readonly editSource: {
    readonly contentTest: {
      readonly active: boolean;
    } | null;
    readonly course: boolean;
    readonly difficulty: Difficulty;
    readonly duoLang: boolean;
    readonly id: string;
    readonly image: {
      readonly authorApiName: string;
      readonly authorName: string;
      readonly url: string;
    } | null;
    readonly imageId: string | null;
    readonly kind: Kind;
    readonly lang: string;
    readonly multiLang: boolean;
    readonly name: string;
    readonly nameRaw: string | null;
    readonly private: boolean;
    readonly source: string | null;
    readonly text: string | null;
    readonly uri: string;
    readonly videoDuration: number | null;
    readonly videoLang: string | null;
    readonly videoStart: number | null;
  } | null;
};
export type editSourceMutation = {
  response: editSourceMutation$data;
  variables: editSourceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentTest"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "course"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "difficulty"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "duoLang"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "imageId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "kind"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lang"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "multiLang"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "private"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "source"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "text"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "videoDuration"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "videoStart"
},
v15 = [
  {
    "kind": "Variable",
    "name": "contentTest",
    "variableName": "contentTest"
  },
  {
    "kind": "Variable",
    "name": "course",
    "variableName": "course"
  },
  {
    "kind": "Variable",
    "name": "difficulty",
    "variableName": "difficulty"
  },
  {
    "kind": "Variable",
    "name": "duoLang",
    "variableName": "duoLang"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "imageId",
    "variableName": "imageId"
  },
  {
    "kind": "Variable",
    "name": "kind",
    "variableName": "kind"
  },
  {
    "kind": "Variable",
    "name": "lang",
    "variableName": "lang"
  },
  {
    "kind": "Variable",
    "name": "multiLang",
    "variableName": "multiLang"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  },
  {
    "kind": "Variable",
    "name": "private",
    "variableName": "private"
  },
  {
    "kind": "Variable",
    "name": "source",
    "variableName": "source"
  },
  {
    "kind": "Variable",
    "name": "text",
    "variableName": "text"
  },
  {
    "kind": "Variable",
    "name": "videoDuration",
    "variableName": "videoDuration"
  },
  {
    "kind": "Variable",
    "name": "videoStart",
    "variableName": "videoStart"
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uri",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lang",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "multiLang",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameRaw",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duoLang",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "course",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "private",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageId",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "difficulty",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoStart",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoLang",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoDuration",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authorName",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "authorApiName",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "concreteType": "ContentTestType",
  "kind": "LinkedField",
  "name": "contentTest",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "editSourceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v15/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "editSource",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v33/*: any*/),
              (v34/*: any*/),
              (v35/*: any*/)
            ],
            "storageKey": null
          },
          (v36/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v12/*: any*/),
      (v11/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v10/*: any*/),
      (v5/*: any*/),
      (v14/*: any*/),
      (v13/*: any*/)
    ],
    "kind": "Operation",
    "name": "editSourceMutation",
    "selections": [
      {
        "alias": null,
        "args": (v15/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "editSource",
        "plural": false,
        "selections": [
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v33/*: any*/),
              (v34/*: any*/),
              (v35/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          (v36/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "355628bc6766b1a1cf7676c7a061d244",
    "id": null,
    "metadata": {},
    "name": "editSourceMutation",
    "operationKind": "mutation",
    "text": "mutation editSourceMutation(\n  $id: ID!\n  $lang: String\n  $multiLang: Boolean\n  $name: String\n  $text: String\n  $source: String\n  $duoLang: Boolean\n  $kind: Kind\n  $course: Boolean\n  $contentTest: Boolean\n  $difficulty: Difficulty\n  $private: Boolean\n  $imageId: String\n  $videoStart: Int\n  $videoDuration: Int\n) {\n  editSource(id: $id, lang: $lang, multiLang: $multiLang, name: $name, text: $text, source: $source, duoLang: $duoLang, kind: $kind, course: $course, contentTest: $contentTest, difficulty: $difficulty, private: $private, imageId: $imageId, videoStart: $videoStart, videoDuration: $videoDuration) {\n    id\n    uri\n    lang\n    multiLang\n    name\n    nameRaw\n    text\n    source\n    duoLang\n    kind\n    course\n    private\n    imageId\n    difficulty\n    videoStart\n    videoLang\n    videoDuration\n    image {\n      url\n      authorName\n      authorApiName\n      id\n    }\n    contentTest {\n      active\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "55c89f3ccda76d56eacc3f7219f7121d";

export default node;
