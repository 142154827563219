import { ConnectionIssue, useGetState } from "components/ReduxProvider";
import React from "react";
import useBooleanChangeTracker from "hooks/useBooleanChangeTracker";
import { useNetworkState, usePrevious } from "react-use";
import * as Sentry from "@sentry/browser";

const ConnectionLogger: React.FC<{}> = () => {
  const network = useNetworkState();
  const connectionProblem = useGetState("connectionProblem");
  const recentConnectionIssues = useGetState("recentConnectionIssues");
  const needsUpdate = useGetState("needsUpdate");

  const connectionProblem3timesIn60s = useBooleanChangeTracker(
    connectionProblem && network.online && !needsUpdate, // same condition as connection problem in snackbar
    3 * 2 - 1,
    60000
  );
  const prevConnectionProblem3timesIn60s = usePrevious(connectionProblem3timesIn60s);

  React.useEffect(() => {
    if (connectionProblem3timesIn60s && !prevConnectionProblem3timesIn60s) {
      // v2 to recognize updated codebase in sentry
      Sentry.captureMessage("Connection problem 3 times in 60s (v2)", {
        tags: {
          networkType: network.type
        },
        extra: {
          networkOnline: network.online, // should be allways true
          networkEffectiveType: network.effectiveType,
          networkDownlink: network.downlink,
          networkRtt: network.rtt,
          networkDownlinkMax: network.downlinkMax,
          ...convertIssuesIntoFlatMap(recentConnectionIssues)
        }
      });
    }
  });

  return null;
};

function convertIssuesIntoFlatMap(issues: ConnectionIssue[] | undefined): { [key: string]: any } {
  if (!issues) return {};

  let flatMap: { [key: string]: any } = {};

  issues.forEach((issue, index) => {
    const suffix = index + 1;
    flatMap[`message_${suffix}`] = issue.message;

    if (issue.networkData) {
      Object.keys(issue.networkData).forEach((key) => {
        flatMap[`networkData_${key}_${suffix}`] = issue.networkData![key];
      });
    }

    // readable time
    flatMap[`time_${suffix}`] = new Date(issue.time).toISOString();
  });

  return flatMap;
}

export default ConnectionLogger;
