import React from "react";
import styled from "styled-components/macro";
import SCardForm from "./SCardForm";
import { useTranslation } from "react-i18next";
import { incAdStack, useGetState, useSetState } from "../ReduxProvider";
import PickerNoCards from "./PickerNoCards";
import { usePrevious } from "react-use";
import { useViewerQuery, useViewerDecks } from "queries/viewerQuery";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { TTheory } from "types/TheoryTypes";
import { useCardCreate } from "queries/cards/cardCreate";
import { useBurnWithSource } from "components/fire/xpHooks";
import { sourceQuery$data } from "queries/sources/__generated__/sourceQuery.graphql";
import { snackbar } from "tools/events";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { SEARCH, useSearchParamTools } from "tools/searchParams";
import PickCardB from "./PickCardB";

export const ICON5HINT = "icon5hint";
export const PICKER_B_WRAP_ID = "pickerBId";

const Wrap = styled.div`
  margin: 15px 0;
  flex-grow: 1;
  position: relative;
`;
const STouchAppIcon = styled(TouchAppIcon)`
  position: absolute;
  top: 45%;
  left: 55%;
  color: ${({ theme }) => theme.duo.color.primary};
  z-index: -1; // 1030
  font-size: 60px;
  pointer-events: none;
  transition: top 600ms ease-in-out;
  filter: drop-shadow(0px 0px 3px #fff);
`;

export type PickerCard = {
  readonly id: string;
  readonly front: string;
  readonly hint: string | null;
  readonly pronunciation: string | null;
  readonly back: {
    readonly value: string;
    readonly sBackId: string | null;
  } | null;
  readonly theory: TTheory | null;
  readonly svg?: any | null;
  readonly isInMyDeck: boolean | null;
};
type Props = {
  source: sourceQuery$data["sourceByUriOrId"];
};

const PickerB: React.FC<Props> = ({ source }) => {
  const [dialogCard, setDialogCard] = React.useState<PickerCard | undefined>();
  const { t } = useTranslation();
  const { viewer, hintWasSeen } = useViewerQuery();
  const { deck } = useViewerDecks();
  const deckId = useGetState("deckId");
  const { cardCreate } = useCardCreate();
  const burnW = useBurnWithSource(source.lang);
  const sCards = source.sCards.filter((c) => c.isInMyDeck === null);
  const noCards = !sCards.length;
  const isLastCard = sCards.length === 1;
  const noCardsPrev = usePrevious<boolean>(noCards);
  const setDeckCreate = useSetState("deckCreate");
  const { navigateSearchParam, backSearchParam, getSearchParam } = useSearchParamTools();

  React.useEffect(() => {
    if (source.course && noCards && !noCardsPrev) {
      snackbar(t("You have finished the course!"), { severity: "info", confetti: true });
    }
  });

  React.useEffect(() => {
    if (!getSearchParam(SEARCH.editCard) && dialogCard) {
      setDialogCard(undefined); // if native "back" button is pressed when dialog is open
    }
  }, [getSearchParam, dialogCard]);

  const handleSave = (sCard: PickerCard, added: boolean) => {
    if (!hintWasSeen(TUTORIALDONE) && !added) return;
    const back = sCard.back?.value;
    if (!deckId || back === undefined || deck?.front !== source.lang) {
      return source.lang && setDeckCreate({ lang: source.lang });
    }
    cardCreate(
      {
        front: sCard.front,
        hint: sCard.hint,
        sCardId: sCard.id,
        sourceId: source.id,
        back,
        sBackId: sCard.back?.sBackId,
        deckId,
        deleted: !added,
        svg: sCard.svg
      },
      isLastCard
    );

    incAdStack(added ? 4 : 2);
    burnW(added ? 1 : 0);

    handleClose();
  };

  const handleClose = () => {
    backSearchParam(SEARCH.editCard);
  };

  /*
  // todo implement arrows if the abtest wins
  const activeCard = sCards.find((c) => c.isInMyDeck === null);
  useKeyMy("ArrowUp", () => activeCard && handleSave(activeCard, true), undefined, [handleSave]);
  useKeyMy("ArrowDown", () => activeCard && handleSave(activeCard, false), undefined, [handleSave]);*/

  const handleDialog = (c) => {
    navigateSearchParam(SEARCH.editCard, "1");
    if (!c.back || deck?.front !== source.lang) {
      return source.lang && setDeckCreate({ lang: source.lang });
    }
    !viewer?.name ? alert(t("You have to be registered to suggest edits")) : setDialogCard(c);
  };

  const printCards: PickerCard[] = sCards.slice(0, 4);

  return (
    <Wrap id={PICKER_B_WRAP_ID}>
      <div>
        {printCards.map((c, index) => (
          <PickCardB
            source={source}
            sCard={c}
            lang={source.lang}
            key={c.id}
            onSave={handleSave}
            onDialog={handleDialog}
            index={index}
          />
        ))}
      </div>

      <STouchAppIcon id={ICON5HINT} />

      {noCards && <PickerNoCards source={source} />}

      <SCardForm card={dialogCard} source={source} onClose={handleClose} />
    </Wrap>
  );
};

export default PickerB;
