import ErrorMammoth from "components/mammoth/ErrorMammoth";
import Loader from "components/other/Loader";
import { useGetState } from "components/ReduxProvider";
import React from "react";
import styled from "styled-components";
import DuoCardsTitle from "../../styled/DuoCardsTitle";

const Wrap = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const SDuoCardsTitle = styled(DuoCardsTitle)<{ hide: boolean }>`
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: opacity 1s;
`;

const Splash: React.FC<{}> = () => {
  const [hide, setHide] = React.useState(false);
  const [timeouted, setTimeouted] = React.useState(false);
  const [showLoader, setShowLoader] = React.useState(false);
  const connectionProblem = useGetState("connectionProblem");

  React.useEffect(() => {
    setTimeout(() => setHide(true), 200);
    const t1 = setTimeout(() => setShowLoader(true), 4000);
    const t2 = setTimeout(() => setTimeouted(true), 16000);
    return () => {
      clearTimeout(t1);
      clearTimeout(t2);
    };
  }, []);

  return (
    <Wrap>
      {!timeouted && !connectionProblem ? (
        // connectionProblem is resolved in ConnectionError
        showLoader ? (
          <Loader />
        ) : (
          <SDuoCardsTitle hide={hide} />
        )
      ) : (
        <ErrorMammoth />
      )}
    </Wrap>
  );
};

export default Splash;
