import React from "react";
import styled from "styled-components/macro";
import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { devicePaddings } from "../../tools/device";
import { useTtsQuery } from "../../queries/ttsQuery";
import { silenceTts } from "../../queries/tts/ttsTools";
import { useDeviceLang } from "../../hooks/deviceHooks";
import MammothIcon from "../../icons/MammothIcon";
import { useNavigate } from "react-router";
import { useEffectOnce } from "react-use";
import { useTimedActions } from "hooks/useTimedActions";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";

const { top } = devicePaddings();

const Wrap = styled.div`
  height: 100%;
  padding: ${top + 55}px 0;
  box-shadow: inset 0 -20px 100px #ffffff85;
  background: black;
  color: ${({ theme }) => theme.duo.color.white};

  .showing {
    opacity: 1;
    transition: opacity 1s;
  }
  .hidden {
    visibility: hidden;
    opacity: 0;
  }
`;
const In = styled.div`
  display: flex;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 20px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  p {
    margin: 8px 0;
  }
`;
const SButton = styled(Button)`
  align-self: center;
  padding: 11px 42px;
`;
const SMammothIcon = styled(MammothIcon)`
  width: 100px;
  height: 100px;
`;

type Props = { onClose: VoidFunction };

const InternAdBlack: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ttsLang } = useDeviceLang();
  const tts = useTtsQuery(ttsLang);
  const [visibleIndex, setVisibleIndex] = React.useState(0);
  useTimedActions([
    { delay: 0, callback: () => setVisibleIndex(1) },
    { delay: 1300, callback: () => setVisibleIndex(2) },
    { delay: 2600, callback: () => setVisibleIndex(3) },
    { delay: 3900, callback: () => setVisibleIndex(4) },
    { delay: 7000, callback: () => setVisibleIndex(6) }
  ]);

  const order = (e) => {
    e.stopPropagation();
    onClose();
    navigate(SUBSCRIPTION.url());
  };

  useEffectOnce(() => {
    tts &&
      tts({
        text: t(
          "Do you want to go dark? Premium users have access to different color themes. Including the dark theme."
        ),
        speakingRate: 1
      });
  });

  return (
    <Wrap>
      <In>
        <Trans parent="p" className={visibleIndex >= 1 ? "showing" : "hidden"}>
          Do you want to go dark?
        </Trans>

        <SMammothIcon className={visibleIndex >= 2 ? "showing" : "hidden"} />

        <SButton
          className={visibleIndex >= 3 ? "showing" : "hidden"}
          variant="contained"
          color="primary"
          size="large"
          onClick={order}
        >
          <Trans>Subscribe</Trans>
        </SButton>

        <Trans parent="p" className={visibleIndex >= 4 ? "showing" : "hidden"}>
          You can choose from different color themes. Including the dark theme.
        </Trans>

        <Button
          variant="text"
          size="medium"
          onClick={() => {
            silenceTts();
            onClose();
          }}
          className={visibleIndex >= 5 ? "showing" : "hidden"}
        >
          <Trans>Maybe later</Trans>
        </Button>
      </In>
    </Wrap>
  );
};

export default InternAdBlack;
