import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import QuestReward from "./QuestReward";
import { PICKED_FEW_CARDS, PICKED_FEW_CARDS_COUNT } from "components/fire/xpHooks";
import { useSeeHint } from "components/hint/Hint";
import { useViewerDecks, useViewerQuery, useViewerXps } from "queries/viewerQuery";
import { useXpIncrement } from "components/fire/xpIncrement";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useAnimateTexts } from "hooks/useAnimateTexts";
import { useEffectOnce } from "react-use";
import { EVENT, dispatchEvent } from "tools/events";
import { useLibraryQuery } from "queries/sources/libraryQuery";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { SOURCE } from "root/library/library/libraryUrls";
import { LIBRARY } from "root/RootRouter.config";
import { sentryCapture } from "sentry/sentry";
import { LibTutorialPointer } from "root/tutorial/steps/BreakLibrary";

const Text = styled.div<{ crossed?: boolean }>`
  text-decoration: ${({ crossed }) => (crossed ? `line-through` : `none`)};
`;

type Props = {};

const FewMoreCards: React.FC<Props> = () => {
  const seeHint = useSeeHint();
  const { xps } = useViewerXps();
  const { getFlag } = useViewerQuery();
  const { deck } = useViewerDecks();
  const xpIncrement = useXpIncrement();
  const pickedFewCards = useGetState("pickedFewCards");
  const incremented = React.useRef<boolean>(false);
  const [s0, s1, s2] = useAnimateTexts({ delay: 800, disable: pickedFewCards });
  const setShowQuestsBackdrop = useSetState("showQuestsBackdrop");
  const navigate = useNavigate();
  const fewMoreCardsClicked = useGetState("fewMoreCardsClicked");
  const setFewMoreCardsClicked = useSetState("fewMoreCardsClicked");
  const forced1stQuest = getFlag("abtest_forced1stQuest")?.value === "on";
  const { lastVisited } = useLibraryQuery();
  const { t } = useTranslation();

  const compactView = !forced1stQuest || fewMoreCardsClicked;
  const cardsToAdd = String(PICKED_FEW_CARDS_COUNT - (deck?.stats?.total || 4)); // in case user deleted some cards already

  React.useEffect(() => {
    if (pickedFewCards && !incremented.current && xps!.total < 5) {
      xpIncrement(5 - xps!.total);
      incremented.current = true;
      setShowQuestsBackdrop(true);
    }
  });

  useEffectOnce(() => {
    setShowQuestsBackdrop(compactView ? false : true);
    dispatchEvent(EVENT.updateMain);
  });

  const uri = lastVisited.data?.sources.edges?.find((edge) => edge?.node?.kind === "set")?.node?.uri;
  const handleContinue = () => {
    if (uri) {
      navigate(SOURCE.url(uri));
    } else {
      navigate(LIBRARY.url());
      sentryCapture("No set uri found for 1st quest", { lastVisited });
    }
    setFewMoreCardsClicked(true);
  };

  return (
    <>
      <Text crossed={pickedFewCards}>
        <Trans parent="div" style={!pickedFewCards ? s1 : {}}>
          Help me light my fire!
        </Trans>
        <p style={{ margin: "12px 0 8px", ...(!pickedFewCards ? s2 : {}) }}>
          {t("Add 4 more words to your deck and learn them").replace("4", cardsToAdd)}
        </p>

        {!compactView && (
          <Button variant="contained" sx={{ margin: "7px 0" }} onClick={handleContinue}>
            <Trans>Continue</Trans>
          </Button>
        )}
      </Text>
      <QuestReward claim={pickedFewCards} onDone={() => seeHint(PICKED_FEW_CARDS)} style={!pickedFewCards ? s0 : {}} />

      {!pickedFewCards && compactView && <LibTutorialPointer delayed />}
    </>
  );
};

export default FewMoreCards;
