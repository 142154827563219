import { Button } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { isChromeExtension } from "tools/device";

const SButton = styled(Button)`
  &&& {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 7px 12px;
    text-transform: none;
    background: ${({ theme }) => theme.duo.color.lightGrey};
  }
`;
const SOpenInNewIcon = styled(OpenInNewIcon)`
  font-size: 16px;
  margin-left: 4px;
`;

type Props = {};

const ExtensionOutButton: React.FC<Props> = () => {
  const inPop = isChromeExtension();

  if (!inPop) return null;

  return (
    <SButton color="primary" size="small" onClick={() => window.open("https://app.duocards.com/", "duocards")}>
      app.DuoCards.com <SOpenInNewIcon />
    </SButton>
  );
};

export default ExtensionOutButton;
