import React from "react";
import styled, { css } from "styled-components/macro";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTranslate } from "@awesome.me/kit-55349eb283/icons/kit/custom";

const SIconButton = styled(({ grey, ...p }) => <IconButton {...p} />)<{ grey: boolean }>`
  ${({ grey }) =>
    grey &&
    css`
      color: ${({ theme }) => theme.duo.color.grey};
    `}svg {
    width: 24px !important;
    height: 24px;
  }
`;

type Props = {
  value: string;
  translated: string;
  translating: boolean;
  onClick: VoidFunction;
};

const TranslateButton: React.FC<Props> = (p) => {
  const grey = !p.value || p.value === p.translated;

  return (
    <InputAdornment position="end">
      {p.translating ? (
        <CircularProgress size={26} />
      ) : (
        <SIconButton
          size="small"
          color="primary"
          onClick={(p.value && p.onClick) || undefined}
          grey={grey}
          tabIndex={-1}
        >
          <FontAwesomeIcon icon={faTranslate} />
        </SIconButton>
      )}
    </InputAdornment>
  );
};

export default TranslateButton;
