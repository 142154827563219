import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { referrersSourcesMutation } from "./__generated__/referrersSourcesMutation.graphql";

export const mutation = graphql`
  mutation referrersSourcesMutation($referrer: String!) {
    processReferrersSources(referrer: $referrer)
  }
`;

export const useReferrerSourcesMutation = () => {
  const [mutate] = useMutation<referrersSourcesMutation>(mutation, {});

  return (variables: referrersSourcesMutation["variables"]) => mutate({ variables });
};
