import { useQuery } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { useSelector } from "react-redux";
import { selectDeviceId } from "../../components/ReduxProvider";
import { canShowOAuth } from "../../tools/device";
import { PreLoginQuery } from "root/auth/__generated__/PreLoginQuery.graphql";

const query = graphql`
  query PreLoginQuery($deviceId: String!) {
    preLogin(deviceId: $deviceId) {
      token
      preferredLoginMethod
      email
      users {
        email
        flamesCount
        loginMethod
        lastLoginAt
        subscription
      }
    }
  }
`;

export const usePreLoginQuery = (deviceIdOverride?: string) => {
  const selectorDeviceId = useSelector(selectDeviceId);
  const deviceId: string = deviceIdOverride ?? (selectorDeviceId || "");
  const { data, isLoading } = useQuery<PreLoginQuery>(query, { deviceId }, { skip: !deviceId || !canShowOAuth() });
  return { data: data?.preLogin, isLoading };
};
