import React from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Button, Checkbox } from "@mui/material";
import { Formik, Form } from "formik";
import styled from "styled-components/macro";
import { FSelect, Select } from "../../../styled/Select";
import { createSourceMutation } from "../../../queries/sources/__generated__/createSourceMutation.graphql";
import Loader from "components/other/Loader";
import { useEditSourceMutation } from "../../../queries/sources/editSource";
import { SOURCE, VIDEOFORM } from "../library/libraryUrls";
import { useDeviceLang } from "../../../hooks/deviceHooks";
import { Trans, useTranslation } from "react-i18next";
import { sourceEditQuery } from "./__generated__/sourceEditQuery.graphql";
import { useMutation, useQuery } from "relay-hooks";
import { createSourceQL } from "../../../queries/sources/createSource";
import { FLangMenu } from "../../../components/LangMenu/LangMenu";
import VideoSearch from "./VideoSearch";
import { HeaderLayout } from "../../../components/header/Header";
import { FTextField } from "../../../styled/TextField";
import YouTube from "react-youtube";
import { getTheme } from "../../../styled/theme";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PublicIcon from "@mui/icons-material/Public";
import { sourceEditQL } from "./sourceEditQuery";
import SourceEditDots from "./components/SourceEditDots";
import { timeToSec, secToTime } from "../../../tools/dateFns";
import HintDialog from "../../../components/hint/HintDialog";
import LinkExt from "../../../components/other/LinkExt";
import { useGetState, useSetState } from "../../../components/ReduxProvider";
import { useWindowSize } from "hooks/useWindowSize";
import { useGetLangOptions } from "hooks/useGetLangTranslates";

const SForm = styled(Form)`
  padding: 20px;
`;
const SButton = styled(Button)`
  margin: 10px;
  width: calc(100% - 20px);
`;
const Title = styled.div`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 10px;
  text-align: center;
`;
const CheckLabel = styled.label`
  display: flex;
  align-items: center;
  margin: -6px 0 0;
  padding: 0 8px 8px 8px;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.4em;
  cursor: pointer;
`;

type Props = {};
const VideoEdit: React.FC<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { deviceLang } = useDeviceLang();
  const [searchParams] = useSearchParams();
  const id = searchParams.get(VIDEOFORM.idParam);
  const [createSource, { loading }] = useMutation<createSourceMutation>(createSourceQL);
  const editSource = useEditSourceMutation((data) => data.editSource && navigate(SOURCE.url(data.editSource.uri)));
  const [agreed, setAgreed] = React.useState(false);
  const libLang = useGetState("libTopic");
  const setLoaderGlobal = useSetState("loaderGlobal");
  const langOptions = useGetLangOptions();
  const { data } = useQuery<sourceEditQuery>(sourceEditQL, { id: id || "" }, { skip: !id });

  const source = data?.node;

  React.useEffect(() => {
    if (source?.private === false) {
      setAgreed(true);
    }
  }, [source]);

  const { width: wWidth } = useWindowSize();
  const maxWidth = parseInt(getTheme().maxWidth.narrow, 10);
  const width = wWidth > maxWidth ? maxWidth : wWidth;
  const height = width * (9 / 16);

  React.useEffect(() => {
    setLoaderGlobal(loading);
  });

  if (id && !source) return <Loader />;

  const handleSubmit = (values: typeof init) => {
    if (id) {
      editSource({ ...values, videoStart: timeToSec(values.videoStart), id });
    } else {
      createSource({
        variables: {
          ...values,
          videoStart: timeToSec(values.videoStart),
          kind: "video",
          duoLang: true
        },
        onCompleted: (data) => {
          setLoaderGlobal(false);
          data.createSource && navigate(SOURCE.url(data.createSource.uri));
        }
      });
    }
  };

  const init = {
    name: source?.name || "",
    text: source?.text || null,
    lang: source?.lang || libLang || deviceLang,
    difficulty: source?.difficulty || "moderate",
    source: source?.source || "",
    private: source?.private === undefined || source?.private ? true : false,
    videoStart: source?.videoStart ? secToTime(source.videoStart) : "0:00",
    videoLang: source?.videoLang || null,
    videoDuration: source?.videoDuration || null
  };

  return (
    <Formik initialValues={init} onSubmit={handleSubmit} enableReinitialize>
      {({ values, setValues }) => {
        return (
          <HeaderLayout
            title={id ? t("Edit Video") : t("Publish Video")}
            narrow
            onBack={values.source ? () => setValues({ ...values, source: "" }) : undefined}
            right={source && <SourceEditDots source={source} />}
          >
            <SForm id="createsourceform">
              <div style={{ display: values.source ? "none" : "block" }}>
                <FLangMenu
                  name="lang"
                  disabled={!!source?.id}
                  title={
                    <Select
                      label={t("Language")}
                      value={values.lang}
                      options={langOptions}
                      style={{ pointerEvents: "none" }}
                    />
                  }
                />

                {values.lang && (
                  <VideoSearch
                    lang={values.lang}
                    onChange={(video) =>
                      setValues({
                        ...values,
                        name: video.title,
                        text: video.description,
                        source: video.videoId,
                        videoLang: video.videoLang,
                        videoDuration: video.duration
                      })
                    }
                  />
                )}
              </div>
              {values.source && (
                <>
                  <HintDialog name="videoCheck">
                    <Trans>
                      Always play the video and check if it's in target language and have valuable content for learning.
                    </Trans>
                  </HintDialog>

                  <div style={{ margin: "-20px -20px 0" }}>
                    <YouTube
                      videoId={values.source}
                      opts={{
                        width: String(width),
                        height: String(height),
                        playerVars: {
                          showinfo: 0,
                          cc_load_policy: "3" as any,
                          fs: 0
                        }
                      }}
                    />
                  </div>
                  <Title>{values.name}</Title>
                  <FSelect
                    name="difficulty"
                    label={t("Level")}
                    options={[
                      {
                        value: "easy",
                        label: t("easy")
                      },
                      {
                        value: "moderate",
                        label: t("moderate")
                      },
                      {
                        value: "hard",
                        label: t("hard")
                      }
                    ]}
                  />

                  <FTextField name="videoStart" label={t("Start at")} />

                  <FSelect
                    name="private"
                    label={t("Visible for")}
                    options={[
                      {
                        value: true,
                        label: t("Private"),
                        icon: <LockOutlinedIcon style={{ fontSize: 18 }} />
                      },
                      {
                        value: false,
                        label: t("Public"),
                        icon: <PublicIcon style={{ fontSize: 18 }} />
                      }
                    ]}
                  />

                  {values.private === false && (
                    <CheckLabel>
                      <Checkbox checked={agreed} onChange={() => setAgreed(!agreed)} color="primary" />
                      <Trans parent="div">
                        I confirm the content is intended for the public and meets the{" "}
                        <LinkExt href="https://www.duocards.com/termsofuse.html">terms of use</LinkExt>
                      </Trans>
                    </CheckLabel>
                  )}

                  <SButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={(values.private === false && !agreed) || loading}
                  >
                    {t("Save")}
                  </SButton>
                </>
              )}
            </SForm>
          </HeaderLayout>
        );
      }}
    </Formik>
  );
};

export default VideoEdit;
