import React from "react";
import styled from "styled-components/macro";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useDeleteSourceMutation } from "../../../../queries/sources/deleteSource";
import { sourceEditQuery } from "../__generated__/sourceEditQuery.graphql";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const Wrap = styled.div``;

type Props = {
  source?: NonNullable<sourceEditQuery["response"]["node"]>;
};

const SourceEditDots: React.FC<Props> = ({ source }) => {
  const { t } = useTranslation();
  const deleteSource = useDeleteSourceMutation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDelete = () => {
    if (source?.id && window.confirm(t("Really delete?"))) {
      deleteSource({ id: source.id });
    }
  };

  return (
    <Wrap>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget as any)}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={handleDelete}>
          <DeleteOutlineIcon /> &nbsp;
          <Trans>Delete</Trans>
        </MenuItem>
      </Menu>
    </Wrap>
  );
};

export default SourceEditDots;
