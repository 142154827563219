import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-hooks";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { fetchSttQuery, fetchSttQuery$data, fetchSttQuery$variables } from "./__generated__/fetchSttQuery.graphql";

export const sttQl = graphql`
  query fetchSttQuery($audio: String!, $audioFormat: String, $languages: [String]) {
    stt(audio: $audio, audioFormat: $audioFormat, languages: $languages) {
      text
    }
  }
`;

export const fetchStt = (environment: RelayModernEnvironment, args: fetchSttQuery$variables) =>
  new Promise<fetchSttQuery$data["stt"] | null>(async (done) => {
    fetchQuery<fetchSttQuery>(environment, sttQl, {
      ...args
    }).subscribe({
      next: (data) => done(data.stt)
    });
  });
