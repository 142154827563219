import React from "react";
import { useLocation, useNavigate } from "react-router";
import DialogWindow from "../other/DialogWindow";
import LearnNoCards from "../../root/main/learn/LearnNoCards";
import { MAIN } from "../../root/RootRouter.config";

export const NOCARDSHASH = "#nocards";

type Props = {};

const NoCardsDialog: React.FC<Props> = () => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (location.hash === NOCARDSHASH) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location.hash]);

  const handleClose = () => {
    navigate(MAIN.url(), { replace: true });
  };

  return (
    <DialogWindow open={open} onClose={handleClose}>
      <LearnNoCards />
    </DialogWindow>
  );
};

export default NoCardsDialog;
