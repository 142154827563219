import { useViewerQuery } from "queries/viewerQuery";
import React from "react";
import { LOSING_XPS_HINT, STREAK_FREEZE_QUEST } from "sharedJs__generated/constants";
import LosingXps from "./LosingXps";
import StreakFreeze from "./StreakFreeze";

type Props = {};

const XpsQuests: React.FC<Props> = () => {
  const { hintWasSeen } = useViewerQuery();

  return (
    <>
      {!hintWasSeen(LOSING_XPS_HINT) && <LosingXps />}
      {hintWasSeen(LOSING_XPS_HINT) && !hintWasSeen(STREAK_FREEZE_QUEST) && <StreakFreeze />}
    </>
  );
};

export default XpsQuests;
