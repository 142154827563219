import { selectJapAlphabeth, selectRomanOff } from "components/ReduxProvider";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

const Pronun = styled.div`
  font-size: 60%;
  line-height: 1.3em;
  font-weight: ${({ theme }) => theme.duo.fontWeight.w400};
  color: ${({ theme }) => theme.duo.color.textGrey};
`;

type Props = {
  lang: string;
  children?: string | null;
};

const Pronunciation: React.FC<Props> = ({ children, lang }) => {
  const romanOff = useSelector(selectRomanOff);
  const japAlphabeth = useSelector(selectJapAlphabeth);

  if (!children || (lang === "ja" ? !japAlphabeth.length : romanOff)) return null;

  return (
    <Pronun>
      {lang === "ja"
        ? japAlphabeth.sort().map((a, i) => (
            <React.Fragment key={a}>
              {!!i && <br />}
              {JSON.parse(children)[a]}
            </React.Fragment>
          ))
        : children}
    </Pronun>
  );
};

export default Pronunciation;
