import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Stack } from "@mui/material";
import LoginGuest from "./LoginGuest";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import { bottomPadding, canShowOAuth, isBrowser, isDesktop, isIos, topPadding, isApp } from "../../tools/device";
import DownloadButton from "./DownloadButton";
import LoginHeader from "./LoginHeader";
import OAuth from "components/other/OAuth";
import { useSetState } from "components/ReduxProvider";
import EmailAuth from "root/auth/EmailAuth";
import Toggler from "components/other/Toggler";
import Loader from "components/other/Loader";
import { usePreLoginQuery } from "./PreLogin";
import MammothNew from "icons/MammothNew";
import { SecondaryLoginButton } from "root/auth/LoginButton";
import { BrandTitle } from "../../components/other/BrandTitle";
import RegistrationAgreeText from "./RegistrationAgreeText";
import ContinueWithEmailButton from "./ContinueWithEmailButton";
import { dispatchEvent, EVENT } from "tools/events";
import { useEffectOnce } from "react-use";
import { UserAction } from "components/initializers/UserActionsLogger";

export const ForceLoginPageHash = "login";
export const LoginPageForced = () => {
  return window.location.hash === `#${ForceLoginPageHash}`;
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  text-align: center;
  padding: ${topPadding}px 0 ${bottomPadding}px;
  .MuiButton-outlined {
    background: white;
  }
`;
const In = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  padding: 5px 24px;
  @media (min-width: 600px) {
    max-width: 400px;
  }
`;
const SliderOut = styled.div`
  width: 100%;
  overflow: hidden;
`;
const SliderIn = styled.div<{ slid: boolean }>`
  width: 200%;
  position: relative;
  left: ${(p) => (p.slid ? "-100%;" : "0")};
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: left 0.3s;
`;
const SliderBox = styled.div`
  width: 50%;
  padding: 8px 0;
`;
const Small = styled.p`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.4em;
  color: ${({ theme }) => theme.duo.color.textGrey};
`;
const SContinueWithEmailButton = styled(ContinueWithEmailButton)`
  margin-bottom: 20px;
`;

const SLoader = styled(Loader)`
  height: 125px;
`;
const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: preLoginData, isLoading: preLoginLoading } = usePreLoginQuery();
  const [isMoreOptionsOpen, setMoreOptionsOpen] = useState(false);
  const [isEmailOpen, setEmailOpen] = useState(false);
  const [isAuthOpen, setAuthOpen] = useState(!isBrowser());
  const location = useLocation();
  const [mammothHeight, setMammothHeight] = useState(120);
  const [subtitleVisible, setSubtitleVisible] = useState(true);
  const setToken = useSetState("token");

  // todo: remove whole block below after ab-test
  const set_abtest_openLogin = useSetState("abtest_openLogin");
  useEffectOnce(() => {
    const isABTestActive = Math.random() > 0.5;
    set_abtest_openLogin(isABTestActive ? "on" : "off"); // to be stored by dblog "user_action"
    setMoreOptionsOpen(isABTestActive);
  });

  if (preLoginData?.token) {
    setToken(preLoginData.token);
  }

  const toggleEmailMethod = (show: boolean) => {
    setEmailOpen(show);
    navigate({ hash: show ? "email" : "" });
  };

  const handleKeyboardToggle = (isVisible: boolean) => {
    if (isDesktop()) return;
    setSubtitleVisible(!isVisible);
  };

  useEffect(() => {
    setMammothHeight(isEmailOpen ? 50 : 120);
  }, [isEmailOpen]);

  useEffect(() => {
    if (location.hash === "#email" && !isEmailOpen) {
      setEmailOpen(true);
    }

    if (location.hash !== "#email" && isEmailOpen) {
      setEmailOpen(false);
    }
  }, [location.hash, isEmailOpen]);

  useEffectOnce(() => {
    dispatchEvent(EVENT.consentCheck);
    dispatchEvent(EVENT.logUserAction, { action: UserAction.signupVisited });
  });

  const googleAuth = <OAuth hideApple />;
  const appleAuth = <OAuth hideGoogle />;
  const emailAuth = <SContinueWithEmailButton onClick={() => toggleEmailMethod(true)} />;
  const guestAuth = <LoginGuest />;

  let authMethods: any[];
  let appleLoginCompliance = false; // following guidelines that apple login must be an equivalent option on iOS
  switch (preLoginData?.preferredLoginMethod) {
    case "apple":
      authMethods = [appleAuth, googleAuth, emailAuth, guestAuth];
      break;
    case "email":
      authMethods = [emailAuth, googleAuth, appleAuth, guestAuth];
      break;
    default:
      if (isIos()) {
        appleLoginCompliance = true;
        authMethods = [googleAuth, emailAuth, guestAuth];
      } else {
        authMethods = [googleAuth, appleAuth, emailAuth, guestAuth];
      }
  }
  if (!canShowOAuth()) {
    authMethods = [emailAuth, guestAuth];
  }
  if (isBrowser() && process.env.NODE_ENV !== "development") {
    authMethods.pop();
  }
  const recommendedAuthMethod = authMethods.shift();

  return (
    <Wrap>
      <LoginHeader />
      <div>
        <Stack alignItems="center" spacing={1}>
          <MammothNew height={mammothHeight} style={{ transition: "height 0.5s ease" }} />
          <BrandTitle>DuoCards</BrandTitle>
          {subtitleVisible && <Trans parent={Small}>Add words from anywhere, never forget them</Trans>}
        </Stack>
        {/* <p style={{ color: "orange" }}>
            We are experiencing technical issues today, but working on it. Sorry for inconvenience.
          </p> */}
      </div>
      {isBrowser() && !isAuthOpen && (
        <>
          <div>
            <DownloadButton>{t("Open App")}</DownloadButton>
          </div>
          {isDesktop() && (
            <div>
              <SecondaryLoginButton
                onClick={() => {
                  setAuthOpen(true);
                }}
              >
                {t("Login as an existing user")}
              </SecondaryLoginButton>
            </div>
          )}
        </>
      )}
      {preLoginLoading ? (
        <SLoader />
      ) : (
        <Toggler show={isAuthOpen}>
          <SliderOut>
            <SliderIn slid={isEmailOpen}>
              <SliderBox>
                <In>
                  <div>{recommendedAuthMethod}</div>
                  {appleLoginCompliance && <div>{appleAuth}</div>}
                  {!isMoreOptionsOpen && (
                    <div>
                      <SecondaryLoginButton onClick={() => setMoreOptionsOpen(true)}>
                        {t("More options")}
                      </SecondaryLoginButton>
                    </div>
                  )}
                  <Toggler show={isMoreOptionsOpen}>
                    {authMethods.map((authMethod, i) => (
                      <div key={i}>{authMethod}</div>
                    ))}
                  </Toggler>
                  {isApp() && <RegistrationAgreeText />} {/* you can only register inside app */}
                </In>
              </SliderBox>
              <SliderBox>
                {isEmailOpen && (
                  <EmailAuth
                    initialEmail={preLoginData?.email || undefined}
                    onBackButton={() => toggleEmailMethod(false)}
                    onFocus={() => handleKeyboardToggle(true)}
                    onBlur={() => handleKeyboardToggle(false)}
                  />
                )}
              </SliderBox>
            </SliderIn>
          </SliderOut>
        </Toggler>
      )}
      {isDesktop() && <div></div>} {/* empty to do not stick to bottom on desktop */}
    </Wrap>
  );
};

export default LoginPage;
