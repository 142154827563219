import { Viewer } from "../viewerQuery";
import { FRIENDSREWARD, MANUALSTOREID } from "../../sharedJs__generated/constants";

export type SubscriptionFlags = {
  hasActiveFriendsReward: boolean;
  hasActiveMonthlySub: boolean;
  hasActiveYearlySub: boolean;
  hasActiveFamilySub: boolean;
  isFamilyFather: boolean;
};

export const getSubscriptionFlags = (
  subscription?: Viewer["subscription"] | null,
  name?: string | null
): SubscriptionFlags => {
  if (!subscription) {
    return {
      hasActiveFriendsReward: false,
      hasActiveMonthlySub: false,
      hasActiveYearlySub: false,
      hasActiveFamilySub: false,
      isFamilyFather: false
    };
  }

  const friendsReward = subscription.storeId === FRIENDSREWARD;
  const manual = subscription.storeId === MANUALSTOREID;

  return {
    hasActiveFriendsReward: friendsReward,
    hasActiveMonthlySub: subscription.storeId.indexOf("month") >= 0,
    hasActiveYearlySub: subscription.storeId.indexOf("month") === -1 && !friendsReward && !manual,
    hasActiveFamilySub: subscription.family,
    isFamilyFather: subscription.family && subscription.famembers?.filter((member) => member.name === name).length !== 1
  };
};
