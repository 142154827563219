import React from "react";
import styled, { css } from "styled-components/macro";
import { Button, IconButton, Stack, Tooltip } from "@mui/material";
import { genderStyle } from "tools/genderStyle";
import Speech from "components/speech/Speech";
import { PickerCard } from "components/picker/Picker";
import { useViewerQuery, useViewerDecks } from "queries/viewerQuery";
import { FIVEHINT } from "root/main/main/Main";
import Theory from "components/Theory/Theory";
import { brown } from "components/Theory/theoryStyles";
import { useTtsQuery } from "queries/ttsQuery";
import TheoryWrapper from "components/Theory/TheoryWrapper";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { devicePaddings } from "tools/device";
import SpeechHintTooltip, { LONGPRESSHINT } from "components/picker/SpeechHintTooltip";
import { canTts } from "queries/tts/ttsTools";
import Pronunciation from "root/main/components/Pronunciation";
import FlatImg from "components/other/FlatImg";
import { sourceQuery$data } from "queries/sources/__generated__/sourceQuery.graphql";
import useLangNative from "hooks/useLangNative";
import { usePrevious } from "react-use";
import { useOpenCourseView } from "root/library/source/course/courseHooks";
import { useGetState } from "components/ReduxProvider";
import UpSize from "root/main/components/UpSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faFlag, faGraduationCap, faPlus, faTrashXmark } from "@fortawesome/pro-regular-svg-icons";
import { faCheck, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { EVENT, dispatchEvent } from "tools/events";
import { Trans, useTranslation } from "react-i18next";
import { FIRSTFEWHINT } from "../../root/tutorial/steps/FirstFewCards";
import { TUTORIALDONE } from "../../root/tutorial/steps/Verdict";

const { bottom, top } = devicePaddings();
const ANIM_DURATION = 150;

const SAnimIcon = styled(FontAwesomeIcon)<{ $accept: boolean }>`
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translateX(-50%);
  font-size: 60px;
  color: ${(p) => (p.$accept ? p.theme.duo.color.easyGreen : p.theme.duo.color.red)};
  opacity: 0;
  transition: opacity 100ms;
  pointer-events: none;
`;
const Wrap = styled.div<{ $index: number; $cardAnimation: null | boolean }>`
  position: absolute;
  width: 86%;
  left: 7%;
  height: 29%;
  top: 20%;
  border-radius: 9px;
  border: ${({ theme }) => theme.duo.border.grey};
  z-index: ${({ $index }) => 50 - $index};
  background: white;
  transform: ${({ $index }) => ($index === 2 ? "rotate(3deg)" : $index === 3 ? "rotate(-3deg)" : "")};
  pointer-events: ${({ $index }) => ($index !== 0 ? "none" : "auto")};
  opacity: 1;

  ${(p) => {
    if (p.$index === 0) {
      return css`
        transition:
          top ${ANIM_DURATION}ms ease-in,
          left ${ANIM_DURATION}ms ease-in,
          opacity ${ANIM_DURATION}ms ease-in,
          transform ${ANIM_DURATION}ms ease-in;
      `;
    }
  }}

  ${(p) => {
    if (p.$index !== 0) return;

    if (p.$cardAnimation === true) {
      return css`
        top: 0;
        left: 100%;
        opacity: 0;
        transform: rotate(45deg);

        .anim-accept {
          opacity: 0.8;
        }
      `;
    }

    if (p.$cardAnimation === false) {
      return css`
        top: 0;
        left: -100%;
        opacity: 0;
        transform: rotate(-45deg);

        .anim-decline {
          opacity: 0.8;
        }
      `;
    }
  }}
`;
const In = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  padding-right: 13px;
`;
const Front = styled.div`
  text-align: center;
  padding: 0 30px 0 10px;
  font-weight: bold;
  font-size: 110%;
`;
const Hint = styled.div`
  font-size: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 78%;
  text-align: center;
  color: ${({ theme }) => theme.duo.color.textGrey};
`;
const Top = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
`;
const SIconButton = styled(IconButton)`
  padding: 15px;
  margin-right: auto;
  svg {
    color: ${({ theme }) => theme.duo.color.textGrey};
    width: 12px;
    height: 12px;
  }
`;
const Translation = styled.div<{ blured: boolean }>`
  position: absolute;
  bottom: 6px;
  right: 11px;
  left: 11px;
  text-align: right;
  filter: blur(${({ blured }) => (blured ? 5 : 0)}px);
`;
const SFlatImg = styled(FlatImg)`
  justify-content: flex-end;
  margin: 0 -2px -1px 0;
`;
const Back = styled.div`
  padding: 0 0 0 12px;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  color: ${({ theme }) => theme.duo.color.textGrey};
  overflow: hidden;
  text-overflow: ellipsis;
`;
const TheoryIcon = styled(AssignmentIcon)`
  color: ${brown};
`;
const TheoryButton = styled(IconButton)`
  float: right;
`;
const SchoolIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.duo.color.lightBlue};
  width: 24px;
  opacity: 0.5;
`;
const Buttons = styled(Stack)`
  position: absolute;
  top: 57%;
  width: 100%;
`;
const SButton = styled(Button)<{ $secondary?: boolean }>`
  box-shadow: none;
  width: 70%;
  font-size: ${(p) => (p.$secondary ? p.theme.duo.fontSize.smaller : p.theme.duo.fontSize.small)};
  font-weight: normal;
  svg {
    font-size: ${(p) => (p.$secondary ? 15 : 18)}px !important;
  }
`;
const ButtonWrap = styled.div`
  width: 100%;
  text-align: center;
`;

type Props = {
  sCard: PickerCard;
  source: sourceQuery$data["sourceByUriOrId"];
  lang: string;
  index: number;
  onSave: (sCard: PickerCard, added: boolean) => void;
  onDialog: (c: PickerCard) => void;
};

const PickCardB: React.FC<Props> = ({ sCard, lang, index, onSave, onDialog, source }) => {
  const cardRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const { hintWasSeen } = useViewerQuery();
  const [firstUnblured, setFirstUnblured] = React.useState(false);
  const [openTheory, setOpenTheory] = React.useState<boolean>();
  const tts = useTtsQuery(lang);
  const { deck } = useViewerDecks();
  const [prevTts, setPrevTts] = React.useState("");
  const voiceOff = useGetState("voiceOff");
  const openCourseView = useOpenCourseView();
  const langNative = useLangNative();
  const isTop = !index && sCard.isInMyDeck === null;
  const indexPrev: number | undefined = usePrevious(index);
  const [cardAnimation, setCardAnimation] = React.useState<null | boolean>(null);
  const animatingRef = React.useRef<boolean>(false);
  const { t } = useTranslation();
  const [tutorialTooltipOpen, setTutorialTooltipOpen] = React.useState(false);
  const discardDisabled = !hintWasSeen(FIRSTFEWHINT) && !hintWasSeen(TUTORIALDONE);

  const doOpenCourseView = (
    openTheoryByCardId: string | undefined = undefined,
    allowScrollAnimation: boolean = false,
    canShowTooMany: boolean = false
  ) => {
    openCourseView(source.uri, langNative, source.lang, openTheoryByCardId, allowScrollAnimation, canShowTooMany);
  };

  React.useEffect(() => {
    if (isTop && indexPrev === 1 && sCard.theory && source.course) {
      doOpenCourseView(undefined, true, true);
    }
  });

  React.useEffect(() => {
    isTop && setTimeout(() => setFirstUnblured(true), 1800);
  }, [setFirstUnblured, isTop]);

  React.useEffect(() => {
    if (
      isTop &&
      (openTheory === false || !sCard.theory) &&
      !voiceOff &&
      tts &&
      deck?.stats.total && // silent on first card (tutorial)
      prevTts !== sCard?.front
    ) {
      tts({ text: sCard?.front });
      setPrevTts(sCard?.front);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sCard, voiceOff, isTop, openTheory, deck?.stats.total, prevTts]);

  const handlePick = (add: boolean) => {
    if (animatingRef.current) return;

    animatingRef.current = true;

    setCardAnimation(add);

    setTimeout(() => {
      onSave(sCard, add);
    }, ANIM_DURATION);
  };

  const svg = sCard?.svg;
  const theoryIsOpened = openTheory || (isTop && !!sCard.theory && !!deck?.stats.total && openTheory === undefined);

  return (
    <>
      <Wrap $index={index} ref={cardRef} $cardAnimation={cardAnimation}>
        {index <= 1 && (
          <In>
            <Top>
              {hintWasSeen(FIVEHINT) && (
                <>
                  {source.mine ? (
                    <SIconButton onClick={() => onDialog(sCard)}>
                      <FontAwesomeIcon icon={faEdit} />{" "}
                    </SIconButton>
                  ) : (
                    <SIconButton
                      onClick={() =>
                        dispatchEvent(EVENT.openReportIssueDialog, {
                          type: "card",
                          sCardId: sCard.id,
                          sBackId: sCard.back?.sBackId,
                          langBack: deck?.back
                        })
                      }
                    >
                      <FontAwesomeIcon icon={faFlag} />
                    </SIconButton>
                  )}
                </>
              )}
              {sCard.theory && (
                <TheoryButton
                  onClick={(e) => {
                    e.stopPropagation();
                    source.course ? doOpenCourseView(sCard.id) : setOpenTheory(true);
                  }}
                >
                  {source.course ? <SchoolIcon icon={faGraduationCap} /> : <TheoryIcon />}
                </TheoryButton>
              )}
            </Top>
            <Front>
              <SpeechHintTooltip
                canOpen={
                  index === 0 &&
                  sCard.isInMyDeck === null &&
                  Number(deck?.stats?.total) > 12 &&
                  !sCard.theory &&
                  canTts(lang)
                }
                name={LONGPRESSHINT}
              >
                <Speech text={sCard.front} lang={lang} small />
              </SpeechHintTooltip>
              <UpSize lang={lang} weightNormal>
                {genderStyle(sCard.front, lang)}
              </UpSize>
            </Front>
            <Pronunciation lang={lang}>{sCard.pronunciation}</Pronunciation>
            <Hint>{sCard.hint}</Hint>
            <Translation blured={sCard.isInMyDeck === null && (!!index || !firstUnblured)}>
              {svg && <SFlatImg img={svg} size={42} />}
              <Back>{sCard.back?.value}</Back>
            </Translation>

            <SAnimIcon className="anim-accept" $accept={true} icon={faCheck} />
            <SAnimIcon className="anim-decline" $accept={false} icon={faXmark} />
          </In>
        )}
      </Wrap>

      {index === 0 && (
        <Buttons flexWrap="nowrap" alignItems="center" justifyContent="center" spacing={4}>
          <Tooltip open={tutorialTooltipOpen} placement="top" title={String(t("For now just choose 3 random cards"))}>
            <ButtonWrap onClick={() => discardDisabled && setTutorialTooltipOpen(true)}>
              <SButton
                disabled={discardDisabled}
                $secondary
                variant="outlined"
                startIcon={<FontAwesomeIcon icon={faTrashXmark} />}
                onClick={() => handlePick(false)}
              >
                <Trans>I don't want this card</Trans>
              </SButton>
            </ButtonWrap>
          </Tooltip>
          <SButton variant="contained" startIcon={<FontAwesomeIcon icon={faPlus} />} onClick={() => handlePick(true)}>
            <Trans>I want to learn this</Trans>
          </SButton>
        </Buttons>
      )}

      {sCard.theory && !source.course && (
        <TheoryWrapper
          open={theoryIsOpened}
          onClick={() => setOpenTheory(false)}
          style={{ height: `calc(100% + ${70 + bottom + top}px)`, top: -70 }}
        >
          <Theory theory={sCard.theory} title={sCard.front} onContinue={() => setOpenTheory(false)} />
        </TheoryWrapper>
      )}
    </>
  );
};

export default PickCardB;
