export type GenderT = { prefix: string; color?: "blue" | "pink" };

const gensGlobal = [
  { prefix: `(m)`, color: "blue" },
  { prefix: `(m.)`, color: "blue" },
  { prefix: `(f)`, color: "pink" },
  { prefix: `(f.)`, color: "pink" },
  { prefix: `(n)` },
  { prefix: `(n.)` },
  { prefix: `(v)` }
];

const gens = {
  en: [{ prefix: "the " }, { prefix: "a " }, { prefix: "an " }, { prefix: "to ", color: "green" }],
  de: [{ prefix: "das " }, { prefix: "der ", color: "blue" }, { prefix: "die ", color: "pink" }],
  pt: [
    { prefix: "o ", color: "blue" },
    { prefix: "os ", color: "blue" },
    { prefix: "um ", color: "blue" },
    { prefix: "a ", color: "pink" },
    { prefix: "as ", color: "pink" },
    { prefix: "uma ", color: "pink" }
  ],
  es: [
    { prefix: "el ", color: "blue" },
    { prefix: "un ", color: "blue" },
    { prefix: "los ", color: "blue" },
    { prefix: "la ", color: "pink" },
    { prefix: "las ", color: "pink" },
    { prefix: "una ", color: "pink" }
  ],
  fr: [
    { prefix: "l'" },
    { prefix: "l´" },
    { prefix: "le ", color: "blue" },
    { prefix: "la ", color: "pink" },
    { prefix: "les " },
    { prefix: "des " }
  ],
  it: [
    { prefix: "l'" },
    { prefix: "il ", color: "blue" },
    { prefix: "lo ", color: "blue" },
    { prefix: "i ", color: "blue" },
    { prefix: "gli", color: "blue" },
    { prefix: "la ", color: "pink" },
    { prefix: "le ", color: "pink" }
  ]
};

export const genders = (lang: string) => [...gensGlobal, ...(gens[lang] || [])] as GenderT[];

export const stripGens = (word: string, lang: string): string => {
  const withoutGen: string = genders(lang).reduce(
    (cont: string, item: GenderT) =>
      cont.toLowerCase().indexOf(item.prefix) === 0 ? cont.substr(item.prefix.length) : cont,
    word
  );
  return withoutGen.trim();
};
