import React from "react";
import DialogFullWindow from "../other/DialogFullWindow";
import { useLocation, useNavigate } from "react-router-dom";
import Assistant from "./Assistant";
import { useEvent } from "react-use";
import { EVENT } from "../../tools/events";
import { DialogProps } from "@mui/material";
import { SuggestionDefinition } from "./suggestions/suggestionsDefinitions";
import { useCloseAssistant } from "./assistantHooks";

export const ASSISTANT_HASH = "#assistant";

export type AssistantOpenSettings = {
  openAnimation?: DialogProps["TransitionComponent"];
  autoApplySuggestion?: SuggestionDefinition;
};

type Props = {};

const AssistantPopup: React.FC<Props> = () => {
  const navigate = useNavigate();
  const closeAssistant = useCloseAssistant();
  const location = useLocation();
  const isOpen = location.hash === ASSISTANT_HASH;
  const animationRef = React.useRef<DialogProps["TransitionComponent"] | undefined>();
  const applySuggestion = React.useRef<SuggestionDefinition>();

  useEvent(EVENT.openAssistant, (e: CustomEvent<AssistantOpenSettings>) => {
    animationRef.current = e.detail.openAnimation;
    applySuggestion.current = e.detail.autoApplySuggestion;
    navigate({ hash: ASSISTANT_HASH });
  });

  const handleClose = () => {
    closeAssistant();
  };

  return (
    <DialogFullWindow
      open={isOpen}
      maxWidth={600}
      allowDesktopPopup
      consumeHeader
      TransitionComponent={animationRef.current}
    >
      {isOpen && <Assistant autoApplySuggestion={applySuggestion.current} onClose={handleClose} />}
    </DialogFullWindow>
  );
};

export default AssistantPopup;
