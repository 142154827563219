/**
 * @generated SignedSource<<1999a2b7a620684a80ebac46ed4f7503>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoopMode = "listening" | "native" | "%future added value";
export type LoopFilter = {
  filterId: string;
  filteredSIds: ReadonlyArray<string | null>;
  includeKnown: boolean;
  loopMode: LoopMode;
};
export type cardKnowMutation$variables = {
  filter?: string | null;
  id: string;
  isLast?: boolean | null;
  know: boolean;
  loopFilter?: LoopFilter | null;
  noResponse?: boolean | null;
};
export type cardKnowMutation$data = {
  readonly cardKnow: {
    readonly " $fragmentSpreads": FragmentRefs<"loopQuery_loop">;
  } | null;
};
export type cardKnowMutation = {
  response: cardKnowMutation$data;
  variables: cardKnowMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isLast"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "know"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "loopFilter"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "noResponse"
},
v6 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "isLast",
    "variableName": "isLast"
  },
  {
    "kind": "Variable",
    "name": "know",
    "variableName": "know"
  },
  {
    "kind": "Variable",
    "name": "noResponse",
    "variableName": "noResponse"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "loopFilter",
    "variableName": "loopFilter"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "cardKnowMutation",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Deck",
        "kind": "LinkedField",
        "name": "cardKnow",
        "plural": false,
        "selections": [
          {
            "args": (v7/*: any*/),
            "kind": "FragmentSpread",
            "name": "loopQuery_loop"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "cardKnowMutation",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "Deck",
        "kind": "LinkedField",
        "name": "cardKnow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "Card",
            "kind": "LinkedField",
            "name": "loop",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sCardId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sBackId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sourceId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "front",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pronunciation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "back",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hint",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "flipped",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "loopedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "knownAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "knownUntil",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "knownCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "failCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Source",
                "kind": "LinkedField",
                "name": "source",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "course",
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Upload",
                "kind": "LinkedField",
                "name": "svg",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "flatId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "294b7d584d741ccd982b1244f4827e54",
    "id": null,
    "metadata": {},
    "name": "cardKnowMutation",
    "operationKind": "mutation",
    "text": "mutation cardKnowMutation(\n  $id: ID!\n  $know: Boolean!\n  $loopFilter: LoopFilter\n  $filter: String\n  $noResponse: Boolean\n  $isLast: Boolean\n) {\n  cardKnow(id: $id, know: $know, filter: $filter, noResponse: $noResponse, isLast: $isLast) {\n    ...loopQuery_loop_gbhcV\n    id\n  }\n}\n\nfragment loopQuery_loop_gbhcV on Deck {\n  loop(loopFilter: $loopFilter) {\n    id\n    sCardId\n    sBackId\n    sourceId\n    front\n    pronunciation\n    back\n    hint\n    flipped\n    loopedAt\n    knownAt\n    knownUntil\n    knownCount\n    failCount\n    source {\n      kind\n      course\n      id\n    }\n    svg {\n      flatId\n      url\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "805c2566959ce0b757afb1d1463e5924";

export default node;
