import React from "react";
import styled from "styled-components/macro";
import Speech from "components/speech/Speech";
import { useViewerDecks } from "queries/viewerQuery";

const SSpeech = styled(Speech)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    color: ${(p) => p.theme.duo.color.lightBlue};
    margin: auto;
    opacity: 1;
    width: 40px;
    height: 40px;
  }
  svg {
    width: 20px;
    height: 20px;
  }
`;

type Props = {
  text: string;
};

const TTSButton: React.FC<Props> = ({ text }) => {
  const { deck } = useViewerDecks();

  return (
    <SSpeech
      text={text}
      lang={deck!.front}
      useServerCache={false}
      // playOnMount={true} // uncomment for tts on mount
      // additionalLangs={compact([deck?.back, appLang])} // uncomment for multilang tts
    />
  );
};

export default TTSButton;
