import { Fade, Portal } from "@mui/material";
import React from "react";
import { useUpdate } from "react-use";
import styled from "styled-components/macro";
import { QUESTS_ID } from "../Quests";

const Drop = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.duo.color.lightGrey};
  z-index: 800;
  width: 100%;
  left: 0;
`;
const Top = styled(Drop)`
  top: 0;
`;
const Bottom = styled(Drop)`
  bottom: 0;
`;

type Props = {
  active: boolean;
};

const QuestsBackdrop: React.FC<Props> = ({ active }) => {
  const sizes = React.useRef<{ top: number; bottom: number }>();
  const update = useUpdate();

  React.useEffect(() => {
    if (active) {
      const updateSizes = () => {
        const questsRect = document.getElementById(QUESTS_ID)?.getBoundingClientRect();
        if (questsRect?.top && questsRect?.bottom) {
          const newSizes = { top: questsRect.top + 4, bottom: window.innerHeight - questsRect.bottom };
          if (newSizes.top !== sizes.current?.top || newSizes.bottom !== sizes.current?.bottom) {
            sizes.current = newSizes;
            update();
          }
        }
      };
      const iId = setInterval(updateSizes, 200);
      return () => clearInterval(iId);
    }
  }, [active, update]);

  if (!sizes.current) return null;

  return (
    <Portal>
      <Fade in={active} timeout={active ? 200 : 1000}>
        <Top style={{ height: sizes.current.top, pointerEvents: active ? "auto" : "none" }} />
      </Fade>
      <Fade in={active} timeout={active ? 200 : 1000}>
        <Bottom style={{ height: sizes.current.bottom, pointerEvents: active ? "auto" : "none" }}></Bottom>
      </Fade>
    </Portal>
  );
};

export default QuestsBackdrop;
