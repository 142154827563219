import React from "react";
import styled, { css, keyframes } from "styled-components/macro";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { CardUndoButtonMutation } from "./__generated__/CardUndoButtonMutation.graphql";
import { loopQuery_loop$data } from "../../../queries/decks/__generated__/loopQuery_loop.graphql";
import { useViewerQuery } from "../../../queries/viewerQuery";
import { useNavigate } from "react-router";
import BottomIcon from "./BottomIcon";
import { useGetState } from "components/ReduxProvider";
import { useEvent } from "react-use";
import { EVENT } from "../../../tools/events";
import { faTurnLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SUBSCRIPTION } from "../../profile/ProfileRouter.config";

const LoopUndoQL = graphql`
  mutation CardUndoButtonMutation(
    $cardId: ID!
    $loopedAt: String!
    $knownAt: String
    $knownUntil: String
    $knownCount: Int!
    $failCount: Int!
    $flipped: Boolean!
    $loopFilter: LoopFilter
  ) {
    loopUndo(
      cardId: $cardId
      loopedAt: $loopedAt
      knownAt: $knownAt
      knownUntil: $knownUntil
      knownCount: $knownCount
      failCount: $failCount
      flipped: $flipped
    ) {
      ...loopQuery_loop @arguments(loopFilter: $loopFilter)
    }
  }
`;

const shake = keyframes`
  0%, 100% { transform: rotateZ(0); }
  50% { transform: rotateZ(360deg); }
`;

const SIconButton = styled(BottomIcon)<{ animate?: boolean }>`
  ${({ animate }) =>
    animate &&
    css`
      animation: ${shake} 2s;
    `}
  && svg {
    width: 17px;
    height: 17px;
  }
`;

type Props = {
  prevCard?: loopQuery_loop$data["loop"][0];
};

const CardUndoButton: React.FC<Props> = ({ prevCard }) => {
  const { viewer, noPayments } = useViewerQuery();
  const [clicked, setClicked] = React.useState(false);
  const [mutate, { loading }] = useMutation<CardUndoButtonMutation>(LoopUndoQL);
  const [prevId, setPrevId] = React.useState<string>();
  const navigate = useNavigate();
  const loopFilter = useGetState("loopFilter");
  const [isAnimating, setIsAnimating] = React.useState(false);

  const animateFn = React.useCallback(() => {
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 2000);
  }, []);
  useEvent(EVENT.animateBackButton, animateFn);

  React.useEffect(() => {
    if (clicked && !loading) {
      setPrevId(prevCard?.id);
      setClicked(false);
    }
    if (!clicked && !loading && prevId !== prevCard?.id) {
      setPrevId(undefined);
    }
  }, [clicked, loading, prevCard?.id, prevId]);

  return (
    <>
      <SIconButton
        premium
        animate={isAnimating}
        disabled={loading || !prevCard || prevId === prevCard.id}
        onClick={() => {
          if (!viewer?.subscription && !noPayments()) {
            return navigate(SUBSCRIPTION.url());
          }
          prevCard &&
            mutate({
              variables: {
                cardId: prevCard.id,
                loopedAt: prevCard.loopedAt as string,
                knownAt: prevCard.knownAt as string,
                knownUntil: prevCard.knownUntil as string,
                knownCount: prevCard.knownCount,
                failCount: prevCard.failCount,
                flipped: prevCard.flipped,
                loopFilter
              }
            });
          setClicked(true);
        }}
      >
        <FontAwesomeIcon icon={faTurnLeft} />
      </SIconButton>
    </>
  );
};

export default CardUndoButton;
