import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { editSourceMutation } from "./__generated__/editSourceMutation.graphql";

export const mutation = graphql`
  mutation editSourceMutation(
    $id: ID!
    $lang: String
    $multiLang: Boolean
    $name: String
    $text: String
    $source: String
    $duoLang: Boolean
    $kind: Kind
    $course: Boolean
    $contentTest: Boolean
    $difficulty: Difficulty
    $private: Boolean
    $imageId: String
    $videoStart: Int
    $videoDuration: Int
  ) {
    editSource(
      id: $id
      lang: $lang
      multiLang: $multiLang
      name: $name
      text: $text
      source: $source
      duoLang: $duoLang
      kind: $kind
      course: $course
      contentTest: $contentTest
      difficulty: $difficulty
      private: $private
      imageId: $imageId
      videoStart: $videoStart
      videoDuration: $videoDuration
    ) {
      id
      uri
      lang
      multiLang
      name
      nameRaw
      text
      source
      duoLang
      kind
      course
      private
      imageId
      difficulty
      videoStart
      videoLang
      videoDuration
      image {
        url
        authorName
        authorApiName
      }
      contentTest {
        active
      }
    }
  }
`;

export const useEditSourceMutation = (onCompleted?: (res: editSourceMutation["response"]) => void) => {
  const [mutate] = useMutation<editSourceMutation>(mutation);

  return (variables: editSourceMutation["variables"]) =>
    mutate({
      variables,
      onCompleted
    });
};

export const publicCheck = (
  source: {
    private?: boolean;
    text?: string | null;
    kind?: any;
    sCards?: any;
    image?: Object | null;
  },
  t
) => {
  if (source.private === false) {
    if (source?.kind === "set" && Number(source?.sCards?.length) < 10) {
      return t("There have to be at least 10 cards in the public set.");
    }
    if (source?.kind === "article" && Number(source?.text?.length) < 200) {
      return t("A public article has to be at least 200 characters long.");
    }
    if (!source?.image) {
      return t("You have to pick image for the public set");
    }
  }

  return;
};
