import React from "react";
import styled from "styled-components/macro";
import BottomIcon from "./BottomIcon";
import { useOpenAssistant } from "../../../components/assistant/assistantHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSolidMammoth } from "@awesome.me/kit-55349eb283/icons/kit/custom";

const SIconButton = styled(BottomIcon)`
  left: auto;
  right: 61px;
  z-index: 710; // above theoryWrapper

  svg {
    width: 22px;
    height: 22px;
  }
`;

type Props = {
  style?: React.CSSProperties;
};

const OpenAssistantButton: React.FC<Props> = ({ style }) => {
  const openAssistant = useOpenAssistant();

  return (
    <SIconButton onClick={openAssistant} style={style}>
      <FontAwesomeIcon icon={faSolidMammoth} />
    </SIconButton>
  );
};

export default OpenAssistantButton;
