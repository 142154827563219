import { useQuery } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { Deck } from "queries/viewerQuery";
import { recommendedSourcesQuery } from "components/RecommendedSources/__generated__/recommendedSourcesQuery.graphql";
import { selectCanRecommend } from "components/ReduxProvider";
import { useSelector } from "react-redux";

const query = graphql`
  query recommendedSourcesQuery($deckId: String!, $langNative: String!) {
    recommendedSources(deckId: $deckId) {
      id
      uri
      name(langNative: $langNative)
      kind
      course
      private
      mine
      difficulty
      source
      videoDuration
      textLength
      top
      avgRating
      image {
        url
        authorName
        authorApiName
      }
      rating {
        rating
        continueFrom
        picked
        dismissed
        finished
      }
      counts {
        total
        theories
      }
    }
  }
`;

export const useRecommendedSources = (deck: Deck | undefined) => {
  const canRecommendSource: boolean | undefined = useSelector(selectCanRecommend(deck?.front));

  const { data } = useQuery<recommendedSourcesQuery>(
    query,
    { deckId: deck?.id || "", langNative: deck?.back || "" },
    { skip: !deck || !canRecommendSource, fetchPolicy: "network-only" }
  );
  return data?.recommendedSources;
};
