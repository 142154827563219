import styled from "styled-components/macro";

export const GreyBubble = styled.div<{ hidden?: boolean }>`
  position: relative;
  display: inline-block;
  border-radius: 20px;
  border: 1px solid #ddd;
  padding: 8px 20px;
  font-size: 85%;
  line-height: 1.5em;
  color: #888;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  transition: opacity 500ms;
  margin: 0 auto 12px;
`;
export const GreyArrow = styled.div`
  width: 8px;
  height: 8px;
  position: absolute;
  bottom: -5px;
  left: 51%;
  border: 1px solid #ccc;
  border-top: none;
  border-left: none;
  transform: rotate(45deg);
  background: ${({ theme }) => theme.duo.color.lightGrey};
`;
