/**
 * @generated SignedSource<<ebae01983b3fe61152b90a7bdfc97e6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type VideoSearchQuery$variables = {
  lang: string;
  search: string;
};
export type VideoSearchQuery$data = {
  readonly videoYTSearch: ReadonlyArray<{
    readonly description: string;
    readonly duration: number;
    readonly existingUri: string | null;
    readonly title: string;
    readonly videoId: string;
    readonly videoLang: string;
  }>;
};
export type VideoSearchQuery = {
  response: VideoSearchQuery$data;
  variables: VideoSearchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lang"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "lang",
        "variableName": "lang"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "Video",
    "kind": "LinkedField",
    "name": "videoYTSearch",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "videoId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "videoLang",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "duration",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "existingUri",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoSearchQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "VideoSearchQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b438cb0c2bdb6e68ca105d79399a9cc2",
    "id": null,
    "metadata": {},
    "name": "VideoSearchQuery",
    "operationKind": "query",
    "text": "query VideoSearchQuery(\n  $search: String!\n  $lang: String!\n) {\n  videoYTSearch(search: $search, lang: $lang) {\n    videoId\n    title\n    description\n    videoLang\n    duration\n    existingUri\n  }\n}\n"
  }
};
})();

(node as any).hash = "351c2cfc57642846649a420c22323419";

export default node;
