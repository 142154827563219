import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCopyToClipboard } from "react-use";
import CopyAll from "@mui/icons-material/CopyAll";

type Props = {
  value: string;
  className?: string;
};

const CopyField: React.FC<Props> = ({ value, className }) => {
  const copyToClipboard = useCopyToClipboard()[1];
  const [copied, setCopied] = React.useState(false);
  const { t } = useTranslation();

  return (
    <TextField
      name="link"
      className={className}
      type="text"
      value={value}
      variant="outlined"
      onClick={() => {
        copyToClipboard(value);
        setCopied(true);
        setTimeout(setCopied, 2000, false);
      }}
      fullWidth
      InputProps={{
        readOnly: true,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton aria-label="copy url" edge="end">
              <Tooltip open={copied} placement="top" title={String(t("Copied to clipboard!"))}>
                <CopyAll />
              </Tooltip>
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  );
};

export default CopyField;
