import React from "react";
import styled from "styled-components/macro";
import { devicePaddings } from "../../tools/device";
import { renderWithLinks } from "../../tools/strings";
import Avatar from "../profile/Avatar";
import { AuthorQuery } from "./__generated__/AuthorQuery.graphql";

const { top } = devicePaddings();

const Wrap = styled.div`
  padding: ${top + 55}px 0 20px;
  margin: 0;
  text-align: center;
  background: white;
`;
const Name = styled.div`
  font-weight: bold;
`;
const Bio = styled.div`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  color: #888;
  line-height: 1.4em;
  white-space: pre-line;
`;

type Props = {
  author: NonNullable<AuthorQuery["response"]["userByName"]>;
};

const AuthorTop: React.FC<Props> = ({ author }) => {
  return (
    <Wrap>
      <Avatar user={author} />
      <Name>{author.name}</Name>
      {author.bio && <Bio>{renderWithLinks(author.bio)}</Bio>}
    </Wrap>
  );
};

export default AuthorTop;
