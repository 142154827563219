import React from "react";
import styled from "styled-components/macro";
import { IconButton, Paper } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import AIGrammar from "components/GrammarDictionary/AIGrammar";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useViewerQuery } from "queries/viewerQuery";
import CloseIcon from "@mui/icons-material/Close";
import GrammarQuota from "components/GrammarDictionary/GrammarQuota";
import { useEffectOnce } from "react-use";
import { Grammar } from "dexieDB/dexie";

export type GrammarTabId = "ai";

const SPaper = styled(Paper)`
  overflow: auto;
  position: relative;
  background: white !important;
`;
const SIconButton = styled(IconButton)`
  position: absolute;
  right: 7px;
  top: 12px;
`;
const STabPanel = styled(TabPanel)`
  padding-top: 15px;
`;

type Props = {
  grammar: readonly Grammar[];
  onClose?: VoidFunction;
  className?: string;
};
const GrammarDictionary: React.FC<Props> = ({ grammar, onClose, className }) => {
  const { viewer } = useViewerQuery();
  const grammarUsage = useGetState("grammarUsage");
  const setGrammarUsage = useSetState("grammarUsage");

  const todayString: string = new Date().toISOString().substring(0, 10);
  const todayUsage: number = grammarUsage?.dateISO === todayString ? grammarUsage.views : 0;
  const grammarQuotaExceeded: boolean =
    !viewer?.subscription && (viewer?.createdBeforeDays === 0 ? todayUsage > 10 : todayUsage > 5);

  useEffectOnce(() => {
    setGrammarUsage({
      dateISO: todayString,
      views: todayUsage + 1
    });
  });

  return (
    <SPaper className={className} onClick={(e) => e.stopPropagation()}>
      <TabContext value={"ai"}>
        {grammar && grammar?.length > 0 && (
          <STabPanel value="ai">
            {grammarQuotaExceeded ? <GrammarQuota /> : <AIGrammar moveLangTab={!!onClose} grammar={grammar} />}
          </STabPanel>
        )}
      </TabContext>

      {onClose && (
        <SIconButton onClick={onClose}>
          <CloseIcon />
        </SIconButton>
      )}
    </SPaper>
  );
};

export default GrammarDictionary;
