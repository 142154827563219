import { Button, IconButton, Paper } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Trans } from "react-i18next";
import { useViewerQuery } from "../../queries/viewerQuery";
import { Navigate, useLocation, useNavigate } from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { VerifyMutation } from "./__generated__/VerifyMutation.graphql";
import { useSeeHint } from "../../components/hint/Hint";
import { devicePaddings } from "../../tools/device";
import { useSearchParams } from "react-router-dom";
import { VERIFY } from "../RootRouter.config";
import { USEREDIT } from "../profile/ProfileRouter.config";

export const VERIFYHINT = "verifyHint";
export const VERIFYTRANS = "Verify your email";

const { top } = devicePaddings();

const ResendQL = graphql`
  mutation VerifyMutation {
    resendVerification
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
  background: ${({ theme }) => theme.duo.color.lightGrey};
`;
const SIconButton = styled(IconButton)`
  position: absolute;
  top: ${top + 5}px;
  right: 5px;
  svg {
    color: #ccc;
  }
`;
const SPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 85%;
  padding: 50px 15px;
`;
const TopIco = styled.div`
  margin-bottom: 10px;
  svg {
    color: ${({ theme }) => theme.duo.color.primary};
    font-size: 40px;
  }
`;
const Title = styled.div`
  font-size: ${({ theme }) => theme.duo.fontSize.medium};
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
  margin: 20px 0;
`;
const Text = styled.div`
  margin-bottom: 20px;
  font-size: 95%;
`;
const Progress = styled.div`
  margin: 40px;
`;
const Green = styled.p`
  color: ${({ theme }) => theme.duo.color.green};
`;

type Props = {};

const Verify: React.FC<Props> = () => {
  const { viewer } = useViewerQuery();
  const seeHint = useSeeHint();
  const navigate = useNavigate();
  const location = useLocation();
  const [sent, setSent] = React.useState(false);

  const [resend] = useMutation<VerifyMutation>(ResendQL);

  const [searchParams] = useSearchParams();
  const verifying = searchParams.get(VERIFY.verifyParam);

  React.useEffect(() => {
    if (location.hash === VERIFY.sendHash) {
      navigate(VERIFY.url(), { replace: true });
      resend({ variables: {} });
      setSent(true);
    }
  }, [navigate, resend, location.hash]);

  React.useEffect(() => {
    seeHint(VERIFYHINT);
  }, [seeHint]);

  if (!viewer || !viewer.email) return <Navigate to="/" />;

  return (
    <Wrap>
      <SIconButton onClick={() => navigate("/", { replace: true })}>
        <CloseIcon />
      </SIconButton>
      <SPaper>
        <TopIco>
          <MailOutlineIcon />
        </TopIco>
        {verifying ? (
          <Progress>
            <CircularProgress />
          </Progress>
        ) : (
          <>
            <Title>
              <Trans>{VERIFYTRANS}</Trans>
            </Title>
            <Text>
              <Trans>Click the link in the email we've sent you</Trans>
              <br />
              <strong>{viewer.email}</strong>
              <IconButton style={{ padding: 6 }} color="primary" onClick={() => navigate(USEREDIT.url())}>
                <EditIcon style={{ fontSize: 20 }} />
              </IconButton>
            </Text>
            {sent ? (
              <Green>
                <Trans>Check your email</Trans>...
              </Green>
            ) : (
              <Button
                color="primary"
                onClick={() => {
                  resend({ variables: {} });
                  setSent(true);
                }}
              >
                <Trans>Resend verification link</Trans>
              </Button>
            )}
          </>
        )}
      </SPaper>
    </Wrap>
  );
};

export default Verify;
