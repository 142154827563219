import { encodeToAudioBlob, calculateRMS } from "./audioEncoder";
import * as Sentry from "@sentry/browser";
import { isApp } from "./device";

declare global {
  interface Window {
    audioinput: any;
  }
}

export const isMicrophoneSupported = () =>
  isApp() ? !!window.audioinput : !!(window.AudioContext || (window as any).webkitAudioContext);

type ErrorCallback = (error: Error) => void;
type StopCallback = (data: Blob) => void;
type FloatTimeDomainDataCallback = (data: Float32Array) => void;
type LoudnessCallback = (loudness: number) => void;

export enum MicrophoneErrorMessage {
  PermissionDenied = "Microphone access denied. Please allow access to the microphone.",
  DeviceNotFound = "No microphone found. Please connect a microphone.",
  GeneralError = "An error occurred while accessing the microphone."
}

const audioInputPermissionErrorMessage = "Microphone permission not granted";

export interface IRecorder {
  start(): Promise<boolean>;
  stop(): void;
  onError(callback: ErrorCallback): void;
  onStop(callback: StopCallback): void;
  onFloatTimeDomainData(callback: (data: Float32Array) => void): void;
}

// Collects loudness data and calculates average loudness over a given interval
class LoudnessHandler {
  private loudnessInterval: number | null = null;
  private loudnessData: number[] = [];

  start(callback: LoudnessCallback, interval: number = 100) {
    if (this.loudnessInterval) return;
    this.loudnessInterval = window.setInterval(() => {
      if (this.loudnessData.length > 0) {
        const averageLoudness = this.loudnessData.reduce((sum, value) => sum + value, 0) / this.loudnessData.length;
        this.loudnessData = [];
        callback(averageLoudness * 10 /* magic number */);
      } else {
        callback(0);
      }
    }, interval);
  }

  stop() {
    if (this.loudnessInterval) {
      clearInterval(this.loudnessInterval);
      this.loudnessInterval = null;
    }
  }

  addLoudnessData(loudness: number) {
    this.loudnessData.push(loudness);
  }
}

export class Recorder implements IRecorder {
  private recorder: IRecorder;
  private canceled = false;
  private isRecording = false;
  private loudnessHandler: LoudnessHandler;
  private loudnessCallback: LoudnessCallback | null = null;
  private floatTimeDomainDataCallback: FloatTimeDomainDataCallback | null = null;

  constructor() {
    this.recorder = window?.audioinput ? new CordovaAudioInputRecorder() : new WebApiRecorder();
    this.loudnessHandler = new LoudnessHandler();
    this.recorder.onFloatTimeDomainData((data) => {
      this.loudnessHandler.addLoudnessData(calculateRMS(data));
      this.floatTimeDomainDataCallback?.(data);
    });
  }

  async start(): Promise<boolean> {
    if (this.isRecording) return false;

    this.canceled = false;
    this.isRecording = true;
    const started = await this.recorder.start();
    if (!started) return false;

    if (this.loudnessCallback) {
      this.loudnessHandler.start(this.loudnessCallback);
    }

    return true;
  }

  stop() {
    this.loudnessHandler.stop();
    this.recorder.stop();

    this.isRecording = false;
  }

  cancel() {
    this.canceled = true;

    this.stop();
  }

  onError(callback: ErrorCallback) {
    this.recorder.onError((error) => {
      if (error.message !== audioInputPermissionErrorMessage) {
        // we don't want to capture permission errors
        Sentry.captureException(error);
      }
      callback(getMicrophoneError(error));
    });
  }

  onStop(callback: StopCallback) {
    this.recorder.onStop((blob) => {
      if (!this.canceled) {
        callback(blob);
      }
    });
  }

  onFloatTimeDomainData(callback: FloatTimeDomainDataCallback) {
    this.floatTimeDomainDataCallback = callback;
  }

  onLoudness(callback: LoudnessCallback) {
    this.loudnessCallback = callback;
  }

  uninitialize() {
    this.cancel();
    this.loudnessHandler.stop();
    this.loudnessCallback = null;
    this.floatTimeDomainDataCallback = null;
  }
}

class WebApiRecorder implements IRecorder {
  private mediaRecorder: MediaRecorder | null = null;
  private audioChunks: Blob[] = [];
  private audioStream: MediaStream | null = null;
  private errorCallback: ErrorCallback | null = null;
  private stopCallback: StopCallback | null = null;
  private onFloatTimeDomainDataCallback: FloatTimeDomainDataCallback | null = null;
  private audioContext: AudioContext | null = null;
  private analyser: AnalyserNode | null = null;
  private dataArray: Float32Array | null = null;
  private source: MediaStreamAudioSourceNode | null = null;

  async start(): Promise<boolean> {
    try {
      // setup media recorder
      this.audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(this.audioStream);
      this.audioChunks = [];

      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };

      this.mediaRecorder.onstop = () => {
        const audioBlob = new Blob(this.audioChunks, { type: "audio/wav" }); // this type is not right type of blob....
        this.stopCallback?.(audioBlob);
      };

      this.mediaRecorder.onerror = (error: Event) => {
        this.errorCallback?.(new Error(error.type));
      };

      // initalize analyser
      this.audioContext = new AudioContext();
      this.source = this.audioContext.createMediaStreamSource(this.audioStream);
      this.analyser = this.audioContext.createAnalyser();
      this.source.connect(this.analyser);
      this.analyser.fftSize = 256;
      const bufferLength = this.analyser.frequencyBinCount;
      this.dataArray = new Float32Array(bufferLength);

      // start recording
      this.mediaRecorder.start();
      this.monitorData();
      return true;
    } catch (error) {
      this.stop();
      this.errorCallback?.(error as Error);
      return false;
    }
  }

  stop() {
    this.mediaRecorder?.stop();
    this.audioStream?.getAudioTracks().forEach((track) => track.stop());
    this.source?.disconnect();
    this.analyser?.disconnect();
    this.audioContext?.close();
    this.analyser = null;
    this.audioContext = null;
    this.source = null;
    this.audioStream = null;
    this.mediaRecorder = null;
    this.audioChunks = [];
    this.dataArray = null;
  }

  onError(callback: ErrorCallback) {
    this.errorCallback = callback;
  }

  onStop(callback: StopCallback) {
    this.stopCallback = callback;
  }

  onFloatTimeDomainData(callback: FloatTimeDomainDataCallback) {
    this.onFloatTimeDomainDataCallback = callback;
  }

  private monitorData() {
    if (!this.analyser || !this.dataArray) return;

    const analyzeData = () => {
      if (!this.analyser || !this.dataArray) return;
      this.analyser.getFloatTimeDomainData(this.dataArray);
      this.onFloatTimeDomainDataCallback?.(this.dataArray);
      requestAnimationFrame(analyzeData);
    };

    analyzeData();
  }
}

class CordovaAudioInputRecorder implements IRecorder {
  private audioData: Float32Array[] = [];
  private errorCallback: ErrorCallback | null = null;
  private stopCallback: StopCallback | null = null;
  private onFloatTimeDomainDataCallback: FloatTimeDomainDataCallback | null = null;

  private BUFFER_SIZE = 4096;
  private SAMPLE_RATE = 44100;
  private CHANNELS = 1;

  async start(): Promise<boolean> {
    if (window?.audioinput && !window.audioinput.isCapturing()) {
      try {
        await this.requestPermission();
      } catch (error) {
        // console.error("Permission denied for Cordova audioinput plugin:", error);
        this.errorCallback?.(error as Error);
        return false;
      }

      window.audioinput.start({
        streamToWebAudio: false,
        bufferSize: this.BUFFER_SIZE,
        sampleRate: this.SAMPLE_RATE,
        channels: this.CHANNELS,
        format: window.audioinput.FORMAT.PCM_16BIT,
        audioSourceType: window.audioinput.AUDIOSOURCE_TYPE.DEFAULT
      });

      this.audioData = [];
      window.addEventListener("audioinput", this.onAudioInput.bind(this), false);
      return true;
    } else {
      const error = new Error("Cordova audioinput plugin not available.");
      this.errorCallback?.(error);
      return false;
    }
  }

  stop() {
    if (window.audioinput) {
      try {
        window.audioinput.stop();
        window.removeEventListener("audioinput", this.onAudioInput.bind(this), false);
        this.stopCallback?.(
          encodeToAudioBlob({
            audioData: this.audioData,
            sampleRate: this.SAMPLE_RATE,
            numChannels: this.CHANNELS,
            format: "mp3"
          })
        ); // way better compression than wav
      } catch (error) {
        this.errorCallback?.(error as Error);
      }
    }
  }

  private onAudioInput(event: any) {
    if (event && event.data) {
      this.audioData.push(event.data);
      this.onFloatTimeDomainDataCallback?.(event.data);
    }
  }

  private requestPermission(): Promise<void> {
    return new Promise((resolve, reject) => {
      window.audioinput.checkMicrophonePermission((hasPermission: boolean) => {
        if (hasPermission) {
          resolve();
        } else {
          window.audioinput.getMicrophonePermission((isGranted: boolean) => {
            if (isGranted) {
              resolve();
            } else {
              reject(new Error(audioInputPermissionErrorMessage));
            }
          });
        }
      });
    });
  }

  onError(callback: ErrorCallback) {
    this.errorCallback = callback;
  }

  onStop(callback: StopCallback) {
    this.stopCallback = callback;
  }

  onFloatTimeDomainData(callback: FloatTimeDomainDataCallback) {
    this.onFloatTimeDomainDataCallback = callback;
  }
}

interface MicrophoneError extends Error {}

const getMicrophoneError = (error: Error): MicrophoneError => {
  if (
    error.name === "NotAllowedError" ||
    error.name === "SecurityError" ||
    error.message.toLocaleLowerCase().includes("permission")
  ) {
    return new Error(MicrophoneErrorMessage.PermissionDenied);
  } else if (error.name === "NotFoundError" || error.name === "OverconstrainedError") {
    return new Error(MicrophoneErrorMessage.DeviceNotFound);
  } else {
    return new Error(MicrophoneErrorMessage.GeneralError);
  }
};
