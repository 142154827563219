/**
 * @generated SignedSource<<ae3341c5eb1068ea32ec6d256f2d4c10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ratingMutation$variables = {
  continueFrom?: number | null;
  rating?: number | null;
  sourceId: string;
};
export type ratingMutation$data = {
  readonly ratingEdit: {
    readonly rating: {
      readonly continueFrom: number | null;
      readonly rating: number | null;
    } | null;
  } | null;
};
export type ratingMutation = {
  response: ratingMutation$data;
  variables: ratingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "continueFrom"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rating"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "continueFrom",
    "variableName": "continueFrom"
  },
  {
    "kind": "Variable",
    "name": "rating",
    "variableName": "rating"
  },
  {
    "kind": "Variable",
    "name": "sourceId",
    "variableName": "sourceId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rating",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "continueFrom",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ratingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "ratingEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Rating",
            "kind": "LinkedField",
            "name": "rating",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ratingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "ratingEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Rating",
            "kind": "LinkedField",
            "name": "rating",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6e972b048d0b9875f72d77ac9506df7a",
    "id": null,
    "metadata": {},
    "name": "ratingMutation",
    "operationKind": "mutation",
    "text": "mutation ratingMutation(\n  $sourceId: ID!\n  $rating: Int\n  $continueFrom: Int\n) {\n  ratingEdit(sourceId: $sourceId, rating: $rating, continueFrom: $continueFrom) {\n    rating {\n      rating\n      continueFrom\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1e85e7eb4437ea0b9fd5f42df00aeef7";

export default node;
