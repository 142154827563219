import React from "react";
import styled from "styled-components/macro";
import { Button } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { HeaderLayout } from "../../../components/header/Header";
import LibraryRows from "./LibraryRows";
import { flagLabel } from "../../../tools/langs";
import LibraryMenu from "./LibraryMenu";
import Topics from "./Topics";
import { useViewerDecks, useViewerQuery } from "../../../queries/viewerQuery";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { langs } from "../../../sharedJs__generated/langsList";
import { useGetState, useSetState } from "../../../components/ReduxProvider";
import LibrarySearch from "./LibrarySearch";
import DialogFullWindow from "components/other/DialogFullWindow";
import { useLocation } from "react-router";
import { PICKED_FEW_CARDS } from "components/fire/xpHooks";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import LibraryPointer from "./components/LibraryPointer";
import RecommendedSources from "components/RecommendedSources/RecommendedSources";
import { LIBRARY } from "../../RootRouter.config";

const TopicsWrap = styled.div`
  height: 100%;
  background: white;
  padding-top: 20px;
`;
const SButton = styled(Button)`
  &&& {
    white-space: nowrap;
    padding: 8px 10px;
    flex-shrink: 1;
    color: ${({ theme }) => theme.palette.text.primary};
  }
`;

const Library = () => {
  const navigate = useNavigate();
  const { viewer, hintWasSeen } = useViewerQuery();
  const { decks } = useViewerDecks();
  const topic = useGetState("libTopic");
  const setLibTopic = useSetState("libTopic");
  const { topic: topicFromUrl } = useParams();
  const [searchOn, setSearchOn] = React.useState(false);
  const location = useLocation();
  const pickedFewCards = useGetState("pickedFewCards");
  const [searchParams] = useSearchParams();
  const setDeckId = useSetState("deckId");

  React.useEffect(() => {
    if (topicFromUrl && topicFromUrl !== topic && langs[topicFromUrl]) {
      setLibTopic(topicFromUrl);
      const foundDeck = decks && decks.find((d) => d.front === topicFromUrl);
      foundDeck && setDeckId(foundDeck.id);
    }
  }, [topicFromUrl, topic, decks, setLibTopic, setDeckId]);

  const openTopics = () => navigate({ hash: LIBRARY.topicHash });
  const closeTopics = () => navigate({ hash: "" }, { replace: true });
  const isSearching = searchParams.get(LIBRARY.searchParam);

  return (
    <HeaderLayout
      left={<LibrarySearch onChangeExpanded={setSearchOn} />}
      right={<LibraryMenu />}
      title={
        topic &&
        !searchOn && (
          <SButton onClick={openTopics}>
            {flagLabel(topic)}
            <ArrowDropDownIcon />
          </SButton>
        )
      }
    >
      {!topic ? (
        <TopicsWrap>
          <Topics />
        </TopicsWrap>
      ) : (
        <>
          {viewer && topic && !isSearching && <RecommendedSources lang={topic} />}
          <LibraryRows />
        </>
      )}
      {!pickedFewCards && !hintWasSeen(PICKED_FEW_CARDS) && hintWasSeen(TUTORIALDONE) && <LibraryPointer />}

      <DialogFullWindow open={location.hash === "#" + LIBRARY.topicHash} onClose={closeTopics}>
        <Topics onPick={closeTopics} />
      </DialogFullWindow>
    </HeaderLayout>
  );
};

export default Library;
