import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components/macro";

const sharedCss = css`
  display: flex;
  align-items: center;
  background: white;
  border-bottom: 0.5px solid ${({ theme }) => theme.duo.color.grey};
  padding: 20px;
  text-align: left;
  cursor: pointer;
  &:last-of-type {
    border-bottom: 1px solid transparent;
  }
`;
const SDiv = styled.div`
  ${sharedCss}
`;
const SLink = styled(Link)`
  ${sharedCss}
  color: inherit;
`;

type Props = {
  children?: React.ReactNode;
  className?: string;
  onClick?: VoidFunction;
  style?: React.CSSProperties;
  to?: string;
};

const Row = React.forwardRef<any, Props>((props, ref) => {
  if (props.to) {
    return <SLink {...props} to={props.to} ref={ref} />;
  } else {
    return <SDiv {...props} ref={ref} />;
  }
});

export default Row;
