import React from "react";
import styled from "styled-components/macro";
import { sourceQuery$data } from "../../../../queries/sources/__generated__/sourceQuery.graphql";
import { useEffectOnce, usePrevious, useUpdate, useWindowSize } from "react-use";
import { PICKED_FEW_CARDS } from "../../../../components/fire/xpHooks";
import YouTube from "react-youtube";
import Captions from "./Captions";
import Controls from "./Controls";
import { getTheme } from "../../../../styled/theme";
import { useLocation } from "react-router";
import LinkExt from "../../../../components/other/LinkExt";
import { useOpenAdsDialog } from "../../../../components/ads/adStack";
import { useMutation } from "relay-hooks";
import { ratingMutation } from "../../../../queries/rating/__generated__/ratingMutation.graphql";
import { ratingEditQL } from "../../../../queries/rating/ratingMutation";
import { ADDCARDHASH } from "../../../main/card/CardNewDialog";
import { useReachedMaximum } from "../../../../components/dialogs/CardsMaxDialog";
import * as Sentry from "@sentry/browser";
import { useSelector } from "react-redux";
import { selectXp } from "components/ReduxProvider";
import { Link } from "react-router-dom";
import PublicIcon from "@mui/icons-material/Public";
import { useViewerQuery } from "queries/viewerQuery";
import { ASSISTANT_HASH } from "components/assistant/AssistantPopup";
import { AUTHOR } from "../../../RootRouter.config";

export const ArticleHeaderH = 240;

const Container = styled.div`
  background: black;
  text-align: center;
`;
const By = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  display: flex;
  font-size: ${({ theme }) => theme.duo.fontSize.smallest};
  color: #bbb;
  padding: 3px 10px 2px;
  background: ${({ theme }) => theme.duo.color.lightGrey}cc;
  backdrop-filter: blur(3px);
  min-height: 18px;
  svg {
    font-size: 13px;
    position: relative;
    top: 3px;
    margin: 0 2px;
  }
  a {
    color: #bbb;
    text-decoration: underline;
  }
`;
const Author = styled.div`
  margin-left: auto;
`;

type Props = {
  source: NonNullable<sourceQuery$data["sourceByUriOrId"]>;
  onShowHeader: (show: boolean) => void;
};

const Video: React.FC<Props> = ({ source, onShowHeader }) => {
  const { hintWasSeen, viewer } = useViewerQuery();
  const [playing, setPlaying] = React.useState(false);
  const [rate, setRate] = React.useState(1);
  const openAdsDialog = useOpenAdsDialog();
  const [ratingMutate] = useMutation<ratingMutation>(ratingEditQL);
  const update = useUpdate();
  const location = useLocation();
  const reachedMaximum = useReachedMaximum();
  const xp = useSelector(selectXp);

  const ytRef = React.useRef<YT.Player>();
  const yt = ytRef.current;

  useEffectOnce(() => {
    return () => {
      if (viewer) {
        const val = Math.ceil(ytRef.current?.getCurrentTime() || 0);
        const continueFrom = val / Number(source.videoDuration) > 0.9 ? 0 : val; // >90% => null
        ratingMutate({ variables: { continueFrom, sourceId: source.id } });
      }
    };
  });

  const { width: wWidth } = useWindowSize();
  const maxWidth = parseInt(getTheme().maxWidth.narrow, 10);
  const width = wWidth > maxWidth ? maxWidth : wWidth;
  const height = width * (9 / 16);

  const prevHash = usePrevious(location.hash);
  const eightCards = !hintWasSeen(PICKED_FEW_CARDS) && xp.warn === "tooManyToLearn";
  React.useEffect(() => {
    if (openAdsDialog || location.hash === ADDCARDHASH || location.hash === ASSISTANT_HASH || eightCards) {
      yt?.pauseVideo();
      yt?.seekTo((yt?.getCurrentTime() || 1) - 1, true);
      setTimeout(() => yt?.pauseVideo(), 400); // in case blur will turn it up
    }
    if ((prevHash === ADDCARDHASH || prevHash === ASSISTANT_HASH) && !location.hash) {
      yt?.playVideo();
    }
  }, [openAdsDialog, yt, location, prevHash, eightCards]);

  const handleReady = (event) => {
    onShowHeader(true);
    ytRef.current = event.target as YT.Player;
    // TODO: Duration is in rare cases 0, but only on specific device (ios) and video shows "not available"
    //       and link to youtube (eg: https://www.youtube.com/watch?time_continue=3&v=DPmtnb8NBog)
    // if (!ytRef.current.getDuration()) {
    //   -> Ideally we should let user to easily report ideos like this and then hide often reported videos
    // }
    if (!reachedMaximum && !(xp.warn === "tooManyToLearn" && !xp.tooManyWarningSeen)) {
      ytRef.current.playVideo();
    }
    update();
    // setYt(event.target as YT.Player);
  };

  const captions = source.captions && JSON.parse(source.captions);

  return (
    <>
      <Container style={{ height: height }} className="nofilter rr-block rr-ignore">
        <YouTube
          videoId={source.source || "ERR"}
          className="rr-block rr-ignore"
          opts={{
            width: String(width),
            height: String(height),
            host: "https://www.youtube.com",
            playerVars: {
              showinfo: 0,
              cc_load_policy: "3" as any,
              start: source.rating?.continueFrom || source.videoStart || 0,
              // cc_lang_pref: "xy",
              fs: 0,
              playsinline: 1,
              origin: window.location.origin as any,
              enablejsapi: 1,
              disablekb: 1 // todo: should disable spacebar " " but doesn't work
            }
          }}
          onReady={handleReady}
          onStateChange={(e) => {
            // hides header if video is running and there is no warning over the screen
            onShowHeader(![1, 3].includes(e.data) || (xp.warn === "tooManyToLearn" && !xp.tooManyWarningSeen));
            setPlaying(e.data === 1);
          }}
          onPlaybackRateChange={(e) => setRate(e.data)}
          onEnd={() => {
            yt?.seekTo(0, true);
            setTimeout(() => yt?.stopVideo(), 10);
          }}
        />
      </Container>

      <By style={{ top: height }}>
        {!playing && (
          <>
            <div>
              <PublicIcon />
              <LinkExt href={`https://www.youtube.com/watch?v=${source.source}`}>YouTube</LinkExt>
            </div>
            {source.authorName && (
              <Author>
                by <Link to={AUTHOR.url(source.authorName)}>{source.authorName}</Link>
              </Author>
            )}{" "}
          </>
        )}
      </By>

      {yt && <Controls yt={yt} playing={playing} style={{ top: height }} />}
      {!captions ? (
        <div style={{ margin: "80px 20px 0", textAlign: "center" }}>
          {Sentry.captureMessage(`Subtitles error on video [${source.source}][${source.id}]`)}
          Subtitles error...
        </div>
      ) : (
        <Captions yt={yt} playing={playing} source={source} rate={rate} captions={captions} />
      )}
    </>
  );
};

export default Video;
