import { ComponentProps } from "react";

const MammothNew = (props: ComponentProps<"svg">) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="Layer_2"
    viewBox="0 0 49.55 35.62"
    {...props}
  >
    <defs>
      <linearGradient id="linear-gradient" x1={37.53} x2={38.42} y1={28.06} y2={35.2} gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#943c02" />
        <stop offset={1} stopColor="#b94b00" />
      </linearGradient>
      <linearGradient xlinkHref="#linear-gradient" id="linear-gradient-2" x1={19.82} x2={21.34} y1={22.21} y2={34.48} />
      <linearGradient id="linear-gradient-3" x1={16.94} x2={41.91} y1={31.94} y2={3.68} gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#d76900" />
        <stop offset={1} stopColor="#ffa44f" />
      </linearGradient>
      <linearGradient id="linear-gradient-4" x1={15.94} x2={17.1} y1={13.45} y2={12.26} gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#2d292d" />
        <stop offset={1} stopColor="#4b464b" />
      </linearGradient>
      <linearGradient
        xlinkHref="#linear-gradient-4"
        id="linear-gradient-5"
        x1={16.93}
        x2={19.42}
        y1={4.97}
        y2={-0.39}
      />
      <linearGradient id="linear-gradient-6" x1={16.65} x2={2.77} y1={22.06} y2={22.06} gradientUnits="userSpaceOnUse">
        <stop offset={0} stopColor="#f0e1cd" />
        <stop offset={1} stopColor="#f8f0e6" />
      </linearGradient>
      <clipPath id="clippath">
        <path
          d="M22.45 20.13v15.48h6.97v-6.86c0-.91.76-1.65 1.69-1.65h9.78c.93 0 1.69.74 1.69 1.65v6.86h6.96V20.28c0-9.12-9.01-16.52-18.34-16.52 0-1.82-1.51-3.77-3.38-3.77h-1.94c-1.87 0-3.43 1.94-3.43 3.77v.09l-.98-.91-6.77-.02-.59.93h-1.58c-1.82 0-3.24 1.39-3.24 3.22v19.82c0 3.65 2.96 6.4 6.61 6.4 2.74 0 5.01-1.94 5.01-5.42h-3.87c0 .56 0 1.16-.77 1.16s-.77-.71-.77-1.16v-7.74h6.97Z"
          className="cls-3"
        />
      </clipPath>
      <clipPath id="clippath-1">
        <path d="M17.34 12.85c0-.46-.37-.83-.83-.83s-.83.37-.83.83.37.83.83.83.83-.37.83-.83" className="cls-3" />
      </clipPath>
      <clipPath id="clippath-2">
        <path
          d="M19.82 0h-3.3c-1.82 0-3.3 1.65-3.3 3.68 0 .51.37.92.83.92s.83-.41.83-.92c0 .51.37.92.83.92s.83-.41.83-.92c0 .51.37.92.83.92s.83-.41.83-.92c0 .51.37.92.83.92s.83-.41.83-.92c0 .51.37.92.83.92s.83-.41.83-.92c0 .51.37.92.83.92s.83-.41.83-.92c0-2.03-1.48-3.68-3.3-3.68Z"
          className="cls-3"
        />
      </clipPath>
      <clipPath id="clippath-3">
        <path
          d="M14.57 17.8c-.38 0-.69.32-.69.71 0 2.75-2.17 4.98-4.86 4.98s-4.86-2.23-4.86-4.98c0-.39-.31-.71-.69-.71s-.69.32-.69.71v.71c0 3.93 3.11 7.11 6.94 7.11s6.94-3.18 6.94-7.11v-.71c0-.39-.31-.71-.69-.71h-1.39Z"
          className="cls-3"
        />
      </clipPath>
      <style>
        {
          ".cls-3,.cls-4,.cls-5{stroke-width:0}.cls-3{clip-rule:evenodd;fill:none}.cls-4,.cls-5{fill:#b5ab98;fill-rule:evenodd}.cls-5{fill:#f0e1ce}"
        }
      </style>
    </defs>
    <g id="Page_1">
      <path
        d="M11.79 17.8c-.38 0-.69.32-.69.71 0 2.75-2.17 4.98-4.86 4.98s-4.86-2.23-4.86-4.98c0-.39-.31-.71-.69-.71s-.69.32-.69.71v.71c0 3.93 3.11 7.11 6.94 7.11s6.94-3.18 6.94-7.11v-.71c0-.39-.31-.71-.69-.71H11.8Z"
        className="cls-4"
      />
      <path
        d="M34.84 27.1h6.19v8.52h-6.19z"
        style={{
          strokeWidth: 0,
          fill: "url(#linear-gradient)"
        }}
      />
      <path
        d="M18.58 20.13h3.87v15.48h-3.87z"
        style={{
          fill: "url(#linear-gradient-2)",
          strokeWidth: 0
        }}
      />
      <path
        d="M18.58 35.62h1.55v-.77c0-.43-.35-.77-.77-.77s-.77.35-.77.77v.77ZM21.68 35.62h-1.55v-.77c0-.43.35-.77.77-.77s.77.35.77.77v.77ZM21.68 35.62h1.55v-.77c0-.43-.35-.77-.77-.77s-.77.35-.77.77v.77ZM34.84 35.62h1.55v-.77c0-.43-.35-.77-.77-.77s-.77.35-.77.77v.77ZM37.94 35.62h-1.55v-.77c0-.43.35-.77.77-.77s.77.35.77.77v.77ZM37.94 35.62h1.55v-.77c0-.43-.35-.77-.77-.77s-.77.35-.77.77v.77Z"
        className="cls-4"
      />
      <g
        style={{
          clipPath: "url(#clippath)"
        }}
      >
        <path
          d="M9.29 0h40.26v35.62H9.29z"
          style={{
            fill: "url(#linear-gradient-3)",
            strokeWidth: 0
          }}
        />
      </g>
      <g
        style={{
          clipPath: "url(#clippath-1)"
        }}
      >
        <path
          d="M15.69 12.03h1.65v1.65h-1.65z"
          style={{
            fill: "url(#linear-gradient-4)",
            strokeWidth: 0
          }}
        />
      </g>
      <g
        style={{
          clipPath: "url(#clippath-2)"
        }}
      >
        <path
          d="M13.21 0h9.91v4.59h-9.91z"
          style={{
            fill: "url(#linear-gradient-5)",
            strokeWidth: 0
          }}
        />
      </g>
      <path
        d="M15.76 18.57c-.21 0-.39-.17-.39-.39s.17-.39.39-.39H18c.38 0 .73-.12 1.02-.31.3-.2.54-.5.67-.84.08-.2.3-.3.5-.22.2.08.3.3.22.5-.19.48-.53.9-.95 1.19-.41.28-.92.45-1.45.45h-2.24Z"
        style={{
          fill: "#4b464b",
          strokeWidth: 0
        }}
      />
      <g
        style={{
          clipPath: "url(#clippath-3)"
        }}
      >
        <path
          d="M2.77 17.8h13.87v8.53H2.77z"
          style={{
            fill: "url(#linear-gradient-6)",
            strokeWidth: 0
          }}
        />
      </g>
      <path d="M22.46 35.62h1.55v-.77c0-.43-.35-.77-.77-.77s-.77.35-.77.77v.77Z" className="cls-5" />
      <path
        d="M25.55 35.62H24v-.77c0-.43.35-.77.77-.77s.77.35.77.77v.77ZM25.55 35.62h1.55v-.77c0-.43-.35-.77-.77-.77s-.77.35-.77.77v.77ZM42.59 35.62h1.55v-.77c0-.43-.35-.77-.77-.77s-.77.35-.77.77v.77ZM45.69 35.62h-1.55v-.77c0-.43.35-.77.77-.77s.77.35.77.77v.77ZM45.69 35.62h1.55v-.77c0-.43-.35-.77-.77-.77s-.77.35-.77.77v.77Z"
        className="cls-5"
      />
    </g>
  </svg>
);
export default MammothNew;
