import React from "react";
import styled, { keyframes } from "styled-components/macro";

const FLOATING_DURATION = 4000;

const Wrap = styled.div`
  position: absolute;
  left: -10px;
  bottom: 20px;
  margin: -15% auto;
  width: 70px;
  height: 150px;
  pointer-events: none;
`;

const floatingHeartKeyframes = keyframes`
	0% {
		opacity: 0;
		bottom: 0%;
		left: 0%;
	}
	10% {
		opacity: 1;
		bottom: 20%;
		left: 70%;
	}
	30% {
		bottom: 40%;
		left: 10%;
	}
	50% {
		bottom: 60%;
		left: 50%;
	}
	70% {
		opacity: 1;
		bottom: 80%;
		left: 5%;
	}
	85% {
		opacity: 0;
		bottom: 100%;
		left: 60%;
	}
`;

const FloatingHeart = styled.div<{ bigger?: boolean }>`
  position: absolute;
  opacity: 0;
  font-size: ${({ bigger }) => (bigger ? "24px" : "16px")};
  animation: ${floatingHeartKeyframes} ${FLOATING_DURATION}ms forwards ease-in-out; //cubic-bezier(0.5, 0.5, 0.5, 0.5);

  ::before {
    content: "🧡";
  }
`;

type Heart = {
  id: string;
  big: boolean;
  created: number;
};

type Props = { addInterval: number };

const MammothHearts: React.FC<Props> = ({ addInterval }) => {
  const [hearts, setHearts] = React.useState<Heart[]>([]);
  const counter = React.useRef(0);

  const addHeart = () => {
    const big = addInterval < 1000;
    const newHeart = {
      id: `unique-heart-${counter.current}`,
      big,
      created: Date.now()
    };
    counter.current++;

    if (big && window.navigator.vibrate) {
      window.navigator.vibrate(200);
    }

    setHearts((hearts) => {
      const remainingHearts = hearts.filter((heart) => heart.created > Date.now() - FLOATING_DURATION);
      return [...remainingHearts, newHeart];
    });
  };

  React.useEffect(() => {
    if (addInterval > 0) {
      const interval = setInterval(addHeart, addInterval);
      addHeart();

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addInterval]);

  return (
    <Wrap>
      {hearts.map((heart) => (
        <FloatingHeart bigger={heart.big} key={heart.id} />
      ))}
    </Wrap>
  );
};

export default MammothHearts;
