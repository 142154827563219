import { RefObject, useEffect, useRef } from "react";

const defaultEvents = ["mousedown", "touchstart"];

const useClickAway = <E extends Event = Event>(
  ref: RefObject<HTMLElement | null>,
  onClickAway: (event: E) => void,
  events: string[] = defaultEvents
) => {
  const savedCallback = useRef(onClickAway);
  useEffect(() => {
    savedCallback.current = onClickAway;
  }, [onClickAway]);
  useEffect(() => {
    const handler = (event) => {
      const { current: el } = ref;
      el && !el.contains(event.target) && savedCallback.current(event);
    };
    for (const eventName of events) {
      document.body.addEventListener(eventName, handler); // there is the difference between original useClickAway from react-use and this one, we listen to body instead of window
    }
    return () => {
      for (const eventName of events) {
        document.body.removeEventListener(eventName, handler);
      }
    };
  }, [events, ref]);
};

export default useClickAway;
