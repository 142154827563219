/**
 * @generated SignedSource<<894243a89284e486b4f39b49f9f35390>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type deleteDeckMutation$variables = {
  id: string;
};
export type deleteDeckMutation$data = {
  readonly deleteDeck: {
    readonly " $fragmentSpreads": FragmentRefs<"viewerQuery_decks">;
  } | null;
};
export type deleteDeckMutation = {
  response: deleteDeckMutation$data;
  variables: deleteDeckMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteDeckMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "deleteDeck",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "viewerQuery_decks"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteDeckMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "deleteDeck",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Deck",
            "kind": "LinkedField",
            "name": "decks",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "front",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "back",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Stats",
                "kind": "LinkedField",
                "name": "stats",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "known",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unknown",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completed",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TtsVoice",
                "kind": "LinkedField",
                "name": "voices",
                "plural": true,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a922cc56e71fc7d9d18e669450a86e0b",
    "id": null,
    "metadata": {},
    "name": "deleteDeckMutation",
    "operationKind": "mutation",
    "text": "mutation deleteDeckMutation(\n  $id: ID!\n) {\n  deleteDeck(id: $id) {\n    ...viewerQuery_decks\n    id\n  }\n}\n\nfragment viewerQuery_decks on User {\n  decks {\n    id\n    name\n    front\n    back\n    stats {\n      total\n      known\n      unknown\n      completed\n    }\n    voices {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "50382159d051c39de4067067e0a93fc1";

export default node;
