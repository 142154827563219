import React from "react";
import { initializeApp } from "firebase/app";
import { getAuth as getAuthCordova } from "firebase/auth/cordova";
import { canShowOAuth, isBrowser } from "tools/device";
import { getAuth as getAuthWeb } from "firebase/auth";

const FirebaseInitializer: React.FC = () => {
  React.useEffect(() => {
    if (!canShowOAuth()) return;

    const getAuth = isBrowser() ? getAuthWeb : getAuthCordova;
    const firebaseConfig = {
      apiKey: "AIzaSyALmUOHBGgKidjfwMenW3930w44H4MitVQ",
      authDomain: "duocards.firebaseapp.com",
      databaseURL: "https://duocards.firebaseio.com",
      projectId: "duocards",
      storageBucket: "duocards.appspot.com",
      messagingSenderId: "438343393548",
      appId: "1:438343393548:web:17b82a99a24a5fa16b6929",
      measurementId: "G-678MZMW0NN"
    };

    const firebaseApp = initializeApp(firebaseConfig);
    (window as any).firebaseAuth = getAuth(firebaseApp);

    /*onAuthStateChanged((window as any).firebaseAuth, (result) => {
      console.info(`INFO> authstatechanged`, result);
    });*/

    /*window.addEventListener(
      "message",
      (msg) => {
        try {
          const data = msg.data.substr(2);
          const json = JSON.parse(data);
          const authEvent = json.a[0].authEvent;
          if (authEvent) {
            console.warn(authEvent);
            (window as any).logAuthEvent = authEvent;
          }
        } catch {}
      },
      false
    );*/
  }, []);

  return null;
};

export default FirebaseInitializer;
