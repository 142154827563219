/**
 * @generated SignedSource<<c793134d21424d845de41aa95385e004>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Kind = "article" | "set" | "video" | "%future added value";
export type LibraryRefetchQuery$variables = {
  count: number;
  course?: boolean | null;
  cursor?: string | null;
  id: string;
  kind?: Kind | null;
  lang?: string | null;
  langNative?: string | null;
  lastVisited?: boolean | null;
  mine?: boolean | null;
  search?: string | null;
  sort?: string | null;
};
export type LibraryRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"libraryQuery_Public">;
  } | null;
};
export type LibraryRefetchQuery = {
  response: LibraryRefetchQuery$data;
  variables: LibraryRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "course"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "kind"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lang"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "langNative"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastVisited"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mine"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v11 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v12 = {
  "kind": "Variable",
  "name": "course",
  "variableName": "course"
},
v13 = {
  "kind": "Variable",
  "name": "kind",
  "variableName": "kind"
},
v14 = {
  "kind": "Variable",
  "name": "lang",
  "variableName": "lang"
},
v15 = {
  "kind": "Variable",
  "name": "langNative",
  "variableName": "langNative"
},
v16 = {
  "kind": "Variable",
  "name": "lastVisited",
  "variableName": "lastVisited"
},
v17 = {
  "kind": "Variable",
  "name": "mine",
  "variableName": "mine"
},
v18 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v19 = {
  "kind": "Variable",
  "name": "sort",
  "variableName": "sort"
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v22 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v12/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  (v19/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LibraryRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              (v12/*: any*/),
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "libraryQuery_Public"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "LibraryRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v20/*: any*/),
          (v21/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v22/*: any*/),
                "concreteType": "SourceConnection",
                "kind": "LinkedField",
                "name": "sources",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SourceEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Source",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v21/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "uri",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              (v15/*: any*/)
                            ],
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "course",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "private",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "mine",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "difficulty",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "source",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "videoDuration",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "textLength",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "top",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "avgRating",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Image",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "authorName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "authorApiName",
                                "storageKey": null
                              },
                              (v21/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Rating",
                            "kind": "LinkedField",
                            "name": "rating",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "rating",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "continueFrom",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "picked",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "dismissed",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "finished",
                                "storageKey": null
                              },
                              (v21/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SCounts",
                            "kind": "LinkedField",
                            "name": "counts",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "total",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "theories",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ContentTestType",
                            "kind": "LinkedField",
                            "name": "contentTest",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "active",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ContentTestAdminType",
                                "kind": "LinkedField",
                                "name": "admin",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "runningSince",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "revenueOn",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "revenueOff",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "percent",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "accuracy",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v20/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v22/*: any*/),
                "filters": [
                  "kind",
                  "course",
                  "lang",
                  "mine",
                  "langNative",
                  "lastVisited",
                  "search",
                  "sort"
                ],
                "handle": "connection",
                "key": "Library_sources",
                "kind": "LinkedHandle",
                "name": "sources"
              }
            ],
            "type": "Public",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff2303d1a7b5363c631be34089bea209",
    "id": null,
    "metadata": {},
    "name": "LibraryRefetchQuery",
    "operationKind": "query",
    "text": "query LibraryRefetchQuery(\n  $count: Int!\n  $course: Boolean\n  $cursor: String\n  $kind: Kind\n  $lang: String\n  $langNative: String\n  $lastVisited: Boolean\n  $mine: Boolean\n  $search: String\n  $sort: String\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...libraryQuery_Public_1gnTqc\n    id\n  }\n}\n\nfragment libraryQuery_Public_1gnTqc on Public {\n  sources(first: $count, after: $cursor, kind: $kind, course: $course, lang: $lang, mine: $mine, langNative: $langNative, lastVisited: $lastVisited, search: $search, sort: $sort) {\n    edges {\n      node {\n        id\n        uri\n        name(langNative: $langNative)\n        kind\n        course\n        private\n        mine\n        difficulty\n        source\n        videoDuration\n        textLength\n        top\n        avgRating\n        image {\n          url\n          authorName\n          authorApiName\n          id\n        }\n        rating {\n          rating\n          continueFrom\n          picked\n          dismissed\n          finished\n          id\n        }\n        counts {\n          total\n          theories\n        }\n        contentTest {\n          active\n          admin {\n            runningSince\n            revenueOn\n            revenueOff\n            percent\n            accuracy\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "fa7b4982ceaad673d497a6ac47930c3f";

export default node;
