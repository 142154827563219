import React from "react";

type ConditonalWrapProps = {
  children: React.ReactElement;
  condition: boolean;
  wrap?: (children: React.ReactElement) => JSX.Element;
  elseWrap?: (children: React.ReactElement) => JSX.Element;
};
export const ConditonalWrap: React.FC<ConditonalWrapProps> = ({ condition, wrap, elseWrap, children }) => {
  if (condition && wrap) return wrap(children);
  if (!condition && elseWrap) return elseWrap(children);
  return children;
};
