import React from "react";
import styled from "styled-components/macro";

const Wrap = styled.span`
  white-space: nowrap;
`;

type Props = { value: number; className?: string };
const FormatNumber: React.FC<Props> = ({ value, className }) => {
  return (
    <Wrap className={className}>{value.toLocaleString("en-US", { maximumFractionDigits: 0 }).replace(/,/g, " ")}</Wrap>
  );
};

export default FormatNumber;
