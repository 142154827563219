import React from "react";
import styled, { css } from "styled-components/macro";
import { getTheme } from "styled/theme";

export type CircleColor = keyof ReturnType<typeof getTheme>["color"];

const Wrap = styled.div`
  position: relative;
  z-index: 1;
  height: 50px;
`;
const Circle = styled.div<{ iconColor: CircleColor; narrow?: boolean }>`
  position: absolute;
  display: flex;
  left: -10px;
  top: 30px;
  border-radius: 6px;
  color: ${({ theme, iconColor }) => theme.duo.color[iconColor]};
  background: white;
  ${({ narrow }) =>
    narrow &&
    css`
      width: 18px;
    `}
  svg {
    font-size: 15px;
    height: 18px;
    width: 18px;
  }
`;
const SCount = styled.div`
  position: absolute;
  bottom: -1px;
  right: -1px;
  background: ${({ theme }) => theme.duo.color.primary};
  color: white;
  font-size: 10px;
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
  width: 1.7em;
  height: 1.7em;
  line-height: 1.7em;
  text-align: center;
  border-radius: 1em;
  border: 0.5px solid white;
`;

type Props = {
  iconColor?: CircleColor;
  icon: React.ReactNode;
  count?: number;
  narrow?: boolean;
};

const CardCircle: React.FC<Props> = (p) => {
  return (
    <Wrap>
      <Circle narrow={p.narrow} iconColor={p.iconColor || "green"}>
        {p.icon}
        {p.count && <SCount>{p.count}x</SCount>}
      </Circle>
    </Wrap>
  );
};

export default CardCircle;
