import React from "react";
import { useGetState, useSetState } from "../components/ReduxProvider";
import { useLocation } from "react-use";
import { useNavigate } from "react-router-dom";

const locEqual = (path1, path2) =>
  path1.pathname === path2.pathname && path1.search === path2.search && path1.hash === path2.hash;

const RootRouterHistory = () => {
  const routeHistory = useGetState("routeHistory");
  const setRouteHistory = useSetState("routeHistory");
  const loc = useLocation();
  const navigate = useNavigate();
  const backing = React.useRef(false);

  React.useEffect(() => {
    if (!routeHistory) {
      setRouteHistory([loc]);
    } else {
      if (loc.trigger === "popstate") {
        const foundIndex = routeHistory.findIndex((r) => locEqual(r, loc));
        if (foundIndex > 0) {
          setRouteHistory(routeHistory.slice(foundIndex));
        }
        backing.current = false;
        return;
      }

      // when replacing url and previous one is identical, it will go back instead
      if (loc.trigger === "replacestate") {
        if (!locEqual(routeHistory[0], loc) && !backing.current) {
          if (routeHistory[1] && locEqual(routeHistory[1], loc)) {
            backing.current = true;
            navigate(-1);
          } else {
            setRouteHistory([loc, ...routeHistory.slice(1)]);
          }
        }
        return;
      }

      if (!locEqual(routeHistory[0], loc)) {
        const lastEightRoutes = [loc, ...routeHistory].slice(0, 8);
        setRouteHistory(lastEightRoutes);
      }
    }
  }, [loc, setRouteHistory, routeHistory, backing, navigate]);

  return null;
};

export default RootRouterHistory;
