import React from "react";
import styled from "styled-components/macro";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setInviteDialog } from "../ReduxProvider";
import { devicePaddings } from "../../tools/device";
import { useTtsQuery } from "../../queries/ttsQuery";
import { silenceTts } from "../../queries/tts/ttsTools";
import { useDeviceLang } from "../../hooks/deviceHooks";
import Mammoth from "icons/Mammoth";
import { useCanAdvertFriends } from "./useCanAdvertFriends";
import { useNavigate } from "react-router";
import { useTimedActions } from "hooks/useTimedActions";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";
const { top } = devicePaddings();

const Wrap = styled.div`
  height: 100%;
  padding: ${top + 55}px 0;
  box-shadow: inset 0 -20px 100px #00aaff33;

  .showing {
    opacity: 1;
    transition: opacity 1s;
  }

  .hidden {
    opacity: 0;
    visibility: hidden;
  }
`;
const SIconButton = styled(IconButton)`
  position: absolute;
  top: ${top + 10}px;
  right: 10px;
  opacity: 0;
  transition: all 1s;
`;
const In = styled.div`
  display: flex;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 20px;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  p {
    margin: 8px 0;
  }
`;
const SButton = styled(Button)`
  align-self: center;
  padding: 11px 42px;
`;
const Connect = styled.span`
  color: ${({ theme }) => theme.duo.color.green};
  cursor: pointer;
`;

type Props = { onClose: VoidFunction };

const InternAdDoYouEnjoy: React.FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const advertFriends = useCanAdvertFriends();
  const { ttsLang } = useDeviceLang();
  const tts = useTtsQuery(ttsLang);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [visibleIndex, setVisibleIndex] = React.useState(0);
  useTimedActions([
    { delay: 0, callback: () => setVisibleIndex(1) },
    { delay: 1500, callback: () => setVisibleIndex(2) },
    { delay: 3000, callback: () => setVisibleIndex(3) },
    { delay: 4500, callback: () => setVisibleIndex(4) },
    { delay: 6000, callback: () => setVisibleIndex(5) }
  ]);

  const order = (e) => {
    e.stopPropagation();
    navigate(SUBSCRIPTION.url());
  };

  React.useEffect(() => {
    if (!tts) return;
    tts({
      text: t("Do you enjoy DuoCards? Subscribe and support us in the development of new features and new courses."),
      speakingRate: 1
    });
  }, [tts, t]);

  return (
    <Wrap>
      <SIconButton
        onClick={() => {
          silenceTts();
          onClose();
        }}
        className={visibleIndex >= 5 ? "showing" : "hidden"}
      >
        <CloseIcon />
      </SIconButton>
      <In>
        <Trans parent="p" className={visibleIndex >= 1 ? "showing" : "hidden"}>
          Do you enjoy DuoCards?
        </Trans>
        <Trans parent="p" className={visibleIndex >= 2 ? "showing" : "hidden"}>
          And do you want to support us in the development of this unique educational app?
        </Trans>

        <div className={visibleIndex >= 3 ? "showing" : "hidden"}>
          🧡 <Trans parent="strong">You can!</Trans> 🧡
          <div>
            <Mammoth />
          </div>
        </div>

        <SButton
          className={visibleIndex >= 4 ? "showing" : "hidden"}
          variant="contained"
          color="primary"
          size="large"
          onClick={order}
        >
          <Trans>Subscribe</Trans>
        </SButton>

        <div className={visibleIndex >= 5 ? "showing" : "hidden"}>
          <p>
            <Trans>More subscribers = more features</Trans>
          </p>

          {advertFriends && (
            <>
              <hr style={{ margin: 10 }} />

              <p>
                {/* prettier-ignore */}
                <Trans>
                Or{" "}
                <Connect onClick={() => dispatch(setInviteDialog(true))}>
                  connect with friends
                </Connect>{" "}
                and get the <strong>subscription for free</strong>!
              </Trans>
              </p>
            </>
          )}
        </div>
      </In>
    </Wrap>
  );
};

export default InternAdDoYouEnjoy;
