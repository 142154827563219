import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAdStack, incAdStack } from "../ReduxProvider";
import { isBrowser, isChromeExtension } from "../../tools/device";
import { useMatch } from "react-router";
import { useViewerQuery } from "../../queries/viewerQuery";
import { SOURCEFORM } from "../../root/library/library/libraryUrls";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";

export const useIncAdStack = () => {
  const dispatch = useDispatch();
  const { viewer } = useViewerQuery();
  const inPop = isChromeExtension();
  const inEditing = useMatch(SOURCEFORM.query());
  return React.useCallback(
    (increment: number) => {
      if ((!!window.admob || isBrowser()) && !inPop && !viewer?.subscription && !inEditing) {
        dispatch(incAdStack(increment));
      }
    },
    [dispatch, inEditing, inPop, viewer?.subscription]
  );
};

const EXTRA = 60; // extra delay for those who cannot buy subscription

const useAdStackAmount = () => {
  const { noPayments } = useViewerQuery();
  const extra = noPayments() ? EXTRA : 0;
  return 125 + extra;
};

export const useOpenAdsDialog = () => {
  const adStackAmount = useAdStackAmount();
  const inSubscription = useMatch(SUBSCRIPTION.url());
  const adStack = useSelector(selectAdStack);
  return Number(adStack) >= adStackAmount && !inSubscription;
};

export const useShouldPrepareAd = () => {
  const adStackAmount = useAdStackAmount();
  const adStack = useSelector(selectAdStack);
  return Number(adStack) >= adStackAmount - 20;
};
