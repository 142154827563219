import React from "react";
import { useMutation } from "relay-hooks";
import styled, { css } from "styled-components/macro";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import ThumbDownRoundedIcon from "@mui/icons-material/ThumbDownRounded";
import { IconButton } from "@mui/material";
import { ratingEditQL } from "queries/rating/ratingMutation";
import { ratingMutation } from "queries/rating/__generated__/ratingMutation.graphql";
import { useViewerQuery } from "queries/viewerQuery";

const Wrap = styled.div<{ padded?: boolean }>`
  text-align: center;
  padding-bottom: ${({ padded }) => (padded ? "50px;" : "0")};
`;
const FixIconButton = ({ small, hide, ...p }) => <IconButton {...p} />;
type SIBProps = { small?: boolean; hide?: boolean };
const SIconButton = styled(FixIconButton)<SIBProps>`
  background: #fff !important;
  color: #555;
  box-shadow: 1px 1px 7px#0004;
  margin: 0 10px;
  opacity: ${({ hide }) => (hide ? 0.4 : 1)};
  ${({ small }) =>
    small &&
    css`
      margin: -8px 0 -2px;
      padding: 10px;
      svg {
        font-size: 15px;
      }
    `}
`;

export type SourceForRate = {
  id: string;
  rating?: {
    rating: number | null;
  } | null;
  name: string;
  uri: string;
  mine: boolean;
};
type Props = {
  source: SourceForRate;
  padded?: boolean;
};

const Rate: React.FC<Props> = ({ source, padded }) => {
  const [ratingMutate] = useMutation<ratingMutation>(ratingEditQL);
  const { viewer } = useViewerQuery();

  if (!viewer || source.mine) return null;

  const rating = source.rating?.rating;

  return (
    <Wrap padded={padded}>
      <SIconButton hide={rating === 1} onClick={() => ratingMutate({ variables: { sourceId: source.id, rating: 5 } })}>
        <ThumbUpRoundedIcon style={{ color: rating === 5 ? "green" : "inherit" }} />
      </SIconButton>
      <SIconButton hide={rating === 5} onClick={() => ratingMutate({ variables: { sourceId: source.id, rating: 1 } })}>
        <ThumbDownRoundedIcon style={{ color: rating === 1 ? "orange" : "inherit" }} />
      </SIconButton>
    </Wrap>
  );
};

export default Rate;
