import React from "react";
import styled from "styled-components/macro";
import { flag } from "../../../tools/langs";
import LangMenu from "../../../components/LangMenu/LangMenu";
import { useGetState } from "../../../components/ReduxProvider";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router";
import { useLibraryUrl } from "../../RootRouter";
import { Trans } from "react-i18next";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import useLangNative from "../../../hooks/useLangNative";
import { LANGNATIVEPARAM } from "./libraryUrls";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  transform: scale(0.85);
`;
const Btn = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: white;
  border-radius: 5px;
  box-shadow: 0.5px 0.5px 5px #0002;
  padding: 2px 1px 2px 5px;
`;
const DownIcon = styled(ArrowDropDownIcon)`
  font-size: 15px;
`;
const PickNative = styled.div`
  position: absolute;
  right: 18px;
  margin-top: 84px;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  background: ${({ theme }) => theme.duo.color.primary};
  padding: 6px 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 6px #0004;
  color: white;
  white-space: nowrap;
  z-index: 10;
`;
const SArrowDropUpIcon = styled(ArrowDropUpIcon)`
  position: absolute;
  right: 7px;
  top: -17px;
  font-size: 30px;
  color: ${({ theme }) => theme.duo.color.primary};
`;

type Props = {};

const LibLangPicker: React.FC<Props> = () => {
  const libLang = useGetState("libTopic") || "";
  const langNative = useLangNative();
  const navigate = useNavigate();
  const libUrl = useLibraryUrl();

  return (
    <Wrap>
      {flag(libLang)} &nbsp;/&nbsp;
      <LangMenu
        onSelect={(lang) => navigate(`${libUrl}?${LANGNATIVEPARAM}=${lang}`)}
        value={langNative || undefined}
        disabledLangs={[libLang]}
        title={
          <Btn>
            {flag(langNative)}
            <DownIcon />
            {libLang === langNative && (
              <PickNative>
                <SArrowDropUpIcon />
                <Trans>Pick your native Language</Trans>
              </PickNative>
            )}
          </Btn>
        }
      />
    </Wrap>
  );
};

export default LibLangPicker;
