import React from "react";
import styled from "styled-components/macro";
import { Button, IconButton } from "@mui/material";
import { selectTheme, setTheme, Theme as ThemeType } from "../../../components/ReduxProvider";
import { useDispatch, useSelector } from "react-redux";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import { useViewerQuery } from "../../../queries/viewerQuery";
import StarIcon from "components/other/StarIcon";
import RollingMenu from "components/other/RollingMenu";
import FADuotoneIcon from "icons/FADuotoneIcon";
import { SUBSCRIPTION } from "../../profile/ProfileRouter.config";

const Wrap = styled.div`
  position: relative;
  svg {
    color: #aaa;
    font-size: 26px;
  }
`;
const SIconButton = styled(IconButton)`
  margin-bottom: 1px;
`;
const SRollingMenu = styled(RollingMenu)`
  text-align: center;
  font-weight: bold;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  color: ${({ theme }) => theme.duo.color.textGrey};
  line-height: 1.35em;
`;
const In = styled.div`
  display: flex;
  justify-content: center;
`;
const Square = styled.div<{ sel: boolean }>`
  position: relative;
  padding: 10px;
  border-bottom: 1px solid
    ${({ theme, sel }) => (sel ? (theme.duo.theme === "dark" ? "#555" : theme.duo.color.grey) : "transparent")};
  div {
    width: 38px;
    height: 38px;
    border-radius: 7px;
    box-shadow: 1px 1px 4px #0003;
  }
  svg {
    color: ${({ theme }) => theme.duo.color.primary};
    right: 11px;
    bottom: 11px;
    top: auto;
  }
`;
const Default = styled.div`
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.duo.color.primary} 40%,
    ${({ theme }) => theme.duo.color.lightGrey} 60%
  );
`;
const Dark = styled.div`
  background: linear-gradient(135deg, black 40%, #555 60%);
`;
const Sepia = styled.div`
  background: linear-gradient(135deg, #b98745 40%, #fffef0 60%);
`;
const Pink = styled.div`
  background: linear-gradient(135deg, #f9649f 40%, #fff5f8 60%);
`;
const Text = styled.div`
  margin: 8px 0 -5px;
`;
const SStarIcon = styled(StarIcon)`
  top: 4px;
  right: 2px;
`;

type Props = {};

const Theme: React.FC<Props> = () => {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const thm = useSelector(selectTheme);
  const navigate = useNavigate();
  const { viewer, noPayments } = useViewerQuery();
  const ref: React.RefObject<HTMLDivElement> = React.useRef(null);
  const buttonRef: React.RefObject<HTMLButtonElement> = React.useRef(null);

  const nonSubscriber = !viewer?.subscription && !noPayments();

  React.useEffect(() => {
    nonSubscriber && thm !== "default" && !open && dispatch(setTheme("default"));
  }, [viewer, thm, open, dispatch, nonSubscriber]);

  const handleChange = (value: ThemeType) => {
    dispatch(setTheme(value));
    if (value !== "default") {
      const setButtonLeft = (pixels: number) => {
        if (!buttonRef.current) return;
        buttonRef.current.style.marginLeft = pixels + "px";
      };
      setTimeout(() => setButtonLeft(10), 200);
      setTimeout(() => setButtonLeft(-10), 300);
      setTimeout(() => setButtonLeft(10), 400);
      setTimeout(() => setButtonLeft(-10), 500);
      setTimeout(() => setButtonLeft(0), 600);
    }
  };

  return (
    <Wrap ref={ref}>
      {nonSubscriber && <SStarIcon />}
      <SIconButton onClick={() => setOpen(true)}>
        <FADuotoneIcon icon="pallete" switchedColors active={open} />
      </SIconButton>

      <SRollingMenu open={open} onClose={() => setOpen(false)}>
        <In className="nofilter">
          <Square onClick={() => handleChange("pink")} sel={thm === "pink"}>
            <StarIcon />
            <Pink />
          </Square>
          <Square onClick={() => handleChange("sepia")} sel={thm === "sepia"}>
            <StarIcon />
            <Sepia />
          </Square>
          <Square onClick={() => handleChange("dark")} sel={thm === "dark"}>
            <StarIcon style={{ color: "#09d" }} />
            <Dark />
          </Square>
          <Square onClick={() => handleChange("default")} sel={thm === "default"}>
            <Default />
          </Square>
        </In>
        {nonSubscriber && (
          <>
            <Trans parent={Text}>To use themes you have to subscribe</Trans>
            <Button
              ref={buttonRef}
              size="small"
              color="primary"
              onClick={() => navigate(SUBSCRIPTION.url())}
              style={{ transition: "margin-left 100ms" }}
            >
              <Trans>Subscribe</Trans>
            </Button>
          </>
        )}
      </SRollingMenu>
    </Wrap>
  );
};

export default Theme;
