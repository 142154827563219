import { useGetState } from "components/ReduxProvider";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";

const Alert = styled.div`
  color: ${({ theme }) => theme.duo.color.red};
`;

export const SubscriptionAlreadyPurchased = () => {
  const alreadyPurchasedBy = useGetState("alreadyPurchasedBy");
  if (!alreadyPurchasedBy) return null;

  return (
    <Alert>
      <Trans>The subscription has been already purchased</Trans>
      <br />
      <strong>{alreadyPurchasedBy.email}</strong>
      <br />
      <Trans>Unfortunately it's not possible to buy a subscription for more accounts from one device.</Trans>
      <br />
      <Trans>If you need our assistance, please contact us by sending a feedback.</Trans>
    </Alert>
  );
};
