import React from "react";
import styled from "styled-components/macro";
import { Trans } from "react-i18next";
import ErrorIcon from "@mui/icons-material/Error";

const Warn = styled.div`
  display: flex;
  align-items: center;
  color: #ff8833;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.4em;
  margin: -3px -10px 0;
  svg {
    margin-right: 7px;
  }
`;

type Props = { warning: string; className?: string };

const DeckWarn: React.FC<Props> = ({ className, warning }) => {
  return (
    <Warn className={className}>
      <ErrorIcon />
      <Trans parent="div">{warning}</Trans>
    </Warn>
  );
};

export default DeckWarn;
