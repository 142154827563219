import React from "react";
import { FileField } from "styled/FileField";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import styled from "styled-components/macro";
import Row from "components/other/Row";
import { ImportRowMutation } from "./__generated__/ImportRowMutation.graphql";

const SRow = styled(Row)`
  margin-top: 20px;
  padding: 10px 0;
  label {
    margin: 0;
  }
`;

const mutation = graphql`
  mutation ImportRowMutation($files: [File!]!) {
    bulkTranslate(files: $files)
  }
`;

type Props = {};

const ImportRow: React.FC<Props> = () => {
  const [mutate] = useMutation<ImportRowMutation>(mutation);
  const [output, setOutput] = React.useState<string | null>(null);

  return (
    <SRow>
      <div>
        <FileField
          label={
            <>
              <FileDownloadIcon /> Import
            </>
          }
          name="translations"
          multiple
          onChange={(files) => {
            mutate({
              // @ts-ignore
              variables: { files },
              onCompleted: (data) => {
                setOutput(data.bulkTranslate);
              }
            });
          }}
        />
        {output && (
          <div
            style={{
              padding: "10px 30px",
              color: output?.includes("ERROR") ? "red" : "inherit",
              whiteSpace: "pre-line"
            }}
          >
            {output}
          </div>
        )}
      </div>
    </SRow>
  );
};

export default ImportRow;
