import React from "react";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { useViewerQuery, useViewerXps } from "../../../../queries/viewerQuery";
import { canShowOAuth } from "../../../../tools/device";
import { FIRSTLOOPDONE } from "../../learn/Learn";
import LikeIt, { LIKEITHINT } from "../LikeIt";
import SocialsHint, { SOCIALSHINT } from "./SocialsHint";
import OAuthHint, { OAUTH_HINT } from "root/main/main/OAuthHint";
import { useGetState } from "components/ReduxProvider";
import { GENERATED_APPLANG_HINT } from "root/other/GeneratedAppLangWarning";
import { isAppLangGenerated } from "sharedJs__generated/langsList";
import GeneratedAppLangDialog from "components/other/GeneratedAppLangDialog";
import { usePreviousDistinct } from "react-use";

type Props = {};

const MainHints: React.FC<Props> = () => {
  const { viewer, hintWasSeen } = useViewerQuery();
  const { xps } = useViewerXps();
  const [showHint, setShowHint] = React.useState<string>();
  const pickedLang = useGetState("pickedLang");

  const prevTotal = usePreviousDistinct(xps?.total);
  const skipWhenRewarding = prevTotal && xps?.total && prevTotal + 1 < xps?.total;

  React.useEffect(() => {
    if (viewer && !showHint && pickedLang && isAppLangGenerated(pickedLang) && !hintWasSeen(GENERATED_APPLANG_HINT)) {
      setShowHint(GENERATED_APPLANG_HINT);
      return;
    }

    if (skipWhenRewarding) return;

    if (viewer && xps && !showHint && hintWasSeen(FIRSTLOOPDONE) && hintWasSeen(TUTORIALDONE)) {
      if (xps.total >= 25 && !hintWasSeen(SOCIALSHINT)) {
        setShowHint(SOCIALSHINT);
      } else if (xps.total >= 35 && !hintWasSeen(LIKEITHINT)) {
        setShowHint(LIKEITHINT);
      } else if (xps.total >= 50 && !hintWasSeen(OAUTH_HINT) && !viewer.gId && !viewer.appleId && canShowOAuth()) {
        setShowHint(OAUTH_HINT);
      }
    }
  }, [viewer, hintWasSeen, showHint, xps, pickedLang, skipWhenRewarding]);

  if (skipWhenRewarding) return null;

  return (
    <>
      {showHint === LIKEITHINT && <LikeIt />}
      {showHint === SOCIALSHINT && <SocialsHint />}
      {showHint === OAUTH_HINT && <OAuthHint />}
      {showHint === GENERATED_APPLANG_HINT && <GeneratedAppLangDialog />}
    </>
  );
};

export default MainHints;
