import React from "react";
import { useIdle } from "react-use";
import { EVENT, dispatchEvent } from "tools/events";

export const useNotificationsSettings = () => {
  const settings = (window as any).cordova?.plugins?.settings;
  const [opened, setOpened] = React.useState(false);
  const isIdle = useIdle(2000);

  React.useEffect(() => {
    if (opened && !isIdle) {
      dispatchEvent(EVENT.refreshNotifications);
      setOpened(false);
    }
  }, [opened, isIdle]);

  return () => {
    settings?.open("notification_id");
    setTimeout(() => setOpened(true), 2500); // opened in combination with isIdle will recognize when user comes back from settings
  };
};
