/**
 * @generated SignedSource<<75e049c0b526a3ac0b8f298523eb245f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type fetchSttQuery$variables = {
  audio: string;
  audioFormat?: string | null;
  languages?: ReadonlyArray<string | null> | null;
};
export type fetchSttQuery$data = {
  readonly stt: {
    readonly text: string;
  } | null;
};
export type fetchSttQuery = {
  response: fetchSttQuery$data;
  variables: fetchSttQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "audio"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "audioFormat"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "languages"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "audio",
        "variableName": "audio"
      },
      {
        "kind": "Variable",
        "name": "audioFormat",
        "variableName": "audioFormat"
      },
      {
        "kind": "Variable",
        "name": "languages",
        "variableName": "languages"
      }
    ],
    "concreteType": "Stt",
    "kind": "LinkedField",
    "name": "stt",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "text",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "fetchSttQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "fetchSttQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5ee66f51df9459e6ebde293f092c975f",
    "id": null,
    "metadata": {},
    "name": "fetchSttQuery",
    "operationKind": "query",
    "text": "query fetchSttQuery(\n  $audio: String!\n  $audioFormat: String\n  $languages: [String]\n) {\n  stt(audio: $audio, audioFormat: $audioFormat, languages: $languages) {\n    text\n  }\n}\n"
  }
};
})();

(node as any).hash = "8f47f8c08dd17d28c49456f118f2e2b8";

export default node;
