import { Button } from "@mui/material";
import { useSeeHint } from "components/hint/Hint";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";
import { TutorialFC } from "../TutorialContent";
import AppLang from "components/other/AppLang";
import { faBooks } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const TUTORIALBEFORESTART = "beforeStart";

const LibIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  color: ${({ theme }) => theme.duo.color.primary};
`;

const BeforeStart: TutorialFC = () => {
  const seeHint = useSeeHint();
  return (
    <>
      <AppLang inTutorial />
      <p>
        <LibIcon icon={faBooks} />
      </p>
      <Trans parent="p">First let's go to the Library to choose words and phrases you want to learn later.</Trans>
      <Button variant="contained" color="primary" onClick={() => seeHint(TUTORIALBEFORESTART)}>
        OK
      </Button>
    </>
  );
};

export default BeforeStart;
