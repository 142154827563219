/**
 * @generated SignedSource<<c9f3bd87825f6d6c6694b2d4b630be7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChatBubbleMutation$variables = {
  fromLang: string;
  messageId?: string | null;
  text: string;
  toLang: string;
};
export type ChatBubbleMutation$data = {
  readonly translateChatMessage: string;
};
export type ChatBubbleMutation = {
  response: ChatBubbleMutation$data;
  variables: ChatBubbleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fromLang"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "messageId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "text"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "toLang"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "fromLang",
        "variableName": "fromLang"
      },
      {
        "kind": "Variable",
        "name": "messageId",
        "variableName": "messageId"
      },
      {
        "kind": "Variable",
        "name": "text",
        "variableName": "text"
      },
      {
        "kind": "Variable",
        "name": "toLang",
        "variableName": "toLang"
      }
    ],
    "kind": "ScalarField",
    "name": "translateChatMessage",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChatBubbleMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChatBubbleMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "d761949a8f4303282279d5352a2f3cac",
    "id": null,
    "metadata": {},
    "name": "ChatBubbleMutation",
    "operationKind": "mutation",
    "text": "mutation ChatBubbleMutation(\n  $messageId: String\n  $text: String!\n  $fromLang: String!\n  $toLang: String!\n) {\n  translateChatMessage(messageId: $messageId, toLang: $toLang, fromLang: $fromLang, text: $text)\n}\n"
  }
};
})();

(node as any).hash = "0e8eace9f52a3cc915286b940aa1079a";

export default node;
