import React from "react";
import { useNavigate, useMatch } from "react-router-dom";
import styled, { css } from "styled-components/macro";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import { devicePaddings, isAndroid } from "../../tools/device";
import { LEARN } from "../../root/main/MainRouter";
import { CONTENT_ID } from "./constants";

type P = { transparent?: boolean; withShadow?: boolean };
const SAppBar = styled.div<P>`
  background: ${({ transparent }) => (transparent ? "transparent" : "white")};
  ${({ theme, transparent }) =>
    !transparent &&
    css`
      border-bottom: 1px solid ${theme.duo.color.grey};
    `}
  transition: opacity 0.3s;
  width: 100%;
  position: ${({ transparent }) => (transparent ? "absolute" : "static")};
  ${({ transparent, withShadow }) =>
    transparent &&
    css`
      .MuiIconButton-root,
      .like-svg {
        ${withShadow &&
        css`
          filter: drop-shadow(1px 1px 2px #000c);
          color: white;
        `}
        z-index: 20;
        transition: background-color 0.3s;
        margin: 0 2px;
      }
      .like-svg {
        filter: none;
        &:empty {
          display: none;
        }
      }
    `}
`;
const { top } = devicePaddings();
const SToolbar = styled.div<{ narrow?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  height: ${top + 55}px;
  padding: ${top + 4}px 10px 0;
  justify-content: space-between;
  width: 100%;
  max-width: ${({ narrow, theme }) => (narrow ? theme.duo.maxWidth.narrow : theme.duo.maxWidth.normal)};
  margin: 0 auto;
  z-index: 1; // fix ios layers
`;
const Side = styled.div`
  min-width: 45px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;
const Content = styled.div<{ noScroll: boolean }>`
  /* position: relative; - breaks Word positioning */
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* overflow-y: overlay; - breaks in firefox */
  overflow-y: ${({ noScroll }) => (noScroll ? "hidden" : "auto")};

  ${isAndroid() &&
  css`
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #0003;
    }
  `}
`;
export const Layout = styled.div<{ narrow?: boolean }>`
  height: 100%;
  min-height: fit-content;
  max-height: ${({ theme }) => theme.duo.maxHeight.normal};
  width: 100%;
  max-width: ${({ narrow, theme }) => (narrow ? theme.duo.maxWidth.narrow : theme.duo.maxWidth.normal)};
  margin: 0 auto;
`;
const IconButtonFix = ({ backgrounded, ...p }) => <IconButton {...p} />;
const SIconButton = styled(IconButtonFix)<{ backgrounded?: boolean }>`
  padding: 7px;
`;
const Ellipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
`;

type BBProps = {
  className?: string;
  onBack?: VoidFunction;
  backgrounded?: boolean;
};
export const BackButton: React.FC<BBProps> = ({ onBack, backgrounded, ...rest }) => {
  const navigate = useNavigate();

  return (
    <SIconButton {...rest} onClick={onBack ? onBack : () => navigate(-1)} color="inherit" backgrounded={backgrounded}>
      <KeyboardArrowLeftRoundedIcon style={{ width: 28, height: 28 }} />
    </SIconButton>
  );
};

type HProps = {
  children: React.ReactNode;
  right?: React.ReactNode;
  left?: React.ReactNode;
  noBack?: boolean;
  onBack?: VoidFunction;
  title?: React.ReactNode | string | null;
  transparent?: boolean;
  className?: string;
  style?: React.CSSProperties;
  narrow?: boolean;
  withShadow?: boolean;
};

export const HeaderLayout: React.FC<HProps> = React.memo((p) => {
  const inLearn = useMatch(LEARN.url());

  return (
    <>
      <SAppBar color="default" transparent={p.transparent} withShadow={p.withShadow}>
        <SToolbar narrow={p.narrow}>
          <Side>{p.left !== undefined ? p.left : !p.noBack && <BackButton onBack={p.onBack} />}</Side>
          {p.title !== undefined ? typeof p.title === "string" ? <Ellipsis>{p.title}</Ellipsis> : p.title : null}
          <Side style={{ justifyContent: "flex-end" }}>{p.right}</Side>
        </SToolbar>
      </SAppBar>
      <Content className={p.className} style={p.style} id={CONTENT_ID} noScroll={!!inLearn}>
        <Layout narrow={p.narrow}>{p.children}</Layout>
      </Content>
    </>
  );
});
