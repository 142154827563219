import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";
import { STREAK_FREEZE_XPS, STREAK_FREEZE_LIMIT, STREAK_FREEZE_LIMIT_SUBSCRIBER } from "sharedJs__generated/constants";
import { useViewerQuery } from "queries/viewerQuery";
import DialogWindow from "../../other/DialogWindow";
import Flames from "../Flames";
import { strengthFn } from "../FireStats";
import { Grid } from "@mui/material";
import IceCube from "../IceCube";

const Title = styled.h1<{ first?: boolean }>`
  font-size: 18px;
  font-weight: bold;
`;

const SFlames = styled(Flames)`
  display: inline-block;
  transform: scale(1.2);
  margin: 0 0 0 10px;
  top: 5px;
`;

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const StreakFreezeDialog: React.FC<Props> = ({ open, setOpen }) => {
  const { viewer } = useViewerQuery();

  return (
    <DialogWindow
      open={open}
      onClose={() => setOpen(false)}
      fullWidth={true}
      title={
        <Title first>
          <Trans>Series freeze</Trans>
        </Title>
      }
    >
      <>
        <IceCube coldness={3} number={viewer?.streakFreeze ?? 0} scale={2} />
        <Trans parent="p">Don't worry about missing a day and losing your daily streak.</Trans>
        <Trans parent="p">
          Freeze will keep your streak alive. Freeze will be applied automatically if you skip a day.
        </Trans>
        <Trans parent="p">Freeze will recharge when you gain:</Trans>
        <SFlames strength={strengthFn(STREAK_FREEZE_XPS)} xps={STREAK_FREEZE_XPS} noZZ />
        <Trans parent="h3">Maximum freezes</Trans>
        <Grid container alignContent={"center"} justifyContent={"center"}>
          <Grid item xs={6}>
            <Trans>Free</Trans>
          </Grid>
          <Grid item xs={6}>
            <Trans>Subscribers</Trans>
          </Grid>
          <Grid item xs={6}>
            <IceCube coldness={3} number={STREAK_FREEZE_LIMIT} />
          </Grid>
          <Grid item xs={6}>
            <IceCube coldness={3} number={STREAK_FREEZE_LIMIT_SUBSCRIBER} scale={2} />
          </Grid>
        </Grid>
      </>
    </DialogWindow>
  );
};

export default StreakFreezeDialog;
