import React from "react";
import { TLoopFilter } from "root/main/learn/CardsFilter";
import { Button, Stack } from "@mui/material";
import { Trans } from "react-i18next";
import { useSetState } from "components/ReduxProvider";
import styled from "styled-components/macro";

const SButton = styled(Button)`
  padding: 3px 9px;
`;

type Props = {
  loopFilter: TLoopFilter;
};
const RenewLoopFilter: React.FC<Props> = ({ loopFilter }) => {
  const setLoopFilter = useSetState("loopFilter");
  const setSavedLoopFilter = useSetState("savedLoopFilter");

  return (
    <Stack direction="column" alignItems="center">
      <Trans parent="p">Do you want to continue from where you left off?</Trans>
      <Stack direction="row" justifyContent="center" spacing={2}>
        <SButton
          variant="outlined"
          size="small"
          onClick={() => {
            setLoopFilter(loopFilter);
            setSavedLoopFilter(loopFilter);
          }}
        >
          <Trans>Yes</Trans>
        </SButton>
        <SButton variant="outlined" size="small">
          <Trans>No</Trans>
        </SButton>
      </Stack>
    </Stack>
  );
};

export default RenewLoopFilter;
