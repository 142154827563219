import React from "react";
import { useDispatch } from "react-redux";
import { useMatch } from "react-router";
import { TAPTUTORIALDONE } from "root/tutorial/steps/TapCard";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { useViewerDecks, useViewerQuery, useViewerXps } from "../../queries/viewerQuery";
import { MANY_UNKNOWN } from "../../root/main/main/Main";
import { setXp_burn, setXpColdness, useGetState, useSetState } from "../ReduxProvider";
import { useSeeHint } from "components/hint/Hint";
import { logEvent, logFBTutorialCompletedEvent, PROXY } from "tools/analyticEvents";
import { useEffectOnce, usePrevious } from "react-use";
import { TUTORIALDONE_SHARED_HINT } from "sharedJs__generated/constants";
import { SOCIALSHINT } from "root/main/main/hints/SocialsHint";
import { LIBRARY } from "../../root/RootRouter.config";

export type XpWarnings = "tooManyLeft" | "tooManyRight" | "tooManyToLearn";
export const PICKED_FEW_CARDS = "picked8c";
export const PICKED_FEW_CARDS_COUNT = 8;

export const useBurn = () => {
  const dispatch = useDispatch();
  const { hintWasSeen, viewer } = useViewerQuery();
  const seeHint = useSeeHint();
  const { xps } = useViewerXps();
  const prevXp = usePrevious(xps?.today);

  return React.useCallback(
    (warn?: XpWarnings) => {
      if (!(hintWasSeen(TAPTUTORIALDONE) || hintWasSeen(TUTORIALDONE))) return undefined;
      dispatch(setXp_burn({ warn }));

      // proxy events

      const incrementedXp = prevXp !== undefined && prevXp !== xps?.today;
      const createdBeforeHours = (viewer?.createdBeforeSeconds || 0) / 60 / 60;

      const usingAgain = createdBeforeHours >= 1 && createdBeforeHours < 94;
      if (usingAgain && incrementedXp && !hintWasSeen(PROXY.retained_1_hour)) {
        seeHint(PROXY.retained_1_hour);
        logEvent(PROXY.retained_1_hour);
        logFBTutorialCompletedEvent();
      }

      if (hintWasSeen(TUTORIALDONE_SHARED_HINT) && incrementedXp && !hintWasSeen(PROXY.active_after_onboarding)) {
        seeHint(PROXY.active_after_onboarding);
        logEvent(PROXY.active_after_onboarding);
      }

      if (hintWasSeen(SOCIALSHINT) && incrementedXp && !hintWasSeen(PROXY.social_popup_displayed)) {
        seeHint(PROXY.social_popup_displayed);
        logEvent(PROXY.social_popup_displayed);
      }
    },
    [dispatch, hintWasSeen, xps?.today, seeHint, prevXp, viewer?.createdBeforeSeconds]
  );
};

// keeps fire burning for X seconds
export const useKeepBurning = () => {
  const intervalLength = 3000;
  const secondsRef = React.useRef<number>(0);
  const burn = useBurn();

  useEffectOnce(() => {
    const intervalId = setInterval(() => {
      if (secondsRef.current > 0) {
        burn();
        secondsRef.current -= intervalLength / 1000;
      }
    }, intervalLength);

    return () => {
      clearInterval(intervalId);
    };
  });

  return React.useCallback((seconds: number): void => {
    secondsRef.current = seconds;
  }, []);
};

export const useBurnWithSource = (topic?: string) => {
  const { hintWasSeen } = useViewerQuery();
  const burn = useBurn();
  const inLibrary = !!useMatch(LIBRARY.root() + "/*");
  const dispatch = useDispatch();
  const { deck } = useViewerDecks();

  /**
   * correction solves this problem: when user creates a card, this is called still with the old deck.stats.total number. So we need to tell, whether we should count this card in already or not
   */
  return (correction: number = 0) => {
    if (deck && topic === deck.front) {
      if (
        ((!hintWasSeen(PICKED_FEW_CARDS) && deck.stats.total + correction >= PICKED_FEW_CARDS_COUNT) ||
          deck.stats.unknown > MANY_UNKNOWN) &&
        inLibrary
      ) {
        burn("tooManyToLearn");

        if (deck && deck.stats.unknown > MANY_UNKNOWN) {
          dispatch(setXpColdness(deck.stats.unknown - MANY_UNKNOWN));
        }
      } else {
        burn();
      }
    }

    // if user goes to a language in library without creating his own deck, let's hide fire warnings, as they are related to a deck still active in Learn
    if (deck && topic && topic !== deck.front) {
      burn();
    }
  };
};

// Is used in Main and Learn because in the moment when user just added 8th card it cannot be set yet because of diplaying warning
export const useSeePickedFewCards = () => {
  const { deck } = useViewerDecks();
  const pickedFewCards = useGetState("pickedFewCards");
  const setPickedFewCards = useSetState("pickedFewCards");

  return React.useEffect(() => {
    if (!pickedFewCards && (deck?.stats?.total || 0) >= PICKED_FEW_CARDS_COUNT) {
      setPickedFewCards(true);
    }
  }, [deck?.stats?.total, setPickedFewCards, pickedFewCards]);
};
