import React from "react";
import styled from "styled-components/macro";
import { useNetworkState } from "react-use";
import Loader from "components/other/Loader";
import { windowReloadLimited } from "tools/windowReload";
import { useGetState } from "components/ReduxProvider";
import { snackbar } from "tools/events";
import NewVersionAvailable from "components/other/NewVersionAvailable";
import { useTranslation } from "react-i18next";
import ErrorMammoth from "components/mammoth/ErrorMammoth";
import { useIsOffline } from "tools/offline";

const Wrap = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 20px 20px white;
  text-align: center;
  z-index: 1300; // behind connection warning (MuiSnackbar)
`;

type Props = {
  tokenIsSet: boolean;
  viewerIsLoaded: boolean;
};

const ConnectionError: React.FC<Props> = ({ tokenIsSet, viewerIsLoaded }) => {
  const network = useNetworkState();
  const [loader, setLoader] = React.useState(false);
  const connectionProblem = useGetState("connectionProblem");
  const needsUpdate = useGetState("needsUpdate");
  const { t } = useTranslation();
  const isOffline = useIsOffline();

  React.useEffect(() => {
    const backOnline = network.online && network.previous !== undefined && !network.previous;
    if (backOnline && tokenIsSet && !viewerIsLoaded) {
      windowReloadLimited(() => setLoader(true), { reason: "back online without viewer data", network });
    }
  }, [network, tokenIsSet, viewerIsLoaded]);

  React.useEffect(() => {
    if (connectionProblem && network.online) {
      if (needsUpdate) {
        snackbar(<NewVersionAvailable />, { severity: "info", autoHide: false, notDissmissable: true });
      } else if (network.previous !== false) {
        snackbar(t("Connection problem"), { severity: "error" });
      }
    }
  });

  // const showConnectionScreen = !tokenIsSet; // todo: once offline version is ready, use this line
  const showConnectionScreen = true;

  if ((showConnectionScreen && isOffline) || loader) {
    return <Wrap>{loader ? <Loader /> : <ErrorMammoth />}</Wrap>;
  }

  return null;
};

export default ConnectionError;
