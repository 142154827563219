/**
 * @generated SignedSource<<199a008f04b45f28645bf96eb843cdb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editDeckMutation$variables = {
  back: string;
  id: string;
  name?: string | null;
};
export type editDeckMutation$data = {
  readonly editDeck: {
    readonly " $fragmentSpreads": FragmentRefs<"viewerQuery_decks">;
  } | null;
};
export type editDeckMutation = {
  response: editDeckMutation$data;
  variables: editDeckMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "back"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v3 = [
  {
    "kind": "Variable",
    "name": "back",
    "variableName": "back"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "editDeckMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "editDeck",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "viewerQuery_decks"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "editDeckMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "editDeck",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Deck",
            "kind": "LinkedField",
            "name": "decks",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "front",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "back",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Stats",
                "kind": "LinkedField",
                "name": "stats",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "known",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unknown",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completed",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TtsVoice",
                "kind": "LinkedField",
                "name": "voices",
                "plural": true,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1a0e5d5ef2ee303cdb67fda6acf14aea",
    "id": null,
    "metadata": {},
    "name": "editDeckMutation",
    "operationKind": "mutation",
    "text": "mutation editDeckMutation(\n  $id: ID!\n  $name: String\n  $back: String!\n) {\n  editDeck(id: $id, name: $name, back: $back) {\n    ...viewerQuery_decks\n    id\n  }\n}\n\nfragment viewerQuery_decks on User {\n  decks {\n    id\n    name\n    front\n    back\n    stats {\n      total\n      known\n      unknown\n      completed\n    }\n    voices {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6ca440d803f4dffc40645cc5cf9bb51c";

export default node;
