import React from "react";
import styled from "styled-components/macro";
import { Button } from "@mui/material";
import { Trans } from "react-i18next";
import CopyField from "components/form/CopyField";
import { isBrowser, isDesktop } from "tools/device";
import ShareButtons from "components/other/ShareButtons";
import { useEffectOnce } from "react-use";

const Wrap = styled.div`
  padding: 15px;
  width: 100%;
  max-width: 360px;
  text-align: center;
  margin: 0 auto;
`;
const SCopyField = styled(CopyField)`
  margin-bottom: 15px;
`;

type Props = {
  url: string;
  text?: string;
  hashtags?: string;
  className?: string;
  autoShare?: boolean;
};
const ShareUrl: React.FC<Props> = ({ url, text, hashtags, className, autoShare }) => {
  hashtags = hashtags || "duocards,flashcards,languages,LanguageLearning";

  const handleShareClick = () => {
    if (isBrowser()) {
      navigator.share({ url, text });
    } else {
      // @ts-ignore
      navigator.share(url, text, "text/plain");
    }
  };

  useEffectOnce(() => {
    if (autoShare) {
      try {
        handleShareClick();
      } catch (e) {}
    }
  });

  return (
    <Wrap className={className}>
      <SCopyField value={url} />

      {!!navigator.share && !isDesktop() ? (
        <Button color="primary" variant="contained" onClick={handleShareClick}>
          <Trans>Share</Trans>
        </Button>
      ) : (
        <ShareButtons url={url} hashtags={hashtags} text={text} />
      )}
    </Wrap>
  );
};

export default ShareUrl;
