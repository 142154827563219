import { Button } from "@mui/material";
import { useSeeHint } from "components/hint/Hint";
import Loader from "components/other/Loader";
import { useLibraryQuery } from "queries/sources/libraryQuery";
import React from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import { ARTICLESID, VIDEOSID } from "root/library/library/LibraryRows";
import { FIVEHINT } from "root/main/main/Main";
import styled from "styled-components/macro";
import { disableLibraryScrolling } from "../misc/helpers";
import { TutorialFC } from "../TutorialContent";
import TutorialPointer from "../TutorialPointer";
import { MAIN } from "../../RootRouter.config";

const Img = styled.img`
  width: 120px;
  height: 120px;
  margin: 20px auto 0;
`;
const SLoader = styled(Loader)`
  height: 300px;
`;
const STutorialPointer = styled(TutorialPointer)`
  top: 50%;
  left: 80px;
  @media (min-width: ${({ theme }) => theme.duo.maxWidth.normal}) {
    left: 40%;
  }
`;

const FirstVideoOrArticle: TutorialFC = ({ position, setPosition, setVisibleId }) => {
  const navigate = useNavigate();
  const { videos, articles, isLoading } = useLibraryQuery();
  const seeHint = useSeeHint();

  const hasVideos = !!videos?.data?.sources?.edges?.length;
  const hasArticles = !!articles?.data?.sources?.edges?.length;
  const bottom = position === "bottom";

  if (isLoading) return <SLoader />;

  return (
    <>
      {bottom ? (
        <>
          {hasVideos ? (
            <Trans parent="p">Pick a video of your choice.</Trans>
          ) : (
            <Trans parent="p">Pick an article of your choice.</Trans>
          )}
          <STutorialPointer pulsing />
        </>
      ) : hasVideos || hasArticles ? (
        <>
          <Img src="https://cdn.duocards.com/svg/video.svg" />
          {hasVideos ? (
            <Trans parent="p">Now let’s see how you can add new words from videos and movies!</Trans>
          ) : (
            <Trans parent="p">Now let's take a look at learning from articles.</Trans>
          )}
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setPosition("bottom");
              setVisibleId(hasVideos ? VIDEOSID : ARTICLESID);
              disableLibraryScrolling();
            }}
          >
            Ok
          </Button>
        </>
      ) : (
        <>
          <Trans parent="p">Great, you just created your deck of cards. Let’s start learning!</Trans>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              seeHint(FIVEHINT);
              navigate(MAIN.url(), { replace: true });
            }}
          >
            <Trans>Start learning</Trans>
          </Button>
        </>
      )}
    </>
  );
};

export default FirstVideoOrArticle;
