import React from "react";
import styled, { css, keyframes } from "styled-components/macro";
import Flames from "./Flames";
import FireWarning from "./FireWarning";
import { useSelector } from "react-redux";
import { selectXp, useGetState } from "../ReduxProvider";
import { useViewerQuery, useViewerXps } from "../../queries/viewerQuery";
import FireDialog from "./FireDialog";
import { LEARN } from "root/main/MainRouter";
import { matchPath, useLocation, useMatch, useNavigate } from "react-router";
import { usePrevious } from "react-use";
import { Trans } from "react-i18next";
import { TAPTUTORIALDONE } from "root/tutorial/steps/TapCard";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import Present from "icons/Present";
import { Badge } from "@mui/material";
import { ASSISTANT_HASH } from "../assistant/AssistantPopup";
import { MAIN } from "../../root/RootRouter.config";

const CompWrap = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Wrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -3px 2px 0 0;
  width: 32px;
  height: 32px;
  transition: transform 1s;
  border-radius: 30px;
  z-index: 610;
`;
const Progress = styled.div<{
  xp: { overflowed: boolean; stack: number; strength: number; coldness: number };
}>`
  position: absolute;
  opacity: ${({ xp }) => (xp.overflowed ? "0" : "1")};
  bottom: 0;
  width: 100%;
  background: #f2f2f2;
  border-radius: 20px;
  transition: opacity 1.7s ease-in;

  .in {
    height: ${({ xp }) => (xp.overflowed ? "30px" : "2px")};
    border-radius: 20px;
    background: ${({ xp }) => (xp.strength === 0 ? "#bbb" : xp.coldness === 5 ? "#7bf" : "#ef5a00")};
    transition: height ${({ xp }) => (xp.stack === 0 ? "0s" : "2s")};
  }
`;

const SHAKEDURATION = 600;
const shakeLearnButton = keyframes`
  40% { left: 0px; }
  50%, 90% { left: 2px; }
  60%, 80% { left: -5px; }
  70% { left: 5px; }
`;
const LearnButton = styled.div<{ $animate: boolean; $show: boolean }>`
  && {
    padding: 2px 3px 0;
    margin: 0;
    min-width: 0;
    display: flex;
    border-width: 1px;
    border-style: solid;
    border-radius: 100px;
    cursor: pointer;
    color: ${(p) => p.theme.duo.color.primary};
    align-items: center;
    position: relative;
    border-color: ${(p) => (p.$show ? p.theme.duo.color.primary : "transparent")};
    background: ${(p) => (p.$show ? "#fff" : "transparent")};
    ${({ $animate }) =>
      $animate &&
      css`
        animation: ${SHAKEDURATION}ms ${shakeLearnButton};
      `};
  }
  .learn {
    overflow: hidden;
    display: inline-block;
    max-width: ${(p) => (p.$show ? "auto" : "0")};
    position: relative;
    font-size: 13px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    margin: ${(p) => (p.$animate || p.$show ? "0 3px 0 8px" : "0px")};
    max-width: ${({ $show }) => ($show ? 200 : 0)}px;
    transition: all 600ms;
  }
`;
const SBadge = styled(Badge)`
  z-index: 1;
  margin: 0 4px 0 0px;
`;

const Fire: React.FC = () => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const xp = useSelector(selectXp);
  const routeHistory = useGetState("routeHistory");
  const { viewer, hintWasSeen } = useViewerQuery();
  const { xps } = useViewerXps();
  const prevWarn = usePrevious(xp.warn);
  const navigate = useNavigate();
  const inLearn = !!useMatch(LEARN.url());
  const location = useLocation();
  const isAssistant = location.hash === ASSISTANT_HASH;
  const [doAnimate, setDoAnimate] = React.useState(false);
  const present = useGetState("present");

  const handleCloseDialog = () => {
    setOpenDialog(false);
    const fireEl = document.getElementById("fireWrap");
    if (fireEl && fireEl.style.zIndex) {
      fireEl.style.removeProperty("z-index");
      fireEl.style.removeProperty("transform");
      fireEl.style.removeProperty("background");
    }
  };

  if (!viewer || !(hintWasSeen(TAPTUTORIALDONE) || hintWasSeen(TUTORIALDONE))) return null;

  const animate = doAnimate || (xp.warn === "tooManyToLearn" && prevWarn !== "tooManyToLearn" && !inLearn);

  const handleLearnButtonClick = () => {
    const previousRoutePath = routeHistory && routeHistory.length > 1 && routeHistory[1].pathname;
    const prevInLearn = previousRoutePath && !!matchPath(previousRoutePath, LEARN.url());
    if (prevInLearn) {
      /* solves the case when user clears whole deck in Learn,
      then goes right to Source through last visited,
      then adds too many cards and goes back to Learn through this button
      => after all that when he would start using back button, he would loop just between Learn and Source */
      navigate(-1);
    } else {
      navigate(LEARN.url());
    }
  };

  const handleFireClick = (e?: any) => {
    if (xp.warn === "tooManyToLearn" && !inLearn) {
      setDoAnimate(true);
      setTimeout(() => setDoAnimate(false), SHAKEDURATION);
      return; // we don't want to show fire hint when the fire is inside the learn button
    }
    setOpenDialog(true);
    e.stopPropagation();
  };

  return (
    <CompWrap>
      {present && (
        <SBadge variant="dot" color="warning">
          <Present className="nofilter" onClick={() => navigate(MAIN.url())} />
        </SBadge>
      )}
      <LearnButton
        $animate={xp.warn === "tooManyToLearn" && animate && !inLearn}
        $show={xp.warn === "tooManyToLearn" && !inLearn && !isAssistant}
        onClick={handleLearnButtonClick}
      >
        <Trans parent="span" className="learn">
          Learn
        </Trans>
        <Wrap onClick={handleFireClick} id="fireWrap">
          <Flames strength={xp.strength} xps={xps?.today || 0} coldness={xp.coldness} />
          {(xp.warn !== "tooManyToLearn" || inLearn) && (
            <Progress xp={xp}>
              <div className="in" style={{ width: xp.stack + "%" }} />
            </Progress>
          )}
        </Wrap>
      </LearnButton>
      <FireWarning onClick={handleFireClick} />
      <FireDialog open={openDialog} onClose={handleCloseDialog} />
    </CompWrap>
  );
};

export default Fire;
