import React from "react";
import { useNavigate } from "react-router";
import { useGetState } from "components/ReduxProvider";
import { useEffectOnce } from "react-use";
import { MAIN } from "../../root/RootRouter.config";

// mobile devices have by default some url by default:
//   - android: .../android_asset/www/index.html (pathname is sometimes only /index.html)
//   - ios: .../DuoCards.app/www/inde.html
// this will redirect address like this to "/" using react-router so it won't break app

const UrlFix: React.FC<{}> = () => {
  // should be rather useLibrary because it's rerendering on change but it's not necessary here
  const navigate = useNavigate();

  const routeHistory = useGetState("routeHistory");
  const routeHistoryLength = React.useRef<number | undefined>(undefined);
  routeHistoryLength.current = routeHistory?.length;

  React.useEffect(() => {
    if ((window as any).location.pathname.indexOf("/index.html") !== -1) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exitApp = React.useCallback(() => {
    const onMainScreen = (window as any).location.pathname === "/" || (window as any).location.pathname === MAIN.url();
    const emptyHashAndSearch = (window as any).location.hash === "" && (window as any).location.search === "";
    const noHistory = routeHistoryLength.current === 1; // there is always 1 url in history (on start)
    if (onMainScreen && emptyHashAndSearch && noHistory) {
      (window as any).navigator.app.exitApp();
    } else {
      (window as any).navigator.app.backHistory();
    }
  }, []);

  useEffectOnce(() => {
    document.addEventListener("backbutton", exitApp);
    return () => window.removeEventListener("backbutton", exitApp);
  });

  return null;
};

export default UrlFix;
