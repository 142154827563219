import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const URLINTENT = "urlIntent";

(window as any).handleOpenURL = (url) => {
  if ((window as any).urlListenerIsLoaded) {
    const path = url.split("com.duocards.app:/")[1] || url.split("app.duocards.com")[1];
    if (path) {
      var event = new CustomEvent(URLINTENT, { detail: path });
      document.dispatchEvent(event);
    }
  } else {
    setTimeout(() => {
      (window as any).handleOpenURL(url);
    }, 500);
  }
};

const Linking: React.FC<{}> = () => {
  const navigate = useNavigate();

  const handleUrl = (e) => {
    try {
      navigate(e.detail);
    } catch (e) {}
  };

  useEffect(() => {
    document.addEventListener(URLINTENT, handleUrl);
    (window as any).urlListenerIsLoaded = true;
    return () => document.removeEventListener(URLINTENT, handleUrl);
  });

  return null;
};

export default Linking;
