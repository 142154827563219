import Mammoth from "icons/Mammoth";
import React from "react";
import { useEvent, usePrevious, useToggle } from "react-use";
import styled, { css } from "styled-components/macro";
import TutorialBackdrop from "./TutorialBackdrop";
import { PETMAMMOTH } from "root/tutorial/steps/PetMammoth";
import { useSeeHint } from "components/hint/Hint";
import { useViewerQuery } from "queries/viewerQuery";
import { useSetFlag } from "queries/users/setFlag";
import { TUTORIAL_STEP_FLAG } from "queries/users/userFlagsModel";
import { useGetState } from "components/ReduxProvider";
import { EVENT } from "tools/events";
import { useWindowSize } from "hooks/useWindowSize";
import { bottomPadding } from "tools/device";

export type HintPosition = undefined | "bottom" | "aboveNavigation" | "full";

const TUTORIALID = "tutorialId";
const STEP_TRANSITION_LENGTH = 2000;

const SPaper = styled.div<{ position: HintPosition; $smaller: boolean }>`
  position: absolute;
  align-items: center;
  text-align: center;
  margin: auto auto;
  width: ${({ position }) => (position === "full" ? "95%" : "90%")};
  height: ${({ position }) => (position === "full" ? "99%" : "fit-content")};
  max-height: calc(100% - 20px);
  max-width: 550px;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 15px 15px;
  z-index: 1050;
  transition: bottom 400ms;
  overflow: auto;
  background: white;
  border-radius: 5px;
  border: ${({ theme }) => theme.duo.border.grey};

  button {
    margin: 8px 0;
  }

  ${({ position }) =>
    position === "full"
      ? css`
          bottom: 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `
      : position === "aboveNavigation"
        ? css`
            bottom: 80px;
          `
        : position === "bottom"
          ? css`
              bottom: ${10 + bottomPadding}px;
              padding: 7px 15px;
            `
          : null}

  ${({ $smaller }) =>
    $smaller &&
    css`
      p {
        margin: 2px 0;
        font-size: 90%;
        line-height: 1.3em;
      }
    `}
`;
const MammothPosition = styled.div<{ progress: string }>`
  position: absolute;
  margin: -35px 0 0 -17px;
  left: ${(p) => p.progress};
  transition: left ${STEP_TRANSITION_LENGTH}ms;
`;
const Slider = styled.div<{ smaller?: boolean }>`
  position: relative;
  display: block;
  padding: 40px 0 8px;
  margin: 10px 20px 0;
  ${({ smaller }) =>
    smaller &&
    css`
      margin: -10px 0px -5px;
      transform: scale(0.7);
    `}
`;
const SliderRail = styled.div`
  position: absolute;
  width: 100%;
  border-radius: 3px;
  height: 3px;
  background: ${({ theme }) => theme.duo.color.green};
  opacity: 0.7;
`;
const SliderTrack = styled.div`
  position: absolute;
  border-radius: 3px;
  height: 4px;
  background: ${({ theme }) => theme.duo.color.primary};
`;

export type TutorialFC = React.FC<{
  position: HintPosition;
  setPosition: (position: HintPosition) => void;
  visibleId?: string;
  setVisibleId: (visibleId: string | undefined) => void;
}>;
type Props = {
  position?: HintPosition;
  perc: string;
  step: number;
  Component: TutorialFC;
  visibleId?: string;
  showProgressbar: boolean;
};

const TutorialContent: React.FC<Props> = ({
  Component,
  position: positionProp,
  perc,
  step,
  visibleId: visibleIdProp,
  showProgressbar
}) => {
  const { getFlag } = useViewerQuery();
  const { height } = useWindowSize();
  const [coords, setCoords] = React.useState({ height: 100 });
  const [positionState, setPosition] = React.useState<HintPosition | undefined>();
  const [visibleIdState, setVisibleId] = React.useState<string | undefined>();
  const [canPet, setCanPet] = React.useState(false);
  const [mammothWalking, toggleMammothWalking] = useToggle(false);
  const stopPettingTimeout = React.useRef<any>(null);
  const prevStep = usePrevious(step);
  const seeHint = useSeeHint();
  const setFlag = useSetFlag();
  const petting = useGetState("pettingMemo");
  const prevPetting = usePrevious(petting);

  const visibleId = visibleIdState || visibleIdProp;
  const position = positionState || positionProp || (visibleId ? "bottom" : undefined);

  React.useEffect(() => {
    if (getFlag(TUTORIAL_STEP_FLAG)?.value !== "" + step) {
      setFlag({ name: TUTORIAL_STEP_FLAG, value: "" + step });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  React.useEffect(() => {
    setPosition(undefined);
    setVisibleId(undefined);
  }, [step]);

  React.useEffect(() => {
    const el = document.getElementById(TUTORIALID);
    el && setCoords(el.getBoundingClientRect());
  }, [height, position]);

  React.useEffect(() => {
    if (step !== prevStep) {
      toggleMammothWalking();
      setTimeout(toggleMammothWalking, STEP_TRANSITION_LENGTH);
    }
  }, [step, prevStep, toggleMammothWalking]);

  React.useEffect(() => {
    // if user started petting by himself, let's stop automatic end of the animation
    petting && !prevPetting && clearTimeout(stopPettingTimeout.current);
    // if user were manually petting, end this step when memo finish flipping
    !petting && prevPetting && seeHint(PETMAMMOTH);
  }, [petting, prevPetting, seeHint]);

  const handleForcePetting = React.useCallback(() => {
    setCanPet(true);
    stopPettingTimeout.current = setTimeout(() => seeHint(PETMAMMOTH), 7200); // 7200 = backflip + walking there + waiting + walking home
    setTimeout(() => setCanPet(false), 7200);
  }, [seeHint]);
  useEvent(EVENT.forcePetting, handleForcePetting);

  return (
    <>
      <TutorialBackdrop visibleId={visibleId} />
      <SPaper
        id="tutorialId"
        position={position}
        $smaller={!!visibleId}
        style={{ bottom: !position ? (height - coords.height) / 2 : undefined }}
      >
        <Component
          position={position}
          setPosition={(position) => setPosition(position)}
          visibleId={visibleId}
          setVisibleId={(visibleId) => {
            setVisibleId(visibleId);
          }}
        />
        {showProgressbar && (
          <div style={{ width: position === "full" ? "85%" : "auto" }}>
            <Slider smaller={!!visibleId}>
              <SliderRail />
              <SliderTrack style={{ width: perc }} />
              <MammothPosition progress={perc}>
                <Mammoth forceWalking={mammothWalking} canPet={canPet} />
              </MammothPosition>
            </Slider>
          </div>
        )}
      </SPaper>
    </>
  );
};

export default TutorialContent;
