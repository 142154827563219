/**
 * @generated SignedSource<<010073e6ce6225762e8fa9a18964b0c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type deleteSourceMutation$variables = {
  id: string;
};
export type deleteSourceMutation$data = {
  readonly deleteSource: boolean | null;
};
export type deleteSourceMutation = {
  response: deleteSourceMutation$data;
  variables: deleteSourceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteSource",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteSourceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deleteSourceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "254db4ef948800c07e63874c264eec6a",
    "id": null,
    "metadata": {},
    "name": "deleteSourceMutation",
    "operationKind": "mutation",
    "text": "mutation deleteSourceMutation(\n  $id: ID!\n) {\n  deleteSource(id: $id)\n}\n"
  }
};
})();

(node as any).hash = "f0c7a4e3a020e89e7d66dfc206301612";

export default node;
