import React from "react";
import styled from "styled-components/macro";
import { addSeconds } from "date-fns";
import { getSubscriptionFlags, SubscriptionFlags } from "queries/users/userSubscriptionModel";
import { Viewer } from "../../../queries/viewerQuery";

const Green = styled.span`
  color: ${(p) => p.theme.duo.color.green};
`;
const Red = styled.span`
  color: ${(p) => p.theme.duo.color.red};
`;

type Props = {
  subscription?: {
    timeToExpire: number;
    storeId: string;
    family: boolean;
  } | null;
};

export const SubscriptionDisplay: React.FC<Props> = ({ subscription }) => {
  const subscriptionFlags: SubscriptionFlags = getSubscriptionFlags(subscription as Viewer["subscription"]);

  if (!subscription) return <>No subscription</>;

  return (
    <>
      <Green>Active till {addSeconds(new Date(), subscription.timeToExpire).toDateString()}</Green>
      {subscriptionFlags.hasActiveFriendsReward && <Red> FriendsReward</Red>}
    </>
  );
};
