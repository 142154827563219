import lamejs from "lamejs";

import MPEGMode from "lamejs/src/js/MPEGMode";
import Lame from "lamejs/src/js/Lame";
import BitStream from "lamejs/src/js/BitStream";

// Fix for lamejs import issues
(window as any).MPEGMode = MPEGMode;
(window as any).Lame = Lame;
(window as any).BitStream = BitStream;

type AudioEncodeParams = { audioData: Float32Array[]; sampleRate: number; numChannels: number };
type AudioEncode = (params: AudioEncodeParams) => Blob;
type EncodeFormat = "mp3" | "wav";
type AudioEncodeParamsWithFormat = AudioEncodeParams & { format: EncodeFormat };

const convertToInt16Array = (chunk: Float32Array): Int16Array => {
  return Int16Array.from(chunk, (sample) => Math.max(-32768, Math.min(32767, sample * 32767)));
};

// MP3 is way more efficient than WAV for audio compression
const encodeToMp3Blob: AudioEncode = ({ audioData, sampleRate, numChannels }) => {
  const encoder = new lamejs.Mp3Encoder(numChannels, sampleRate, 128);
  const mp3Data: number[] = [];

  audioData.forEach((chunk) => {
    const int16Chunk = convertToInt16Array(chunk);
    const mp3Chunk = encoder.encodeBuffer(int16Chunk);
    mp3Data.push(...mp3Chunk);
  });

  const lastChunk = encoder.flush();
  mp3Data.push(...lastChunk);

  return new Blob([new Uint8Array(mp3Data)], { type: "audio/mp3" });
};

const writeString = (view: DataView, offset: number, string: string) => {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
};

const floatTo16BitPCM = (output: DataView, offset: number, input: Float32Array) => {
  for (let i = 0; i < input.length; i++, offset += 2) {
    const s = Math.max(-1, Math.min(1, input[i]));
    output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
};

// WAV is lossless, so it doesn't lose any quality
// No need to for third party libraries
// Way more data
const encodeToWavBlob: AudioEncode = ({ audioData, sampleRate, numChannels }) => {
  const dataSize = audioData.reduce((acc, curr) => acc + curr.length, 0) * 2;
  const buffer = new ArrayBuffer(44 + dataSize);
  const view = new DataView(buffer);

  writeString(view, 0, "RIFF");
  view.setUint32(4, 36 + dataSize, true);
  writeString(view, 8, "WAVE");
  writeString(view, 12, "fmt ");
  view.setUint32(16, 16, true);
  view.setUint16(20, 1, true);
  view.setUint16(22, numChannels, true);
  view.setUint32(24, sampleRate, true);
  view.setUint32(28, sampleRate * numChannels * 2, true);
  view.setUint16(32, numChannels * 2, true);
  view.setUint16(34, 16, true);
  writeString(view, 36, "data");
  view.setUint32(40, dataSize, true);

  let offset = 44;
  audioData.forEach((chunk) => {
    floatTo16BitPCM(view, offset, chunk);
    offset += chunk.length * 2;
  });

  return new Blob([view], { type: "audio/wav" });
};

export const encodeToAudioBlob = (params: AudioEncodeParamsWithFormat): Blob => {
  const { audioData, sampleRate, numChannels, format } = params;

  const encoders: { [key in EncodeFormat]: (params: AudioEncodeParams) => Blob } = {
    mp3: encodeToMp3Blob,
    wav: encodeToWavBlob
  };

  const encoder = encoders[format];
  if (!encoder) {
    throw new Error(`Unsupported format: ${format}`);
  }

  return encoder({ audioData, sampleRate, numChannels });
};

export function calculateRMS(data: Float32Array) {
  const meanSquare = data.reduce((sum, value) => sum + value * value, 0) / data.length;
  return Math.sqrt(meanSquare);
}
