import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { useViewerQuery } from "../../../queries/viewerQuery";
import { useNavigate } from "react-router";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { VERIFYTRANS } from "../../other/Verify";
import { redirectToStore } from "../../../tools/device";
import ScreenLockPortraitIcon from "@mui/icons-material/ScreenLockPortrait";
import CheckItem from "./quests/misc/CheckItem";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { PROFILE, VERIFY } from "../../RootRouter.config";

export const useShowFriendshipChecklist = () => {
  const { viewer, hintWasSeen } = useViewerQuery();
  return (
    viewer?.inviter && hintWasSeen(TUTORIALDONE) && (!viewer?.email || !viewer?.deviceId || !viewer?.emailVerified)
  );
};

const Tit = styled.p`
  font-weight: bold;
  font-size: 90%;
  line-height: 1.4em;
  text-align: center;
  color: black;
  span {
    color: ${({ theme }) => theme.duo.color.green};
  }
`;
export const FinishTitle: React.FC<{}> = () => {
  const { viewer } = useViewerQuery();
  const { t } = useTranslation();
  const tit = t("Finish your training so you can connect with {*} and get free Premium").split("{*}");
  return (
    <Tit>
      {tit[0]}
      <span>{viewer?.inviter?.name || t("friends")}</span>
      {tit[1]}
    </Tit>
  );
};

type Props = {
  className?: string;
};

const FriendshipChecklist: React.FC<Props> = ({ className }) => {
  const { viewer } = useViewerQuery();
  const navigate = useNavigate();

  return (
    <div className={className}>
      <FinishTitle />
      {!viewer?.email && (
        <CheckItem onClick={() => navigate(PROFILE.url())}>
          <Trans>Register</Trans> &nbsp; <VpnKeyIcon />
        </CheckItem>
      )}
      {!viewer?.emailVerified && (
        <CheckItem onClick={() => navigate(VERIFY.url())}>
          <Trans>{VERIFYTRANS}</Trans> &nbsp; <VpnKeyIcon />
        </CheckItem>
      )}
      {!viewer?.deviceId && (
        <CheckItem onClick={redirectToStore}>
          <Trans>Log in to the mobile app</Trans> &nbsp; <ScreenLockPortraitIcon />
        </CheckItem>
      )}
    </div>
  );
};

export default FriendshipChecklist;
