import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React from "react";
import { AdminUserType } from "./AdminUser";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { SubscribeManuallyMutation } from "./__generated__/SubscribeManuallyMutation.graphql";

const mutation = graphql`
  mutation SubscribeManuallyMutation($family: Boolean!, $userId: ID!) {
    subscribeManually(family: $family, userId: $userId) {
      subscriptions {
        transactionId
        timeToExpire
        platform
        storeId
        family
      }
    }
  }
`;

type Props = {
  user: AdminUserType;
};

const SubscribeManually: React.FC<Props> = ({ user }) => {
  const [variant, setVariant] = React.useState("");
  const [subscribeManually, { loading }] = useMutation<SubscribeManuallyMutation>(mutation);

  const handleSubscribe = () => {
    subscribeManually({ variables: { userId: user.id, family: variant === "family" } });
  };

  return (
    <>
      <Select value={variant} onChange={(event: SelectChangeEvent) => setVariant(event.target.value)}>
        <MenuItem value={"individual"}>Individual</MenuItem>
        <MenuItem value={"family"}>Family</MenuItem>
      </Select>
      <Button variant="outlined" size="small" onClick={handleSubscribe} disabled={!variant || loading}>
        {loading ? "Subscribing" : "Subscribe Manually"}
      </Button>
    </>
  );
};

export default SubscribeManually;
