import React from "react";
import { HeaderLayout } from "../../../components/header/Header";
import Row from "../../../components/other/Row";
import RowIn from "../../../components/other/RowIn";
import styled from "styled-components/macro";
import { useNavigate } from "react-router";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SOURCEFORM, VIDEOFORM } from "../library/libraryUrls";
import { useViewerQuery } from "../../../queries/viewerQuery";
import { Button } from "@mui/material";
import LibraryMenu from "../library/LibraryMenu";
import { useTranslation } from "react-i18next";
import HintDialog from "../../../components/hint/HintDialog";
import { VERIFYTRANS } from "../../other/Verify";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { PROFILE, VERIFY } from "../../RootRouter.config";

const SRowIn = styled(RowIn)`
  padding: 0px 15px;
`;
const CenterDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const In = styled.div`
  padding-bottom: 30px;
`;
const Emoji = styled.div.attrs({ className: "nofilter" })`
  font-size: 30px;
`;

const SourceNew: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { viewer } = useViewerQuery();
  const { t } = useTranslation();

  return (
    <HeaderLayout title={`${t("Create")}...`} right={<LibraryMenu />} narrow>
      <CenterDiv>
        {!viewer?.emailVerified ? (
          <>
            <p style={{ textAlign: "center" }}>{!viewer?.email && t("You have to register first")}</p>
            {!viewer?.email ? (
              <Button color="primary" onClick={() => navigate(PROFILE.url())}>
                {t("Go to profile")}
              </Button>
            ) : (
              <Button color="primary" onClick={() => navigate(VERIFY.url())}>
                {t(VERIFYTRANS)}
              </Button>
            )}
          </>
        ) : (
          <>
            <In>
              <Row
                onClick={() =>
                  // replace so the summary knows how many steps back after creating
                  navigate(SOURCEFORM.url({ kind: "set" }), { replace: true })
                }
              >
                <Emoji children="🗃️" />
                <SRowIn
                  title={t("Create a Set of cards")}
                  text={t("Sets are shared with particular group or publicly in library (author's choice).")}
                />
                <ChevronRightIcon />
              </Row>
              <Row onClick={() => navigate(VIDEOFORM.url(), { replace: true })}>
                <Emoji children={<YouTubeIcon style={{ color: "red", fontSize: 32 }} />} />
                <SRowIn title={t("Publish Video")} text={t("Youtube videos in the target language with captions")} />
                <ChevronRightIcon />
              </Row>
              <Row onClick={() => navigate(SOURCEFORM.url({ kind: "article" }), { replace: true })}>
                <Emoji children="📝" />
                <SRowIn
                  title={t("Create Article")}
                  text={t("Articles about interesting topics where users can find words to learn.")}
                />
                <ChevronRightIcon />
              </Row>
            </In>
            <HintDialog name="sourceCreatee" emoji="🎓">
              {t("Creating Sets / Articles is for sharing materials with your students or study-group.")}
            </HintDialog>
          </>
        )}
      </CenterDiv>
    </HeaderLayout>
  );
};

export default SourceNew;
