import { useWindowSize } from "hooks/useWindowSize";
import React from "react";

const MAXHEIGHT = 855;
export const MINWIDTH = 360;

const ViewportKiller: React.FC<{}> = () => {
  const { width, height } = useWindowSize();
  const [scaling, setScaling] = React.useState<"startScaling" | "scaled" | false>(false);

  React.useEffect(() => {
    if (scaling === "startScaling") {
      setScaling("scaled");
      return;
    }
    var vp = document.getElementsByName("viewport")[0];
    const ratio = MAXHEIGHT / height;
    const tabletWidth = Math.round(width * ratio);
    if (scaling === "scaled") {
      // unscale to be able to scale again
      vp?.setAttribute("content", "user-scalable=no,width=device-width");
      setScaling(false);
    }
    // set viewport of small phones (iphone5) to be MINWIDTH
    else if (width < MINWIDTH) {
      vp?.setAttribute("content", `user-scalable=no, width=${MINWIDTH}`);
      setScaling("startScaling");
    }
    // set viewport of tablets (not desktop browsers) to be like it's height is MAXHEIGHT
    else if (height > MAXHEIGHT) {
      vp?.setAttribute("content", `user-scalable=no, width=${tabletWidth + 1}`); // +1 fixes white pixel on iPhone14Pro
      setScaling("startScaling");
    }
    /**
     * update on width change but not height because keyboard is also changing height
     * */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width]);

  return null;
};

export default ViewportKiller;
