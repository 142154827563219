import React from "react";
import { Route, Routes, useMatch } from "react-router-dom";
import LibraryRouter from "./library/LibraryRouter";
import MainRouter from "./main/MainRouter";
import ProfileRouter from "./profile/ProfileRouter";
import TrySharing from "./other/TrySharing";
import { useGetState } from "../components/ReduxProvider";
import Verify from "./other/Verify";
import Author from "./author/Author";
import AdminRouter from "./admin/AdminRouter";
import GeneratedAppLangWarning from "root/other/GeneratedAppLangWarning";
import Share from "root/main/share/Share";
import { SOURCE } from "root/library/library/libraryUrls";
import {
  ADMIN,
  AUTHOR,
  GENERATED_APP_LANG,
  LIBRARY,
  MAIN,
  PROFILE,
  SHARE,
  TRYSHARING,
  VERIFY
} from "./RootRouter.config";
import { PASSWORD } from "./profile/ProfileRouter.config";

export const useLibraryUrl = () => {
  const libTopic = useGetState("libTopic");
  return LIBRARY.url(libTopic);
};

export const useMatchMain: (params?: { end: boolean }) => boolean = (p) => {
  const inMain = useMatch({ path: MAIN.url(), end: !!p?.end });
  const inMain2 = useMatch("/");
  return !!(inMain || inMain2);
};

export const useIsPublicUrl = () => {
  const pwdUrl = useMatch(PASSWORD.url());
  const trySharingUrl = useMatch(TRYSHARING.query());
  const libIndexUrl = useMatch(LIBRARY.root());
  const libUrl = useMatch(LIBRARY.query());
  const sourceUrl = useMatch(SOURCE.query());
  const authorUrl = useMatch(AUTHOR.query());
  const shareUrl = useMatch(SHARE.query());

  return !!(pwdUrl || trySharingUrl || libIndexUrl || libUrl || sourceUrl || authorUrl || shareUrl);
};

const RootRouter: React.FC<{}> = React.memo(() => {
  return (
    <Routes>
      <Route path={LIBRARY.root() + "/*"} element={<LibraryRouter />} />
      <Route path={PROFILE.url() + "/*"} element={<ProfileRouter />} />
      <Route path={TRYSHARING.query()} element={<TrySharing />} />
      <Route path={VERIFY.url()} element={<Verify />} />
      <Route path={GENERATED_APP_LANG.url()} element={<GeneratedAppLangWarning />} />
      <Route path={AUTHOR.query()} element={<Author />} />
      <Route path={ADMIN.query() + "/*"} element={<AdminRouter />} />
      <Route path={SHARE.query()} element={<Share />} />

      <Route path={MAIN.url() + "/*"} element={<MainRouter />} />
      <Route path="/*" element={<MainRouter />} />
    </Routes>
  );
});

export default RootRouter;
