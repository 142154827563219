import React from "react";
import styled from "styled-components/macro";
import Loader from "components/other/Loader";
import SourceCard from "./SourceCard";
import { LibrarySources, sourcesOnPage } from "queries/sources/libraryQuery";
import { CONTENT_ID } from "components/header/constants";

const Wrap = styled.div`
  height: 100%;
  overflow: auto;
`;
const In = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

type Props = {
  sources?: LibrarySources;
  isLatestVisited?: boolean;
};

const LibraryList: React.FC<Props> = ({ sources, isLatestVisited }) => {
  const wrapRef: React.RefObject<HTMLDivElement> = React.useRef(null);

  const content = React.useMemo(() => document.getElementById(CONTENT_ID), []);

  const handleScroll = React.useCallback(
    (e) => {
      if (
        sources?.hasNext &&
        !sources.isLoadingNext &&
        content!.scrollHeight - content!.offsetHeight - e.target.scrollTop < 700
      ) {
        sources?.loadNext?.(sourcesOnPage);
      }
    },
    [sources, content]
  );
  React.useEffect(() => {
    if (!content) return;
    content.addEventListener("scroll", handleScroll);
    return () => content.removeEventListener("scroll", handleScroll);
  }, [sources, content, handleScroll]);

  if (!sources?.data?.sources?.edges || sources.data.sources.edges.length === 0) return null;

  return (
    <Wrap ref={wrapRef}>
      <In>
        {sources.data.sources.edges.map(
          (source) =>
            source?.node && (
              <SourceCard responsive source={source.node} key={source.node.id} isLatestVisited={isLatestVisited} />
            )
        )}

        {(sources.isLoadingNext || sources.isLoading) && (
          <div style={{ height: 200, flexBasis: "100%" }}>
            <Loader />
          </div>
        )}
      </In>
    </Wrap>
  );
};

export default LibraryList;
