import React from "react";
import styled from "styled-components/macro";
import { CreateSCard } from "../../../../queries/sources/createSCard";
import { useMutation } from "relay-hooks";
import { createSCardMutation } from "../../../../queries/sources/__generated__/createSCardMutation.graphql";
import { editSCardMutation } from "../../../../queries/sources/__generated__/editSCardMutation.graphql";
import { EditSCard } from "../../../../queries/sources/editSCard";
import { useTranslation } from "react-i18next";
import { sourceEditQuery$data } from "../__generated__/sourceEditQuery.graphql";
import CardForm, { ADDCARDID } from "../../../main/card/CardForm";
import { CardValues } from "../../../../components/ReduxProvider";

const Wrap = styled.div`
  padding: 20px;
`;

type Props = {
  onSubmit?: VoidFunction;
  sCard?: NonNullable<NonNullable<sourceEditQuery$data["node"]>["sCards"]>[0];
  source: sourceEditQuery$data["node"];
  backLang?: string | null;
};

const SCardForm: React.FC<Props> = (p) => {
  const { t } = useTranslation();

  const [createSCard] = useMutation<createSCardMutation>(CreateSCard, {
    onCompleted: () => {
      setTimeout(() => {
        const el = document.getElementById(ADDCARDID);
        if (el !== null && el.scrollIntoView) el.scrollIntoView({ behavior: "smooth" });
      });
    }
  });

  const [editSCard] = useMutation<editSCardMutation>(EditSCard);

  const validate = (values: CardValues) => {
    if (p.source?.sCards?.some((c) => c.front === values.front && c.id !== p.sCard?.id)) {
      alert(t("That's already in the set."));
      return false;
    }
  };

  const handleSubmit = (values: CardValues) => {
    if (validate(values) === false) return;
    if (p.sCard?.id) {
      editSCard({ variables: { ...values, id: p.sCard.id, backLang: p.backLang } });
    } else {
      const variables = { ...values, sourceId: p.source?.id as string, backLang: p.backLang };
      createSCard({ variables });
    }
    p.onSubmit && p.onSubmit();
  };

  if (!p.source || !p.source.lang) return null;

  return (
    <Wrap>
      <CardForm
        card={
          p.sCard && {
            ...p.sCard,
            theory: p.sCard.theory?.theory,
            back: String(p.sCard.back?.value)
          }
        }
        withTheory
        withoutSorter
        onSave={handleSubmit}
        fromLang={p.source.lang}
        toLang={p.backLang}
        disableGrammar
      />
    </Wrap>
  );
};

export default SCardForm;
