import React from "react";
import styled from "styled-components/macro";
import { Trans } from "react-i18next";
import Button from "@mui/material/Button";
import DuoCardsTitle from "styled/DuoCardsTitle";

export const loginToExtensionHash = "#logintoextension";

const Wrap = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    margin-top: 25px;
    max-width: 120px;
  }
`;

type Props = {};

const ChromeExtensionLogin: React.FC<Props> = () => {
  return (
    <Wrap>
      <DuoCardsTitle />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        fullWidth
        onClick={() => {
          window.open(`${window.location.protocol}//${window.location.host}${loginToExtensionHash}`, "duocards");
        }}
      >
        <Trans>Login</Trans>
      </Button>
    </Wrap>
  );
};

export default ChromeExtensionLogin;
