import React from "react";
import { Button } from "@mui/material";
import DialogWindow from "components/other/DialogWindow";
import { Trans } from "react-i18next";
import { useGetState } from "components/ReduxProvider";
import { useViewerQuery } from "queries/viewerQuery";
import { useNavigate } from "react-router";
import { useNotificationsSettings } from "hooks/useNotificationsSettings";
import { NOTIFICATIONSDONE } from "root/profile/Notifications";
import { useNotificationDialogController } from "./notificationDialogController";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { NOTIFICATIONS } from "../../root/profile/ProfileRouter.config";

const NotificationDialog = () => {
  const { viewer, hintWasSeen } = useViewerQuery();
  const [closed, setClosed] = React.useState(false);
  const fcmId = useGetState("fcmId");
  const navigate = useNavigate();
  const openNotificationsSettings = useNotificationsSettings();
  const { incNotificationsDialogSeen, pickedMessage, allowOpen } = useNotificationDialogController();
  const afterTutorial = hintWasSeen(NOTIFICATIONSDONE) || hintWasSeen(TUTORIALDONE);

  if (fcmId !== null) return null;

  const handleClose = () => {
    setClosed(true);
    incNotificationsDialogSeen();
  };

  const handleAllowing = () => {
    if (viewer?.coachLevel) {
      openNotificationsSettings();
    } else {
      navigate(NOTIFICATIONS.url());
    }
    handleClose();
  };

  return (
    <DialogWindow open={!closed && allowOpen && afterTutorial} onClose={handleClose}>
      <Trans>{pickedMessage}</Trans>
      <Button variant="contained" sx={{ margin: "20px 0 10px" }} onClick={handleAllowing}>
        <Trans>Set up notifications</Trans>
      </Button>
    </DialogWindow>
  );
};

export default NotificationDialog;
