import { HeaderLayout } from "components/header/Header";
import React from "react";
import styled from "styled-components/macro";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import { Trans } from "react-i18next";
import { useViewerQuery } from "queries/viewerQuery";
import Notifications from "./Notifications";

const HTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const ViewerNotificationsIcon: React.FC<{ coachLevel: number }> = React.memo(({ coachLevel }) => {
  if (coachLevel === 3) {
    return <NotificationImportantIcon />;
  } else if (coachLevel === 2) {
    return <NotificationsIcon />;
  } else if (coachLevel === 1) {
    return <NotificationsNoneIcon />;
  }
  return <NotificationsOffIcon />;
});

type Props = {};

const NotificationsScreen: React.FC<Props> = () => {
  const { viewer } = useViewerQuery();

  if (!viewer) return null;

  return (
    <HeaderLayout
      title={
        <HTitle>
          <ViewerNotificationsIcon coachLevel={viewer.coachLevel} />
          &nbsp; <Trans>Notifications</Trans>
        </HTitle>
      }
      narrow
    >
      <Notifications />
    </HeaderLayout>
  );
};

export default NotificationsScreen;
