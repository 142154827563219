import styled from "styled-components/macro";
import { Portal } from "@mui/material";
import { AddButton } from "root/main/components/CardList/AddButton";
import TutorialPointer from "root/tutorial/TutorialPointer";

const STutorialPointer = styled(TutorialPointer)`
  right: 8px;
  bottom: 46px;
  @media (min-width: ${({ theme }) => theme.duo.maxWidth.normal}) {
    right: calc(50% - 392px);
  }
`;
const SAddButton = styled(AddButton)`
  position: absolute;
  z-index: 1000;
  bottom: 80px;
  right: 20px;
  @media (min-width: ${({ theme }) => theme.duo.maxWidth.normal}) {
    right: calc(50% - 392px);
  }
`;

const OwnWordAddButton = () => {
  return (
    <Portal>
      <SAddButton />
      <STutorialPointer pulsing />
    </Portal>
  );
};

export default OwnWordAddButton;
