export const motivationGigs = [
  `Will you pet me please?`,
  `You're doing great today!`,
  `You're getting better every day!`,
  `You did something for yourself today!`,
  `Your vocabulary is growing!`,
  `You learned new words today!`,
  `Thank you for being so awesome!`,
  `Keep up the good work!`,
  `Education is the most powerful weapon!`,
  `A little every day leads to big things!`,
  `You can be happy with your progress!`,
  `The beautiful thing about knowledge is that no one can take it away from you!`,
  `If it were easy, it wouldn't be worth it!`
];
