import React from "react";
import { Route, Routes } from "react-router-dom";
import Library from "./library/Library";
import Source from "./source/Source";
import SourceNew from "./sourceEdit/SourceNew";
import SourceEdit from "./sourceEdit/SourceEdit";
import { MYSOURCES, SOURCE, SOURCEFORM, SOURCENEW, VIDEOFORM } from "./library/libraryUrls";
import MySources from "./mySources/MySources";
import VideoEdit from "./sourceEdit/VideoEdit";

import { LIBRARY } from "../RootRouter.config";

export enum SourceKind {
  article = "article",
  set = "set",
  video = "video"
}

const LibraryRouter: React.FC<{}> = () => {
  return (
    <Routes>
      <Route path={SOURCE.relative} element={<Source />} />
      <Route path={SOURCENEW.relative} element={<SourceNew />} />
      <Route path={SOURCEFORM.relative} element={<SourceEdit />} />
      <Route path={VIDEOFORM.relative} element={<VideoEdit />} />
      <Route path={MYSOURCES.relative} element={<MySources />} />

      <Route path={LIBRARY.topicRelative} element={<Library />} />
      <Route index element={<Library />} />
    </Routes>
  );
};

export default LibraryRouter;
