/**
 * @generated SignedSource<<43876d14ff93e5620a7f1a1593b1e803>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VerifyingMutation$variables = {
  hash: string;
};
export type VerifyingMutation$data = {
  readonly verifyUser: {
    readonly emailVerified: boolean;
  };
};
export type VerifyingMutation = {
  response: VerifyingMutation$data;
  variables: VerifyingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hash"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "hash",
    "variableName": "hash"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailVerified",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "verifyUser",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "verifyUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd18574f4fe728c24b797335ddf45736",
    "id": null,
    "metadata": {},
    "name": "VerifyingMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyingMutation(\n  $hash: String!\n) {\n  verifyUser(hash: $hash) {\n    emailVerified\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "25030884a23dda5cfdeb368d348c20d7";

export default node;
