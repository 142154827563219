import { graphql } from "babel-plugin-relay/macro";
import { isNumber } from "lodash";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMutation } from "relay-hooks";
import { selectDeviceId } from "../../components/ReduxProvider";
import { useDeviceLang } from "../../hooks/deviceHooks";
import { useViewerQuery } from "../viewerQuery";
import { editUserMutation, editUserMutation$rawResponse } from "./__generated__/editUserMutation.graphql";
import { useInstallReferrer } from "tools/installReferrer";
import React from "react";
import { useSetFlag } from "queries/users/setFlag";
import { useGetState } from "components/ReduxProvider";
import { fromGlobalId } from "graphql-relay";
import { CURRENT_DECK_ID_FLAG } from "sharedJs__generated/constants";
import { getTimezone } from "tools/dateFns";
import { isChromeExtension } from "tools/device";
import { CHROME_EXTENSION_FLAG } from "queries/users/userFlagsModel";
import { differenceInDays } from "date-fns";

const EditUser = graphql`
  mutation editUserMutation(
    $timezone: String
    $appLang: String
    $fcmId: String
    $coachLevel: Int
    $deviceId: String
    $deviceOs: String
    $streakTime: Int
    $unsetInviter: Boolean
    $installReferrer: String
    $streakFreeze: Int
    $streakFreezeXps: Int
  ) @raw_response_type {
    editUserFields(
      timezone: $timezone
      appLang: $appLang
      fcmId: $fcmId
      coachLevel: $coachLevel
      deviceId: $deviceId
      deviceOs: $deviceOs
      streakTime: $streakTime
      unsetInviter: $unsetInviter
      installReferrer: $installReferrer
      streakFreeze: $streakFreeze
      streakFreezeXps: $streakFreezeXps
    ) {
      timezone
      appLang
      fcmId
      coachLevel
      deviceId
      deviceOs
      installReferrer
      streakTime
      streakFreeze
      streakFreezeXps
      inviter {
        name
        deviceId
      }
      defaultLanguages {
        target
        native
      }
    }
  }
`;

export const useUpdateUserOnChanged = () => {
  const [editUser] = useMutation<editUserMutation>(EditUser);
  const { appLang } = useDeviceLang();
  const { viewer, getFlag } = useViewerQuery();
  const setFlag = useSetFlag();
  const deviceId = useSelector(selectDeviceId);
  const deviceOs = useGetState("deviceOs");

  const { t } = useTranslation();
  const installReferrer = useInstallReferrer();
  const deckId = useGetState("deckId");
  const fcmId = useGetState("fcmId");
  const requesting = React.useRef<boolean>(); // todo-fix-weird-useEffect-behavior
  const adminOrigin = useGetState("adminOrigin");

  const unsetInviter = !!deviceId && deviceId === viewer?.inviter?.deviceId;
  const timezone = getTimezone();
  const flagUpdated = React.useRef<boolean>(false);

  React.useEffect(() => {
    if (!isChromeExtension() || !viewer || flagUpdated.current) return;

    const chromeExtensionFlag = getFlag(CHROME_EXTENSION_FLAG);
    if (!chromeExtensionFlag || differenceInDays(new Date(), new Date(chromeExtensionFlag.updated)) > 0) {
      setFlag({ name: CHROME_EXTENSION_FLAG, value: "yes" });
      // because chrome-extensions cannot update localstorage and for some reason this causes a loop
      flagUpdated.current = true;
    }
  });

  React.useEffect(() => {
    if (!viewer || !deckId) return;

    const realDeckId = fromGlobalId(deckId).id;
    if (getFlag(CURRENT_DECK_ID_FLAG)?.value !== realDeckId) {
      setFlag({ name: CURRENT_DECK_ID_FLAG, value: realDeckId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deckId, viewer]);

  React.useEffect(() => {
    // We don't check installReferrer in this condition to avoid looping, but it will save installReferrer anyway because of other conditions which always triggeres with new users. Setting installReferrer from cordova is also a last fallback.
    if (
      viewer &&
      !requesting.current &&
      !adminOrigin &&
      ((timezone && viewer.timezone !== timezone) ||
        viewer?.appLang !== appLang ||
        (deviceId && viewer.deviceId !== deviceId) ||
        (deviceOs && viewer.deviceOs !== deviceOs) ||
        unsetInviter ||
        (fcmId && viewer.fcmId !== fcmId))
    ) {
      requesting.current = true;
      editUser({
        variables: {
          timezone,
          appLang,
          unsetInviter,
          deviceId,
          deviceOs,
          ...(fcmId && { fcmId }),
          ...(installReferrer && !viewer.installReferrer && { installReferrer })
        },
        onCompleted: () => {
          requesting.current = false;
        }
      });
      if (unsetInviter) {
        alert(
          t(
            "You are on the same device as the one who invited you which is not allowed so the invitation was discarted."
          ) + "[2]"
        );
      }
    }
  });
};

export const useEditUser = () => {
  const [editUser] = useMutation<editUserMutation>(EditUser);
  const { viewer } = useViewerQuery();

  return (variables: editUserMutation["variables"]) => {
    const optimisticResponse: editUserMutation$rawResponse | undefined = !viewer
      ? undefined
      : {
          editUserFields: {
            id: viewer?.id,
            appLang: viewer?.appLang || null,
            fcmId: viewer?.fcmId || null,
            deviceId: viewer?.deviceId || null,
            deviceOs: viewer?.deviceOs || null,
            installReferrer: viewer?.installReferrer || null,
            inviter: viewer?.inviter || null,
            defaultLanguages: viewer?.defaultLanguages || null,

            ...variables,

            streakTime: variables?.streakTime === -1 ? null : variables?.streakTime || viewer?.streakTime,
            streakFreeze: variables.streakFreezeXps || viewer.streakFreeze,
            streakFreezeXps: variables.streakFreezeXps || viewer.streakFreezeXps,
            timezone: variables.timezone || viewer?.timezone,
            coachLevel: isNumber(variables.coachLevel) ? variables.coachLevel : viewer.coachLevel
          }
        };
    return editUser({ variables, optimisticResponse });
  };
};
