import React from "react";
import styled, { css, keyframes } from "styled-components/macro";
import { TutorialFC } from "root/tutorial/TutorialContent";
import TutorialPointer from "../TutorialPointer";
import { HintLearnAfterIn, HintLearnClickIn } from "root/main/learn/CardHints";
import { Button } from "@mui/material";
import { CARDSID } from "root/main/learn/Cards";
import { useDispatch, useSelector } from "react-redux";
import { selectTutorialTapPhase, nextTutorialTapPhase } from "components/ReduxProvider";
import { useDebounce } from "react-use";
import useClickAway from "hooks/useClickAway";
import { useSeeHint } from "components/hint/Hint";
import { Trans } from "react-i18next";
import { useViewerDecks } from "queries/viewerQuery";
import { useSetState } from "components/ReduxProvider";

export const TAPTUTORIALDONE = "tapsDone";

export const TapPhases = ["tap1", "swipe1", "msg1", "tap2", "swipe2", "msg2", "msg3"];

const P = styled.p`
  margin: 40px 0 20px;
`;
const moovingRightKF = keyframes`
  0% { left: 52%; opacity: 1; transform: rotate(0deg) }
  50% {  left: 80%; opacity: 1; transform: rotate(10deg) }
  60% { left: 80%; opacity: 0; transform: rotate(10deg) }
  90% { left: 52%; opacity: 0; transform: rotate(0deg) }
`;
const moovingLeftKF = keyframes`
  0% { left: 52%; opacity: 1; transform: rotate(0deg) }
  50% {  left: 20%; opacity: 1; transform: rotate(-10deg) }
  60% { left: 20%; opacity: 0; transform: rotate(-10deg) }
  90% { left: 52%; opacity: 0; transform: rotate(0deg) }
`;
const STutorialPointer = styled(TutorialPointer)<{ right?: boolean; left?: boolean }>`
  left: 52%;
  top: 55%;
  ${({ right }) =>
    right &&
    css`
      animation: ${moovingRightKF} 2s ease-out infinite;
    `}
  ${({ left }) =>
    left &&
    css`
      animation: ${moovingLeftKF} 2s ease-out infinite;
    `}
`;
const shakeKF = keyframes`
  10%, 90% {left: 2px; }
  25%, 75% {left: -4px;}
  50% {left: 4px;}
`;
const Strong = styled.strong<{ shake?: boolean; color?: "green" | "red" }>`
  position: relative;
  color: ${({ theme, color }) =>
    color === "green" ? theme.duo.color.green : color === "red" ? theme.duo.color.red : "inherit"};
  ${({ shake }) =>
    shake &&
    css`
      animation: ${shakeKF} 500ms;
    `}
`;
const Grey = styled.div`
  font-size: 90%;
  color: #777;
  margin-bottom: 5px;
`;

const TapCard: TutorialFC = ({ setVisibleId, visibleId }) => {
  const { deck } = useViewerDecks();
  const dispatch = useDispatch();
  const tapPhase = useSelector(selectTutorialTapPhase);
  const seeHint = useSeeHint();
  const setTutorialTapPhase = useSetState("tutorialTapPhase");

  const ref = React.useRef<any>();
  const [shakeIt, setShakeIt] = React.useState(false);
  useClickAway(ref, (e) => setShakeIt(true), ["click"]);
  useDebounce(() => shakeIt && setShakeIt(false), 500, [shakeIt]);

  React.useEffect(() => {
    if ((tapPhase === "msg1" || tapPhase === "msg2") && visibleId) {
      setVisibleId(undefined);
    }
  });

  React.useEffect(() => {
    const secondCardStartIndex = TapPhases.indexOf("tap2");
    const currentTapIndex = TapPhases.indexOf(tapPhase || "");

    // making sure user gets to the right tap phase if he swiped first cards and restarted app
    if (!tapPhase && deck && deck.stats.unknown === 1 && currentTapIndex < secondCardStartIndex) {
      setTutorialTapPhase("tap2");
      setTimeout(() => {
        setVisibleId(CARDSID); // needs to be in timeout because TutorialContent resets visibleId after each step change, which can happen after this effect
      });
    }

    if (deck && deck.stats.unknown === 0 && tapPhase !== "msg2" && tapPhase !== "msg3") {
      dispatch(nextTutorialTapPhase());
    }
  });

  return (
    <div ref={ref}>
      {!tapPhase || tapPhase === "tap1" || tapPhase === "tap2" ? (
        <P>
          <HintLearnClickIn />
          {visibleId && <STutorialPointer pulsing />}
        </P>
      ) : tapPhase === "swipe1" ? (
        <div style={{ margin: "10px 0" }}>
          <Trans>
            If you knew the translation,
            <Grey>(for now let's say you did)</Grey>
            <Strong shake={shakeIt} color="green">
              swipe the card right.
            </Strong>
          </Trans>
          <STutorialPointer right delayed />
        </div>
      ) : tapPhase === "msg1" ? (
        <Trans parent={P}>
          <Strong color="green">Swiping right</Strong> means that you <Strong color="green">guessed</Strong> the word
          correctly and you won't see that word for some time.
        </Trans>
      ) : tapPhase === "swipe2" ? (
        <div>
          <Trans>
            <Strong color="red" shake={shakeIt}>
              Now swipe the card left.
            </Strong>
            <Grey>(as if you didn’t know the translation)</Grey>
          </Trans>
          <STutorialPointer left delayed />
        </div>
      ) : tapPhase === "msg2" ? (
        // prettier-ignore
        <Trans parent={P}>
          <Strong color="red">Swiping left</Strong> means that you{" "}
          <Strong color="red">didn't guess</Strong> the word and you will see the word again several
          cards later.
        </Trans>
      ) : tapPhase === "msg3" ? (
        <div>
          <p>
            <Trans parent="strong">Summary:</Trans>
          </p>
          <HintLearnAfterIn animated />
        </div>
      ) : (
        <></>
      )}
      {!visibleId && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            tapPhase !== "msg2" && setVisibleId(CARDSID);
            dispatch(nextTutorialTapPhase());
            tapPhase === "msg3" && seeHint(TAPTUTORIALDONE);
          }}
        >
          OK
        </Button>
      )}
    </div>
  );
};

export default TapCard;
