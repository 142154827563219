/**
 * @generated SignedSource<<76ace7834d9a3416eb9a9179073ff249>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NativeRowDelMutation$variables = {
  langNative: string;
  sourceId: string;
};
export type NativeRowDelMutation$data = {
  readonly nativeDelete: {
    readonly natives: ReadonlyArray<{
      readonly langNative: string;
      readonly nameNative: string;
    }>;
  };
};
export type NativeRowDelMutation = {
  response: NativeRowDelMutation$data;
  variables: NativeRowDelMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "langNative"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "langNative",
    "variableName": "langNative"
  },
  {
    "kind": "Variable",
    "name": "sourceId",
    "variableName": "sourceId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "langNative",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameNative",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NativeRowDelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "nativeDelete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Native",
            "kind": "LinkedField",
            "name": "natives",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NativeRowDelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "nativeDelete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Native",
            "kind": "LinkedField",
            "name": "natives",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "da924f910b0779e6fced6d2e164c2069",
    "id": null,
    "metadata": {},
    "name": "NativeRowDelMutation",
    "operationKind": "mutation",
    "text": "mutation NativeRowDelMutation(\n  $sourceId: ID!\n  $langNative: String!\n) {\n  nativeDelete(sourceId: $sourceId, langNative: $langNative) {\n    natives {\n      langNative\n      nameNative\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4055776b4174687837c94c0dc7e162a1";

export default node;
