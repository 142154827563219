/**
 * @generated SignedSource<<a360ed1a1d888692ae0ead2451986187>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NativeRowMutation$variables = {
  langNative: string;
  nameNative: string;
  sourceId: string;
};
export type NativeRowMutation$data = {
  readonly nativeEdit: {
    readonly natives: ReadonlyArray<{
      readonly langNative: string;
      readonly nameNative: string;
    }>;
  };
};
export type NativeRowMutation = {
  response: NativeRowMutation$data;
  variables: NativeRowMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "langNative"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nameNative"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "langNative",
    "variableName": "langNative"
  },
  {
    "kind": "Variable",
    "name": "nameNative",
    "variableName": "nameNative"
  },
  {
    "kind": "Variable",
    "name": "sourceId",
    "variableName": "sourceId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "langNative",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameNative",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NativeRowMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "nativeEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Native",
            "kind": "LinkedField",
            "name": "natives",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "NativeRowMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "nativeEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Native",
            "kind": "LinkedField",
            "name": "natives",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d1649ab4f62294e2b7d8868d9702d3d1",
    "id": null,
    "metadata": {},
    "name": "NativeRowMutation",
    "operationKind": "mutation",
    "text": "mutation NativeRowMutation(\n  $sourceId: ID!\n  $langNative: String!\n  $nameNative: String!\n) {\n  nativeEdit(sourceId: $sourceId, nameNative: $nameNative, langNative: $langNative) {\n    natives {\n      langNative\n      nameNative\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "11c2730dd4865f08ac2a516a11b57425";

export default node;
