import { addMinutes } from "date-fns";
import { useViewerQuery } from "queries/viewerQuery";
import React from "react";
import { useIdle } from "react-use";
import { useMatchMain } from "root/RootRouter";

const threeMinutesInFuture = () => addMinutes(new Date(), 3);

type Props = {};

const ViewerRefresher: React.FC<Props> = () => {
  const { viewer, retry } = useViewerQuery();
  const [refreshAt, setRefreshAt] = React.useState<Date>(threeMinutesInFuture());
  const inMain = useMatchMain({ end: true });
  const isIdle = useIdle(60e3); // consider user inactive after 1 minute

  // refresh viewer query every 3 minutes so that "To learn" numbers match
  React.useEffect(() => {
    if (viewer && !isIdle && inMain && refreshAt < new Date()) {
      retry(undefined, { fetchPolicy: "store-and-network" });
      setRefreshAt(threeMinutesInFuture());
    }
  }, [viewer, refreshAt, isIdle, retry, inMain]);

  return null;
};

export default ViewerRefresher;
