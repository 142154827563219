import React from "react";
import styled from "styled-components/macro";
import DialogWindow from "../other/DialogWindow";
import { useSelector, useDispatch } from "react-redux";
import { selectInviteDialog, setInviteDialog } from "../ReduxProvider";
import { Trans, useTranslation } from "react-i18next";
import { Button, TextareaAutosize } from "@mui/material";
import { useViewerQuery } from "../../queries/viewerQuery";
import { useCopyToClipboard } from "react-use";
import RedeemIcon from "@mui/icons-material/Redeem";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import { isDesktop } from "../../tools/device";
import { useMatch } from "react-router";
import { useCanAdvertFriends } from "components/ads/useCanAdvertFriends";
import VerifyButton from "root/profile/VerifyButton";

const SDialogWindow = styled(DialogWindow)`
  .MuiDialog-paper {
    box-shadow: inset 0 0 80px #ffd70099;
  }
`;
const Wrap = styled.div``;
const SButton = styled(Button)`
  &&& {
    margin: 6px 0;
    padding: 9px 50px;
  }
`;
const CopyButton = styled(Button)`
  &&& {
    margin-top: -36px;
    background: white;
  }
`;
const Input = styled(TextareaAutosize)`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.duo.color.green}88;
  color: ${({ theme }) => theme.duo.color.text}dd;
  padding: 12px 12px 28px;
  flex-grow: 1;
  font-size: 12.5px;
  line-height: 1.4em;
  width: 100%;
  font-family: "Noto Sans", Arial, Helvetica, sans-serif;
  margin-top: 7px;
`;
const Free = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: ${({ theme }) => theme.duo.color.gold};
  font-size: 16px;
  line-height: 1.3em;
  margin: 15px;
`;
const SRedeemIcon = styled(RedeemIcon)`
  color: ${({ theme }) => theme.duo.color.gold};
`;
const Icons = styled.div`
  color: ${({ theme }) => theme.duo.color.green};
  font-size: 24px;
  svg {
    font-size: 36px;
    margin: 3px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Copied = styled.div`
  position: absolute;
  margin: -98px auto 0;
  left: 0;
  right: 0;
  width: 83%;
  border-radius: 10px;
  background: #ffffffee;
  padding: 20px 0;
  text-align: center;
  transition: opacity 0.6s;
  opacity: 0;
  pointer-events: none;
`;
const Ul = styled.ul`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.3em;
  text-align: left;
  margin: 10px 0;
  padding: 0 0 0 10px;
  li {
    margin: 4px;
  }
`;
const HowLink = styled.a`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  color: ${({ theme }) => theme.duo.color.green};
`;

export const useInviteHash = (): string | undefined => {
  const iRoute: any = useMatch("/i/:ihash");
  return iRoute?.params?.ihash;
};

type Props = {};

const InviteDialog: React.FC<Props> = () => {
  const openDialog = useSelector(selectInviteDialog);
  const dispatch = useDispatch();
  const copyToClipboard = useCopyToClipboard()[1];
  const { viewer } = useViewerQuery();
  const advertFriends = useCanAdvertFriends();
  const [copied, setCopied] = React.useState(false);
  const [howClicked, setHowClicked] = React.useState(false);
  const { t } = useTranslation();

  if (!viewer?.id || !viewer?.name) return null;

  const hash = btoa(viewer.name);

  const text = t("Hi, connect with me in DuoCards. It is a great app for learning vocabulary:");
  const textPromo = t("Hi, join me in DuoCards and we will both get one month of Premium for free:");
  const inviteText = (advertFriends ? textPromo : text) + ` https://app.duocards.com/i/${hash}`;

  return (
    <SDialogWindow wide open={!!openDialog} onClose={() => dispatch(setInviteDialog(false))}>
      <Wrap>
        {advertFriends ? (
          <>
            <Icons>
              <SupervisedUserCircleIcon /> = <SRedeemIcon />
            </Icons>
            <Free></Free>
            <p style={{ fontSize: "90%" }}>
              <Trans parent="strong">
                For <span style={{ color: "green" }}>each</span> invited friend{" "}
                <span style={{ color: "green" }}>both of you</span> will get one month of{" "}
                <span style={{ color: "green" }}>Premium for free</span>
              </Trans>
            </p>
          </>
        ) : (
          <Icons>
            <SupervisedUserCircleIcon />
          </Icons>
        )}
        {viewer.emailVerified ? (
          <>
            <Input readOnly value={inviteText} onFocus={(e: any) => e.target.select()} />
            <Trans parent={Copied} style={{ opacity: copied ? 1 : 0 }}>
              Copied to clipboard!
            </Trans>
            <CopyButton
              color="secondary"
              variant="outlined"
              onClick={() => {
                copyToClipboard(inviteText);
                setCopied(true);
                setTimeout(() => setCopied(false), 1400);
              }}
            >
              <Trans>Copy invitation</Trans>
            </CopyButton>

            <div style={{ marginBottom: 16 }}>
              {navigator.share && !isDesktop() && (
                <SButton
                  color="secondary"
                  variant="contained"
                  onClick={() =>
                    // @ts-ignore
                    navigator.share(inviteText, undefined, "text/plain")
                  }
                >
                  <Trans>Send Invitation</Trans>
                </SButton>
              )}
            </div>

            <div style={{ fontSize: "90%", margin: "1em 0" }}>
              <strong>TIP:</strong> <Trans>Share the link on Facebook to invite your friends</Trans>{" "}
            </div>
          </>
        ) : (
          <>
            <Trans>But first of all, let's verify your email.</Trans>
            <div style={{ margin: "5px 0 20px" }}>
              <VerifyButton />
            </div>
          </>
        )}

        {advertFriends && (
          <>
            {!howClicked ? (
              <HowLink onClick={() => setHowClicked(true)}>
                <Trans>How does it work?</Trans>
              </HowLink>
            ) : (
              <Ul>
                <Trans parent="li">Your friend has to finish the registration and the tutorial.</Trans>
                <Trans parent="li">
                  If you don't see your friend here after you send the link, ask what instructions your friend sees on
                  the main screen of the app.
                </Trans>
                <Trans parent="li">The offer is valid even if the invited friend is already registered</Trans>
              </Ul>
            )}
          </>
        )}
      </Wrap>
    </SDialogWindow>
  );
};

export default InviteDialog;
