import { Trans } from "react-i18next";
import { Button, Paper } from "@mui/material";
import styled from "styled-components/macro";
import { useOpenAssistant } from "./assistantHooks";
import TransVariable from "components/other/TransVariable";

export const MAX_MESSAGES = 30; // 0.1-0.2% of users
export const MAX_MESSAGE_LENGTH = 3000;

const Wrap = styled(Paper)`
  margin: 10px 10px 20px 10px;
  padding: 10px;
  background-color: #fff3f3;
  text-align: center;
`;
const Div = styled.div`
  margin: 10px;
`;
const Warn = styled.div`
  color: orange;
  padding: 2px 6px 6px;
  font-size: 80%;
  line-height: 1.4em;
`;

export const FullChat = () => {
  const openAssistant = useOpenAssistant();

  return (
    <Wrap>
      <Trans parent={Div}>
        You reached the maximum of <TransVariable text={String(MAX_MESSAGES * 2)} /> messages for one chat.
      </Trans>

      <Button variant="contained" color="primary" size="small" onClick={() => openAssistant()}>
        <Trans>Start a new chat</Trans>
      </Button>
    </Wrap>
  );
};

export const FullChatWarning = ({ messagesCount }: { messagesCount: number }) => {
  if (messagesCount >= MAX_MESSAGES - 3 && messagesCount < MAX_MESSAGES) {
    return (
      <Warn>
        <Trans>This chat is reaching its limit. You can ask few more questions.</Trans> ({MAX_MESSAGES - messagesCount})
      </Warn>
    );
  }
  return null;
};
