import { Button } from "@mui/material";
import React from "react";
import styled, { css } from "styled-components/macro";
import { useViewerQuery } from "../../queries/viewerQuery";
import { devicePaddings } from "../../tools/device";
import { BOTTOMNAVID } from "../other/BottomNavigation";
import DialogWindow from "../other/DialogWindow";
import { HintEmoji, useSeeHint } from "./Hint";

const { bottom } = devicePaddings();

const SDialogWindow = styled(DialogWindow)<{ position?: "bottom"; showNav?: boolean | number }>`
  .MuiDialog-container {
    align-items: ${({ position }) => (position === "bottom" ? "flex-end" : "center")};
  }
  ${({ showNav }) => {
    const navHeight = document.getElementById(BOTTOMNAVID)?.offsetHeight;
    const bottParam = typeof showNav === "number" ? showNav : (navHeight || 56) + bottom;
    return (
      showNav &&
      css`
        &,
        .MuiBackdrop-root {
          bottom: ${bottParam}px !important;
        },
      `
    );
  }}
`;
const ButtWrap = styled.div`
  padding: 12px 0 3px;
`;

type Props = {
  children: React.ReactNode;
  name: string;
  emoji?: React.ReactNode;
  className?: string;
  noOk?: boolean;
  position?: "bottom";
  showNav?: boolean | number;
  wide?: boolean;
  withClose?: boolean;
  onClose?: VoidFunction;
};

const HintDialog: React.FC<Props> = ({
  className,
  position,
  showNav,
  wide,
  children,
  emoji,
  name,
  noOk,
  withClose,
  onClose
}) => {
  const { hintWasSeen, viewer } = useViewerQuery();
  const seeHint = useSeeHint();

  if (!viewer) return null;

  const handleClick = () => {
    seeHint(name);
    onClose && onClose();
  };

  return (
    <SDialogWindow
      open={!hintWasSeen(name)}
      className={className}
      position={position}
      showNav={showNav}
      wide={wide}
      onClose={withClose ? handleClick : undefined}
      title={emoji !== "" && <HintEmoji>{emoji !== undefined ? emoji : "💡"}</HintEmoji>}
    >
      {children}
      {name && !noOk && (
        <ButtWrap>
          <Button color="primary" variant="contained" onClick={handleClick}>
            OK
          </Button>
        </ButtWrap>
      )}
    </SDialogWindow>
  );
};

export default HintDialog;
