import React from "react";
import styled from "styled-components/macro";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { Trans } from "react-i18next";
import { TutorialFC } from "../TutorialContent";

const STouchAppIconRight = styled(TouchAppIcon)`
  /* prettier-ignore */
  @keyframes swipingRight {
    0%, 20% { left: 0;  transform: rotate(-20deg);}
    70%, 100% { left: 10px;  transform: rotate(20deg); }
    20%, 80% { opacity: 1; }
    10%, 90% { opacity: 0; }
  }
  opacity: 0;
  color: ${({ theme }) => theme.duo.color.green};
  position: relative;
  animation: swipingRight 2.5s ease-in-out infinite;
  margin-right: 10px;
  font-size: 26px;
`;

const ThenSwipeAllRight: TutorialFC = () => {
  return (
    <>
      <STouchAppIconRight />
      <Trans parent="p">Swipe all the cards to the right to get further in the App.</Trans>
    </>
  );
};

export default ThenSwipeAllRight;
