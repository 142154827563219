import { useDBLog } from "hooks/useDBLog";
import React from "react";
import { useEffectOnce } from "react-use";

/**
 * Logs used translations so that we can remove unused ones later with `unusedTranslations.ts`
 */

export const useHandleUsedTranslations = (usedKeys: React.MutableRefObject<string[]>) => {
  const dblog = useDBLog();

  // "usedTranslations" will update only the one existing record on BE (exception)
  const name = "usedTranslations";

  useEffectOnce(() => {
    const interval10min = setTimeout(
      () => {
        usedKeys.current.length && dblog(name, name, { usedTranslations: usedKeys.current });
      },
      10 * 60 * 1000
    );

    const interval2hours = setTimeout(
      () => {
        usedKeys.current.length && dblog(name, name, { usedTranslations: usedKeys.current });
      },
      2 * 60 * 1000
    );

    return () => {
      clearInterval(interval10min);
      clearInterval(interval2hours);
    };
  });
};
