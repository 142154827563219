/**
 * @generated SignedSource<<e33ff943e70e495bf907b682a5129363>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VoicePickerItemSaveQuery$variables = {
  deckId: string;
  voices: ReadonlyArray<string>;
};
export type VoicePickerItemSaveQuery$data = {
  readonly saveVoices: {
    readonly voices: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null;
};
export type VoicePickerItemSaveQuery = {
  response: VoicePickerItemSaveQuery$data;
  variables: VoicePickerItemSaveQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deckId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "voices"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "deckId",
    "variableName": "deckId"
  },
  {
    "kind": "Variable",
    "name": "voices",
    "variableName": "voices"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "TtsVoice",
  "kind": "LinkedField",
  "name": "voices",
  "plural": true,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VoicePickerItemSaveQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deck",
        "kind": "LinkedField",
        "name": "saveVoices",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VoicePickerItemSaveQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Deck",
        "kind": "LinkedField",
        "name": "saveVoices",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "95bdb95b6030d0edc556f894246dc232",
    "id": null,
    "metadata": {},
    "name": "VoicePickerItemSaveQuery",
    "operationKind": "mutation",
    "text": "mutation VoicePickerItemSaveQuery(\n  $deckId: ID!\n  $voices: [String!]!\n) {\n  saveVoices(deckId: $deckId, voices: $voices) {\n    voices {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a1df0c7c6d53dc4f980b7296ad7bde97";

export default node;
