import React from "react";
import styled from "styled-components/macro";
import { Backdrop, IconButton } from "@mui/material";
import { Portal } from "@mui/base";
import GrammarDictionary, { GrammarTabId } from "components/GrammarDictionary/GrammarDictionary";
import HintTooltip from "components/other/HintTooltip";
import { Grammar } from "dexieDB/dexie";
import { faBookOpenCover } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AI_GRAMMAR_HINT = "aiGrammarTooltip";

const Wrap = styled.div``;
const SIconButton = styled(IconButton)`
  position: relative;

  && svg {
    width: 19px;
    height: 19px;
  }
`;
const SBackdrop = styled(Backdrop)`
  z-index: 1000;
`;
const SGrammarDictionary = styled(GrammarDictionary)`
  max-width: 90%;
  max-height: 90%;
`;

type Props = {
  grammar: readonly Grammar[];
  canShowTooltip: boolean;
};
const CardGrammarOpener: React.FC<Props> = React.memo(({ grammar, canShowTooltip }) => {
  const [grammarTab, setGrammarTab] = React.useState<GrammarTabId | undefined>();
  const [delayedTooltip, setDelayedTooltip] = React.useState(canShowTooltip);

  React.useEffect(() => {
    setTimeout(() => setDelayedTooltip(canShowTooltip), 500);
  }, [canShowTooltip]);

  const handleClose = (e?: React.MouseEvent<HTMLElement>) => {
    setGrammarTab(undefined);
    e?.stopPropagation();
  };

  return (
    <Wrap onClick={(e) => e.stopPropagation()}>
      {grammar && (
        <HintTooltip
          title="This card is hard. Read more info about it to remember it better."
          canOpen={delayedTooltip}
          name={AI_GRAMMAR_HINT}
          placement="bottom"
        >
          <SIconButton onClick={() => setGrammarTab("ai")}>
            <FontAwesomeIcon icon={faBookOpenCover} />
          </SIconButton>
        </HintTooltip>
      )}

      {
        // cannot use WindowDialog because Contextor inside Mui-Dialog doesn't work
        grammarTab && (
          <Portal>
            <SBackdrop
              open={true}
              onTouchStart={(e) => e.stopPropagation()}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={handleClose}
            >
              <SGrammarDictionary grammar={grammar} onClose={handleClose} />
            </SBackdrop>
          </Portal>
        )
      }
    </Wrap>
  );
});

export default CardGrammarOpener;
