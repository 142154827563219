import React from "react";
import { useDebounce, usePrevious, useUpdate } from "react-use";
import styled, { keyframes, css } from "styled-components/macro";
import { ANIMDUR } from "../ItemAnimation";

export const PROGRESS_TIME = 1200;

const disapearingKF = keyframes`
    0% { opacity: 1; }
    38% { opacity: 1; }
    100% { opacity: 0; }`;

const Svg = styled.svg<{ $perc: number }>`
  position: absolute;
  width: 54px;
  height: 54px;
  top: -12px;
  left: -9px;
  ${({ $perc }) =>
    $perc === 100 &&
    css`
      animation: ${disapearingKF} 4s forwards;
    `};
`;
const Circle = styled.path`
  stroke-linecap: round;
  transform: rotate(0.625turn);
  transform-origin: center center;
  stroke-dasharray: 289px, 289px;
  stroke-dashoffset: 72.5px;
  fill-opacity: 0;
  transition: all 2s;
`;
const BgCircle = styled(Circle)<{ $hide: boolean }>`
  stroke: #ccc;
  stroke-width: 1px;
  opacity: ${({ $hide }) => ($hide ? 0 : 1)};
`;
const ProgressCircle = styled(Circle)<{ $perc: number; $animatedProgress: boolean }>`
  stroke: #ef5a00;
  stroke-linecap: round;
  transform: rotate(0.625turn);
  transform-origin: center center;
  stroke-dasharray: 289px, 289px;
  stroke-dashoffset: ${({ $perc }) => 72.5 + (100 - $perc) * ((289 - 72.5) / 100)}px;
  stroke-width: ${({ $animatedProgress }) => ($animatedProgress ? 10 : 2)}px;
`;

type Props = {
  perc: number;
};

const FlameCircle: React.FC<Props> = ({ perc }) => {
  const [destroy, setDestroy] = React.useState(false);
  const prevDestroy = usePrevious(destroy) || false;
  const update = useUpdate();
  const [debouncedPerc, setDebouncedPerc] = React.useState(perc);
  useDebounce(() => setDebouncedPerc(perc), PROGRESS_TIME, [perc]);
  const animatedProgress = debouncedPerc !== perc;

  React.useEffect(() => {
    if (perc === 100 && !destroy) {
      setTimeout(() => setDestroy(true), ANIMDUR - 1000);
    } else if (destroy) {
      setDestroy(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perc]);

  React.useEffect(() => {
    if (!destroy && prevDestroy) {
      setTimeout(update, 50);
    }
  }, [destroy, prevDestroy, update]);

  return (
    <Svg $perc={perc} viewBox="0 0 100 100" className="hideOnAnimation">
      <BgCircle
        $hide={destroy}
        d="
  M 50,50
  m 0,-46
  a 46,46 0 1 1 0,92
  a 46,46 0 1 1 0,-92
"
      ></BgCircle>
      {!destroy && (
        <ProgressCircle
          $animatedProgress={animatedProgress}
          $perc={prevDestroy ? 0 : perc}
          d="
  M 50,50
  m 0,-46
  a 46,46 0 1 1 0,92
  a 46,46 0 1 1 0,-92
"
        ></ProgressCircle>
      )}
    </Svg>
  );
};

export default FlameCircle;
