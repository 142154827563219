import React, { useEffect } from "react";
import Word from "./Word";
import { isDesktop, isIos } from "../../../../tools/device";
import { useTranslateQuery } from "../../../../queries/translateQuery";
import { isEqual } from "lodash";
import { useCardCreate } from "queries/cards/cardCreate";
import { useSetState } from "../../../../components/ReduxProvider";
import * as Sentry from "@sentry/browser";
import { useIncAdStack } from "../../../../components/ads/adStack";
import { useBurnWithSource } from "../../../../components/fire/xpHooks";
import { Portal } from "@mui/base";
import { useSeeHint } from "../../../../components/hint/Hint";
import { useEffectOnce, usePrevious, useScrolling } from "react-use";
import { getHint, getSelectedSentence } from "./contextorTools";
import { useViewerDecks, useViewerQuery } from "../../../../queries/viewerQuery";
import styled from "styled-components/macro";
import { useDeviceLang } from "../../../../hooks/deviceHooks";
import { FIVEHINT } from "root/main/main/Main";
import { CONTENT_ID } from "components/header/constants";
import { dispatchEvent, EVENT, snackbar } from "tools/events";
import { useTranslation } from "react-i18next";
import rangy from "rangy";
import { VIDEO_TUTORIAL } from "root/tutorial/steps/LongTouch";
import useSingleAndDoubleClick from "hooks/useSingleAndDoubleClick";
import SentenceHint from "./SentenceHint";
import { differenceInMilliseconds } from "date-fns";

const Cont = styled.span`
  display: block;
`;

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  source?: { lang: string; id?: string | null; kind?: string };
  hint?: string;
  onSelection?: (selected?: string) => void;
  onBlur?: VoidFunction;
};

const Contextor: React.FC<Props> = React.memo(({ children, className, source, hint, onSelection, onBlur, style }) => {
  const { hintWasSeen } = useViewerQuery();
  const { deck } = useViewerDecks();
  const [anchorPos, setAnchorPos] = React.useState<{ top?: number; left?: number }>({});
  const [text, setText] = React.useState<string>();
  const { cardCreate } = useCardCreate();

  const srcLang = (source?.lang || deck?.front) as string;
  const { deviceLang } = useDeviceLang();
  const incAdStack = useIncAdStack();
  const burnW = useBurnWithSource(source?.lang);
  const seeHint = useSeeHint();
  const { t } = useTranslation();
  const scrolling = useScrolling({ current: document.getElementById(CONTENT_ID) });
  const setDeckCreate = useSetState("deckCreate");
  const ref = React.useRef<HTMLSpanElement>(null);
  const [clickedSelectSentence, setClickedSelectSentence] = React.useState(false);
  const setShowSentenceHint = useSetState("showSentenceHint"); // use redux because this component can be rerendered by parent, so state would be lost

  const prevText = usePrevious(text);
  const lastTextChangeRef = React.useRef<number>(0);
  if (prevText !== text) {
    lastTextChangeRef.current = Date.now();
  }

  useEffect(() => {
    if (!text) return;

    const interval = setInterval(() => {
      const selection = window?.getSelection();
      const selectedText = selection?.toString();
      if (text && selectedText && text !== selectedText) {
        setText(undefined);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [text]);

  React.useEffect(() => {
    scrolling && text && deselect();
  }, [scrolling, text]);

  const checkIfShouldHandleSelection = (e) => {
    let parentEl;
    try {
      const selection = window?.getSelection();
      parentEl = selection?.getRangeAt(0).commonAncestorContainer || null;
    } catch (e) {}
    if (!parentEl) return;

    while (parentEl) {
      if (parentEl === ref.current) {
        e.preventDefault();
        handleSelection();
        return;
      }
      parentEl = parentEl.parentNode;
    }
  };

  /* when you drag selection handle and release, contextmenu event is called, that's where Contextor handles new selection
   *  the thing is that if Contextor is placed inside a Dialog, the contextmenu event never reaches Contextor element (I dunno why)
   *  so this solves the problem, I bind contextmenu event to window itself, when a selection is made I check if it origins from this particular Contextor
   *  if yes, then I handle the selection and preventDefault, which blocks native selection popup on android (which lays over our add word dialog).
   *  There is another contextmenu handler defined below (on the Contextor itself), which handles most of the cases, and has stopPropagation() to not interfere with this one */
  useEffectOnce(() => {
    window.addEventListener("contextmenu", checkIfShouldHandleSelection);

    return () => {
      window.removeEventListener("contextmenu", checkIfShouldHandleSelection);
    };
  });

  const { translate: apiTranslate, svg } = useTranslateQuery(
    text
      ? {
          from: text,
          fromLang: srcLang,
          toLang: deck?.back || deviceLang,
          withSvg: true
        }
      : undefined
  );

  const deselect = () => {
    window.getSelection && window.getSelection()?.removeAllRanges();
  };

  const handleSelection = () => {
    const selection = window?.getSelection();
    const selectedText = selection?.toString();
    if (selectedText && text !== selectedText) {
      burnW();
      incAdStack(4);
      onSelection && onSelection(selectedText);
    }
    setText(selectedText);
    if (selectedText) {
      try {
        const oRange = selection?.getRangeAt(0);
        const oRect = oRange?.getBoundingClientRect();
        if (oRect?.x && oRect?.y) {
          const newPos = { top: oRect.y - 10, left: oRect.x + oRect.width / 2 };
          !isEqual(newPos, anchorPos) && setAnchorPos(newPos);
        }
      } catch (err) {
        Sentry.captureMessage("oRange error (iphone5)");
      }
    } else if (text) {
      onBlur && onBlur();
    }
  };

  const canSelectSentence = (text: string) => {
    const res = getSelectedSentence(ref.current, srcLang);
    return res && res.data.sentence.length > 2 && text.trim() !== res.data.sentence;
  };

  const selectSentence = () => {
    const selection = window.getSelection();
    if (!selection || !selection.rangeCount || !selection.anchorNode) return; // No selection

    const res = getSelectedSentence(ref.current, srcLang);
    if (!res) return;

    const { start, end } = res;
    if (!start.node || !end.node) return;

    // create new sentence selection
    const newRange = new Range();
    newRange.setStart(start.node?.node, start.offset);
    newRange.setEnd(end.node?.node, end.offset);
    selection.removeAllRanges();
    selection.addRange(newRange);
  };

  const afterSave = () => {
    deselect();
    handleSelection();
    onBlur && onBlur();
    seeHint(FIVEHINT);
    seeHint(VIDEO_TUTORIAL);

    if (clickedSelectSentence && !isIos()) setShowSentenceHint(true); // not working properly on ios, so we do not show it
  };

  const handleSave = (edit?: boolean) => {
    if (deck?.front !== srcLang) {
      setDeckCreate({ lang: srcLang as string });
    } else {
      if (text && apiTranslate) {
        if (edit) {
          setText(""); // text is condition for displaying <Word> so this closes it
          dispatchEvent(EVENT.openCardEditDialog, {
            front: text,
            back: apiTranslate,
            sourceId: source?.id,
            hint: hint || getHint(text),
            svg,
            cardEditCallback: afterSave
          });
        } else {
          cardCreate({
            front: text,
            back: apiTranslate,
            deckId: deck?.id,
            sourceId: source?.id,
            hint: hint || getHint(text),
            svg,
            onComplete: (data) => {
              if (!data?.cardCreate?.duplicatedCard) {
                snackbar(t("Saved"), { bottom: true, autoHide: 2000 });
              } else {
                snackbar(<>{t("Already saved in the deck")}</>, { bottom: true, autoHide: 2000 });
              }
            }
          });
          afterSave();
        }
      }
      source?.lang && burnW();
      incAdStack(5);
    }
  };

  const handleSelectSentenceClick = () => {
    setClickedSelectSentence(true);
    selectSentence();
    handleSelection();
  };

  const handleSingleClick = () => {
    handleSelection();
  };

  const handleDoubleClick = () => {
    if (isIos()) return; // ios not creating/showing new selection, so this feature will not work properly
    makeDummySelectionUnderClick();
    selectSentence();
    handleSelection();
  };

  const handleClick = useSingleAndDoubleClick(handleSingleClick, handleDoubleClick);

  return (
    <>
      <Cont
        ref={ref}
        className={className}
        style={style}
        onContextMenu={(e) => {
          e.preventDefault();
          e.stopPropagation();
          isDesktop() && selectClickedWord(srcLang); // for right mouse button (mac has this in defaul behaviour)
          handleSelection();
        }}
        onTouchEnd={() => {
          isIos() && handleSelection();
        }}
        onTouchStart={(e) => {
          handleSelection();
        }}
        onMouseUp={() => {
          handleSelection();
        }}
        onMouseDown={(e) => {
          handleSelection();
        }}
        onClick={(e) => {
          handleClick(e);
          e.stopPropagation();
        }}
      >
        {children}
      </Cont>
      {text && (
        <Portal>
          <Word
            anchorPos={anchorPos}
            onSave={() => handleSave(false)}
            translate={apiTranslate}
            text={text}
            onEdit={() => handleSave(true)}
            lang={srcLang}
            onSelectSentence={canSelectSentence(text) ? handleSelectSentenceClick : undefined}
            onClickAway={() => {
              if (
                !hintWasSeen(FIVEHINT) &&
                (source?.kind === "video" || source?.kind === "article") &&
                text.length > 2 &&
                Number(apiTranslate?.length) > 2
              ) {
                return; // stay focused in tutorial
              }

              const isJustSelected = differenceInMilliseconds(Date.now(), lastTextChangeRef.current) < 100; // solves the problem that onClickAway can get called right after the selection has been made, which would clear the selection immediatelly
              !isIos() && !isJustSelected && deselect(); // ios deselects on scroll
              handleSelection();
            }}
          />
        </Portal>
      )}
      <SentenceHint />
    </>
  );
});

export default Contextor;

// helper func

const isCharacterDelimiter = (text: string, index: number): boolean => {
  if (index < 0 || index > text.length - 1) return true;

  const delimiters = [" ", ".", ":", ";", "!", "'", '"', ",", "„", "“", "]", "["];
  return delimiters.includes(text[index]);
};
const getWordBoundariesFromIndex = (text: string, index: number): { start: number; end: number } => {
  if (isCharacterDelimiter(text, index)) {
    if (!isCharacterDelimiter(text, index - 1)) index--;
    if (!isCharacterDelimiter(text, index + 1)) index++;
  }

  const boundaries = { start: index, end: index };
  while (!isCharacterDelimiter(text, boundaries.start - 1)) {
    boundaries.start--;
  }
  while (!isCharacterDelimiter(text, boundaries.end + 1)) {
    boundaries.end++;
  }

  return boundaries;
};

const makeInitialSelectionRange = () => {
  try {
    const s = rangy.getSelection();
    if (!s || s.rangeCount < 1) return;

    const range = s.getRangeAt(0);
    if (!range) return;

    const node = s.anchorNode;
    if (!node) return;

    if (range.toString().length > 0) {
      // don't select a word if user selected something by dragging on desktop
      return null;
    }

    range.setStart(node, 0);

    return {
      selection: s,
      range
    };
  } catch (e) {
    console.log(`Selection not supported (${e})`);
    return;
  }
};

const makeDummySelectionUnderClick = () => {
  try {
    const res = makeInitialSelectionRange();
    if (!res) return;
    const { selection, range } = res;

    range.setStart(range.endContainer, range.endOffset - 1);
    range.setEnd(range.endContainer, range.endOffset);

    // on ios code is creating/showing a selection only after user selects something, Do not know how to solve it, but it is just a visual bug
    selection.setSingleRange(range);
    return true;
  } catch (e) {
    console.log(`Selection not supported (${e})`);
    return false;
  }
};

const selectClickedWord = (lang: string | undefined): boolean => {
  let unsupportedLangs = ["zh-CN", "zh-TW", "ja", "ko", "th", "lo", "km", "my", "ar", "hi", "mr", "ne", "sa", "zh"];
  if (!lang || unsupportedLangs.includes(lang)) return false;

  try {
    const res = makeInitialSelectionRange();
    if (!res) return false;
    const { selection, range } = res;

    const node = range.startContainer;

    range.setStart(node, 0);
    const clickIndex = range.toString().length;
    const text = node.textContent;
    if (!text) return false;

    const boundaries = getWordBoundariesFromIndex(text, clickIndex);

    if (boundaries.start === boundaries.end) {
      selection.removeAllRanges();
    } else {
      range.setStart(node, boundaries.start);
      range.setEnd(node, boundaries.end + 1);
      selection.setSingleRange(range);
      return true;
    }
  } catch (e) {
    console.log(`Selection not supported (${e})`);
  }

  return false;
};
