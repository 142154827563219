import React from "react";
import * as Sentry from "@sentry/browser";
import styled from "styled-components/macro";
import { Button } from "@mui/material";
import { windowReload } from "tools/windowReload";
import { deleteAllIndexedDBs } from "dexieDB/dexie";

const Wrap = styled.div`
  display: flex;
  height: 95vh;
  flex-direction: column;
  justify-content: center;
  font-family: "Noto Sans", Arial;
  text-align: center;
  margin: 0 30px;
`;
const SButton = styled(Button)`
  margin-top: 30px;
  border-radius: 20px;
`;
const Small = styled.p`
  font-size: 13px;
`;

class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtra("errorInfo", errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      let token = "";
      try {
        token = JSON.parse(localStorage.getItem("reduxState") || "{}")?.token;
      } catch {}

      return (
        <Wrap>
          <p>
            <img src="https://cdn.duocards.com/img/damage.png" alt="error" width="100" height="100" />
          </p>
          <p>Something went wrong</p>
          <p>Use the button below to reload the application</p>
          <Small>
            If reloading does not help, tell us about your experience on{" "}
            <a href="mailto:info@duocards.com">info@duocards.com</a> so we can help.
          </Small>

          <SButton
            variant="contained"
            onClick={() => {
              // clear localStorage & IndexedDB
              localStorage.clear();
              deleteAllIndexedDBs();

              // set back token so user stay logged in
              token && localStorage.setItem("reduxState", JSON.stringify({ token }));

              windowReload();
            }}
          >
            Reload App
          </SButton>
        </Wrap>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
