import React, { useEffect } from "react";
import { AdminUserType } from "./AdminUser";
import styled from "styled-components/macro";
import Flames from "components/fire/Flames";
import { addSeconds, formatDistanceToNow, subSeconds } from "date-fns";
import { useMutation } from "relay-hooks";
import {
  EmailAuthGetEmailInfoMutation,
  EmailAuthGetEmailInfoMutation$data
} from "root/auth/__generated__/EmailAuthGetEmailInfoMutation.graphql";
import { getEmailInfoQL } from "root/auth/EmailAuth";
import Streak from "components/fire/Streak";
import { Button, Stack, TextField } from "@mui/material";
import Refund from "./Refund";
import { useEffectOnce, useToggle } from "react-use";
import { AdminDeviceUsers } from "./AdminDeviceUsers";
import { AdminUserDBLog } from "./AdminUserDBLog";
import Loader from "components/other/Loader";
import { AFFILIATE_MINIMUM_PAYMENT } from "sharedJs__generated/constants";
import { addPayoutMutation } from "root/profile/Affiliate";
import { AffiliateAddPayoutMutation } from "root/profile/__generated__/AffiliateAddPayoutMutation.graphql";
import { Trans } from "react-i18next";
import { snackbar } from "tools/events";
import FormatNumber from "components/formatters/FormatNumber";

const Title = styled.div`
  font-weight: bold;
  margin-top: 10px;
  font-size: 90%;
`;

const Value = styled.div``;
const SFlames = styled(Flames)`
  top: 3px;
`;
const SButton = styled(Button)`
  padding: 0;
`;
const SDiv = styled.div`
  display: flex;
  align-items: center;
`;
const SLoader = styled(Loader)`
  justify-content: left;
  padding-left: 90px;
`;

type Props = {
  user: AdminUserType;
};

export const AdminUserInfo: React.FC<Props> = ({ user }) => {
  const [getEmailInfoMutation] = useMutation<EmailAuthGetEmailInfoMutation>(getEmailInfoQL);
  const [loginOptions, setLoginOptions] = React.useState("");
  const [payout, setPayout] = React.useState("");
  const [openDeviceUsers, setOpenDeviceUsers] = useToggle(false);
  const [addPayout, { loading: addPayoutLoading }] = useMutation<AffiliateAddPayoutMutation>(addPayoutMutation);

  useEffectOnce(() => {
    if (!user.email) return;

    getEmailInfoMutation({
      variables: { email: user.email },
      onCompleted: (response: EmailAuthGetEmailInfoMutation$data) => {
        const emailInfo = response.getEmailInfo;
        const options: string[] = [];

        if (emailInfo.google) options.push("Google");
        if (emailInfo.apple) options.push("Apple");
        if (emailInfo.password) options.push("Email/Password");

        setLoginOptions(options.join(", "));
      }
    });
  });

  useEffect(() => {
    if (user.affiliateStats) {
      const newPayout = (user.affiliateStats?.payout || 0) - sumOfPayouts;
      setPayout(newPayout > 0 ? newPayout.toString() : "0");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const addPayoutHandler = async () => {
    if (!user || !payout) return;
    addPayout({
      variables: { userId: user.id, amount: Number(payout) },
      onCompleted: () => {
        snackbar(
          <Trans>
            Your request for a payout of <FormatNumber value={Number(payout)} />€ has been submitted successfully.
          </Trans>,
          {
            severity: "success"
          }
        );
      }
    });
  };

  const sumOfPayouts = user.payouts?.reduce((acc, current) => acc + (current.amount || 0), 0) || 0;
  const availablePayout = (user.affiliateStats?.payout || 0) - sumOfPayouts;

  return (
    <>
      <Title>Name</Title>
      <Value>{user.name}</Value>

      <Title>Timezone</Title>
      <Value>{user.timezone}</Value>

      <Title>
        <SButton variant="text" onClick={() => setOpenDeviceUsers()}>
          Device ID
        </SButton>
      </Title>
      <Value>
        {user.deviceOs && <>{user.deviceOs} | </>}
        {user.deviceId}
      </Value>
      {user.deviceId && openDeviceUsers && <AdminDeviceUsers deviceId={user.deviceId} />}

      <Title>Application language</Title>
      <Value>{user.appLang}</Value>

      <Title>Experience</Title>
      <Value>
        <Stack direction="row" alignItems="center">
          <SFlames strength={user.xps.total} xps={user.xps.total} />
          <Streak days={user.xps.currentStreak} />
        </Stack>
      </Value>

      <Title>Last login</Title>
      <Value>{formatDistanceToNow(subSeconds(new Date(), user.lastLoginBeforeSeconds))} ago</Value>

      <Title>Registered for</Title>
      <Value>{formatDistanceToNow(subSeconds(new Date(), user.createdBeforeSeconds))}</Value>

      <Title>Signed up by</Title>
      <Value>{loginOptions}</Value>

      <Title>Subscription</Title>
      {user?.subscriptions?.map((subscription) => (
        <Value key={subscription.transactionId}>
          {subscription?.storeId.startsWith("friend") ? "friend" : subscription?.family ? "family" : "individual"} | id:{" "}
          {subscription?.storeId} | {subscription?.platform} | until:{" "}
          {addSeconds(new Date(), subscription.timeToExpire).toDateString()}
          {subscription?.platform === "android" && <Refund transactionId={subscription?.transactionId} />}
        </Value>
      ))}
      {user.affiliate && (
        <>
          <Title>Affiliate partner</Title>
          <Value>{user.affiliate}</Value>
          <Title>Sum of payouts</Title>
          <Value>{sumOfPayouts} €</Value>
          <Title>Ready for payout (€)</Title>
          <SDiv>
            <TextField
              variant="standard"
              size="small"
              name="name"
              type="number"
              onChange={(target) => setPayout(target.currentTarget.value)}
              value={payout}
              required
            />
            {!addPayoutLoading ? (
              <Button
                onClick={addPayoutHandler}
                variant={availablePayout > AFFILIATE_MINIMUM_PAYMENT ? "contained" : "text"}
                color="primary"
                style={{ marginLeft: "0.5rem" }}
                size="small"
              >
                Issue Payout
              </Button>
            ) : (
              <SLoader size={35} />
            )}
          </SDiv>
        </>
      )}

      <AdminUserDBLog user={user} />
    </>
  );
};
