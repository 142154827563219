import styled from "styled-components/macro";
import { Trans } from "react-i18next";
import Button from "@mui/material/Button";
import { useState } from "react";
import PromoCodeDialog from "components/dialogs/PromoCodeDialog";
import { useViewerQuery } from "queries/viewerQuery";

const SButton = styled(Button)`
  font-size: 75%;
  text-decoration: underline;
  margin-top: 5px;
  color: ${({ theme }) => theme.duo.color.textGrey};
  text-transform: none;
`;

type Props = {
  className?: string;
  onSuccess?: VoidFunction;
};

const SubscriptionPromocode: React.FC<Props> = ({ className, onSuccess }) => {
  const { viewer } = useViewerQuery();

  const [open, setOpen] = useState(false);

  // not yet used because we need to communicate it to influencers first
  if (true) return null;

  if (viewer?.installReferrer) return null;

  return (
    <>
      <SButton onClick={() => setOpen(true)} className={className}>
        <Trans>I have a promocode</Trans>
      </SButton>
      <PromoCodeDialog open={open} setOpen={setOpen} onSuccess={onSuccess} />
    </>
  );
};

export default SubscriptionPromocode;
