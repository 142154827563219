import { DependencyList } from "react";
import { useLocation } from "react-router";
import { useKey } from "react-use";
import { UseEventTarget } from "react-use/lib/useEvent";
import { Handler, KeyFilter, UseKeyOptions } from "react-use/lib/useKey";
import { ADDCARDHASH } from "root/main/card/CardNewDialog";
import { ASSISTANT_HASH } from "../components/assistant/AssistantPopup";
import { SEARCH, useSearchParamTools } from "tools/searchParams";

export const useKeyMy = (key: KeyFilter, fn: Handler, opts?: UseKeyOptions<UseEventTarget>, deps?: DependencyList) => {
  const location = useLocation();
  const { getSearchParam } = useSearchParamTools();

  // we can refactor addCard and Assistant hash into searchParams once we find it useful
  const inFormDialog =
    getSearchParam(SEARCH.editCard) || location.hash === ADDCARDHASH || location.hash === ASSISTANT_HASH;

  useKey(key, (e) => (!inFormDialog ? fn(e) : undefined), opts, [...(deps ? deps : []), location]);
};
