export type HelpCenterQuestions = {
  questions: { question: string; anchor?: string; nextLevel?: HelpCenterQuestions; autoShowFeedback?: boolean }[];
};

export const helpCenterLevels: HelpCenterQuestions = {
  questions: [
    {
      question: "I have a technical problem",
      nextLevel: {
        questions: [
          {
            question: "Issue regarding subscription",
            nextLevel: {
              questions: [
                { question: "How do I cancel my subscription?", anchor: "cancel_sub" },
                { question: "How do I request a refund?", anchor: "refund" },
                { question: "My subscription isn't working", anchor: "sub_not_working" },
                {
                  question: "How do I upgrade my existing individual subscription to a Family subscription?",
                  anchor: "upgrade_family"
                },
                { question: "How do I add members to my Family subscription?", anchor: "family_plan" }
              ]
            }
          },
          { question: "The app is crashing/not loading", anchor: "app_not_working" },
          { question: "My microphone is not working", anchor: "microphone" },
          { question: "I found a bug", autoShowFeedback: true }
        ]
      }
    },
    {
      question: "I found a content error",
      nextLevel: {
        questions: [
          { question: "How do I report a mistake in the translation?", anchor: "wrong_translation" },
          { question: "The subtitles for a video are missing/wrong", anchor: "subtitles" },
          { question: "The content is in a different language", anchor: "wrong_lang" },
          { question: "How can I report an inappropriate content?", anchor: "inappropriate" }
        ]
      }
    },
    {
      question: "I have a problem with my account",
      nextLevel: {
        questions: [
          { question: "I lost access to my account", anchor: "lost_progress" },
          { question: "I can’t log in via Google", anchor: "google_login" },
          { question: "How can I change my password?", anchor: "change_password" },
          { question: "I invited a friend and didn’t get the free month", anchor: "friends_rewards" },
          { question: "Are there other payment methods available?", anchor: "payment_methods" }
        ]
      }
    },
    {
      question: "I want to ask about features",
      nextLevel: {
        questions: [
          { question: "How to sort cards?", anchor: "sort" },
          { question: "How can I filter cards?", anchor: "filter" },
          { question: "How can I create my own content?", anchor: "create_content" },
          { question: "How can I upload a video or an article?", anchor: "video_article" },
          { question: "Can I share content with others?", anchor: "share" },
          { question: "How can I import my vocabulary into the app?", anchor: "import" },
          { question: "How can I test myself from learned cards?", anchor: "learned_cards" },
          { question: "What are the advantages of the subscription?", anchor: "premium_version" },
          { question: "How does the Family subscription work?", anchor: "family_sub" },
          { question: "Is there an offline version of the app?", anchor: "offline_version" },
          { question: "How does the cards algorithm work?", anchor: "algorithm" }
        ]
      }
    },
    {
      question: "I want to help you",
      nextLevel: {
        questions: [
          { question: "I want to promote your app", anchor: "promote_app" },
          { question: "I'm interested in the affiliate program", anchor: "affiliate" }
        ]
      }
    },
    { question: "I have a different question", autoShowFeedback: true }
  ]
};
