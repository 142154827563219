/**
 * @generated SignedSource<<c019e9253e1d0d7cb020c588843b96d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CardImgPickerMutation$variables = {
  lang: string;
  term: string;
};
export type CardImgPickerMutation$data = {
  readonly flatIconsClear: boolean | null;
};
export type CardImgPickerMutation = {
  response: CardImgPickerMutation$data;
  variables: CardImgPickerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lang"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "term"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "lang",
        "variableName": "lang"
      },
      {
        "kind": "Variable",
        "name": "term",
        "variableName": "term"
      }
    ],
    "kind": "ScalarField",
    "name": "flatIconsClear",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CardImgPickerMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CardImgPickerMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "da3095020cffc40123b20056ca188fca",
    "id": null,
    "metadata": {},
    "name": "CardImgPickerMutation",
    "operationKind": "mutation",
    "text": "mutation CardImgPickerMutation(\n  $term: String!\n  $lang: String!\n) {\n  flatIconsClear(term: $term, lang: $lang)\n}\n"
  }
};
})();

(node as any).hash = "0c2bc0ba535a049475e88b62c9b09591";

export default node;
