import { useCallback, useRef } from "react";
type EventCallback = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => void;

/**
 * Custom hook to handle single click/tap and double click/tap events.
 * @param onSingleClick Callback function for single click/tap event.
 * @param onDoubleClick Callback function for double click/tap event.
 * @param delay Optional delay in milliseconds to distinguish between single and double clicks/taps. Default is 250ms.
 * @returns Event handler to use on clickable/tappable elements.
 */
function useSingleAndDoubleClick(
  onSingleClick: EventCallback,
  onDoubleClick: EventCallback,
  delay: number = 250
): EventCallback {
  const clickTimeoutId = useRef<number | null>(null);

  const eventHandler = useCallback(
    (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
      // If there's already a timeout in progress, it means this click/tap is a second click/tap (double click/tap)
      if (clickTimeoutId.current !== null) {
        // Clear the existing timeout to prevent the single click/tap action
        clearTimeout(clickTimeoutId.current);
        clickTimeoutId.current = null;
        onDoubleClick(event);
      } else {
        clickTimeoutId.current = window.setTimeout(() => {
          onSingleClick(event);
          clickTimeoutId.current = null;
        }, delay);
      }
    },
    [onSingleClick, onDoubleClick, delay]
  );

  return eventHandler;
}

export default useSingleAndDoubleClick;
