import { Button } from "@mui/material";
import { useSeeHint } from "components/hint/Hint";
import Cave from "icons/garden/Cave";
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";
import { useAnimateTexts } from "hooks/useAnimateTexts";

export const SHOW_SOURCES_QUEST = "showSourcesQuest";

const TextWrap = styled.div`
  margin: 22px 0 18px;
`;
const RewardWrap = styled.div`
  position: absolute;
  left: calc(50% - 30px);
  top: -27px;
  svg {
    position: static;
    width: 57px;
    height: 57px;
  }
`;

type Props = {};

const HelpFindCave: React.FC<Props> = () => {
  const seeHint = useSeeHint();
  const [s0, s1, s2] = useAnimateTexts({ delay: 1000 });

  return (
    <>
      <TextWrap>
        <div style={s0}>
          <Trans>Thank you</Trans>!
        </div>
        <div style={s1}>
          <Trans>Can you also help me find my cave?</Trans>
        </div>
      </TextWrap>
      <div style={s2}>
        <Button variant="contained" color="primary" onClick={() => seeHint(SHOW_SOURCES_QUEST)}>
          OK, <Trans>Show me the quest!</Trans>
        </Button>
      </div>
      <RewardWrap>
        <Cave />
      </RewardWrap>
    </>
  );
};

export default HelpFindCave;
