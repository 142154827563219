import React from "react";
import styled from "styled-components/macro";
import uniqBy from "lodash/uniqBy";
import { useViewerDecks } from "../../../queries/viewerQuery";
import { Button } from "@mui/material";
import LangMenu from "../../../components/LangMenu/LangMenu";
import { useNavigate } from "react-router";
import { flagLabel } from "../../../tools/langs";
import { useTranslation } from "react-i18next";
import { topLangs } from "../../../sharedJs__generated/langsList";
import LangButton from "../../../components/LangMenu/LangButton";
import { useGetState } from "components/ReduxProvider";
import { LIBRARY } from "../../RootRouter.config";

const Wrap = styled.div`
  text-align: center;
  button {
    margin: 3px;
  }
`;

type Props = {
  onPick?: VoidFunction;
};

const Topics: React.FC<Props> = ({ onPick }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { decks } = useViewerDecks();
  const topic = useGetState("libTopic");

  const handleChange = (lang: string) => {
    onPick && onPick();
    navigate(LIBRARY.url(lang));
    // libTopic state is changed by effect in Library
  };

  return (
    <Wrap>
      <h2>{t("Languages")}</h2>
      {decks && !decks?.some((d) => d.front === topic) && (
        <Button variant="contained" color="primary" onClick={onPick}>
          {flagLabel(topic)}
        </Button>
      )}
      {(decks ? uniqBy(decks, "front") : topLangs.map((l) => ({ front: l }))).map((d) => (
        <Button
          variant={topic === d?.front ? "contained" : "outlined"}
          color="primary"
          onClick={() => handleChange(d.front)}
          key={d.front}
        >
          {flagLabel(d.front)}
        </Button>
      ))}
      <div>
        <LangMenu
          title={<LangButton small>{t("Learn another language")}</LangButton>}
          onSelect={(lang) => handleChange(lang)}
        />
      </div>
      {/* <hr />
      <h2>Topics</h2>
      {["Will be", "Soon"].map((topic) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleChange({ duoLang: false, id: topic })}
          key={topic}
          disabled={true}
        >
          {topic}
        </Button>
      ))} */}
    </Wrap>
  );
};

export default Topics;
