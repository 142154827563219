import { ComponentProps } from "react";

const AzureIcon = (props: ComponentProps<"svg">) => (
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" {...props}>
    <path style={{ fill: "#009AD2" }} d="M150.962,379.495H0l136.118-232.954l151.777-76.943L150.962,379.495z" />
    <path
      style={{ fill: "#007CAA" }}
      d="M512,442.402H126.163v-30.034h214.419l-109.079-139.6L309.235,95.39L512,442.402z"
    />
  </svg>
);

export default AzureIcon;
