import Flames from "components/fire/Flames";
import React from "react";
import { useEffectOnce } from "react-use";
import styled, { css } from "styled-components/macro";
import { GardenXps } from "../Garden";
import { getLevel, LEVEL_PRICES } from "../levelsHelper";
import FlameProgress from "./FlameProgress";
import RewardComponent from "./RewardComponent";

const Wrap = styled.div`
  margin: 10px;
`;
const SFlameProgress = styled(FlameProgress)`
  position: relative;
  top: 0;
  left: 0;
  margin: 0 5px 0 auto;
`;
const Row = styled.div<{ $active?: boolean; $disabled?: boolean; $noBorder?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px 10px;
  border-bottom: 1px solid #eee;

  ${({ $active }) =>
    $active &&
    css`
      box-shadow: 1px 1px 6px #aaa;
      border-radius: 15px;
      border-bottom: none;
      transform: scale(1.2);
      margin: 15px;
      transform-origin: center;
      padding: 15px 20px 10px;
      ${SFlameProgress} {
        top: 3px;
      }
    `}
  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.8;
      filter: saturate(0.2);
    `}
    ${({ $noBorder }) =>
    $noBorder &&
    css`
      border-bottom: none;
    `}
`;
const SvgCell = styled.div`
  svg {
    position: static;
    width: 45px;
    height: 45px;
  }
  .frogSpecialClass {
    width: 35px;
    height: 35px;
  }
`;
const LeftCell = styled.div`
  margin-right: 25px;
`;
const SFlames = styled(Flames)`
  margin: 0 10px 0 auto;
`;

type Props = {
  items: React.ReactNode[];
  nextItemIndex: number;
  xps: GardenXps;
};

const RewardsList: React.FC<Props> = ({ items, nextItemIndex, xps }) => {
  const { level } = getLevel(xps.total);
  const activeRef = React.useRef<any>(null);

  useEffectOnce(() => {
    setTimeout(() => activeRef.current?.scrollIntoView({ behavior: "smooth", block: "center" }), 10);
  });

  return (
    <Wrap>
      {items.map((component, i) => (
        <Row
          ref={level === i ? activeRef : undefined}
          key={i}
          $active={level === i}
          $disabled={level < i}
          $noBorder={level - 1 === i || i === items.length - 1}
        >
          <LeftCell>
            {i === nextItemIndex ? (
              <SFlameProgress xps={xps} numberInside={LEVEL_PRICES[i].totalXp} />
            ) : (
              <SFlames strength={4} xps={LEVEL_PRICES[i].totalXp} />
            )}
          </LeftCell>

          <SvgCell>
            <RewardComponent itemIndex={i} component={component} />
          </SvgCell>
        </Row>
      ))}
    </Wrap>
  );
};

export default RewardsList;
