// import * as Sentry from "@sentry/browser";

export const dividePrice = (price: string, priceMicros: number, divider: number) => {
  const num = (price.match(/(([0-9]+\s)?[0-9,.]+)/gi) || [""])[0];

  let divided = priceMicros / 1000000 / divider;
  const dividedStr = String(divided).replace(".", ",");
  const dotPos = dividedStr.indexOf(",");
  const arranged = dividedStr.substring(0, dotPos === -1 ? undefined : dotPos < 3 ? 4 : dotPos);
  const formatted =
    arranged.indexOf(",") !== -1
      ? arranged
      : arranged
          .split("")
          .map((num, i) => (i < 3 ? num : "0"))
          .join("");

  if (!num) {
    // Sentry.captureMessage("Coudn't read price [" + price + "]");
    return formatted;
  }

  return price.replace(num, formatted);
};
