/**
 * @generated SignedSource<<2a65572986ee3557def478bc510f4de8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Kind = "article" | "set" | "video" | "%future added value";
export type FormMoreQuery$variables = {
  langNative?: string | null;
  sourceId: string;
};
export type FormMoreQuery$data = {
  readonly node: {
    readonly id?: string;
    readonly kind?: Kind;
    readonly mine?: boolean;
    readonly name?: string;
    readonly private?: boolean;
    readonly uri?: string;
  } | null;
};
export type FormMoreQuery = {
  response: FormMoreQuery$data;
  variables: FormMoreQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "langNative"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "sourceId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "langNative",
      "variableName": "langNative"
    }
  ],
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uri",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "private",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mine",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FormMoreQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "type": "Source",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FormMoreQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "type": "Source",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d88380e9792794f2e581b8de425cb3f3",
    "id": null,
    "metadata": {},
    "name": "FormMoreQuery",
    "operationKind": "query",
    "text": "query FormMoreQuery(\n  $sourceId: ID!\n  $langNative: String\n) {\n  node(id: $sourceId) {\n    __typename\n    ... on Source {\n      id\n      name(langNative: $langNative)\n      uri\n      private\n      kind\n      mine\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "542c5157414337ae46f6ebfa4fa03cf4";

export default node;
