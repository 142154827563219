import React from "react";
import { Backdrop } from "@mui/material";
import { Portal } from "@mui/base";
import Loader from "components/other/Loader";
import { useGetState } from "components/ReduxProvider";

type Props = {};

const LoaderGlobal: React.FC<Props> = () => {
  const loaderGlobal = useGetState("loaderGlobal");
  if (!loaderGlobal) return null;

  return (
    <Portal>
      <Backdrop open={true} style={{ zIndex: 1500 }}>
        <Loader />
      </Backdrop>
    </Portal>
  );
};

export default LoaderGlobal;
