import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { writeAdNameMutation } from "./__generated__/writeAdNameMutation.graphql";

export const writeAdNameQL = graphql`
  mutation writeAdNameMutation($adName: String!) {
    writeAdName(adName: $adName)
  }
`;

export const useWriteAdName = () => {
  const [mutate] = useMutation<writeAdNameMutation>(writeAdNameQL);

  return (adName: string) => {
    mutate({ variables: { adName } });
  };
};
