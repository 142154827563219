import { HeaderLayout } from "components/header/Header";
import Row from "components/other/Row";
import React from "react";
import { getSourceEditUrl } from "root/library/library/libraryUrls";
import { CARDS_ACTION } from "root/library/sourceEdit/SourceEdit";
import styled from "styled-components/macro";
import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ImportRow from "./ImportRow";
import { appLangs } from "sharedJs__generated/langsList";
import UnreviewedCount from "./UnreviewedCount";
import { Kind } from "queries/sources/__generated__/LibraryRefetchQuery.graphql";
import ExportRow from "./ExportRow";

const Rows = styled.div`
  svg {
    margin-right: 10px;
  }
`;

type Props = {};

const AdminTranslations: React.FC<Props> = () => {
  const appUrl = { id: "U291cmNlOjJjNTE1ZWZhLTg5NWEtNGNlNi04ZTc5LTE5OGQwYzNmNzAwYQ==", kind: "set" as Kind };
  const mailUrl = { id: "U291cmNlOjJjZjQ0MjIxLWFiYTUtNDc5ZC1iNzFiLWVjZGM1ODdkYjJjNg==", kind: "set" as Kind };

  const langs = appLangs.filter((l) => !["en", "cs"].includes(l));

  return (
    <HeaderLayout title="Translations">
      <Rows>
        <Row to={getSourceEditUrl(appUrl, CARDS_ACTION)}>
          <AppShortcutIcon />
          <div>
            <strong>App translates</strong>
            <div>
              {langs.map((l) => (
                <UnreviewedCount key={l} sourceUrl={"app_translates"} lang={l} />
              ))}
            </div>
          </div>
        </Row>
        <Row to={getSourceEditUrl(mailUrl, CARDS_ACTION)}>
          <MailOutlineIcon />
          <div>
            <strong>Mail translates</strong>
            <div>
              {langs.map((l) => (
                <UnreviewedCount key={l} sourceUrl={"mail_translates"} lang={l} />
              ))}
            </div>
          </div>
        </Row>

        <ExportRow />

        <ImportRow />
      </Rows>
    </HeaderLayout>
  );
};

export default AdminTranslations;
