import React from "react";
import styled from "styled-components/macro";
import SpeedIcon from "@mui/icons-material/Speed";
import { Tooltip } from "@mui/material";
import { formatDistance } from "date-fns";

const Wrap = styled.div<{ resolved?: boolean }>`
  background: ${({ resolved }) => (resolved ? "lime" : "orange")};
  border-radius: 12px;
  font-size: 18px;
  margin-left: 3px;
  display: flex;
  padding: 1px 2px 0;
  color: white;
`;
const SSpeedIcon = styled(SpeedIcon)`
  font-size: 20px;
`;

type Props = {
  testData: {
    runningSince: number;
    revenueOn: number;
    revenueOff: number;
    percent: number;
    accuracy: number;
  };
};

const ContentTest: React.FC<Props> = ({ testData: { runningSince, revenueOn, revenueOff, percent, accuracy } }) => {
  const sureWinner = revenueOn > 20_000 && Math.abs(percent) - Math.abs(accuracy) > 0;
  const resolved = !!(sureWinner || revenueOn > 150_000);

  return (
    <Tooltip
      title={
        <>
          <div>{formatDistance(0, Date.now() - runningSince)}</div>
          <div>{revenueOn < 10_000 ? "n/a" : `${percent}% ±${accuracy}%`}</div>
          <div>
            {revenueOn} vs {revenueOff} CZK
          </div>
          <div>
            {!resolved ? (
              <span style={{ color: "orange" }}>Collecting data</span>
            ) : sureWinner ? (
              percent > 0 ? (
                <span style={{ color: "lime" }}> Win -&gt; top-up this source</span>
              ) : (
                <span style={{ color: "lightsalmon" }}>Loss -&gt; don't top-up this source</span>
              )
            ) : Math.abs(percent) >= 3 ? (
              percent > 0 ? (
                <span style={{ color: "lime" }}> Draw, close win, you can use this source if you like)</span>
              ) : (
                <span style={{ color: "lightsalmon" }}>Draw, close loss</span>
              )
            ) : (
              <>Draw</>
            )}
          </div>
        </>
      }
    >
      <Wrap
        resolved={resolved}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <SSpeedIcon />
      </Wrap>
    </Tooltip>
  );
};

export default ContentTest;
