import { Button } from "@mui/material";
import Flames from "components/fire/Flames";
import { useSetState } from "components/ReduxProvider";
import React from "react";
import { Trans } from "react-i18next";
import { useEffectOnce } from "react-use";
import styled, { keyframes, css } from "styled-components/macro";
import { dispatchEvent, EVENT } from "tools/events";
import { RewardWrap } from "../QuestReward";

const ANIMDUR = 1000;

const FlamePlacingKF = keyframes`
  50% { transform: scale(2) }
  100% { transform: scale(1.1) }
`;
const TextWrap = styled.div`
  margin: 20px 0 14px;
`;
const SFlames = styled(Flames)<{ clicked?: boolean }>`
  position: relative;
  left: 0;
  top: 0;
  margin: 0px 4px -6px 2px;
  display: inline-block;
  transition: all ${ANIMDUR}ms ease-in-out;
  ${({ clicked }) =>
    clicked &&
    css`
      animation: ${FlamePlacingKF} ${ANIMDUR - 100}ms;
    `}
`;
export const Appearing = styled.div<{ appear: boolean }>`
  transition: all 1000ms;
  opacity: ${({ appear }) => (appear ? 1 : 0)};
  margin: 8px 0;
`;

type Props = {};

const QuestsExplained: React.FC<Props> = () => {
  const [appearing, setAppearing] = React.useState<0 | 1 | 2 | 3>(0);
  const setQuestsExplained = useSetState("questsExplained");
  const flamesRef = React.useRef<HTMLDivElement>(null);
  const placeholderRef = React.useRef<HTMLDivElement>(null);
  const [clicked, setClicked] = React.useState(false);

  useEffectOnce(() => {
    dispatchEvent(EVENT.updateMain);
    setTimeout(() => setAppearing(1), 100);
    setTimeout(() => setAppearing(2), 1300);
    setTimeout(() => setAppearing(3), 2500);
  });

  const handleOk = () => {
    setClicked(true);
    const f1 = flamesRef.current?.getBoundingClientRect();
    const f2 = placeholderRef.current?.getBoundingClientRect();
    if (f1 && f2) {
      flamesRef.current!.style.left = f2.left - f1.left + 4 + "px";
      flamesRef.current!.style.top = f2.top - f1.top + 4 + "px";
    }
    setTimeout(() => setQuestsExplained(true), ANIMDUR);
  };

  return (
    <>
      <TextWrap>
        <Appearing appear={appearing >= 1}>
          <Trans>
            I need you to gain <SFlames clicked={clicked} ref={flamesRef} strength={4} xps={10} /> flames.
          </Trans>
        </Appearing>
        <Appearing appear={appearing >= 2 && !clicked}>
          <Trans>You get flames by learning or completing quests.</Trans>
        </Appearing>
      </TextWrap>
      <Appearing appear={appearing >= 3 && !clicked}>
        <Button variant="contained" color="primary" onClick={handleOk}>
          OK
        </Button>
      </Appearing>

      <RewardWrap ref={placeholderRef} />
    </>
  );
};

export default QuestsExplained;
