import React from "react";
import DialogFullWindow from "components/other/DialogFullWindow";
import GeneratedAppLangWarning from "root/other/GeneratedAppLangWarning";

const GeneratedAppLangDialog: React.FC = () => {
  const [warningOpen, setWarningOpen] = React.useState(true);

  return (
    <DialogFullWindow open={warningOpen} background>
      <GeneratedAppLangWarning onSave={() => setWarningOpen(false)} />
    </DialogFullWindow>
  );
};

export default GeneratedAppLangDialog;
