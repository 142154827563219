import React from "react";
import styled from "styled-components/macro";
import MuiSelect, { SelectProps } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import { useField } from "formik";

const SFormControl = styled(FormControl)`
  margin: 0 0 15px;
`;
const SSelect = styled(MuiSelect)`
  text-align: left;
  &&&,
  .MuiSelect-select:focus {
    background: white;
  }
`;

type TProps = SelectProps & {
  helperText?: string;
  options: { value: any; label: any; icon?: React.ReactNode }[];
};

export const Select: React.FC<TProps> = ({ options, helperText, className, ...props }) => (
  <SFormControl fullWidth variant="filled" className={className}>
    <InputLabel id={`${props.name}-label`}>{props.label}</InputLabel>
    <SSelect {...props} variant={props.variant || "filled"} fullWidth={props.fullWidth === false ? false : true}>
      {options.map((o) => (
        <MenuItem value={o.value} key={o.value}>
          {o.icon && <>{o.icon}&nbsp;&nbsp;</>}
          {o.label}
        </MenuItem>
      ))}
    </SSelect>
    {helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
  </SFormControl>
);

type FProps = TProps;

export const FSelect: React.FC<FProps> = (props) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const error = Boolean(meta.touched && meta.error);
  return (
    <Select
      {...field}
      {...props}
      error={error}
      labelId={`${props.name}-label`}
      helperText={error ? meta.error : props.helperText}
    />
  );
};
