import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";
import { Button } from "@mui/material";
import { useSeeHint } from "components/hint/Hint";
import FolderIcon from "@mui/icons-material/Folder";
import FilterIcon from "icons/FilterIcon";
import StarIcon from "components/other/StarIcon";

export const SETINTROHINT = "setIntro";

const Icon = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 50px;
  padding: 10px;
  width: 60px;
  height: 60px;
  box-shadow: 1px 1px 6px #0003;
`;
const SFilterIcon = styled(FilterIcon)`
  width: 30px;
  height: 30px;
  margin: 8px 6px;
  fill: ${({ theme }) => theme.palette.primary.main};
`;
const SFolderIcon = styled(FolderIcon)`
  width: 36px;
  height: 36px;
  margin: 2px 4px;
  color: ${({ theme }) => theme.palette.primary.main};
`;
const StarSmall = styled(StarIcon)`
  && {
    font-size: 18px;
  }
`;
const Premium = styled.div`
  color: ${({ theme }) => theme.duo.color.primary};
  line-height: 1.7em;
`;

type Props = {};

const SetIntro: React.FC<Props> = () => {
  const seeHint = useSeeHint();

  return (
    <>
      <div />
      <div />
      <div />
      <Icon>
        <SFolderIcon />
      </Icon>
      <Trans parent="p">You can categorise your cards</Trans>

      <div />
      <Icon>
        <SFilterIcon />
        <StarSmall unconditional />
      </Icon>
      <div>
        <Trans parent="strong">and learn the vocabulary by topics</Trans>
        <Premium>
          * <Trans>premium feature</Trans>
        </Premium>
      </div>

      <div />

      <Button color="primary" variant="contained" onClick={() => seeHint(SETINTROHINT)}>
        <Trans>Continue</Trans>
      </Button>
      <div />
    </>
  );
};

export default SetIntro;
