import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styled, { css } from "styled-components/macro";
import { Grid, IconButton, Paper } from "@mui/material";
import { useViewerDecks } from "../../../../queries/viewerQuery";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "../../../../styled/TextField";
import CardListItems, { scrollToListEnd } from "./CardListItems";
import { useDebounce, useEvent } from "react-use";
import { CardState } from "../../../../queries/cards/__generated__/cardsQuery.graphql";
import { EVENT } from "../../../../tools/events";
import { Select } from "../../../../styled/Select";
import { getCardStates } from "../CardState";
import { useIsOffline } from "tools/offline";
import { useOpenAssistant } from "components/assistant/assistantHooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSolidMammoth } from "@awesome.me/kit-55349eb283/icons/kit/custom";
import { AddButton, SFab } from "./AddButton";

const Wrap = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: stretch;
  padding: 0 20px 20px;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}
`;
const SPaper = styled(Paper)<{ disabled: boolean }>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 56px;
  padding: 15px;
  margin: 0 15px 0 0;
  font-size: 90%;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0;
      pointer-events: none;
    `};
`;
const Search = styled(IconButton)`
  margin: 1px -5px 0 auto;
  opacity: 0.5;
  border-left: 1px solid #ddd;
  padding-left: 10px;
  border-radius: 0;
  svg {
    font-size: 22px;
  }
`;
const SearchField = styled(TextField)`
  padding: 0;
  .MuiFilledInput-root {
    background: ${({ theme }) => theme.duo.color.white};
  }
`;
const SGrid = styled(Grid)`
  padding: 0px 20px 0;
`;

type Props = {
  onClick: VoidFunction;
  showCards: boolean;
  setShowCards: (showCards: boolean) => void;
};

export type CardListOpenDetail = {
  cardState: CardState;
};

const CardList: React.FC<Props> = ({ onClick, showCards, setShowCards }) => {
  const { deck } = useViewerDecks();
  const { t } = useTranslation();
  const [searchOn, setSearchOn] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [cardState, setCardState] = React.useState<CardState | null>(null);
  const [debouncedSearch, setDebouncedSearch] = React.useState("");
  const cardStates = getCardStates(t);
  const isOffline = useIsOffline();
  const openAssistant = useOpenAssistant();

  const handleExternalOpen = React.useCallback(
    (e: CustomEvent<CardListOpenDetail>) => {
      setCardState(e.detail.cardState);
      setShowCards(true);
      setSearchOn(true);
      scrollToListEnd();
    },
    [setShowCards]
  );
  useEvent(EVENT.openCardList, handleExternalOpen);

  useDebounce(
    () => {
      setDebouncedSearch(searchText);
      if (searchText && !showCards) onClick();
    },
    600,
    [searchText]
  );

  const handleCardStateChange = (event) => {
    setCardState(event.target.value ? (event.target.value as CardState) : null);
    if (!showCards) onClick();
  };

  return (
    <>
      <div id="scrollToMe" />
      <Wrap disabled={isOffline}>
        <SPaper
          disabled={deck?.stats.total === 0}
          onClick={() => {
            showCards && setSearchOn(false);
            onClick();
          }}
        >
          {showCards ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} &nbsp;{t("Cards")}{" "}
          <Search
            size="small"
            onClick={(e) => {
              setSearchOn(!searchOn);
              setSearchText("");
              e.stopPropagation();
            }}
          >
            <SearchIcon />
          </Search>
        </SPaper>
        <SFab $secondary color="primary" onClick={() => openAssistant()}>
          <FontAwesomeIcon icon={faSolidMammoth} />
        </SFab>
        <AddButton />
      </Wrap>

      {(searchOn || cardState) && !isOffline && (
        <SGrid container spacing={2}>
          <Grid item xs={6}>
            <Select
              size="small"
              label={t("Card state")}
              onChange={handleCardStateChange}
              options={cardStates}
              value={cardState ?? ""}
            />
          </Grid>
          <Grid item xs={6}>
            <SearchField
              size="small"
              value={searchText}
              autoFocus
              label={t("Search")}
              onChange={(e) => setSearchText(e.currentTarget.value)}
            />
          </Grid>
        </SGrid>
      )}

      {showCards && !isOffline && <CardListItems searchText={debouncedSearch} cardState={cardState} />}
    </>
  );
};

export default CardList;
