import HintTooltip from "components/other/HintTooltip";
import styled from "styled-components/macro";

export const LONGPRESSHINT = "longpress";
export const LONGPRESSHINT2 = "longpress2";

const args = {
  title: "Press and hold the play button to slow down the pronunciation"
};

const SpeechHintTooltip = styled(HintTooltip).attrs(args)`
  .MuiTooltip-tooltipArrow {
    top: 10px;
  }
  .MuiTooltip-arrow {
    transform: translateX(-5px);
  }
`;

export default SpeechHintTooltip;
