import memoizeOne from "memoize-one";

const LEVELS_MAX = 35;
export const FLAME_INDEXES = [0, 13, 24];
export const SUN_INDEX = 2;

// incrementing xps table
// docs: https://docs.google.com/spreadsheets/d/1ZZGwjQlGezB-S992ae3aNGBwuVsPohLtQjsoenzWwmI/edit#gid=0
let levelXp = 0;
let totalXp = 0;
export let LEVEL_PRICES: { levelXp: number; totalXp: number }[] = [];
for (let level = 0; level < LEVELS_MAX; level++) {
  if (levelXp < 40) {
    levelXp += 5;
  } else if (levelXp < 100) {
    levelXp += 10;
  } else {
    levelXp += 20;
  }
  totalXp += levelXp;

  LEVEL_PRICES[level] = { levelXp, totalXp };
}

export const getLevel = memoizeOne((viewerXps: number) => {
  let level = LEVEL_PRICES.findIndex((level) => level.totalXp > viewerXps);
  if (level === -1) {
    return { level: LEVELS_MAX, progressXps: null, nextLevelXps: null };
  }

  const nextLevelXps = LEVEL_PRICES[level].levelXp;
  const progressXps = viewerXps - (level ? LEVEL_PRICES[level - 1].totalXp : 0);

  return { level, progressXps, nextLevelXps };
});
