import { LinearProgress } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";

const SLinearProgress = styled(LinearProgress)<{ show?: boolean }>`
  height: 1px;
  position: relative;
  top: -13px;
`;
const SWrap = styled.div<{ show?: boolean }>`
  height: 0;
`;

export type Props = {
  loading: boolean;
};

const LoadingBar: React.FC<Props> = ({ loading }) => {
  return <SWrap>{loading && <SLinearProgress />}</SWrap>;
};

export default LoadingBar;
