import React from "react";
import { AssistantMode } from "../__generated__/AssistantMutation.graphql";
import { useAIRequest } from "../../../hooks/useAIRequest";
import { langs } from "../../../sharedJs__generated/langsList";
import * as Sentry from "@sentry/browser";
import { SuggestionsTranslations } from "../../../sharedJs__generated/constants";
import { useGetState, useSetState } from "components/ReduxProvider";
import {
  AI_STORY_SUPPORTED_LANGS,
  SuggestionDefinition,
  suggestionsDefinition,
  TranslatedSuggestion,
  useFlattenSuggestions
} from "./suggestionsDefinitions";

// chooses which suggestions should be shown and pick them from translations (all translations are grouped together)
export const useGetSuggestions = (
  chatMode: AssistantMode,
  messagesCount: number,
  nativeLang: string,
  targetLang: string
): { isLoading: boolean; translatedSuggestions: TranslatedSuggestion[] } => {
  const allTranslatedSuggestions = useAllSuggestionsTranslations(nativeLang, targetLang);
  const [currentSuggestions, setCurrentSuggestions] = React.useState<TranslatedSuggestion[]>([]);

  React.useEffect(() => {
    let currentDefinitions: SuggestionDefinition[] = [];
    let timeoutId;
    if (messagesCount === 0 && chatMode === "assistant") {
      currentDefinitions = suggestionsDefinition["topLevel"];

      if (!AI_STORY_SUPPORTED_LANGS.includes(targetLang)) {
        currentDefinitions = currentDefinitions.filter((def) => def.switchToMode !== "aiStory");
      }

      setCurrentSuggestions(pickSuggestionsTranslations(currentDefinitions, allTranslatedSuggestions));
    }

    if (chatMode === "aiStory" && messagesCount >= 2) {
      timeoutId = setTimeout(() => {
        currentDefinitions = suggestionsDefinition["aiStory"];
        setCurrentSuggestions(pickSuggestionsTranslations(currentDefinitions, allTranslatedSuggestions));
      }, 5000);
    }

    if (!currentDefinitions.length) {
      setCurrentSuggestions([]);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [chatMode, messagesCount, allTranslatedSuggestions, targetLang]);

  return { isLoading: !currentSuggestions.length, translatedSuggestions: currentSuggestions };
};

// bulks all possible suggestions together and translates to two languages
const useAllSuggestionsTranslations = (nativeLang: string, targetLang: string): TranslatedSuggestion[] => {
  const nativeTranslations = useGetSuggestionsInLang(nativeLang);
  const targetTranslations = useGetSuggestionsInLang(targetLang);
  const allChatSuggestions = useFlattenSuggestions();
  const [translatedSuggestions, setTranslatedSuggestions] = React.useState<TranslatedSuggestion[]>([]);

  React.useEffect(() => {
    if (!nativeTranslations.length || !targetTranslations.length) {
      setTranslatedSuggestions([]);
      return;
    }

    const newTranslatedSuggestions: TranslatedSuggestion[] = allChatSuggestions.map((suggestion) => ({
      ...suggestion,
      nativeText:
        nativeTranslations.find((translation) => translation.originalText === suggestion.text)?.translatedText || "",
      targetText:
        targetTranslations.find((translation) => translation.originalText === suggestion.text)?.translatedText || ""
    }));

    setTranslatedSuggestions(newTranslatedSuggestions);
  }, [allChatSuggestions, nativeTranslations, targetTranslations]);

  return translatedSuggestions;
};

// translates all possible suggestions to specified lang
const useGetSuggestionsInLang = (lang: string): SuggestionsTranslations => {
  const allChatSuggestions = useFlattenSuggestions();
  const suggestionsTranslations = useGetState("suggestionsTranslations");
  const setSuggestionsTranslations = useSetState("suggestionsTranslations");
  const [translations, setTranslations] = React.useState<SuggestionsTranslations>([]);
  const allTexts = React.useMemo(() => allChatSuggestions.map((suggestion) => suggestion.text), [allChatSuggestions]);

  const { result: translationsJSON } = useAIRequest(
    "assistant suggestions",
    [
      { key: "toLang", value: langs[lang].label },
      { key: "suggestions", value: JSON.stringify(allTexts) }
    ],
    lang === "en" || !!suggestionsTranslations?.[lang]
  );

  React.useEffect(() => {
    let translations: SuggestionsTranslations = [];

    try {
      if (translationsJSON) {
        translations = JSON.parse(translationsJSON);
      }
    } catch (e) {
      Sentry.captureException(e);
    }

    if (lang === "en") {
      translations = allTexts.map((originalText) => ({ originalText, translatedText: originalText }));
    }

    if (suggestionsTranslations?.[lang]) {
      translations = suggestionsTranslations[lang];
    }

    if (translations.length && !suggestionsTranslations?.[lang]) {
      setSuggestionsTranslations({ ...suggestionsTranslations, [lang]: translations });
    }

    setTranslations(translations);
  }, [lang, suggestionsTranslations, translationsJSON, allTexts, setSuggestionsTranslations]);

  return translations;
};

// returns only those translations that are requested in definitions array
export function pickSuggestionsTranslations(
  definitions: SuggestionDefinition[],
  translations: TranslatedSuggestion[]
): TranslatedSuggestion[] {
  const translatedSuggestions: TranslatedSuggestion[] = [];
  definitions.forEach((currentSuggestion) => {
    const translatedSuggestion = translations.find((translation: TranslatedSuggestion) => {
      return translation.text === currentSuggestion.text && translation.behavior === currentSuggestion.behavior;
    });

    if (translatedSuggestion) {
      translatedSuggestions.push(translatedSuggestion);
    }
  });

  return translatedSuggestions;
}
