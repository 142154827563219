import React from "react";
import styled from "styled-components/macro";
import { HeaderLayout } from "../../../components/header/Header";
import LibraryMenu from "../library/LibraryMenu";
import { Button } from "@mui/material";
import { SOURCENEW } from "../library/libraryUrls";
import { useNavigate } from "react-router";
import Loader from "../../../components/other/Loader";
import Hint from "../../../components/hint/Hint";
import { useTranslation } from "react-i18next";
import LibraryList from "../library/LibraryList";
import { usePagination, useQuery } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { libraryQuery } from "../../../queries/sources/__generated__/libraryQuery.graphql";
import { libraryQuery_Public$key } from "../../../queries/sources/__generated__/libraryQuery_Public.graphql";
import { sourcesFrg } from "../../../queries/sources/libraryQuery";
import { MySourcesQuery } from "./__generated__/MySourcesQuery.graphql";

const query = graphql`
  query MySourcesQuery($count: Int!, $cursor: String) {
    mySources: public {
      ...libraryQuery_Public @arguments(count: $count, cursor: $cursor, mine: true)
    }
  }
`;

const Wrap = styled.div`
  padding: 10px 0;
  height: 100%;
`;
const CreateWrap = styled.div`
  margin: 20px;
  text-align: center;
`;

const MySources: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, isLoading } = useQuery<MySourcesQuery>(
    query,
    {
      count: 1000 // ...WORKAROUND because paginating does not work yet...
    },
    { fetchPolicy: "store-and-network" }
  );
  const mySources = usePagination<libraryQuery, libraryQuery_Public$key>(
    sourcesFrg,
    data?.mySources as libraryQuery_Public$key
  );

  return (
    <HeaderLayout right={<LibraryMenu />} title={t("My sources")}>
      <Wrap>
        {isLoading ? (
          <Loader />
        ) : mySources?.data?.sources?.edges?.length ? (
          <LibraryList sources={mySources} />
        ) : (
          <CreateWrap>
            <p>{t("You didn't create any Article or Set of cards yet.")}</p>
            <Button
              variant="outlined"
              color="secondary"
              style={{ background: "white" }}
              onClick={() => navigate(`${SOURCENEW.url()}`)}
            >
              {t("Create Set / Article")}
            </Button>
            <br />
            <br />
            <Hint name="sourceCreate">
              {t("Creating Sets / Articles is for sharing materials with your students or study-group.")}
            </Hint>
          </CreateWrap>
        )}
      </Wrap>
    </HeaderLayout>
  );
};

export default MySources;
