import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { Button } from "@mui/material";
import { useMutation } from "relay-hooks";
import { RefundMutation } from "./__generated__/RefundMutation.graphql";

const refundQL = graphql`
  mutation RefundMutation($transactionId: String!) {
    refund(transactionId: $transactionId) {
      subscriptions {
        transactionId
        timeToExpire
        platform
        storeId
        family
      }
    }
  }
`;

type Props = {
  transactionId: string;
};

const Refund: React.FC<Props> = ({ transactionId }) => {
  const [refund, { loading }] = useMutation<RefundMutation>(refundQL);

  return (
    <Button
      disabled={loading}
      size="small"
      sx={{ padding: "3px 15px" }}
      onClick={() => {
        if ((window as any).confirm("Are you sure? This can't be undone!")) {
          refund({ variables: { transactionId } });
        }
      }}
    >
      refund
    </Button>
  );
};

export default Refund;
