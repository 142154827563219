import React from "react";
import styled from "styled-components/macro";
import { useNavigate, useLocation } from "react-router-dom";
import CardForm from "./CardForm";
import ExtensionOutButton from "./ExtensionOutButton";
import { useCardCreate } from "queries/cards/cardCreate";
import { useViewerDecks } from "../../../queries/viewerQuery";
import DialogFullWindow from "../../../components/other/DialogFullWindow";
import { CardValues } from "components/ReduxProvider";

export const ADDCARDHASH = "#newcard";

const Wrap = styled.div`
  padding: 20px;
`;

const CardNewDialog: React.FC<{}> = () => {
  const { deck } = useViewerDecks();
  const navigate = useNavigate();
  const { cardCreate } = useCardCreate();
  const location = useLocation();

  const handleSave = (values: CardValues) => {
    if (cardCreate({ ...values, deckId: deck!.id })) {
      navigate(-1);
    } else {
      return false;
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  if (!deck) return null;

  return (
    <DialogFullWindow open={location.hash === ADDCARDHASH} onClose={handleClose} background>
      <Wrap>
        <CardForm onSave={handleSave} fromLang={deck.front} toLang={deck.back} checkForDuplicates={true} />
        <ExtensionOutButton />
      </Wrap>
    </DialogFullWindow>
  );
};

export default CardNewDialog;
