import { useMutation } from "relay-hooks";
import { useCallback } from "react";
import { addReportedIssueQL } from "../queries/reportIssue/addReportedIssueMutation";
import { addReportedIssueMutation } from "../queries/reportIssue/__generated__/addReportedIssueMutation.graphql";

type ReportIssueParams = {
  sCardId: string;
  sBackId: string | null;
  langBack: string | null;
  reasons: string[];
};

function useReportIssue() {
  const [doReportIssue] = useMutation<addReportedIssueMutation>(addReportedIssueQL);

  const reportIssue = useCallback(
    (params: ReportIssueParams) => {
      doReportIssue({
        variables: {
          ...params
        }
      });
      if (process.env.NODE_ENV !== "production") {
        console.info("Report Issue", params);
      }
    },
    [doReportIssue]
  );

  return reportIssue;
}

export default useReportIssue;
