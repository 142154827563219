import React from "react";
import { useQuery } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { sourceQuery } from "./__generated__/sourceQuery.graphql";
import { useNavigate } from "react-router";
import { useLibraryUrl } from "../../root/RootRouter";
import { FetchPolicy } from "relay-hooks/lib/RelayHooksTypes";

// TODO: pass only deckId as second arg to sourceByUri and pass __deck prop to children or read from query somehow
export const SourceQuery = graphql`
  query sourceQuery($uriOrId: String!, $backLang: String, $deckId: ID) {
    sourceByUriOrId(uriOrId: $uriOrId) {
      id
      uri
      name(langNative: $backLang)
      seo(langNative: $backLang)
      text
      duoLang
      kind
      course
      private
      lang
      source
      mine
      copySource
      isCopied
      authorId
      authorName
      difficulty
      videoStart
      videoLang
      videoDuration
      captions
      imageId
      image {
        url
        authorName
        authorApiName
      }
      rating {
        rating
        continueFrom
        finished
      }
      sCards(backLang: $backLang) {
        id
        front
        hint
        pronunciation
        back(langNative: $backLang) {
          sBackId
          value
          translated
        }
        theory(langNative: $backLang) {
          sCardId
          theory
          theoryIsChecked
          theoryNative
          theoryNativeIsChecked
          theoryEn
          lang
          langNative
          canEdit
        }
        svg {
          flatId
          url
        }
        isInMyDeck(deckId: $deckId)
      }
    }
  }
`;

export const useSourceQuery = (
  uriOrId: string,
  deck?: { id?: string; back?: string | null },
  fetchPolicy?: FetchPolicy
) => {
  const navigate = useNavigate();
  const libraryUrl = useLibraryUrl();
  const backLang = deck?.back;
  const deckId = deck?.id;
  const { data, error } = useQuery<sourceQuery>(
    SourceQuery,
    { uriOrId, backLang, deckId },
    // without network only it sometimes probablytook result from different query without translations (during creating own set)
    { skip: !uriOrId, fetchPolicy: fetchPolicy || "network-only" }
  );

  React.useEffect(() => {
    if (error) {
      navigate(libraryUrl, { replace: true });
    }
  }, [error, navigate, libraryUrl]);

  return data?.sourceByUriOrId;
};
