import React from "react";
import { Helmet } from "react-helmet";

type Props = {
  title?: string;
  image?: string;
  description?: string;
};

const Head: React.FC<Props> = (p) => {
  const title = p.title ? `${p.title} | DuoCards` : null;

  return (
    <Helmet>
      {title && <title>{title}</title>}
      {p.description && (
        <meta
          name="description"
          content={p.description.substr(0, 155) + (p.description.length > 156 ? "..." : "")}
        ></meta>
      )}

      {/* OPEN-GRAPH
      doesn't work atm, but maybe in future, or with usage of some SSR it will be */}
      {title && <meta property="og:title" content={title}></meta>}
      {p.image && <meta property="og:image" content={p.image}></meta>}
      {/* OPEN-GRAPH-END  */}
    </Helmet>
  );
};

export default Head;
