/**
 * @generated SignedSource<<e82198e8947003374969669ebca02556>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useTranslatesQuery$variables = {
  texts: ReadonlyArray<string>;
  toLang: string;
};
export type useTranslatesQuery$data = {
  readonly translateBulk: ReadonlyArray<string>;
};
export type useTranslatesQuery = {
  response: useTranslatesQuery$data;
  variables: useTranslatesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "texts"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "toLang"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "texts",
        "variableName": "texts"
      },
      {
        "kind": "Variable",
        "name": "toLang",
        "variableName": "toLang"
      }
    ],
    "kind": "ScalarField",
    "name": "translateBulk",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useTranslatesQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useTranslatesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "dccffbb5c9dc50b9c7ad168c4d834262",
    "id": null,
    "metadata": {},
    "name": "useTranslatesQuery",
    "operationKind": "query",
    "text": "query useTranslatesQuery(\n  $toLang: String!\n  $texts: [String!]!\n) {\n  translateBulk(toLang: $toLang, texts: $texts)\n}\n"
  }
};
})();

(node as any).hash = "d75a412b9edae03c8570023de9569c7a";

export default node;
