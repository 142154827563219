/**
 * @generated SignedSource<<d70d5d96f28bac1035bc50057f315d9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type VoiceGender = "FEMALE" | "MALE" | "%future added value";
export type VoicePlatform = "azure" | "google" | "openai" | "%future added value";
export type VoiceType = "Neural" | "Standard" | "%future added value";
export type VoicePickerItemVoicesQuery$variables = {
  lang: string;
};
export type VoicePickerItemVoicesQuery$data = {
  readonly ttsVoices: {
    readonly sampleTexts: ReadonlyArray<string>;
    readonly voices: ReadonlyArray<{
      readonly gender: VoiceGender;
      readonly id: string;
      readonly name: string;
      readonly platform: VoicePlatform;
      readonly type: VoiceType;
    }>;
  };
};
export type VoicePickerItemVoicesQuery = {
  response: VoicePickerItemVoicesQuery$data;
  variables: VoicePickerItemVoicesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lang"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "lang",
        "variableName": "lang"
      }
    ],
    "concreteType": "TtsVoices",
    "kind": "LinkedField",
    "name": "ttsVoices",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TtsVoice",
        "kind": "LinkedField",
        "name": "voices",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gender",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "platform",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sampleTexts",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VoicePickerItemVoicesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VoicePickerItemVoicesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e6e97049836121011474d359e82b769c",
    "id": null,
    "metadata": {},
    "name": "VoicePickerItemVoicesQuery",
    "operationKind": "query",
    "text": "query VoicePickerItemVoicesQuery(\n  $lang: String!\n) {\n  ttsVoices(lang: $lang) {\n    voices {\n      id\n      name\n      gender\n      type\n      platform\n    }\n    sampleTexts\n  }\n}\n"
  }
};
})();

(node as any).hash = "cff5125da420ad0e51baf0826b9a1da3";

export default node;
