import React, { useState } from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { devicePaddings } from "tools/device";
import { useTimedActions } from "hooks/useTimedActions";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";

const { top, bottom } = devicePaddings();

const Wrap = styled.div`
  height: 100%;
  padding: ${top + 10}px 40px ${bottom + 10}px;
  box-shadow: inset 0 -20px 100px #fe58;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;

  .showing {
    opacity: 1;
    transition: opacity 1s;
  }

  .hidden {
    opacity: 0;
    visibility: hidden;
  }
`;
const Img = styled.img`
  width: 80px;
  height: 80px;
`;
const Strong = styled.strong``;
const ExitButton = styled(Button)`
  color: #777;
  transition: all 2s;
`;

type Props = {
  onClose: VoidFunction;
};

const InternAdMotivation: React.FC<Props> = ({ onClose }) => {
  const navigate = useNavigate();
  const [visibleIndex, setVisibleIndex] = useState(0);
  useTimedActions([
    { delay: 0, callback: () => setVisibleIndex(1) },
    { delay: 1000, callback: () => setVisibleIndex(2) },
    { delay: 2000, callback: () => setVisibleIndex(3) },
    { delay: 5000, callback: () => setVisibleIndex(4) }
  ]);

  const order = (e) => {
    e.stopPropagation();
    onClose();
    navigate(SUBSCRIPTION.url());
  };

  return (
    <Wrap>
      <div />
      <div />
      <div />
      <Img src="https://cdn.duocards.com/svg/crown.svg" />
      <div />
      <Trans parent="p" className={visibleIndex >= 1 ? "showing" : "hidden"}>
        Are you serious about learning?
      </Trans>
      <Trans parent="p" className={visibleIndex >= 2 ? "showing" : "hidden"}>
        Consider buying a premium version <Strong>without limits or ads</Strong>.
      </Trans>

      <Button
        className={visibleIndex >= 3 ? "showing" : "hidden"}
        color="primary"
        variant="contained"
        size="large"
        onClick={order}
      >
        <Trans>Show me options</Trans>
      </Button>
      <ExitButton className={visibleIndex >= 4 ? "showing" : "hidden"} onClick={onClose}>
        <Trans>I'm not so serious</Trans>
      </ExitButton>
      <div />
      <div />
      <div />
    </Wrap>
  );
};

export default InternAdMotivation;
