import { useQuery } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { useTranslatesQuery } from "hooks/__generated__/useTranslatesQuery.graphql";

const translateBulk = graphql`
  query useTranslatesQuery($toLang: String!, $texts: [String!]!) {
    translateBulk(toLang: $toLang, texts: $texts)
  }
`;

export function useTranslates(langCode: string, texts: string[], skip: boolean = false) {
  const { data, isLoading: isLoadingTranslates } = useQuery<useTranslatesQuery>(
    translateBulk,
    {
      toLang: langCode,
      texts
    },
    { skip }
  );

  return {
    translates: data?.translateBulk || [],
    isLoadingTranslates
  };
}
