import { useQuery } from "relay-hooks";
import { useGetLangTranslatesQuery } from "./__generated__/useGetLangTranslatesQuery.graphql";
import { graphql } from "babel-plugin-relay/macro";
import { SingleLangTranslationMap, useGetState } from "components/ReduxProvider";
import { useSetState } from "components/ReduxProvider";
import { useEffect, useMemo } from "react";
import { langs } from "sharedJs__generated/langsList";
import { translateLangOptions } from "tools/langs";

export const getLangTranslatesQuery = graphql`
  query useGetLangTranslatesQuery($toLang: String!) {
    langTranslates(toLang: $toLang) {
      code
      name
    }
  }
`;

export function useGetLangTranslates() {
  const pickedLang = useGetState("pickedLang");
  const langTranslates = useGetState("langTranslates");
  const setLangTranslates = useSetState("langTranslates");

  const allTranslationsAreInStore = !!pickedLang && areAllTranslationsInStore(langTranslates?.[pickedLang] || {});

  const { data, isLoading } = useQuery<useGetLangTranslatesQuery>(
    getLangTranslatesQuery,
    { toLang: pickedLang! },
    { skip: allTranslationsAreInStore || !pickedLang }
  );

  useEffect(() => {
    if (!pickedLang || !data || data.langTranslates.length === 0 || allTranslationsAreInStore) return;

    const translationsMap = createTranslationMap(data.langTranslates);

    setLangTranslates({
      ...langTranslates,
      [pickedLang]: translationsMap
    });
  }, [data, langTranslates, setLangTranslates, pickedLang, allTranslationsAreInStore]);

  return {
    langTranslates: langTranslates?.[pickedLang || "en"],
    isLoading
  };
}

export const useGetLangOptions = () => {
  const { langTranslates } = useGetLangTranslates();
  const langOptions = useMemo(() => translateLangOptions(langTranslates), [langTranslates]);
  return langOptions;
};

const createTranslationMap = (
  langTranslates: useGetLangTranslatesQuery["response"]["langTranslates"]
): SingleLangTranslationMap =>
  langTranslates.reduce((acc, lang) => {
    if (!lang?.code || !lang?.name) return acc;
    acc[lang.code] = lang.name;
    return acc;
  }, {} as SingleLangTranslationMap);

const areAllTranslationsInStore = (langTranslates: SingleLangTranslationMap): boolean => {
  const langCodes = Object.keys(langs);
  return langCodes.every((langCode) => langTranslates[langCode] !== undefined);
};
