import React from "react";
import styled from "styled-components/macro";
import { Dialog, Button } from "@mui/material";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";

const Wrap = styled.div`
  padding: 20px;
  text-align: center;
`;
const ContinueButton = styled(Button)`
  margin: 15px 0 15px;
`;
const Text = styled.p`
  margin: 15px 10px;
`;
const TopIco = styled.p`
  font-size: 20px;
`;

type Props = {
  open: boolean;
  onContinue: VoidFunction;
};

const AdDialog: React.FC<Props> = ({ open, onContinue }) => {
  const navigate = useNavigate();

  return (
    <Dialog open={open}>
      <Wrap>
        <TopIco>{"🚀"}</TopIco>
        <Text>
          <Trans>We use Ads to keep this App available for free.</Trans>
        </Text>
        <ContinueButton variant="contained" color="primary" onClick={onContinue}>
          <Trans>Continue with Ads</Trans>
        </ContinueButton>
        <div>
          <Button color="primary" onClick={() => navigate(SUBSCRIPTION.url())}>
            <WhatshotIcon /> &nbsp;<Trans>Disable Ads</Trans>
          </Button>
        </div>
      </Wrap>
    </Dialog>
  );
};

export default AdDialog;
