import React from "react";
import { CardValues } from "../../../components/ReduxProvider";
import { dispatchEvent, EVENT } from "../../../tools/events";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/pro-regular-svg-icons";
import { IconButton } from "@mui/material";
import styled from "styled-components";

const SIconButton = styled(IconButton)`
  position: relative;

  && svg {
    width: 17px;
    height: 17px;
  }
`;

type Props = {
  card: CardValues;
};

const CardEditButton: React.FC<Props> = ({ card }) => {
  const handleStopPropagation = (e) => {
    e.stopPropagation();
    dispatchEvent(EVENT.openCardEditDialog, card);
  };

  return (
    <SIconButton onClick={handleStopPropagation}>
      <FontAwesomeIcon icon={faPenToSquare} />
    </SIconButton>
  );
};

export default CardEditButton;
