import React from "react";
import { LoginButton, LoginButtonProps } from "root/auth/LoginButton";
import { useTranslation } from "react-i18next";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

interface Props extends LoginButtonProps {}

const ContinueWithEmailButton = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <LoginButton startIcon={<MailOutlineIcon />} fullWidth {...props}>
        {t("Continue with email")}
      </LoginButton>
    </div>
  );
};

export default ContinueWithEmailButton;
