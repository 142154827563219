import React from "react";
import Loader from "../other/Loader";
import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrophone } from "@fortawesome/pro-regular-svg-icons";
import { useGetState } from "components/ReduxProvider";
import { getTheme } from "styled/theme";
import useSpeechToText from "hooks/useSpeechToText";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-right: 5px;
`;

const RecordButton = styled.button<{ disabled: boolean | undefined }>`
  color: ${({ disabled, theme }) => (disabled ? theme.duo.palette.greyLight : theme.duo.palette.greyDark)};
  border: none;
  transition: color 0.3s;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 30px;
`;

const SFontAwesomeIcon = styled(FontAwesomeIcon)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
`;

interface SpeechToTextProps {
  onTranscription: (text: string) => void;
  languages?: string[]; // specify if you know the language
}

const SpeechToText: React.FC<SpeechToTextProps> = ({ onTranscription, languages }) => {
  const recordButtonRef = React.useRef<HTMLButtonElement>(null);
  const globalRecording = useGetState("isAudioRecording");
  const { transcribing, listening, supported, startListening, stopListening } = useSpeechToText(onTranscription, {
    languages,
    listeningOptions: {
      stopAfterMs: 30000,
      sourceElementRef: recordButtonRef
    }
  });

  if (!supported) {
    return null;
  }

  return (
    <Wrap>
      {transcribing ? (
        <Loader size={28} />
      ) : (
        <RecordButton
          ref={recordButtonRef}
          disabled={globalRecording ? !listening : false}
          onClick={globalRecording && listening ? stopListening : startListening}
        >
          <SFontAwesomeIcon
            icon={faMicrophone}
            color={
              globalRecording
                ? listening
                  ? getTheme().palette.blueMain
                  : getTheme().palette.greyLight
                : getTheme().palette.greyDark
            }
            fade={globalRecording ? listening : false}
          />
        </RecordButton>
      )}
    </Wrap>
  );
};

export default SpeechToText;
