import React from "react";
import styled from "styled-components/macro";

const Svg = styled.svg`
  width: 55px;
  height: 55px;
  position: absolute;
  left: 0;
  top: 5px;
  z-index: 7;
  filter: drop-shadow(0 0 5px #fff);
`;

type Props = {};

const Cloud: React.FC<Props> = () => {
  return (
    <Svg
      enable-background="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="_x31_2_cloud">
        <path
          d="m443.875 272.982c4.238-59.343-50.4-105.087-107.758-91.164-11.628-52.393-58.363-91.574-114.257-91.574-66.241 0-119.656 55.044-116.937 121.884-.036 0-.071-.002-.107-.002-57.889 0-104.816 46.927-104.816 104.815 0 56.526 44.748 102.593 100.751 104.729v.086h336.715c41.164 0 74.534-33.37 74.534-74.534 0-39.002-29.964-70.988-68.125-74.24z"
          fill="#d8ecfe"
        ></path>
        <g>
          <g>
            <path
              d="m443.875 272.982c4.238-59.344-50.4-105.087-107.758-91.164-11.628-52.393-58.363-91.574-114.257-91.574-18.496 0-35.99 4.295-51.543 11.936 3.9-.393 7.855-.598 11.858-.598 55.894 0 102.629 39.181 114.257 91.574 57.358-13.924 111.996 31.82 107.758 91.164 38.161 3.253 68.125 35.238 68.125 74.241 0 26.65-13.997 50.019-35.03 63.195h.182c41.164 0 74.534-33.37 74.534-74.534-.001-39.002-29.965-70.988-68.126-74.24z"
              fill="#c4e2ff"
            ></path>
          </g>
        </g>
      </g>
    </Svg>
  );
};

export default Cloud;
