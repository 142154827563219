import React from "react";
import styled from "styled-components/macro";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { TopSourceMutation } from "./__generated__/TopSourceMutation.graphql";
import { Stack } from "@mui/material";
import { FileDownloadOff } from "@mui/icons-material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { EVENT, dispatchEvent } from "tools/events";
import { useSetState } from "components/ReduxProvider";

export const topSourceQL = graphql`
  mutation TopSourceMutation($id: ID!, $top: Boolean!, $offset: Int) {
    topSource(id: $id, top: $top, offset: $offset) {
      id
      top
    }
  }
`;

const SFileDownloadOff = styled(FileDownloadOff)`
  transform: rotate(180deg);
`;
const SStack = styled(Stack)<{ $topped: boolean }>`
  background: #fff;
  border-radius: 12px;
  font-size: 18px;
  color: ${(p) => p.theme.duo.color.primary};
  ${(p) => !p.$topped && `opacity: 0.7;`}
`;

type Props = {
  source: {
    readonly id: string;
    readonly top: number | null;
    readonly course: boolean;
  };
};

const TopSource: React.FC<Props> = ({ source }) => {
  const setLoaderGlobal = useSetState("loaderGlobal");
  const [mutate] = useMutation<TopSourceMutation>(topSourceQL, {
    onCompleted: () => {
      dispatchEvent(EVENT.refetchLibrary);
      setLoaderGlobal(false);
    }
  });

  const toggleTop = (e) => {
    mutate({ variables: { id: source.id, top: !source.top } });
    e.stopPropagation();
    e.preventDefault();
    setLoaderGlobal(true);
  };

  const moveSource = (e, offset: number) => {
    mutate({ variables: { id: source.id, top: true, offset } });
    e.stopPropagation();
    e.preventDefault();
    setLoaderGlobal(true);
  };

  return (
    <SStack alignItems="center" direction="row" $topped={!!source.top}>
      {!source.course && source.top && <FileUploadIcon onClick={toggleTop} />}
      {!source.course && !source.top && <SFileDownloadOff onClick={toggleTop} />}
      {source.course && (
        <>
          <KeyboardDoubleArrowLeftIcon onClick={(e) => moveSource(e, -1)} />
          <KeyboardDoubleArrowRightIcon onClick={(e) => moveSource(e, +1)} />
        </>
      )}
    </SStack>
  );
};

export default TopSource;
