/**
 * @generated SignedSource<<eb7b695952d6e610bd4dd32538c02530>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CardSorterQuery$variables = {
  lang: string;
  langNative?: string | null;
};
export type CardSorterQuery$data = {
  readonly mine: {
    readonly sources: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly counts: {
            readonly total: number;
          } | null;
          readonly id: string;
          readonly name: string;
          readonly uri: string;
        } | null;
      } | null> | null;
    };
  };
};
export type CardSorterQuery = {
  response: CardSorterQuery$data;
  variables: CardSorterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lang"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "langNative"
  }
],
v1 = {
  "kind": "Literal",
  "name": "kind",
  "value": "set"
},
v2 = {
  "kind": "Variable",
  "name": "lang",
  "variableName": "lang"
},
v3 = {
  "kind": "Variable",
  "name": "langNative",
  "variableName": "langNative"
},
v4 = {
  "kind": "Literal",
  "name": "mine",
  "value": true
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SourceEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "uri",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              (v3/*: any*/)
            ],
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SCounts",
            "kind": "LinkedField",
            "name": "counts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  },
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CardSorterQuery",
    "selections": [
      {
        "alias": "mine",
        "args": null,
        "concreteType": "Public",
        "kind": "LinkedField",
        "name": "public",
        "plural": false,
        "selections": [
          {
            "alias": "sources",
            "args": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "concreteType": "SourceConnection",
            "kind": "LinkedField",
            "name": "__Library_sources_connection",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CardSorterQuery",
    "selections": [
      {
        "alias": "mine",
        "args": null,
        "concreteType": "Public",
        "kind": "LinkedField",
        "name": "public",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": "SourceConnection",
            "kind": "LinkedField",
            "name": "sources",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v7/*: any*/),
            "filters": [
              "kind",
              "lang",
              "mine",
              "langNative"
            ],
            "handle": "connection",
            "key": "Library_sources",
            "kind": "LinkedHandle",
            "name": "sources"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a3f692ddb0b3ca18270fb27fb93a86ae",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "mine",
            "sources"
          ]
        }
      ]
    },
    "name": "CardSorterQuery",
    "operationKind": "query",
    "text": "query CardSorterQuery(\n  $lang: String!\n  $langNative: String\n) {\n  mine: public {\n    sources(first: 1000, kind: set, lang: $lang, mine: true, langNative: $langNative) {\n      edges {\n        node {\n          id\n          uri\n          name(langNative: $langNative)\n          counts {\n            total\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1689c254ab7778db42b2d04f93133230";

export default node;
