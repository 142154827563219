import { useSetState } from "components/ReduxProvider";
import React from "react";
import { TSource, TSourceCard, TSourceCards } from "types/SourceTypes";
import { TTheory } from "types/TheoryTypes";
import { Mutable } from "utility-types";
import { getTheoryFirstLine, theoryGetContent, useTheoryPickedLang } from "components/Theory/theoryTools";
import { isCardSorted } from "components/picker/pickCardTools";
import { COURSE_VIEW_HASH } from "root/library/source/course/CourseView";
import { useNavigate } from "react-router-dom";

export function useOpenCourseView() {
  const navigate = useNavigate();
  const setCourseView = useSetState("courseView");

  return React.useCallback(
    (
      sourceUriOrId: string,
      langNative: string,
      langLearning: string,
      openTheoryByCardId: string | undefined = undefined,
      allowScrollAnimation: boolean = false,
      canShowTooMany: boolean = false
    ) => {
      setCourseView({
        sourceUriOrId,
        langNative,
        langLearning,
        openTheoryByCardId,
        allowScrollAnimation,
        canShowTooMany
      });
      navigate({ hash: COURSE_VIEW_HASH });
    },
    [setCourseView, navigate]
  );
}

export type TTheoryBox = {
  cardId: string;
  title: string;
  theory: TTheory;
  theoryCards: TSourceCards;
  isCompleted: boolean;
  isCurrent: boolean;
  animateCompletion: boolean;
  progress: number;
  image: { url: string };
};
type TTheoryBoxes = TTheoryBox[];

function getTheoryCards(cards: TSourceCards, theoryStartIndex: number): TSourceCards {
  const theoryCards: Mutable<TSourceCards> = [cards[theoryStartIndex]];
  for (let i = theoryStartIndex + 1; i < cards.length; i++) {
    if (!cards[i].theory) {
      theoryCards.push(cards[i]);
    } else {
      break;
    }
  }
  return theoryCards;
}

export function useGetTheories(source: TSource, openTheoryByCardId?: string): TTheoryBoxes {
  const firstTheory: TTheory | undefined = source.sCards.find((c) => !!c.theory)?.theory || undefined;
  const theoryPickedLang = useTheoryPickedLang(firstTheory);

  if (!firstTheory) return [];

  const theories: TTheoryBoxes = [];
  let defaultImageCounter = 0;
  for (let i = 0; i < source.sCards.length; i++) {
    const card = source.sCards[i];

    if (card.theory) {
      const theoryContent = theoryGetContent(card.theory, theoryPickedLang);
      const firstLine = getTheoryFirstLine(theoryContent);
      const theoryCards = getTheoryCards(source.sCards, i);
      const theoryCardsSorted = theoryCards.filter((card: TSourceCard) => isCardSorted(card));
      const isForceOpen = theoryCards.some((card) => card.id === openTheoryByCardId);
      const isCompleted = theoryCards.every((card: TSourceCard) => isCardSorted(card));
      const previousTheory: TTheoryBox | undefined = theories[theories.length - 1];
      const defaultImage = {
        url: `https://cdn.duocards.com/course/${defaultImageCounter < 10 ? "0" : ""}${defaultImageCounter}.png`
      };

      if (!card.svg) {
        defaultImageCounter++;
      }

      if (previousTheory) {
        previousTheory.animateCompletion = previousTheory.isCompleted && !isCardSorted(card);
      }

      theories.push({
        cardId: card.id,
        title: firstLine,
        theory: card.theory,
        isCompleted,
        isCurrent:
          isForceOpen || (!openTheoryByCardId && !isCompleted && (!previousTheory || previousTheory.isCompleted)),
        theoryCards,
        animateCompletion: false,
        image: card.svg || defaultImage,
        progress: Math.ceil((100 * theoryCardsSorted.length) / theoryCards.length)
      });
    }
  }

  if (theories.length && !theories.some((t) => t.isCurrent)) theories[0].isCurrent = true;

  return theories;
}
