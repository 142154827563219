import React from "react";
import styled from "styled-components/macro";

// source: https://speckyboy.com/flame-effects-code-snippets/

const digits = (xps) => (Math.log(xps) * Math.LOG10E + 1) | 0;

const Wrap = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  margin: 7px;
  background-color: transparent;
`;
const In = styled.div<{ strength: number; xps: number | null; infinite: boolean }>`
  position: relative;
  height: 100%;
  transform: scaleY(${({ strength }) => (strength > 1 ? "1" : "0.7")})
    scaleX(${({ xps }) => (xps && xps > 99 ? digits(xps) / 5 + 0.6 : 1)});
  transition: all 2s;

  /* prettier-ignore */
  @keyframes scaleUpDown {
    0%, 100% { transform: scaleY(1) scaleX(1); }
    50%, 90% { transform: scaleY(1.3); }
    75% { transform: scaleY(0.95); }
    80% { transform: scaleX(0.95); }
  }
  /* prettier-ignore */
  @keyframes shake {
    0%, 100% { transform: skewX(0) scale(1); }
    50% { transform: skewX(7deg) scale(0.85); }
  }
  /* prettier-ignore */
  @keyframes particleUp {
    0% { opacity: 0; }
    20% { opacity: 1; }
    80% { opacity: 1; }
    100% { opacity: 0; top: -100%; transform: scale(0.5); }
  }
  /* prettier-ignore */
  @keyframes glow {
    0%, 100% { background-color: ${({ theme }) => theme.duo.color.flame}; }
    50% { background-color: #ff7800; }
  }
  .particle-fire {
    display: ${({ strength }) => (strength > 4 ? "block" : "none")};
    transition: opacity 2s;
  }
  .fire-main,
  .fire-left,
  .fire-right {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: all 2s;
    transition-property: height, width;
  }
  .fire-main {
    position: absolute;
    height: 100%;
    width: 100%;
    animation: ${({ strength, infinite }) =>
      strength > 0 ? "scaleUpDown 3s ease-out " + (infinite ? " infinite" : "") : "none"};
  }
  .fire-main .main-fire {
    position: absolute;
    height: 100%;
    width: 100%;
    background: ${({ strength, theme, xps }) =>
      strength > 0
        ? strength > 1
          ? "#e55200 radial-gradient(farthest-corner at 3px 0, #d43300 0%, " + theme.duo.color.flame + " 95%)"
          : "#e55200"
        : xps && xps < 0
          ? "#9cf"
          : "#bbb"};
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%;
    transition: all 2s;
  }
  .fire-main .particle-fire {
    position: absolute;
    top: 60%;
    left: 42%;
    width: 5px;
    height: 5px;
    background-color: ${({ theme }) => theme.duo.color.flame};
    border-radius: 50%;
    animation: particleUp 2s ease-out ${({ infinite }) => (infinite ? "infinite" : "")};
  }
  .fire-right {
    height: ${({ strength }) => (strength > 3 ? "100%" : "0%")};
    width: ${({ strength }) => (strength > 3 ? "100%" : "0%")};
    animation: shake 1.5s ease-out ${({ infinite }) => (infinite ? "infinite" : "")};
  }
  .fire-right .main-fire {
    position: absolute;
    top: 15%;
    right: -25%;
    width: 80%;
    height: 80%;
    background-color: ${({ theme }) => theme.duo.color.flame};
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%;
  }
  .fire-right .particle-fire {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: ${({ theme }) => theme.duo.color.flame};
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 50%;
    animation: particleUp 2.5s ease-out ${({ infinite }) => (infinite ? "infinite" : "")};
  }
  .fire-left {
    position: absolute;
    height: ${({ strength }) => (strength > 2 ? "100%" : "0%")};
    width: ${({ strength }) => (strength > 2 ? "100%" : "0%")};
    animation: shake 2s ease-out ${({ infinite }) => (infinite ? "infinite" : "")};
  }
  .fire-left .main-fire {
    position: absolute;
    top: 15%;
    left: -20%;
    width: 80%;
    height: 80%;
    background-color: ${({ theme }) => theme.duo.color.flame};
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%;
  }
`;
const Sleep = styled.div<{ strength: number }>`
  position: absolute;
  top: -18px;
  right: 1px;
  font-size: 10px;
  color: #bbb;
  opacity: ${({ strength }) => (strength === 0 ? "1" : "0")};
  transition: all 2s;
  animation: shake 2s ease-out;
  span {
    position: absolute;
    margin-top: -5px;
    font-size: 12px;
  }
`;
export const CubeImg = styled.img.attrs(() => ({
  src: "https://cdn.duocards.com/img/ice.png",
  alt: "ice",
  className: "nofilter"
}))<{ coldness: number }>`
  position: absolute;
  top: -4px;
  left: -3px;
  z-index: 15;
  width: 28px;
  opacity: ${(p) => p.coldness * 0.2};
  transition: opacity 1s;
`;
const Xp = styled.div<{ xps: number | null }>`
  color: white;
  position: absolute;
  top: -1px;
  left: ${({ xps }) => (xps && xps > 9999 ? (digits(xps) - 4) * -2 : 0)}px;
  right: 0;
  font-size: 11px;
  line-height: 24px;
  font-weight: bold;
  margin: 0 auto;
  text-align: center;
  z-index: 10;
`;

type Props = {
  strength: number;
  className?: string;
  xps: number | null;
  noZZ?: boolean;
  coldness?: number;
  infinite?: boolean;
  xpSize?: number;
};

const Flames = React.forwardRef<any, Props>(({ strength, className, xps, noZZ, coldness, infinite = false }, ref) => {
  return (
    <Wrap ref={ref} className={"nofilter flamesExtraClass " + className}>
      <In strength={strength} xps={xps} infinite={infinite}>
        <div className="fire-left">
          <div className="main-fire"></div>
        </div>
        <div className="fire-main">
          <div className="main-fire"></div>
          <div className="particle-fire"></div>
        </div>
        <div className="fire-right">
          <div className="main-fire"></div>
          <div className="particle-fire"></div>
        </div>
        {!noZZ && !coldness && (
          <Sleep strength={strength}>
            z<span>z</span>
          </Sleep>
        )}
      </In>
      <CubeImg coldness={coldness || 0} />
      <Xp xps={xps}>{xps !== null && xps}</Xp>
    </Wrap>
  );
});

export default Flames;
