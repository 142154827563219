import React from "react";
import styled from "styled-components/macro";
import Avatar from "./Avatar";
import FriendDialog from "./FriendDialog";

const Wrap = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 10px 0 0;
`;
const In = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 0 7px;
`;
const Col = styled.div`
  padding: 10px 5px 5px;
  width: 80px;
  flex-shrink: 0;
  text-align: center;
`;
const FriendName = styled.div`
  font-weight: bold;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  display: -webkit-box;
  line-height: 16px;
  max-height: 32px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export type Friend = {
  id: string;
  name: string | null;
  picture: string | null;
  xps: {
    total: number;
    today: number;
    currentStreak: number;
  };
};
type Props = {
  friends?: readonly (Friend | null)[] | null;
  atLeast3?: boolean;
};

const FriendsList: React.FC<Props> = ({ friends: friendsProp, atLeast3 }) => {
  const [openFriend, setOpenFriend] = React.useState<Friend>();

  const friends = [...(friendsProp || [])];

  if (atLeast3) {
    [0, 1, 2].forEach((i) => {
      if (!friends[i]) {
        friends[i] = null;
      }
    });
  }

  return (
    <Wrap>
      <In>
        {friends.map((friend, i) => (
          <Col key={i} onClick={() => setOpenFriend(friend || undefined)}>
            <Avatar user={friend} />
            {friend && <FriendName>{friend.name}</FriendName>}
          </Col>
        ))}

        {openFriend && <FriendDialog friend={openFriend} onClose={() => setOpenFriend(undefined)} />}
      </In>
    </Wrap>
  );
};

export default FriendsList;
