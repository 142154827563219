import Flames from "components/fire/Flames";
import { Trans } from "react-i18next";
import PublicIcon from "@mui/icons-material/Public";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import styled from "styled-components/macro";

const SFlames = styled(Flames)`
  transform: scale(0.9);
`;

export const ShowLessContent = () => {
  return (
    <>
      <tr>
        <td className="icon">
          <SFlames strength={4} xps={null} />
        </td>
        <td className="text">
          <Trans parent="span">Unlimited experience</Trans>
        </td>
        <td className="premium">✓</td>
      </tr>
      <tr>
        <td className="icon" style={{ color: "#090" }}>
          <PublicIcon />
        </td>
        <td className="text">
          <Trans parent="span">Unlimited languages</Trans>
        </td>
        <td className="premium">✓</td>
      </tr>
      <tr>
        <td className="icon" style={{ color: "#900" }}>
          <NotInterestedIcon />
        </td>
        <td className="text">
          <Trans parent="span">100% Ad free</Trans>
        </td>
        <td className="premium">✓</td>
      </tr>
    </>
  );
};
