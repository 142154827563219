import { graphql } from "babel-plugin-relay/macro";
import { fetchQuery } from "relay-hooks";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import { fetchTtsQuery, fetchTtsQuery$data, fetchTtsQuery$variables } from "./__generated__/fetchTtsQuery.graphql";

export const ttsQl = graphql`
  query fetchTtsQuery($text: String!, $lang: String!, $speakingRate: Float, $voiceId: String, $useCache: Boolean) {
    tts(text: $text, lang: $lang, speakingRate: $speakingRate, voiceId: $voiceId, useCache: $useCache) {
      id
      speech
      voiceId
    }
  }
`;

export const fetchTts = (environment: RelayModernEnvironment, args: fetchTtsQuery$variables) =>
  new Promise<fetchTtsQuery$data["tts"] | null>(async (done) => {
    fetchQuery<fetchTtsQuery>(environment, ttsQl, {
      ...args
    }).subscribe({
      next: (data) => done(data.tts)
    });
  });
