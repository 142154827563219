import React from "react";
import { AssistantMode } from "../__generated__/AssistantMutation.graphql";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { ElementType } from "react";

export const AI_STORY_SUPPORTED_LANGS = ["en", "es", "fr", "it", "nl", "ja", "de", "cs", "zh"];
export enum SuggestionBehavior {
  opensMoreSuggestions = "opensMoreSuggestions", // to be implemented when needed
  goesToInput = "goesToInput",
  sendsMessage = "sendsMessage",
  closesAssistant = "closesAssistant"
}

export type SuggestionDefinition = {
  behavior: SuggestionBehavior;
  text: string;
  icon?: ElementType;
  switchToMode?: AssistantMode;
  childSuggestions?: SuggestionDefinition[]; // to be implemented when needed
};

type SuggestionsDefinition = { [K in "topLevel" | "aiStory"]: SuggestionDefinition[] };

export type TranslatedSuggestion = SuggestionDefinition & {
  nativeText: string;
  targetText: string;
};

const suggestionsDefinitionTopLevel: SuggestionDefinition[] = [
  {
    behavior: SuggestionBehavior.sendsMessage,
    text: "Make a story from my cards",
    switchToMode: "aiStory",
    icon: AutoStoriesOutlinedIcon
  },
  {
    behavior: SuggestionBehavior.goesToInput,
    text: "Give me examples of usage for:"
  },
  {
    behavior: SuggestionBehavior.goesToInput,
    text: "What is the difference between:"
  },
  {
    behavior: SuggestionBehavior.goesToInput,
    text: "What are synonyms for:"
  },
  {
    behavior: SuggestionBehavior.goesToInput,
    text: "What is the meaning of:"
  },
  {
    behavior: SuggestionBehavior.goesToInput,
    text: "How do I say:"
  },
  {
    behavior: SuggestionBehavior.goesToInput,
    text: "How is used the word:"
  },
  {
    behavior: SuggestionBehavior.goesToInput,
    text: "Explain me:"
  }
];

export const suggestionsDefinition: SuggestionsDefinition = {
  topLevel: suggestionsDefinitionTopLevel,
  aiStory: [
    {
      behavior: SuggestionBehavior.closesAssistant,
      text: "Thank you! That's all",
      icon: CheckIcon
    },
    {
      behavior: SuggestionBehavior.sendsMessage,
      text: "Make a fairytale"
    },
    {
      behavior: SuggestionBehavior.sendsMessage,
      text: "Make a romance story"
    },
    {
      behavior: SuggestionBehavior.sendsMessage,
      text: "Make a science-fiction story"
    },
    {
      behavior: SuggestionBehavior.sendsMessage,
      text: "Make a comedy story"
    }
  ]
};

export function getAIStoryStarter(): SuggestionDefinition {
  return (
    suggestionsDefinitionTopLevel.find((suggestion) => suggestion.switchToMode === "aiStory") ||
    suggestionsDefinitionTopLevel[0]
  );
}

export function useFlattenSuggestions(): SuggestionDefinition[] {
  return React.useMemo(() => {
    let flattenedSuggestions: SuggestionDefinition[] = [];

    for (let key in suggestionsDefinition) {
      flattenedSuggestions = flattenedSuggestions.concat(suggestionsDefinition[key]);
    }

    return flattenedSuggestions;
  }, []);
}
