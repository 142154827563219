import React from "react";
import styled from "styled-components/macro";
import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useToggle } from "react-use";
import DialogWindow from "components/other/DialogWindow";

const NO_PAYMENT_TIMEZONES: string[] = [
  "Asia/Tehran", // Iran
  "Asia/Tashkent", // Uzbekistan
  "Asia/Ulaanbaatar", // Mongolia
  "Asia/Hovd", // Mongolia
  "Europe/Moscow", // Russia
  "Europe/Samara", // Russia
  "Asia/Novosibirsk", // Russia
  "Asia/Novokuznetsk", // Russia
  "Asia/Irkutsk", // Russia
  "Asia/Yakutsk", // Russia
  "Asia/Vladivostok", // Russia
  "Asia/Sakhalin", // Russia
  "Asia/Magadan", // Russia
  "Asia/Kamchatka" // Russia
];

export const isNoPaymentTimezone = (timezone: string | undefined): boolean => {
  if (!timezone) return false;

  return NO_PAYMENT_TIMEZONES.includes(timezone);
};

const Title = styled.h1<{ first?: boolean }>`
  font-size: 18px;
  font-weight: bold;
  position: relative;
  top: -3px;
  margin-top: ${(p) => (p.first ? 0 : 40)}px;
`;
const PopupWrap = styled.section`
  padding: 20px;
`;
const Btn = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

type Props = {};

const VpnAffiliate: React.FC<Props> = () => {
  const [open, toggleOpen] = useToggle(false);
  const { t } = useTranslation();

  return (
    <>
      <Btn onClick={toggleOpen}>
        <Trans>Payment troubleshooting</Trans>
      </Btn>

      <DialogWindow open={open} onClose={toggleOpen}>
        <PopupWrap>
          <Title first>
            <Trans>Payment troubleshooting</Trans>
          </Title>
          <p>
            <Trans>Google doesn't let you pay for the subscription?</Trans>{" "}
            <Trans>Google payment system might not be accessible in your country.</Trans>{" "}
            <Trans>This affects all the apps, not just DuoCards.</Trans>{" "}
            <Trans>A possible way to get around it might be by using a VPN.</Trans>
          </p>

          <Title>
            <Trans>What is VPN?</Trans>
          </Title>
          <p>
            <Trans>VPN anonymizes your connection and can make it look like you live in a different country.</Trans>{" "}
            <Trans>
              By installing VPN you can gain access to all restricted content in your country, including Google
              Payments.
            </Trans>
          </p>
          <p>
            <Trans>For example, on your mobile device, you can install</Trans>{" "}
            <a href="https://get.duocards.com/r/vpn">PureVPN</a>
          </p>
          <p>
            <Trans>Note: you might need to create a completely new Google account.</Trans>{" "}
          </p>

          <Button color="primary" onClick={toggleOpen}>
            {t("Close")}
          </Button>
        </PopupWrap>
      </DialogWindow>
    </>
  );
};

export default VpnAffiliate;
