/**
 * @generated SignedSource<<2c96004ea9c5ff011eb148d0bce762a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type referrersSourcesMutation$variables = {
  referrer: string;
};
export type referrersSourcesMutation$data = {
  readonly processReferrersSources: boolean | null;
};
export type referrersSourcesMutation = {
  response: referrersSourcesMutation$data;
  variables: referrersSourcesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "referrer"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "referrer",
        "variableName": "referrer"
      }
    ],
    "kind": "ScalarField",
    "name": "processReferrersSources",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "referrersSourcesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "referrersSourcesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "83df6e3d93e4e7e799089bcfc4bb62df",
    "id": null,
    "metadata": {},
    "name": "referrersSourcesMutation",
    "operationKind": "mutation",
    "text": "mutation referrersSourcesMutation(\n  $referrer: String!\n) {\n  processReferrersSources(referrer: $referrer)\n}\n"
  }
};
})();

(node as any).hash = "69885d5104186879dc68b222122dbc3e";

export default node;
