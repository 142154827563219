import React from "react";
import styled, { css } from "styled-components/macro";
import { CubeImg } from "./Flames";

const Wrap = styled.div`
  display: inline-block;
  margin: 0 7px;
`;

const SCubeImg = styled(CubeImg)<{ scale: number }>`
  ${({ scale }) =>
    scale &&
    css`
      width: ${scale * 30}px;
    `}
  top: 0;
  left: 0;
`;

const SCubeWrap = styled.div<{ scale: number }>`
  position: relative;
  display: inline-block;
  margin: 0 auto;
  ${({ scale }) =>
    scale &&
    css`
      width: ${scale * 30}px;
      height: ${scale * 30}px;
      line-height: ${scale * 30}px;
    `}
  text-align: center;
  font-weight: bold;
`;

const SCubeSpan = styled.span<{ scale: number }>`
  font-weight: bold;
  ${({ scale }) =>
    scale &&
    css`
      font-size: ${scale * 12}px;
    `}
`;

type Props = {
  coldness: number;
  number: number;
  scale?: number;
  style?: React.CSSProperties;
};

const IceCube: React.FC<Props> = ({ coldness, number, scale, style }) => {
  return (
    <Wrap style={style}>
      <SCubeWrap scale={scale ?? 1}>
        <SCubeImg scale={scale ?? 1} coldness={coldness} />
        <SCubeSpan scale={scale ?? 1}>{number}</SCubeSpan>
      </SCubeWrap>
    </Wrap>
  );
};

export default IceCube;
