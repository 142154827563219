import React from "react";
import styled from "styled-components/macro";
import FaceIcon from "@mui/icons-material/Face";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import { Menu, MenuItem, IconButton, Button } from "@mui/material";
import { useNavigate } from "react-router";
import { MYSOURCES, SOURCENEW } from "./libraryUrls";
import { Trans, useTranslation } from "react-i18next";
import { useViewerQuery } from "../../../queries/viewerQuery";
import { FIVEHINT } from "../../main/main/Main";
import { SmallDuoCards } from "styled/DuoCardsTitle";

const SIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.duo.color.textGrey};
`;
const LButton = styled(Button)`
  &&& {
    padding: 8px 10px;
  }
`;

const LibraryMenu: React.FC<{}> = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hintWasSeen, viewer } = useViewerQuery();

  const openMenu = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const mySources = () => {
    navigate(MYSOURCES.url());
    handleClose();
  };
  const create = () => {
    navigate(SOURCENEW.url());
    handleClose();
  };

  if (!hintWasSeen(FIVEHINT)) return null;

  if (!viewer) {
    return (
      <LButton color="primary" onClick={() => navigate("/")}>
        <Trans>Login</Trans>
      </LButton>
    );
  }

  return (
    <>
      <SIconButton onClick={openMenu}>
        <MenuIcon />
      </SIconButton>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {!viewer && (
          <MenuItem>
            <a href="https://duocards.com" style={{ margin: "0 auto" }}>
              <SmallDuoCards />
            </a>
          </MenuItem>
        )}
        <MenuItem onClick={mySources}>
          <FaceIcon /> &nbsp; {t("My sources")}
        </MenuItem>
        <MenuItem onClick={create}>
          <AddIcon /> &nbsp; {t("Create")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default LibraryMenu;
