import React, { ComponentProps } from "react";

type Props = ComponentProps<"svg"> & {
  showStars?: boolean;
};

const CrownIcon: React.FC<Props> = ({ showStars = false, ...props }) => {
  return (
    <svg enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path
          d="m406.702 332.469h-301.403c-6.796 0-12.743-4.569-14.494-11.135l-42.098-157.869c-1.52-5.701.443-11.764 5.017-15.492s10.909-4.427 16.185-1.79l115.117 57.559 57.558-115.117c2.541-5.082 7.735-8.292 13.417-8.292s10.876 3.21 13.416 8.292l57.559 115.117 115.117-57.559c5.278-2.64 11.612-1.938 16.185 1.79 4.574 3.728 6.537 9.791 5.017 15.492l-42.098 157.869c-1.753 6.566-7.7 11.135-14.495 11.135z"
          fill="#ffd961"
        />
        <path
          d="m421.195 321.333 42.098-157.869c1.521-5.701-.442-11.764-5.017-15.492-4.572-3.728-10.906-4.429-16.185-1.79l-115.117 57.559-57.558-115.116c-2.54-5.082-7.734-8.292-13.416-8.292v252.135h150.701c6.796.001 12.743-4.568 14.494-11.135z"
          fill="#ffb300"
        />
        {showStars && (
          <>
            <g fill="#ffd961">
              <path d="m15 54.1h9.1v9.1c0 8.284 6.716 15 15 15s15-6.716 15-15v-9.1h9.1c8.284 0 15-6.716 15-15s-6.716-15-15-15h-9.1v-9.1c0-8.284-6.716-15-15-15s-15 6.716-15 15v9.1h-9.1c-8.284 0-15 6.716-15 15s6.716 15 15 15z" />
              <path d="m79.267 449.867h-17.134v-17.133c0-8.284-6.716-15-15-15s-15 6.716-15 15v17.133h-17.133c-8.284 0-15 6.716-15 15s6.716 15 15 15h17.133v17.133c0 8.284 6.716 15 15 15s15-6.716 15-15v-17.133h17.133c8.284 0 15-6.716 15-15s-6.715-15-14.999-15z" />
            </g>
            <g fill="#ffb300">
              <path d="m441.833 95.333c0 8.284 6.716 15 15 15s15-6.716 15-15v-25.166h25.167c8.284 0 15-6.716 15-15s-6.716-15-15-15h-25.167v-25.167c0-8.284-6.716-15-15-15s-15 6.716-15 15v25.167h-25.166c-8.284 0-15 6.716-15 15s6.716 15 15 15h25.166z" />
              <path d="m497 457.9h-9.1v-9.1c0-8.284-6.716-15-15-15s-15 6.716-15 15v9.1h-9.1c-8.284 0-15 6.716-15 15s6.716 15 15 15h9.101v9.1c0 8.284 6.716 15 15 15s15-6.716 15-15v-9.1h9.1c8.284 0 15-6.716 15-15s-6.717-15-15.001-15z" />
            </g>
          </>
        )}
        <path
          d="m384.533 415.6h-257.066c-6.796 0-12.743-4.569-14.494-11.135l-22.168-83.131h330.39l-22.168 83.131c-1.752 6.566-7.698 11.135-14.494 11.135z"
          fill="#ffb300"
        />
        <path
          d="m399.026 404.465 22.168-83.131h-165.194v94.266h128.533c6.796 0 12.742-4.569 14.493-11.135z"
          fill="#ff8d00"
        />
      </g>
    </svg>
  );
};

export default CrownIcon;
