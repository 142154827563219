import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

export enum SEARCH {
  editCard = "editCard",
  openImage = "openImage"
}

export const useSearchParamTools = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  // if url is /?editCard=1 -> getSearchParam("editCard") returns "1"
  const getSearchParam = (key: SEARCH) => {
    return searchParams.get(key);
  };

  // navigateSearchParam("editCard", "123") will navigate to /?editCard=123
  const navigateSearchParam = (key: SEARCH, value: string = "1") => {
    if (getSearchParam(key) !== value) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set(key, value);
      navigate({ ...location, search: newSearchParams.toString() });
    }
  };

  // /?editCard=1 => / by going back
  const backSearchParam = (key: SEARCH) => {
    if (getSearchParam(key)) {
      navigate(-1);
    }
  };

  return { navigateSearchParam, backSearchParam, getSearchParam };
};
