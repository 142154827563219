import { Button, IconButton } from "@mui/material";
import React from "react";
import styled, { css } from "styled-components/macro";
import { TTheory } from "types/TheoryTypes";
import TheoryParser from "./TheoryParser";
import TheoryToggler from "./TheoryToggler";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import TheoryEditor, { THEORYTEXTID } from "./TheoryEditor";
import CloseIcon from "@mui/icons-material/Close";
import { brown, brownDark, insetShadow } from "./theoryStyles";
import TheoryDelete from "./TheoryDelete";
import { useViewerQuery } from "../../queries/viewerQuery";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FIVEHINT } from "../../root/main/main/Main";
import { theoryGetContent, useTheoryPickedLang } from "components/Theory/theoryTools";
import { usePrevious } from "react-use";
import { EVENT, dispatchEvent } from "tools/events";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/pro-regular-svg-icons";

const Content = styled.div<{ bigger: boolean; collapsed?: boolean; $courseStyle?: boolean }>`
  background: white;
  font-size: ${({ theme, bigger }) => (bigger ? theme.duo.fontSize.normal : theme.duo.fontSize.small)};
  line-height: 1.55em;
  border-radius: 5px;
  overflow: ${(p) => (p.$courseStyle ? "visible" : "hidden")};
  strong {
    font-size: 95%;
  }
  ${(p) => !p.$courseStyle && `padding: 6px 16px ${p.bigger ? 56 : 6}px 18px;`}
  ${(p) => !p.$courseStyle && `box-shadow: ${insetShadow}, 3px 4px 5px #0001;`}
  ${({ collapsed }) =>
    collapsed &&
    css`
      opacity: 0.9;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 5px 12px;
    `}
  p {
    margin: 10px 0;
  }
`;
const Top = styled.div`
  position: relative;
  margin: -3px -3px 0;
  height: 7px;
  border-radius: 8px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background: ${brown};
`;
const Bottom = styled.div`
  position: relative;
  margin: 0 -3px -3px;
  height: 4px;
  border-radius: 5px;
  background: ${brown};
  z-index: 1;
`;
const Line = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 4px;
  height: 14px;
  background: ${brownDark};
`;
const Knob = styled.div`
  position: absolute;
  border: 3px solid ${brownDark};
  border-radius: 10px;
  width: 12px;
  height: 12px;
  bottom: -9px;
  left: -4px;
`;
const Title = styled.div`
  font-weight: bold;
  font-size: 90%;
  text-align: center;
  margin: 10px 0 5px;
  line-height: 1.3em;
  color: ${brownDark};
`;
const OkBox = styled.div`
  text-align: center;
  background: linear-gradient(#fff0, #fff 40%);
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 10px;
  right: 10px;
`;
const Wrap = styled.div<{ bigger: boolean; $courseStyle?: boolean }>`
  position: ${(p) => (p.$courseStyle ? "unset" : "relative")};
  z-index: 1;
  ${(p) => !p.$courseStyle && `margin: 9px 9px 2px;`}
  min-width: 300px;
  max-width: 700px;
  ${({ bigger }) =>
    bigger &&
    css`
      max-height: 76%;
      margin: 20px 9px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      ${Content} {
        max-height: 95%;
        overflow: auto;
        border-radius: 0;
      }
    `}
`;
const SButton = styled(Button)`
  &&& {
    margin: 0 0 12px;
    background: ${brownDark};
    box-shadow: none;
  }
`;
const Header = styled.div<{ bigger: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${({ bigger }) => (bigger ? -4 : -12)}px;
`;
const ButtWrap = styled.div<{ $courseStyle?: boolean }>`
  display: flex;
  align-items: center;

  ${(p) =>
    p.$courseStyle &&
    `
      display: block;
      width: 100%;
    `}
`;
const SIconButton = styled(IconButton)<{ $courseStyle?: boolean }>`
  float: left;
  padding: 6px;
  position: relative;
  left: -10px;
  svg {
    color: #aaa;
    font-size: 18px;
  }

  ${(p) =>
    p.$courseStyle &&
    `
    position: absolute;
    top: 7px;
    left: 8px;
  `}
`;
const SArrowDropDownIcon = styled(ArrowDropDownIcon)`
  float: right;
  position: relative;
  margin: -0px -6px -3px 0;
  color: #bbb;
  font-size: 20px;
`;

type Props = {
  theory: TTheory;
  title?: string;
  sourceId?: string | null;
  onContinue?: VoidFunction;
  className?: string;
  collapsed?: boolean;
  withDelete?: boolean;
  coursePreview?: boolean;
  hideEdit?: boolean;
  onTheoryEditToggle?: (to: boolean, cardId: string) => void;
  cardId?: string;
};

const Theory: React.FC<Props> = ({
  theory,
  title,
  onContinue,
  className,
  collapsed,
  sourceId,
  withDelete,
  coursePreview,
  hideEdit,
  onTheoryEditToggle,
  cardId
}) => {
  const { viewer, hintWasSeen } = useViewerQuery();
  const [collapsedOff, setCollapsedOff] = React.useState(false);
  const [editing, setEditing] = React.useState(false);
  const { t } = useTranslation();
  const bigger = !!title;
  const picked = useTheoryPickedLang(theory);
  const pickedPrev = usePrevious(picked);
  const theoryText = theoryGetContent(theory, picked);
  const isAutoTrans = picked === theory.langNative && !theory.theoryNativeIsChecked;
  const isAutoAndMine = theory.canEdit && !theory.theoryNativeIsChecked;
  const isCollapsed = collapsed && !collapsedOff && !isAutoAndMine;
  const showEmpty = picked === theory.lang && !theory.theoryIsChecked;

  const switchEditing = (to: boolean) => {
    setEditing(to);

    coursePreview && onTheoryEditToggle && cardId && onTheoryEditToggle(to, cardId);
  };

  const turnEditingOn = () => {
    switchEditing(!editing);

    if (!coursePreview) {
      setTimeout(() => {
        const el = document.getElementById(THEORYTEXTID);
        if (el !== null && el.scrollIntoView)
          el.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }, 100);
    }
  };

  React.useEffect(() => {
    if (pickedPrev !== picked && editing) {
      switchEditing(false);
    }
  });

  const handleOpenReportIssueDialog = () => {
    dispatchEvent(EVENT.openReportIssueDialog, {
      type: "theory",
      sCardId: theory.sCardId,
      langBack: theory.langNative,
      theoryOptions: {
        isAutoTrans: isAutoTrans,
        theoryLang: theory.lang,
        displaySwitchToEnglish: isAutoTrans && coursePreview
      }
    });
  };

  return (
    <Wrap
      bigger={bigger}
      className={className}
      $courseStyle={coursePreview}
      onClick={(e) => {
        setCollapsedOff(true);
        e.stopPropagation();
        const ev = document.createEvent("MouseEvents");
        ev.initEvent("mousedown", true, true); // close Word
        document.getElementById("root")?.dispatchEvent(ev);
      }}
    >
      {bigger && <Top />}
      <Content bigger={bigger && !editing} collapsed={isCollapsed} $courseStyle={coursePreview}>
        {isCollapsed && (
          <>
            <SArrowDropDownIcon />
            {theoryText}
          </>
        )}

        {!isCollapsed && (
          <>
            <Header bigger={bigger}>
              {hintWasSeen(FIVEHINT) && !hideEdit && (picked !== "en" || theory.canEdit) ? (
                <ButtWrap
                  onClick={() =>
                    viewer
                      ? theory.canEdit
                        ? turnEditingOn()
                        : handleOpenReportIssueDialog()
                      : alert(t("You have to be registered to suggest edits"))
                  }
                  $courseStyle={coursePreview}
                >
                  <SIconButton $courseStyle={coursePreview}>
                    {editing ? (
                      <CloseIcon />
                    ) : theory.canEdit ? (
                      <EditIcon style={isAutoTrans ? { color: "orange" } : undefined} />
                    ) : (
                      <FontAwesomeIcon icon={faFlag} style={isAutoTrans ? { color: "orange" } : undefined} />
                    )}
                  </SIconButton>
                </ButtWrap>
              ) : (
                <div />
              )}
              {!editing && !coursePreview && (
                <>
                  {title && !isAutoTrans && <Title>{title}</Title>}
                  <TheoryToggler theory={theory} value={picked} />
                </>
              )}
            </Header>

            {editing && (
              <TheoryEditor
                theory={theory}
                coursePreview={coursePreview}
                theoryText={theoryText}
                isAutoTrans={isAutoTrans}
                lang={picked}
                onContinue={() => {
                  onContinue && onContinue();
                  switchEditing(false);
                }}
                bigger={bigger}
              />
            )}

            {!editing && (
              <>
                <TheoryParser
                  theory={showEmpty ? " ---" : theoryText}
                  lang={theory.lang}
                  pickedLang={picked}
                  bigger={bigger}
                  sourceId={sourceId}
                  skipFirstLine={coursePreview}
                />
                {withDelete && theory.canEdit && <TheoryDelete sCardId={theory.sCardId} backLang={theory.langNative} />}
                {onContinue && (
                  <OkBox>
                    <SButton color="primary" variant="contained" onClick={onContinue}>
                      OK
                    </SButton>
                  </OkBox>
                )}
              </>
            )}
          </>
        )}
      </Content>
      {bigger && (
        <Bottom>
          <Line>
            <Knob />
          </Line>
        </Bottom>
      )}
    </Wrap>
  );
};

export default Theory;
