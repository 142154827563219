import React from "react";
import { useSearchParams } from "react-router-dom";
import { isBrowser } from "tools/device";
import { useReferrerSourcesMutation } from "../../queries/users/referrersSources";
import { useViewerQuery } from "../../queries/viewerQuery";

export const REFERRER_PARAM = "r";
export const REFERRER_FROM_URL = "referrerFromUrl";

type Props = {};

const ReferrerCounter: React.FC<Props> = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const referrer = searchParams.get(REFERRER_PARAM);
  const done = React.useRef(false);
  const referrerSources = useReferrerSourcesMutation();
  const { viewer } = useViewerQuery();

  React.useEffect(() => {
    if (referrer && isBrowser() && !done.current) {
      (window as any)[REFERRER_FROM_URL] = referrer;
      if (viewer?.id) {
        referrerSources({ referrer });
      }
      const url = "https://get.duocards.com/r/" + referrer + "?noredirect=1";
      fetch(url);
      done.current = true;
      setSearchParams([], { replace: true });
    }
  }, [referrer, setSearchParams, referrerSources, viewer?.id]);

  return null;
};

export default ReferrerCounter;
