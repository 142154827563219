import React from "react";
import DialogWindow from "../../../../components/other/DialogWindow";
import styled from "styled-components/macro";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useViewerQuery } from "../../../../queries/viewerQuery";
import { SUBSKICKHINT } from "../../../../sharedJs__generated/constants";
import { useMutation } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { SubsKickerMutation } from "./__generated__/SubsKickerMutation.graphql";
import { FEEDBACK, SUBSCRIPTION } from "../../../profile/ProfileRouter.config";

const seeHintQL = graphql`
  mutation SubsKickerMutation($name: String!) {
    seeHint(name: $name) {
      seenHints {
        name
        when
      }
      showSubsKickDialog
    }
  }
`;

const Wrap = styled.div`
  padding: 10px 0 0;
  font-size: 95%;
  line-height: 1.45em;
`;
const SIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
  transition: all 1s;
`;
const Showing = styled.div<{ show: boolean }>`
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 1s;
`;

type CompProps = {
  onClose: VoidFunction;
};

const SubsKickerComponent: React.FC<CompProps> = React.memo(({ onClose }) => {
  const exitRef: any = React.useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showIndex, setShowIndex] = React.useState(-1);

  React.useEffect(() => {
    setTimeout(() => setShowIndex(0), 200);
    setTimeout(() => setShowIndex(1), 1100);
    setTimeout(() => setShowIndex(2), 4100);
    setTimeout(() => setShowIndex(3), 6000);
  }, []);

  return (
    <DialogWindow open={true}>
      <Wrap>
        <SIconButton
          onClick={() => {
            onClose();
            navigate(SUBSCRIPTION.url());
          }}
          style={{ opacity: showIndex >= 3 ? 1 : 0 }}
          ref={exitRef}
        >
          <CloseIcon />
        </SIconButton>

        <Showing show={showIndex >= 0}>
          <p>
            <Trans>Hello</Trans>,
          </p>
        </Showing>
        <Showing show={showIndex >= 1}>
          <p>
            <Trans>
              We are so happy you have been using DuoCards for some time already. You have certainly come across the ads
              and limitations of the free version, which can complicate learning a bit.
            </Trans>
          </p>
        </Showing>
        <Showing show={showIndex >= 2}>
          <Trans parent="p">
            We use ads, but that’s not enough for the app maintenance, third-party APIs and basic marketing. Therefore,
            please consider buying the Premium version, which will support us in further development.
          </Trans>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onClose();
              navigate(SUBSCRIPTION.url());
            }}
          >
            <Trans>Subscription</Trans>
          </Button>
          <p>
            {t("Alternatively *let us know* what you would appreciate in DuoCards to be willing to pay for it.")
              .split("*")
              .map((part, i) => (
                <React.Fragment key={i}>
                  {i === 1 ? (
                    <Link to={FEEDBACK.url()} onClick={onClose}>
                      {part}
                    </Link>
                  ) : (
                    part
                  )}
                </React.Fragment>
              ))}
          </p>
          <p>
            <Trans>Thank you</Trans>!
          </p>
        </Showing>
      </Wrap>
    </DialogWindow>
  );
});

const SubsKicker = () => {
  const { viewer } = useViewerQuery();
  const [seeHint] = useMutation<SubsKickerMutation>(seeHintQL);

  const handleClose = React.useCallback(() => {
    seeHint({
      variables: { name: SUBSKICKHINT },
      optimisticUpdater: (store) => {
        const viewerRec = viewer?.id && store.get(viewer.id);
        viewerRec && viewerRec?.setValue(false, "showSubsKickDialog");
      }
    });
  }, [seeHint, viewer?.id]);

  if (!viewer?.showSubsKickDialog || viewer?.subscription) return null;

  return <SubsKickerComponent onClose={handleClose} />;
};

export default SubsKicker;
