import React from "react";
import styled from "styled-components/macro";
import DialogWindow from "./DialogWindow";
import ShareUrl from "components/other/ShareUrl";

const SDialogWindow = styled(DialogWindow)`
  .MuiPaper-root {
    width: calc(100% - 64px);
  }
`;

type Props = {
  uri: string;
  open: boolean;
  onClose: VoidFunction;
};
const SharePopup: React.FC<Props> = ({ uri, open, onClose }) => {
  const shareUrl = `https://app.duocards.com${uri}`;

  return (
    <SDialogWindow open={open} onClose={onClose}>
      <ShareUrl url={shareUrl} />
    </SDialogWindow>
  );
};

export default SharePopup;
