/**
 * @generated SignedSource<<cc3c78a32af80abb8ad58b5aacbe9b0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NativeCreateMutation$variables = {
  backsJson?: string | null;
  langNative: string;
  nameNative: string;
  sourceId: string;
};
export type NativeCreateMutation$data = {
  readonly nativeCreate: {
    readonly natives: ReadonlyArray<{
      readonly langNative: string;
      readonly nameNative: string;
    }>;
  };
};
export type NativeCreateMutation = {
  response: NativeCreateMutation$data;
  variables: NativeCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "backsJson"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "langNative"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nameNative"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "backsJson",
    "variableName": "backsJson"
  },
  {
    "kind": "Variable",
    "name": "langNative",
    "variableName": "langNative"
  },
  {
    "kind": "Variable",
    "name": "nameNative",
    "variableName": "nameNative"
  },
  {
    "kind": "Variable",
    "name": "sourceId",
    "variableName": "sourceId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "langNative",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameNative",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NativeCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "nativeCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Native",
            "kind": "LinkedField",
            "name": "natives",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NativeCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Source",
        "kind": "LinkedField",
        "name": "nativeCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Native",
            "kind": "LinkedField",
            "name": "natives",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5076515887e956f2661aa2d61c14e7b4",
    "id": null,
    "metadata": {},
    "name": "NativeCreateMutation",
    "operationKind": "mutation",
    "text": "mutation NativeCreateMutation(\n  $sourceId: ID!\n  $nameNative: String!\n  $langNative: String!\n  $backsJson: String\n) {\n  nativeCreate(sourceId: $sourceId, nameNative: $nameNative, langNative: $langNative, backsJson: $backsJson) {\n    natives {\n      langNative\n      nameNative\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d1a9bc9746f6134870e50a2f73a79fb6";

export default node;
