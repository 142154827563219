import MammothIcon from "icons/MammothIcon";
import React from "react";
import styled from "styled-components/macro";

const SPinkMamooth = styled(MammothIcon)`
  position: absolute;
  left: 210px;
  top: 80px;
  transform: rotateY(0deg);
`;

type Props = {
  className?: string;
};

const PinkMamooth: React.FC<Props> = ({ className }) => {
  return <SPinkMamooth className={className} pink />;
};

export default PinkMamooth;
