import { shuffle } from "lodash";
import React from "react";
import { usePrevious } from "react-use";
import { loopQuery_loop$data } from "../../../queries/decks/__generated__/loopQuery_loop.graphql";
import { useViewerQuery } from "../../../queries/viewerQuery";
import PairsComp from "./PairsComp";
import { useSeeHint } from "components/hint/Hint";

const FIRSTPARISHINT = "firstPairs";

type Props = {
  cards: loopQuery_loop$data["loop"];
  cardsLoading: boolean;
  hideIfBehind: boolean;
  onIsPrepared: (val: boolean) => void;
};

const Pairs: React.FC<Props> = ({ cards, cardsLoading, hideIfBehind, onIsPrepared }) => {
  const { hintWasSeen } = useViewerQuery();
  const prevFirstId = usePrevious(cards[0]?.id);
  const [picked, setPicked] = React.useState<{
    front: loopQuery_loop$data["loop"];
    back: loopQuery_loop$data["loop"];
  }>();
  const [behind, setBehind] = React.useState(true);
  const seeHint = useSeeHint();

  const isPrevDifferent = cards.length && prevFirstId && prevFirstId !== cards[0]?.id;
  React.useEffect(() => {
    if (isPrevDifferent) {
      behind && setBehind(false);
    }
  }, [behind, isPrevDifferent]);

  const handleIsPrepared = (val: boolean) => {
    if (!val) {
      setPicked(undefined);
      seeHint(FIRSTPARISHINT);
    }
    onIsPrepared(val);
  };

  React.useEffect(() => {
    if (picked || cardsLoading) return;

    const firstTime = !hintWasSeen(FIRSTPARISHINT);

    const filtered = cards
      .slice(firstTime && cards.length >= 5 ? cards.length - 5 : 3)
      .filter((c) => c?.knownCount || 0 <= 3)
      .sort((a, b) =>
        (a.knownCount === 3 || b.knownCount === 3) && a.knownCount !== b.knownCount
          ? a.knownCount - b.knownCount
          : b.failCount - a.failCount
      )
      .slice(0, 5);

    if (filtered.length === 5 && (filtered[3].failCount !== 0 || firstTime)) {
      const back = shuffle(filtered);
      const front = shuffle(filtered);
      setPicked({ back, front });
      setBehind(true);
      handleIsPrepared(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cards, picked, hintWasSeen, cardsLoading]);

  if (!picked || (behind && hideIfBehind && !isPrevDifferent)) return null;

  return <PairsComp behind={behind} cards={picked} onIsPrepared={handleIsPrepared} />;
};

export default Pairs;
