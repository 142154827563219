import React from "react";
import styled from "styled-components/macro";
import LoadingBar from "../components/LoadingBar";

const Wrap = styled.div<{ collapsed: boolean }>`
  max-height: ${({ collapsed }) => (collapsed ? 0 : 150)}px;
  transition: max-height 500ms;
  margin: ${({ collapsed }) => (collapsed ? "0" : "-14px 0 11px")};
  overflow: hidden;
`;
const Example = styled.div`
  display: inline-block;
  background: white;
  font-size: 13px;
  line-height: 1.2em;
  color: #7a8;
  padding: 3px 8px;
  margin: 0 0 1px;
  border: 1px solid #7a85;
  border-radius: 6px;
  position: relative;
  z-index: 10;
`;

type Props = {
  examples?: readonly string[];
  collapsed: boolean;
  lang: string;
  onPick: (hint: string) => void;
  loading?: boolean;
};

const Examples: React.FC<Props> = ({ examples, lang, onPick, collapsed, loading }) => {
  return (
    <>
      <LoadingBar loading={!!loading} />
      <Wrap collapsed={collapsed || !examples?.length}>
        {examples &&
          examples.map((hint) => (
            <Example key={hint} onClick={() => onPick(hint)}>
              {hint}
            </Example>
          ))}
      </Wrap>
    </>
  );
};

export default Examples;
