import { Button, Paper, Radio, Stack } from "@mui/material";
import React from "react";
import styled, { css } from "styled-components/macro";
import { Trans, useTranslation } from "react-i18next";
import { useViewerQuery } from "../../queries/viewerQuery";
import { useNavigate } from "react-router";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import { useDeviceLang } from "hooks/deviceHooks";
import LangDisplay from "components/LangMenu/LangDisplay";
import { useDispatch } from "react-redux";
import { setPickedLang } from "components/ReduxProvider";
import AppLangMenu from "components/LangMenu/AppLangMenu";
import { useSeeHint } from "components/hint/Hint";
import { MAIN, VERIFY } from "../RootRouter.config";

export const GENERATED_APPLANG_HINT = "generatedAppLangHint";

const TopIco = styled.div`
  margin-bottom: 10px;
  svg {
    color: ${({ theme }) => theme.duo.color.primary};
    font-size: 40px;
  }
`;
const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
`;
const SPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 85%;
  padding: 50px 30px;
  background: #fff !important;
`;
const Title = styled.div`
  font-size: ${({ theme }) => theme.duo.fontSize.medium};
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
  margin: 20px 0;
`;
const Text = styled.div`
  margin-bottom: 20px;
  font-size: 95%;
`;
const RadioRow = styled.div<{ selected?: boolean; first?: boolean }>`
  border: 1px solid white;
  padding: 17px 20px;
  cursor: pointer;

  ${(p) =>
    !p.first &&
    css`
      border-top: 1px solid ${({ theme }) => theme.duo.color.grey};
    `}

  ${(p) =>
    p.selected &&
    css`
      color: ${({ theme }) => theme.duo.color.primary};
      border: 1px solid #0099dd !important;
      border-radius: 4px;
    `}
`;
const SLangDisplay = styled(LangDisplay)`
  font-size: ${({ theme }) => theme.duo.fontSize.normal};
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
`;
const ButtonStack = styled(Stack)`
  margin-top: 30px;
`;

type Props = {
  onSave?: VoidFunction;
};

const GeneratedAppLangWarning: React.FC<Props> = ({ onSave }) => {
  const { viewer } = useViewerQuery();
  const navigate = useNavigate();
  const [selectedVariant, setSelectedVariant] = React.useState(0);
  const [selectedLang, setSelectedLang] = React.useState("en");
  const { browserLang } = useDeviceLang();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const seeHint = useSeeHint();

  React.useEffect(() => {
    seeHint(GENERATED_APPLANG_HINT);
  });

  React.useEffect(() => {
    if (selectedVariant === 1) dispatch(setPickedLang(selectedLang));
    if (selectedVariant === 2) dispatch(setPickedLang(browserLang));
  });

  const handleSave = () => {
    if (onSave) {
      onSave();
      return;
    }

    if (!viewer?.email || viewer?.emailVerified) {
      navigate(MAIN.url(), { replace: false });
    } else {
      navigate(VERIFY.url(), { replace: false });
    }
  };

  return (
    <Wrap>
      <SPaper>
        <TopIco>
          <GTranslateIcon />
        </TopIco>
        <Title>
          <Trans>Application language</Trans>
        </Title>
        <Text>
          {t(
            "The app is displayed in your native language. These translations are only generated by a machine and can contain mistakes."
          )}{" "}
          <Trans>Do you want to choose another language?</Trans>
        </Text>

        <RadioRow selected={selectedVariant === 1} onClick={() => setSelectedVariant(1)} first={true}>
          <Stack direction="row" alignItems="center" justifyContent="middle" spacing={2}>
            <Radio checked={selectedVariant === 1} color="primary" />
            <SLangDisplay lang={selectedLang} />
          </Stack>
        </RadioRow>

        <RadioRow selected={selectedVariant === 2} onClick={() => setSelectedVariant(2)}>
          <Stack direction="row" alignItems="center" justifyContent="middle" spacing={2}>
            <Radio checked={selectedVariant === 2} color="primary" />
            <SLangDisplay lang={browserLang} showAutoGenerated={true} />
          </Stack>
        </RadioRow>

        <ButtonStack direction="row" alignItems="center" justifyContent="space-between">
          <AppLangMenu
            title={
              <Button variant="text">
                <Trans>Show more</Trans>
              </Button>
            }
            hideDeviceLanguage
            onSelect={(lang) => setSelectedLang(lang)}
            value={selectedLang}
          />

          <Button color="primary" variant="contained" onClick={handleSave} disabled={selectedVariant === 0}>
            <Trans>Save</Trans>
          </Button>
        </ButtonStack>
      </SPaper>
    </Wrap>
  );
};

export default GeneratedAppLangWarning;
