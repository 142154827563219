import React from "react";
import { useSetState } from "components/ReduxProvider";
import DialogWindow from "components/other/DialogWindow";
import styled from "styled-components/macro";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { SOURCE } from "root/library/library/libraryUrls";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import SourceCard from "root/library/library/SourceCard";
import { useSourceCardQuery } from "queries/sources/sourceCardQuery";

const SDialogWindow = styled(DialogWindow)`
  .MuiDialog-paper {
    width: 100%;
  }
`;
const SSourceWrap = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 20px 0 25px;
`;
const ContentWrap = styled.div`
  padding: 25px 0 30px;
`;
const H1 = styled.h1`
  margin: 10px 25px;
`;

type Props = {
  sourceId: string | undefined;
  langNative: string | undefined;
  onClose: VoidFunction;
  title?: string;
  buttonText?: string;
};

const SourceView: React.FC<Props> = ({ sourceId, langNative, onClose, title, buttonText }) => {
  const setLoaderGlobal = useSetState("loaderGlobal");
  const source = useSourceCardQuery(sourceId || "", langNative);
  const { t } = useTranslation();
  const navigate = useNavigate();

  // show loader until source is loaded
  React.useEffect(() => {
    setLoaderGlobal(!source);
  }, [source, setLoaderGlobal]);

  if (!source) return null;

  return (
    <SDialogWindow open={true} onClose={onClose} noContentPadding consumeHeader>
      <ContentWrap>
        {title && <H1>{t(title)}</H1>}
        {!title && (
          <H1>
            {source.kind === "set" && t("Set of cards")}
            {source.kind === "video" && t("Video")}
            {source.kind === "article" && t("Article")}
          </H1>
        )}
        <SSourceWrap>
          <SourceCard source={source} isLatestVisited />
        </SSourceWrap>

        <Stack alignItems="center">
          <Button color="primary" variant="contained" onClick={() => navigate(SOURCE.url(source.uri, langNative))}>
            {buttonText ? t(buttonText) : t("Go to source")}
          </Button>
        </Stack>
      </ContentWrap>
    </SDialogWindow>
  );
};

export default SourceView;
