import React from "react";
import { Button, IconButton } from "@mui/material";
import { graphql } from "babel-plugin-relay/macro";
import { useEnvironment } from "../../../graphql/environment";
import { fetchQuery } from "relay-hooks";
import * as Sentry from "@sentry/browser";
import { FeatureTestsSentryQuery } from "./__generated__/FeatureTestsSentryQuery.graphql";
import styled from "styled-components/macro";
import { isAndroid, isApp, isIos } from "tools/device";
import { useSubscribe, useSubscriptions } from "root/profile/subscription/subscriptionHooks";
import { HeaderLayout } from "components/header/Header";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RefreshIcon from "@mui/icons-material/Refresh";
import CampaignIcon from "@mui/icons-material/Campaign";
import ShareIcon from "@mui/icons-material/Share";
import InfoIcon from "@mui/icons-material/Info";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SettingsIcon from "@mui/icons-material/Settings";
import StarIcon from "@mui/icons-material/Star";
import { initAndLoadInterstitial } from "components/ads/AdsLoader";
import { windowReload } from "tools/windowReload";
import launchRating from "tools/launchRatings";
import { useGetState, useSetState } from "components/ReduxProvider";

const query = graphql`
  query FeatureTestsSentryQuery($internalUndefined: Boolean, $throwError: Boolean) {
    sentryTest(internalUndefined: $internalUndefined, throwError: $throwError)
  }
`;

const Check = styled.div<{ ok: boolean }>`
  color: ${({ ok }) => (ok ? "green" : "red")};
`;

const FeatureTests: React.FC<{}> = () => {
  const testVal = "someString";
  const environment = useEnvironment();
  const { store } = useSubscriptions();
  const subscribe = useSubscribe();
  const logUIActive = useGetState("logUIActive");
  const setLogUIActive = useSetState("logUIActive");

  const qr = (vars) => fetchQuery<FeatureTestsSentryQuery>(environment, query, vars);

  return (
    <HeaderLayout title="Feature tests" style={{ textAlign: "center" }}>
      <h1>Plugins checks:</h1> {/* @ts-ignore*/}
      {!isApp() ? (
        <>Cordova N/A</>
      ) : (
        <>
          <Check ok={!!window.cordova?.plugins?.firebase?.messaging}>
            cordova-plugin-firebase-messaging
            <IconButton
              onClick={() => {
                window.cordova?.plugins?.firebase?.messaging
                  .requestPermission()
                  .then(() => {
                    alert("notifications permission is granted");
                  })
                  .catch((e) => alert("error: " + JSON.stringify(e)));
              }}
            >
              <InfoIcon />
            </IconButton>
          </Check>
          <Check ok={!!store}>
            cordova-plugin-purchase{" "}
            <IconButton onClick={() => subscribe()}>
              <ShoppingCartIcon />
            </IconButton>
          </Check>
          <Check ok={!!window.admob}>
            admob-plus-cordova
            <IconButton
              disabled={!window.admob}
              onClick={async () => {
                const interstitial = await initAndLoadInterstitial();
                setTimeout(() => {
                  interstitial.show();
                }, 2000);
              }}
            >
              <CampaignIcon />
            </IconButton>
          </Check>
          <Check ok={!!window.consent}>
            admob-plugin-consent
            <IconButton
              disabled={!window.consent}
              onClick={async () => {
                try {
                  const canRequest = await window.consent.canRequestAds();
                  alert(canRequest ? "can request ads" : "can't request ads");
                } catch (e: any) {
                  alert("error: " + e.message);
                }
              }}
            >
              <InfoIcon />
            </IconButton>
            <IconButton
              disabled={!window.consent}
              onClick={() => {
                window.consent
                  .reset()
                  .then(windowReload) // reload to show consent form
                  .catch((e) => alert("error: " + e.message));
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Check>

          <Check ok={!!navigator.share}>
            cordova-plugin-share
            <IconButton
              onClick={() => {
                // @ts-ignore
                navigator.share("test", undefined, "text/plain");
              }}
            >
              <ShareIcon />
            </IconButton>
          </Check>

          <Check ok={!!window.LaunchReview}>
            cordova-launch-review
            <IconButton onClick={() => launchRating()}>
              <StarIcon />
            </IconButton>
          </Check>

          <Check ok={!!window.cordova?.plugins?.settings}>
            cordova-open-native-settings
            <IconButton onClick={() => window.cordova?.plugins?.settings?.open("notification_id")}>
              <SettingsIcon />
            </IconButton>
          </Check>

          <Check ok={!!window.cordova?.plugins?.firebase?.analytics}>
            cordova-plugin-firebase-analytics
            <IconButton onClick={() => window.cordova?.plugins?.firebase?.analytics?.logEvent("test")}>
              <ForwardToInboxIcon />
            </IconButton>
          </Check>

          <Check ok={!!(window as any).facebookConnectPlugin}>
            cordova-plugin-facebook-connect
            <IconButton onClick={() => (window as any).facebookConnectPlugin?.logEvent("test")}>
              <ForwardToInboxIcon />
            </IconButton>
          </Check>

          <Check ok={!!window.cordova?.plugins?.SingularCordovaSdk}>
            singular_cordova_sdk
            <IconButton
              onClick={() =>
                window.cordova?.plugins?.SingularCordovaSdk.eventWithArgs("TEST_EVENT", { test: "test_content" })
              }
            >
              <ForwardToInboxIcon />
            </IconButton>
          </Check>

          <Check ok={!!window.audioinput}>cordova-plugin-audioinput</Check>

          {isAndroid() && <Check ok={!!(window as any).ClarityPlugin}>cordova-clarity</Check>}

          {isIos() && <Check ok={!!(window as any).Adjust}>com.adjust.sdk</Check>}
        </>
      )}
      <h1>Sentry tests:</h1> {/* @ts-ignore*/}
      <Button onClick={() => testVal.a()}>React - call function on undefined</Button>
      <Button onClick={() => Sentry.captureMessage("TEST Sentry.captureMessage")}>React - captureMessage</Button>
      <Button onClick={() => qr({ internalUndefined: true }).subscribe({})}>Express - fn on undefined</Button>
      <Button onClick={() => qr({ throwError: true }).subscribe({})}>Express - unknown error</Button>
      <div>window.appversion: {(window as any).appversion}</div>
      <Button onClick={() => setLogUIActive(!logUIActive)}>{logUIActive ? "disable ui logs" : "enable ui logs"}</Button>
    </HeaderLayout>
  );
};

export default FeatureTests;
