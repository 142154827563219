/**
 * @generated SignedSource<<ed23257facdc2376973abfa53e34ba8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginGuestMutation$variables = {};
export type LoginGuestMutation$data = {
  readonly loginGuest: {
    readonly token: string;
  } | null;
};
export type LoginGuestMutation = {
  response: LoginGuestMutation$data;
  variables: LoginGuestMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginGuestMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "loginGuest",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LoginGuestMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "loginGuest",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e9da914a2195991ecfacdbb28f5295e2",
    "id": null,
    "metadata": {},
    "name": "LoginGuestMutation",
    "operationKind": "mutation",
    "text": "mutation LoginGuestMutation {\n  loginGuest {\n    token\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "dc04a47ca0fe5d85b7b662b9bb45da66";

export default node;
