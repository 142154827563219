import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { finishSourceMutation } from "./__generated__/finishSourceMutation.graphql";

const mutation = graphql`
  mutation finishSourceMutation($id: ID!, $finished: Boolean!) {
    finishSource(finished: $finished, id: $id) {
      id
      finished
    }
  }
`;

export const useFinishSource = () => {
  const [mutate, { loading }] = useMutation<finishSourceMutation>(mutation);

  const finishSource = (variables: finishSourceMutation["variables"]) =>
    mutate({
      variables: {
        ...variables
      },
      optimisticUpdater: (store) => {
        const { id, finished } = variables;
        const sourceRec = store.get(id || "");

        if (!sourceRec) return;
        const rating = sourceRec?.getLinkedRecord("rating");
        if (!rating) return;
        rating.setValue(finished ? Date.now() : null, "finished");
      }
    });

  return { finishSource, loading };
};
