import React from "react";
import styled from "styled-components/macro";
import PickCard from "./PickCard";
import SCardForm from "./SCardForm";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { incAdStack, useGetState, useSetState } from "../ReduxProvider";
import PickerNoCards from "./PickerNoCards";
import { usePrevious } from "react-use";
import { useViewerQuery, useViewerDecks } from "queries/viewerQuery";
import Loader from "../other/Loader";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { TTheory } from "types/TheoryTypes";
import { useCardCreate } from "queries/cards/cardCreate";
import { useBurnWithSource } from "components/fire/xpHooks";
import { sourceQuery$data } from "queries/sources/__generated__/sourceQuery.graphql";
import { snackbar } from "tools/events";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { useKeyMy } from "hooks/useKeyMy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCardsBlank } from "@fortawesome/pro-solid-svg-icons";
import { SEARCH, useSearchParamTools } from "tools/searchParams";

export const ICON5HINT = "icon5hint";
export const PICKERID = "pickerId";

const Wrap = styled.div`
  margin: 15px 0;
  flex-grow: 1;
  position: relative;
`;
const Decks = styled.div`
  & > * {
    position: absolute;
    width: 86%;
    height: 30%;
    left: 7%;
    font-weight: bold;
    font-size: 90%;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    font-size: 30px;
  }
`;
const ToLearn = styled.div`
  background: ${({ theme }) => theme.duo.color.green}aa;
  box-shadow: 0 0 5px ${({ theme }) => theme.duo.color.green}aa;
  color: ${({ theme }) => theme.duo.color.green};
`;
const Dismiss = styled.div`
  bottom: 0;
  background: #ddd;
  box-shadow: 0 0 5px #ddd;
  color: ${({ theme }) => theme.duo.color.textGrey};
`;
const In = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.duo.color.lightGrey};
  bottom: -20px;
  border-radius: 50px;
  padding: 8px 12px;
  cursor: pointer;
  &.upper {
    bottom: auto;
    top: -20px;
  }
`;
const STouchAppIcon = styled(TouchAppIcon)`
  position: absolute;
  top: 45%;
  left: 55%;
  color: ${({ theme }) => theme.duo.color.primary};
  z-index: -1; // 1030
  font-size: 60px;
  pointer-events: none;
  transition: top 600ms ease-in-out;
  filter: drop-shadow(0px 0px 3px #fff);
`;
const SLearningIcon = styled(FontAwesomeIcon)`
  font-size: 24px !important;
  transform: rotate(20deg);
`;
const TutorialPlaceholder = styled.div`
  height: 66%;
  margin: -2px -15px;
  width: calc(86% + 30px);
`;

export type PickerCard = {
  readonly id: string;
  readonly front: string;
  readonly hint: string | null;
  readonly pronunciation: string | null;
  readonly back: {
    readonly value: string;
    readonly sBackId: string | null;
  } | null;
  readonly theory: TTheory | null;
  readonly svg?: any | null;
  readonly isInMyDeck: boolean | null;
};
type Props = {
  source: sourceQuery$data["sourceByUriOrId"];
};

const Picker: React.FC<Props> = ({ source }) => {
  const [dialogCard, setDialogCard] = React.useState<PickerCard | undefined>();
  const { t } = useTranslation();
  const { viewer, hintWasSeen } = useViewerQuery();
  const { deck } = useViewerDecks();
  const [dragged, setDragged] = React.useState<{ isInMy: boolean; id: string }[]>();
  const deckId = useGetState("deckId");
  const { cardCreate } = useCardCreate();
  const burnW = useBurnWithSource(source.lang);
  const sCards = source.sCards;
  const noCards = sCards.length - (dragged?.length || 0) <= 0;
  const isLastCard = sCards.length - (dragged?.length || 0) === 1;
  const noCardsPrev = usePrevious<boolean>(noCards);
  const setDeckCreate = useSetState("deckCreate");
  const { navigateSearchParam, backSearchParam, getSearchParam } = useSearchParamTools();

  React.useEffect(() => {
    if (source.course && noCards && !noCardsPrev) {
      snackbar(t("You have finished the course!"), { severity: "info", confetti: true });
    }
  });

  React.useEffect(() => {
    if (!getSearchParam(SEARCH.editCard) && dialogCard) {
      setDialogCard(undefined); // if native "back" button is pressed when dialog is open
    }
  }, [getSearchParam, dialogCard]);

  React.useEffect(() => {
    if (dragged === undefined && sCards.length) {
      let init: { isInMy: boolean; id: string }[] = [];
      [...sCards].reverse().forEach((c) => {
        c.isInMyDeck === true && init.push({ id: c.id, isInMy: true });
        c.isInMyDeck === false && init.push({ id: c.id, isInMy: false });
      });
      setDragged(init);
    }
  }, [sCards, dragged]);

  const handleSave = (sCard: PickerCard, added: boolean) => {
    if (!hintWasSeen(TUTORIALDONE) && !added) return;
    const back = sCard.back?.value;
    if (!deckId || back === undefined || deck?.front !== source.lang) {
      return source.lang && setDeckCreate({ lang: source.lang });
    }
    cardCreate(
      {
        front: sCard.front,
        hint: sCard.hint,
        sCardId: sCard.id,
        sourceId: source.id,
        back,
        sBackId: sCard.back?.sBackId,
        deckId,
        deleted: !added,
        svg: sCard.svg
      },
      isLastCard
    );

    incAdStack(added ? 4 : 2);
    burnW(added ? 1 : 0);

    setDragged((dragged) => [{ id: sCard.id, isInMy: added }, ...(dragged || []).filter((d) => d.id !== sCard.id)]);
    handleClose();
  };

  const handleClose = () => {
    backSearchParam(SEARCH.editCard);
  };

  const activeCard = sCards.find((c) => c.isInMyDeck === null);
  useKeyMy("ArrowUp", () => activeCard && handleSave(activeCard, true), undefined, [handleSave]);
  useKeyMy("ArrowDown", () => activeCard && handleSave(activeCard, false), undefined, [handleSave]);

  const handleDialog = (c) => {
    navigateSearchParam(SEARCH.editCard, "1");
    if (!c.back || deck?.front !== source.lang) {
      return source.lang && setDeckCreate({ lang: source.lang });
    }
    !viewer?.name ? alert(t("You have to be registered to suggest edits")) : setDialogCard(c);
  };

  if (dragged === undefined) return <Loader />;

  let printCards: PickerCard[] = [];
  sCards.some((c) => {
    c.isInMyDeck === null && printCards.push(c);
    return printCards.length >= 4;
  });
  const enoughIn = () => printCards.filter((c) => c.isInMyDeck === true).length >= 4;
  const enoughDel = () => printCards.filter((c) => c.isInMyDeck === false).length >= 4;
  dragged &&
    dragged.some(({ id, isInMy }) => {
      const c = sCards.find((c) => c.id === id);
      c && isInMy && !enoughIn() && printCards.push(c);
      c && !isInMy && !enoughDel() && printCards.push(c);
      return enoughIn() && enoughDel();
    });

  let index = 0;

  return (
    <Wrap>
      <div>
        <Decks>
          <ToLearn>
            <In onClick={() => activeCard && handleSave(activeCard, true)}>
              <SLearningIcon icon={faCardsBlank} />
              &nbsp;
              {t("To learn")}
            </In>
          </ToLearn>
          <Dismiss>
            <In className="upper" onClick={() => activeCard && handleSave(activeCard, false)}>
              <DeleteIcon />
              {t("Already knew / dismiss")}
            </In>
          </Dismiss>
          <TutorialPlaceholder id={PICKERID} />
        </Decks>

        {sCards
          .filter((c) => printCards.find((pc) => pc.id === c.id))
          .map((c) => (
            <PickCard
              source={source}
              sCard={c}
              lang={source.lang}
              key={c.id}
              onSave={handleSave}
              onDialog={handleDialog}
              index={
                c.isInMyDeck === true
                  ? dragged?.filter((d) => d.isInMy === true).findIndex((d) => d.id === c.id) || 0
                  : c.isInMyDeck === false
                    ? dragged?.filter((d) => d.isInMy === false).findIndex((d) => d.id === c.id) || 0
                    : index++
              }
            />
          ))}
      </div>

      <STouchAppIcon id={ICON5HINT} />

      {noCards && <PickerNoCards source={source} />}

      <SCardForm card={dialogCard} source={source} onClose={handleClose} />
    </Wrap>
  );
};

export default Picker;
