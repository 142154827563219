/**
 * @generated SignedSource<<e55499ee1b1cb4b4557ffd5e67186650>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GenericVariableInput = {
  key: string;
  value: string;
};
export type useAIRequestQuery$variables = {
  template: string;
  variables?: ReadonlyArray<GenericVariableInput> | null;
};
export type useAIRequestQuery$data = {
  readonly aiRequest: string;
};
export type useAIRequestQuery = {
  response: useAIRequestQuery$data;
  variables: useAIRequestQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "template"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "variables"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "template",
        "variableName": "template"
      },
      {
        "kind": "Variable",
        "name": "variables",
        "variableName": "variables"
      }
    ],
    "kind": "ScalarField",
    "name": "aiRequest",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useAIRequestQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useAIRequestQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8a088ca9c7d3bbfe1ee615e998d5cf7f",
    "id": null,
    "metadata": {},
    "name": "useAIRequestQuery",
    "operationKind": "query",
    "text": "query useAIRequestQuery(\n  $template: String!\n  $variables: [GenericVariableInput!]\n) {\n  aiRequest(template: $template, variables: $variables)\n}\n"
  }
};
})();

(node as any).hash = "59a7196064fe903653772da27a548fc0";

export default node;
