/**
 * @generated SignedSource<<2b98d41ead59dba377085fed90be7d30>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type addReportedIssueMutation$variables = {
  langBack?: string | null;
  reasons: ReadonlyArray<string>;
  sBackId?: string | null;
  sCardId: string;
};
export type addReportedIssueMutation$data = {
  readonly addReportedIssue: boolean | null;
};
export type addReportedIssueMutation = {
  response: addReportedIssueMutation$data;
  variables: addReportedIssueMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "langBack"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "reasons"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sBackId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sCardId"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "langBack",
        "variableName": "langBack"
      },
      {
        "kind": "Variable",
        "name": "reasons",
        "variableName": "reasons"
      },
      {
        "kind": "Variable",
        "name": "sBackId",
        "variableName": "sBackId"
      },
      {
        "kind": "Variable",
        "name": "sCardId",
        "variableName": "sCardId"
      }
    ],
    "kind": "ScalarField",
    "name": "addReportedIssue",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "addReportedIssueMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "addReportedIssueMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "8440e0d1a3900573817ed320ac73178b",
    "id": null,
    "metadata": {},
    "name": "addReportedIssueMutation",
    "operationKind": "mutation",
    "text": "mutation addReportedIssueMutation(\n  $sCardId: ID!\n  $sBackId: ID\n  $langBack: String\n  $reasons: [String!]!\n) {\n  addReportedIssue(sCardId: $sCardId, sBackId: $sBackId, langBack: $langBack, reasons: $reasons)\n}\n"
  }
};
})();

(node as any).hash = "8440d5981038af798db051daaf65388f";

export default node;
