import { createApi } from "unsplash-js";
import { LibCardW, LibCardH } from "root/library/library/SourceCard";
import { ArticleHeaderH } from "root/library/source/Article";

export type Image = {
  url: string;
  authorName: string;
  authorApiName: string;
};

export const unsplash = createApi({ accessKey: "w_dLNKoexzVjcY028XLZiRoY7ZLGvCr2LscEEpNJ4bY" });

const defaultSet = {
  url: "https://images.unsplash.com/photo-1422036306541-00138cae4dbc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjE0MjI0NX0",
  authorName: "Andrew Pons",
  authorApiName: "imandrewpons"
};
const defaultArticle = {
  url: "https://images.unsplash.com/photo-1504711434969-e33886168f5c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjE0MjI0NX0",
  authorApiName: "freegraphictoday",
  authorName: "AbsolutVision"
};
export const fromSourceOrDefault = (source: {
  kind?: string;
  image?: { url: string; authorName?: string; authorApiName?: string } | null;
}) => {
  return source.image || (source.kind === "set" ? defaultSet : defaultArticle);
};

export const imgHeaderFormat = (url: string) => `url(${url}&fit=crop&w=800&h=${ArticleHeaderH + 200}&dpr=2)`; // +200 otherwise it's too wide for mobiles

export const imgLibRowFormat = (url: string) => `url(${url}&fit=crop&w=${LibCardW}&h=${LibCardH}&dpr=2)`;

export const imgCourseFormat = (url: string) => `url(${url}&fit=crop&w=600&h=337&dpr=2)`;

export const imgOGFormat = (url: string) => `${url}&fit=crop&w=1200&h=630`;

// not from unspash, but whatever

export const ytimg = (videoId?: string | null) => `https://i.ytimg.com/vi/${videoId}/mqdefault.jpg`;
export const ytbg = (videoId?: string | null) => `url(${ytimg(videoId)})`;
