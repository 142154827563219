import { useViewerFriends, useViewerQuery } from "queries/viewerQuery";
import { FRIENDSREWARD } from "../../sharedJs__generated/constants";

export const useCanAdvertFriends = () => {
  const { viewer } = useViewerQuery();
  const { friends } = useViewerFriends();

  return !!(
    viewer &&
    viewer.verifiedBeforeDays &&
    viewer.verifiedBeforeDays >= 6 &&
    (!viewer.subscription || viewer.subscription?.storeId === FRIENDSREWARD) &&
    Number(friends?.edges?.length) < 24
  );
};
