import React from "react";
import { useEffectOnce } from "react-use";
import styled, { css, keyframes } from "styled-components/macro";

const PLACEMENT_START = 5300;
export const ANIMDUR = 7000; // 500ms extra for slower phones

const rotatingKF = keyframes`
    0% { transform: scale(0.2) rotate(360deg); }
    40% { transform: scale(0.5) rotate(-720deg); }
    65% { transform: scale(2) rotate(-720deg); }
    85% { transform: scale(1)  }
    95% { transform: scale(1.1) }
    100% { transform: scale(1) }`;
const rotatingFirstKF = keyframes`
  0%, 5% { transform: scale(0.01) rotate(360deg); }
    50% { transform: scale(2) rotate(-720deg); }
    70% { transform: scale(1); }
    85% { transform: scale(1.4) }
    100% { transform: scale(1) }`;

const Wrap = styled.div<{ finalPlacement: boolean; first: boolean }>`
  > * {
    transition:
      all 1000ms ease-in-out,
      left 1000ms ease-in-out,
      top 1000ms ease-in-out;
    transform-origin: center;
    animation: ${({ first }) => (first ? rotatingFirstKF : rotatingKF)} ${ANIMDUR}ms forwards;
    filter: drop-shadow(1px 1px 5px #fff);
    z-index: 10;

    ${({ finalPlacement }) =>
      !finalPlacement &&
      css`
        left: 145px !important;
        top: 68px !important;
        width: 55px;
        height: 55px;
      `}
  }
  .hideOnAnimation {
    display: none;
  }
`;

type Props = {
  item: React.ReactNode;
  firstAnimation: boolean;
};

const ItemAnimation: React.FC<Props> = ({ item, firstAnimation }) => {
  const [finalPlacement, setFinalPlacement] = React.useState(false);

  useEffectOnce(() => {
    setTimeout(() => setFinalPlacement(true), PLACEMENT_START);
  });
  return (
    <Wrap first={firstAnimation} finalPlacement={finalPlacement}>
      {item}
    </Wrap>
  );
};

export default ItemAnimation;
