/**
 * @generated SignedSource<<3137e41c445872f517757db081581270>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Difficulty = "beginner" | "easy" | "hard" | "moderate" | "%future added value";
export type Kind = "article" | "set" | "video" | "%future added value";
export type sourceEditQuery$variables = {
  backLang?: string | null;
  id: string;
};
export type sourceEditQuery$data = {
  readonly node: {
    readonly contentTest?: {
      readonly active: boolean;
    } | null;
    readonly course?: boolean;
    readonly difficulty?: Difficulty;
    readonly duoLang?: boolean;
    readonly id?: string;
    readonly image?: {
      readonly url: string;
    } | null;
    readonly imageId?: string | null;
    readonly kind?: Kind;
    readonly lang?: string;
    readonly multiLang?: boolean;
    readonly name?: string;
    readonly nameRaw?: string | null;
    readonly natives?: ReadonlyArray<{
      readonly langNative: string;
      readonly nameNative: string;
    }>;
    readonly private?: boolean;
    readonly sCards?: ReadonlyArray<{
      readonly back: {
        readonly translated: boolean | null;
        readonly value: string;
      } | null;
      readonly front: string;
      readonly hint: string | null;
      readonly id: string;
      readonly svg: {
        readonly flatId: string;
        readonly url: string;
      } | null;
      readonly theory: {
        readonly canEdit: boolean;
        readonly lang: string;
        readonly langNative: string;
        readonly sCardId: string;
        readonly theory: string;
        readonly theoryEn: string | null;
        readonly theoryIsChecked: boolean;
        readonly theoryNative: string;
        readonly theoryNativeIsChecked: boolean;
      } | null;
    }>;
    readonly source?: string | null;
    readonly text?: string | null;
    readonly uri?: string;
    readonly videoDuration?: number | null;
    readonly videoLang?: string | null;
    readonly videoStart?: number | null;
  } | null;
};
export type sourceEditQuery = {
  response: sourceEditQuery$data;
  variables: sourceEditQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "backLang"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uri",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameRaw",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "langNative",
    "variableName": "backLang"
  }
],
v7 = {
  "alias": null,
  "args": (v6/*: any*/),
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lang",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "multiLang",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "course",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "source",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duoLang",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "private",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "difficulty",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoStart",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoLang",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoDuration",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageId",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "langNative",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameNative",
  "storageKey": null
},
v24 = [
  {
    "kind": "Variable",
    "name": "backLang",
    "variableName": "backLang"
  }
],
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "front",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hint",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "translated",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": (v6/*: any*/),
  "concreteType": "SCardTheory",
  "kind": "LinkedField",
  "name": "theory",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sCardId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theoryIsChecked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theoryNative",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theoryNativeIsChecked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "theoryEn",
      "storageKey": null
    },
    (v8/*: any*/),
    (v22/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEdit",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flatId",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "concreteType": "ContentTestType",
  "kind": "LinkedField",
  "name": "contentTest",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "sourceEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v21/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Native",
                "kind": "LinkedField",
                "name": "natives",
                "plural": true,
                "selections": [
                  (v22/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v24/*: any*/),
                "concreteType": "SCard",
                "kind": "LinkedField",
                "name": "sCards",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SCardBack",
                    "kind": "LinkedField",
                    "name": "back",
                    "plural": false,
                    "selections": [
                      (v27/*: any*/),
                      (v28/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v29/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Upload",
                    "kind": "LinkedField",
                    "name": "svg",
                    "plural": false,
                    "selections": [
                      (v30/*: any*/),
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v31/*: any*/)
            ],
            "type": "Source",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "sourceEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v21/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Native",
                "kind": "LinkedField",
                "name": "natives",
                "plural": true,
                "selections": [
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v24/*: any*/),
                "concreteType": "SCard",
                "kind": "LinkedField",
                "name": "sCards",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SCardBack",
                    "kind": "LinkedField",
                    "name": "back",
                    "plural": false,
                    "selections": [
                      (v27/*: any*/),
                      (v28/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v29/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Upload",
                    "kind": "LinkedField",
                    "name": "svg",
                    "plural": false,
                    "selections": [
                      (v30/*: any*/),
                      (v21/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v31/*: any*/)
            ],
            "type": "Source",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e6d286ea19355fe9816a1f03cf61eaa8",
    "id": null,
    "metadata": {},
    "name": "sourceEditQuery",
    "operationKind": "query",
    "text": "query sourceEditQuery(\n  $id: ID!\n  $backLang: String\n) {\n  node(id: $id) {\n    __typename\n    ... on Source {\n      id\n      uri\n      nameRaw\n      name(langNative: $backLang)\n      lang\n      multiLang\n      kind\n      course\n      text\n      source\n      duoLang\n      private\n      difficulty\n      videoStart\n      videoLang\n      videoDuration\n      imageId\n      image {\n        url\n        id\n      }\n      natives {\n        langNative\n        nameNative\n        id\n      }\n      sCards(backLang: $backLang) {\n        id\n        front\n        hint\n        back {\n          value\n          translated\n          id\n        }\n        theory(langNative: $backLang) {\n          sCardId\n          theory\n          theoryIsChecked\n          theoryNative\n          theoryNativeIsChecked\n          theoryEn\n          lang\n          langNative\n          canEdit\n        }\n        svg {\n          flatId\n          url\n          id\n        }\n      }\n      contentTest {\n        active\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6f09c68eb17237553ad39dd46d9a0fe1";

export default node;
