import { usePagination, useQuery } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { cardsQuery } from "./__generated__/cardsQuery.graphql";
import { cardsQuery_Deck$data, cardsQuery_Deck$key } from "./__generated__/cardsQuery_Deck.graphql";
import { useViewerDecks } from "../viewerQuery";
import { CardState } from "./__generated__/CardsRefetchQuery.graphql";

export type TCard = NonNullable<NonNullable<NonNullable<cardsQuery_Deck$data["cards"]["edges"]>[number]>["node"]>;

const pagination = graphql`
  fragment cardsQuery_Deck on Deck
  @argumentDefinitions(
    count: { type: "Int!" }
    cursor: { type: "String" }
    search: { type: "String" }
    cardState: { type: "CardState" }
  )
  @refetchable(queryName: "CardsRefetchQuery") {
    cards(first: $count, after: $cursor, search: $search, cardState: $cardState) @connection(key: "Cards_cards") {
      edges {
        node {
          id
          sCardId
          sBackId
          sourceId
          front
          back
          hint
          waiting
          knownCount
          source {
            kind
            course
          }
          sCard {
            theory {
              sCardId
              theory
              theoryIsChecked
              theoryNative
              theoryNativeIsChecked
              theoryEn
              lang
              langNative
              canEdit
            }
          }
          svg {
            flatId
            url
          }
        }
      }
      pageInfo {
        endCursor
      }
    }
  }
`;

const query = graphql`
  query cardsQuery(
    $count: Int!
    $cursor: String
    $deckId: ID! # $orderBy: [FriendsOrdering]! # $userID: ID!
    $search: String
    $cardState: CardState
  ) {
    node(id: $deckId) {
      ...cardsQuery_Deck @arguments(count: $count, cursor: $cursor, search: $search, cardState: $cardState)
    }
  }
`;

export const cardsOnPage = 30;

export const useCardsQuery = (variables: { search: string; cardState: CardState | null }) => {
  const { deck } = useViewerDecks();
  const { data } = useQuery<cardsQuery>(
    query,
    { ...variables, deckId: deck?.id || "", count: cardsOnPage },
    { skip: !deck, fetchPolicy: "store-and-network" }
  );
  return usePagination<cardsQuery, cardsQuery_Deck$key>(pagination, data?.node as unknown as cardsQuery_Deck$key);
};
