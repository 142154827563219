import React from "react";
import styled, { keyframes } from "styled-components/macro";
import { SelectProps } from "@mui/material/Select";
import { FormControlLabel } from "@mui/material";

const check = keyframes`
  50%{
    transform: scale(1.2);
  }
`;

const AnimatedCheckboxInput = styled.input`
  display: none;
  :checked + .cbx {
    span {
      &:first-child {
        border-color: black;
        background: white;
        animation: ${check} 0.6s ease;
        svg {
          stroke-dashoffset: 0;
        }
      }
      &:last-child {
        color: black;
        transition: all 0.3s ease;
        &:after {
          transform: scaleX(1);
          transition: all 0.3s ease;
        }
      }
    }
  }
`;

const AnimatedLabel = styled.span`
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  span {
    display: inline-block;
    vertical-align: middle;
    transform: translate3d(0, 0, 0);

    &:first-child {
      background: white;
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      transform: scale(1);
      box-shadow: 1px 1px 7px ${({ theme }) => theme.duo.color.shadow};
      vertical-align: middle;
      transition: all 0.2s ease;
      margin-right: 20px;

      :hover {
        box-shadow: 0px 0px 15px ${({ theme }) => theme.duo.color.shadow};
      }

      svg {
        position: absolute;
        z-index: 1;
        top: 11px;
        left: 9px;
        fill: none;
        stroke: black;
        stroke-width: 2;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-dasharray: 16px;
        stroke-dashoffset: 16px;
        transition: all 0.3s ease;
        transition-delay: 0.1s;
        transform: translate3d(0, 0, 0);
      }

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        transform: scale(0);
        opacity: 1;
        border-radius: 50%;
        transition-delay: 0.2s;
      }
    }
  }
`;

export const SFormControlLabel = styled(FormControlLabel)`
  margin: 0;
`;

type TProps = SelectProps & {
  label: string;
  handleOnClick: () => void;
  checked: boolean;
};

export const AnimatedCheckbox: React.FC<TProps> = ({ label, handleOnClick, checked }) => (
  <SFormControlLabel
    control={
      <>
        <AnimatedCheckboxInput
          type={"checkbox"}
          checked={checked}
          onClick={() => {
            handleOnClick();
          }}
          onChange={() => {}}
          id={"cbx"}
        />
        <AnimatedLabel className={"cbx"}>
          <span>
            <svg width="24px" height="18px" viewBox="0 0 12 9 ">
              <polyline points="1 5 4 8 11 1" />
            </svg>
          </span>
          <span>{label}</span>
        </AnimatedLabel>
      </>
    }
    label={""}
  />
);
