import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";
import { Button } from "@mui/material";
import { useSeeHint } from "components/hint/Hint";
import { useNavigate } from "react-router";

import { SUBSCRIPTION } from "../../profile/ProfileRouter.config";

export const MOTIVATIONHINT = "motivation";

const Img = styled.img`
  width: 80px;
  height: 80px;
`;
const Strong = styled.strong``;

type Props = {};

const Motivation: React.FC<Props> = () => {
  const seeHint = useSeeHint();
  const navigate = useNavigate();

  return (
    <>
      <div />
      <div />
      <div />
      <div />
      <div />
      <Img src="https://cdn.duocards.com/svg/crown.svg" />
      <div />
      <Trans parent="p">Are you ready to learn?</Trans>
      <Trans parent="p">
        Consider buying a premium version <Strong>without limits or ads</Strong>.
      </Trans>
      <div />
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          seeHint(MOTIVATIONHINT);
          navigate(SUBSCRIPTION.url());
        }}
      >
        <Trans>Show me options</Trans>
      </Button>
      <div />
      <div />
      <div />
    </>
  );
};

export default Motivation;
