import React, { useState } from "react";
import { useViewerDecks, useViewerQuery } from "queries/viewerQuery";
import styled from "styled-components/macro";
import { IconButton, Stack, TextareaAutosize } from "@mui/material";
import ChatBubble from "components/assistant/ChatBubble";
import HeaderMenu from "components/header/HeaderMenu";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import {
  AssistantMode,
  AssistantMutation,
  AssistantMutation$data,
  ChatMessageOwnerType
} from "components/assistant/__generated__/AssistantMutation.graphql";
import { useCloseAssistant, useRemainingAiMessages } from "components/assistant/assistantHooks";
import ChatSuggestions from "components/assistant/suggestions/ChatSuggestions";
import { useTranslates } from "hooks/useTranslates";
import FireIcon from "../fire/Fire";
import { useKeepBurning } from "../fire/xpHooks";
import { Optional } from "utility-types";
import { isDesktop } from "../../tools/device";
import { AiMessagesDepletedWarning } from "./AiMessagesDepletedWarning/AiMessagesDepletedWarning";
import AiMessagesLimitDialog from "./AiMessagesLimitDialog/AiMessagesLimitDialog";
import { FullChat, FullChatWarning, MAX_MESSAGE_LENGTH, MAX_MESSAGES } from "./FullChat";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useEvent, usePrevious } from "react-use";
import { EVENT } from "../../tools/events";
import { SuggestionDefinition, TranslatedSuggestion } from "./suggestions/suggestionsDefinitions";
import { AI_ASSISTANT_QUEST } from "../../root/main/main/quests/AiAssistantQuest";
import {
  MAX_FREE_AI_MESSAGES,
  MAX_SUBSCRIBER_AI_MESSAGES,
  STREAK_FREEZE_EARN_QUEST
} from "sharedJs__generated/constants";
import SpeechToText from "components/speech/SpeechToText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSend } from "@fortawesome/pro-regular-svg-icons";
import { useDeviceLang } from "hooks/deviceHooks";
import { compact, uniq } from "lodash";
import { RemainingMessages } from "./RemainingMessages/RemainingMessages";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useDBLog } from "hooks/useDBLog";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";
import { useNavigate } from "react-router-dom";

export type TChatBubble = Optional<
  AssistantMutation$data["askAssistant"]["chat"]["chatMessages"][0],
  "analysis" | "translation" | "id"
>;
export type TChatBubbles = TChatBubble[];

const ChatArea = styled.div`
  padding: 20px;
  background-color: ${(p) => p.theme.duo.color.lightGrey};
  flex-grow: 1;
  overflow: auto;
`;
const Menu = styled(Stack)`
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.duo.color.lightGrey};
  background-color: white;
  flex-grow: 0;
  position: relative;
`;
const InputRow = styled.div`
  padding: 10px;
  padding-left: 20px;
  border-top: 1px solid ${(p) => p.theme.duo.color.lightGrey};
  background-color: white;
`;
const STextareaAutosize = styled(TextareaAutosize)`
  border-radius: ${(p) => p.theme.duo.borderRadius.normal};
  font-size: ${(p) => p.theme.duo.fontSize.normal};
  padding: 10px;
  padding-right: 45px;
  font-family: "Noto Sans", Arial, Helvetica, sans-serif;
  border-color: ${(p) => p.theme.duo.color.grey};
  width: 100%;

  &::-webkit-resizer {
    display: none;
  }
`;
const SIconButton = styled(IconButton)`
  svg {
    font-size: 1.7rem;
  }
`;
const BackButton = styled(IconButton)`
  position: absolute;
  left: 15px;
  top: 10px;
  padding: 5px;
`;

const FireButton = styled(Stack)`
  height: 56px;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 0;
`;

const MicWrap = styled.div`
  position: absolute;
  height: 100%;
  right: 0;
`;

const TxtAreaWrap = styled.div`
  flex-grow: 1;
  position: relative;
  display: flex;
`;

const askAssistant = graphql`
  mutation AssistantMutation(
    $chatMessage: ChatMessageInputType!
    $deckId: String!
    $chatId: String
    $mode: AssistantMode!
  ) {
    askAssistant(chatMessage: $chatMessage, deckId: $deckId, chatId: $chatId, mode: $mode) {
      chat {
        id
        chatMessages {
          id
          text
          translation
          analysis
          owner
        }
      }
      viewer {
        aiMessagesUsedThisWeek
        flags {
          name
          value
          updated
        }
      }
    }
  }
`;

type Props = {
  autoApplySuggestion?: SuggestionDefinition;
  onClose: () => void;
};

const Assistant: React.FC<Props> = ({ autoApplySuggestion, onClose }) => {
  const dblog = useDBLog();
  const navigate = useNavigate();

  const { isSubscriber } = useViewerQuery();

  const { deck } = useViewerDecks();
  const { appLang } = useDeviceLang();
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const lastBubbleRef = React.useRef<HTMLDivElement>(null);
  const chatIdRef = React.useRef<string | undefined>(undefined);
  const [chatBubbles, setChatBubbles] = React.useState<TChatBubbles>([]);
  const [sending, setSending] = React.useState(false);
  const { hintWasSeen } = useViewerQuery();
  const { translates, isLoadingTranslates } = useTranslates(
    deck?.front || "",
    ["Hi! I am your AI language assistant. Ask me any question!"],
    !deck
  );
  const [isMoreInfoDialogOpen, setIsMoreInfoDialogOpen] = useState(false);
  const isAudioRecording = useGetState("isAudioRecording");
  const modeRef = React.useRef<AssistantMode>("assistant");
  const keepBurning = useKeepBurning();
  const userMessagesCount = chatBubbles.filter((bubble) => bubble.owner === "user").length;
  const deckFrontPrev: string | undefined = usePrevious(deck?.front);
  const closeAssistant = useCloseAssistant();
  const [mutate] = useMutation<AssistantMutation>(askAssistant, {
    onCompleted: (data) => {
      chatIdRef.current = data.askAssistant.chat.id;
      setChatBubbles([...data.askAssistant.chat.chatMessages]);
      setSending(false);
      scrollToBottom();
    }
  });
  const setQuestAiAssistant = useSetState("questAiAssistant");
  const remainingAiMessages = useRemainingAiMessages();

  const clearChat = () => {
    setChatBubbles([]);
    chatIdRef.current = undefined;
    setSending(false);
    modeRef.current = "assistant";
  };

  React.useEffect(() => {
    // only clear chat if user manually changes deck
    if (deck?.front !== deckFrontPrev && deckFrontPrev) {
      clearChat();
    }
  }, [deck?.front, deckFrontPrev]);

  useEvent(EVENT.resetAssistant, () => {
    clearChat();
  });

  if (!deck) return null;

  const scrollToBottom = () => {
    setTimeout(() => {
      lastBubbleRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const handleSuggestionClick = (suggestion: TranslatedSuggestion) => {
    if (!inputRef.current) return;

    if (suggestion.switchToMode) {
      modeRef.current = suggestion.switchToMode;
    }

    if (suggestion.behavior === "goesToInput") {
      inputRef.current.value = suggestion.targetText + " ";
      inputRef.current.focus();
    }

    if (suggestion.behavior === "sendsMessage") {
      requestAssistant(suggestion.targetText);
    }

    if (suggestion.behavior === "closesAssistant") {
      const chatMessage: TChatBubble = {
        id: "",
        text: suggestion.targetText,
        owner: "user" as ChatMessageOwnerType
      };
      setChatBubbles([...chatBubbles, chatMessage]);
      scrollToBottom();
      setTimeout(closeAssistant, 300);
    }
  };

  const handleSend = () => {
    if (!inputRef.current?.value) return;
    requestAssistant(inputRef.current.value);
    inputRef.current.value = "";
  };

  const requestAssistant = (message: string) => {
    if (remainingAiMessages <= 1) {
      const count = isSubscriber ? MAX_SUBSCRIBER_AI_MESSAGES : MAX_FREE_AI_MESSAGES;
      dblog("AI messages", "0 remaining messages", { isSubscriber, count });
    }

    const chatMessage: TChatBubble = {
      id: "",
      text: message,
      owner: "user" as ChatMessageOwnerType
    };

    mutate({ variables: { chatMessage, deckId: deck.id, chatId: chatIdRef.current, mode: modeRef.current } });

    setChatBubbles([...chatBubbles, chatMessage]);
    setSending(true);
    scrollToBottom();
    keepBurning(15);

    if (!hintWasSeen(AI_ASSISTANT_QUEST) && hintWasSeen(STREAK_FREEZE_EARN_QUEST)) {
      setQuestAiAssistant(true);
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter" && !e.shiftKey && isDesktop()) {
      handleSend();
    }
  };

  const handleTranscription = (text: string) => {
    if (text.length > 0) requestAssistant(text);
  };

  const renderInputContent = () => {
    if (!isSubscriber && remainingAiMessages === 0) {
      return (
        <AiMessagesDepletedWarning
          onShowSubscriptionOptions={() => {
            dblog("AI messages - depleted warning", "user clicked on subscribe button");
            navigate(SUBSCRIPTION.url());
          }}
        />
      );
    }

    if (userMessagesCount >= MAX_MESSAGES) {
      return <FullChat />;
    }

    if (remainingAiMessages > 0) {
      return (
        <Stack direction="row" flexWrap="nowrap" alignItems="center">
          <TxtAreaWrap>
            <STextareaAutosize maxRows={10} ref={inputRef} onKeyUp={handleKeyUp} maxLength={MAX_MESSAGE_LENGTH} />
            <MicWrap>
              <SpeechToText
                languages={uniq(compact([deck?.front, deck?.back, appLang]))}
                onTranscription={handleTranscription}
              />
            </MicWrap>
          </TxtAreaWrap>
          <SIconButton
            color="primary"
            onClick={handleSend}
            disabled={sending || isLoadingTranslates || remainingAiMessages === 0 || isAudioRecording}
          >
            <FontAwesomeIcon icon={faSend} />
          </SIconButton>
        </Stack>
      );
    }

    return null;
  };

  return (
    <>
      {isMoreInfoDialogOpen && (
        <AiMessagesLimitDialog
          onShowSubscriptionOptions={() => {
            dblog("AI messages - more info", "user clicked on subscribe button");
            navigate(SUBSCRIPTION.url());
          }}
          onClose={() => setIsMoreInfoDialogOpen(false)}
        />
      )}

      <Stack direction="column" sx={{ flexWrap: "nowrap", height: "100%" }}>
        <Menu>
          <BackButton onClick={onClose}>
            <KeyboardArrowLeftIcon />
          </BackButton>
          <HeaderMenu />
          <FireButton>
            <FireIcon />
          </FireButton>
        </Menu>

        <ChatArea>
          <Stack direction={"column"} spacing={2.5}>
            {!isLoadingTranslates && (
              <ChatBubble chatMessage={{ owner: "assistant", text: translates[0] }} onBurn={() => keepBurning(9)} />
            )}
            {chatBubbles.map((chatBubble, i) => (
              <ChatBubble
                key={i}
                chatMessage={chatBubble}
                onBurn={() => keepBurning(9)}
                ref={i === chatBubbles.length - 1 ? lastBubbleRef : null}
                additionalInfo={
                  i === chatBubbles.length - 1 &&
                  !isSubscriber &&
                  chatBubble.owner === "assistant" && (
                    <RemainingMessages
                      remainingMessages={remainingAiMessages}
                      onShowMoreInfo={() => {
                        dblog("AI messages - more info", "dialog opened");
                        setIsMoreInfoDialogOpen(true);
                      }}
                    />
                  )
                }
              />
            ))}
            {(sending || isLoadingTranslates) && <ChatBubble chatMessage={{ owner: "assistant", text: "" }} isTyping />}
          </Stack>
        </ChatArea>

        {(isSubscriber || remainingAiMessages > 0) && (
          <ChatSuggestions
            chatMode={modeRef.current}
            messagesCount={chatBubbles.length}
            onUseSuggestion={handleSuggestionClick}
            autoApplySuggestion={autoApplySuggestion}
          />
        )}

        <InputRow>
          <FullChatWarning messagesCount={userMessagesCount} />

          {renderInputContent()}
        </InputRow>
      </Stack>
    </>
  );
};

export default Assistant;
