import styled, { css } from "styled-components/macro";

export const LibTitle = styled.div<{ $justText?: boolean }>`
  font-size: 105%;
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
  padding: ${(p) => (p.$justText ? "9px 9px 6px 30px" : "9px 0 6px 30px")};
  display: ${(p) => (p.$justText ? "block" : "flex")};
  align-items: center;
  cursor: pointer;
`;

export const LibLine = styled.div<{ disabled?: boolean }>`
  width: 100%;
  overflow-x: ${({ disabled }) => (disabled ? "hidden" : "auto")};
  ${({ disabled }) =>
    disabled &&
    css`
      filter: blur(1px);
      opacity: 0.2;
      pointer-events: none;
    `};
`;

export const LibLineIn = styled.div<{ $center?: boolean }>`
  display: flex;
  ${(p) => (p.$center ? "justify-content: center;" : "")}
  position: relative;
  padding: 0 0 10px 10px;
  &:after {
    content: "_";
    opacity: 0;
  }
`;

export const TitleOverImage = styled.h1`
  padding: 15px 30px;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4px);
  font-size: 22px;
  color: white;
  text-shadow:
    0.3px 0.3px 1px black,
    -0.3px -0.3px 0.5px #0004,
    0 0 6px #0008;
  width: 100%;
`;
