import React from "react";
import { useMatch, useNavigate } from "react-router";
import styled from "styled-components/macro";
import ShareUrl from "components/other/ShareUrl";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import DuoCardsTitle from "styled/DuoCardsTitle";
import CloseIcon from "@mui/icons-material/Close";

import { SHARE } from "../../RootRouter.config";

export const useShareHash = (): string | undefined => {
  const iRoute: any = useMatch(SHARE.query());
  return iRoute?.params?.hash;
};

const Wrap = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const SButton = styled(Button)`
  margin-top: 25px;
`;
const SDuoCardsTitle = styled(DuoCardsTitle)`
  margin-bottom: 40px;
`;
const SCloseIcon = styled(CloseIcon)`
  font-size: 18px;
`;

const Share: React.FC = () => {
  const hash = useShareHash();
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!hash) return null;

  return (
    <Wrap>
      <SDuoCardsTitle />
      <ShareUrl url={`https://app.duocards.com/i/${hash}`} />
      <SButton variant="text" onClick={() => navigate("/")}>
        <SCloseIcon />
        {t("Close")}
      </SButton>
    </Wrap>
  );
};

export default Share;
