import React from "react";
import styled from "styled-components/macro";
import Cave from "./Cave";
import FlameProgress from "./flame/FlameProgress";
import Floor from "./Floor";
import Flower from "./Flower";
import Pond from "./Pond";
import Hills from "./Hills";
import Trees from "./Trees";
import Grass from "./Grass";
import PinkMamooth from "./PinkMamooth";
import Frog from "./Frog";
import Hammock from "./Hammock";
import ItemAnimation, { ANIMDUR } from "./ItemAnimation";
import SunStreak from "./sun/SunStreak";
import NextItemDialog, { Epoch } from "./flame/NextItemDialog";
import PetableMammoth from "components/mammoth/PetableMammoth";
import { getLevel } from "./levelsHelper";
import { useDebounce } from "react-use";
import { coloringFilters } from "./flame/NextItemDialog";
import MainHints from "root/main/main/hints/MainHints";
import { dispatchEvent, EVENT } from "tools/events";

export const GARDEN_ID = "garden_id";

const Wrap = styled.div`
  position: relative;
  width: 340px;
  height: 140px;
  margin: 0 auto;
  text-align: center;

  ${coloringFilters}
`;
const MemoWrap = styled.div`
  position: absolute;
  left: 89px;
  top: 46px;
`;

export type GardenXps = {
  total: number;
  today: number;
  currentStreak: number;
};
type PropsComponent = {
  xps: GardenXps;
  allowPetting?: boolean;
  noDeadFire?: boolean;
};

const Garden: React.FC<PropsComponent> = ({ xps, allowPetting, noDeadFire }) => {
  const { level } = getLevel(xps.total);

  const [debouncedLevel, setDebouncedLevel] = React.useState(level);
  useDebounce(() => setDebouncedLevel(level), ANIMDUR, [level]);

  const [debouncedXps, setDebouncedXps] = React.useState(xps);
  useDebounce(() => setDebouncedXps(xps), 1100, [xps]);

  const animate = level > debouncedLevel;

  const itemsList = React.useMemo(
    () => [
      {
        component: (cls) => <FlameProgress className={cls} animateMax={animate} xps={debouncedXps} />
      },
      { component: (cls) => <Cave className={cls} /> },
      { component: () => <SunStreak xps={xps} />, noGold: true },
      { component: (cls) => <Pond className={cls} /> },
      { component: (cls) => <Trees className={cls} /> },
      { component: (cls) => <Hills className={cls} /> },
      { component: (cls) => <Hammock className={cls} /> },
      { component: (cls) => <Trees className={cls} position={1} /> },
      { component: (cls) => <Flower className={cls} /> },
      { component: (cls) => <Grass className={cls} /> },
      { component: (cls) => <Trees className={cls} position={2} /> },
      { component: (cls) => <PinkMamooth className={cls} />, noGold: true },
      { component: (cls) => <Frog className={cls} /> }
    ],
    [animate, xps, debouncedXps]
  );

  const items = React.useMemo(
    () => [
      ...itemsList.map((i) => i.component(undefined)),
      ...itemsList.filter((i) => !i.noGold).map((i) => i.component(Epoch.gold)),
      ...itemsList.filter((i) => !i.noGold).map((i) => i.component(Epoch.titan))
    ],
    [itemsList]
  );

  const nextItem = items[debouncedLevel];

  let renderedNoGolds = 0;
  const itemsRender = [...Array(debouncedLevel)].map((_, step) => {
    itemsList[step] && itemsList[step].noGold && renderedNoGolds++;
    if (step < debouncedLevel - itemsList.length - renderedNoGolds && (!itemsList[step] || !itemsList[step].noGold)) {
      return null;
    }
    return <React.Fragment key={step}>{items[step]}</React.Fragment>;
  });

  return (
    <Wrap id={GARDEN_ID} className="nofilter">
      <Floor />

      {debouncedLevel === 0 && !noDeadFire && <FlameProgress xps={xps} animateMax={animate} dead />}

      {itemsRender}

      {animate && <ItemAnimation item={nextItem} firstAnimation={!!noDeadFire} />}

      {!animate && <MainHints />}

      <MemoWrap onClick={() => dispatchEvent(EVENT.expandDailyQuest)}>
        <PetableMammoth noBubble={!allowPetting} noPet={!allowPetting} inGarden />
      </MemoWrap>

      <NextItemDialog xps={xps} items={items} nextItemIndex={level} />
    </Wrap>
  );
};

export default Garden;
