/**
 * @generated SignedSource<<8f8b07593f4ec4a722740898b86da122>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type translateQuery$variables = {
  from: string;
  fromLang: string;
  toLang: string;
  withSvg?: boolean | null;
};
export type translateQuery$data = {
  readonly translate: {
    readonly svg: {
      readonly flatId: string;
      readonly url: string;
    } | null;
    readonly translate: string;
  };
};
export type translateQuery = {
  response: translateQuery$data;
  variables: translateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "from"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fromLang"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "toLang"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "withSvg"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "from",
    "variableName": "from"
  },
  {
    "kind": "Variable",
    "name": "fromLang",
    "variableName": "fromLang"
  },
  {
    "kind": "Variable",
    "name": "toLang",
    "variableName": "toLang"
  },
  {
    "kind": "Variable",
    "name": "withSvg",
    "variableName": "withSvg"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "translate",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flatId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "translateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TranslateResponse",
        "kind": "LinkedField",
        "name": "translate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Upload",
            "kind": "LinkedField",
            "name": "svg",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "translateQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TranslateResponse",
        "kind": "LinkedField",
        "name": "translate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Upload",
            "kind": "LinkedField",
            "name": "svg",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e4cc1f821632996198edce7656cf10e2",
    "id": null,
    "metadata": {},
    "name": "translateQuery",
    "operationKind": "query",
    "text": "query translateQuery(\n  $from: String!\n  $fromLang: String!\n  $toLang: String!\n  $withSvg: Boolean\n) {\n  translate(from: $from, fromLang: $fromLang, toLang: $toLang, withSvg: $withSvg) {\n    translate\n    svg {\n      flatId\n      url\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "043d57bf7dae42927859a32757ede0e1";

export default node;
