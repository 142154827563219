/**
 * @generated SignedSource<<dd0cb9de84b0217d7ed1e148713734c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CardFormWordQuery$variables = {
  cardId: string;
  checkForDuplicates: boolean;
  deckId: string;
  lang: string;
  nativeLang: string;
  word: string;
};
export type CardFormWordQuery$data = {
  readonly dictionary: {
    readonly examples: ReadonlyArray<string> | null;
    readonly grammar: ReadonlyArray<{
      readonly grammar: string;
      readonly id: string;
      readonly lang: string;
    }> | null;
  } | null;
  readonly existsInDeck?: {
    readonly back: string;
    readonly flipped: boolean;
    readonly front: string;
    readonly hint: string | null;
    readonly id: string;
    readonly sCardId: string | null;
    readonly sourceId: string | null;
    readonly svg: {
      readonly flatId: string;
      readonly url: string;
    } | null;
  } | null;
};
export type CardFormWordQuery = {
  response: CardFormWordQuery$data;
  variables: CardFormWordQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cardId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "checkForDuplicates"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deckId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lang"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nativeLang"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "word"
},
v6 = {
  "kind": "Variable",
  "name": "lang",
  "variableName": "lang"
},
v7 = {
  "kind": "Variable",
  "name": "word",
  "variableName": "word"
},
v8 = [
  {
    "kind": "Variable",
    "name": "cardId",
    "variableName": "cardId"
  },
  {
    "kind": "Variable",
    "name": "deckId",
    "variableName": "deckId"
  },
  (v6/*: any*/),
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "front",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "back",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flipped",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hint",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sourceId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sCardId",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flatId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": [
    (v6/*: any*/),
    {
      "kind": "Variable",
      "name": "nativeLang",
      "variableName": "nativeLang"
    },
    (v7/*: any*/)
  ],
  "concreteType": "DictionaryType",
  "kind": "LinkedField",
  "name": "dictionary",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "examples",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GrammarReturnType",
      "kind": "LinkedField",
      "name": "grammar",
      "plural": true,
      "selections": [
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lang",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grammar",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CardFormWordQuery",
    "selections": [
      {
        "condition": "checkForDuplicates",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "Card",
            "kind": "LinkedField",
            "name": "existsInDeck",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Upload",
                "kind": "LinkedField",
                "name": "svg",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      (v18/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "CardFormWordQuery",
    "selections": [
      {
        "condition": "checkForDuplicates",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "Card",
            "kind": "LinkedField",
            "name": "existsInDeck",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Upload",
                "kind": "LinkedField",
                "name": "svg",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      (v18/*: any*/)
    ]
  },
  "params": {
    "cacheID": "d48c238f7ef035c97772dff986f150a1",
    "id": null,
    "metadata": {},
    "name": "CardFormWordQuery",
    "operationKind": "query",
    "text": "query CardFormWordQuery(\n  $checkForDuplicates: Boolean!\n  $cardId: ID!\n  $deckId: ID!\n  $word: String!\n  $lang: String!\n  $nativeLang: String!\n) {\n  existsInDeck(cardId: $cardId, deckId: $deckId, word: $word, lang: $lang) @include(if: $checkForDuplicates) {\n    id\n    front\n    back\n    flipped\n    hint\n    sourceId\n    sCardId\n    svg {\n      flatId\n      url\n      id\n    }\n  }\n  dictionary(word: $word, lang: $lang, nativeLang: $nativeLang) {\n    examples\n    grammar {\n      id\n      lang\n      grammar\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fbf509d212b5fe39baffae566c88b1d7";

export default node;
