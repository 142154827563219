/**
 * @generated SignedSource<<0dc23c160a7ae7384046ff0326eeb52b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AssistantMode = "aiStory" | "assistant" | "%future added value";
export type ChatMessageOwnerType = "assistant" | "user" | "%future added value";
export type ChatMessageInputType = {
  id?: string | null;
  owner: ChatMessageOwnerType;
  text: string;
};
export type AssistantMutation$variables = {
  chatId?: string | null;
  chatMessage: ChatMessageInputType;
  deckId: string;
  mode: AssistantMode;
};
export type AssistantMutation$data = {
  readonly askAssistant: {
    readonly chat: {
      readonly chatMessages: ReadonlyArray<{
        readonly analysis: string | null;
        readonly id: string;
        readonly owner: ChatMessageOwnerType;
        readonly text: string;
        readonly translation: string | null;
      }>;
      readonly id: string;
    };
    readonly viewer: {
      readonly aiMessagesUsedThisWeek: number;
      readonly flags: ReadonlyArray<{
        readonly name: string;
        readonly updated: number;
        readonly value: string | null;
      }>;
    };
  };
};
export type AssistantMutation = {
  response: AssistantMutation$data;
  variables: AssistantMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chatId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chatMessage"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deckId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mode"
},
v4 = [
  {
    "kind": "Variable",
    "name": "chatId",
    "variableName": "chatId"
  },
  {
    "kind": "Variable",
    "name": "chatMessage",
    "variableName": "chatMessage"
  },
  {
    "kind": "Variable",
    "name": "deckId",
    "variableName": "deckId"
  },
  {
    "kind": "Variable",
    "name": "mode",
    "variableName": "mode"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "ChatType",
  "kind": "LinkedField",
  "name": "chat",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ChatMessageType",
      "kind": "LinkedField",
      "name": "chatMessages",
      "plural": true,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "translation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "analysis",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "owner",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "aiMessagesUsedThisWeek",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updated",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssistantMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ChatResponseType",
        "kind": "LinkedField",
        "name": "askAssistant",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserFlags",
                "kind": "LinkedField",
                "name": "flags",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssistantMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ChatResponseType",
        "kind": "LinkedField",
        "name": "askAssistant",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserFlags",
                "kind": "LinkedField",
                "name": "flags",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7a199b7c5613a62c38aeb3d96eaf410e",
    "id": null,
    "metadata": {},
    "name": "AssistantMutation",
    "operationKind": "mutation",
    "text": "mutation AssistantMutation(\n  $chatMessage: ChatMessageInputType!\n  $deckId: String!\n  $chatId: String\n  $mode: AssistantMode!\n) {\n  askAssistant(chatMessage: $chatMessage, deckId: $deckId, chatId: $chatId, mode: $mode) {\n    chat {\n      id\n      chatMessages {\n        id\n        text\n        translation\n        analysis\n        owner\n      }\n    }\n    viewer {\n      aiMessagesUsedThisWeek\n      flags {\n        name\n        value\n        updated\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "803b9b028f099b864900fe22285dea4d";

export default node;
