import React from "react";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  onClick: VoidFunction;
};

const ClearButton: React.FC<Props> = ({ onClick }) => {
  return (
    <InputAdornment position="end">
      <IconButton size="small" color="primary" onClick={onClick} tabIndex={-1}>
        <CloseIcon />
      </IconButton>
    </InputAdornment>
  );
};

export default ClearButton;
