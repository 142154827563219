import React from "react";
import styled from "styled-components/macro";
import DialogWindow from "../other/DialogWindow";
import { useGetState, useSetState } from "../ReduxProvider";
import { Select } from "../../styled/Select";
import { Button } from "@mui/material";
import { useMutation } from "relay-hooks";
import { CreateDeck } from "../../queries/decks/createDeck";
import { isDesktop } from "../../tools/device";
import { Trans, useTranslation } from "react-i18next";
import { T_NATIVELANG, T_LANGLEARNING, T_DIFFLANGS, T_ENGLISH } from "../../root/main/deck/DeckForm";
import { useViewerDecks, useViewerQuery } from "../../queries/viewerQuery";
import { useNavigate } from "react-router";
import DeckWarn from "../../root/main/deck/DeckWarn";
import LangMenu from "../LangMenu/LangMenu";
import useLangNative from "../../hooks/useLangNative";
import { useOpenAppOrStore } from "root/auth/DownloadButton";
import { useGetLangOptions } from "hooks/useGetLangTranslates";

const SDialogWindow = styled(DialogWindow)`
  .MuiPaper-root {
    padding: -5px 0 14px;
  }
`;
const DeckWrap = styled.div`
  padding: 0 5px 5px;
`;
const Text = styled.div`
  margin: 5px 0 25px;
`;
const SButton = styled(Button)`
  &&& {
    margin-bottom: 7px;
    width: 100%;
    max-width: 300px;
  }
`;
const SDeckWarn = styled(DeckWarn)`
  text-align: left;
  margin-bottom: 10px;
`;

const DeckCreateDialog: React.FC<{}> = () => {
  const { t } = useTranslation();
  const deckCreateState = useGetState("deckCreate");
  const setDeckCreate = useSetState("deckCreate");
  const setTooManyDecks = useSetState("tooManyDecks");
  const { viewer } = useViewerQuery();
  const { decks } = useViewerDecks();
  const langNative = useLangNative();
  const [back, setBack] = React.useState<string>(langNative);
  const [created, setCreated] = React.useState(false);
  const navigate = useNavigate();
  const openAppOrStore = useOpenAppOrStore();
  const langOptions = useGetLangOptions();

  React.useEffect(() => {
    !!deckCreateState && setBack(langNative);
  }, [deckCreateState, langNative]);

  const [createDeck] = useMutation(CreateDeck, {
    onCompleted: (res) => setCreated(true)
  });

  if (!deckCreateState?.lang) return null;

  const close = () => {
    setDeckCreate(undefined);
    setCreated(false);
  };

  return (
    <SDialogWindow background={!!viewer && !created} open={!!deckCreateState} onClose={close}>
      {!viewer ? (
        <>
          <Trans parent={Text}>Register in the app to add cards</Trans>
          <SButton color="primary" variant="contained" onClick={openAppOrStore}>
            {isDesktop() ? <Trans>Download App</Trans> : <Trans>Open App</Trans>}
          </SButton>
          {isDesktop() && (
            <Button
              color="primary"
              onClick={() => {
                navigate({ hash: "login" });
                setDeckCreate(undefined);
              }}
              fullWidth
            >
              <Trans>Login as an existing user</Trans>
            </Button>
          )}
        </>
      ) : !created ? (
        <DeckWrap>
          <Text>{t("You don't have deck for this language yet. Let's create it.")}</Text>
          <Select label={t(T_LANGLEARNING)} options={langOptions} value={deckCreateState?.lang} disabled={true} />

          <LangMenu
            onSelect={(lang) => setBack(lang)}
            title={
              <Select label={t(T_NATIVELANG)} value={back} options={langOptions} style={{ pointerEvents: "none" }} />
            }
          />

          {back !== "en" && deckCreateState?.lang !== "en" && <SDeckWarn warning={T_ENGLISH} />}
          <Button
            style={{ margin: "10px 0" }}
            color="primary"
            variant="contained"
            onClick={() => {
              if (!viewer?.subscription && (decks?.length || 0) >= 2) {
                setDeckCreate(undefined);
                setTooManyDecks(true);
                return;
              }
              if (deckCreateState?.lang === back) {
                alert(t(T_DIFFLANGS));
                return;
              }
              createDeck({ variables: { front: deckCreateState?.lang, back } });
            }}
          >
            {t("Create Deck")}
          </Button>
        </DeckWrap>
      ) : (
        <>
          <p>{t("Excelent! Now click on the word you want to learn again.")}</p>
          <Button color="primary" variant="contained" onClick={close}>
            Ok
          </Button>
        </>
      )}
    </SDialogWindow>
  );
};

export default DeckCreateDialog;
