// https://stackoverflow.com/questions/46795955/how-to-know-scroll-to-element-is-done-in-javascript
export const smoothScroll = (element: HTMLElement, behavior?: ScrollBehavior): Promise<void> => {
  return new Promise((resolve) => {
    let notMovingFramesCount = 0;
    let lastPos;

    setTimeout(() => {
      element.scrollIntoView({ behavior: behavior || "smooth", block: "start" });
      requestAnimationFrame(checkIfStillScolling);
    }, 10);

    function checkIfStillScolling() {
      const newPos = element.getBoundingClientRect().top;

      if (newPos === lastPos) {
        if (notMovingFramesCount++ > 4) {
          resolve();
          return;
        }
      } else {
        notMovingFramesCount = 0;
        lastPos = newPos;
      }
      requestAnimationFrame(checkIfStillScolling);
    }
  });
};
