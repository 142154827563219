/**
 * @generated SignedSource<<d290c3082c8fb8e7c68fa11ca53d9b65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Difficulty = "beginner" | "easy" | "hard" | "moderate" | "%future added value";
export type Kind = "article" | "set" | "video" | "%future added value";
export type createSourceMutation$variables = {
  difficulty: Difficulty;
  duoLang?: boolean | null;
  imageId?: string | null;
  kind: Kind;
  lang: string;
  langNative?: string | null;
  multiLang?: boolean | null;
  name?: string | null;
  nameNative?: string | null;
  private?: boolean | null;
  source?: string | null;
  text?: string | null;
  videoDuration?: number | null;
  videoLang?: string | null;
  videoStart?: number | null;
};
export type createSourceMutation$data = {
  readonly createSource: {
    readonly id: string;
    readonly lang: string;
    readonly uri: string;
  } | null;
};
export type createSourceMutation = {
  response: createSourceMutation$data;
  variables: createSourceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "difficulty"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "duoLang"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "imageId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "kind"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lang"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "langNative"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "multiLang"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nameNative"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "private"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "source"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "text"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "videoDuration"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "videoLang"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "videoStart"
},
v15 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "difficulty",
        "variableName": "difficulty"
      },
      {
        "kind": "Variable",
        "name": "duoLang",
        "variableName": "duoLang"
      },
      {
        "kind": "Variable",
        "name": "imageId",
        "variableName": "imageId"
      },
      {
        "kind": "Variable",
        "name": "kind",
        "variableName": "kind"
      },
      {
        "kind": "Variable",
        "name": "lang",
        "variableName": "lang"
      },
      {
        "kind": "Variable",
        "name": "langNative",
        "variableName": "langNative"
      },
      {
        "kind": "Variable",
        "name": "multiLang",
        "variableName": "multiLang"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "nameNative",
        "variableName": "nameNative"
      },
      {
        "kind": "Variable",
        "name": "private",
        "variableName": "private"
      },
      {
        "kind": "Variable",
        "name": "source",
        "variableName": "source"
      },
      {
        "kind": "Variable",
        "name": "text",
        "variableName": "text"
      },
      {
        "kind": "Variable",
        "name": "videoDuration",
        "variableName": "videoDuration"
      },
      {
        "kind": "Variable",
        "name": "videoLang",
        "variableName": "videoLang"
      },
      {
        "kind": "Variable",
        "name": "videoStart",
        "variableName": "videoStart"
      }
    ],
    "concreteType": "Source",
    "kind": "LinkedField",
    "name": "createSource",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uri",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lang",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createSourceMutation",
    "selections": (v15/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v11/*: any*/),
      (v10/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v9/*: any*/),
      (v2/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v12/*: any*/)
    ],
    "kind": "Operation",
    "name": "createSourceMutation",
    "selections": (v15/*: any*/)
  },
  "params": {
    "cacheID": "a44800fd87e047819f33479405a7a999",
    "id": null,
    "metadata": {},
    "name": "createSourceMutation",
    "operationKind": "mutation",
    "text": "mutation createSourceMutation(\n  $lang: String!\n  $langNative: String\n  $multiLang: Boolean\n  $name: String\n  $nameNative: String\n  $text: String\n  $source: String\n  $duoLang: Boolean\n  $kind: Kind!\n  $difficulty: Difficulty!\n  $private: Boolean\n  $imageId: String\n  $videoLang: String\n  $videoStart: Int\n  $videoDuration: Int\n) {\n  createSource(lang: $lang, langNative: $langNative, multiLang: $multiLang, name: $name, nameNative: $nameNative, text: $text, source: $source, duoLang: $duoLang, kind: $kind, difficulty: $difficulty, private: $private, imageId: $imageId, videoStart: $videoStart, videoLang: $videoLang, videoDuration: $videoDuration) {\n    id\n    uri\n    lang\n  }\n}\n"
  }
};
})();

(node as any).hash = "36c6cc684b6c29c8acb932c168140a35";

export default node;
