import { PROFILE } from "../RootRouter.config";

export const PASSWORD = {
  relative: "password",
  url: () => PROFILE.url() + "/" + PASSWORD.relative,
  hashParam: "hash" // used in `resetPwd.html`
};
export const FEEDBACK = {
  relative: "feedback",
  url: () => PROFILE.url() + "/" + FEEDBACK.relative
};
export const HELP_CENTER = {
  relative: "help",
  url: () => PROFILE.url() + "/" + HELP_CENTER.relative
};
export const SUBSCRIPTION = {
  relative: "subscription",
  url: () => PROFILE.url() + "/" + SUBSCRIPTION.relative
};
export const USEREDIT = {
  relative: "edit",
  url: () => PROFILE.url() + "/" + USEREDIT.relative
};
export const NOTIFICATIONS = {
  relative: "notifications",
  url: () => PROFILE.url() + "/" + NOTIFICATIONS.relative
};
export const AFFILIATE = {
  relative: "affiliate",
  url: () => PROFILE.url() + `/` + AFFILIATE.relative
};
