import React from "react";
import styled from "styled-components/macro";

const Svg = styled.svg`
  width: 55px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
`;

type Props = {
  className?: string;
};

const Sun: React.FC<Props> = ({ className }) => (
  <Svg
    className={className}
    enable-background="new 0 0 497 497"
    viewBox="0 0 497 497"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m374.293 248.5c0 74.997-50.797 135.794-125.794 135.794s-135.793-60.797-135.793-135.794 60.797-135.794 135.794-135.794 125.793 60.797 125.793 135.794z"
        fill="#fee45a"
      ></path>
      <path
        d="m248.5 112.706c-5.071 0-10.074.287-15 .829 67.937 7.467 120.794 65.039 120.794 134.965s-52.857 127.498-120.794 134.965c4.926.542 9.929.829 15 .829 74.997 0 135.794-60.797 135.794-135.794s-60.798-135.794-135.794-135.794z"
        fill="#fed402"
      ></path>
      <path
        d="m248.5 79.431h-24.558c-3.599 0-6.113-3.563-4.906-6.954l13.495-37.925 11.063-31.09c1.643-4.616 8.17-4.616 9.813 0l11.063 31.09 13.495 37.925c1.207 3.39-1.308 6.954-4.906 6.954z"
        fill="#fee45a"
      ></path>
      <path
        d="m248.5 417.57h24.558c3.599 0 6.113 3.563 4.906 6.954l-13.495 37.925-11.063 31.09c-1.643 4.616-8.17 4.616-9.813 0l-11.063-31.09-13.495-37.925c-1.206-3.391 1.308-6.954 4.906-6.954z"
        fill="#fee45a"
      ></path>
      <path
        d="m417.57 248.5v-24.558c0-3.599 3.563-6.113 6.954-4.906l37.925 13.495 31.09 11.063c4.616 1.643 4.616 8.17 0 9.813l-31.09 11.063-37.925 13.495c-3.391 1.207-6.954-1.308-6.954-4.906z"
        fill="#fed402"
      ></path>
      <path
        d="m79.431 248.5v24.558c0 3.599-3.563 6.113-6.954 4.906l-37.925-13.495-31.09-11.063c-4.616-1.642-4.616-8.17 0-9.813l31.09-11.063 37.925-13.495c3.391-1.206 6.954 1.308 6.954 4.906z"
        fill="#fee45a"
      ></path>
      <path
        d="m128.95 128.95-17.365 17.365c-2.545 2.545-6.842 1.803-8.386-1.448l-17.274-36.359-14.162-29.807c-2.103-4.425 2.513-9.041 6.939-6.939l29.807 14.161 36.359 17.274c3.251 1.544 3.992 5.842 1.448 8.386z"
        fill="#fee45a"
      ></path>
      <path
        d="m368.05 368.05 17.365-17.365c2.545-2.545 6.842-1.803 8.386 1.448l17.275 36.359 14.161 29.807c2.102 4.425-2.513 9.041-6.939 6.939l-29.807-14.161-36.359-17.275c-3.251-1.544-3.992-5.842-1.448-8.386z"
        fill="#fed402"
      ></path>
      <path
        d="m368.05 128.95-17.365-17.365c-2.545-2.545-1.803-6.842 1.448-8.386l36.359-17.274 29.807-14.161c4.425-2.103 9.041 2.513 6.939 6.939l-14.161 29.807-17.275 36.359c-1.544 3.251-5.842 3.992-8.386 1.448z"
        fill="#fed402"
      ></path>
      <path
        d="m128.95 368.05 17.365 17.365c2.545 2.545 1.803 6.842-1.448 8.386l-36.359 17.274-29.807 14.161c-4.425 2.103-9.041-2.513-6.939-6.939l14.161-29.807 17.274-36.359c1.544-3.251 5.842-3.992 8.386-1.448z"
        fill="#fee45a"
      ></path>
    </g>
  </Svg>
);

export default Sun;
