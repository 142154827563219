import React from "react";
import styled from "styled-components/macro";

const Svg = styled.svg`
  width: 38px;
  height: 38px;
  position: absolute;
  left: 262px;
  top: 100px;
`;

type Props = {
  className?: string;
};
const Grass: React.FC<Props> = ({ className }) => {
  return (
    <Svg className={className} viewBox="0 0 512 512" enableBackground="new 0 0 512 512">
      <path
        fill="#47821C"
        d="M1.38,311.293c2.819-5.981,9.334-9.285,15.825-8.026c33.468,6.489,69.049,23.096,105.754,49.361
    c28.787,20.598,58.428,47.215,88.1,79.111c0.235,0.253,0.466,0.504,0.7,0.757H111.453c-9.88-10.965-19.292-21.001-27.93-29.951
    c-43.882-45.46-77.926-73.659-78.264-73.937C0.159,324.402-1.438,317.273,1.38,311.293z"
      ></path>
      <path
        fill="#4E901E"
        d="M24.532,203.946c4.002-5.263,11.062-7.14,17.149-4.558c31.386,13.311,62.733,36.958,93.169,70.285
    c23.871,26.137,47.324,58.339,69.71,95.711c14.6,24.374,26.769,47.612,36.27,67.112h-88.554
    c-22.131-47.983-46.638-90.572-66.387-122.204c-33.462-53.596-60.895-88.261-61.167-88.603
    C20.611,216.514,20.531,209.208,24.532,203.946z"
      ></path>
      <path
        fill="#5EAC24"
        d="M80.575,122.206c4.891-4.449,12.174-5.013,17.691-1.369c28.447,18.788,54.979,47.733,78.861,86.033
    c18.729,30.037,35.947,65.961,51.175,106.776c19.435,52.091,30.516,98.224,34.993,118.849h-85.089
    c-13.243-75.266-37.535-146.218-56.599-194.57c-23.175-58.781-43.859-97.851-44.063-98.236
    C74.437,133.854,75.685,126.654,80.575,122.206z"
      ></path>
      <path
        fill="#6DC82A"
        d="M204.684,432.409c0.002-0.512,0.03-1.017,0.084-1.514c4.949-85.351-5.917-171.119-15.921-228.106
    c-10.914-62.176-23.201-104.534-23.323-104.953c-1.851-6.329,0.708-13.145,6.216-16.552c5.509-3.408,12.559-2.538,17.125,2.114
    c23.545,23.984,43.333,57.555,58.814,99.781c12.142,33.117,21.723,71.713,28.478,114.717
    c11.312,72.013,10.864,131.159,10.822,134.889L204.684,432.409z"
      ></path>
      <path
        fill="#4E901E"
        d="M510.62,311.293c-2.819-5.981-9.334-9.285-15.825-8.026c-33.468,6.489-69.049,23.096-105.754,49.361
    c-28.787,20.598-58.428,47.215-88.1,79.111c-0.235,0.253-0.466,0.504-0.7,0.757h100.306c9.879-10.965,19.292-21.001,27.93-29.951
    c43.882-45.46,77.926-73.659,78.264-73.937C511.841,324.402,513.438,317.273,510.62,311.293z"
      ></path>
      <path
        fill="#5EAC24"
        d="M487.468,203.946c-4.002-5.263-11.062-7.14-17.149-4.558c-31.386,13.311-62.733,36.958-93.169,70.285
    c-23.871,26.137-47.324,58.339-69.71,95.711c-14.6,24.374-26.769,47.612-36.271,67.112h88.554
    c22.131-47.983,46.638-90.572,66.387-122.204c33.462-53.596,60.895-88.261,61.167-88.603
    C491.389,216.514,491.469,209.208,487.468,203.946z"
      ></path>
      <path
        fill="#6DC82A"
        d="M431.425,122.206c-4.891-4.449-12.174-5.013-17.691-1.369
    c-28.447,18.788-54.979,47.733-78.861,86.033c-18.729,30.037-35.947,65.961-51.175,106.776
    c-19.435,52.091-30.516,98.224-34.993,118.849h85.089c13.243-75.266,37.535-146.218,56.599-194.57
    c23.175-58.781,43.859-97.851,44.063-98.236C437.564,133.854,436.315,126.654,431.425,122.206z"
      ></path>
      <path
        fill="#91DC5A"
        d="M308.875,432.495c0-0.512-0.027-1.017-0.078-1.514c-4.688-85.373,6.894-171.09,17.454-228.031
    c11.521-62.125,24.361-104.426,24.488-104.844c1.934-6.321-0.668-13.148-6.316-16.58c-5.651-3.434-12.908-2.596-17.626,2.035
    c-24.335,23.876-44.85,57.355-60.975,99.512c-12.647,33.061-22.686,71.613-29.837,114.586
    c-11.977,71.961-11.794,131.108-11.77,134.838h84.661V432.495z"
      ></path>
      <g>
        <path
          fill="#47821C"
          d="M120.163,432.495H48.762c-4.328,0-7.837-3.508-7.837-7.837s3.509-7.837,7.837-7.837h71.401
      c4.328,0,7.837,3.508,7.837,7.837S124.491,432.495,120.163,432.495z"
        ></path>
        <path
          fill="#47821C"
          d="M21.943,432.495H8.359c-4.328,0-7.837-3.508-7.837-7.837s3.509-7.837,7.837-7.837h13.584
      c4.328,0,7.837,3.508,7.837,7.837S26.271,432.495,21.943,432.495z"
        ></path>
      </g>
      <g>
        <path
          fill="#4E901E"
          d="M444.43,432.495h-57.817c-4.329,0-7.837-3.508-7.837-7.837s3.508-7.837,7.837-7.837h57.817
      c4.329,0,7.837,3.508,7.837,7.837S448.759,432.495,444.43,432.495z"
        ></path>
        <path
          fill="#4E901E"
          d="M502.954,432.495h-30.66c-4.329,0-7.837-3.508-7.837-7.837s3.508-7.837,7.837-7.837h30.66
      c4.329,0,7.837,3.508,7.837,7.837S507.282,432.495,502.954,432.495z"
        ></path>
      </g>
      <g>
        <path
          fill="#5EAC24"
          d="M271.167,401.386c-4.245,0-7.733-3.391-7.831-7.656c-0.612-26.559,0.728-54.983,3.986-84.481
      c0.475-4.3,4.349-7.395,8.65-6.93c4.302,0.475,7.404,4.348,6.93,8.65c-3.183,28.81-4.494,56.533-3.896,82.399
      c0.1,4.327-3.327,7.915-7.654,8.015C271.289,401.385,271.227,401.386,271.167,401.386z"
        ></path>
        <path
          fill="#5EAC24"
          d="M279.766,283.426c-0.409,0-0.822-0.031-1.239-0.098c-4.275-0.678-7.189-4.695-6.51-8.969
      c3.731-23.493,7.206-38.067,7.352-38.676c1.01-4.208,5.24-6.798,9.447-5.792c4.208,1.009,6.802,5.239,5.794,9.447
      c-0.033,0.144-3.487,14.645-7.114,37.479C286.884,280.675,283.553,283.426,279.766,283.426z"
        ></path>
      </g>
    </Svg>
  );
};

export default Grass;
