import { Button } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components/macro";
import DialogWindow from "../other/DialogWindow";
import { useViewerQuery, useViewerDecks } from "../../queries/viewerQuery";
import { LEARN } from "../../root/main/MainRouter";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { useDispatch, useSelector } from "react-redux";
import { selectLearnLimit, setLearnLimit } from "../ReduxProvider";
import { isChromeExtension } from "tools/device";
import PriceButton from "root/profile/subscription/PriceButton";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";

export const TOLEARNMAX = 20;
const NOPAYMENTSEXTRA = 10;

const SWhatshotIcon = styled(WhatshotIcon)`
  font-size: 36px;
  color: ${({ theme }) => theme.duo.color.primary};
  margin: 15px 0 -10px;
`;
const Strong = styled.strong`
  white-space: nowrap;
`;
const Green = styled.span`
  color: ${({ theme }) => theme.duo.color.green};
`;

const ToLearn = () => (
  <Green>
    "<Trans>To learn</Trans>"
  </Green>
);

export const useReachedMaximum = () => {
  const { viewer, noPayments } = useViewerQuery();
  const { deck } = useViewerDecks();
  return !!deck && deck.stats.unknown >= TOLEARNMAX + (noPayments() ? NOPAYMENTSEXTRA : 0) && !viewer?.subscription;
};

type Props = {
  showPermanently?: boolean;
};

const CardsMaxDialog: React.FC<Props> = ({ showPermanently }) => {
  const navigate = useNavigate();
  const reachedMaximum = useReachedMaximum();
  const showWithPermanently = !!(showPermanently && reachedMaximum);
  const dispatch = useDispatch();
  const learnLimit = useSelector(selectLearnLimit);

  if (!learnLimit && !showWithPermanently) return null;

  return (
    <DialogWindow
      open
      onClose={() => {
        dispatch(setLearnLimit(false));
        if (showPermanently) {
          navigate(-1);
        }
      }}
      title={<SWhatshotIcon />}
    >
      <p>
        {/* prettier-ignore */}
        <Trans>
          You reached the free version limit of <Strong>{String(TOLEARNMAX)} cards</Strong> in{" "}
          <ToLearn /> state
        </Trans>
      </p>

      <Trans parent="p">Learn those before adding more. Or become a subscriber to remove all limits.</Trans>

      {isChromeExtension() ? (
        <PriceButton notInBrowser />
      ) : (
        <>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              dispatch(setLearnLimit(false));
              navigate(SUBSCRIPTION.url());
            }}
          >
            <Trans>Subscribe</Trans>
          </Button>

          <p style={{ margin: "10px 0 15px" }}>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => {
                dispatch(setLearnLimit(false));
                navigate(LEARN.url());
              }}
            >
              <Trans>Start learning</Trans>
            </Button>
          </p>
        </>
      )}
    </DialogWindow>
  );
};

export default CardsMaxDialog;
