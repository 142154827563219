import React from "react";
import { sentryCapture } from "sentry/sentry";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useViewerQuery } from "queries/viewerQuery";
import { MOTIVATIONHINT } from "root/tutorial/setting/Motivation";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { useUserSale } from "queries/users/userSaleQuery";
import { isIos } from "tools/device";
import { SUB, StoreId, useStoreIds } from "./subscriptionIds";
import { useIdle } from "react-use";
import { useDBLog } from "hooks/useDBLog";
import { SUBDBLOG } from "sharedJs__generated/constants";

export const useSubscribe = () => {
  const { storeIdWithSale } = useSaleExpiration();
  const { store, storePlatform } = useSubscriptions();
  const purchasedOffer = useGetState("purchasedOffer");
  const setPurchasedOffer = useSetState("purchasedOffer");
  const orderOpened = useGetState("orderOpened");
  const setOrderOpened = useSetState("orderOpened");
  const isIdle = useIdle(5e3);
  const dblog = useDBLog();

  React.useEffect(() => {
    // end the order proces after some time (enable subsribe button again)
    if (!isIdle && orderOpened && Date.now() - orderOpened > 5e3) {
      setOrderOpened(undefined);
    }
  }, [isIdle, orderOpened, setOrderOpened]);

  return (storeIdParam?: StoreId) => {
    const storeId = storeIdParam || storeIdWithSale;
    if (!orderOpened) {
      if (!store) return sentryCapture(`Subscription ERROR - no store defined in order`);

      // subscription is either root-one (year on android or all on iOS) or basePlan (on android)
      const subInfo = SUB.find((s) => s.id === storeId);
      const rootId = subInfo?.masterId || storeId;
      // offerId is id of base-plans on Androids (undefined = root-one)
      const offerId = subInfo?.masterId ? storeId : null;
      const product = store.get(rootId, storePlatform);
      if (product) {
        product.getOffer(offerId || undefined).order();
        !isIos() && setPurchasedOffer({ ...purchasedOffer, orderedId: offerId });
        dblog(SUBDBLOG, "clicked on subscribe", { rootId, offerId });
      } else {
        sentryCapture(`Subscription ERROR - store get failed 2`, { storeId, storePlatform, product });
      }
      setOrderOpened(Date.now());
    }
  };
};

const SALE_DEFAULT_DISCOUNT = 40;
const SALE_DEFAULT_TITLE = "Limited offer";

export const useSaleExpiration = () => {
  const { hintWasSeen, whenWasHintSeen } = useViewerQuery();
  const { storeIdYear, storeIdFamily } = useStoreIds();
  const { userSale } = useUserSale();

  if (userSale && userSale.id === "blackFriday") {
    return {
      saleExpiration: Math.floor(userSale.remaining / 1000),
      saleActive: true,
      storeIdWithSale: storeIdYear,
      familyIdWithSale: storeIdFamily,
      saleDiscount: SALE_DEFAULT_DISCOUNT,
      saleTitle: "Black Friday",
      saleId: userSale.id
    };
  }

  const saleLength: number = 5 * 60 * 60;
  const newUserSaleStart: number = whenWasHintSeen(MOTIVATIONHINT);
  const _saleExpiration: number = saleLength - Math.abs(Math.floor((Date.now() - newUserSaleStart) / 1000));
  const saleExpiration = _saleExpiration > 0 ? _saleExpiration : 0; // can be -Infinity when hint doesnt exists
  const afterTutorial: boolean = hintWasSeen(MOTIVATIONHINT) || hintWasSeen(TUTORIALDONE);
  // const saleActive: boolean = saleExpiration > 0 || !!viewer?.installReferrer
  const saleActive = true;
  const storeIdWithSale: StoreId = /*saleActive ||*/ afterTutorial ? storeIdYear : storeIdFamily;
  const familyIdWithSale: StoreId = storeIdFamily;

  // for debug
  // return {
  //   saleExpiration: saleLength,
  //   saleActive: true,
  //   storeIdWithSale: storeIdYear,
  //   familyIdWithSale: storeIdFamily,
  //   saleDiscount: SALE_DEFAULT_DISCOUNT,
  //   saleTitle: SALE_DEFAULT_TITLE
  // };

  return {
    saleExpiration,
    saleActive,
    storeIdWithSale,
    familyIdWithSale,
    saleDiscount: SALE_DEFAULT_DISCOUNT,
    saleTitle: SALE_DEFAULT_TITLE
  };
};

export const useSubscriptions = () => {
  const subscriptions = useGetState("subscriptions");
  const store = (window as any).CdvPurchase?.store;
  const Platform = (window as any).CdvPurchase?.Platform;
  const storePlatform = isIos() ? Platform?.APPLE_APPSTORE : Platform?.GOOGLE_PLAY;

  // for debug
  // const { storeIdFamily, storeIdMonth, storeIdYear } = useStoreIds();
  // const subscriptions = {
  //   [storeIdMonth]: { canPurchase: true, price: "CZK 220.00", priceMicros: 220_000_000 } as any,
  //   [storeIdYear]: { canPurchase: true, price: "CZK 850.00", priceMicros: 850_000_000 } as any,
  //   [storeIdFamily]: { canPurchase: true, price: "CZK 1300.00", priceMicros: 1300_000_000 } as any
  // };
  // const store = {
  //   manageSubscriptions: () => {},
  //   order: (...p) => {},
  //   update: () => {},
  //   initialize: (p: any) => {},
  //   restorePurchases: () => {},
  //   register: (p: any) => {},
  //   when: () => ({ updated: (p: any) => ({ approved: (p: any) => ({}) }) }),
  //   error: (p: any) => {},
  //   localReceipts: [] as any,
  //   get: (p: any, p2?: any) => ({
  //     getOffer: (p: any) => ({
  //       order: () => {}
  //     })
  //   }),
  //   PAID_SUBSCRIPTION: "PAID_SUBSCRIPTION"
  // };

  return { subscriptions, store, storePlatform };
};
