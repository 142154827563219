import React from "react";
import styled from "styled-components/macro";
import { Trans } from "react-i18next";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { devicePaddings } from "../../../tools/device";

const LEARNARROWSID = "learnArrows";
const DIDKNOWID = "didKnowId";

const { top } = devicePaddings();

export const setLearnSwipesOpacity = (opacity: number, oneByOne?: boolean) => {
  const el = document.getElementById(LEARNARROWSID);
  if (!el) return;
  if (oneByOne) {
    const secondEl = document.getElementById(DIDKNOWID);
    secondEl && (secondEl.style.opacity = "0");
    setTimeout(() => secondEl && secondEl.style.removeProperty("opacity"), 1200);
  }
  if (opacity) setTimeout(() => (el.style.opacity = `${opacity}`), oneByOne ? 400 : 0);
  if (!opacity) el.style.removeProperty("opacity");
};

const Wrap = styled.div`
  position: absolute;
  top: ${top + 100}px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0 30px;
  z-index: 20;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity 400ms;
`;

const Green = styled.div`
  color: ${({ theme }) => theme.duo.color.green};
`;
const Red = styled.div`
  color: ${({ theme }) => theme.duo.color.red};
  opacity: 1;
  transition: opacity 400ms;
`;
const Bold = styled.div`
  font-weight: bold;
`;
const Sides = styled.div`
  display: flex;
  justify-content: space-evenly;
  font-size: 95%;
  line-height: 1.4em;
  & > div {
    width: 43%;
  }
  svg {
    font-size: 26px;
  }
`;

type Props = {};

export const HintLearnSwipeIn = () => (
  <Sides>
    <Red>
      <ArrowBackRoundedIcon />
      <Trans parent="div">If you didn't know</Trans>
      <Bold>
        <Trans>swipe left</Trans>
      </Bold>
    </Red>
    <Green id={DIDKNOWID}>
      <ArrowForwardRoundedIcon className="green" />
      <Trans parent="div">If you were right</Trans>
      <Bold>
        <Trans>swipe right</Trans>
      </Bold>
    </Green>
  </Sides>
);

const LearnArrows: React.FC<Props> = React.memo(() => {
  return (
    <Wrap id={LEARNARROWSID}>
      <HintLearnSwipeIn />
    </Wrap>
  );
});

export default LearnArrows;
