import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectXp, setXp } from "../ReduxProvider";
import { useInterval } from "react-use";
import { useLocation, useMatch } from "react-router";
import { LEARN, CARD } from "../../root/main/MainRouter";
import { SOURCE } from "../../root/library/library/libraryUrls";
import { useXpIncrement } from "./xpIncrement";
import { ASSISTANT_HASH } from "../assistant/AssistantPopup";

type Props = {};

const FireChecker: React.FC<Props> = () => {
  const xp = useSelector(selectXp);
  const dispatch = useDispatch();
  const inLearn = useMatch(LEARN.url());
  const inCard = useMatch(CARD.url());
  const inSource = useMatch(SOURCE.query());
  const xpIncrement = useXpIncrement();
  const location = useLocation();
  const isAssistant = location.hash === ASSISTANT_HASH;

  useInterval(() => {
    // reset after overflowed
    if (xp.overflowed) {
      dispatch(
        setXp({
          ...xp,
          stack: 0,
          overflowed: false
        })
      );
    }
    // increment
    else if (xp.strength > 0 && (inLearn || inCard || inSource || isAssistant)) {
      const strength =
        xp.lastActivity && (Date.now() - xp.lastActivity) / 1000 < 12 - xp.strength ? xp.strength : xp.strength - 1;
      const stack = xp.warn ? xp.stack : xp.stack + xp.strength + 2;
      const overflowed = stack >= 100;
      dispatch(
        setXp({
          ...xp,
          stack: overflowed ? 100 : stack,
          overflowed,
          strength
        })
      );
      if (overflowed) {
        xpIncrement(); // increment XP in DB
      }
    }
  }, 2200);

  return null;
};

export default FireChecker;
