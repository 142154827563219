import React from "react";
import { Trans } from "react-i18next";
import styled, { keyframes } from "styled-components/macro";
import FilterIcon from "icons/FilterIcon";
import { Button, Paper } from "@mui/material";
import { devicePaddings } from "tools/device";
import Mammoth from "icons/Mammoth";
import StarIcon from "components/other/StarIcon";
import { useNavigate } from "react-router";
import { BrandTitle } from "components/other/BrandTitle";
import { useTimedActions } from "hooks/useTimedActions";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";

const { top, bottom } = devicePaddings();

const Wrap = styled.div`
  height: 100%;
  padding: ${top + 10}px 20px ${bottom + 10}px;
  box-shadow: inset 0 -20px 100px #00aaff33;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  font-size: 17px;
  line-height: 1.5em;
  background: ${({ theme }) => theme.duo.color.themeGradient};

  .showing {
    opacity: 1;
    transition: opacity 1s;
  }
  .hidden {
    opacity: 0;
    visibility: hidden;
  }
`;
const pulsingKF = keyframes`
  0%, 100% { transform: scale(1); }
  50% {  transform: scale(1.2); }
`;
const Icon = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 50px;
  padding: 15px 16px 15px 14px;
  width: 70px;
  height: 70px;
  box-shadow: 1px 1px 6px #0003;
  background: white;
  animation: ${pulsingKF} 2s ease-out infinite;
`;
const SFilterIcon = styled(FilterIcon)`
  width: 30px;
  height: 30px;
  margin: 9px 6px;
  fill: ${({ theme }) => theme.palette.primary.main};
`;
const Top = styled(BrandTitle)`
  font-size: 30px;
  margin: 18px 0;
  text-align: center;
  line-height: 1em;
`;
const SButton = styled(Button)`
  &&& {
    background: white;
    color: ${({ theme }) => theme.duo.color.primary};
    padding: 12px 45px;
    font-size: 17px;
    transition: all 1s;
  }
`;
const Bubble = styled(Paper)`
  padding: 20px 25px;
  position: relative;
`;
const Arrow = styled.div`
  position: absolute;
  bottom: -5px;
  left: 50px;
  width: 8px;
  height: 8px;
  border: 1px solid #888;
  border-top: none;
  border-left: none;
  transform: rotate(45deg);
  background: white;
`;
const SMammoth = styled(Mammoth)`
  position: absolute;
  left: 20px;
  bottom: -72px;
  top: auto;
  svg {
    width: 60px;
    height: 60px;
  }
`;
const StarSmall = styled(StarIcon)`
  top: 8px;
  right: 7px;
  && {
    font-size: 18px;
  }
`;
const ExitButton = styled(Button)`
  color: white;
  margin-top: -10px;
  opacity: 0;
  transition: all 2s;
`;

type Props = {
  onClose: VoidFunction;
};

const InternAdSets: React.FC<Props> = ({ onClose }) => {
  const navigate = useNavigate();
  const [visibleIndex, setVisibleIndex] = React.useState(0);
  useTimedActions([
    { delay: 0, callback: () => setVisibleIndex(1) },
    { delay: 550, callback: () => setVisibleIndex(2) },
    { delay: 1100, callback: () => setVisibleIndex(3) },
    { delay: 1650, callback: () => setVisibleIndex(4) },
    { delay: 2200, callback: () => setVisibleIndex(5) },
    { delay: 4000, callback: () => setVisibleIndex(6) }
  ]);

  const order = (e) => {
    e.stopPropagation();
    onClose();
    navigate(SUBSCRIPTION.url());
  };

  return (
    <Wrap>
      <div />

      <Bubble className={visibleIndex >= 1 ? "showing" : "hidden"}>
        <Top className={visibleIndex >= 2 ? "showing" : "hidden"}>DuoCards Premium</Top>
        <Trans parent="p" className={visibleIndex >= 3 ? "showing" : "hidden"}>
          Enjoy an unlimited amount of cards, which you can <b>filter out</b> in learning mode!
        </Trans>

        <Arrow />

        <SMammoth />
      </Bubble>

      <Icon className={visibleIndex >= 4 ? "showing" : "hidden"}>
        <SFilterIcon />
        <StarSmall unconditional />
      </Icon>

      <div className={visibleIndex >= 5 ? "showing" : "hidden"}>
        <SButton variant="contained" color="primary" size="large" onClick={order}>
          <Trans>Show me options</Trans>
        </SButton>
      </div>
      <ExitButton onClick={onClose} className={visibleIndex >= 6 ? "showing" : "hidden"}>
        <Trans>I'm not interested</Trans>
      </ExitButton>

      <div />
    </Wrap>
  );
};

export default InternAdSets;
