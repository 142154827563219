import React, { useRef } from "react";
import { Trans } from "react-i18next";
import styled, { keyframes } from "styled-components/macro";
import { Button, Slide } from "@mui/material";
import { useSeeHint } from "components/hint/Hint";
import { Alignment } from "@rive-app/react-canvas";
import { SUBSCRIPTION } from "../../profile/ProfileRouter.config";
import { useNavigate } from "react-router";
import { useViewerQuery } from "queries/viewerQuery";
import FeaturesHintAnimation, { Feature } from "./FeaturesHintAnimation";
import { useGetState, useSetState } from "components/ReduxProvider";
import DialogFullWindow from "components/other/DialogFullWindow";
import { MOTIVATIONHINT } from "./Motivation";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const features: Feature[] = [
  {
    title: "Add new words via the share button",
    rive: "https://storage.googleapis.com/imgs.duocards.com/rive/onboarding-web.riv",
    alignment: Alignment.Center
  },
  {
    title: "Create custom cards in the app",
    rive: "https://storage.googleapis.com/imgs.duocards.com/rive/onboarding-add.riv",
    alignment: Alignment.Center,
    texts: [
      {
        id: "Run-Original",
        text: "Rabbit",
        translate: "front"
      },
      {
        id: "Run-Translate",
        text: "Rabbit",
        translate: "back"
      },
      {
        id: "Run-Save",
        text: "Save",
        translate: "app"
      }
    ]
  },
  {
    title: "Add words from a PC via Chrome Extensions",
    rive: "https://storage.googleapis.com/imgs.duocards.com/rive/onboarding-chrome.riv",
    alignment: Alignment.CenterLeft,
    texts: [
      {
        id: "Run-Original",
        text: "Word",
        translate: "front"
      },
      {
        id: "Run-Translate",
        text: "Word",
        translate: "back"
      }
    ]
  },
  {
    title: "Learn grammar with our AI assistant",
    rive: "https://storage.googleapis.com/imgs.duocards.com/rive/onboarding-chat.riv",
    alignment: Alignment.Center,
    texts: [
      {
        id: "RunQuestion",
        text: "Question",
        translate: "front"
      },
      {
        id: "RunAnswer",
        text: "Answer",
        translate: "front"
      }
    ]
  }
];

const SDialogFullWindow = styled(DialogFullWindow)`
  background-color: #0a70a2;
`;

const ModalContent = styled.div`
  background-color: #0a70a2;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
`;

const STitle = styled.div`
  flex: 0 1 auto;
  color: ${({ theme }) => theme.duo.color.white};
  font-size: 32px;
  font-weight: 700;
  margin-top: 60px;
  margin-bottom: 30px;
  line-height: 1.2;
  opacity: 0;
  animation: ${fadeIn} 0.3s ease-in-out 0s forwards;
`;

const AnimWrap = styled.div<{ $isLeftAligned?: boolean }>`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $isLeftAligned }) => ($isLeftAligned ? "calc(100% + 30px)" : "100%")};
  margin-right: ${({ $isLeftAligned }) => ($isLeftAligned ? "30px" : "0")};
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-in-out 0.6s forwards;
`;

const SButton = styled(Button)`
  opacity: 0;
  animation: ${fadeIn} 0.5s ease-in-out 1.2s forwards;
  width: 100%;
  margin-top: 44px;
  margin-bottom: 44px;
`;
type Props = {};

const FeaturesHint: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { viewer } = useViewerQuery();
  const seeHint = useSeeHint();
  const setFeatureIndex = useSetState("onboardingFeaturesScreenIndex");
  const featureIndex = useGetState("onboardingFeaturesScreenIndex");
  const animationRef = useRef<{ startAnimation: () => void }>(null);

  const handleNextClick = () => {
    const currentIndex = featureIndex ?? 0;
    if (currentIndex < features.length - 1) {
      setFeatureIndex(currentIndex + 1);
      return;
    }

    seeHint(MOTIVATIONHINT);
    if (!viewer?.inviter) {
      navigate(SUBSCRIPTION.url());
    }
  };

  const handleAnimWrapFadedIn = () => {
    animationRef.current?.startAnimation();
  };

  const feature = features[featureIndex ?? 0];

  return (
    <SDialogFullWindow open={true} background={true} TransitionComponent={Slide}>
      <ModalContent key={`content-${featureIndex}`}>
        <STitle>
          <Trans>{feature.title}</Trans>
        </STitle>
        <AnimWrap
          key={`anim-${featureIndex}`}
          $isLeftAligned={feature.alignment === Alignment.CenterLeft}
          onAnimationEnd={handleAnimWrapFadedIn}
        >
          <FeaturesHintAnimation key={`rive-${featureIndex}`} feature={feature} ref={animationRef} />
        </AnimWrap>
        <SButton key={`button-${featureIndex}`} onClick={handleNextClick} variant="contained" color="info" size="large">
          <Trans>Continue</Trans>
        </SButton>
      </ModalContent>
    </SDialogFullWindow>
  );
};

export default FeaturesHint;
