import React from "react";
import styled from "styled-components/macro";
import { fromSourceOrDefault, Image } from "../../../tools/unsplash";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PublicIcon from "@mui/icons-material/Public";
import { sourceQuery$data } from "../../../queries/sources/__generated__/sourceQuery.graphql";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PhotoLibraryOutlinedIcon from "@mui/icons-material/PhotoLibraryOutlined";
import LinkExt from "../../../components/other/LinkExt";
import { Link } from "react-router-dom";
import { AUTHOR } from "../../RootRouter.config";

const Wrap = styled.div`
  font-size: ${({ theme }) => theme.duo.fontSize.smallest};
  color: #bbb;
  margin: 0 10px;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  a {
    color: #bbb;
    text-decoration: underline;
  }
  svg {
    font-size: 13px;
    position: relative;
    top: 2px;
    margin: 0 1px 0 5px;
  }
`;

export const ImgAuthor: React.FC<{ image?: Image | null }> = ({ image }) => {
  if (!image) return null;
  return (
    <>
      <ImageOutlinedIcon />
      <LinkExt href={`https://unsplash.com/@${image.authorApiName}?utm_source=DuoCards.com&utm_medium=referral`}>
        {image.authorName}
      </LinkExt>
      <PhotoLibraryOutlinedIcon />
      <LinkExt href="https://unsplash.com/?utm_source=DuoCards.com&amp;utm_medium=referral">Unsplash</LinkExt>
    </>
  );
};

type Props = {
  source: NonNullable<sourceQuery$data["sourceByUriOrId"]>;
};

const Metadata: React.FC<Props> = ({ source }) => {
  return (
    <Wrap>
      {source?.private && <LockOutlinedIcon />}{" "}
      {source.authorName && (
        <>
          by <Link to={AUTHOR.url(source.authorName)}>{source.authorName}</Link>
        </>
      )}
      {source.source && (
        <>
          <PublicIcon />
          {source.source.startsWith("www") || source.source.startsWith("http") ? (
            <>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={(source.source.startsWith("www") ? "https://" : "") + source.source}
              >
                {source.source.replace(/(https?:\/\/)?(www.)?/, "").substring(0, 16)}
              </a>
              ...
            </>
          ) : (
            <>
              {source.source.substring(0, 16)}
              {source.source.length > 16 && "..."}
            </>
          )}
        </>
      )}
      {source.kind === "article" && <ImgAuthor image={fromSourceOrDefault(source) as typeof source.image} />}
    </Wrap>
  );
};

export default Metadata;
