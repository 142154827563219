import React from "react";
import styled from "styled-components/macro";
import Mammoth from "icons/Mammoth";
import { GreyArrow, GreyBubble } from "./GreyBubble";
import { useGetState } from "components/ReduxProvider";

export const FIRSTPET = "firstPet";

const Wrap = styled.div`
  position: relative;
  text-align: center;
`;

type Props = {
  gig?: React.ReactNode;
  style?: React.CSSProperties;
  forceCanPet?: boolean;
  noBubble?: boolean;
  noPet?: boolean;
  petting?: boolean;
  inGarden?: boolean;
};

const PetableMammoth: React.FC<Props> = ({ gig, style, forceCanPet, noBubble, noPet, inGarden }) => {
  const canPet = !noPet || forceCanPet;
  const petting = useGetState("pettingMemo");

  return (
    <Wrap style={style}>
      {!noBubble && gig && (
        <GreyBubble hidden={!!petting}>
          {gig}
          <GreyArrow />
        </GreyBubble>
      )}
      <div>
        <Mammoth inGarden={inGarden} canPet={canPet} />
      </div>
    </Wrap>
  );
};

export default PetableMammoth;
