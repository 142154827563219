import { isEqual } from "lodash";
import React from "react";
import { useEffectOnce } from "react-use";
import TutorialPointer from "root/tutorial/TutorialPointer";
import { SET_OR_COURSE_CLS } from "../SourceCard";

type Props = {};

const LibraryPointer: React.FC<Props> = () => {
  const [pointerPos, setPointerPos] = React.useState({ left: 0, top: 0 });

  const showSourceCard = () => {
    const card = (document.querySelectorAll(`.${SET_OR_COURSE_CLS}`) as any)?.[0];
    if (card) {
      const rect = card.getBoundingClientRect();
      const newPos = rect && { left: rect.left + 30, top: rect.top + 100 };
      newPos && !isEqual(newPos, pointerPos) && setPointerPos(newPos);
    }
  };

  useEffectOnce(() => {
    const iId = setInterval(() => showSourceCard(), 500);
    return () => clearInterval(iId);
  });

  if (!pointerPos.left) return null;

  return <TutorialPointer pulsing style={pointerPos} />;
};

export default LibraryPointer;
