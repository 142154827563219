import React from "react";
import styled from "styled-components/macro";
import PetableMammoth from "./PetableMammoth";
import SignalWifiStatusbarConnectedNoInternet4Icon from "@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4";
import { Button } from "@mui/material";
import { devicePaddings } from "tools/device";
import { useNetworkState } from "react-use";
import { useGetState } from "components/ReduxProvider";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Trans } from "react-i18next";
import { windowReload } from "tools/windowReload";

const { bottom } = devicePaddings();

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  justify-content: center;
`;

const NoNetwork = styled(SignalWifiStatusbarConnectedNoInternet4Icon)`
  color: ${({ theme }) => theme.duo.color.flame};
  font-size: 30px;
  margin: 5px 0 0;
`;
const SButton = styled(Button)`
  margin-top: 30px;
`;
const Message = styled.div`
  font-size: 11px;
  color: #ccc;
  position: absolute;
  bottom: ${15 + bottom}px;
`;
const SRefreshIcon = styled(RefreshIcon)`
  font-size: 18px;
  margin-right: 5px;
`;

type Props = {};

const ErrorMammoth: React.FC<Props> = () => {
  const network = useNetworkState();
  const connectionProblem = useGetState("connectionProblem");

  return (
    <Wrap>
      <PetableMammoth gig={<NoNetwork />} forceCanPet={true} />
      <SButton onClick={() => windowReload()}>
        <SRefreshIcon /> <Trans>Refresh</Trans>
      </SButton>
      <Message>
        connection: {network.online ? "ok" : "offline"}, server: {!connectionProblem ? "ok" : "error"}
      </Message>
    </Wrap>
  );
};

export default ErrorMammoth;
