import React from "react";
import styled from "styled-components/macro";
import { Paper, Button } from "@mui/material";
import { useViewerQuery } from "../../queries/viewerQuery";
import { useMutation } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { HintMutation } from "./__generated__/HintMutation.graphql";
import Nofilter from "../other/Nofilter";

export const seeHintQL = graphql`
  mutation HintMutation($name: String!) {
    seeHint(name: $name) {
      seenHints {
        name
        when
      }
    }
  }
`;
export const useSeeHint = () => {
  const { hintWasSeen, viewer } = useViewerQuery();
  const [seeHint] = useMutation<HintMutation>(seeHintQL);

  return React.useCallback(
    (name: string) => {
      if (!hintWasSeen(name)) {
        seeHint({
          variables: { name },
          optimisticUpdater: (store) => {
            const viewerRec = viewer?.id && store.get(viewer.id);
            if (!viewerRec) return;
            const hints = viewerRec.getLinkedRecords("seenHints");
            if (!hints) return;
            let hint = hints?.find((f) => f?.getValue("name") === name);
            if (!hint) {
              hint = store.create("newID" + Math.floor(Math.random() * 10e8), "Hint");
              viewerRec.setLinkedRecords([...hints, hint], "seenHints");
            }
            hint.setValue(name, "name");
            hint.setValue(Date.now(), "when");
          }
        });
      }
    },
    [hintWasSeen, seeHint, viewer?.id]
  );
};

const SPaper = styled(Paper)`
  align-items: center;
  padding: 16px;
  margin: 15px 0;
  text-align: center;
`;
export const HintEmoji = styled(Nofilter)`
  display: block;
  font-size: 28px;
  margin: 10px 0;
  svg {
    color: ${({ theme }) => theme.duo.color.primary};
    position: relative;
    top: 5px;
  }
`;
const ButtWrap = styled.div`
  padding: 12px 0 3px;
`;

type Props = {
  children: React.ReactNode;
  name?: string;
  className?: string;
  emoji?: React.ReactNode;
  onClose?: VoidFunction;
  noOk?: boolean;
  id?: string;
  noViewer?: boolean;
  style?: React.CSSProperties;
};

const Hint: React.FC<Props> = (p) => {
  const { hintWasSeen, viewer } = useViewerQuery();
  const seeHint = useSeeHint();

  if ((viewer || !p.noViewer) && p.name && hintWasSeen(p.name)) return null;

  const handleClick = () => {
    p.name && seeHint(p.name);
    p.onClose && p.onClose();
  };

  return (
    <SPaper className={p.className} id={p.id} style={p.style}>
      {p.emoji !== "" && <HintEmoji>{p.emoji || `💡`}</HintEmoji>}
      {p.children}
      {p.name && !p.noOk && (
        <ButtWrap>
          <Button color="primary" variant="contained" onClick={handleClick}>
            OK
          </Button>
        </ButtWrap>
      )}
    </SPaper>
  );
};

export default Hint;
