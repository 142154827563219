import React from "react";
import { Trans } from "react-i18next";
import { Button, Stack } from "@mui/material";
import { windowReload } from "tools/windowReload";

const NewVersionAvailable: React.FC = () => {
  return (
    <Stack direction="column" alignItems="center" justifyContent="center">
      <Trans parent="p">New version available!</Trans>
      <Button variant="outlined" size="small" onClick={() => windowReload()}>
        <Trans>Reload</Trans>
      </Button>
    </Stack>
  );
};

export default NewVersionAvailable;
