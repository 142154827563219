import { useQuery } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { GenericVariableInput, useAIRequestQuery } from "./__generated__/useAIRequestQuery.graphql";
import { AIRequestTemplates } from "sharedJs__generated/constants";

const aiRequestQuery = graphql`
  query useAIRequestQuery($template: String!, $variables: [GenericVariableInput!]) {
    aiRequest(template: $template, variables: $variables)
  }
`;

export function useAIRequest(template: AIRequestTemplates, variables?: GenericVariableInput[], skip: boolean = false) {
  const { data, isLoading } = useQuery<useAIRequestQuery>(
    aiRequestQuery,
    {
      template,
      variables
    },
    { skip }
  );

  return {
    result: data?.aiRequest || "",
    isLoading
  };
}
