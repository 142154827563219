import { ComponentProps } from "react";

const MaleIcon = (props: ComponentProps<"svg">) => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="783.044px"
    height="783.044px"
    viewBox="0 0 783.044 783.044"
    {...props}
  >
    <g>
      <path
        style={{ fill: "navy" }}
        d="M751.123,715L751.123,715L751.123,715c-0.5-3.4-1.301-6.6-2.4-9.7c-7.9-30.399-26-85.8-34.199-110.6
		c-0.9-4.3-2.301-8.5-4.102-12.4c0-0.1,0-0.1,0-0.1l0,0c-5-10.8-13.199-19.8-23.299-25.8l0,0c-0.6-0.4-1.9-1.101-3.6-2
		c-0.701-0.301-1.4-0.7-2.102-1c-19.898-9.4-81-33.801-81-33.801l0,0c-30.6-13.199-62.199-24.5-94.5-36l0,0l0,0l0,0l0,0
		c-5.799,24-60.5,183.301-69.199,205.4l-6.9-148c2.301-3.5,3.9-7.5,5.701-11.3l22.799-45.101c-16.1,13.4-40.199,21.9-67.1,21.9
		c-26.3,0-49.899-8.1-66-21l22.4,44.2c1.8,3.8,3.4,7.8,5.7,11.3l-6.9,148c-8.8-22.1-63.6-181.5-69.3-205.5l0,0l0,0l0,0l0,0
		c-32.3,11.5-63.9,22.8-94.5,36l0,0c0,0-61.1,24.5-81,33.8c-0.7,0.3-1.4,0.601-2.1,1c-1.7,0.8-3,1.5-3.6,2l0,0
		c-10.1,6.101-18.3,15.101-23.3,25.9l0,0v0.1c-1.8,3.9-3.2,8.101-4.1,12.4c-8.2,24.8-26.3,80.1-34.2,110.6
		c-1.1,3.101-1.9,6.4-2.4,9.7l0,0l0,0c-0.5,2.9-0.7,6-0.7,9c0,32.6,26.4,59,59,59h56.3c80.6,0,161.2,0.1,241.8,0c1.1,0,2.1,0,3.2,0
		l0,0c1.1,0,2.1,0,3.2,0c80.6,0,161.199,0,241.801,0h56.299c32.6,0,59-26.4,59-59C751.822,721,751.523,717.9,751.123,715z"
      />
      <path
        style={{ fill: "navy" }}
        d="M545.523,209.5c0-10.4,2-48.2,2-56.9C547.623,68.3,479.222,0,394.921,0l0,0l0,0c-0.1,0-0.199,0-0.299,0s-0.201,0-0.301,0
		l0,0l0,0c-1,0-1.9,0-2.9,0c-1,0-1.899,0-2.899,0l0,0l0,0c-0.101,0-0.2,0-0.301,0c-0.1,0-0.199,0-0.3,0l0,0l0,0
		c-84.3,0-152.7,68.4-152.7,152.7c0,8.7,2,46.4,2,56.9c-3.7,0.2-37.9-8-34.2,32.9c7.8,86.8,40.9,70,41.9,70
		c16.3,52.3,41.6,85.7,66.4,107c38.7,33.2,76.2,36.8,76.899,36.8c1.101,0,2.101,0.101,3.2,0.101l0,0l0,0c1,0,2.101,0,3.201-0.101
		c0.6,0,37.9-3.6,76.4-36.399c24.898-21.2,50.398-54.7,66.799-107.4c1,0,34.1,16.8,41.9-70
		C583.421,201.5,549.222,209.7,545.523,209.5z"
      />
    </g>
  </svg>
);

export default MaleIcon;
