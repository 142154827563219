/**
 * @generated SignedSource<<5633330f000f243589bc8553a9e6cfda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubsKickerMutation$variables = {
  name: string;
};
export type SubsKickerMutation$data = {
  readonly seeHint: {
    readonly seenHints: ReadonlyArray<{
      readonly name: string;
      readonly when: number;
    }>;
    readonly showSubsKickDialog: boolean;
  };
};
export type SubsKickerMutation = {
  response: SubsKickerMutation$data;
  variables: SubsKickerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Hint",
  "kind": "LinkedField",
  "name": "seenHints",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "when",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showSubsKickDialog",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubsKickerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "seeHint",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubsKickerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "seeHint",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a55a8d29d5cc5011ec40c121e6ab1ed1",
    "id": null,
    "metadata": {},
    "name": "SubsKickerMutation",
    "operationKind": "mutation",
    "text": "mutation SubsKickerMutation(\n  $name: String!\n) {\n  seeHint(name: $name) {\n    seenHints {\n      name\n      when\n    }\n    showSubsKickDialog\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9351d68bca2ec639feeaf921803f41d";

export default node;
