import React from "react";
import styled from "styled-components/macro";

const Wrap = styled.div`
  position: relative;
  width: 40px;
  height: 10px;
  margin: 5px;

  span {
    content: "";
    animation: blink 1.5s infinite;
    animation-fill-mode: both;
    height: 10px;
    width: 10px;
    background: #999;

    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;

    &:nth-child(2) {
      animation-delay: 0.2s;
      margin-left: 15px;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
      margin-left: 30px;
    }
  }

  @keyframes blink {
    0% {
      opacity: 0.1;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }
`;

type Props = {};

const TypingDots: React.FC<Props> = () => {
  return (
    <Wrap>
      <span></span>
      <span></span>
      <span></span>
    </Wrap>
  );
};

export default TypingDots;
