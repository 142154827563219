import React from "react";
import styled from "styled-components/macro";

const Wrap = styled.div`
  position: relative;
`;
const Circle = styled.div<{ iterations: string | number; bold?: boolean }>`
  position: absolute;
  top: 0;
  width: 24px;
  height: 24px;
  border: 1px solid ${({ theme }) => theme.duo.color.primary};
  border-radius: 50%;
  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0.14, 0.26, 0.08, 1);
  animation-name: ${({ bold }) => (bold ? "pulseBold" : "pulse")};
  animation-iteration-count: ${({ iterations }) => iterations};
  opacity: 0;

  /* keyframes are in theme */
`;

type Props = {
  children: React.ReactNode;
  iterations?: string | number;
  id?: string;
  bold?: boolean;
  className?: string;
};

const Pulse: React.FC<Props> = ({ children, iterations, id, bold, className }) => {
  return (
    <Wrap className={className}>
      {children}
      <Circle iterations={iterations !== undefined ? iterations : "infinite"} id={id} bold={bold} />
    </Wrap>
  );
};

export default Pulse;
