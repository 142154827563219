import React from "react";
import styled from "styled-components/macro";
import { Trans } from "react-i18next";
import IceCube from "./IceCube";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
`;

const StreakFreezeChargeAchievement: React.FC = () => {
  return (
    <Wrap>
      <div>
        <Trans>You just earned freeze for daily series!</Trans>
      </div>
      <IceCube coldness={3} number={1} />
    </Wrap>
  );
};

export default StreakFreezeChargeAchievement;
