import React from "react";
import { HeaderLayout } from "../../../components/header/Header";
import { sourceEditQL } from "./sourceEditQuery";
import { sourceEditQuery } from "./__generated__/sourceEditQuery.graphql";
import { useQuery } from "relay-hooks";
import SourceForm from "./SourceForm";
import TopRow from "./components/TopRow";
import CardsRow from "./components/CardsRow";
import { SourceKind } from "../LibraryRouter";
import ImgPicker from "./ImgPicker";
import SourceEditDots from "./components/SourceEditDots";
import SourcePublish from "./components/SourcePublish";
import NativeRow from "./components/NativeRow";
import NativeCreate from "./sourceCards/NativeCreate";
import styled from "styled-components/macro";
import DialogFullWindow from "../../../components/other/DialogFullWindow";
import SourceCards from "./sourceCards/SourceCards";
import { usePrevious } from "react-use";
import { Trans } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SOURCE, SOURCEFORM } from "../library/libraryUrls";
import { Button } from "@mui/material";

export const CARDS_ACTION = "cards";

const SDialogFullWindow = styled(DialogFullWindow)`
  margin-top: 0;
`;
const Warn = styled.p`
  text-align: center;
  color: orange;
`;
const SButton = styled(Button)`
  width: calc(100% - 160px);
  margin: 30px 80px;
  color: ${({ theme }) => theme.duo.color.text};
  border: 1px solid ${({ theme }) => theme.duo.color.text};
  &:hover {
    border: 1px solid ${({ theme }) => theme.duo.color.text};
  }
`;

const SourceEdit: React.FC<{}> = () => {
  const [langNative, setLangNative] = React.useState<string>();
  const [openNew, setOpenNew] = React.useState(false);
  const [fetchKey, refetch] = React.useState(0);
  const [searchParams] = useSearchParams();
  const id = searchParams.get(SOURCEFORM.idParam);
  const action = searchParams.get(SOURCEFORM.actionParam);
  const [openForm, setOpenForm] = React.useState(!id);
  const navigate = useNavigate();

  const { data } = useQuery<sourceEditQuery>(
    sourceEditQL,
    { id: id || "" },
    { skip: !id, fetchPolicy: "store-and-network", fetchKey }
  );
  const source = data?.node;

  const prevAction = usePrevious(action);
  React.useEffect(() => {
    if (prevAction === CARDS_ACTION && action !== CARDS_ACTION) {
      refetch(Math.random());
    }
  }, [action, refetch, prevAction]);

  React.useEffect(() => {
    if (
      source?.natives?.length &&
      (langNative === undefined || !source?.natives.some((n) => n.langNative === langNative))
    ) {
      setLangNative(source?.natives[0].langNative);
    }
  }, [source, langNative]);

  return (
    <HeaderLayout transparent withShadow right={source && <SourceEditDots source={source} />}>
      <ImgPicker source={source} />
      {source?.id && <TopRow source={source} onClick={() => setOpenForm(!openForm)} openForm={openForm} />}

      {openForm && <SourceForm source={source} onSave={() => setOpenForm(false)} />}

      {source?.id && source?.kind === SourceKind.set && (
        <NativeRow
          setLangNative={setLangNative}
          source={source}
          langNative={langNative}
          nativeCreate={() => setOpenNew(true)}
        />
      )}

      {source?.kind === SourceKind.set && (
        <>
          {!langNative && !source.multiLang ? (
            <Warn>Create a translate above to be able to edit cards</Warn>
          ) : (
            <>
              {<CardsRow source={source} />}
              <SDialogFullWindow open={action === "cards"} background>
                <SourceCards
                  langNative={langNative || (source.lang !== "en" ? "en" : "es")}
                  nativeCreate={() => setOpenNew(true)}
                  setLangNative={setLangNative}
                />
              </SDialogFullWindow>
            </>
          )}
        </>
      )}

      {source && (
        <>
          {!source?.sCards?.length && source?.kind === SourceKind.set ? (
            <Warn>
              <Trans>Add cards before continuing</Trans>
            </Warn>
          ) : (
            <SourcePublish source={source} />
          )}
        </>
      )}

      {source && (
        <NativeCreate
          open={openNew}
          onClose={() => setOpenNew(false)}
          source={source}
          onSubmit={(lang) => {
            setOpenNew(false);
            setLangNative(lang);
          }}
        />
      )}
      {source && (
        <SButton
          variant="outlined"
          color="success"
          onClick={() => {
            navigate(SOURCE.url(source.uri as string));
          }}
        >
          <Trans>View</Trans>
        </SButton>
      )}
    </HeaderLayout>
  );
};

export default SourceEdit;
