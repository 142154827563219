import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { useNavigate } from "react-router";
import { editDeckMutation } from "./__generated__/editDeckMutation.graphql";
import { MAIN } from "../../root/RootRouter.config";

const mutation = graphql`
  mutation editDeckMutation($id: ID!, $name: String, $back: String!) {
    editDeck(id: $id, name: $name, back: $back) {
      ...viewerQuery_decks
    }
  }
`;

export const useEditDeckMutation = () => {
  const navigate = useNavigate();

  const [mutate] = useMutation<editDeckMutation>(mutation, {
    onCompleted: () => navigate(`${MAIN.url()}`)
  });

  return (variables: editDeckMutation["variables"]) => mutate({ variables });
};
