import React from "react";
import { IconButton } from "@mui/material";
import styled from "styled-components/macro";
import StarIcon from "components/other/StarIcon";

const SBottomIcon = styled(IconButton)<{ disabled?: boolean }>`
  position: absolute;
  bottom: 20px;
  left: 16px;
  width: 34px;
  height: 34px;
  background: white !important;
  border: ${({ theme, disabled }) => theme.duo.border.grey + (disabled ? "77" : "")};
  border-radius: 50%;
  padding: 5px;
`;

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick: any;
  style?: React.CSSProperties;
  className?: string;
  premium?: boolean;
};

const BottomIcon: React.FC<Props> = ({ disabled, children, onClick, style, className, premium }) => {
  return (
    <SBottomIcon disabled={disabled} onClick={onClick} style={style} className={className}>
      {premium && <StarIcon />}
      {children}
    </SBottomIcon>
  );
};

export default BottomIcon;
