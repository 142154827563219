import React from "react";
import styled from "styled-components/macro";
import { GenderT, genders } from "../sharedJs__generated/gens";
import { escapeRegExp } from "lodash";

const Gender = styled.span<{ color?: string }>`
  color: ${({ color, theme }) =>
    color === "green"
      ? theme.duo.color.green
      : color === "blue"
        ? theme.duo.color.primary
        : color === "pink"
          ? "#d37"
          : theme.duo.color.textGrey};
  opacity: 0.6;
  font-weight: normal;
`;

const getGender = (genderPrefix: string, lang: string): GenderT | undefined => {
  return genders(lang).find((g) => g.prefix === genderPrefix.toLocaleLowerCase());
};

export const genderStyle = (text: string, lang: string) => {
  const gendersRegex = genders(lang)
    .map((g) => (g.prefix[0] === "(" ? "" : `\\b`) + escapeRegExp(g.prefix))
    .join("|");

  const parts = text.split(new RegExp("(" + gendersRegex + ")", "gi")).filter((token) => !!token);

  const result = parts.map((token, i) => {
    const prevCharIsBlank = () => i === 0 || parts[i - 1][parts[i - 1].length - 1].match(/\s/g);

    if (getGender(token, lang) && (token[0] === "(" || prevCharIsBlank())) {
      return (
        <Gender className="nofilter" color={getGender(token, lang)?.color}>
          {token}
        </Gender>
      );
    }
    return token;
  });

  return (
    <>
      {result.map((r, i) => (
        <React.Fragment key={i}>{r}</React.Fragment>
      ))}
    </>
  );
};
