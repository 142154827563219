import { Fade, Paper } from "@mui/material";
import { PICKED_FEW_CARDS } from "components/fire/xpHooks";
import { useSeeHint } from "components/hint/Hint";
import { MINWIDTH } from "components/initializers/ViewportKiller";
import { selectMotivationGig, useGetState, useSetState } from "components/ReduxProvider";
import Garden from "icons/garden/Garden";
import { useViewerQuery, useViewerXps } from "queries/viewerQuery";
import React from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffectOnce, usePreviousDistinct } from "react-use";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import {
  LOSING_XPS_HINT,
  SHARE_AND_CHROME_QUEST,
  STREAK_FREEZE_EARN_QUEST,
  STREAK_FREEZE_QUEST
} from "sharedJs__generated/constants";
import styled from "styled-components/macro";
import DailyXps, { DAILYXPS } from "./DailyXps";
import ShareAndChrome from "./everySource/ShareAndChrome";
import QuestsBackdrop from "./everySource/QuestsBackdrop";
import FewMoreCards from "./FewMoreCards";
import { GreyArrow, GreyBubble } from "../../../../components/mammoth/GreyBubble";
import { motivationGigs } from "./misc/motivationGigs";
import { FIRSTPET } from "../../../../components/mammoth/PetableMammoth";
import OwnWord, { OWNWORDQUEST } from "./everySource/OwnWord";
import StreakFreezeEarn from "./StreakFreezeEarn";
import XpsQuests from "./XpsQuests";
import AiAssistantQuest, { AI_ASSISTANT_QUEST } from "./AiAssistantQuest";

export const QUESTS_ID = "questsId";
export const GARDENWIDTH = MINWIDTH + 25;

const Wrap = styled.div<{ $scale?: number }>`
  min-width: 340px;
  margin: auto 0;
  padding: 12px 0;
  transform-origin: left top;
  transform: scale(${({ $scale }) => ($scale ? $scale : 1)});
`;
const SPaper = styled(Paper)`
  position: relative;
  padding: 16px 18px 14px !important;
  margin: 8px auto -5px;
  z-index: 10;
`;
const Arrow = styled.div`
  position: absolute;
  bottom: -6px;
  left: calc(50% - 57px);
  width: 10px;
  height: 10px;
  border: 1px solid #888;
  border-top: none;
  border-left: none;
  transform: rotate(45deg);
  background: white;
`;
const BubbleWrap3 = styled.div`
  margin: 0 -8px -20px;
`;
const BubbleWrap2 = styled.div`
  margin: 0 auto;
  width: 360px;
`;
const BubbleWrap = styled.div`
  margin: 0 auto 0 0;
  width: 246px;
`;
const SGreyBubble = styled(GreyBubble)`
  margin: 0px auto 0 0;
  background: ${({ theme }) => theme.duo.color.lightGrey}66;
  z-index: 3;
`;

type Props = {};

const Quests: React.FC<Props> = () => {
  const { hintWasSeen, getFlag } = useViewerQuery();
  const { xps } = useViewerXps();
  const petting = useGetState("pettingMemo");
  const gigIndex = useSelector(selectMotivationGig);
  const lastSeenTotalXp = useGetState("lastSeenTotalXp");
  const setLastSeenTotalXp = useSetState("lastSeenTotalXp");
  const prevClaimed = usePreviousDistinct(hintWasSeen(PICKED_FEW_CARDS));
  const seeHint = useSeeHint();
  const showQuestsBackdrop = useGetState("showQuestsBackdrop");
  const wrapRef = React.useRef<HTMLDivElement>(null);
  const [marginScaled, setMarginScaled] = React.useState(12); // 12px is convenient for many phones with 360px width
  const setPresent = useSetState("present");
  const lastQuestXps = useGetState("lastQuestXps");
  const feature = getFlag("abtest_aiQuest");

  useEffectOnce(() => {
    setPresent(false);
  });

  React.useEffect(() => {
    if (petting && !hintWasSeen(FIRSTPET) && hintWasSeen(TUTORIALDONE)) {
      setTimeout(() => seeHint(FIRSTPET), 500);
    }
  }, [petting, hintWasSeen, seeHint]);

  React.useEffect(() => {
    if (xps && lastSeenTotalXp !== xps.total) {
      setLastSeenTotalXp(xps.total);
    }
  }, [lastSeenTotalXp, xps, setLastSeenTotalXp]);

  const scale = GARDENWIDTH > window.innerWidth ? Number((window.innerWidth / GARDENWIDTH).toFixed(2)) : undefined;
  React.useEffect(() => {
    if (scale && wrapRef.current) {
      setMarginScaled(Math.round(wrapRef.current.offsetHeight * (1 - scale)));
    }
  }, [scale]);

  if (!hintWasSeen(TUTORIALDONE) || !xps) return null;

  const QuestComponent = !hintWasSeen(PICKED_FEW_CARDS)
    ? FewMoreCards
    : !hintWasSeen(OWNWORDQUEST) && !hintWasSeen(SHARE_AND_CHROME_QUEST) // SHAREANDCHROMEQUEST for existing users
      ? OwnWord
      : !hintWasSeen(SHARE_AND_CHROME_QUEST)
        ? ShareAndChrome
        : !hintWasSeen(LOSING_XPS_HINT) || !hintWasSeen(STREAK_FREEZE_QUEST)
          ? XpsQuests
          : (xps?.today || 0) < DAILYXPS && !hintWasSeen(FIRSTPET)
            ? DailyXps
            : !hintWasSeen(AI_ASSISTANT_QUEST) &&
                hintWasSeen(STREAK_FREEZE_EARN_QUEST) &&
                lastQuestXps !== xps.total &&
                feature?.value === "on"
              ? AiAssistantQuest
              : null;

  const canPet = (xps.today || 0) >= DAILYXPS && !QuestComponent;

  return (
    <Wrap id={QUESTS_ID} $scale={scale} ref={wrapRef} style={{ marginBottom: scale ? -marginScaled : undefined }}>
      <QuestsBackdrop active={!!showQuestsBackdrop} />

      {QuestComponent ? (
        <Fade in appear timeout={1200}>
          <SPaper elevation={8}>
            <QuestComponent />
            <Arrow />
          </SPaper>
        </Fade>
      ) : (
        <BubbleWrap3>
          <BubbleWrap2>
            <BubbleWrap>
              <SGreyBubble hidden={petting}>
                {hintWasSeen(STREAK_FREEZE_QUEST) && !hintWasSeen(STREAK_FREEZE_EARN_QUEST) ? (
                  <StreakFreezeEarn />
                ) : !canPet ? (
                  <DailyXps inGrey />
                ) : (
                  <Trans>{motivationGigs[!hintWasSeen(FIRSTPET) ? 0 : gigIndex]}</Trans>
                )}
                <GreyArrow />
              </SGreyBubble>
            </BubbleWrap>
          </BubbleWrap2>
        </BubbleWrap3>
      )}
      <Garden
        xps={{
          ...xps,
          total: lastSeenTotalXp !== undefined && lastSeenTotalXp < xps.total ? lastSeenTotalXp : xps.total
        }}
        allowPetting={canPet}
        noDeadFire={!hintWasSeen(PICKED_FEW_CARDS) || prevClaimed === false}
      />
    </Wrap>
  );
};

export default Quests;
