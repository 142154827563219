/**
 * @generated SignedSource<<fd4b25e317f31f734412876d98cf6de0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FlatIconEdit = {
  flatId: string;
  url: string;
};
export type cardCreateMutation$variables = {
  back: string;
  deckId: string;
  deleted?: boolean | null;
  front: string;
  hint?: string | null;
  langBack?: string | null;
  sBackId?: string | null;
  sCardId?: string | null;
  sourceId?: string | null;
  svg?: FlatIconEdit | null;
};
export type cardCreateMutation$data = {
  readonly cardCreate: {
    readonly duplicatedCard: {
      readonly back: string;
      readonly front: string;
      readonly id: string;
    } | null;
  } | null;
};
export type cardCreateMutation = {
  response: cardCreateMutation$data;
  variables: cardCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "back"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deckId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deleted"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "front"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hint"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "langBack"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sBackId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sCardId"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceId"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "svg"
},
v10 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "back",
        "variableName": "back"
      },
      {
        "kind": "Variable",
        "name": "deckId",
        "variableName": "deckId"
      },
      {
        "kind": "Variable",
        "name": "deleted",
        "variableName": "deleted"
      },
      {
        "kind": "Variable",
        "name": "front",
        "variableName": "front"
      },
      {
        "kind": "Variable",
        "name": "hint",
        "variableName": "hint"
      },
      {
        "kind": "Variable",
        "name": "langBack",
        "variableName": "langBack"
      },
      {
        "kind": "Variable",
        "name": "sBackId",
        "variableName": "sBackId"
      },
      {
        "kind": "Variable",
        "name": "sCardId",
        "variableName": "sCardId"
      },
      {
        "kind": "Variable",
        "name": "sourceId",
        "variableName": "sourceId"
      },
      {
        "kind": "Variable",
        "name": "svg",
        "variableName": "svg"
      }
    ],
    "concreteType": "CardReturnType",
    "kind": "LinkedField",
    "name": "cardCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Card",
        "kind": "LinkedField",
        "name": "duplicatedCard",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "front",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "back",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "cardCreateMutation",
    "selections": (v10/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/),
      (v8/*: any*/),
      (v2/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Operation",
    "name": "cardCreateMutation",
    "selections": (v10/*: any*/)
  },
  "params": {
    "cacheID": "9be89b45d93ae9f21d5fd9af463eb939",
    "id": null,
    "metadata": {},
    "name": "cardCreateMutation",
    "operationKind": "mutation",
    "text": "mutation cardCreateMutation(\n  $deckId: ID!\n  $front: String!\n  $back: String!\n  $langBack: String\n  $hint: String\n  $sCardId: ID\n  $sBackId: ID\n  $sourceId: ID\n  $deleted: Boolean\n  $svg: FlatIconEdit\n) {\n  cardCreate(deckId: $deckId, front: $front, back: $back, langBack: $langBack, hint: $hint, sCardId: $sCardId, sBackId: $sBackId, sourceId: $sourceId, deleted: $deleted, svg: $svg) {\n    duplicatedCard {\n      id\n      front\n      back\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9252013387b2df1fd5e8835b8a0f2fa7";

export default node;
