import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router";
import { DECK } from "../../root/main/MainRouter";
import styled, { css } from "styled-components/macro";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";
import { useViewerDecks, useViewerQuery } from "../../queries/viewerQuery";
import { useSetState } from "../ReduxProvider";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { LangLabel, flag } from "../../tools/langs";
import { useTranslation } from "react-i18next";
import { isChromeExtension } from "tools/device";
import { useIsOffline } from "tools/offline";

const NEW = "new";

const SMenuItem = styled(MenuItem)`
  padding-right: 5px;
`;
const ItemIn = styled.div`
  flex-grow: 1;
  line-height: 1.3;
`;
const AddMenuItem = styled(MenuItem)`
  color: ${({ theme }) => theme.duo.color.green};
`;
const Subtitle = styled.div<{ green: boolean }>`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  color: ${({ theme, green }) => (green ? theme.duo.color.green : theme.duo.color.textGrey)};
`;
const SIconButton = styled(IconButton)`
  position: relative;
  top: -2px;
  right: -3px;
  svg {
    font-size: 18px;
    opacity: 0.5;
  }
`;
const SSelect = styled(Select)<{ $noLabel?: boolean }>`
  ${Subtitle}, ${SIconButton} {
    display: none;
  }
  :before,
  :after {
    border-bottom: none !important;
  }
  .MuiSelect-select {
    background: none;
    ${({ $noLabel }) =>
      $noLabel &&
      css`
        padding: 10px 25px 10px 10px !important;
        ${ItemIn} {
          display: none;
        }
      `}
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
const MenuIn = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const Flags = styled.div`
  position: relative;
  width: 37px;
  height: 22px;
  line-height: 1em;
`;
const Bigger = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
`;
const Smaller = styled.div`
  position: absolute;
  top: 8px;
  left: 12px;
  transform: scale(0.8);
`;

type Props = {
  noLabel?: boolean;
};

const HeaderMenu: React.FC<Props> = ({ noLabel }) => {
  const { viewer, noPayments } = useViewerQuery();
  const { decks, deck } = useViewerDecks();
  const navigate = useNavigate();
  const setDeckId = useSetState("deckId");
  const { t } = useTranslation();
  const inPop = isChromeExtension();
  const setTooManyDecks = useSetState("tooManyDecks");
  const isOffline = useIsOffline();

  const handleChange = (e) => {
    if (e.target.value === NEW) {
      const maxDecks = noPayments() ? 3 : 2;
      if (!viewer?.subscription && (decks?.length || 0) >= maxDecks) {
        return setTooManyDecks(true);
      }
      navigate(DECK.url());
    } else {
      setDeckId(e.target.value);
    }
  };

  if (!deck) return <CircularProgress />;
  return (
    <SSelect value={deck.id} onChange={handleChange} $noLabel={noLabel} disabled={isOffline}>
      {decks &&
        decks.map((d) => (
          <SMenuItem key={d.id} value={d.id}>
            <MenuIn>
              <Flags>
                {d.back && <Smaller>{flag(d.back)}</Smaller>}
                <Bigger>{flag(d.front)}</Bigger>
              </Flags>
              <ItemIn>
                <div>
                  <LangLabel lang={d.front} />
                </div>
                <Subtitle green={!!d.stats.unknown}>
                  {d.stats.unknown} {t("To learn")}
                </Subtitle>
              </ItemIn>
              {!inPop ? (
                <SIconButton onClick={() => navigate(DECK.url(d?.id))}>
                  <EditIcon />
                </SIconButton>
              ) : (
                <div>&nbsp; &nbsp;</div>
              )}
            </MenuIn>
          </SMenuItem>
        ))}
      {!inPop && (
        <AddMenuItem value={NEW}>
          <AddIcon /> {t("Create new deck")}
        </AddMenuItem>
      )}
    </SSelect>
  );
};

export default HeaderMenu;
