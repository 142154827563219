import React from "react";
import styled from "styled-components/macro";

const Svg = styled.svg`
  width: 51px;
  height: 51px;
  position: absolute;
  left: 290px;
  top: 54px;
`;

type Props = {
  className?: string;
};

const Hammock: React.FC<Props> = ({ className }) => {
  return (
    <Svg className={className} height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m105 330c-50.761719 0-105-35.464844-105-135 0-72.527344 35.789062-195 105-195 69.019531 0 105 122.03125 105 195 0 99.535156-54.238281 135-105 135zm0 0"
        fill="#b9dd39"
      />
      <path
        d="m407 330c-50.761719 0-105-35.464844-105-135 0-72.527344 35.789062-195 105-195 69.019531 0 105 122.03125 105 195 0 99.535156-54.238281 135-105 135zm0 0"
        fill="#90cc1c"
      />
      <path
        d="m419 324-54 72c-25.359375 33.808594-66.109375 54-109 54s-83.640625-20.191406-109-54l-54-72c.089844-.101562 19.621094-21.761719 19.71875-21.859375 43.519531 26.109375 93.402344 39.160156 143.28125 39.160156s99.761719-13.050781 143.28125-39.160156c.089844.097656 19.628906 21.757813 19.71875 21.859375zm0 0"
        fill="#ff637b"
      />
      <path
        d="m419 324-54 72c-25.359375 33.808594-66.109375 54-109 54v-108.699219c49.878906 0 99.761719-13.050781 143.28125-39.160156.089844.097656 19.628906 21.757813 19.71875 21.859375zm0 0"
        fill="#e63950"
      />
      <path
        d="m158.320312 147.480469-16.640624-24.960938-21.679688 14.453125v-61.972656h-30v121.972656l-21.679688-14.453125-16.640624 24.960938 38.320312 25.546875v263.972656h30v-323.972656zm0 0"
        fill="#725d57"
      />
      <path
        d="m460.320312 207.480469-16.640624-24.960938-21.679688 14.453125v-121.972656h-30v61.972656l-21.679688-14.453125-16.640624 24.960938 38.320312 25.546875v323.972656h30v-263.972656zm0 0"
        fill="#53433f"
      />
      <path d="m0 482h512v30h-512zm0 0" fill="#b9dd39" />
      <path d="m256 482h256v30h-256zm0 0" fill="#b9dd39" />
      <path d="m256 482h256v30h-256zm0 0" fill="#90cc1c" />
    </Svg>
  );
};

export default Hammock;
