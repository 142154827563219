import React from "react";
import styled from "styled-components/macro";
import { IconButton } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkExt from "../../../../components/other/LinkExt";
import { isDesktop } from "../../../../tools/device";
import { Trans } from "react-i18next";
import HintDialog from "../../../../components/hint/HintDialog";

export const SOCIALSHINT = "socialHint";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 20px 30px;
  font-weight: bold;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
`;
const Social = styled(IconButton)`
  a  {
    display: inherit;
    color: white;
  }
  &.fb {
    background: #3b5999;
  }
  &.ig {
    background: #e4405f;
  }
`;

type Props = {};

const SocialsHint: React.FC<Props> = () => {
  return (
    <HintDialog name={SOCIALSHINT} emoji={"❤️"} withClose noOk>
      <Wrap>
        <Trans>Follow us on social media</Trans>:
        <Buttons>
          <Social size="large" className="fb">
            <LinkExt href={isDesktop() ? "https://www.facebook.com/pg/DuoCardsCom" : "fb://page/107180174194369"}>
              <FacebookIcon />
            </LinkExt>
          </Social>
          <Social size="large" className="ig">
            <LinkExt href="https://www.instagram.com/duocardscom">
              <InstagramIcon />
            </LinkExt>
          </Social>
        </Buttons>
      </Wrap>
    </HintDialog>
  );
};

export default SocialsHint;
