import React from "react";
import styled from "styled-components/macro";
import { TranslatedSuggestion } from "./suggestionsDefinitions";
import { Stack } from "@mui/material";

const Suggestion = styled.div`
  text-align: center;
  white-space: nowrap;
`;
const TargetText = styled.div`
  color: ${(p) => p.theme.duo.color.text};
  font-size: ${(p) => p.theme.duo.fontSize.small};
  line-height: 1.6em;
`;
const NativeText = styled.div`
  color: ${(p) => p.theme.duo.color.textGrey};
  font-size: ${(p) => p.theme.duo.fontSize.smaller};
  line-height: 1.6em;
`;
const SuggestionWrap = styled(Stack)`
  padding: 10px 16px;
  border-radius: ${(p) => p.theme.duo.borderRadius.normal};
  border: 1px solid #cdcdcd;
  background: white;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
`;

type Props = {
  onClick: (suggestion: TranslatedSuggestion) => void;
  suggestion: TranslatedSuggestion;
};
const ChatSuggestion: React.FC<Props> = React.memo(({ suggestion, onClick }) => {
  const SuggestionIcon = suggestion.icon;
  return (
    <SuggestionWrap direction="row" spacing={1} onClick={() => onClick(suggestion)}>
      {SuggestionIcon && <SuggestionIcon />}
      <Suggestion>
        <TargetText>{suggestion.targetText}</TargetText>
        <NativeText>{suggestion.nativeText}</NativeText>
      </Suggestion>
    </SuggestionWrap>
  );
});

export default ChatSuggestion;
