import React from "react";
import { RelayEnvironmentProvider } from "relay-hooks";
import { useEnvironment } from "../graphql/environment";

const RelayWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const environment = useEnvironment();

  return <RelayEnvironmentProvider environment={environment}>{children}</RelayEnvironmentProvider>;
};

export default RelayWrapper;
