import React from "react";
import UpSize from "root/main/components/UpSize";
import styled from "styled-components/macro";

const Wrap = styled.div`
  flex-grow: 1;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.3em;
`;
const Title = styled.div`
  font-weight: ${({ theme }) => theme.duo.fontWeight.w700};
  font-size: 14px;
`;
const Text = styled.div`
  margin-top: 2px;
`;
const Hint = styled.div`
  color: ${({ theme }) => theme.duo.color.textGrey};
  margin-top: 2px;
  white-space: pre-line;
`;

type Props = {
  title: React.ReactNode;
  text?: React.ReactNode;
  hint?: React.ReactNode;
  lang?: string;
};

const RowIn: React.FC<Props> = ({ title, text, hint, lang, ...props }) => (
  <Wrap {...props}>
    <Title>
      <UpSize lang={lang} ratio={0.5} weightNormal noLineHeight>
        {title}
      </UpSize>
    </Title>
    <Text>{text}</Text>
    <Hint>
      <UpSize lang={lang} ratio={0.2} noLineHeight>
        {hint}
      </UpSize>
    </Hint>
  </Wrap>
);

export default RowIn;
