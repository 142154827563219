import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";
import DoneIcon from "@mui/icons-material/Done";
import { useSaleExpiration, useSubscribe, useSubscriptions } from "./subscriptionHooks";
import { StoreId } from "./subscriptionIds";
import { useGetState } from "components/ReduxProvider";

const SButton = styled(Button)<{ fullWidth?: boolean }>`
  margin: 20px auto;
  padding: 10px 40px;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  white-space: nowrap;
`;

type Props = {
  storeId?: StoreId;
  bought?: boolean;
  notAvailable?: boolean;
  notInBrowser?: boolean;
  trial?: boolean;
  green?: boolean;
  fullWidth?: boolean;
};

const PriceButton: React.FC<Props> = ({
  storeId: storeIdParam,
  bought,
  notAvailable,
  notInBrowser,
  green,
  fullWidth
}) => {
  const { subscriptions } = useSubscriptions();
  const { storeIdWithSale } = useSaleExpiration();
  const orderOpened = useGetState("orderOpened");

  const storeId = storeIdParam || storeIdWithSale;

  const subscribe = useSubscribe();

  if (bought || notAvailable || notInBrowser) {
    return (
      <SButton
        variant="contained"
        style={{ pointerEvents: "none" }}
        disabled={notAvailable || notInBrowser || bought}
        fullWidth={fullWidth}
      >
        {notInBrowser && <Trans>Subscribe in mobile App</Trans>}
        {notAvailable && <>N / A</>}
        {bought && <DoneIcon />}
      </SButton>
    );
  }

  if (!storeId || !subscriptions) return null;

  return (
    <SButton
      variant="contained"
      color={green ? "secondary" : "primary"}
      onClick={() => subscribe(storeId)}
      fullWidth={fullWidth}
    >
      {orderOpened ? <CircularProgress style={{ color: "white" }} size={24} /> : <Trans>Subscribe</Trans>}
    </SButton>
  );
};

export default PriceButton;
