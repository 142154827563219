import React from "react";
import styled from "styled-components/macro";
import DialogWindow from "../other/DialogWindow";
import { Trans } from "react-i18next";
import Flames from "./Flames";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import TouchAppIcon from "@mui/icons-material/TouchApp";

const Table = styled.table`
  margin: 15px 0 0;
  font-size: 13.5px;
  line-height: 1.35em;
  text-align: left;

  td {
    border-top: 1px solid ${({ theme }) => theme.duo.color.lightGrey};
    padding: 10px 3px;
  }
`;
const SArrowDownwardIcon = styled(ArrowDownwardIcon)`
  position: relative;
  top: -2px;
  margin-top: -5px;
  color: #ccc;
  font-size: 16px;
`;
const Li = styled.div`
  display: flex;
  margin: 3px 0;
  color: ${({ theme }) => theme.duo.color.lightBlue};
`;
const LiDot = styled.div`
  flex-shrink: 0;
  width: 30px;
  text-align: center;
`;
const Grey = styled.div`
  color: ${({ theme }) => theme.duo.color.textGrey};
`;
const Orange = styled.span`
  color: ${({ theme }) => theme.duo.color.flame};
`;

type Props = { open: boolean; onClose: VoidFunction };

const FireDialog: React.FC<Props> = ({ open, onClose }) => {
  return (
    <DialogWindow open={open} onClose={onClose} wide>
      <Trans>
        The <Orange>Flame</Orange> is there to help you, and track your learning progress
      </Trans>
      <Table>
        <tbody>
          <tr>
            <td style={{ textAlign: "center" }}>
              <Flames strength={1} xps={null} />
              <SArrowDownwardIcon />
              <Flames strength={5} xps={1} />
            </td>
            <td>
              <Trans>It grows when adding or learning words,</Trans>
              <Trans parent={Grey}>doesn’t matter to which directions you swipe the cards.</Trans>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>
              <Flames strength={0} xps={null} />
            </td>
            <td>
              <Trans>It falls asleep when you are inactive.</Trans>
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "center" }}>
              <Flames strength={-1} xps={null} coldness={5} />
            </td>
            <td>
              <Trans>The flame freezes to warn you in case of:</Trans>
              <Li>
                <LiDot>
                  <TouchAppIcon />
                </LiDot>
                <div>
                  <Trans>swiping to one direction only</Trans>
                  <Grey>
                    <Trans>and algorithm doesn't see what you learnt</Trans>
                  </Grey>
                </div>
              </Li>
              <Li>
                <LiDot>{"🤯"}</LiDot>
                <div>
                  <Trans>you have too many cards</Trans>
                  <Grey>
                    <Trans>you should start learning them instead of adding new ones</Trans>
                  </Grey>
                </div>
              </Li>
            </td>
          </tr>
        </tbody>
      </Table>
    </DialogWindow>
  );
};

export default FireDialog;
