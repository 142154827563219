import React from "react";
import styled from "styled-components/macro";
import { Trans } from "react-i18next";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { PICKED_FEW_CARDS } from "./xpHooks";
import { Backdrop, Fade } from "@mui/material";
import { Portal } from "@mui/base";
import { devicePaddings, topPadding } from "../../tools/device";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { selectXp, setXpTooManyWarningSeen } from "components/ReduxProvider";
import { useViewerQuery } from "queries/viewerQuery";
import TutorialPointer from "root/tutorial/TutorialPointer";

const { top } = devicePaddings();

const Wrap = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.duo.maxWidth.narrow};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
`;
const Bubble = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  max-width: calc(100% - 20px);
  top: ${top + 53}px;
  right: 8px;
  padding: 8px 14px;
  background: ${({ theme }) => theme.duo.color.lightBlue};
  border-radius: 8px;
  color: white;
  z-index: 760; /* highter than cards in Picker, longTouchHint and Backdrop */
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.4em;
  text-align: right;
  box-shadow: 1px 1px 4px #0003;
`;
const SArrowDropUpIcon = styled(ArrowDropUpIcon)`
  position: absolute;
  right: 7px;
  top: -17px;
  font-size: 30px;
  color: ${({ theme }) => theme.duo.color.lightBlue};
`;
const STouchAppIconRight = styled(TouchAppIcon)`
  /* prettier-ignore */
  @keyframes swipingRight {
    0%, 10% { left: 0;  transform: rotate(-20deg);}
    50%, 100% { left: 10px;  transform: rotate(20deg); }
    10%, 90% { opacity: 1; }
    0%, 100% { opacity: 0.3; }
  }
  position: relative;
  animation: swipingRight 2s ease-in-out infinite;
  margin-right: 10px;
`;
const STouchAppIconLeft = styled(TouchAppIcon)`
  /* prettier-ignore */
  @keyframes swipingLeft {
    0%, 10% { right: -10px;  transform: rotate(20deg);}
    50%, 100% { right: 0px;  transform: rotate(-20deg); }
    10%, 90% { opacity: 1; }
    0%, 100% { opacity: 0.3; }
  }
  position: relative;
  animation: swipingLeft 2s ease-in-out infinite;
  margin-right: 10px;
`;
const SCloseIcon = styled(CloseIcon)`
  float: left;
  font-size: 20px;
  margin-left: -5px;
`;

const WrapTooMany = styled.div`
  p {
    max-width: 330px;
    text-align: center;
    margin: 10px;
    font-size: 0.9rem;
  }
`;
const SBackdrop = styled(Backdrop)`
  z-index: 750;
  top: ${top + 56}px;
`;
const STutorialPointer = styled(TutorialPointer)`
  right: 15px;
  top: ${20 + topPadding}px;
  @media (min-width: ${({ theme }) => theme.duo.maxWidth.narrow}) {
    right: calc(50% - 246px);
  }
  svg {
    transform: scale(0.7);
  }
`;

type Props = { onClick?: VoidFunction };

const FireWarning: React.FC<Props> = ({ onClick }) => {
  const { hintWasSeen } = useViewerQuery();
  const dispatch = useDispatch();
  const xp = useSelector(selectXp);

  return (
    <Portal>
      <Wrap>
        <Fade in={xp.warn === "tooManyLeft"} timeout={1000}>
          <Bubble onClick={onClick}>
            <SArrowDropUpIcon />
            <STouchAppIconRight />
            <Trans>Swipe right if you are able to guess the other side already.</Trans>
          </Bubble>
        </Fade>

        <Fade in={xp.warn === "tooManyRight"} timeout={1000}>
          <Bubble onClick={onClick}>
            <SArrowDropUpIcon />
            <STouchAppIconLeft />
            <Trans>Swipe left if you are not able to guess the other side.</Trans>
          </Bubble>
        </Fade>

        <Fade
          in={
            xp.warn === "tooManyToLearn" &&
            !xp.tooManyWarningSeen &&
            !xp.fireWarningClicked &&
            xp.coldness >= (hintWasSeen(PICKED_FEW_CARDS) ? 4 : 1)
          }
          unmountOnExit={true}
          mountOnEnter={true}
          timeout={1000}
        >
          <Bubble onClick={onClick}>
            <SArrowDropUpIcon />
            <WrapTooMany>
              {hintWasSeen(PICKED_FEW_CARDS) && (
                <SCloseIcon
                  onClick={(e) => {
                    dispatch(setXpTooManyWarningSeen());
                    e.stopPropagation();
                  }}
                />
              )}

              {!hintWasSeen(PICKED_FEW_CARDS) && (
                <>
                  <Portal>
                    <SBackdrop open={true} />
                  </Portal>
                  <STutorialPointer pulsing />
                </>
              )}

              <Trans parent="p">You have enough cards to learn now.</Trans>
              <Trans parent="p">
                The most effective way is to learn new words while there are not too many of them.
              </Trans>
            </WrapTooMany>
          </Bubble>
        </Fade>
      </Wrap>
    </Portal>
  );
};

export default FireWarning;
