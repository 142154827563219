import { differenceInHours } from "date-fns";
import { useViewerDecks, useViewerQuery } from "queries/viewerQuery";
import React from "react";
import { useNavigate } from "react-router";
import { useMatchMain } from "root/RootRouter";
import { NOCARDSHASH } from "../../components/dialogs/NoCardsDialog";
import { useLocation } from "react-use";
import { SHARE_AND_CHROME_QUEST } from "../../sharedJs__generated/constants";
import { useGetState, useSetState } from "../../components/ReduxProvider";
import { MAIN } from "../RootRouter.config";

export const useNoCardsDialogIfNoCards = () => {
  const { deck } = useViewerDecks();
  const { isLoading, hintWasSeen } = useViewerQuery();
  const navigate = useNavigate();

  const noCardsDialogShown = useGetState("noCardsDialogShown");
  const setNoCardsDialogShown = useSetState("noCardsDialogShown");
  const inMain = useMatchMain({ end: true });
  const location = useLocation();

  // disable redirect for 6 hours since the attempt (in case app was running in background)
  const disabled = noCardsDialogShown && differenceInHours(new Date(), new Date(noCardsDialogShown)) < 6;

  const notEnoughCardsInToLearn = deck && deck.stats.unknown < 3;

  React.useEffect(() => {
    if (!hintWasSeen(SHARE_AND_CHROME_QUEST) || (deck && deck.stats.total < 20)) return;

    if (notEnoughCardsInToLearn && inMain && !disabled && !isLoading && location.hash !== NOCARDSHASH) {
      navigate(MAIN.url() + NOCARDSHASH, { replace: true });
      setNoCardsDialogShown(new Date().toString());
    }
  });
};
