import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { deleteSourceMutation } from "./__generated__/deleteSourceMutation.graphql";
import { useNavigate } from "react-router";
import { MYSOURCES } from "../../root/library/library/libraryUrls";

const mutation = graphql`
  mutation deleteSourceMutation($id: ID!) {
    deleteSource(id: $id)
  }
`;

export const useDeleteSourceMutation = () => {
  const navigate = useNavigate();

  const [mutate] = useMutation<deleteSourceMutation>(mutation);

  return (variables: deleteSourceMutation["variables"]) =>
    mutate({
      variables,
      onCompleted: () => navigate(MYSOURCES.url())
    });
};
