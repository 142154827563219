import React from "react";
import styled from "styled-components/macro";

const Svg = styled.svg`
  width: 40px;
  height: 40px;
  position: absolute;
  left: 3px;
  top: -5px;
  transform: scaleY(1.1);
`;

type Props = { className?: string };

const FlameBg: React.FC<Props> = ({ className }) => {
  return (
    <Svg
      enableBackground="new 0 0 621 621"
      height="512"
      viewBox="0 0 621 621"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="m311 526.5c-3 22.5-27.982 32.5-62.5 32.5s-64.5-2.051-64.5-20 31.466-34.99 64.5-45c49.5-15 65.26 11.797 62.5 32.5z"
        fill="#bdbdc0"
      ></path>
      <path
        d="m256.624 491.842c17.338 5.708 23.14 21.427 21.376 34.658-2.649 19.868-22.445 29.984-50.805 32.08 6.728.324 13.91.42 21.305.42 34.518 0 59.5-10 62.5-32.5 2.603-19.525-11.271-44.462-54.376-34.658z"
        fill="#ababad"
      ></path>
      <path
        d="m85 487c-6.598 22.434-14.528 22.597-38.271 22.597s-46.729-1.468-46.729-24.853 20.226-49.087 42.991-42.341c29.009 8.597 47.009 27.597 42.009 44.597z"
        fill="#bdbdc0"
      ></path>
      <path
        d="m42.991 442.403c-8.165-2.42-15.997-.65-22.692 3.674 22.829 9.627 36.078 26.041 31.701 40.923-4.751 16.152-10.193 20.759-21.729 22.072 5.247.437 10.829.524 16.458.524 23.743 0 31.673-.163 38.271-22.597 5-16.999-13-35.999-42.009-44.596z"
        fill="#ababad"
      ></path>
      <path
        d="m494.855 481.065c2.381 18.457-24.943 33.414-55.71 33.696-26.145.239-37.71-3.721-47.145-19.761-10-17 17.537-55.998 47.145-47.63 41.333 11.681 53.913 19.768 55.71 33.695z"
        fill="#bdbdc0"
      ></path>
      <path
        d="m494.855 481.065c-1.797-13.927-14.377-22.014-55.71-33.695-8.366-2.364-16.567-.945-23.802 2.689 34.025 10.297 44.856 18.173 46.512 31.006 2.026 15.702-17.453 28.865-42.256 32.619 5.473.851 11.879 1.146 19.546 1.076 30.767-.28 58.092-15.238 55.71-33.695z"
        fill="#ababad"
      ></path>
      <path
        d="m397.491 515.744c0 23.384-15.509 24.852-39.252 24.852s-46.729-1.468-46.729-24.852 19.248-42.341 42.991-42.341 42.99 18.957 42.99 42.341z"
        fill="#ddddde"
      ></path>
      <path
        d="m354.5 473.403c-5.847 0-11.419 1.154-16.5 3.237 15.553 6.373 26.491 21.479 26.491 39.104 0 17.626-8.814 22.798-23.322 24.278 5.423.483 11.222.574 17.069.574 23.743 0 39.252-1.468 39.252-24.852.001-23.384-19.247-42.341-42.99-42.341z"
        fill="#d3d3d4"
      ></path>
      <path
        d="m198.301 507.065c2.381 18.457-24.942 33.696-55.71 33.696-51.217 0-64.953-15.649-62.898-34.145 2.696-24.261 33.29-41.614 62.898-33.246 41.333 11.681 53.913 19.768 55.71 33.695z"
        fill="#ddddde"
      ></path>
      <path
        d="m198.301 507.065c-1.797-13.927-14.377-22.014-55.71-33.695-10.963-3.098-22.058-2.663-31.825.336 40.383 11.5 52.755 19.567 54.535 33.359 2.034 15.763-17.604 29.17-42.546 32.75 5.886.611 12.46.945 19.836.945 30.768.001 58.092-15.238 55.71-33.695z"
        fill="#d3d3d4"
      ></path>
    </Svg>
  );
};

export default FlameBg;
