import { Button } from "@mui/material";
import { Trans } from "react-i18next";
import { useSubscriptions } from "./subscriptionHooks";

export const ManageSubscriptionButton = () => {
  const { store } = useSubscriptions();

  const handleClick = () => store.manageSubscriptions();

  return (
    <Button color="primary" onClick={handleClick}>
      <Trans>Manage Subscriptions</Trans>
    </Button>
  );
};
