import React from "react";
import styled from "styled-components/macro";
import { HeaderLayout } from "../../components/header/Header";
import { Form, Formik } from "formik";
import { FTextField } from "../../styled/TextField";
import { Button } from "@mui/material";
import DuoCardsTitle from "../../styled/DuoCardsTitle";
import { usePasswordMutation } from "../../queries/users/passwordMutation";
import { Trans, useTranslation } from "react-i18next";
import { useViewerQuery } from "queries/viewerQuery";
import OAuth, { OAuthButtonType } from "components/other/OAuth";
import { canShowOAuth } from "tools/device";
import { useSearchParams } from "react-router-dom";

import { PASSWORD } from "./ProfileRouter.config";

const SHeaderLayout = styled(HeaderLayout)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`;
const SDuoCardsTitle = styled(DuoCardsTitle)`
  margin-bottom: 40px;
  text-align: center;
`;
const WrapOAuth = styled.div`
  text-align: center;
`;
const Description = styled.div`
  margin: 0 0 30px;
  padding-bottom: 30px;
  font-size: 85%;
  text-align: center;
  border-bottom: 0.5px solid ${({ theme }) => theme.duo.color.grey};
`;
const Connections = styled.div`
  margin: 20px 0 40px 0;

  p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;

    img {
      margin-right: 5px;
    }
  }
`;

type Props = {};

const Password: React.FC<Props> = () => {
  const [searchParams] = useSearchParams();
  const hash = searchParams.get(PASSWORD.hashParam);
  const passwordMutation = usePasswordMutation();
  const { t } = useTranslation();
  const { viewer } = useViewerQuery();

  return (
    <SHeaderLayout title={t("Login options")} narrow>
      {viewer && (!viewer.gId || !viewer.appleId) && canShowOAuth() && (
        <>
          <WrapOAuth>
            <OAuth type={OAuthButtonType.CONNECT} hideApple={!!viewer.appleId} hideGoogle={!!viewer.gId} />
          </WrapOAuth>
          {!viewer.appleId && !viewer.gId && (
            <Trans parent={Description}>
              Connect your account with Google or Apple so that you don't have to type your email and password every
              time you log in.
            </Trans>
          )}
        </>
      )}
      {(viewer?.gId || viewer?.appleId) && (
        <Connections>
          {viewer?.gId && (
            <p>
              <img src="https://www.google.com/favicon.ico" alt="google icon" width="26" height="26" />
              <Trans>Your Google account is connected</Trans>
            </p>
          )}
          {viewer?.appleId && (
            <p>
              <img src="https://www.apple.com/favicon.ico" alt="apple icon" width="26" height="26" />
              <Trans>Your Apple account is connected</Trans>
            </p>
          )}
        </Connections>
      )}
      <Formik
        initialValues={{
          newPassword: ""
        }}
        onSubmit={(values) => passwordMutation({ ...values, hash })}
      >
        <Form autoComplete="off">
          {hash && <SDuoCardsTitle />}
          <FTextField name="newPassword" required label={t("New Password")} type="password" autoFocus={!!hash} />
          <Button variant="contained" color="primary" type="submit" fullWidth>
            {t("Update Password")}
          </Button>
        </Form>
      </Formik>
    </SHeaderLayout>
  );
};

export default Password;
