import { smallAlphabets } from "sharedJs__generated/langsList";
import styled, { css } from "styled-components/macro";

const UpSize = styled.span<{
  lang?: string | null;
  weightNormal?: boolean; // `fon-size: normal;` for bigger alphabets
  ratio?: number; // e.g. "ja" will be 140% but with ration 0.5 it is 120%
  noLineHeight?: boolean;
}>`
  ${({ lang, weightNormal, ratio, noLineHeight }) =>
    lang &&
    smallAlphabets.includes(lang) &&
    css`
      font-size: ${100 + (lang === "ar" ? 80 : 50) * (ratio || 1)}%;

      ${!noLineHeight &&
      css`
        line-height: 1.45em;
      `}

      ${weightNormal &&
      css`
        font-weight: normal;
      `}
    `}
`;

export default UpSize;
