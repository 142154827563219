import React from "react";
import styled from "styled-components/macro";
import { isAndroid, isDesktop, isIos, redirectToStore } from "../../tools/device";
import { useLocation } from "react-router";
import ShopIcon from "@mui/icons-material/Shop";
import AppleIcon from "@mui/icons-material/Apple";
import LinkExt from "../../components/other/LinkExt";
import { LoginButton } from "./LoginButton";
import GooglePlayIcon from "icons/GooglePlayIcon";
import AppStoreIcon from "icons/AppStoreIcon";
import { REFERRER_FROM_URL } from "../../components/initializers/ReferrerCounter";
import { MAIN } from "../RootRouter.config";

const Wrap = styled.div`
  margin: 20px 0;
`;

export const useOpenAppOrStore = () => {
  const location = useLocation();
  return () => {
    const path = location.pathname.length > 1 ? location.pathname : MAIN.url;
    (window as any).location.replace("com.duocards.app:/" + path);
    setTimeout(redirectToStore, 100);
  };
};

type Props = { children: React.ReactNode };

const DownloadButton: React.FC<Props> = ({ children }) => {
  const openAppOrStore = useOpenAppOrStore();
  const r = (window as any)[REFERRER_FROM_URL] ? "?referrer=" + (window as any)[REFERRER_FROM_URL] : "";

  if (isDesktop()) {
    return (
      <Wrap>
        {!isIos() && (
          <div>
            <LinkExt href={"https://play.google.com/store/apps/details?id=com.duocards.app" + r}>
              <GooglePlayIcon />
            </LinkExt>
          </div>
        )}
        {!isAndroid() && (
          <div>
            <LinkExt href="https://apps.apple.com/us/app/duocards-learning-decks/id1508159917">
              <AppStoreIcon />
            </LinkExt>
          </div>
        )}
      </Wrap>
    );
  }

  return (
    <LoginButton onClick={openAppOrStore}>
      {isAndroid() && <ShopIcon style={{ fontSize: 20 }} />}
      {isIos() && <AppleIcon style={{ fontSize: 20 }} />}
      &nbsp; {children}
    </LoginButton>
  );
};

export default DownloadButton;
