import { useWindowSize } from "hooks/useWindowSize";
import React from "react";
import { useCounter } from "react-use";
import styled from "styled-components/macro";

export const TUTORIAL_BACKDROP_OFF = "OFF";

const Rect = styled.div`
  position: absolute;
  z-index: 800;
  background: #0125;
  transition: 500ms all;
`;
const Top = styled(Rect)`
  top: 0;
  width: 100%;
`;
const Bottom = styled(Rect)`
  bottom: 0;
  width: 100%;
`;
const Left = styled(Rect)`
  left: 0;
`;
const Right = styled(Rect)`
  right: 0;
`;

type Props = { visibleId?: string };

const TutorialBackdrop: React.FC<Props> = ({ visibleId }) => {
  const { width, height } = useWindowSize();
  const initCoords = { top: height, right: width, bottom: height, left: 0, height: 0, width };
  const [coords, setCoords] = React.useState(initCoords);
  const [counter, { inc }] = useCounter();

  React.useEffect(() => {
    if (visibleId) {
      const el = document.getElementById(visibleId);
      if (el) {
        setCoords(el.getBoundingClientRect());
        el.scrollIntoView({ block: "center", inline: "center" });
        setTimeout(() => coords.top !== el.getBoundingClientRect().top && counter < 50 && inc(), 300);
      } else {
        setTimeout(() => counter < 50 && inc(), 300);
      }
    } else {
      setCoords(initCoords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleId, width, height, counter]);

  if (visibleId === TUTORIAL_BACKDROP_OFF) return null;

  return (
    <>
      <Top style={{ height: coords.top !== height ? `${coords.top - 5}px` : height }} />
      <Bottom style={{ height: height - coords.bottom ? `${height - coords.bottom - 5}px` : `0px` }} />
      <Left
        style={{
          top: coords.top - 5,
          height: coords.height + 10,
          width: coords.left ? coords.left - 5 : 0
        }}
      />
      <Right
        style={{
          top: coords.top - 5,
          height: coords.height + 10,
          width: width - coords.right ? width - coords.right - 5 : 0
        }}
      />
    </>
  );
};

export default TutorialBackdrop;
