import { IconButton } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";
import Row from "../../../../components/other/Row";
import { sourceEditQuery } from "../__generated__/sourceEditQuery.graphql";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const RowCont = styled.div`
  width: calc(100% - 85px);
`;
const SIconButton = styled(IconButton)`
  margin-left: auto;
`;
const SExpandMoreIcon = styled(ExpandMoreIcon)<{ open?: boolean }>`
  color: ${({ theme }) => theme.duo.color.textGrey};
  transform: ${({ open }) => (open ? "none" : "rotate(-90deg)")};
  transition: all 0.3s;
`;
const SIconWrapper = styled.div`
  margin-right: 15px;
  svg {
    width: 24px;
    height: 24px;
  }
`;

type Props = {
  children: React.ReactNode;
  icon: React.ReactNode;
  source?: sourceEditQuery["response"]["node"];
  onClick: VoidFunction;
  isOpen: boolean;
};

const SingleRow: React.FC<Props> = ({ children, icon, onClick, isOpen }) => {
  return (
    <Row onClick={onClick}>
      <SIconWrapper>{icon}</SIconWrapper>
      <RowCont>{children}</RowCont>
      <SIconButton>
        <SExpandMoreIcon open={isOpen} />
      </SIconButton>
    </Row>
  );
};

export default SingleRow;
