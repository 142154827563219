import * as Sentry from "@sentry/browser";

export const sentryCapture = (message: string, data?: { [key: string]: any }) => {
  console.warn("CAPTURED:", message, data);
  Sentry.withScope((scope) => {
    data &&
      Object.keys(data).forEach((key) => {
        scope.setExtra(key, data[key]);
      });
    Sentry.captureMessage(message);
  });
};
