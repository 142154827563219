import { Button } from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import DialogWindow from "../../components/other/DialogWindow";
import Avatar from "./Avatar";
import { Friend } from "./FriendsList";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { FriendDialogMutation } from "./__generated__/FriendDialogMutation.graphql";
import FriendGarden from "./FriendGarden";
import { useViewerFriends, useViewerQuery } from "queries/viewerQuery";
import { FriendDialogFamilyMutation } from "./__generated__/FriendDialogFamilyMutation.graphql";

export const FriendDeleteQL = graphql`
  mutation FriendDialogMutation($friendId: String!) {
    friendDelete(friendId: $friendId) {
      ...viewerQuery_friends
    }
  }
`;
export const FamemberDeleteQl = graphql`
  mutation FriendDialogFamilyMutation($memberId: ID!, $subscriptionId: ID!) {
    famemberDelete(memberId: $memberId, subscriptionId: $subscriptionId) {
      subscriptions {
        famembers {
          id
          name
          picture
          xps {
            total
            today
            currentStreak
          }
        }
      }
    }
  }
`;

const Wrap = styled.div`
  padding: 0 10px;
`;
const Name = styled.div`
  font-weight: bold;
  font-size: 110%;
  margin: 5px 0 0;
`;
const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px 0 0;
`;
const RemoveButton = styled(Button)`
  color: #999;
`;

type Props = {
  onClose: VoidFunction;
  friend?: Friend;
};

const FriendDialog: React.FC<Props> = ({ friend, onClose }) => {
  const { t } = useTranslation();
  const [friendDelete] = useMutation<FriendDialogMutation>(FriendDeleteQL);
  const [familyDelete] = useMutation<FriendDialogFamilyMutation>(FamemberDeleteQl);
  const { friends } = useViewerFriends();
  const { viewer } = useViewerQuery();

  return (
    <DialogWindow open={!!friend} onClose={onClose} wide>
      {friend && (
        <Wrap>
          <Avatar user={friend} />
          <Name>{friend?.name}</Name>
          <FriendGarden xps={friend.xps} />
          <Buttons>
            {friends?.edges?.find((f) => f.friend.id === friend.id) && (
              <RemoveButton
                onClick={() =>
                  friend &&
                  window.confirm(t("Remove friend") + "?") &&
                  friendDelete({
                    variables: { friendId: friend.id },
                    onCompleted: onClose
                  })
                }
              >
                <Trans>Remove friend</Trans>
              </RemoveButton>
            )}

            {viewer?.subscription?.famembers?.find((f) => f.id === friend.id) && (
              <RemoveButton
                onClick={() =>
                  friend &&
                  viewer?.subscription?.transactionId &&
                  window.confirm(t("Remove family member") + "?") &&
                  familyDelete({
                    variables: { memberId: friend.id, subscriptionId: viewer?.subscription?.transactionId },
                    onCompleted: onClose
                  })
                }
              >
                <Trans>Remove family member</Trans>
              </RemoveButton>
            )}

            <Button color="primary" variant="outlined" onClick={onClose}>
              <Trans>Close</Trans>
            </Button>
          </Buttons>
        </Wrap>
      )}
    </DialogWindow>
  );
};

export default FriendDialog;
