import { Button, TextField } from "@mui/material";
import { ChangeEventHandler, FormEventHandler, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import AddIcon from "@mui/icons-material/Add";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { useViewerQuery } from "queries/viewerQuery";
import FriendsList from "../FriendsList";
import { FamembersAddMutation } from "./__generated__/FamembersAddMutation.graphql";
import DoneIcon from "@mui/icons-material/Done";

const mutation = graphql`
  mutation FamembersAddMutation($emailOrName: String!, $transactionId: ID!) {
    addFamember(emailOrName: $emailOrName, transactionId: $transactionId) {
      subscriptions {
        famembers {
          id
          name
          picture
          xps {
            total
            today
            currentStreak
          }
        }
      }
    }
  }
`;

const Form = styled.form`
  position: relative;
  padding: 0 15px;
`;
const SButton = styled(Button)`
  position: absolute;
  right: 20px;
  top: 8px;
`;

const Top = styled.div`
  color: ${({ theme }) => theme.duo.color.green};
`;

const Famembers = () => {
  const { viewer } = useViewerQuery();
  const [emailOrName, setEmailOrName] = useState("");
  const { t } = useTranslation();
  const [addFamember, { loading: addFamemberInProgress }] = useMutation<FamembersAddMutation>(mutation);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmailOrName(e.target.value);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const transactionId = viewer?.subscription?.transactionId as string;
    addFamember({
      variables: { emailOrName, transactionId },
      onCompleted: () => setEmailOrName("")
    });
  };

  return (
    <>
      <Top>
        <Trans parent="h1">Family</Trans>
        <DoneIcon />
      </Top>

      <FriendsList friends={viewer?.subscription?.famembers} />

      {Number(viewer?.subscription?.famembers?.length) < 5 && (
        <Form onSubmit={handleSubmit}>
          <TextField
            label={t("Add a member")}
            value={emailOrName}
            onChange={handleChange}
            placeholder={t("Email or Username")}
            variant="filled"
            color="secondary"
            disabled={addFamemberInProgress}
            fullWidth
          />
          <SButton type="submit" color="secondary" variant="contained" disabled={!emailOrName || addFamemberInProgress}>
            <AddIcon />
          </SButton>
        </Form>
      )}
    </>
  );
};

export default Famembers;
