import { useSetState } from "components/ReduxProvider";
import React from "react";
import { useEvent, useToggle } from "react-use";
import styled, { css, keyframes } from "styled-components/macro";
import { EVENT } from "tools/events";
import MammothHearts from "./MammothHearts";
import MammothIcon from "./MammothIcon";

const BACKFLIP_DURATION = 2000;
const WALK_TICK_DURATION = 100;
const WALK_PIXELS_PER_TICK = 20;

const pettingKF_CW = keyframes`
	0% { top: 0; }
    14% { transform: rotate(20deg); top: -8px; }
    28% { transform: rotate(-10deg); top: -8px; }
    72% { transform: rotate(370deg); top: -8px; }
    86% { transform: rotate(340deg); top: -8px; }
    100% { transform: rotate(360deg); top: 0; }`;
const pettingKF_CCW = keyframes`
    0% { top: 0; }
    14% { transform: rotate(-20deg); top: -8px; }
    28% { transform: rotate(10deg); top: -8px; }
    72% { transform: rotate(-370deg); top: -8px; }
    86% { transform: rotate(-340deg); top: -8px; }
    100% { transform: rotate(-360deg); top: 0; }`;

const In = styled.div<{ backflipping?: boolean; animation: any }>`
  position: relative;
  line-height: 0;
  ${({ backflipping, animation }) =>
    backflipping &&
    css`
      animation: ${animation} ${BACKFLIP_DURATION}ms infinite ease-in-out;
    `}
`;
const Wrap = styled.div<{ duration: number }>`
  position: relative;
  z-index: 10;
  top: 0;
  left: 0;
  display: inline-block;
  background: transparent;
  transition:
    top ${(p) => p.duration}ms linear,
    left ${(p) => p.duration}ms linear;
`;
const SvgWrap = styled.div<{ flipped: boolean }>`
  ${(p) => (p.flipped ? "transform: scaleX(-1);" : "")}
`;
const Sizer = styled.div<{ petting?: boolean }>`
  transition: transform 1000ms ease-in-out;
  transform: scale(${(p) => (p.petting ? 1.4 : 1)});
`;

type Props = {
  forceWalking?: boolean;
  canPet?: boolean;
  inGarden?: boolean;
  className?: string;
};

const Mammoth: React.FC<Props> = React.memo(({ forceWalking, canPet, inGarden, className }) => {
  const [backflipDirection, toggleBackflipDirection] = useToggle(false);
  const [backflipping, toggleBackflipping] = useToggle(false);
  const touching = React.useRef(false);
  const touchCoordinates = React.useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const memoRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const [following, setFollowing] = React.useState(false);
  const [flipped, setFlipped] = React.useState(false);
  const [waiting, setWaiting] = React.useState(false);
  const [walkingHome, setWalkingHome] = React.useState(false);
  const walking = forceWalking || following || walkingHome;
  const setPettingMemo = useSetState("pettingMemo");
  const setLastQuestXps = useSetState("lastQuestXps");

  const finalizePetting = () => {
    setWalkingHome(false);
    setWaiting(false);
    setFlipped(false);
    setPettingMemo(false);
    setLastQuestXps(0);

    if (!memoRef.current) return false;
    memoRef.current.style.left = "0px";
    memoRef.current.style.top = "0px";
  };

  const startBackflip = () => {
    if (backflipping) return;

    toggleBackflipping();
    toggleBackflipDirection();
    setWalkingHome(false);

    handleBackflipEnd();
  };

  const handleBackflipEnd = () => {
    setTimeout(() => {
      if (touching.current && hasContact()) {
        toggleBackflipDirection();
        handleBackflipEnd();
      } else {
        if (hasContact()) {
          touchCoordinates.current.x += 70;
          touchCoordinates.current.y -= 30;
        }
        toggleBackflipping();
        setFollowing(true);
      }
    }, BACKFLIP_DURATION);
  };

  const hasContact = () => {
    if (!memoRef.current) return false;

    const memoRect = (memoRef.current as Element).getBoundingClientRect();

    if (
      touchCoordinates.current.x > memoRect.left &&
      touchCoordinates.current.x < memoRect.right &&
      touchCoordinates.current.y > memoRect.top &&
      touchCoordinates.current.y < memoRect.bottom
    ) {
      return true;
    }

    return false;
  };

  const walkTo = () => {
    if (!memoRef.current) return false;

    if (hasContact()) {
      setFollowing(false);

      if (touching.current) {
        startBackflip();
      } else {
        if (walkingHome) {
          finalizePetting();
        } else {
          setWaiting(true);
        }
      }
      return;
    }

    const memoRect = (memoRef.current as Element).getBoundingClientRect();
    const memoMiddle = {
      x: memoRect.left + memoRect.width / 2,
      y: memoRect.top + memoRect.height / 2
    };
    const prevTop = parseInt(memoRef.current.style.top) || 0;
    const prevLeft = parseInt(memoRef.current.style.left) || 0;
    const distanceX = touchCoordinates.current.x - memoMiddle.x;
    const distanceY = touchCoordinates.current.y - memoMiddle.y;
    const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);
    const ratio = WALK_PIXELS_PER_TICK / distance;
    const walkX = Math.ceil(distanceX * ratio);
    const walkY = Math.ceil(distanceY * ratio);

    memoRef.current.style.left = prevLeft + walkX + "px";
    memoRef.current.style.top = prevTop + walkY + "px";

    if (walkX >= 0) {
      setFlipped(false);
    } else {
      setFlipped(true);
    }
  };

  const walkHome = () => {
    setWaiting(false);
    setWalkingHome(true);

    // set touch coordinates to home so memo follows it
    if (!memoRef.current) return false;
    const memoRect = (memoRef.current as Element).getBoundingClientRect();
    const prevTop = parseInt(memoRef.current.style.top) || 0;
    const prevLeft = parseInt(memoRef.current.style.left) || 0;
    touchCoordinates.current.x = memoRect.left - prevLeft + memoRect.width / 2;
    touchCoordinates.current.y = memoRect.top - prevTop + memoRect.height / 2;
  };

  const checkIfPetting = () => {
    if (canPet && touching.current && !backflipping) {
      if (hasContact()) {
        startBackflip();
      } else {
        setFollowing(true);
      }
    }

    if (walkingHome && touching.current) {
      setWalkingHome(false);
    }
  };

  React.useEffect(() => {
    if (following || walkingHome) {
      const moveInterval = setInterval(walkTo, WALK_TICK_DURATION);

      return () => clearInterval(moveInterval);
    }
  });

  React.useEffect(() => {
    if (waiting) {
      const waitingTimeout = setTimeout(walkHome, 3000);
      const searchingInterval = setInterval(() => setFlipped((flipped) => !flipped), 1050);

      return () => {
        clearTimeout(waitingTimeout);
        clearInterval(searchingInterval);
      };
    }
  }, [waiting]);

  const handleTouchDown = (e) => {
    if (canPet) {
      touchCoordinates.current = { x: e.clientX || e.touches[0].clientX, y: e.clientY || e.touches[0].clientY };
      touching.current = true;
      setWalkingHome(false);
      setWaiting(false);
      setPettingMemo(true);
      checkIfPetting();

      window.addEventListener("mousemove", handleTouchMove);
      window.addEventListener("touchmove", handleTouchMove);
      window.addEventListener("mouseup", handleTouchUp);
      window.addEventListener("touchend", handleTouchUp);
    }
  };
  const handleTouchUp = () => {
    touching.current = false;
    window.removeEventListener("mousemove", handleTouchMove);
    window.removeEventListener("touchmove", handleTouchMove);
    window.removeEventListener("mouseup", handleTouchUp);
    window.removeEventListener("touchend", handleTouchUp);
  };
  const handleTouchMove = (e) => {
    touchCoordinates.current = { x: e.clientX || e.touches[0].clientX, y: e.clientY || e.touches[0].clientY };
    window.getSelection?.()?.empty?.();
    window.getSelection?.()?.removeAllRanges?.();
  };

  React.useEffect(() => {
    if (!touching.current && (backflipping || following || waiting || walkingHome)) {
      window.addEventListener("mousedown", handleTouchDown);
      window.addEventListener("touchstart", handleTouchDown);

      return () => {
        window.removeEventListener("mousedown", handleTouchDown);
        window.removeEventListener("touchstart", handleTouchDown);
      };
    }
  });

  const handleForcePetting = React.useCallback(() => {
    if (!memoRef.current) return;
    const memoRect = (memoRef.current as Element).getBoundingClientRect();
    touchCoordinates.current = { x: memoRect.left + 80, y: memoRect.top - 80 };
    startBackflip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEvent(EVENT.forcePetting, handleForcePetting);

  return (
    <Wrap
      className={(className || "") + (inGarden ? "" : " nofilter")}
      onTouchStart={handleTouchDown}
      onMouseDown={handleTouchDown}
      ref={memoRef}
      duration={WALK_TICK_DURATION}
    >
      <MammothHearts addInterval={backflipping ? 600 : following ? 2000 : 0} />
      <Sizer petting={backflipping || following}>
        <In backflipping={backflipping} animation={backflipDirection ? pettingKF_CW : pettingKF_CCW}>
          <SvgWrap flipped={flipped}>
            <MammothIcon walking={walking} />
          </SvgWrap>
        </In>
      </Sizer>
    </Wrap>
  );
});

export default Mammoth;
