import React from "react";
import styled from "styled-components/macro";
import { Trans } from "react-i18next";
import { TutorialFC } from "root/tutorial/TutorialContent";
import TutorialPointer from "../TutorialPointer";
import { STARTID } from "root/main/main/Main";
import { Button, Fade } from "@mui/material";
import { useSeeHint } from "components/hint/Hint";
import ExitIconButton from "../misc/ExitIconButton";

export const STARTLEARNING = "startLearning";

const STutorialPointer = styled(TutorialPointer)`
  left: 50%;
  top: 3px;
`;
const Strong = styled.strong`
  white-space: nowrap;
`;

export const StartLearning1: TutorialFC = () => {
  const seeHint = useSeeHint();

  return (
    <Fade in appear timeout={1000}>
      <div>
        <p style={{ fontSize: "28px" }}>🚀</p>
        <Trans parent="p">
          Ok, now you have some words in <Strong>your deck</Strong>.
        </Trans>
        <Trans parent="p">Let's start learning them!</Trans>
        <Button variant="contained" color="primary" onClick={() => seeHint(STARTLEARNING)}>
          Ok
        </Button>
      </div>
    </Fade>
  );
};

export const StartLearning2: TutorialFC = () => {
  const [pointerPos, setPointerPos] = React.useState({ left: 0, top: 0 });

  React.useEffect(() => {
    const el = document.getElementById(STARTID);
    if (el) {
      const rect = el.getBoundingClientRect();
      setPointerPos({ left: rect.left + rect.width / 2 + 10, top: rect.top + 20 });
    }
  }, []);

  return (
    <>
      <Trans parent="p">Click on the "START" button to start learning.</Trans>

      <ExitIconButton />

      <STutorialPointer pulsing style={pointerPos} />
    </>
  );
};
