import React from "react";
import { useViewerQuery } from "queries/viewerQuery";
import { MAX_FREE_AI_MESSAGES, MAX_SUBSCRIBER_AI_MESSAGES } from "sharedJs__generated/constants";
import { dispatchEvent, EVENT } from "../../tools/events";
import { AssistantOpenSettings } from "./AssistantPopup";
import { useNavigate } from "react-router-dom";
import { useGetState } from "components/ReduxProvider";
import { PICKED_FEW_CARDS } from "components/fire/xpHooks";

export function useOpenAssistant() {
  return React.useCallback((settings?: AssistantOpenSettings) => {
    dispatchEvent(EVENT.resetAssistant);
    dispatchEvent(EVENT.openAssistant, settings || {});
  }, []);
}

export function useCloseAssistant(): () => void {
  const navigate = useNavigate();
  const { hintWasSeen } = useViewerQuery();
  const pickedFewCards = useGetState("pickedFewCards");

  return React.useCallback(() => {
    const isInFirstQuest = pickedFewCards && !hintWasSeen(PICKED_FEW_CARDS);
    if (!isInFirstQuest) navigate(-1);
    dispatchEvent(EVENT.assistantClosed);
  }, [navigate, hintWasSeen, pickedFewCards]);
}

export function useRemainingAiMessages(): number {
  const { viewer, isSubscriber } = useViewerQuery();
  const maxAiMessages = isSubscriber ? MAX_SUBSCRIBER_AI_MESSAGES : MAX_FREE_AI_MESSAGES;
  const remainingMessages = maxAiMessages - (viewer?.aiMessagesUsedThisWeek || 0);

  // in case a subscription expires, or we change MAX_SUBSCRIBER_AI_MESSAGES or MAX_FREE_AI_MESSAGES
  return remainingMessages < 0 ? 0 : remainingMessages;
}
