/**
 * @generated SignedSource<<5f8fb4c845ce53a94f65979436ac46c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type editUserMutation$variables = {
  appLang?: string | null;
  coachLevel?: number | null;
  deviceId?: string | null;
  deviceOs?: string | null;
  fcmId?: string | null;
  installReferrer?: string | null;
  streakFreeze?: number | null;
  streakFreezeXps?: number | null;
  streakTime?: number | null;
  timezone?: string | null;
  unsetInviter?: boolean | null;
};
export type editUserMutation$data = {
  readonly editUserFields: {
    readonly appLang: string | null;
    readonly coachLevel: number;
    readonly defaultLanguages: {
      readonly native: string | null;
      readonly target: string | null;
    } | null;
    readonly deviceId: string | null;
    readonly deviceOs: string | null;
    readonly fcmId: string | null;
    readonly installReferrer: string | null;
    readonly inviter: {
      readonly deviceId: string | null;
      readonly name: string | null;
    } | null;
    readonly streakFreeze: number;
    readonly streakFreezeXps: number;
    readonly streakTime: number | null;
    readonly timezone: string;
  };
};
export type editUserMutation$rawResponse = {
  readonly editUserFields: {
    readonly appLang: string | null;
    readonly coachLevel: number;
    readonly defaultLanguages: {
      readonly native: string | null;
      readonly target: string | null;
    } | null;
    readonly deviceId: string | null;
    readonly deviceOs: string | null;
    readonly fcmId: string | null;
    readonly id: string;
    readonly installReferrer: string | null;
    readonly inviter: {
      readonly deviceId: string | null;
      readonly id: string;
      readonly name: string | null;
    } | null;
    readonly streakFreeze: number;
    readonly streakFreezeXps: number;
    readonly streakTime: number | null;
    readonly timezone: string;
  };
};
export type editUserMutation = {
  rawResponse: editUserMutation$rawResponse;
  response: editUserMutation$data;
  variables: editUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appLang"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "coachLevel"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceOs"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fcmId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "installReferrer"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "streakFreeze"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "streakFreezeXps"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "streakTime"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timezone"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "unsetInviter"
},
v11 = [
  {
    "kind": "Variable",
    "name": "appLang",
    "variableName": "appLang"
  },
  {
    "kind": "Variable",
    "name": "coachLevel",
    "variableName": "coachLevel"
  },
  {
    "kind": "Variable",
    "name": "deviceId",
    "variableName": "deviceId"
  },
  {
    "kind": "Variable",
    "name": "deviceOs",
    "variableName": "deviceOs"
  },
  {
    "kind": "Variable",
    "name": "fcmId",
    "variableName": "fcmId"
  },
  {
    "kind": "Variable",
    "name": "installReferrer",
    "variableName": "installReferrer"
  },
  {
    "kind": "Variable",
    "name": "streakFreeze",
    "variableName": "streakFreeze"
  },
  {
    "kind": "Variable",
    "name": "streakFreezeXps",
    "variableName": "streakFreezeXps"
  },
  {
    "kind": "Variable",
    "name": "streakTime",
    "variableName": "streakTime"
  },
  {
    "kind": "Variable",
    "name": "timezone",
    "variableName": "timezone"
  },
  {
    "kind": "Variable",
    "name": "unsetInviter",
    "variableName": "unsetInviter"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timezone",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "appLang",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fcmId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coachLevel",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceId",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceOs",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "installReferrer",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streakTime",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streakFreeze",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "streakFreezeXps",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "DefaultLanguages",
  "kind": "LinkedField",
  "name": "defaultLanguages",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "target",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "native",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "editUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "editUserFields",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "inviter",
            "plural": false,
            "selections": [
              (v22/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          (v23/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v9/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v8/*: any*/),
      (v10/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "editUserMutation",
    "selections": [
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "editUserFields",
        "plural": false,
        "selections": [
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "inviter",
            "plural": false,
            "selections": [
              (v22/*: any*/),
              (v16/*: any*/),
              (v24/*: any*/)
            ],
            "storageKey": null
          },
          (v23/*: any*/),
          (v24/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "45b1bac9d873bc8c2cce851c714a8dce",
    "id": null,
    "metadata": {},
    "name": "editUserMutation",
    "operationKind": "mutation",
    "text": "mutation editUserMutation(\n  $timezone: String\n  $appLang: String\n  $fcmId: String\n  $coachLevel: Int\n  $deviceId: String\n  $deviceOs: String\n  $streakTime: Int\n  $unsetInviter: Boolean\n  $installReferrer: String\n  $streakFreeze: Int\n  $streakFreezeXps: Int\n) {\n  editUserFields(timezone: $timezone, appLang: $appLang, fcmId: $fcmId, coachLevel: $coachLevel, deviceId: $deviceId, deviceOs: $deviceOs, streakTime: $streakTime, unsetInviter: $unsetInviter, installReferrer: $installReferrer, streakFreeze: $streakFreeze, streakFreezeXps: $streakFreezeXps) {\n    timezone\n    appLang\n    fcmId\n    coachLevel\n    deviceId\n    deviceOs\n    installReferrer\n    streakTime\n    streakFreeze\n    streakFreezeXps\n    inviter {\n      name\n      deviceId\n      id\n    }\n    defaultLanguages {\n      target\n      native\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9fbd8945c7f0d409e57fe18abade7bbf";

export default node;
