import React from "react";
import styled from "styled-components/macro";
import { sumBy, xor } from "lodash";
import Loader from "components/other/Loader";
import { Trans } from "react-i18next";
import { Button, Checkbox, IconButton, Radio, Stack } from "@mui/material";
import { useViewerQuery, useViewerDecks } from "queries/viewerQuery";
import { useQuery } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { CardsFilterInQuery } from "./__generated__/CardsFilterInQuery.graphql";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router";
import { useSetState, useGetState } from "components/ReduxProvider";
import { LoopMode } from "queries/decks/__generated__/loopQuery.graphql";
import { TLoopFilterIds } from "root/main/learn/CardsFilter";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import SourceView from "root/main/learn/SourceView";
import Toggler from "components/other/Toggler";
import { LEARN } from "../MainRouter";
import { useSearchParams } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { SUBSCRIPTION } from "../../profile/ProfileRouter.config";

const sourcesQL = graphql`
  query CardsFilterInQuery($deckId: ID!, $langNative: String) {
    deckSources(deckId: $deckId) {
      toLearn
      total
      source {
        id
        name(langNative: $langNative)
        rating {
          picked
          dismissed
        }
        counts {
          total
        }
      }
    }
  }
`;

const Wrap = styled.div`
  min-width: 250px;
`;
const Warn = styled.div`
  color: ${(p) => p.theme.duo.color.warning};
  margin-left: 26px;
  font-size: ${(p) => p.theme.duo.fontSize.small};
`;
const Enable = styled.span`
  text-decoration: underline;
  margin-left: 5px;
`;
const Sources = styled(Stack)`
  text-align: left;
  padding-bottom: 5px;
  height: 60vh;
  overflow: auto;

  & > * {
    flex-shrink: 0;
  }

  & > .grow {
    flex-shrink: 1;
    overflow-y: scroll;
  }
`;
const CheckLabel = styled.label`
  display: flex;
  align-items: center;
  .MuiCheckbox-root {
    padding: 6px 3px;
  }
`;
const SLoader = styled(Loader)`
  height: 200px;
  width: 250px;
`;
const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
`;
const Count = styled.div`
  font-size: 85%;
  color: ${({ theme }) => theme.duo.color.green};
  margin: 0 0 0 2px;
`;
const Subcategory = styled.div<{ $disabled?: boolean }>`
  padding-left: 21px;
  ${(p) => p.$disabled && `opacity: 0.5;`}
`;
const Extra = styled.span`
  color: ${(p) => p.theme.duo.color.gold};
`;
const Total = styled.span`
  margin-left: 5px;
  font-size: ${(p) => p.theme.duo.fontSize.small};
`;
const SRadioStack = styled(Stack)`
  cursor: default;
  .MuiRadio-root {
    margin: 6px 3px;
    padding: 0;
  }
`;
const SWarningAmberIcon = styled(WarningAmberIcon)`
  color: ${(p) => p.theme.duo.color.warning};
  font-size: 100%;
  margin-left: 5px;
  cursor: pointer;
`;

type Props = {};
const CardsFilterIn: React.FC<Props> = React.memo(() => {
  const { viewer, noPayments } = useViewerQuery();
  const { deck } = useViewerDecks();
  const loopFilter = useGetState("loopFilter");
  const [selected, setSelected] = React.useState<TLoopFilterIds>(loopFilter?.filteredSIds || []);
  const [includeKnown, setIncludeKnown] = React.useState(loopFilter?.includeKnown || false);
  const [loopMode, setLoopMode] = React.useState<LoopMode>(loopFilter?.loopMode || "native");
  const setLoopFilter = useSetState("loopFilter");
  const setSavedLoopFilter = useSetState("savedLoopFilter");
  const setLoopFilterDeckId = useSetState("loopFilterDeckId");

  const navigate = useNavigate();
  const voiceOff = useGetState("voiceOff");
  const setVoiceOff = useSetState("voiceOff");
  const { data, isLoading } = useQuery<CardsFilterInQuery>(
    sourcesQL,
    { deckId: deck?.id as string, langNative: deck?.back },
    { fetchPolicy: "store-and-network" }
  );
  const [addCardsSourceId, setAddCardsSourceId] = React.useState<string | undefined>(undefined);

  const dSources = data?.deckSources;
  const isSourceSelected = (id: string | undefined): boolean => selected.findIndex((s) => s === (id || null)) >= 0;
  const isSelectedAll = !!selected && !!dSources && selected.length === dSources.length;
  const toLearnCount = sumBy(dSources, (s) => s.toLearn);
  const totalCount = sumBy(dSources, (s) => s.total);
  const selectedCount = sumBy(dSources, (s) => {
    if (!isSourceSelected(s.source?.id)) return 0;
    return includeKnown ? s.total : s.toLearn;
  });

  const [searchParams] = useSearchParams();
  const popupKnown = searchParams.get(LEARN.popupKnownParam);
  useEffectOnce(() => {
    // 800ms to animate the checkbox after it appear and toggles down
    popupKnown && setTimeout(() => setIncludeKnown(true), 800);
  });

  const handleCheck = (value) => {
    setSelected(xor(selected, [value]));
  };
  const handleFilter = () => {
    if (isSelectedAll && !includeKnown) {
      setLoopFilter(undefined);
      setSavedLoopFilter(undefined);
    }

    const loopFilter = {
      filteredSIds: selected,
      includeKnown,
      loopMode,
      filterId: String(Math.floor(Math.random() * 1_000_000))
    };
    setLoopFilter(loopFilter);
    setLoopFilterDeckId(deck?.id);
    setSavedLoopFilter(loopFilter);
  };
  const handleSelectAll = () => {
    if (!dSources?.length) return;

    if (isSelectedAll) {
      setSelected([]);
    } else {
      setSelected(dSources.map((s) => s.source?.id || null));
    }
  };
  const handleSub = (e) => {
    if (!viewer?.subscription && !noPayments()) {
      e.preventDefault();
      navigate(SUBSCRIPTION.url());
      return;
    }
  };
  const handleMode = (mode: LoopMode) => {
    if (includeKnown) {
      setLoopMode(mode);
    }
  };
  const handleAddCardsClick = (e, sourceId: string | undefined) => {
    e.preventDefault();
    setAddCardsSourceId(sourceId);
  };

  if (!isLoading && !dSources?.length) {
    return <Trans parent="p">Add some cards to your deck before using filter.</Trans>;
  }

  return (
    <Wrap onClick={handleSub}>
      {isLoading && <SLoader />}

      {!isLoading && (
        <>
          <Sources>
            <CheckLabel>
              <Checkbox size="small" checked={isSelectedAll} onChange={handleSelectAll} />
              <div>
                <Trans parent="strong">All</Trans>
              </div>
              <Count>
                ({toLearnCount}
                {includeKnown && totalCount - toLearnCount > 0 && <Extra>+{totalCount - toLearnCount}</Extra>})
              </Count>
            </CheckLabel>
            <Subcategory className="grow">
              <Toggler show delay={100}>
                {dSources?.map((src) => (
                  <CheckLabel key={src.source?.id || "NULL"}>
                    <Checkbox
                      size="small"
                      checked={isSourceSelected(src.source?.id)}
                      onChange={() => handleCheck(src.source?.id || null)}
                    />
                    <Name>{src.source?.name || <Trans>added manually</Trans>}</Name>
                    <Count>
                      ({src.toLearn}
                      {includeKnown && src.total - src.toLearn > 0 && <Extra>+{src.total - src.toLearn}</Extra>})
                    </Count>
                    {includeKnown &&
                      src.source?.counts &&
                      src.source?.rating &&
                      src.source.counts.total - src.source.rating.picked - src.source.rating.dismissed > 0 &&
                      isSourceSelected(src.source?.id) && (
                        <SWarningAmberIcon onClick={(e) => handleAddCardsClick(e, src.source?.id)} />
                      )}
                  </CheckLabel>
                ))}
              </Toggler>
            </Subcategory>

            <CheckLabel>
              <Checkbox size="small" checked={includeKnown} onChange={() => setIncludeKnown(!includeKnown)} />
              <Trans parent="div">Include known cards</Trans>
            </CheckLabel>
            <Subcategory $disabled={!includeKnown}>
              <SRadioStack direction="row" alignItems="center" onClick={() => handleMode("native")}>
                <Radio checked={loopMode === "native" && includeKnown} color="primary" size="small" />
                <Trans parent="div">Show native side first</Trans>
              </SRadioStack>
              <SRadioStack direction="row" alignItems="center" onClick={() => handleMode("listening")}>
                <Radio checked={loopMode === "listening" && includeKnown} color="primary" size="small" />
                <Trans parent="div">Listening exercise</Trans>
              </SRadioStack>
              {includeKnown && loopMode === "listening" && voiceOff && (
                <Warn>
                  <Trans>You have disabled sound in settings.</Trans>
                  <Enable onClick={() => setVoiceOff(false)}>
                    <Trans>Enable</Trans>
                  </Enable>
                  ?
                </Warn>
              )}
            </Subcategory>
          </Sources>
          <div>
            <Button variant="contained" color="primary" onClick={handleFilter} disabled={selectedCount === 0}>
              <Trans>Filter</Trans>
              {selectedCount > 0 && <Total>({selectedCount})</Total>}
            </Button>
            {loopFilter && (
              <IconButton
                onClick={() => {
                  setLoopFilter(undefined);
                  setSavedLoopFilter(undefined);
                }}
              >
                <ClearIcon style={{ color: "red" }} />
              </IconButton>
            )}
          </div>
        </>
      )}

      {addCardsSourceId && deck?.back && (
        <SourceView
          sourceId={addCardsSourceId}
          langNative={deck.back}
          onClose={() => setAddCardsSourceId(undefined)}
          title="You can add more cards to your deck from this set:"
          buttonText="Pick cards"
        />
      )}
    </Wrap>
  );
});

export default CardsFilterIn;
