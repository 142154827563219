import React from "react";
import styled from "styled-components/macro";
import { useField } from "formik";
import { Button, Paper } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AttachmentIcon from "@mui/icons-material/Attachment";

const FileLabel = styled.label`
  display: block;
  margin: 0 0 15px;
`;
const SPaper = styled(Paper)`
  white-space: nowrap;
  display: flex;
  padding: 10px 15px;
  font-size: ${({ theme }) => theme.duo.fontSize.small};
`;
const Stretch = styled.div`
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((reader.result as string).split("base64,")[1]);
    reader.onerror = (error) => reject(error);
  });
};
const fileToObj = async (file) => {
  const base64 = (await toBase64(file)) as string;
  return { filename: file.name, type: file.type, content: base64 };
};

type File = {
  filename: string;
  type: string;
  content: string;
};
type Props = {
  onChange?: (file: File | File[] | null) => void;
  label: React.ReactNode;
  name: string;
  value?: File;
  multiple?: boolean;
};

export const FileField: React.FC<Props> = ({ onChange, label, value, multiple }) => {
  const handleFile = async (e) => {
    console.log([...e.target.files]);
    onChange &&
      onChange(
        multiple ? await Promise.all([...e.target.files].map((f) => fileToObj(f))) : await fileToObj(e.target.files[0])
      );
  };

  return (
    <>
      <input style={{ display: "none" }} id="button-file" type="file" multiple={multiple} onChange={handleFile} />
      <FileLabel htmlFor="button-file">
        {value?.filename ? (
          <SPaper>
            <AttachmentIcon /> <Stretch>{value.filename}</Stretch>{" "}
            <ClearIcon
              onClick={(e) => {
                onChange && onChange(null);
                e.stopPropagation();
              }}
            />
          </SPaper>
        ) : (
          <Button color="primary" component="span">
            {label}
          </Button>
        )}
      </FileLabel>
    </>
  );
};

type FProps = Props;

export const FFileField: React.FC<FProps> = (props) => {
  // @ts-ignore
  const [field, meta, form] = useField(props); // eslint-disable-line
  return <FileField {...field} {...props} onChange={form.setValue} />;
};
