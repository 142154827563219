import React from "react";
import styled from "styled-components/macro";
import { HeaderLayout } from "../../components/header/Header";
import { Formik, Form } from "formik";
import { FTextField } from "../../styled/TextField";
import { Button, CircularProgress } from "@mui/material";
import { useViewerQuery } from "../../queries/viewerQuery";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { FeedbackMutation } from "./__generated__/FeedbackMutation.graphql";
import { useNavigate, useLocation } from "react-router";
import { FFileField } from "../../styled/FileField";
import { useTranslation } from "react-i18next";
import { snackbar } from "tools/events";

const FeedbackQL = graphql`
  mutation FeedbackMutation($email: String!, $feedback: String!, $attachment: File, $relatedTo: RelatedTo) {
    feedback(email: $email, feedback: $feedback, attachment: $attachment, relatedTo: $relatedTo)
  }
`;

const SHeaderLayout = styled(HeaderLayout)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
`;
const FeedbackField = styled(FTextField)`
  textarea {
    min-height: 100px;
  }
`;
const RelatedTo = styled.div`
  background: #fffa;
  padding: 5px 12px;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 90%;
  color: #777;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

type Props = {};

const Feedback: React.FC<Props> = () => {
  const [feedbackMutation] = useMutation<FeedbackMutation>(FeedbackQL);
  const { viewer } = useViewerQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const init = {
    email: (viewer?.email as string) || "",
    feedback: "",
    attachment: null
  };
  const relatedTo: { text: string } | undefined = location.state && (location.state as any).relatedTo;

  const handleSubmit = (variables: typeof init) => {
    feedbackMutation({
      variables: { ...variables, relatedTo },
      onCompleted: () => {
        snackbar(t("Thank you for the feedback!"));
        navigate(-1);
      }
    });
  };

  return (
    <SHeaderLayout title={t("Message")} narrow>
      <Formik initialValues={init} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            {relatedTo && <RelatedTo>{relatedTo.text}</RelatedTo>}

            <FTextField name="email" required label="Email" type="email" />

            <FeedbackField name="feedback" required label={t("Message")} autoFocus multiline />

            <FFileField name="attachment" label={t("attach photo")} />

            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={isSubmitting}
              style={{ marginBottom: 20 }}
            >
              {isSubmitting ? <CircularProgress /> : t("Send message")}
            </Button>
          </Form>
        )}
      </Formik>
    </SHeaderLayout>
  );
};

export default Feedback;
