import { MenuItem, Switch } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { Trans } from "react-i18next";
import { silenceTts } from "queries/tts/ttsTools";
import { useGetState, useSetState } from "components/ReduxProvider";

const SSwitch = styled(Switch)`
  margin-left: auto;
`;

type Props = { onClick?: VoidFunction };

const Template: React.FC<Props> = (p) => {
  const voiceOff = useGetState("voiceOff");
  const setVoiceOff = useSetState("voiceOff");

  return (
    <MenuItem
      onClick={() => {
        setVoiceOff(!voiceOff);
        silenceTts();
        p.onClick && p.onClick();
      }}
    >
      <VolumeUpIcon /> &nbsp;<Trans>Sound</Trans>
      <SSwitch size="small" checked={!voiceOff} color="primary" />
    </MenuItem>
  );
};

export default Template;
