import React from "react";
import { useSetFlag } from "queries/users/setFlag";
import { NOTIFICATION_DIALOG_SEEN } from "queries/users/userFlagsModel";
import { useViewerQuery } from "queries/viewerQuery";
import { differenceInDays } from "date-fns";

const NOTIFICATION_MESSAGES = [
  "For effective learning, it's essential to be notified at the right moments",
  "We will send you only important notifications needed for learning",
  "Students that allowed notifications are 70% more likely to achieve their goals",
  "Notifications are important for building necessary learning habits"
];
type FlagValue = {
  count: number;
  variants: number[];
  lastVariant: number;
};

export const useNotificationDialogController = () => {
  const { getFlag } = useViewerQuery();
  const setFlag = useSetFlag();
  const nFlag = getFlag(NOTIFICATION_DIALOG_SEEN);
  const nFlagValue = nFlag?.value ? (JSON.parse(nFlag.value) as FlagValue) : null;
  const nFlagUpdated = new Date(nFlag?.updated || 0);

  /** AB test
   *  - let's test if this dialog even does anything valuable, because so far we had 0% open rate and -2% revenue in last (more intensive ab-test)
   *  - if the results will be similar, we can remove this dialog completely
   */
  const isABTestActive = getFlag("abtest_notificationDialog3")?.value === "on";

  const variant = React.useMemo(() => Math.floor(Math.random() * NOTIFICATION_MESSAGES.length), []);
  const pickedMessage = NOTIFICATION_MESSAGES[variant];

  const incNotificationsDialogSeen = () => {
    const value = {
      count: String((Number(nFlagValue?.count) || 0) + 1),
      variants: [...(nFlagValue?.variants || []), variant],
      lastVariant: variant
    };
    setFlag({ name: NOTIFICATION_DIALOG_SEEN, value: JSON.stringify(value) });
  };

  const firstTime = isABTestActive && !nFlagValue; // Show the dialog to everyone after "continue" in NotificationsStep in tutorial
  const shownCount = Number(nFlagValue?.count || 0);
  const is3daysSinceLast = differenceInDays(new Date(), nFlagUpdated) > 3;
  const secondTime = isABTestActive && shownCount < 2 && is3daysSinceLast;

  const allowOpen = secondTime || firstTime;

  return { incNotificationsDialogSeen, allowOpen, pickedMessage };
};
