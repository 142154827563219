/**
 * @generated SignedSource<<12602bdf00643244ceaaa9747a0fb9af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CardImgPickerQuery$variables = {
  lang: string;
  term: string;
};
export type CardImgPickerQuery$data = {
  readonly flatIcons: ReadonlyArray<{
    readonly id: string;
    readonly url: string;
  }>;
};
export type CardImgPickerQuery = {
  response: CardImgPickerQuery$data;
  variables: CardImgPickerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lang"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "term"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "lang",
        "variableName": "lang"
      },
      {
        "kind": "Variable",
        "name": "term",
        "variableName": "term"
      }
    ],
    "concreteType": "FlatIcon",
    "kind": "LinkedField",
    "name": "flatIcons",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CardImgPickerQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CardImgPickerQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "349c19c8d3015375cbdcdd4231c59e29",
    "id": null,
    "metadata": {},
    "name": "CardImgPickerQuery",
    "operationKind": "query",
    "text": "query CardImgPickerQuery(\n  $term: String!\n  $lang: String!\n) {\n  flatIcons(term: $term, lang: $lang) {\n    id\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "bb23098968f645a9be24d0822f62ad36";

export default node;
