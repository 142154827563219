/**
 * @generated SignedSource<<b48e894ad715d525a2b284c406c2d951>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Kind = "article" | "set" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type loopQuery_loop$data = {
  readonly loop: ReadonlyArray<{
    readonly back: string;
    readonly failCount: number;
    readonly flipped: boolean;
    readonly front: string;
    readonly hint: string | null;
    readonly id: string;
    readonly knownAt: any | null;
    readonly knownCount: number;
    readonly knownUntil: any | null;
    readonly loopedAt: any | null;
    readonly pronunciation: string | null;
    readonly sBackId: string | null;
    readonly sCardId: string | null;
    readonly source: {
      readonly course: boolean;
      readonly kind: Kind;
    } | null;
    readonly sourceId: string | null;
    readonly svg: {
      readonly flatId: string;
      readonly url: string;
    } | null;
  }>;
  readonly " $fragmentType": "loopQuery_loop";
};
export type loopQuery_loop$key = {
  readonly " $data"?: loopQuery_loop$data;
  readonly " $fragmentSpreads": FragmentRefs<"loopQuery_loop">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "loopFilter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "loopQuery_loop",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "loopFilter",
          "variableName": "loopFilter"
        }
      ],
      "concreteType": "Card",
      "kind": "LinkedField",
      "name": "loop",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sCardId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sBackId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sourceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "front",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pronunciation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "back",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hint",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "flipped",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "loopedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "knownAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "knownUntil",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "knownCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "failCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Source",
          "kind": "LinkedField",
          "name": "source",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "kind",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "course",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Upload",
          "kind": "LinkedField",
          "name": "svg",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "flatId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Deck",
  "abstractKey": null
};

(node as any).hash = "2eefe3c08299ed645df8b8fd15619a46";

export default node;
