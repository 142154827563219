import { useDeviceLang } from "./deviceHooks";
import { useGetState } from "../components/ReduxProvider";
import { useViewerDecks } from "queries/viewerQuery";
import { useSearchParams } from "react-router-dom";
import { LANGNATIVEPARAM } from "root/library/library/libraryUrls";

const useLangNative: () => string = () => {
  const { decks } = useViewerDecks();
  const libTopic = useGetState("libTopic");
  const [searchParams] = useSearchParams();
  const { deviceLang } = useDeviceLang();

  return (
    searchParams.get(LANGNATIVEPARAM) ||
    decks?.find((d) => d.front === libTopic)?.back ||
    (libTopic === "en" ? deviceLang : "en")
  );
};

export default useLangNative;
