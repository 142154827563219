import React from "react";
import styled from "styled-components/macro";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Dialog } from "@mui/material";
import { useToggle } from "react-use";
import SCardForm from "./SCardForm";
import CloseIcon from "@mui/icons-material/Close";
import { sourceEditQuery$data } from "../__generated__/sourceEditQuery.graphql";
import CardItem from "../../../../components/other/CardItem";
import { Trans } from "react-i18next";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const Back = styled.span<{ translated: boolean }>`
  color: ${({ theme, translated }) => (translated ? theme.duo.color.textGrey : theme.duo.color.text)};
`;
const SDialog = styled(Dialog)`
  .MuiDialog-paper {
    background: ${({ theme }) => theme.duo.color.lightGrey};
    margin: 0;
    padding: 0 0 10px;
  }
`;
const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Close = styled(IconButton)`
  position: relative;
  top: 4px;
  right: 4px;
`;

type Props = {
  sCard: NonNullable<NonNullable<sourceEditQuery$data["node"]>["sCards"]>[0];
  source: sourceEditQuery$data["node"];
  backLang?: string | null;
  onSort: (id: string) => void;
  onDelete: (id: string) => void;
  className?: string;
};

const SCardItem: React.FC<Props> = ({ sCard, source, backLang, onSort, onDelete, className }) => {
  const [formOpen, formToggle] = useToggle(false);
  const queryParam = new URLSearchParams(window.location.search);
  const noBack = queryParam.get("export") !== null;

  return (
    <>
      <SDialog open={formOpen} onClose={formToggle}>
        <Flex>
          <Close onClick={formToggle}>
            <CloseIcon />
          </Close>
        </Flex>
        <SCardForm source={source} onSubmit={formToggle} sCard={sCard} backLang={backLang} />
      </SDialog>

      <CardItem
        className={className}
        card={{
          ...sCard,
          back: noBack ? undefined : <Back translated={!!sCard.back?.translated}>{sCard.back?.value}</Back>
        }}
        sourceId={source?.id}
        speechLang={source?.lang}
        onClick={formToggle}
        actions={[
          {
            title: (
              <>
                <EditIcon /> <Trans>Edit</Trans>
              </>
            ),
            action: formToggle
          },
          {
            title: (
              <>
                <SwapVertIcon /> <Trans>Sort</Trans>
              </>
            ),
            action: () => onSort(sCard.id)
          },
          {
            title: (
              <>
                <DeleteOutlineIcon /> <Trans>Delete</Trans>
              </>
            ),
            action: () => onDelete(sCard.id)
          }
        ]}
      />
    </>
  );
};

export default SCardItem;
