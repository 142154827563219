import React from "react";
import { AdminUserType } from "./AdminUser";
import { Button, MenuItem, Select } from "@mui/material";
import { graphql } from "relay-runtime";
import { useQuery } from "relay-hooks";
import Loader from "components/other/Loader";
import { uniqBy } from "lodash";
import styled from "styled-components/macro";
import { AdminUserDBLogQuery } from "./__generated__/AdminUserDBLogQuery.graphql";

const ALL = "ALL";

const dblogQL = graphql`
  query AdminUserDBLogQuery($userId: ID!) {
    node(id: $userId) {
      ... on User {
        dblog {
          created
          name
          text
          body
        }
      }
    }
  }
`;

const List = styled.div`
  font-size: 12px;
  margin: 10px 0;
  white-space: nowrap;
  cursor: pointer;
`;
const Wrap = styled.div`
  margin-top: 10px;
`;

type Props = {
  user: AdminUserType;
};
export const AdminUserDBLog: React.FC<Props> = ({ user }) => {
  const [open, setOpen] = React.useState(false);
  const [dblogName, setDblogName] = React.useState(ALL);
  const { data } = useQuery<AdminUserDBLogQuery>(
    dblogQL,
    { userId: user.id },
    { fetchPolicy: "network-only", skip: !open }
  );

  const userDblog = data?.node?.dblog;

  return (
    <Wrap>
      {!open && (
        <Button size="small" onClick={() => setOpen(true)} style={{ padding: 0 }}>
          Show log
        </Button>
      )}

      {open && !userDblog && <Loader />}

      {open && userDblog && (
        <List>
          <Select value={dblogName} onChange={(e) => setDblogName(e.target.value)}>
            <MenuItem value={ALL}>{ALL}</MenuItem>
            {uniqBy(userDblog, "name").map((line, i) => (
              <MenuItem key={i} value={line.name}>
                {line.name}
              </MenuItem>
            ))}
          </Select>

          {userDblog
            .filter((line) => (dblogName !== ALL ? line.name === dblogName : true))
            .map((line, i) => (
              <div
                key={i}
                onClick={() => {
                  console.info(JSON.parse(String(line.body))); // console for better readability
                  alert(line.body);
                }}
              >
                <span style={{ fontSize: "85%", color: "gray" }}>
                  {new Date(Number(line.created)).toLocaleString()}
                  {" - "}
                </span>
                {[line.text, line.body].join(" | ")}
              </div>
            ))}
        </List>
      )}
    </Wrap>
  );
};
