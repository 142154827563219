import React from "react";
import { Dialog, IconButton } from "@mui/material";
import styled from "styled-components/macro";
import { appLangs, langs, topLangs } from "../../sharedJs__generated/langsList";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import LangList from "./LangList";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";
import { useField } from "formik";
import { useTranslation } from "react-i18next";
import { without } from "lodash";
import { useGetLangTranslates } from "hooks/useGetLangTranslates";

const SDialog = styled(Dialog)`
  .MuiPaper-root {
    max-height: 94%;
  }
`;
const Wrap = styled.div`
  padding: 12px;
  height: 100%;
  overflow: auto;
`;
const Head = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.duo.color.grey};
  display: flex;
  align-items: center;
  padding: 4px;
  background: white;
`;
const SearchInput = styled.input`
  border: none;
  width: 100%;
  padding: 5px;
  font-size: ${({ theme }) => theme.duo.fontSize.normal};
`;
const CloseButton = styled(IconButton)`
  opacity: 0.6;
`;
const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 20px;
`;
const Butt = styled.div<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
const SearchButton = styled(IconButton)`
  background: white;
`;
const Error = styled.div`
  color: ${({ theme }) => theme.duo.color.red};
  margin: 4px 26px 0;
  font-weight: bold;
`;

type Props = {
  title?: React.ReactNode;
  onSelect?: (id: string, label: string) => void;
  onClick?: () => boolean;
  onOpen?: (open: boolean) => void;
  className?: string;
  value?: string;
  disabled?: boolean;
  disabledLangs?: string[];
  noButton?: boolean;
  skipLang?: string;
  showOnlySupported?: boolean;
};

const LangMenu: React.FC<Props> = ({
  title,
  onSelect,
  onClick,
  onOpen,
  value,
  disabled,
  disabledLangs,
  skipLang,
  showOnlySupported
}) => {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const buttRef: any = React.useRef(null);
  const { t } = useTranslation();

  const langList = Object.keys(langs);
  const preferredLangs = showOnlySupported ? appLangs : topLangs;
  const topLangList = without(preferredLangs, skipLang || "");

  if (skipLang) {
    disabledLangs = disabledLangs || [];
    disabledLangs.push(skipLang);
  }

  const handleOpen = () => {
    !disabled && setOpen(true);
    onOpen && onOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSearch("");
    onOpen && onOpen(false);
  };
  const handleSearch = (e) => setSearch(e.target.value);
  const handleSelect = (lang: string) => {
    onSelect && onSelect(lang, langs[lang].label);
    handleClose();
  };

  const normalize = (str) =>
    str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

  const { langTranslates } = useGetLangTranslates();

  const searchableLangs = showOnlySupported ? topLangList : langList;
  const displayedLangs = search
    ? searchableLangs.filter((l) =>
        normalize(langs[l].label + " " + langs[l].native + " " + (langTranslates?.[l] || "")).includes(
          normalize(search)
        )
      )
    : topLangList;

  return (
    <>
      <Butt
        disabled={disabled}
        onClick={() => {
          if (!onClick || onClick()) {
            handleOpen();
          }
        }}
        ref={buttRef}
      >
        {title}
      </Butt>

      <SDialog open={open} onClose={handleClose} fullWidth>
        <Head>
          <SearchButton onClick={() => document.getElementById("langSearchInp")?.focus()}>
            <SearchIcon />
          </SearchButton>
          <SearchInput
            type="text"
            id="langSearchInp"
            value={search}
            autoFocus
            placeholder={t("Search")}
            autoComplete="off"
            onChange={handleSearch}
            onKeyPress={(e) => e.key === "Enter" && displayedLangs.length && handleSelect(displayedLangs[0])}
          />
          <IconButton disabled={!search} onClick={() => setSearch("")}>
            <BackspaceOutlinedIcon />
          </IconButton>
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
        </Head>
        <Wrap>
          {!displayedLangs.length ? (
            <Empty>{"🤷"}</Empty>
          ) : (
            <>
              <LangList langList={displayedLangs} onSelect={handleSelect} value={value} disabledLangs={disabledLangs} />
              {!search && !showOnlySupported && (
                <>
                  <hr style={{ margin: "16px 0" }} />
                  <LangList langList={langList} onSelect={handleSelect} value={value} disabledLangs={disabledLangs} />
                </>
              )}
            </>
          )}
        </Wrap>
      </SDialog>
    </>
  );
};

export default LangMenu;

type FProps = Props & { name: string; skipLang?: string; onChange?: Function };

export const FLangMenu: React.FC<FProps> = (props) => {
  // @ts-ignore
  const [field, meta, form] = useField(props); // eslint-disable-line

  const handleSelect = (value) => {
    form.setValue(value);
    props.onChange && props.onChange(value);
  };

  return (
    <>
      {/* @ts-ignore */}
      <LangMenu {...field} {...props} onSelect={handleSelect} />
      {meta.error && <Error>{meta.error}</Error>}
    </>
  );
};
