import React from "react";
import styled, { css } from "styled-components/macro";

const Svg = styled.svg<{ $position?: number }>`
  width: 53px;
  height: 53px;
  position: absolute;
  left: 231px;
  top: 20px;
  z-index: 5;
  ${({ $position }) =>
    $position === 1 &&
    css`
      left: 190px;
      top: 21px;
      width: 48px;
      height: 48px;
      z-index: 4;
    `}
  ${({ $position }) =>
    $position === 2 &&
    css`
      left: 160px;
      top: 8px;
      z-index: 3;
      width: 48px;
      height: 48px;
    `}
`;

type Props = {
  position?: number;
  className?: string;
};

const Trees: React.FC<Props> = ({ position, className }) => {
  return (
    <Svg
      className={className}
      $position={position}
      enable-background="new 0 0 512 512"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m421 346v151c0 8.399-6.599 15-15 15s-15-6.601-15-15v-151c0-8.401 6.599-15 15-15s15 6.599 15 15z"
        fill="#985040"
      ></path>
      <path d="m421 346v151c0 8.399-6.599 15-15 15v-181c8.401 0 15 6.599 15 15z" fill="#713c2e"></path>
      <path
        d="m512 382.899v11.4c0 31.199-26.499 57.7-57.7 57.7h-96.6c-12.9 0-25.501-4.501-35.4-12.301l-4.501-116.8 34.501-104.2c7.799-23.099 29.4-38.698 53.7-38.698s45.901 15.599 53.699 38.699l49.302 146.201c2.098 5.7 2.999 11.699 2.999 17.999z"
        fill="#8bdb52"
      ></path>
      <path
        d="m512 382.899v11.4c0 31.199-26.499 57.7-57.7 57.7h-48.3v-271.999c24.3 0 45.901 15.599 53.699 38.699l49.302 146.201c2.098 5.7 2.999 11.699 2.999 17.999z"
        fill="#5dc379"
      ></path>

      <path
        d="m121 346v151c0 8.399-6.599 15-15 15s-15-6.601-15-15v-151c0-8.401 6.599-15 15-15s15 6.599 15 15z"
        fill="#985040"
      ></path>
      <path d="m121 346v151c0 8.399-6.599 15-15 15v-181c8.401 0 15 6.599 15 15z" fill="#713c2e"></path>

      <path
        d="m194.2 322.899-4.501 116.8c-9.899 7.8-22.5 12.301-35.4 12.301h-96.599c-31.201 0-57.7-26.501-57.7-57.7v-11.4c0-6.301.901-12.299 2.999-17.999l49.301-146.201c7.799-23.101 29.4-38.7 53.7-38.7s45.901 15.599 53.699 38.699z"
        fill="#8bdb52"
      ></path>
      <path
        d="m194.2 322.899-4.501 116.8c-9.899 7.8-22.5 12.301-35.4 12.301h-48.299v-272c24.3 0 45.901 15.599 53.699 38.699z"
        fill="#5dc379"
      ></path>

      <path
        d="m271 346v151c0 8.399-6.599 15-15 15s-15-6.601-15-15v-151c0-8.401 6.599-15 15-15s15 6.599 15 15z"
        fill="#985040"
      ></path>
      <path d="m271 346v151c0 8.399-6.599 15-15 15v-181c8.401 0 15 6.599 15 15z" fill="#713c2e"></path>
      <path
        d="m358.9 366.4-50.1-176.8c-6.899-23.401-28.5-39.6-52.8-39.6s-45.901 16.199-52.8 39.6l-50.1 176.8c-1.5 5.099-2.1 10.199-2.1 15.3v14.399c0 30.301 24.6 55.901 54.901 55.901h100.199c30.3 0 54.9-25.6 54.9-55.901v-14.399c0-5.101-.601-10.201-2.1-15.3z"
        fill="#8bdb52"
      ></path>
      <path
        d="m306.099 452h-50.099v-302c24.3 0 45.901 16.199 52.8 39.6l50.1 176.8c1.5 5.099 2.1 10.199 2.1 15.3v14.399c0 30.301-24.6 55.901-54.901 55.901z"
        fill="#5dc379"
      ></path>
    </Svg>
  );
};

export default Trees;
