import { useQuery } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { sourceCardQuery } from "queries/sources/__generated__/sourceCardQuery.graphql";

const SourceCardQuery = graphql`
  query sourceCardQuery($uriOrId: String!, $langNative: String) {
    sourceByUriOrId(uriOrId: $uriOrId) {
      id
      uri
      name(langNative: $langNative)
      kind
      course
      private
      mine
      difficulty
      source
      videoDuration
      textLength
      top
      avgRating
      image {
        url
        authorName
        authorApiName
      }
      rating {
        rating
        continueFrom
        picked
        dismissed
        finished
      }
      counts {
        total
        theories
      }
    }
  }
`;

export const useSourceCardQuery = (uriOrId: string, langNative: string | undefined) => {
  const { data } = useQuery<sourceCardQuery>(SourceCardQuery, { uriOrId, langNative }, { skip: !uriOrId });

  return data?.sourceByUriOrId;
};
