import { useSetState } from "components/ReduxProvider";
import { useLiveQuery } from "dexie-react-hooks";
import React from "react";
import { dexie } from "./dexie";

type Props = {};

/**
 * We can't use useLiveQuery() in our code directly because every instance of this hook creates new big object,
 * which would ended as an app crash based on depleted memory.
 *
 * So we read IndexDB data from redux and this component is writing the data there.
 */

const IdbToRedux: React.FC<Props> = () => {
  const setIdsCardsData = useSetState("idbCardsData");
  const cardsData = useLiveQuery(() => dexie?.cardsData.toArray() || []);

  React.useEffect(() => {
    if (cardsData !== undefined) {
      setIdsCardsData(cardsData);
    }
  }, [cardsData, setIdsCardsData]);

  return null;
};

export default IdbToRedux;
