import { loopQuery_loop$data } from "queries/decks/__generated__/loopQuery_loop.graphql";
import React, { useRef } from "react";
import { dexie, useDexieError, CardData } from "dexieDB/dexie";
import { useGetState } from "components/ReduxProvider";
import { graphql } from "relay-runtime";
import { useMutation } from "relay-hooks";
import { usePreloadCardsMutation } from "./__generated__/usePreloadCardsMutation.graphql";
import { isEqual } from "lodash";

const ttsQl = graphql`
  mutation usePreloadCardsMutation($cardIds: [String!]!) {
    cardsData(cardIds: $cardIds) {
      front
      tts {
        id
        speech
        voiceId
      }
      grammar {
        id
        lang
        grammar
      }
    }
  }
`;

type ElementOf<T> = T extends readonly (infer U)[] ? U : never;
type LoopElement = ElementOf<loopQuery_loop$data["loop"]>;

export const usePreloadCards = (cards: loopQuery_loop$data["loop"] | undefined, lang?: string) => {
  const cardsData = useGetState("idbCardsData");
  const loading = React.useRef(false);
  const dexieError = useDexieError();
  const [fetchCardsData] = useMutation<usePreloadCardsMutation>(ttsQl);
  const prevCardWords = useRef<string[]>([]);

  const handleTooManyCardsInDexie = async (cardsData: CardData[]) => {
    if (!cardsData || !cards) {
      // no cards mean no need to clean-up
      return;
    }

    const cardsMap: Record<string, LoopElement> = cards.reduce(
      (result, card) => ({ ...result, [card.front]: card }),
      {}
    );
    const otherCachedCards = cardsData.filter((cd) => lang === cd.lang && !cardsMap[cd.text]);

    if (otherCachedCards.length > 200) {
      const deleteIds = otherCachedCards.slice(0, 50).map((cd) => cd.id as number);
      await dexie!.cardsData.bulkDelete(deleteIds).catch(dexieError);
    }
  };

  React.useEffect(() => {
    if (!cards || !lang || loading.current) return;

    const uncachedCards = cards.filter((c) => c && !cardsData.find((cd) => cd.text === c.front && cd.lang === lang));
    const firstUncachedCardIndex = cards.findIndex((cd) => (uncachedCards[0]?.id || 0) === cd.id);

    // only load data to cache if we have at least 3 uncached cards in the loop or if the uncached card is first or second in learning mode (so we need its data asap)
    if (uncachedCards.length >= 3 || [0, 1].includes(firstUncachedCardIndex)) {
      loading.current = true;

      // fetch up to 12 cards in a batch (repeatedly in full offline version)
      const cardIds = uncachedCards.slice(0, 12).map((c) => c.id);

      const cardWords = uncachedCards.slice(0, 12).map((c) => c.front);
      if (isEqual(prevCardWords.current, cardWords)) {
        // this can happen due to race condition that this useEffect is called sooner than data from previous call is is written in redux through IdbToRedux.tsx
        // it's convergent though, it won't end up in a loop, but still we don't need to call it multiple times on BE
        // Petr will solve it in future offline version, where the architecture will be different
        return;
      }
      prevCardWords.current = cardWords;

      fetchCardsData({
        variables: { cardIds },
        onCompleted: async (data) => {
          const newCardsData = data.cardsData.map((card) => ({
            tts: card.tts?.speech,
            text: card.front,
            ttsVoiceId: card.tts?.voiceId || "",
            lang,
            grammar: card.grammar
          }));
          await dexie!.cardsData.bulkAdd(newCardsData).catch(dexieError);
          await handleTooManyCardsInDexie(cardsData);

          loading.current = false;
        }
      });
    }
  });
};
