import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { FLangMenu } from "../../../../components/LangMenu/LangMenu";
import { Select } from "../../../../styled/Select";
import { useGetLangOptions } from "hooks/useGetLangTranslates";

export const MULTILNGOPTION = "MULTILNGOPTION";

const Langs = styled.div`
  display: flex;
  justify-content: space-between;
`;
const LangCol = styled.div`
  width: 49%;
`;
const Red = styled.div`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  color: red;
  line-height: 1.3em;
  position: relative;
  top: -9px;
  text-align: center;
`;

type Props = {
  disabled?: boolean;
  values: { lang: string; langNative: string | null | undefined };
  withBackLang?: boolean;
};

const LangDropdowns: React.FC<Props> = ({ disabled, values, withBackLang }) => {
  const { t } = useTranslation();

  const langOptions = useGetLangOptions();

  return (
    <>
      {withBackLang ? (
        <>
          <Langs>
            <LangCol>
              <FLangMenu
                name="lang"
                disabled={disabled}
                title={
                  <Select
                    label={t("Language")}
                    value={values.lang}
                    options={langOptions}
                    style={{ pointerEvents: "none" }}
                  />
                }
              />
            </LangCol>
            <LangCol>
              <FLangMenu
                name="langNative"
                disabled={disabled}
                title={
                  <Select
                    label={t("Known Language")}
                    value={values.langNative}
                    options={[{ value: MULTILNGOPTION, label: " - multi - " }, ...langOptions]}
                    style={{ pointerEvents: "none" }}
                  />
                }
              />
            </LangCol>
          </Langs>
          {values.langNative === values.lang && <Red>We don't support uni-language sets at the moment</Red>}
        </>
      ) : (
        <FLangMenu
          name="lang"
          disabled={disabled}
          title={
            <Select label={t("Language")} value={values.lang} options={langOptions} style={{ pointerEvents: "none" }} />
          }
        />
      )}
    </>
  );
};

export default LangDropdowns;
