/**
 * @generated SignedSource<<37bd434a9b1d86607a7a94d8698e2de9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InviteAcceptDialogSIMutation$variables = {
  hash: string;
};
export type InviteAcceptDialogSIMutation$data = {
  readonly setInviter: {
    readonly inviter: {
      readonly deviceId: string | null;
      readonly name: string | null;
    } | null;
  };
};
export type InviteAcceptDialogSIMutation = {
  response: InviteAcceptDialogSIMutation$data;
  variables: InviteAcceptDialogSIMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hash"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "hash",
    "variableName": "hash"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteAcceptDialogSIMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "setInviter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "inviter",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteAcceptDialogSIMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "setInviter",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "inviter",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6e2b68602bf2a66daccce6d9b8ccdf3f",
    "id": null,
    "metadata": {},
    "name": "InviteAcceptDialogSIMutation",
    "operationKind": "mutation",
    "text": "mutation InviteAcceptDialogSIMutation(\n  $hash: String!\n) {\n  setInviter(hash: $hash) {\n    inviter {\n      name\n      deviceId\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "11a5f50b5b0d8268129c789ebfd7f5a1";

export default node;
