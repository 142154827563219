import { Button } from "@mui/material";
import { useSeeHint } from "components/hint/Hint";
import React from "react";
import { Trans } from "react-i18next";
import { dispatchEvent, EVENT } from "tools/events";
import { TutorialFC } from "../TutorialContent";

export const PETMAMMOTH = "petMammoth2";

const PetMammoth: TutorialFC = () => {
  const seeHint = useSeeHint();
  const [showSecond, setShowSecond] = React.useState(false);
  const [saidYes, setSaidYes] = React.useState(false);

  React.useEffect(() => {
    if (showSecond === false) setTimeout(() => setShowSecond(true), 1000);
  }, [showSecond]);

  return (
    <>
      <Trans parent="p" style={{ opacity: saidYes ? 0 : 1 }}>
        You are doing great!
      </Trans>
      <div
        style={{
          opacity: showSecond && !saidYes ? 1 : 0,
          pointerEvents: saidYes ? "none" : "all",
          transition: "all 0.7s"
        }}
      >
        <Trans parent="p">Do you want to pet Memo?</Trans>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            dispatchEvent(EVENT.forcePetting);
            setSaidYes(true);
          }}
        >
          <Trans>Yes</Trans>
        </Button>{" "}
        &nbsp;
        <Button variant="outlined" color="primary" onClick={() => seeHint(PETMAMMOTH)}>
          <Trans>No</Trans>
        </Button>
      </div>
    </>
  );
};

export default PetMammoth;
