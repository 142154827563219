import React from "react";
import { Route, Routes } from "react-router-dom";
import Password from "./Password";
import Profile from "./Profile";
import Feedback from "./Feedback";
import UserEdit from "./UserEdit";
import NotificationsScreen from "./NotificationsScreen";
import Affiliate from "root/profile/Affiliate";
import HelpCenter from "root/profile/HelpCenter/HelpCenter";
import SubscriptionScreen from "./subscription/SubscriptionScreen";
import {
  AFFILIATE,
  FEEDBACK,
  HELP_CENTER,
  NOTIFICATIONS,
  PASSWORD,
  SUBSCRIPTION,
  USEREDIT
} from "./ProfileRouter.config";

const ProfileRouter: React.FC<{}> = () => {
  return (
    <Routes>
      <Route path={PASSWORD.relative} element={<Password />} />
      <Route path={FEEDBACK.relative} element={<Feedback />} />
      <Route path={HELP_CENTER.relative} element={<HelpCenter />} />
      <Route path={SUBSCRIPTION.relative} element={<SubscriptionScreen />} />
      <Route path={USEREDIT.relative} element={<UserEdit />} />
      <Route path={NOTIFICATIONS.relative} element={<NotificationsScreen />} />
      <Route path={AFFILIATE.relative} element={<Affiliate />} />
      <Route index element={<Profile />} />
    </Routes>
  );
};

export default ProfileRouter;
