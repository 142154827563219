/**
 * @generated SignedSource<<d68c73390dc6443bdf9761a25d62bf69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HintMutation$variables = {
  name: string;
};
export type HintMutation$data = {
  readonly seeHint: {
    readonly seenHints: ReadonlyArray<{
      readonly name: string;
      readonly when: number;
    }>;
  };
};
export type HintMutation = {
  response: HintMutation$data;
  variables: HintMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Hint",
  "kind": "LinkedField",
  "name": "seenHints",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "when",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HintMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "seeHint",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HintMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "seeHint",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fe7243451ea6048a38e195ca3ffff04d",
    "id": null,
    "metadata": {},
    "name": "HintMutation",
    "operationKind": "mutation",
    "text": "mutation HintMutation(\n  $name: String!\n) {\n  seeHint(name: $name) {\n    seenHints {\n      name\n      when\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "32f53b86cf020250cc4f5ac24e577b63";

export default node;
