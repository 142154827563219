import { usePagination, useQuery } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { Deck } from "queries/viewerQuery";
import { recommendedSourcesOnPage } from "./RecommendedSourcesPaginated";
import { recommendedSourcesPaginatedQuery } from "./__generated__/recommendedSourcesPaginatedQuery.graphql";
import { recommendedSourcesPaginatedQuery_Sources$key } from "./__generated__/recommendedSourcesPaginatedQuery_Sources.graphql";

const pagination = graphql`
  fragment recommendedSourcesPaginatedQuery_Sources on Recommended
  @argumentDefinitions(
    count: { type: "Int!" }
    cursor: { type: "String" }
    sourceId: { type: "String" }
    langNative: { type: "String" }
  )
  @refetchable(queryName: "recommendedSourcesPaginatedRefetchQuery") {
    sources(first: $count, after: $cursor, sourceId: $sourceId, langNative: $langNative)
      @connection(key: "Recommended_sources") {
      edges {
        node {
          id
          uri
          name(langNative: $langNative)
          kind
          course
          private
          mine
          difficulty
          source
          videoDuration
          textLength
          top
          avgRating
          image {
            url
            authorName
            authorApiName
          }
          rating {
            rating
            continueFrom
            picked
            dismissed
            finished
          }
          counts {
            total
            theories
          }
        }
      }
    }
  }
`;

const query = graphql`
  query recommendedSourcesPaginatedQuery($count: Int!, $cursor: String, $sourceId: String!, $langNative: String!) {
    sources: recommended {
      ...recommendedSourcesPaginatedQuery_Sources
        @arguments(count: $count, cursor: $cursor, sourceId: $sourceId, langNative: $langNative)
    }
  }
`;

export const useRecommendedSourcesPaginated = (deck: Deck | undefined, sourceId: string | undefined) => {
  const { data } = useQuery<recommendedSourcesPaginatedQuery>(
    query,
    {
      sourceId: sourceId || "",
      langNative: deck?.back || "",
      count: recommendedSourcesOnPage
    },
    { skip: !deck || !sourceId, fetchPolicy: "store-and-network" }
  );
  return usePagination<recommendedSourcesPaginatedQuery, recommendedSourcesPaginatedQuery_Sources$key>(
    pagination,
    data?.sources as unknown as recommendedSourcesPaginatedQuery_Sources$key
  );
};
