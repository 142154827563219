export const getISODate = () => {
  const date = new Date();
  const dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split("T")[0];
  return dateString; // YYYY-MM-DD
};

export const secToTime = function (value: number) {
  const seconds = value % 60;
  const minutes = (value - seconds) / 60;
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
};

export const timeToSec = function (value: string) {
  const trimmed = value?.trim();
  if (!trimmed) return 0;
  const parts = trimmed.split(":");
  return Number(parts[0]) * 60 + Number(parts[1]) * 1;
};

export const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone?.replace("Europe/Kyiv", "Europe/Kiev");
};
