import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { useViewerDecks } from "../viewerQuery";
import { cardKnowMutation, cardKnowMutation$data } from "./__generated__/cardKnowMutation.graphql";
import { useGetState } from "components/ReduxProvider";
import { RecordSourceSelectorProxy } from "relay-runtime";

const mutation = graphql`
  mutation cardKnowMutation(
    $id: ID!
    $know: Boolean!
    $loopFilter: LoopFilter
    $filter: String
    $noResponse: Boolean
    $isLast: Boolean
  ) {
    cardKnow(id: $id, know: $know, filter: $filter, noResponse: $noResponse, isLast: $isLast) {
      ...loopQuery_loop @arguments(loopFilter: $loopFilter)
    }
  }
`;

export const useCardKnow = () => {
  const { deck } = useViewerDecks();
  const [mutate, { loading }] = useMutation<cardKnowMutation>(mutation);
  const loopFilter = useGetState("loopFilter");

  const cardKnow = (variables: cardKnowMutation["variables"]) => {
    const updater = (store: RecordSourceSelectorProxy<cardKnowMutation$data>) => {
      const { know } = variables;
      const deckRec = store.get(deck?.id || "");
      if (deckRec) {
        let loop = deckRec.getLinkedRecords("loop", { loopFilter });
        if (!loop?.length) return;
        const movedCard = loop?.find((card) => card?.getDataID() === variables.id);
        loop = loop?.filter((card) => card?.getDataID() !== variables.id);
        if (!know && movedCard && loop.length > 0) {
          loop.push(movedCard); // move first element as last
        }
        if (know && Number(deck?.stats.unknown) > 0) {
          const stats = deckRec.getLinkedRecord("stats");
          stats?.setValue(Number(deck?.stats.known) + 1, "known");
          stats?.setValue(Number(deck?.stats.unknown) - 1, "unknown");
        }
        deckRec.setLinkedRecords(loop, "loop", { loopFilter });
      }
    };

    const filter = loopFilter?.includeKnown ? loopFilter.filterId : undefined;
    return mutate({
      variables: { ...variables, ...{ loopFilter }, filter },
      optimisticUpdater: updater,
      updater
    });
  };
  return { cardKnow, loading };
};
