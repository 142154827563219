/**
 * @generated SignedSource<<c96469114d769d209d6ff00af5ff9712>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AffiliateStatsQuery$variables = {};
export type AffiliateStatsQuery$data = {
  readonly viewer: {
    readonly affiliateStats: {
      readonly clicksCount: number;
      readonly commission: number;
      readonly installsCount: number;
      readonly payout: number;
      readonly subscriptionsCount: number;
    } | null;
    readonly payouts: ReadonlyArray<{
      readonly amount: number | null;
      readonly created: any | null;
    }>;
  };
};
export type AffiliateStatsQuery = {
  response: AffiliateStatsQuery$data;
  variables: AffiliateStatsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "AffiliateStats",
  "kind": "LinkedField",
  "name": "affiliateStats",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "clicksCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "installsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commission",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payout",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AffiliateStatsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "payout",
            "kind": "LinkedField",
            "name": "payouts",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AffiliateStatsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "payout",
            "kind": "LinkedField",
            "name": "payouts",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7c83c6bf9d5e6a72d6914d086abfe330",
    "id": null,
    "metadata": {},
    "name": "AffiliateStatsQuery",
    "operationKind": "query",
    "text": "query AffiliateStatsQuery {\n  viewer {\n    affiliateStats {\n      clicksCount\n      installsCount\n      subscriptionsCount\n      commission\n      payout\n    }\n    payouts {\n      amount\n      created\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd75bbc4b13be0409c992ce0e764fecf";

export default node;
