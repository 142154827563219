import * as Sentry from "@sentry/browser";

export function getStoragedJsVersion(): string {
  return window.localStorage.getItem("jsVersion") || ""; // empty can be for browser or historic cordova version
}

export function setStoragedJsVersion(jsVersion: string): void {
  try {
    window.localStorage.setItem("jsVersion", jsVersion);
  } catch (e) {
    Sentry.captureException(e);
    window.localStorage.removeItem("jsVersion");
  }
}
