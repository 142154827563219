import { sentryCapture } from "../sentry/sentry";

type Debug = {
  reason: string;
  [key: string]: any;
};

type WindowReloaderData = {
  reloadTimestamp: number;
  reloadCount: number;
  cooldown: boolean;
};

const RELOADER_STORAGE_ID = "windowReloader";
const MAX_RELOADS = 4; // allow maximum N reloads for MAX_RELOADS_TIME (which is sliding since last reload) (for example it's ok to reload window when connection is restored and then right away again when users gets new releaseId with first request
const MAX_RELOADS_TIME = 30 * 1000;
const COOLDOWN_TIME = 60 * 60 * 1000; // if reload is looping, stop reloading for an hour
const DEFAULT_RELOADER_DATA = { reloadTimestamp: 0, reloadCount: 0, cooldown: false };

export const windowReloadLimited = (onReload?: VoidFunction, debug?: Debug) => {
  const reloaderStorage = window.localStorage.getItem(RELOADER_STORAGE_ID);
  let reloaderData: WindowReloaderData = reloaderStorage ? JSON.parse(reloaderStorage) : DEFAULT_RELOADER_DATA;
  // reset reloader if monitored time window has passed
  if (
    (reloaderData.reloadTimestamp < Date.now() - MAX_RELOADS_TIME && !reloaderData.cooldown) ||
    reloaderData.reloadTimestamp < Date.now() - COOLDOWN_TIME
  ) {
    reloaderData = DEFAULT_RELOADER_DATA;
  }

  // we're not reloading until cooldown has passed
  if (reloaderData.cooldown) {
    return;
  }

  // check if we should cool down
  if (reloaderData.reloadCount === MAX_RELOADS) {
    sentryCapture(`windowReloader looping`, { jsVersion: window.localStorage.getItem("jsVersion"), ...debug });
    reloaderData.cooldown = true;
    window.localStorage.setItem(RELOADER_STORAGE_ID, JSON.stringify(reloaderData));
    return;
  }

  // save data and reload
  reloaderData.reloadTimestamp = Date.now();
  reloaderData.reloadCount++;
  window.localStorage.setItem(RELOADER_STORAGE_ID, JSON.stringify(reloaderData));
  onReload?.();

  windowReload();
};

export const windowReload = () => {
  window.location.href = "/"; // we need to go to root for the app to reload correctly
};
