import React from "react";
import styled from "styled-components/macro";
import Contextor from "root/library/source/contextor/Contextor";
import Speech from "../speech/Speech";
import { ConditonalWrap } from "../../tools/ConditionalWrap";

const Strong = styled.strong`
  border-bottom: 2px dotted ${({ theme }) => theme.duo.color.lightBlue}55;
  position: relative;
  z-index: 1; // put above Speech
`;
const Wrap = styled.span`
  button {
    padding: 6px 15px;
    margin: -3px 0 0 -27px;
  }
`;
const SSpeech = styled(Speech)`
  position: relative;
  left: 9px;
  top: 2px;
  transform: scale(0.75);
`;

const shouldHighlight = (array, index): boolean => {
  const isOddPart = index % 2 === 1; // part to highlight is between separators, so it will be always at odd position
  const isLastPart = array[index + 1] === undefined;

  return isOddPart && !isLastPart;
};

const parseStars = (str: string) => {
  const parts = str.split("*");
  return parts.map((part, i) => (
    <React.Fragment key={i}>{shouldHighlight(parts, i) ? <strong>{part}</strong> : part}</React.Fragment>
  ));
};

type Props = {
  theory: string;
  lang?: string;
  pickedLang?: string;
  bigger?: boolean;
  sourceId?: string | null;
  skipFirstLine?: boolean;
};

const TheoryParser: React.FC<Props> = ({ theory, lang, pickedLang, bigger, sourceId, skipFirstLine }) => {
  if (!lang) return null;

  const paragraphs = theory?.split(new RegExp("\\n\\s*\\n"));
  const allInContextor = lang === pickedLang;

  return (
    <ConditonalWrap
      condition={allInContextor}
      wrap={(children) => <Contextor source={{ lang, id: sourceId }}>{children}</Contextor>}
    >
      <>
        {paragraphs.map((paragraph, j) => (
          <p key={j}>
            {paragraph?.split(new RegExp("\\n")).map((line, i) => {
              if (skipFirstLine && j === 0 && i === 0) return null;

              const lines = line.split('"');
              return (
                <React.Fragment key={i}>
                  {lines.map((str, i) => (
                    <React.Fragment key={i}>
                      {i % 2 === 1 && lines[i + 1] !== undefined ? (
                        <Wrap>
                          <SSpeech lang={lang} text={str} small={!bigger} />
                          <ConditonalWrap
                            condition={!allInContextor}
                            wrap={(children) => (
                              <Contextor style={{ display: "inline" }} source={{ lang, id: sourceId }}>
                                {children}
                              </Contextor>
                            )}
                          >
                            <Strong> {str}</Strong>
                          </ConditonalWrap>
                        </Wrap>
                      ) : (
                        parseStars(str)
                      )}
                    </React.Fragment>
                  ))}
                  <br />
                </React.Fragment>
              );
            })}
          </p>
        ))}
      </>
    </ConditonalWrap>
  );
};

export default TheoryParser;
