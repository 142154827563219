/**
 * @generated SignedSource<<fd3c26c444be45d82ecdb43458715fc2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FeatureTestsSentryQuery$variables = {
  internalUndefined?: boolean | null;
  throwError?: boolean | null;
};
export type FeatureTestsSentryQuery$data = {
  readonly sentryTest: boolean | null;
};
export type FeatureTestsSentryQuery = {
  response: FeatureTestsSentryQuery$data;
  variables: FeatureTestsSentryQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "internalUndefined"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "throwError"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "internalUndefined",
        "variableName": "internalUndefined"
      },
      {
        "kind": "Variable",
        "name": "throwError",
        "variableName": "throwError"
      }
    ],
    "kind": "ScalarField",
    "name": "sentryTest",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeatureTestsSentryQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeatureTestsSentryQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d14fec5901e04ce6a364b11c8b05d7b9",
    "id": null,
    "metadata": {},
    "name": "FeatureTestsSentryQuery",
    "operationKind": "query",
    "text": "query FeatureTestsSentryQuery(\n  $internalUndefined: Boolean\n  $throwError: Boolean\n) {\n  sentryTest(internalUndefined: $internalUndefined, throwError: $throwError)\n}\n"
  }
};
})();

(node as any).hash = "a92ead8e1c21f2c6e2f7559337f8035b";

export default node;
