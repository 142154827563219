import React from "react";
import { useLocation, useMatch, useNavigate } from "react-router";
import { FIVEHINT } from "root/main/main/Main";
import { useLibraryUrl, useMatchMain } from "root/RootRouter";
import { Kind } from "queries/sources/__generated__/sourceQuery.graphql";
import { useViewerDecks, useViewerQuery } from "queries/viewerQuery";
import { FIRSTCARDSCOUNT } from "./Tutorial";
import { FIRSTLOOPDONE } from "root/main/learn/Learn";
import { TUTORIALDONE } from "./steps/Verdict";
import { viewerQuery_decks$data } from "queries/__generated__/viewerQuery_decks.graphql";
import { useSeeHint } from "components/hint/Hint";
import { FIRSTFEWHINT } from "./steps/FirstFewCards";
import { DECK } from "root/main/MainRouter";
import { LIBRARY, MAIN } from "../RootRouter.config";

// if user quit tutorial earlier and try to visit different kind of source will be redirected
export const useRedirectIfTutorialInKind = (sourceKind?: Kind, deck?: viewerQuery_decks$data["decks"][0]) => {
  const { hintWasSeen } = useViewerQuery();
  const navigate = useNavigate();
  const seeHint = useSeeHint();
  React.useEffect(() => {
    if (deck && sourceKind && !hintWasSeen(FIVEHINT) && !hintWasSeen(TUTORIALDONE)) {
      if (deck.stats.total < FIRSTCARDSCOUNT && sourceKind !== "set") {
        navigate(LIBRARY.url(), { replace: true });
      }
      if (deck.stats.total === FIRSTCARDSCOUNT && !(sourceKind === "video" || sourceKind === "article")) {
        navigate(LIBRARY.url(), { replace: true });
        setTimeout(() => seeHint(FIRSTFEWHINT));
      }
    }
  }, [deck, sourceKind, hintWasSeen, navigate, seeHint]);
};

// if user is still in first loop, should be at source at all
export const useRedirectIfTutorialInLoop = () => {
  const { hintWasSeen } = useViewerQuery();
  const navigate = useNavigate();
  const inLibrary = useMatch(LIBRARY.root() + "/*");

  React.useEffect(() => {
    if (inLibrary && !hintWasSeen(TUTORIALDONE) && !hintWasSeen(FIRSTLOOPDONE) && hintWasSeen(FIVEHINT)) {
      navigate(MAIN.url(), { replace: true });
    }
  });
};

export const useDisallowGoingBackFromLibraryInTutorial = () => {
  const [libVisited, setLibVisited] = React.useState(false);
  const inLibrary = useMatch(LIBRARY.root() + "/*");
  const { hintWasSeen } = useViewerQuery();
  const inMain = useMatchMain();
  const { deck } = useViewerDecks();
  const navigate = useNavigate();
  const libraryUrl = useLibraryUrl();

  React.useEffect(() => {
    !libVisited && inLibrary && setLibVisited(true);
    if (
      libVisited &&
      inMain &&
      Number(deck?.stats?.total) < 5 &&
      !hintWasSeen(FIVEHINT) &&
      !hintWasSeen(TUTORIALDONE)
    ) {
      navigate(libraryUrl, { replace: true });
    }
  }, [libVisited, setLibVisited, inLibrary, deck, hintWasSeen, navigate, inMain, libraryUrl]);
};

export const useRedirectToDeckIfNoDecks = () => {
  const { decks } = useViewerDecks();
  const inMain = useMatchMain();
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (decks !== undefined && !decks?.length && inMain && location.pathname !== DECK.url()) {
      navigate(DECK.url(), { state: { noBack: true } });
    }
  }, [decks, navigate, location.pathname, inMain]);
};
