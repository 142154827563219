/**
 * @generated SignedSource<<f9bcab1a5a1ae0970d7aaf6a06159814>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MeAndFriends_friends$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"viewerQuery_friends">;
  readonly " $fragmentType": "MeAndFriends_friends";
};
export type MeAndFriends_friends$key = {
  readonly " $data"?: MeAndFriends_friends$data;
  readonly " $fragmentSpreads": FragmentRefs<"MeAndFriends_friends">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./viewerRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "MeAndFriends_friends",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "viewerQuery_friends"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "b210e0fe416c52269a6d1ee36f835612";

export default node;
