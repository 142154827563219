import React from "react";
import styled from "styled-components/macro";
import { htmlParser } from "tools/htmlParser";
import { useViewerDecks } from "queries/viewerQuery";
import AIGrammarReport from "components/GrammarDictionary/AIGrammarReport";
import { useGetState, useSetState } from "components/ReduxProvider";
import LanguageSwitcher from "components/LangMenu/LanguageSwitcher";
import { Grammar } from "dexieDB/dexie";

const Wrap = styled.div`
  color: #555;
  text-align: left;
  font-size: 13px;
  line-height: 1.6;
  position: relative;
  p {
    margin: 0.8rem 0;
  }
`;
const SLanguageSwitcher = styled(LanguageSwitcher)<{ moveLangTab?: boolean }>`
  float: right;
  position: relative;
  top: -18px;
  right: ${(props) => (props.moveLangTab ? "20px" : "-15px")};
`;

type Props = { moveLangTab: boolean; grammar: readonly Grammar[] };

const AIGrammar: React.FC<Props> = React.memo(({ moveLangTab, grammar }) => {
  const { deck } = useViewerDecks();
  const currentGrammarLang = useGetState("lastGrammarLang") || grammar[0].lang;
  const setCurrentGrammarLang = useSetState("lastGrammarLang");

  if (!deck) return null;

  const currentGrammar = grammar.find((g) => g.lang === currentGrammarLang) || grammar[0];
  const grammarHtml = htmlParser(currentGrammar.grammar, {
    grammar: { grammarLang: currentGrammar.lang, targetLang: deck.front }
  });

  return (
    <Wrap>
      {grammar.length > 1 && (
        <SLanguageSwitcher
          moveLangTab={moveLangTab}
          langCodes={grammar.map((g) => g.lang)}
          activeLang={currentGrammarLang}
          onLanguageSwitch={setCurrentGrammarLang}
        />
      )}

      {grammarHtml}
      <AIGrammarReport grammarForLang={currentGrammar} />
    </Wrap>
  );
});

export default AIGrammar;
