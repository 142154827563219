import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { deleteUserMutation } from "./__generated__/deleteUserMutation.graphql";
import { useTranslation } from "react-i18next";
import { snackbar } from "tools/events";

export const mutation = graphql`
  mutation deleteUserMutation {
    deleteUser
  }
`;

export const useDeleteUserMutation = () => {
  const { t } = useTranslation();

  const [mutate] = useMutation<deleteUserMutation>(mutation, {
    onCompleted: () => {
      snackbar(t("Account was deleted"));
    }
  });

  return () => mutate({ variables: {} });
};
