import { IconButton, Button } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";
import { LabelTextWithLangLabel, flag } from "../../../../tools/langs";
import { sourceEditQuery } from "../__generated__/sourceEditQuery.graphql";
import TranslateIcon from "@mui/icons-material/Translate";
import AddIcon from "@mui/icons-material/Add";
import { usePrevious, useToggle } from "react-use";
import { TextField } from "../../../../styled/TextField";
import { Trans, useTranslation } from "react-i18next";
import { langs } from "../../../../sharedJs__generated/langsList";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { NativeRowMutation } from "./__generated__/NativeRowMutation.graphql";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { NativeRowDelMutation } from "./__generated__/NativeRowDelMutation.graphql";
import SingleRow from "./SingleRow";

export const nativeEditQL = graphql`
  mutation NativeRowMutation($sourceId: ID!, $langNative: String!, $nameNative: String!) {
    nativeEdit(sourceId: $sourceId, nameNative: $nameNative, langNative: $langNative) {
      natives {
        langNative
        nameNative
      }
    }
  }
`;
export const nativeDeleteQL = graphql`
  mutation NativeRowDelMutation($sourceId: ID!, $langNative: String!) {
    nativeDelete(sourceId: $sourceId, langNative: $langNative) {
      natives {
        langNative
        nameNative
      }
    }
  }
`;

const Cont = styled.div`
  position: relative;
  padding: 20px;
  text-align: center;
`;
const Flag = styled.div<{ selected: boolean }>`
  background: ${({ theme, selected }) => (selected ? "white" : "transparent")};
  border: ${({ selected }) => (selected ? 1 : 0.5)}px solid ${({ theme }) => theme.duo.color.primary};
  box-shadow: ${({ selected }) => (selected ? "1px 1px 6px #0003" : "none")};
  opacity: ${({ selected }) => (selected ? "1" : "0.7")};
  border-radius: 50px;
  width: 45px;
  height: 45px;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const Add = styled(IconButton)`
  border: 0.5px solid ${({ theme }) => theme.duo.color.primary};
  width: 45px;
  height: 45px;
  opacity: 0.7;
`;
const DelIcon = styled(IconButton)`
  position: absolute;
  bottom: 16px;
  left: 16px;
  opacity: 0.5;
`;

type Props = {
  source: sourceEditQuery["response"]["node"];
  langNative?: string;
  setLangNative: (l: string) => void;
  nativeCreate: VoidFunction;
};

const NativeRow: React.FC<Props> = ({ source, langNative, setLangNative, nativeCreate }) => {
  const [open, toggleOpen] = useToggle(false);
  const [values, setValues] = React.useState({
    nameNative: ""
  });
  const { t } = useTranslation();
  const prevLangNative = usePrevious(langNative);
  const [nativeEdit] = useMutation<NativeRowMutation>(nativeEditQL);
  const [nativeDelete] = useMutation<NativeRowDelMutation>(nativeDeleteQL);

  React.useEffect(() => {
    if (prevLangNative !== langNative) {
      setValues({
        ...values,
        nameNative: source?.natives?.find((n) => n.langNative === langNative)?.nameNative || ""
      });
    }
  }, [langNative, prevLangNative, setValues, source, values]);

  const handleEdit = () => {
    source &&
      source.id &&
      langNative &&
      nativeEdit({
        variables: { nameNative: values.nameNative, sourceId: source.id, langNative: langNative }
      });
  };
  const handleDelete = () => {
    if (Number(source?.natives?.length) < 2) {
      return alert("You cannot delete the only translation. If you want to delete the set completely, it's elsewhere.");
    }
    langNative &&
      window.confirm(t("Sure to delete this translation?") + " (" + langs[langNative].label + ")") &&
      nativeDelete({
        variables: { sourceId: source?.id as string, langNative: langNative }
      });
  };

  const nameNative = (source?.natives && source.natives.find((n) => n.langNative === langNative)?.nameNative) || "";

  return (
    <>
      <SingleRow icon={<TranslateIcon />} onClick={toggleOpen} isOpen={open}>
        <Trans parent="strong">Translations</Trans>
        <br />
        {source?.multiLang && <>{"🏳️‍🌈"} &nbsp; </>}
        {langNative && (
          <>
            {nameNative} {flag(langNative)}
          </>
        )}
        {source?.natives?.map(
          (native) =>
            native?.langNative !== langNative && <span key={native?.langNative}>| {flag(native?.langNative)}</span>
        )}
      </SingleRow>

      {open && source?.natives && (
        <Cont>
          {source.natives.map((native) => (
            <Flag
              onClick={() => setLangNative(native.langNative)}
              key={native.langNative}
              selected={langNative === native.langNative}
            >
              {flag(native.langNative)}
            </Flag>
          ))}
          {!!source.sCards?.length && !source.multiLang && (
            <Add onClick={nativeCreate} color="primary">
              <AddIcon />
            </Add>
          )}

          {langNative && (
            <>
              <TextField
                label={<LabelTextWithLangLabel label={t("Name")} lang={langNative} />}
                value={values.nameNative}
                onChange={(e) => setValues({ ...values, nameNative: e.target.value })}
              />
              <DelIcon onClick={handleDelete}>
                <DeleteOutlineIcon />
              </DelIcon>
              <Button
                color="primary"
                variant="contained"
                onClick={handleEdit}
                disabled={values.nameNative === nameNative}
              >
                <Trans>Save</Trans>
              </Button>
            </>
          )}
        </Cont>
      )}
    </>
  );
};

export default NativeRow;
