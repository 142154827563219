import React from "react";
import styled from "styled-components/macro";
import { MenuItem } from "@mui/material";
import LangDisplay from "components/LangMenu/LangDisplay";

const Wrap = styled.div``;

type Props = {
  langList: string[];
  onSelect: (value: string) => void;
  value?: string;
  disabledLangs?: (string | null | undefined)[];
  showAutoGenerated?: boolean;
};

const LangList: React.FC<Props> = ({ langList, onSelect, value, disabledLangs, showAutoGenerated }) => {
  return (
    <Wrap>
      {langList.map((lang) => (
        <MenuItem
          key={lang}
          value={lang}
          onClick={() => onSelect(lang)}
          selected={value === lang}
          disabled={!!(disabledLangs && disabledLangs.find((d) => d === lang))}
        >
          <LangDisplay lang={lang} showAutoGenerated={showAutoGenerated} />
        </MenuItem>
      ))}
    </Wrap>
  );
};

export default LangList;
