/**
 * @generated SignedSource<<11a612081eb7dbe21d57ed6cde98a515>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmailAuthForgottenMutation$variables = {
  email: string;
};
export type EmailAuthForgottenMutation$data = {
  readonly forgottenPassword: boolean | null;
};
export type EmailAuthForgottenMutation = {
  response: EmailAuthForgottenMutation$data;
  variables: EmailAuthForgottenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "kind": "ScalarField",
    "name": "forgottenPassword",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailAuthForgottenMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmailAuthForgottenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "73bd10105b697aeb6efa43405de7a650",
    "id": null,
    "metadata": {},
    "name": "EmailAuthForgottenMutation",
    "operationKind": "mutation",
    "text": "mutation EmailAuthForgottenMutation(\n  $email: String!\n) {\n  forgottenPassword(email: $email)\n}\n"
  }
};
})();

(node as any).hash = "3eda385b730a637fb8ac9dfca0e1f210";

export default node;
