/**
 * @generated SignedSource<<ad289014b9b5ab08b1e3d3484ed86de0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type File = {
  content: string;
  filename: string;
  type: string;
};
export type ImportRowMutation$variables = {
  files: ReadonlyArray<File>;
};
export type ImportRowMutation$data = {
  readonly bulkTranslate: string | null;
};
export type ImportRowMutation = {
  response: ImportRowMutation$data;
  variables: ImportRowMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "files"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "files",
        "variableName": "files"
      }
    ],
    "kind": "ScalarField",
    "name": "bulkTranslate",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImportRowMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImportRowMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dc07a422c0b910d9119e6a52c0a0daf9",
    "id": null,
    "metadata": {},
    "name": "ImportRowMutation",
    "operationKind": "mutation",
    "text": "mutation ImportRowMutation(\n  $files: [File!]!\n) {\n  bulkTranslate(files: $files)\n}\n"
  }
};
})();

(node as any).hash = "98161272cab32870081cfc3759496134";

export default node;
