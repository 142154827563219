import styled from "styled-components";
import React from "react";
import Button from "@mui/material/Button";
import { Trans } from "react-i18next";
import { useNotificationsSettings } from "hooks/useNotificationsSettings";

const Wrap = styled.div`
  margin: 5px 0px;
  background-color: ${({ theme }) => theme.duo.color.white};
  text-align: center;
  border: 1px solid ${({ theme }) => theme.duo.color.red};
  border-radius: 5px;
`;
const Content = styled.div`
  margin: 15px;
  font-size: 15px;
  line-height: 1.5em;
`;

const NotificationsPermissionsAlert: React.FC = () => {
  const openNotificationsSettings = useNotificationsSettings();

  return (
    <Wrap>
      <Trans parent={Content}>Enable notifications in your settings to receive updates.</Trans>
      <Button sx={{ margin: "0 0 15px" }} variant="contained" size="small" onClick={openNotificationsSettings}>
        <Trans>Set up notifications</Trans>
      </Button>
    </Wrap>
  );
};
export default NotificationsPermissionsAlert;
