import React from "react";
import styled from "styled-components/macro";
import { Box, Button, Radio, Stack, TextField } from "@mui/material";
import { Trans } from "react-i18next";
import DialogWindow from "components/other/DialogWindow";
import { snackbar } from "tools/events";
import { t } from "i18next";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";
import { AIGrammarReportMutation } from "components/GrammarDictionary/__generated__/AIGrammarReportMutation.graphql";
import { Grammar } from "dexieDB/dexie";
import { useDBLog } from "hooks/useDBLog";

const reportMutation = graphql`
  mutation AIGrammarReportMutation($id: String!) {
    reportGrammar(id: $id)
  }
`;

const Wrap = styled.div`
  margin: 25px 0 -5px;
  color: ${(p) => p.theme.duo.color.textDelete};
  text-align: center;
  font-size: 90%;
  line-height: 1.4em;
  opacity: 0.8;
`;
const Quiet = styled.span`
  font-size: 70%;
  color: ${(p) => p.theme.duo.color.textGrey};
`;
const RadioRow = styled.div`
  cursor: pointer;
`;
const RadioGroup = styled.div`
  margin: 15px 0;
`;
const SDialogWindow = styled(DialogWindow)`
  text-align: left;
`;
const STextField = styled(TextField)`
  margin: 15px 0;
`;
const SInfo = styled.div`
  color: ${(p) => p.theme.duo.color.textGrey};
`;

type Props = { grammarForLang: Grammar };

const AIGrammarReport: React.FC<Props> = ({ grammarForLang }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedVariant, setSelectedVariant] = React.useState(-1);
  const customReason = React.useRef<string>("");
  const [reportGrammarMutate] = useMutation<AIGrammarReportMutation>(reportMutation);
  const dblog = useDBLog();

  const reasons = ["Inaccurate", "Insufficient", "Not understandable", "Other reason"];

  const handleSend = () => {
    setIsOpen(false);
    snackbar(<Trans>Thank you for the feedback!</Trans>);
    reportGrammarMutate({ variables: { id: grammarForLang.id } });

    dblog("grammarReported", "Grammar reported", {
      reason: reasons[selectedVariant],
      customReason: customReason.current,
      grammarLang: grammarForLang.lang,
      grammar: grammarForLang.grammar,
      grammarId: grammarForLang.id
    });
  };

  return (
    <Wrap>
      <SInfo>
        <Trans>This is AI generated grammar. If you see a mistake:</Trans>{" "}
        <a
          href="#/"
          onClick={(e) => {
            setIsOpen(true);
            e.preventDefault();
          }}
        >
          <Trans>Report poor quality</Trans>
        </a>
      </SInfo>

      <SDialogWindow open={isOpen} onClose={() => setIsOpen(false)} wide fullWidth>
        <h2>
          <Trans>Reason</Trans>:{" "}
          <Quiet>
            (<Trans>optional</Trans>)
          </Quiet>
        </h2>

        <RadioGroup>
          {reasons.map((reason, index) => (
            <RadioRow onClick={() => setSelectedVariant(index)} key={index}>
              <Stack direction="row" alignItems="center" justifyContent="middle" spacing={2}>
                <Radio checked={selectedVariant === index} color="primary" />
                <Trans>{reason}</Trans>
              </Stack>
            </RadioRow>
          ))}
        </RadioGroup>

        {selectedVariant === 3 && (
          <STextField
            fullWidth
            onChange={(e) => (customReason.current = e.currentTarget.value)}
            placeholder={t("Want to be more specific?")}
          />
        )}

        <Box textAlign="center">
          <Button color="primary" variant="contained" onClick={handleSend}>
            <Trans>Send</Trans>
          </Button>
        </Box>
      </SDialogWindow>
    </Wrap>
  );
};

export default AIGrammarReport;
