import Flames from "components/fire/Flames";
import React from "react";
import styled from "styled-components/macro";
import { useViewerQuery } from "../../../../queries/viewerQuery";
import { STREAK_FREEZE_XPS } from "../../../../sharedJs__generated/constants";
import IceCube from "../../../../components/fire/IceCube";

const SFlames = styled(Flames)`
  display: inline-block;
  margin: 0px 7px -7px 6px;
`;

type Props = {
  inGrey?: boolean;
};

const StreakFreezeEarn: React.FC<Props> = ({ inGrey }) => {
  const { viewer } = useViewerQuery();

  return (
    <span>
      +<SFlames strength={4} xps={STREAK_FREEZE_XPS - (viewer?.streakFreezeXps ?? 0)} /> = +
      <IceCube coldness={2} number={1} />
    </span>
  );
};

export default StreakFreezeEarn;
