import React from "react";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";
import { sourceQuery$data } from "../../../queries/sources/__generated__/sourceQuery.graphql";
import { useViewerQuery } from "../../../queries/viewerQuery";
import { MenuItem } from "@mui/material";
import { useMutation } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { SourceAdminMenuGenMutation } from "./__generated__/SourceAdminMenuGenMutation.graphql";
import { SourceAdminMenuDelMutation } from "./__generated__/SourceAdminMenuDelMutation.graphql";
import { snackbar } from "tools/events";

const Gen = graphql`
  mutation SourceAdminMenuGenMutation($id: ID!) {
    generateSourceToLangs(id: $id) {
      copySource
    }
  }
`;
const Del = graphql`
  mutation SourceAdminMenuDelMutation($id: ID!) {
    deleteGeneratedSources(id: $id) {
      copySource
    }
  }
`;

type Props = {
  source: sourceQuery$data["sourceByUriOrId"];
  closeMenu: VoidFunction;
};

const SourceAdminMenu: React.FC<Props> = ({ source, closeMenu }) => {
  const { viewer } = useViewerQuery();
  const [generateSourceToLangs] = useMutation<SourceAdminMenuGenMutation>(Gen);
  const [deleteGeneratedSources] = useMutation<SourceAdminMenuDelMutation>(Del);

  const generate = () => {
    if (window.confirm("Sure to generate translates of this set to all languages?")) {
      generateSourceToLangs({
        variables: { id: source?.id as string },
        onCompleted: () => snackbar("Yes")
      });
      setTimeout(() => closeMenu());
    }
  };

  const deleteGenerated = () => {
    if (window.confirm("Sure delete all generated sets from this-one?")) {
      deleteGeneratedSources({
        variables: { id: source?.id as string },
        onCompleted: () => snackbar("Yes")
      });
      setTimeout(() => closeMenu());
    }
  };

  if (!viewer?.admin) return null;

  return (
    <>
      {viewer?.admin >= 2 && !source?.isCopied && (
        <>
          {source?.copySource ? (
            <MenuItem onClick={deleteGenerated}>
              <DeleteIcon /> Delete all generated translates {"🏳️‍🌈"}
            </MenuItem>
          ) : (
            <MenuItem onClick={generate}>
              <ShareIcon /> &nbsp;Generate to all langs
            </MenuItem>
          )}
        </>
      )}
    </>
  );
};

export default SourceAdminMenu;
