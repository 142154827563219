import React from "react";
import styled from "styled-components/macro";
import { HintEmoji } from "../../../components/hint/Hint";
import { Trans } from "react-i18next";
import { HintLearnSwipeIn } from "./LearnArrows";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { useAnimateTexts } from "hooks/useAnimateTexts";

const Green = styled.span`
  color: ${({ theme }) => theme.duo.color.green};
`;
const Red = styled.span`
  color: ${({ theme }) => theme.duo.color.red};
`;
const Grey = styled.span`
  color: ${({ theme }) => theme.duo.color.textGrey};
`;
const Paragraphs = styled.div`
  p {
    margin: 3px 8px 20px;
  }
`;

export const HintLearnClickIn = () => (
  <>
    <HintEmoji>
      {"🤔"}
      <span style={{ position: "relative", top: -16 }}>{"💭"}</span>
    </HintEmoji>
    <Trans>Guess the translation of the word, then tap on the card.</Trans>
  </>
);

const Icons = styled.div`
  margin-top: 20px;
  * {
    font-size: 2.2rem;
  }
`;
const STouchAppIcon = styled(TouchAppIcon)`
  position: relative;
  top: -3px;
`;

export const HintLearnAfterIn: React.FC<{ animated?: boolean }> = ({ animated }) => {
  const [s0, s1, s2, s3, s4] = useAnimateTexts({ disable: !animated });

  return (
    <Paragraphs>
      <Icons style={s0}>
        <Green>
          <STouchAppIcon></STouchAppIcon>
          <ArrowForward></ArrowForward>
        </Green>
      </Icons>
      <Trans parent="p" style={s1}>
        Cards you <Green>swiped right</Green> will appear in your deck few times more in the future (and from both
        sides) until you really learn them.
      </Trans>
      <Icons style={s2}>
        <Red>
          <ArrowBack></ArrowBack>
          <STouchAppIcon></STouchAppIcon>
        </Red>
      </Icons>
      <Trans parent="p" style={s3}>
        And those cards you <Red>swiped left</Red> are moved a few cards below and will be shown more often in the
        future.
      </Trans>
      <br />
      <Trans parent="p" style={s4}>
        Do not cheat (on yourself) and rather swipe left until you are confident enough.
      </Trans>
    </Paragraphs>
  );
};

export const HintDeleteIn: React.FC<{}> = () => (
  <Trans parent="p">
    You can <Grey>swipe down</Grey> to delete a card from your deck completely.
  </Trans>
);

export const LearnHints = () => (
  <>
    <HintLearnClickIn />
    <hr style={{ marginBottom: 14 }} />
    <HintLearnSwipeIn />
    <Paragraphs style={{ marginTop: 10 }}>
      <HintDeleteIn />
    </Paragraphs>
    <hr style={{ marginBottom: 12 }} />
    <HintLearnAfterIn />
  </>
);
