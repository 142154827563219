import { graphql } from "babel-plugin-relay/macro";

export const createSourceQL = graphql`
  mutation createSourceMutation(
    $lang: String!
    $langNative: String
    $multiLang: Boolean
    $name: String
    $nameNative: String
    $text: String
    $source: String
    $duoLang: Boolean
    $kind: Kind!
    $difficulty: Difficulty!
    $private: Boolean
    $imageId: String
    $videoLang: String
    $videoStart: Int
    $videoDuration: Int
  ) {
    createSource(
      lang: $lang
      langNative: $langNative
      multiLang: $multiLang
      name: $name
      nameNative: $nameNative
      text: $text
      source: $source
      duoLang: $duoLang
      kind: $kind
      difficulty: $difficulty
      private: $private
      imageId: $imageId
      videoStart: $videoStart
      videoLang: $videoLang
      videoDuration: $videoDuration
    ) {
      id
      uri
      lang
    }
  }
`;
