import React from "react";
import CardItem from "../../../../components/other/CardItem";
import { cardsQuery_Deck$data } from "../../../../queries/cards/__generated__/cardsQuery_Deck.graphql";
import Battery20 from "@mui/icons-material/Battery20";
import Battery30 from "@mui/icons-material/Battery30";
import Battery50 from "@mui/icons-material/Battery50";
import Battery80 from "@mui/icons-material/Battery80";
import Battery90 from "@mui/icons-material/Battery90";
import BatteryFull from "@mui/icons-material/BatteryFull";
import { useTranslation } from "react-i18next";
import { useViewerDecks } from "../../../../queries/viewerQuery";
import { dispatchEvent, EVENT } from "../../../../tools/events";
import styled from "styled-components/macro";
import { CSSTransition } from "react-transition-group";
import { MAXKNOWNCOUNT } from "../../../../sharedJs__generated/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashXmark, faEdit, faArrowsRotate } from "@fortawesome/pro-regular-svg-icons";

const SCardMenuItem = styled.div<{ isDelete?: boolean }>`
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  justify-items: center;
  gap: 10px;
  color: ${({ isDelete, theme }) => (isDelete ? theme.duo.color.red : "inherit")};
  > svg {
    font-size: 16px;
  }
`;

const batteryIcons = [
  <Battery20 />,
  <Battery20 />,
  <Battery30 />,
  <Battery50 />,
  <Battery80 />,
  <Battery90 />,
  <Battery90 />,
  <BatteryFull />
];

const SCardItem = styled(CardItem)`
  &.animated-exit {
    opacity: 1;
    transform: translateY(0);
  }
  &.animated-exit-active {
    opacity: 0;
    transform: translateY(-150%);
    transition:
      opacity 300ms,
      transform 300ms;
  }
`;

type Props = { cards: cardsQuery_Deck$data["cards"]["edges"]; onDeleteCard; onResetCard; hidden };

const CardListIn: React.FC<Props> = React.memo(({ cards, onDeleteCard, onResetCard, hidden }) => {
  const { deck } = useViewerDecks();
  const { t } = useTranslation();

  return (
    <>
      {cards?.map((edge) => {
        const card = edge?.node && {
          ...edge?.node,
          theory: edge?.node?.sCard?.theory || null
        };

        return (
          card && (
            <CSSTransition
              key={card.id}
              in={card.id !== hidden}
              timeout={500}
              classNames="animated"
              mountOnEnter
              unmountOnExit
              appear
            >
              <SCardItem
                key={card.id}
                card={card}
                sourceId={card.sourceId}
                icon={batteryIcons[Math.min(card.knownCount || 0, 7)]}
                speechLang={deck?.front}
                onClick={() =>
                  card && dispatchEvent(EVENT.openCardEditDialog, { ...card, theory: card.theory?.theory })
                }
                mainScreenDesign
                iconColor={
                  !card.waiting && Number(card.knownCount) < MAXKNOWNCOUNT
                    ? "green"
                    : Number(card.knownCount) >= 5
                      ? "gold"
                      : "primary"
                }
                actions={[
                  {
                    title: (
                      <SCardMenuItem>
                        <FontAwesomeIcon icon={faEdit} />
                        {t("Edit")}
                      </SCardMenuItem>
                    ),
                    action: () =>
                      card && dispatchEvent(EVENT.openCardEditDialog, { ...card, theory: card.theory?.theory })
                  },
                  {
                    title: (
                      <SCardMenuItem>
                        <FontAwesomeIcon icon={faArrowsRotate} />
                        {t("Reset")}
                      </SCardMenuItem>
                    ),
                    action: () => card && onResetCard(card)
                  },
                  {
                    title: (
                      <SCardMenuItem isDelete>
                        <FontAwesomeIcon icon={faTrashXmark} />
                        {t("Delete")}
                      </SCardMenuItem>
                    ),
                    action: () => onDeleteCard(card)
                  }
                ]}
              />
            </CSSTransition>
          )
        );
      })}
    </>
  );
});

export default CardListIn;
