import React from "react";
import { useInterval } from "react-use";

export const useAnimateTexts = (config?: {
  countUpTo?: number;
  initialDelay?: number;
  delay?: number;
  disable?: boolean;
}) => {
  const countUpTo = config?.countUpTo || 10;
  const delay = config?.delay || 500;
  const initialDelay = config?.initialDelay || 0;

  const [counter, setCounter] = React.useState(config?.disable ? Infinity : 0);

  const counting = countUpTo > counter;

  useInterval(
    () => {
      setCounter((count) => count + 1);
    },
    counting ? (counter === 0 ? initialDelay : delay) : null
  );

  return [...Array(countUpTo)].map((_, index) =>
    index < counter ? { opacity: 1, transition: "opacity 1500ms" } : { opacity: 0 }
  );
};
