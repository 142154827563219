import React from "react";
import styled from "styled-components/macro";
import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { devicePaddings } from "../../tools/device";
import { useTtsQuery } from "../../queries/ttsQuery";
import { silenceTts } from "../../queries/tts/ttsTools";
import { useDeviceLang } from "../../hooks/deviceHooks";
import MammothIcon from "../../icons/MammothIcon";
import { useEffectOnce } from "react-use";
import { useNavigate } from "react-router";
import { useTimedActions } from "../../hooks/useTimedActions";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";

const { top } = devicePaddings();

const Wrap = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: ${top + 55}px 0;
  background-color: #d4418e;
  background-image: linear-gradient(0deg, #d4418e 50%, #063579 74%);

  .showing {
    opacity: 1;
    transition: opacity 1s;
  }

  .hidden {
    opacity: 0;
    visibility: hidden;
  }
`;
const In = styled.div`
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  p {
    margin: 38px 0;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 4;
    color: white;
    font-size: 18px;
  }
`;
const SButtonWrap = styled.div`
  align-self: center;
  padding: 11px 22px;
  position: absolute;
  bottom: 10%;
  width: 94%;
  left: 3%;
  z-index: 4;
`;
const SSkipButton = styled(Button)`
  align-self: center;
  padding: 0px;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0%;
  left: 0;
  z-index: 4;
  color: white;
`;
const Sun = styled.div`
  position: absolute;
  top: 20%;
  right: 30%;
  width: 70px;
  height: 70px;
  background: #ff5100;
  border-radius: 50%;
  z-index: 0;
  animation: 60s ease-out 0s 1 sun;
  @keyframes sun {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(150px);
    }
  }
`;
const SMammothIcon = styled(MammothIcon)`
  width: 250px;
  height: 250px;
  position: absolute;
  bottom: 150px;
  left: -10%;
  z-index: 3;
  animation: 4s ease-out 0s 1 slideInFromLeft;
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%) scaleX(-1);
    }
    100% {
      transform: translateX(0%) scaleX(-1);
    }
    101% {
      transform: translateX(0) scaleX(1);
    }
  }
`;
const Beach = styled.div`
  position: absolute;
  top: 50%;
  left: -130%;
  width: 300%;
  height: 500px;
  background-color: #c2b280;
  background-image: linear-gradient(190deg, #c2b280 40%, #81754e 60%);
  border-radius: 50%;
  z-index: 2;
`;
const Water = styled.div`
  position: absolute;
  top: 30%;
  left: -150%;
  width: 400%;
  height: 300px;
  background: #1d5fa4;
  border-radius: 50%;
  z-index: 1;
`;

type Props = { onClose: VoidFunction };

const InternAdSunset: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { ttsLang } = useDeviceLang();
  const tts = useTtsQuery(ttsLang);
  const [visibleIndex, setVisibleIndex] = React.useState(0);
  const [movingMammoth, setMovingMammoth] = React.useState(true);
  useTimedActions([
    { delay: 0, callback: () => setVisibleIndex(1) },
    { delay: 1500, callback: () => setVisibleIndex(2) },
    { delay: 3000, callback: () => setVisibleIndex(3) },
    { delay: 4000, callback: () => setMovingMammoth(false) },
    { delay: 6000, callback: () => setVisibleIndex(4) }
  ]);

  const order = (e) => {
    e.stopPropagation();
    onClose();
    navigate(SUBSCRIPTION.url());
  };

  useEffectOnce(() => {
    tts &&
      tts({
        text: t(
          "Do you want to continue learning? Or watch the sunset with Memo. Subscribe and stop distractions. Even if it's a lovely sunset."
        ),
        speakingRate: 1
      });
  });

  return (
    <Wrap>
      <In>
        <Beach />
        <Water />
        <Sun />
        <Trans parent="p" className={visibleIndex >= 1 ? "showing" : "hidden"}>
          Do you want to continue learning?
        </Trans>

        <SMammothIcon walking={movingMammoth} />
        <Trans parent="p" className={visibleIndex >= 2 ? "showing" : "hidden"}>
          ..or watch the sunset with mammoth Memo.
        </Trans>

        <SButtonWrap className={visibleIndex >= 3 ? "showing" : "hidden"}>
          <Button variant="contained" color="primary" size="large" onClick={order}>
            <Trans>Subscribe and stop distractions</Trans>
          </Button>
        </SButtonWrap>

        <SSkipButton
          className={visibleIndex >= 4 ? "showing" : "hidden"}
          variant="text"
          size="medium"
          onClick={() => {
            silenceTts();
            onClose();
          }}
        >
          <Trans>Skip for now.</Trans>
        </SSkipButton>
      </In>
    </Wrap>
  );
};

export default InternAdSunset;
