import styled from "styled-components/macro";
import AppLang from "../../components/other/AppLang";

const Wrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

const LoginHeader = () => (
  <Wrap>
    <div style={{ margin: "0 auto" }}>
      <AppLang />
    </div>
  </Wrap>
);

export default LoginHeader;
