import React from "react";
import { isEqual } from "lodash";
import TutorialPointer from "root/tutorial/TutorialPointer";
import { useEffectOnce } from "react-use";

const CourseViewTutorialHand: React.FC = () => {
  const [tutorialPointerPos, setTutorialPointerPos] = React.useState<React.CSSProperties | undefined>(undefined);

  const updateHandPosition = () => {
    const actionButtonElm = document.getElementById("course-view-action-button");
    if (!actionButtonElm) return;

    const rect = actionButtonElm.getBoundingClientRect();
    const newPosition: React.CSSProperties = {
      top: Math.floor(rect.top + rect.height / 2) + "px",
      left: Math.floor(rect.left + rect.width / 2) + "px",
      zIndex: 1400
    };

    !isEqual(newPosition, tutorialPointerPos) && setTutorialPointerPos(newPosition);
  };

  useEffectOnce(() => {
    const iId = setInterval(updateHandPosition, 1000);
    return () => {
      clearInterval(iId);
    };
  });

  if (tutorialPointerPos) {
    return <TutorialPointer pulsing style={tutorialPointerPos} />;
  } else {
    return null;
  }
};

export default CourseViewTutorialHand;
