import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";
import DialogWindow from "../../components/other/DialogWindow";
import DownloadButton from "./DownloadButton";
import { useDispatch } from "react-redux";
import { setDeviceId } from "components/ReduxProvider";
import { useLogout } from "queries/users/logout";

const Wrap = styled.div`
  padding: 0 15px 20px;
  max-width: 380px;
`;

type Props = {};

const DownloaderDialog: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const logout = useLogout();

  return (
    <DialogWindow open={true} onClose={logout} wide>
      <Wrap>
        <p>
          <Trans parent="strong">Good job!</Trans>
        </p>

        <Trans parent="p">Now install DuoCards app and log in there</Trans>

        <DownloadButton>
          <Trans>Install DuoCards</Trans>
        </DownloadButton>

        {process.env.NODE_ENV === "development" && (
          <button onClick={() => dispatch(setDeviceId(`admin-${Math.random().toString(36).substr(2, 7)}`))}>
            DEV: set deviceId manually
          </button>
        )}
      </Wrap>
    </DialogWindow>
  );
};

export default DownloaderDialog;
