import React from "react";
import styled from "styled-components/macro";
import MuiTextField, { TextFieldProps } from "@mui/material/TextField";
import { useField } from "formik";

const STextField = styled(MuiTextField)`
  margin: 0 0 15px;
  .MuiFilledInput-root {
    background: white;
  }
  .MuiFormHelperText-root {
    line-height: 1.35em;
  }
  .MuiInputLabel-root {
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
`;

type TProps = TextFieldProps;

export const TextField: React.FC<TProps> = (props) => (
  <STextField
    {...props}
    variant={props.variant || "filled"}
    fullWidth={props.fullWidth !== undefined ? props.fullWidth : true}
    inputProps={{
      ...{ autoCapitalize: "off", autoCorrect: "off", autoComplete: "off", spellCheck: "false" },
      ...(props.inputProps || {})
    }}
  />
);

type FProps = TProps;

export const FTextField: React.FC<FProps> = (props) => {
  // @ts-ignore
  const [field, meta] = useField(props);
  const error = Boolean(meta.touched && meta.error);
  return (
    <TextField
      {...field}
      {...props}
      error={error}
      helperText={error ? meta.error : props.helperText}
      FormHelperTextProps={{ style: { marginTop: 1, marginBottom: -5 } }}
      InputLabelProps={{ required: false }}
    />
  );
};
