import React from "react";
import styled from "styled-components/macro";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CheckItemS = styled.div<{ crossed?: boolean; onClick?: any }>`
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 85%;
  line-height: 1.3em;
  text-decoration: ${({ crossed }) => (crossed ? "line-through" : "none")};
  cursor: pointer;
  & > svg:first-child {
    font-size: 20px;
    color: ${({ theme }) => theme.duo.color.green};
    margin: 2px 7px 2px 0;
  }
`;
const In = styled.span<{ clickable?: any }>`
  display: flex;
  align-items: center;
  border-bottom: ${({ clickable }) => (clickable ? `2px dotted #ccc` : `none`)};
  padding: 1px 0;
  cursor: ${({ clickable }) => (clickable ? `pointer` : `default`)};
`;
const Grey = styled.div`
  background: #ccc;
  color: white;
  padding: 1px 1px;
  border-radius: 4px;
  margin-left: 4px;
  svg {
    font-size: 13px;
    display: block;
  }
`;

type Props = {
  children: React.ReactNode;
  done?: boolean;
  onClick?: VoidFunction;
  className?: string;
};

const CheckItem: React.FC<Props> = ({ className, done, onClick: onClickRaw, children }) => {
  const onClick = done ? undefined : onClickRaw;

  return (
    <CheckItemS className={className} crossed={done} onClick={onClick}>
      {done ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
      <In clickable={onClick}>{children}</In>
      {onClick && (
        <Grey>
          <ArrowForwardIcon />
        </Grey>
      )}
    </CheckItemS>
  );
};

export default CheckItem;
