import { Tooltip } from "@mui/material";
import TransVariable from "components/other/TransVariable";
import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";

const Wrap = styled.div`
  color: ${({ theme }) => theme.duo.color.red};
  text-align: center;
  margin-top: -10px;
  margin-bottom: 10px;
  font-size: 13px;
  line-height: 1.7em;
`;
// used button because it better captures touch events on mobiles
const U = styled.button`
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.duo.color.red};
`;

const UTooltip = ({ children, ...p }) => (
  // @ts-ignore
  <Tooltip {...p}>
    <U>{children}</U>
  </Tooltip>
);

type Props = {
  onEditOriginal: VoidFunction;
  onResetProgress: VoidFunction;
  word: string;
};

const DuplicatedCard: React.FC<Props> = ({ onEditOriginal, word, onResetProgress }) => {
  return (
    <Wrap>
      <Trans>Duplicated card.</Trans> <br />
      <U onClick={onEditOriginal}>
        <Trans>edit original</Trans>
      </U>{" "}
      |
      <UTooltip
        title={
          <Trans>
            instead of <TransVariable text={`"${word}"`} /> use <TransVariable text={`"${word} (meaning)"`} />.
          </Trans>
        }
        placement="bottom"
        enterTouchDelay={0}
        leaveTouchDelay={5000}
        arrow
      >
        <Trans>use brackets</Trans>
      </UTooltip>
      |{" "}
      <U onClick={onResetProgress}>
        <Trans>reset progress</Trans>
      </U>
    </Wrap>
  );
};

export default DuplicatedCard;
