import React, { useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClose } from "@fortawesome/pro-regular-svg-icons";
import { Recorder } from "tools/recorder";
import { useEvent, useMount } from "react-use";
import { dispatchEvent, EVENT, snackbar } from "tools/events";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useUnmount } from "react-use";
import { useTranslation } from "react-i18next";
import { Popper, Zoom, PopperProps } from "@mui/material";
import { silenceTts } from "queries/tts/ttsTools";

const NUMBER_OF_AUDIOBARS = 64;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding: 15px 20px;
  gap: 15px;
  width: calc(100%);
  max-width: 400px;
  height: 60px;
  margin-bottom: 10px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.duo.palette.blueMain};
`;

const IconButton = styled.button<{ color: "primary" | "secondary" }>`
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-color: ${({ theme, color }) => (color === "primary" ? theme.duo.color.white : theme.duo.palette.blueMain)};
  color: ${({ theme, color }) => (color === "primary" ? theme.duo.palette.blueMain : theme.duo.color.white)};
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme, color }) => theme.duo.color.white};

  > svg {
    width: 16px;
    height: 16px;
  }
`;

const LoudnessGraph = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.duo.palette.blueMain} 0%,
      transparent 10%,
      transparent 90%,
      ${({ theme }) => theme.duo.palette.blueMain} 100%
    );
    pointer-events: none;
  }
`;

const LoudnessBar = styled.div`
  width: 4px;
  margin-right: 1px;
  background-color: ${({ theme }) => theme.duo.color.white};
  border-radius: 4px;
`;

const showArrowAnim = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Arrow = styled.span`
  position: absolute;

  width: 10px;
  height: 10px;
  &::before {
    content: "";
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0px 10px;
    border-color: ${({ theme }) => theme.duo.palette.blueMain} transparent transparent transparent;
    opacity: 0;
    animation: ${showArrowAnim} 0.3s forwards 0.4s;
  }
`;

const PopperWrapper = styled(Popper)`
  z-index: 1;
  padding-left: 20px;
  padding-right: 20px;
  &[data-popper-placement*="top"] ${Arrow} {
    bottom: 0;
    left: 0;
    width: 10px;
    height: 10px;
    margin-left: -5px;
  }
`;

export type RecordingResultDetail = {
  audioBlob: Blob;
};

export type RecordingOptions = {
  stopAfterMs?: number;
  sourceElementRef?: React.RefObject<HTMLElement>;
};

export type RecordingStartDetail = RecordingOptions;

const initialLoudnessGraph = Array(NUMBER_OF_AUDIOBARS).fill(-1);

const AudioInput: React.FC = () => {
  const recorderRef = useRef<Recorder | null>(null);
  const [loudnessGraph, setLoudnessGraph] = useState<number[]>(initialLoudnessGraph);
  const recording = useGetState("isAudioRecording");
  const setRecording = useSetState("isAudioRecording");
  const { t } = useTranslation();
  const arrowRef = useRef<HTMLDivElement | null>(null);
  const recordingTimeoutRef = useRef<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<PopperProps["anchorEl"]>(null);

  const handleStopRecording = () => {
    setRecording(false);
    recorderRef.current?.stop();
  };

  const handleStartRecording = async (event: CustomEvent<RecordingStartDetail>) => {
    const { stopAfterMs, sourceElementRef } = event.detail;
    if (recording) return;

    silenceTts(); // also fixing mic initialization delay

    const sourceElement = sourceElementRef?.current;
    setAnchorEl(
      sourceElement
        ? {
            getBoundingClientRect: () => {
              const rect = sourceElement.getBoundingClientRect();
              return new DOMRect(rect.left, rect.top, rect.width, rect.height);
            }
          }
        : null
    );

    setLoudnessGraph(initialLoudnessGraph);
    recorderRef.current = new Recorder();

    recorderRef.current.onError((err: Error) => {
      snackbar(t(err.message), { severity: "error" });
      dispatchEvent(EVENT.recordingStopped);
    });

    recorderRef.current.onStop((audioBlob: Blob) => {
      dispatchEvent(EVENT.recordingResult, { audioBlob });
      dispatchEvent(EVENT.recordingStopped);
    });

    recorderRef.current.onLoudness((loudness: number) => {
      setLoudnessGraph((prev) => {
        const updatedGraph = [...prev, loudness];
        return updatedGraph.length > NUMBER_OF_AUDIOBARS ? updatedGraph.slice(-NUMBER_OF_AUDIOBARS) : updatedGraph;
      });
    });

    const started = await recorderRef.current.start();

    setRecording(started);

    if (started) {
      // limit recording time to 30 seconds
      recordingTimeoutRef.current = setTimeout(() => {
        dispatchEvent(EVENT.stopRecording);
      }, stopAfterMs || 30000);
    }
  };

  const handleCancelRecording = () => {
    recorderRef.current?.cancel();
    recorderRef.current = null;
    setRecording(false);
    dispatchEvent(EVENT.recordingStopped);
  };

  useEvent(EVENT.startRecording, handleStartRecording);
  useEvent(EVENT.stopRecording, handleStopRecording);
  useEvent(EVENT.recordingStopped, () => {
    if (recordingTimeoutRef.current) {
      clearTimeout(recordingTimeoutRef.current);
      recordingTimeoutRef.current = null;
    }
  });

  useMount(() => {
    setRecording(false);
  });

  useUnmount(() => {
    setRecording(false);
    recorderRef.current?.uninitialize();
    recorderRef.current = null;
  });

  return (
    <PopperWrapper
      open={!!recording && !!anchorEl}
      placement="top"
      anchorEl={anchorEl}
      style={{ zIndex: 10000 }}
      modifiers={[
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef.current
          }
        },
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            rootBoundary: "document"
          }
        }
      ]}
      transition
    >
      {({ TransitionProps }) => (
        <Zoom {...TransitionProps}>
          <Wrapper>
            <Arrow ref={arrowRef} />
            <IconButton color="secondary" onClick={handleCancelRecording}>
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
            <LoudnessGraph>
              {loudnessGraph.map((value, index) => (
                <LoudnessBar
                  key={`${value}-${index}`}
                  style={{ height: `${8 + value * 100}%`, color: value === -1 ? "transparent" : "auto" }}
                />
              ))}
            </LoudnessGraph>
            <IconButton color="primary" onClick={handleStopRecording}>
              <FontAwesomeIcon icon={faCheck} size="xs" />
            </IconButton>
          </Wrapper>
        </Zoom>
      )}
    </PopperWrapper>
  );
};

export default AudioInput;
