import React from "react";
import styled from "styled-components/macro";
import { useKey, usePrevious } from "react-use";
import Contextor from "../contextor/Contextor";
import { textCss } from "../Article";
import Caption from "./Caption";
import { IconButton } from "@mui/material";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import Rate from "../../../../components/other/Rate";
import { sourceQuery$data } from "../../../../queries/sources/__generated__/sourceQuery.graphql";
import { CaptionType } from "sharedJs__generated/globalTypes";
import { useOpenAdsDialog } from "components/ads/adStack";
import Finish from "../Finish";
import { useFinishSource } from "../../../../queries/sources/finishSource";
import RecommendedSourcesPaginated from "../../../../components/RecommendedSources/RecommendedSourcesPaginated";
import { useGetState } from "components/ReduxProvider";

export const CAPTIONSID = "captionsId";
const TOP_OFFSET = 50;

const Wrap = styled.div<{ lang: string }>`
  padding: 23px 0 0;
  overflow: auto;
  flex-grow: 1;
  scroll-behavior: smooth;

  ${({ lang }) => textCss(lang)}
`;
const In = styled.div`
  padding: 50px 0 20px;
`;
const Recenter = styled(IconButton)`
  position: absolute;
  z-index: 701;
  bottom: 20px;
  right: 40px;
  background: ${({ theme }) => theme.duo.color.primary} !important;
  box-shadow: 0.5px 0.5px 6px #0004;
  color: white;
`;
const Dots = styled.div`
  margin: 0 0 12px 50px;
  &:before {
    content: "...";
    font-size: 30px;
  }
`;

type Props = {
  yt?: YT.Player;
  playing: boolean;
  source: NonNullable<sourceQuery$data["sourceByUriOrId"]>;
  rate: number;
  captions: CaptionType[];
};
const Captions: React.FC<Props> = ({ yt, playing, source, rate, captions: capRaw }) => {
  const captions = capRaw.filter((c) => c.text && c.text.replace(/\s/gi, ""));
  const startFrom = source.rating?.continueFrom || source.videoStart || 0;
  const ind = captions.findIndex((c) => c.start > startFrom * 1000) - 1;
  const [activeIndex, setActiveIndex] = React.useState(ind);
  const [silence, setSilence] = React.useState(false);
  const [recenter, setRecenter] = React.useState(false);
  const [noScroll, setNoScroll] = React.useState(false);
  const deckCreate = useGetState("deckCreate");
  const openAdsDialog = useOpenAdsDialog();

  const wrapRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const captsRef: React.RefObject<React.RefObject<HTMLDivElement>[]> = React.useRef(
    captions.map(() => React.createRef())
  );
  const finishRef = React.useRef<HTMLDivElement>(null);
  const { finishSource, loading: finishSourceLoading } = useFinishSource();
  const [finishing, setFinishing] = React.useState(false);

  const caption = (index: number) => (captsRef.current || [])[index]?.current;

  const scrollIt = React.useCallback(
    (index: number, force?: boolean) => {
      const captionEl = caption(index);
      const wrapEl = wrapRef.current;
      if (!wrapEl || !captionEl) return;
      const wBottom = wrapEl.offsetTop + wrapEl.offsetHeight;
      const cBottom = captionEl.offsetTop + captionEl.offsetHeight - wrapEl.scrollTop;
      const cTop = captionEl.offsetTop - wrapEl.scrollTop;
      // out of viewport -> show "back on track"
      if (!force && (cTop > wBottom || cBottom < wrapEl.offsetTop)) {
        setRecenter(true);
        return;
      } else if (recenter) {
        setRecenter(false);
      }
      // too down -> scroll
      if (!noScroll && (wBottom - cBottom < 90 || cBottom < wrapEl.offsetTop)) {
        setTimeout(() => {
          wrapEl.scrollTop = captionEl.offsetTop - wrapEl.offsetTop - TOP_OFFSET;
        }, 100); // wait until captions rerenders before scrolling
      }
    },
    [recenter, noScroll]
  );

  const prevPlaying = usePrevious(playing);
  React.useEffect(() => {
    if (!prevPlaying && playing) scrollIt(activeIndex, true);
    if (prevPlaying === undefined && activeIndex > 0 && wrapRef.current) {
      wrapRef.current.scrollTop = (caption(activeIndex)?.offsetTop || 0) - wrapRef.current.offsetTop - TOP_OFFSET;
    }
  }, [playing, prevPlaying, activeIndex, scrollIt]);

  const handleFinishSource = React.useCallback(
    (finished) => {
      setFinishing(true);
      if (source && source?.rating && !finishSourceLoading) {
        finishSource({ id: source.id, finished: finished }).then(() => {});
      }
    },
    [finishSource, finishSourceLoading, source]
  );

  React.useEffect(() => {
    if (!yt || !playing) return;
    const currentT = yt?.getCurrentTime() * 1000;

    const nextIndex = captions.findIndex((c) => c.start - 200 > currentT);
    const ind = nextIndex === activeIndex + 1 || nextIndex === 0 ? nextIndex : nextIndex - 1;
    const forceScroll = nextIndex !== activeIndex + 1;
    const timer = !captions[ind]
      ? undefined
      : setTimeout(
          () => {
            setActiveIndex(ind);
            setSilence(false);
            scrollIt(ind, forceScroll);
          },
          (captions[ind].start - currentT) / rate
        );

    const timerSilence =
      !captions[activeIndex] ||
      (captions[activeIndex + 1] &&
        captions[activeIndex + 1].start - (captions[activeIndex].start + captions[activeIndex].dur) < 200)
        ? undefined
        : setTimeout(
            () => setSilence(true),
            (captions[activeIndex].dur - (currentT - captions[activeIndex].start)) / rate
          );
    if (activeIndex >= captions.length - 2 && !source.rating?.finished && !finishing) {
      handleFinishSource(true);
    }

    return () => {
      timer && clearTimeout(timer);
      timerSilence && clearTimeout(timerSilence);
    };
  }, [playing, yt, rate, activeIndex, scrollIt, captions, handleFinishSource, source.rating?.finished, finishing]);

  useKey(
    " ",
    (e) => {
      if (yt?.getPlayerState() === 1) {
        yt?.pauseVideo();
      } else {
        yt?.playVideo();
      }
      e.preventDefault();
    },
    undefined,
    [yt]
  );

  const handleRecenter = () => {
    const captionEl = caption(activeIndex);
    const wrapEl = wrapRef.current;
    if (!captionEl || !wrapEl) return;
    wrapEl.scrollTop = captionEl.offsetTop - wrapEl?.offsetTop - TOP_OFFSET;
    setRecenter(false);
  };

  const handleDown = (e) => setNoScroll(true);
  const handleUp = (e) => setNoScroll(false);

  return (
    <>
      <Wrap
        lang={source.lang}
        ref={wrapRef}
        onMouseDown={handleDown}
        onTouchStart={handleDown}
        onMouseUp={handleUp}
        onTouchEnd={handleUp}
        id={CAPTIONSID}
      >
        <Contextor
          source={source}
          onSelection={(selected) => yt?.pauseVideo()}
          onBlur={() => {
            if (deckCreate || openAdsDialog) return;
            try {
              yt?.seekTo((yt?.getCurrentTime() || 1.5) - 1.5, true);
              yt?.playVideo();
            } catch (e) {
              console.log(`Youtube widget api threw an error`);
            }
          }}
        >
          <In>
            {activeIndex >= 40 && <Dots />}
            {captions.map((c, i) => {
              const active = i === activeIndex && !silence;
              if (i <= activeIndex - 40 || i >= activeIndex + 40) return null;
              return (
                <Caption
                  lang={source.lang}
                  key={c.start}
                  ref={(captsRef.current || [])[i]}
                  active={active}
                  caption={c}
                  onPlay={() => {
                    setTimeout(() => setActiveIndex(i), 100);
                    yt?.seekTo((c.start - 100) / 1000, true);
                    yt?.playVideo();
                  }}
                />
              );
            })}
            {captions.length - activeIndex >= 40 && <Dots />}
          </In>
        </Contextor>

        <Rate padded source={source} />

        <Finish source={source} myRef={finishRef} finishSource={handleFinishSource} />

        <RecommendedSourcesPaginated lang={source.lang} sourceId={source?.id} />
      </Wrap>

      {recenter && (
        <Recenter onClick={handleRecenter}>
          <VerticalAlignCenterIcon />
        </Recenter>
      )}
    </>
  );
};

export default Captions;
