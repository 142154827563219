import { Button } from "@mui/material";
import { useSeeHint } from "components/hint/Hint";
import React from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";
import styled from "styled-components/macro";
import { TUTORIALDONE } from "../steps/Verdict";
import { logEvent } from "tools/analyticEvents";
import { MAIN } from "../../RootRouter.config";

const Wrap = styled.div`
  padding: 40px 0 10px;
  margin: auto;
`;
const Img = styled.img`
  width: 60px;
  height: 60px;
`;

type Props = {};

const SettingsDone: React.FC<Props> = () => {
  const seeHint = useSeeHint();
  const navigate = useNavigate();

  return (
    <Wrap>
      <Img src="https://cdn.duocards.com/svg/confetti.svg" />

      <Trans parent="p">So far you are doing great!</Trans>

      <p>
        <Trans parent="strong">Memo has a quest for you!</Trans>
      </p>

      <p>&nbsp;</p>

      <Button
        variant="contained"
        onClick={(e) => {
          seeHint(TUTORIALDONE);
          logEvent("af_tutorial_completion");
          navigate(MAIN.url(), { replace: true });
        }}
      >
        <Trans>great, let's go</Trans>
      </Button>
    </Wrap>
  );
};

export default SettingsDone;
