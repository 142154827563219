import Loader from "components/other/Loader";
import { LibrarySources, sourcesOnPage } from "queries/sources/libraryQuery";
import React from "react";
import SourceCard from "./SourceCard";
import { LibLine, LibLineIn } from "./styled";

type Props = {
  sources?: LibrarySources;
  disabled?: boolean;
  isLatestVisited?: boolean;
  sourcesOnPageOverride?: number;
};

const LibraryLine: React.FC<Props> = ({ sources, disabled, isLatestVisited, sourcesOnPageOverride }) => {
  const wrapRef: React.RefObject<HTMLDivElement> = React.useRef(null);

  const handleScroll = (e) => {
    if (
      sources?.hasNext &&
      !sources.isLoadingNext &&
      wrapRef.current &&
      wrapRef.current?.scrollWidth - wrapRef.current?.offsetWidth - e.target.scrollLeft < 700
    ) {
      sources?.loadNext?.(sourcesOnPageOverride || sourcesOnPage);
    }
  };

  return (
    <LibLine disabled={disabled} onScroll={handleScroll} ref={wrapRef}>
      <LibLineIn>
        {!!sources?.data?.sources?.edges?.length &&
          sources?.data?.sources?.edges.map(
            (source) =>
              source?.node && <SourceCard source={source.node} key={source.node.id} isLatestVisited={isLatestVisited} />
          )}
        {(sources?.isLoadingNext || sources?.isLoading) && (
          <div style={{ minWidth: 140 }}>
            <Loader />
          </div>
        )}
      </LibLineIn>
    </LibLine>
  );
};

export default LibraryLine;
