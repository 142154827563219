import React from "react";
import { Dialog, Button } from "@mui/material";
import styled from "styled-components/macro";
import { langs, appLangs, topAppLangs } from "../../sharedJs__generated/langsList";
import LangList from "./LangList";
import { useTranslation } from "react-i18next";
import { browserLang } from "../../tools/device";
import { without } from "lodash";

const SDialog = styled(Dialog)`
  .MuiPaper-root {
    max-height: 94%;
  }
`;
const Wrap = styled.div`
  padding: 12px;
  height: 100%;
  overflow: auto;
`;
const Butt = styled.div<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  display: inline;
`;

type Props = {
  title?: React.ReactNode;
  onSelect?: (id: string, label: string) => void;
  className?: string;
  value: string;
  disabled?: boolean;
  hideDeviceLanguage?: boolean;
};

const AppLangMenu: React.FC<Props> = ({ title, onSelect, value, disabled, hideDeviceLanguage }) => {
  const [open, setOpen] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);
  const buttRef: any = React.useRef(null);
  const { t } = useTranslation();
  const userLang = browserLang();

  const moreLanguages = React.useMemo<string[]>((): string[] => {
    const more = [...appLangs];
    if (!hideDeviceLanguage && !more.includes(userLang)) more.push(userLang);
    if (!more.includes(value)) more.push(value);

    return without(more, ...topAppLangs);
  }, [userLang, hideDeviceLanguage, value]);

  const toppedLang: string = topAppLangs.includes(value) ? "" : value;

  const handleClose = () => {
    setOpen(false);
    setShowMore(false);
  };

  const handleSelect = (lang: string) => {
    onSelect && onSelect(lang, langs[lang].label);
    handleClose();
  };

  return (
    <>
      <Butt
        disabled={disabled}
        onClick={() => {
          setOpen(!open);
        }}
        ref={buttRef}
      >
        {title}
      </Butt>

      <SDialog open={open} onClose={handleClose} fullWidth>
        <Wrap>
          <LangList langList={topAppLangs} onSelect={handleSelect} value={value} />
          <hr style={{ margin: "15px 0" }} />
          {!showMore && toppedLang && (
            <LangList langList={[toppedLang]} onSelect={handleSelect} value={value} showAutoGenerated={true} />
          )}

          {showMore && (
            <LangList langList={moreLanguages} onSelect={handleSelect} value={value} showAutoGenerated={true} />
          )}

          {!showMore && (
            <Button onClick={() => setShowMore(true)} variant="text" size="small" color="secondary" fullWidth={true}>
              {t("Show more")}
            </Button>
          )}
        </Wrap>
      </SDialog>
    </>
  );
};

export default AppLangMenu;
