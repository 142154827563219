/**
 * @generated SignedSource<<0a0083789e50c1601b26715af46d4cb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type setFlagMutation$variables = {
  name: string;
  remove?: boolean | null;
  value?: string | null;
};
export type setFlagMutation$data = {
  readonly setUserFlag: {
    readonly flags: ReadonlyArray<{
      readonly name: string;
      readonly value: string | null;
    }>;
  } | null;
};
export type setFlagMutation = {
  response: setFlagMutation$data;
  variables: setFlagMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "remove"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "value"
},
v3 = [
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  },
  {
    "kind": "Variable",
    "name": "remove",
    "variableName": "remove"
  },
  {
    "kind": "Variable",
    "name": "value",
    "variableName": "value"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "setFlagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "setUserFlag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFlags",
            "kind": "LinkedField",
            "name": "flags",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "setFlagMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "setUserFlag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFlags",
            "kind": "LinkedField",
            "name": "flags",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "29dba267d1bf7f9d6e9710e18a3fd483",
    "id": null,
    "metadata": {},
    "name": "setFlagMutation",
    "operationKind": "mutation",
    "text": "mutation setFlagMutation(\n  $name: String!\n  $value: String\n  $remove: Boolean\n) {\n  setUserFlag(name: $name, value: $value, remove: $remove) {\n    flags {\n      name\n      value\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1cd3e4743b214ed0ebcaad33b0fd6542";

export default node;
