/**
 * @generated SignedSource<<8d77bcddbd70230704d31b66c254bae8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ImgPickerMutation$variables = {
  apiId: string;
  authorApiName: string;
  authorName: string;
  url: string;
};
export type ImgPickerMutation$data = {
  readonly setImage: string;
};
export type ImgPickerMutation = {
  response: ImgPickerMutation$data;
  variables: ImgPickerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "apiId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "authorApiName"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "authorName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "url"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "apiId",
        "variableName": "apiId"
      },
      {
        "kind": "Variable",
        "name": "authorApiName",
        "variableName": "authorApiName"
      },
      {
        "kind": "Variable",
        "name": "authorName",
        "variableName": "authorName"
      },
      {
        "kind": "Variable",
        "name": "url",
        "variableName": "url"
      }
    ],
    "kind": "ScalarField",
    "name": "setImage",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ImgPickerMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ImgPickerMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "a8c98598f46a4315fdbfb14cded84740",
    "id": null,
    "metadata": {},
    "name": "ImgPickerMutation",
    "operationKind": "mutation",
    "text": "mutation ImgPickerMutation(\n  $url: String!\n  $apiId: String!\n  $authorName: String!\n  $authorApiName: String!\n) {\n  setImage(url: $url, apiId: $apiId, authorName: $authorName, authorApiName: $authorApiName)\n}\n"
  }
};
})();

(node as any).hash = "8eda4fdfdfe825f3361a8a6b09b8239e";

export default node;
