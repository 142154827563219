import { Button } from "@mui/material";
import { useSeeHint } from "components/hint/Hint";
import AppLang from "components/other/AppLang";
import { useViewerQuery } from "queries/viewerQuery";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { FinishTitle } from "root/main/main/FriendshipChecklist";
import styled from "styled-components/macro";
import { TutorialFC } from "../TutorialContent";

export const TUTORIALWELCOME = "welcome";

const Wrap = styled.div``;

const AhoyMemooth: TutorialFC = () => {
  const { viewer } = useViewerQuery();
  const seeHint = useSeeHint();
  const { t } = useTranslation();

  return (
    <Wrap>
      <AppLang inTutorial />
      <p style={{ fontSize: "34px" }}>👋</p>
      <Trans parent="p">Hello!</Trans>

      {viewer?.inviter ? (
        <FinishTitle />
      ) : (
        <p style={{ lineHeight: "1.8em" }}>
          {t(`I am {*} and I will walk you through the App.`)
            .split("{*}")
            .map((txt, i) => (
              <React.Fragment key={i}>
                {txt}
                {!i && <strong> Memo </strong>}
                <br />
              </React.Fragment>
            ))}
        </p>
      )}
      <Button variant="contained" color="primary" onClick={() => seeHint(TUTORIALWELCOME)}>
        <Trans>Continue</Trans>
      </Button>
    </Wrap>
  );
};

export default AhoyMemooth;
