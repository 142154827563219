import { useQuery } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { translateQuery } from "./__generated__/translateQuery.graphql";

export const TOO_LONG = 160;

export const translateQL = graphql`
  query translateQuery($from: String!, $fromLang: String!, $toLang: String!, $withSvg: Boolean) {
    translate(from: $from, fromLang: $fromLang, toLang: $toLang, withSvg: $withSvg) {
      translate
      svg {
        flatId
        url
      }
    }
  }
`;

export const useTranslateQuery = (variables?: {
  from?: string | null;
  fromLang?: string | null;
  toLang?: string | null;
  withSvg?: boolean;
}) => {
  const tooLong = (variables?.from?.length || 0) > TOO_LONG;
  const skip =
    !variables ||
    !variables?.from ||
    !variables?.fromLang ||
    !variables?.toLang ||
    tooLong ||
    variables.toLang === variables.fromLang;

  const { data, isLoading } = useQuery<translateQuery>(translateQL, variables as translateQuery["variables"], { skip });

  if (variables?.toLang === variables?.fromLang) return { translate: "", svg: null, isLoading };
  if (tooLong) return { translate: " - text is too long - ", svg: null, isLoading };
  if (!data?.translate && data?.translate.translate !== "") return { translate: null, svg: null, isLoading };

  return { ...data.translate, isLoading };
};
