import { DifficultyEnum } from "../sharedJs__generated/constants";
import { getTheme } from "./theme";

export const difficultyToColor = (difficulty: string, withTransparency?: boolean) => {
  let color;
  const theme = getTheme();
  switch (difficulty) {
    case DifficultyEnum.beginner:
      color = theme.color.beginnerGreen;
      break;
    case DifficultyEnum.easy:
      color = theme.color.easyGreen;
      break;
    case DifficultyEnum.moderate:
      color = theme.color.primary;
      break;
    case DifficultyEnum.hard:
      color = theme.color.red;
      break;
    default:
      break;
  }

  return color + (!!withTransparency ? "99" : "");
};
