import React from "react";
import styled from "styled-components/macro";

const Svg = styled.svg`
  width: 68px;
  height: 68px;
  position: absolute;
  left: 200px;
  top: -18px;
`;

type Props = {
  className?: string;
};

const Hills: React.FC<Props> = ({ className }) => {
  return (
    <Svg className={className} viewBox="0 -10 512 511" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m397.09375 222.523438-17.367188 36.609374-26.609374-56.09375-59.230469 107.917969 19.265625 55.878907 78.171875 37.34375 53.285156-59.753907zm0 0"
        fill="#cae8f9"
      ></path>
      <path
        d="m397.09375 222.523438-17.367188 36.609374-26.609374-56.09375-59.230469 107.917969 19.265625 55.878907 78.171875 37.34375 53.285156-59.753907zm0 0"
        fill="#b7e0f6"
      ></path>
      <path
        d="m432.386719 313.066406-48.457031 54.339844-84.496094-40.363281 51.371094 149h145.105468zm0 0"
        fill="#b35f49"
      ></path>
      <path
        d="m432.386719 313.066406-48.457031 54.339844-84.496094-40.363281 51.371094 149h145.105468zm0 0"
        fill="#99503d"
      ></path>
      <path
        d="m270.621094 151.457031-20.351563 23.273438-50.308593-101.960938-73.773438 147.550781-28.726562-8.195312-30.234376 99.832031 84.324219 52.933594 47.265625-34.492187 55.484375 54.183593 61.695313-101.515625zm0 0"
        fill="#cae8f9"
      ></path>
      <g fill="#b7e0f6">
        <path d="m315.996094 283.066406-45.375-131.609375-20.351563 23.273438-50.238281-101.816407v258.671876l54.269531 52.996093zm0 0"></path>
        <path d="m457.011719.5h-21.984375c-25.121094 0-46.363282 16.9375-52.90625 39.992188h-27.675782c-22.050781 0-39.992187 17.9375-39.992187 39.988281s17.941406 39.992187 39.992187 39.992187h102.566407c30.320312 0 54.988281-24.667968 54.988281-54.988281v-9.996094c0-30.320312-24.667969-54.988281-54.988281-54.988281zm0 0"></path>
        <path d="m116.222656 32.445312c-5.507812-18.453124-22.632812-31.945312-42.855468-31.945312h-3.550782c-20.21875 0-37.34375 13.492188-42.851562 31.945312-15.449219 5.429688-26.558594 20.171876-26.558594 37.457032v1.453125c0 21.886719 17.804688 39.691406 39.691406 39.691406h62.992188c21.886718 0 39.691406-17.804687 39.691406-39.691406v-1.453125c0-17.28125-11.101562-32.023438-26.558594-37.457032zm0 0"></path>
      </g>
      <path
        d="m303.292969 246.21875-55.027344 90.550781-46.640625-45.550781-51.339844 37.46875-74.046875-46.484375-58.703125 193.839844h364.996094zm0 0"
        fill="#99503d"
      ></path>
      <path
        d="m303.292969 246.21875-55.027344 90.550781-46.640625-45.550781-1.59375 1.164062v183.660157h182.5zm0 0"
        fill="#804231"
      ></path>
      <path
        d="m457.011719.5h-21.984375c-7.738282 0-15.109375 1.613281-21.800782 4.511719v115.460937h43.785157c30.320312 0 54.988281-24.667968 54.988281-54.988281v-9.996094c0-30.320312-24.667969-54.988281-54.988281-54.988281zm0 0"
        fill="#a4d9f5"
      ></path>
      <path
        d="m116.222656 32.445312c-5.507812-18.453124-22.632812-31.945312-42.855468-31.945312h-1.773438v110.550781h31.492188c21.890624 0 39.695312-17.808593 39.695312-39.695312v-1.449219c0-17.285156-11.101562-32.027344-26.558594-37.460938zm0 0"
        fill="#a4d9f5"
      ></path>
    </Svg>
  );
};

export default Hills;
