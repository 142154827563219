import React from "react";
import styled from "styled-components/macro";
import { flagLabel } from "../../tools/langs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";

const Wrap = styled.div<{ small?: boolean; quiet?: boolean }>`
  margin: 10px 0;
  display: ${({ small }) => (small ? "inline-flex" : "flex")};
  align-items: center;
  padding: ${({ small }) => (small ? "9px 26px" : "15px 26px")};
  /* color: ${({ theme }) => theme.duo.color.primary}; */
  background: white;
  border-radius: 50px;
  border: 1px solid rgba(0, 153, 221, 0.5);
  font-size: 105%;
  ${(p) => (p.quiet ? `color: ${p.theme.duo.color.textGrey}` : "")}
`;
const SExpand = styled(ExpandMoreIcon)`
  margin-left: auto;
  position: relative;
  right: -8px;
`;

type Props = {
  children?: React.ReactNode;
  lang?: string | null;
  small?: boolean;
  style?: React.CSSProperties;
};

const LangButton: React.FC<Props> = ({ lang, small, children, style }) => {
  const { t } = useTranslation();

  return (
    <Wrap small={small} quiet={!children && !lang} style={style}>
      {children ? children : lang ? flagLabel(lang) : ` 🏳️ ${t("Select a language")}`}
      <SExpand />
    </Wrap>
  );
};

export default LangButton;
