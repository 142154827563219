import React from "react";
import { Trans } from "react-i18next";
import styled from "styled-components/macro";
import { Button } from "@mui/material";
import { useSeeHint } from "components/hint/Hint";
import WandIcon from "icons/WandIcon";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import ExtensionIcon from "@mui/icons-material/Extension";

export const TRYMANUALHINT = "tryManual";

const SWandIcon = styled(WandIcon)`
  width: 100px;
  height: 100px;
`;
const List = styled.div`
  margin: 0 auto 15px;
  width: fit-content;
`;
const Li = styled.div`
  font-size: 14px;
  line-height: 1.35em;
  text-align: left;
  display: flex;
  align-items: center;
  margin: 8px 0;
  svg {
    margin-right: 8px;
    color: ${({ theme }) => theme.duo.color.primary};
  }
`;

type Props = {};

const AddingManually: React.FC<Props> = () => {
  const seeHint = useSeeHint();

  return (
    <>
      <div />
      <div />
      <SWandIcon />
      <div />
      <p>
        <Trans parent="strong">You can save your own phrases</Trans>
      </p>
      <List>
        <Li>
          <AddCircleIcon />
          <Trans>Manually</Trans>
        </Li>
        <Li>
          <MobileScreenShareIcon />
          <Trans>By "sharing" text from your phone</Trans>
        </Li>
        <Li>
          <ExtensionIcon />
          <Trans>From Chrome extension</Trans>
        </Li>
      </List>
      <div>
        <Trans>So you never forget the new words you come across!</Trans>
      </div>
      <div />
      <Button color="primary" variant="contained" onClick={() => seeHint(TRYMANUALHINT)}>
        <Trans>Continue</Trans>
      </Button>
      <div />
      <div />
      <div />
    </>
  );
};

export default AddingManually;
