import Flames from "components/fire/Flames";
import React from "react";
import styled, { css } from "styled-components/macro";
import FlameBg from "./FlameBg";
import FlameCircle, { PROGRESS_TIME } from "./FlameCircle";
import CountUp from "react-countup";
import FlameDead from "./FlameDead";
import { getLevel } from "../levelsHelper";
import { useDebounce, usePreviousDistinct } from "react-use";
import { strengthFn } from "components/fire/FireStats";
import { GardenXps } from "../Garden";
import { dispatchEvent, EVENT } from "tools/events";

const Wrap = styled.div<{ dead?: boolean }>`
  position: absolute;
  left: 154px;
  top: 82px;
  display: flex;
  flex-direction: column;
  ${({ dead }) =>
    dead &&
    css`
      filter: saturate(0);
    `}
  width: 36px;
`;
const SFlames = styled(Flames)`
  margin: 7px auto;
`;
const Numbers = styled.div<{ $bold: boolean }>`
  font-size: 10px;
  line-height: 1em;
  color: ${({ theme }) => theme.duo.color.flame};
  position: relative;
  top: -1px;
  border-radius: 10px;
  white-space: nowrap;
  font-weight: ${({ $bold }) => ($bold ? "bold" : "normal")};
  font-size: ${({ $bold }) => ($bold ? "12px" : "10px")};
`;

type Props = {
  animateMax?: boolean;
  dead?: boolean;
  xps: GardenXps;
  nextItem?: React.ReactNode;
  className?: string;
  numberInside?: number;
};

const FlameProgress: React.FC<Props> = ({ animateMax, dead, xps, className, numberInside }) => {
  const [debouncedXps, setDebouncedXps] = React.useState(xps);
  useDebounce(() => setDebouncedXps(xps), PROGRESS_TIME, [xps]);
  const animatedNumbers = !!animateMax || debouncedXps.total !== xps.total;

  const { progressXps, nextLevelXps } = getLevel(xps.total);
  const prevProgress = usePreviousDistinct(animateMax ? 0 : progressXps || 0);
  const prevNextLevelXps = usePreviousDistinct(nextLevelXps);
  const startCounterOn = prevProgress !== undefined ? prevProgress : progressXps || 0;

  return (
    <Wrap dead={dead} onClick={() => dispatchEvent(EVENT.openNextItemDialog)} className={className}>
      {nextLevelXps !== null && <FlameCircle perc={animateMax ? 100 : (progressXps / nextLevelXps) * 100} />}
      {dead ? (
        <FlameDead />
      ) : (
        <SFlames strength={numberInside ? strengthFn(xps.total) : xps.today || 1} xps={numberInside || null} />
      )}
      <FlameBg className="hideOnAnimation" />
      {nextLevelXps !== null && progressXps != null && (
        <Numbers className="hideOnAnimation" $bold={animatedNumbers}>
          <CountUp
            delay={PROGRESS_TIME / 1000}
            start={startCounterOn}
            end={animateMax ? prevNextLevelXps || 0 : progressXps}
          />{" "}
          / {animateMax ? prevNextLevelXps || 0 : nextLevelXps}
        </Numbers>
      )}
    </Wrap>
  );
};

export default FlameProgress;
