import ReactHtmlParser, { convertNodeToElement, Transform } from "react-html-parser";
import React, { ReactElement } from "react";
import Speech from "components/speech/Speech";
import styled from "styled-components/macro";
import Contextor from "root/library/source/contextor/Contextor";
import innerText from "react-innertext";
import UpSize from "root/main/components/UpSize";

type HtmlParserOptions = {
  grammar?: {
    grammarLang: string;
    targetLang: string;
  };
};

const Example = styled.div`
  padding: 10px 0;
`;
const ExampleText = styled.span`
  border-bottom: 2px dotted ${({ theme }) => theme.duo.color.lightBlue}55;
`;
const Wrap = styled.div`
  display: inline;
  ul.examples-list {
    list-style-type: none;
    padding-left: 7px;
  }
`;

const speechExample = (exampleElement: ReactElement, exampleLang: string, grammarLang: string): ReactElement => {
  const speechText = innerText(exampleElement);

  return (
    <Example key={Math.random()}>
      <Speech text={speechText} lang={exampleLang} small />
      {exampleLang === grammarLang ? (
        <ExampleText>{exampleElement}</ExampleText>
      ) : (
        <UpSize lang={exampleLang} ratio={0.5}>
          <Contextor key={Math.random()} style={{ display: "inline" }}>
            <ExampleText>{exampleElement}</ExampleText>
          </Contextor>
        </UpSize>
      )}
    </Example>
  );
};

const transformNode: Transform = function (this: HtmlParserOptions, node, index: number): ReactElement | void | null {
  // transform top level text nodes into <p>
  if (node.type === "text" && node.parent === null) {
    const paragraphs: string[] = node.data?.split("\n\n") || [];
    return (
      <span key={Math.random()}>
        {paragraphs.map((text, i) => (
          <p key={i}>{text}</p>
        ))}
      </span>
    );
  }

  // skip example translations (for now, it can become handy in the future)
  if (node.name === "trans") {
    return null;
  }

  // remove bullets for UL which contains examples (instead of bullets there will be the Speech icons)
  if (node.name === "ul") {
    const children = node.children;
    if (!children || !children.length) return null; // empty ul => skip node

    const li = children.find((child) => child.type === "tag" && child.name === "li");
    if (!li || !li.children || !li.children.length) return null; // ul without li => skip node

    const example = li.children.find((child) => child.type === "tag" && child.name === "example");
    if (!example) return; // ul without example => ACCEPT node

    // ul with example => ACCEPT node and add class to it
    node.attribs = { class: "examples-list" };
    return convertNodeToElement(node, index, transformNode.bind(this));
  }

  // transform <example> to Speech, but also handle the case when the whole UL is in <example>
  if (node.name === "example") {
    if (!node.children?.length) return null; // skip empty examples

    // <example><ul>...</ul> => distribute <example> inside all <li>
    const nestedUl = node.children.find((child) => child.name === "ul");
    if (nestedUl) {
      return (
        <ul className="examples-list" key={index}>
          {nestedUl.children
            ?.filter((child) => child.name === "li")
            .map((child, index) => {
              child.name = "span";
              const exampleElement = convertNodeToElement(child, index, transformNode.bind(this));

              return (
                <li key={index}>
                  {speechExample(exampleElement, this.grammar!.targetLang, this.grammar!.grammarLang)}
                </li>
              );
            })}
        </ul>
      );
    }

    // <example>text</example> => Speech
    node.name = "span";
    const exampleElement = convertNodeToElement(node, index, transformNode.bind(this));
    return speechExample(exampleElement, this.grammar!.targetLang, this.grammar!.grammarLang);
  }
};

export function htmlParser(htmlText: string, options?: HtmlParserOptions): ReactElement {
  let renderedGrammar: React.ReactNode = null;

  if (options?.grammar?.grammarLang && options?.grammar?.targetLang) {
    renderedGrammar = ReactHtmlParser(htmlText, { transform: transformNode.bind(options) });

    if (options.grammar.grammarLang === options.grammar.targetLang) {
      renderedGrammar = (
        <UpSize lang={options.grammar.targetLang} ratio={0.5} key={options.grammar.targetLang}>
          <Contextor>{renderedGrammar}</Contextor>
        </UpSize>
      );
    }
  } else {
    renderedGrammar = ReactHtmlParser(htmlText);
  }

  return <Wrap key={Math.random()}>{renderedGrammar}</Wrap>;
}
