import React from "react";
import { usePreLoginQuery } from "../../auth/PreLogin";
import { Grid } from "@mui/material";
import Flames from "../../../components/fire/Flames";
import styled from "styled-components/macro";

const SImg = styled.img`
  width: 20px;
  height: 20px;
`;

type Props = {
  deviceId: string;
};

export const AdminDeviceUsers: React.FC<Props> = ({ deviceId }) => {
  const { data } = usePreLoginQuery(deviceId);

  return (
    <>
      {data?.users?.map((user) => (
        <div key={user.email}>
          <Grid container>
            <Grid item xs={10}>
              {user.email}
              {user.subscription && <SImg src="https://cdn.duocards.com/svg/crown.svg" />}-{" "}
              {user.lastLoginAt && <>{new Date(user.lastLoginAt).toLocaleDateString()}</>}
            </Grid>
            <Grid item xs={2}>
              <Flames strength={5} xps={user.flamesCount} />
            </Grid>
          </Grid>
        </div>
      ))}
    </>
  );
};
