import { useSourceQuery } from "queries/sources/sourceQuery";
import React from "react";
import { flag } from "tools/langs";

type Props = {
  lang: string;
  sourceUrl: string;
};
const UnreviewedCount: React.FC<Props> = ({ lang, sourceUrl }) => {
  const src = useSourceQuery(sourceUrl, { back: lang });

  const unreviewed = !src?.sCards ? "--" : [...src?.sCards].reverse().findIndex((c) => !c.back?.translated);

  return (
    <span style={{ whiteSpace: "nowrap", marginRight: "10px" }}>
      {flag(lang)} {unreviewed}
    </span>
  );
};

export default UnreviewedCount;
