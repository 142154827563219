import { MenuItem, Checkbox, Button } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";
import { Trans, useTranslation } from "react-i18next";
import DialogWindow from "../other/DialogWindow";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation, useQuery } from "relay-hooks";
import { useViewerQuery, useViewerDecks } from "../../queries/viewerQuery";
import Loader from "../other/Loader";
import AzureIcon from "../../icons/AzureIcon";
import GoogleIcon from "../../icons/GoogleIcon";
import FemaleIcon from "../../icons/FemaleIcon";
import MaleIcon from "../../icons/MaleIcon";
import { xor } from "lodash";
import { SpeechForVoice } from "./Speech";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import { VoicePickerItemVoicesQuery } from "./__generated__/VoicePickerItemVoicesQuery.graphql";
import { useNavigate } from "react-router";
import { usePrevious } from "react-use";
import StarIcon from "../other/StarIcon";
import { dexie } from "dexieDB/dexie";
import { SUBSCRIPTION } from "../../root/profile/ProfileRouter.config";

const ttsVoicesQL = graphql`
  query VoicePickerItemVoicesQuery($lang: String!) {
    ttsVoices(lang: $lang) {
      voices {
        id
        name
        gender
        type
        platform
      }
      sampleTexts
    }
  }
`;
const saveVoicesQL = graphql`
  mutation VoicePickerItemSaveQuery($deckId: ID!, $voices: [String!]!) {
    saveVoices(deckId: $deckId, voices: $voices) {
      voices {
        id
      }
    }
  }
`;

const Wrap = styled.div`
  min-width: 200px;
  min-height: 100px;
`;
const Label = styled.label`
  display: flex;
  align-items: center;
  padding-right: 10px;
  svg {
    width: 15px;
    height: 15px;
  }
`;
const Li = styled.div`
  display: flex;
  align-items: center;
`;
const SCheckbox = styled(Checkbox)`
  padding: 10px 3px 8px 7px;
  &.Mui-disabled {
    color: #0002;
  }
`;
const Voices = styled.div`
  max-height: 60vh;
  overflow: auto;
`;
const Title = styled.div`
  font-size: ${({ theme }) => theme.duo.fontSize.small};
  line-height: 1.3em;
  font-weight: bold;
  text-align: center;
`;
const IconWrap = styled.div`
  position: relative;
  height: 24px;
`;
const SStarIcon = styled(StarIcon)`
  top: -3px;
  left: -5px;
`;

type Props = {
  onDone: VoidFunction;
  lang?: string;
};

const VoicePickerItem: React.FC<Props> = ({ onDone, lang }) => {
  const { viewer, noPayments } = useViewerQuery();
  const { deck } = useViewerDecks();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<string[]>(
    (viewer?.subscription && (!lang || deck?.front === lang) && deck?.voices?.map((v) => v.id)) || []
  );
  const [lastClicked, setLastClicked] = React.useState<string>();
  const { t } = useTranslation();
  const [saveVoices] = useMutation(saveVoicesQL);
  const navigate = useNavigate();

  if (!lang && !deck) throw Error("VoicePickerItem without lang and deck");

  const { data, isLoading } = useQuery<VoicePickerItemVoicesQuery>(
    ttsVoicesQL,
    { lang: lang || deck?.front || "" },
    { skip: !open }
  );
  const prevData = usePrevious(data);
  const allVoices = data?.ttsVoices?.voices || [];
  const neuralVoices = allVoices.filter((v) => v.type === "Neural");
  const voices = neuralVoices.length > 2 ? neuralVoices : allVoices;
  const sampleTexts = data?.ttsVoices?.sampleTexts || [];

  React.useEffect(() => {
    if (!prevData && data && !selected.length) setSelected([data.ttsVoices.voices[0].id]);
  }, [data, prevData, selected]);

  if (!viewer || (lang && lang !== deck?.front)) return null;

  return (
    <>
      <MenuItem onClick={() => setOpen(true)}>
        <IconWrap>
          <SStarIcon />
          <RecordVoiceOverIcon />
        </IconWrap>
        &nbsp;
        <Trans>Voices</Trans>
      </MenuItem>

      <DialogWindow
        wide
        title={<Trans parent={Title}>Select up to three voices which will be rotating</Trans>}
        open={open}
        onClose={() => {
          setOpen(false);
          onDone();
        }}
      >
        <Wrap>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Voices>
                {voices.map((voice, i) => (
                  <Li key={voice.id}>
                    <div style={{ position: "relative" }}>
                      {!!i && <SStarIcon style={{ top: 5, right: -3, left: "auto" }} />}
                      <SCheckbox
                        checked={selected.includes(voice.id)}
                        disabled={selected.length >= 3 && !selected.includes(voice.id)}
                        onChange={() =>
                          xor(selected, [voice.id]).length > 3
                            ? alert(t("Select up to three voices which will be rotating"))
                            : setSelected(xor(selected, [voice.id]))
                        }
                      />
                    </div>
                    <Label key={voice.id}>
                      <SpeechForVoice
                        text={lastClicked === voice.id ? sampleTexts[1] : sampleTexts[0]}
                        lang={lang || deck?.front || ""}
                        voiceId={voice.id}
                        hash={voice.id}
                        onClick={() => setLastClicked(lastClicked === voice.id ? undefined : voice.id)}
                      />
                      {voice.gender === "MALE" ? <MaleIcon /> : <FemaleIcon />}&nbsp; {voice.name} &nbsp;
                      {voice.platform === "azure" ? <AzureIcon /> : <GoogleIcon style={{ width: 12, height: 12 }} />}
                    </Label>
                  </Li>
                ))}
              </Voices>

              <Button
                color="primary"
                variant="contained"
                style={{ marginTop: 6 }}
                onClick={() => {
                  if (viewer?.subscription || noPayments()) {
                    saveVoices({
                      variables: { deckId: deck?.id, voices: selected },
                      onCompleted: () => dexie.cardsData.clear()
                    });
                    setOpen(false);
                    onDone();
                  } else {
                    navigate(SUBSCRIPTION.url());
                  }
                }}
              >
                {viewer?.subscription || noPayments() ? <Trans>Save</Trans> : <Trans>Subscribe</Trans>}
              </Button>
            </>
          )}
        </Wrap>
      </DialogWindow>
    </>
  );
};

export default VoicePickerItem;
