import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "./main/Main";
import Learn from "./learn/Learn";
import CardNew from "./card/CardNew";
import DeckForm from "./deck/DeckForm";
import { queryString } from "tools/url";
import { MAIN } from "../RootRouter.config";

export const LEARN = {
  relative: "learn",
  url: (qs?: { popupKnown?: boolean }) => MAIN.url() + "/" + LEARN.relative + queryString(qs),
  popupKnownParam: "popupKnown" as "popupKnown"
};
export const CARD = {
  relative: "card",
  relativeTerm: `card/:term`, // used in chrome-extension
  url: (term?: string) => MAIN.url() + "/" + CARD.relative + (term ? `/${term}` : ``),
  termUrlParam: "term" as "term"
};
export const DECK = {
  relative: "deck",
  url: (id?: string) => MAIN.url() + "/" + DECK.relative + queryString({ id }),
  idParam: "id"
};

const MainRouter: React.FC<{}> = () => {
  return (
    <Routes>
      <Route path={LEARN.relative} element={<Learn />} />

      <Route path={CARD.relative} element={<CardNew />} />
      <Route path={CARD.relativeTerm} element={<CardNew />} />

      <Route path={DECK.relative} element={<DeckForm />} />

      <Route path="*" element={<Main />} />
    </Routes>
  );
};

export default MainRouter;
