import React from "react";
import { Trans } from "react-i18next";
import { TutorialFC } from "../TutorialContent";
import { Button } from "@mui/material";
import { useSeeHint } from "components/hint/Hint";
import { TUTORIALDONE_SHARED_HINT } from "sharedJs__generated/constants";

export const SWIPINGDONE = "swipingDone";
export const TUTORIALDONE = TUTORIALDONE_SHARED_HINT;

const Verdict: TutorialFC = () => {
  const seeHint = useSeeHint();

  return (
    <>
      <p style={{ fontSize: "34px" }}>🙌</p>
      <Trans parent="p">Good job!</Trans>
      <Trans parent="p">Let's go through the last few things.</Trans>
      <Button variant="contained" color="primary" onClick={() => seeHint(SWIPINGDONE)}>
        <Trans>great, let's go</Trans>
      </Button>{" "}
    </>
  );
};

export default Verdict;
