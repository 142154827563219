import { useGetState, useSetState } from "components/ReduxProvider";
import { maxBy } from "lodash";
import { useViewerDecks } from "queries/viewerQuery";
import React from "react";
import { usePrevious } from "react-use";

const DeckSetter = () => {
  const { deck, decks } = useViewerDecks();
  const setLibTopic = useSetState("libTopic");
  const setDeckId = useSetState("deckId");

  const prevDecks = usePrevious(decks);
  const newDeckCreated = React.useMemo(
    () =>
      decks &&
      prevDecks &&
      decks.length >= prevDecks.length &&
      decks.filter((deck) => prevDecks.find((prevDeck) => prevDeck.id === deck.id)).length > 0,
    [decks, prevDecks]
  );

  React.useEffect(() => {
    // if new deck is created it will pick it as actual deck
    if (newDeckCreated) {
      const newDeck = decks!.find((d) => !prevDecks!.find((pd) => pd.id === d.id));
      if (newDeck) {
        setDeckId(newDeck.id);
        setLibTopic(newDeck.front);
      }
    }
  }, [prevDecks, decks, setDeckId, newDeckCreated, setLibTopic]);

  React.useEffect(() => {
    // if deck is not set, pick first
    if (!deck && decks && decks.length && !newDeckCreated) {
      const firstDeck = maxBy(decks, (d) => d.stats.unknown);
      if (firstDeck) {
        setDeckId(firstDeck.id);
        setLibTopic(firstDeck.front);
      }
    }
  }, [decks, deck, setDeckId, newDeckCreated, setLibTopic]);

  const prevDeckId = usePrevious(deck?.id);
  const libTopic = useGetState("libTopic");
  React.useEffect(() => {
    if (
      // if deck changes => changes libTopic accordingly
      deck &&
      (prevDeckId || typeof libTopic !== "string") &&
      prevDeckId !== deck?.id &&
      deck.front !== libTopic
    ) {
      setLibTopic(deck.front);
    }
  }, [deck, libTopic, prevDeckId, setLibTopic]);

  return null;
};

export default DeckSetter;
