import React from "react";
import styled from "styled-components/macro";
import { Paper } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import DoneIcon from "@mui/icons-material/Done";
import { Trans } from "react-i18next";
import { useTranslateQuery } from "../../queries/translateQuery";
import { useParams } from "react-router";
import { TRYSHARINGWORDS } from "../../sharedJs__generated/constants";
import { isIos } from "../../tools/device";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const In = styled.div`
  padding: 20px;
`;
const Words = styled.div`
  font-size: 19px;
  line-height: 1.3em;
  font-family: "Times New Roman", Times, serif;
  margin: 30px;
  text-align: center;
`;
const PaperFix = ({ hide, ...p }) => <Paper {...p} />;
const Note = styled(PaperFix)<{ hide?: boolean }>`
  padding: 5px 20px;
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  background-color: ${({ hide }) => (hide ? "yellow" : "white")};
  transition:
    opacity 1.5s,
    background-color 1.5s;
  svg {
    margin-right: 4px;
    color: ${({ theme }) => theme.duo.color.primary};
  }
  p {
    display: flex;
    align-items: center;
  }
`;
const Programmer = styled.div`
  color: green;
  font-size: 90%;
  margin: 20px;
  position: absolute;
`;

const TrySharing = () => {
  const [contexted, setContexted] = React.useState(false);
  const [inNote, setInNote] = React.useState(false);
  const params = useParams<any>();

  let textToTry = useTranslateQuery({
    from: TRYSHARINGWORDS,
    fromLang: "en",
    toLang: params.lang
  })?.translate;
  if (params.lang === "en") {
    textToTry = TRYSHARINGWORDS;
  }

  const handleSelection = () => {
    const selection = window?.getSelection();
    const selectedText = selection?.toString();
    if (selectedText) {
      setContexted(true);
    }
  };

  return (
    <Wrap onContextMenu={handleSelection} onTouchEnd={() => isIos() && handleSelection()}>
      <In>
        <Note>
          <p onContextMenu={() => setInNote(true)}>
            <TouchAppIcon />
            <Trans>select any word by long touch</Trans>
          </p>
        </Note>
        <Words>{textToTry?.split("\n").map((w, i) => <p key={i}>{w}</p>)}</Words>
        <Note hide={!contexted}>
          <p>
            <MenuIcon />
            <Trans>pick "share" from the options</Trans>
          </p>
          <p>
            <DoneIcon />
            <Trans>and choose DuoCards</Trans>
          </p>
        </Note>
        {inNote && (
          <Programmer>
            Yes, selecting the description will work too. Aren't you programmer or something? {"🙂"}
          </Programmer>
        )}
      </In>
    </Wrap>
  );
};

export default TrySharing;
