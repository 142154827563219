import React, { ComponentProps } from "react";
import styled, { css, keyframes } from "styled-components/macro";
import CrownIcon from "./CrownIcon";

const blinkingKF = keyframes`
    0%,94% {  opacity: 1; }
    95%,100% { opacity: 0; }`;
const movingKF = keyframes`
    0% { transform: translateX(0); }
    25% { transform: translateX(-15px); }
    75% { transform: translateX(20px); }`;
const rotatingKF = keyframes`
    0%,50% { transform: rotate(0deg); }
    25% { transform: rotate(3deg); }
    75% { transform: rotate(8deg); }`;

const Svg = styled.svg`
  width: 45px;
  height: 45px;
  transform: rotateY(180deg);
`;
const Eye = styled.g`
  animation: ${blinkingKF} 5s infinite;
`;
const Leg = styled.g<{ moving?: boolean }>`
  ${({ moving }) =>
    moving &&
    css`
      animation: ${movingKF} 500ms infinite;
    `};
`;
const Head = styled.g<{ moving?: boolean }>`
  transform-origin: center;
  ${({ moving }) =>
    moving &&
    css`
      animation: ${rotatingKF} 1000ms infinite;
    `};
`;

type Props = ComponentProps<typeof Svg> & {
  walking?: boolean;
  className?: string;
  pink?: boolean;
  crown?: boolean;
};

const MammothIcon: React.FC<Props> = ({ walking, className, pink, crown = false, ...props }) => {
  let brown1 = "#d76900";
  let brown2 = "#f5871e";
  if (pink) {
    brown1 = "#e44ea7";
    brown2 = "#fe73c5";
  }
  return (
    <Svg
      className={className}
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g>
        <Leg moving={walking}>
          <path d="m344 320h64v80h-64z" fill={brown1} />
          <path d="m192 236h64v164h-64z" fill={brown1} />
        </Leg>
        <g>
          <path
            d="m496 448h-464c-4.418 0-8-3.582-8-8v-32c0-4.418 3.582-8 8-8h464c4.418 0 8 3.582 8 8v32c0 4.418-3.582 8-8 8z"
            fill="#69646966"
          />
        </g>
        <path
          d="m328 96c0-17.673-14.327-32-32-32h-32c-17.673 0-32 14.327-32 32v304h64v-64c0-8.836 7.163-16 16-16h96c8.837 0 16 7.164 16 16v64h64v-144c0-88.366-71.635-160-160-160z"
          fill={brown2}
        />
        <Head moving={walking}>
          <g>
            <path
              d="m144 224c-4.418 0-8 3.582-8 8 0 30.928-25.072 56-56 56-30.928 0-56-25.072-56-56 0-4.418-3.582-8-8-8-4.418 0-8 3.582-8 8v8c0 44.183 35.817 80 80 80 44.183 0 80-35.817 80-80v-8c0-4.418-3.582-8-8-8z"
              fill="#b9af9b"
            />
          </g>
          <g>
            <path
              d="m256 128h-24v-32h-96c-17.673 0-32 14.327-32 32v192c0 35.346 28.654 64 64 64 26.51 0 48-21.49 48-48h-32c0 4.418-3.582 8-8 8s-8-3.582-8-8v-80h64v-64c17.673 0 32-14.327 32-32v-24c0-4.418-3.582-8-8-8z"
              fill={brown1}
            />
          </g>
          <g>
            <path
              d="m176 224c-4.418 0-8 3.582-8 8 0 30.928-25.072 56-56 56-30.928 0-56-25.072-56-56 0-4.418-3.582-8-8-8-4.418 0-8 3.582-8 8v8c0 44.183 35.817 80 80 80 44.183 0 80-35.817 80-80v-8c0-4.418-3.582-8-8-8z"
              fill="#f0e1cd"
            />
          </g>
          <Eye>
            <circle cx="168" cy="184" fill="#4b464b" r="8" />
          </Eye>
          <g>
            <path
              d="m200 64h-32c-17.673 0-32 14.327-32 32 0 4.418 3.582 8 8 8s8-3.582 8-8c0 4.418 3.582 8 8 8s8-3.582 8-8c0 4.418 3.582 8 8 8s8-3.582 8-8c0 4.418 3.582 8 8 8s8-3.582 8-8c0 4.418 3.582 8 8 8s8-3.582 8-8c0 4.418 3.582 8 8 8s8-3.582 8-8c0-17.673-14.327-32-32-32z"
              fill="#4b464b"
            />
          </g>
          {crown && (
            <svg width={100} height={100} x={135} y={-15}>
              <CrownIcon />
            </svg>
          )}
        </Head>
      </g>
    </Svg>
  );
};

export default MammothIcon;
