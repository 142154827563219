import React from "react";
import { useEffectOnce } from "react-use";

type Props = { seconds: number };

const Countdown: React.FC<Props> = ({ seconds }) => {
  const [remaining, setRemaining] = React.useState(seconds);
  const hrs = Math.floor(remaining / 60 / 60);
  const min = Math.floor(remaining / 60 - hrs * 60);
  const sec = remaining - hrs * 60 * 60 - min * 60;

  useEffectOnce(() => {
    const int = setInterval(() => {
      setRemaining((remaining) => {
        return remaining > 0 ? remaining - 1 : 0;
      });
    }, 1000);

    return () => {
      clearInterval(int);
    };
  });

  return <>{`${hrs}:${min < 10 ? "0" : ""}${min}:${sec < 10 ? "0" : ""}${sec}`}</>;
};

export default Countdown;
