import React from "react";
import styled, { css } from "styled-components/macro";
import { useViewerQuery, useViewerXps } from "../../queries/viewerQuery";
import RollingMenu from "components/other/RollingMenu";
import { IconButton } from "@mui/material";
import FireStatsGraph from "./FireStatsGraph";
import { Trans } from "react-i18next";
import Flames from "./Flames";
import Streak from "./Streak";
import { useEvent } from "react-use";
import { EVENT } from "tools/events";
import {
  STREAK_FREEZE_LIMIT,
  STREAK_FREEZE_LIMIT_SUBSCRIBER,
  STREAK_FREEZE_QUEST,
  STREAK_FREEZE_XPS
} from "../../sharedJs__generated/constants";
import StreakFreezeDialog from "./dialog/StreakFreezeDialog";
import IceCube from "./IceCube";
import PetableMammoth from "components/mammoth/PetableMammoth";
import FADuotoneIcon from "icons/FADuotoneIcon";

const SIconButton = styled(IconButton)<{ open: boolean }>`
  svg {
    color: ${({ theme }) => theme.duo.color.textGrey};
  }
`;
const In = styled.div`
  max-width: ${({ theme }) => theme.duo.maxWidth.narrow};
  margin: 0 auto;
`;
const Middle = styled.div`
  padding: 26px 0;
  border-top: 1px solid ${({ theme }) => theme.duo.color.grey};
  margin: 0 20px;
`;
const TitleCss = css`
  min-width: 60%;
  padding: 0 8px 0 0;
  text-align: right;
`;
const Title = styled.div`
  ${TitleCss};
`;
const TitleUnderline = styled.div`
  ${TitleCss};
  text-decoration: underline;
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 8px;
`;
const SFlames = styled(Flames)`
  transform: scale(1.2);
  margin: 0 15px 0 10px;
  display: inline-block;
`;
const FreezeMsgWrap = styled.div`
  text-align: right;
  line-height: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const strengthFn = (xps) => (xps > 10 ? 4 : Math.ceil(xps / 2.5));

type Props = {};

const FireStats: React.FC<Props> = () => {
  const [open, setOpen] = React.useState(false);
  const { xps } = useViewerXps();
  const { viewer, noPayments, hintWasSeen } = useViewerQuery();
  const [streakFreezeDialogOpen, setStreakFreezeDialogOpen] = React.useState(false);

  const nonSubscriber = !viewer?.subscription && !noPayments();
  const openFireStats = React.useCallback(() => setOpen(true), []);
  useEvent(EVENT.openFireStats, openFireStats);

  if (!xps) return null;

  return (
    <>
      <StreakFreezeDialog open={streakFreezeDialogOpen} setOpen={setStreakFreezeDialogOpen} />
      <SIconButton open={open} onClick={() => setOpen(true)}>
        <FADuotoneIcon icon="chartSimple" active={open} />
      </SIconButton>

      <RollingMenu open={open} onClose={() => setOpen(false)}>
        <In>
          <FireStatsGraph days={xps.days} />

          <Middle>
            <Flex>
              <Title>
                <Trans>Total XP</Trans>:
              </Title>
              &nbsp; <SFlames strength={strengthFn(xps.total)} xps={xps.total} noZZ />
            </Flex>
            <Flex>
              <Title>
                <Trans>Current daily streak</Trans>:
              </Title>
              <Streak days={xps.currentStreak} />
            </Flex>
            {hintWasSeen(STREAK_FREEZE_QUEST) && (
              <>
                <Flex>
                  <TitleUnderline onClick={() => setStreakFreezeDialogOpen(true)}>
                    <Trans>Series freeze</Trans>:
                  </TitleUnderline>
                  <IceCube coldness={3} number={viewer?.streakFreeze ?? 0} />
                </Flex>
                {(viewer?.streakFreeze ?? 0) <
                  (nonSubscriber ? STREAK_FREEZE_LIMIT : STREAK_FREEZE_LIMIT_SUBSCRIBER) && (
                  <PetableMammoth
                    style={{ margin: "25px -35px 0" }}
                    noPet
                    gig={
                      <FreezeMsgWrap>
                        <Trans>
                          Learn another
                          <SFlames
                            strength={strengthFn(STREAK_FREEZE_XPS - (viewer?.streakFreezeXps ?? 0))}
                            xps={STREAK_FREEZE_XPS - (viewer?.streakFreezeXps ?? 0)}
                            noZZ
                          />
                          and you gain
                          <IceCube coldness={3} number={1} style={{ marginLeft: 4 }} />
                        </Trans>
                      </FreezeMsgWrap>
                    }
                  />
                )}
              </>
            )}
          </Middle>
        </In>
      </RollingMenu>
    </>
  );
};

export default FireStats;
