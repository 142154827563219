import { useState } from "react";
import styled from "styled-components/macro";
import { Trans } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { ShowMoreContent } from "./subscription-content/ShowMoreContent";
import { ShowLessContent } from "./subscription-content/ShowLessContent";

const Wrap = styled.div`
  text-align: center;
  table {
    width: 330px;
    display: block;
    margin: 18px auto 0;
    border-collapse: collapse;
    border-radius: 6px;
    overflow: hidden;
    font-size: 90%;
    padding: 0px 15px;
  }
  td {
    padding: 8px 10px;
  }
  .icon {
    padding-left: 2px;
    padding-right: 0;
    text-align: center;
    vertical-align: center;
    color: ${({ theme }) => theme.duo.color.primary};
    svg {
      width: 24px;
      height: 24px;
      scale: 90%;
      position: relative;
      top: 3px;
    }
  }
  .text {
    width: 100%;
    text-align: left;
    padding-left: 2px;
    line-height: 1.4em;
  }
  .free {
    white-space: nowrap;
  }
  .premium {
    font-weight: bold;
    color: #090;
    width: 12px;
    height: 8px;
    line-height: 1.1em;
  }
  .more {
    border-top: 1px solid ${({ theme }) => theme.duo.color.grey};
  }
`;
const SMoreButton = styled(Button)`
  color: #09d;
  text-align: center;
  font-family:
    Noto Sans,
    Arial;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;
`;

const SubscriptionText = () => {
  const [expand, setExpand] = useState(false);
  const expandAndScroll = () => {
    setExpand(true);
    setTimeout(() => {
      const el = document.getElementById("subPricesScrollId");
      if (el !== null && el.scrollIntoView) el.scrollIntoView({ behavior: "smooth", block: "start" });
    });
  };

  return (
    <Wrap>
      <table>
        <tbody>
          <ShowLessContent />
          {expand && <ShowMoreContent />}
        </tbody>
      </table>

      {!expand && (
        <SMoreButton variant="text" startIcon={<ExpandMoreIcon />} onClick={expandAndScroll}>
          <Trans>Show more perks</Trans>
        </SMoreButton>
      )}
    </Wrap>
  );
};

export default SubscriptionText;
