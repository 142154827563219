import React from "react";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";
import DialogWindow from "../../../components/other/DialogWindow";
import CardSorter from "./CardSorter";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { graphql } from "babel-plugin-relay/macro";
import { useQuery } from "relay-hooks";
import FolderIcon from "@mui/icons-material/Folder";
import { FormMoreQuery } from "./__generated__/FormMoreQuery.graphql";
import NoSimIcon from "@mui/icons-material/NoSim";
import HintTooltip from "components/other/HintTooltip";
import { OWNWORDQUEST } from "../main/quests/everySource/OwnWord";
import { useViewerQuery } from "queries/viewerQuery";

export const CATEGORYHINT = "caregorising";

const sourceQL = graphql`
  query FormMoreQuery($sourceId: ID!, $langNative: String) {
    node(id: $sourceId) {
      ... on Source {
        id
        name(langNative: $langNative)
        uri
        private
        kind
        mine
      }
    }
  }
`;

const Wrap = styled.div``;
const Between = styled.div`
  display: flex;
  width: 100%;
  padding: 0 1px 10px;
  margin-top: -30px;
  font-size: ${({ theme }) => theme.duo.fontSize.smallest};
  color: #0006;
  align-items: center;
  svg {
    font-size: 12px;
  }
`;
const Folder = styled.div`
  background: white;
  border: none;
  font-size: 11px;
  color: ${({ theme }) => theme.duo.color.textGrey};
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  box-shadow: 0.5px 0.5px 3px #0002;
  max-width: 130px;
`;
const SrcName = styled.span<{ isSet?: boolean }>`
  margin-left: 3px;
  padding-right: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: ${({ isSet }) => (isSet ? "inherit" : "italic")};
`;
const SNoSimIcon = styled(NoSimIcon)`
  margin-left: 3px;
`;

type Props = {
  lang: string;
  langNative?: string;
  sourceId?: string;
  values: { sourceId?: string | null; noDeck?: boolean };
  withoutSorter?: boolean;
  onValuesChange: (values: { sourceId?: string | null; noDeck?: boolean }) => void;
  allowNoDeck?: boolean;
  saved?: boolean;
};

const FormMore: React.FC<Props> = ({
  values,
  onValuesChange,
  withoutSorter,
  lang,
  langNative,
  sourceId,
  allowNoDeck,
  saved
}) => {
  const { hintWasSeen } = useViewerQuery();
  const [openSet, setOpenSet] = React.useState(false);
  const { t } = useTranslation();

  const { data: sourceData } = useQuery<FormMoreQuery>(
    sourceQL,
    { sourceId: values.sourceId as string, langNative },
    { skip: !values.sourceId }
  );
  const source = sourceData?.node;

  return (
    <Wrap>
      <DialogWindow open={openSet} onClose={() => setOpenSet(false)} title={`${t("Sort the card into")}:`}>
        <CardSorter
          lang={lang}
          langNative={langNative}
          sourceId={sourceId}
          values={values}
          onDone={({ sourceId, noDeck }) => {
            setOpenSet(false);
            onValuesChange({ sourceId, noDeck });
          }}
          allowNoDeck={allowNoDeck}
        />
      </DialogWindow>

      {!withoutSorter && (
        <Between>
          <HintTooltip
            name={CATEGORYHINT}
            title="You can categorise your cards"
            canOpen={!!saved && hintWasSeen(OWNWORDQUEST)}
          >
            <Folder
              onClick={(e) => {
                setOpenSet(true);
                e.preventDefault();
              }}
            >
              {source?.mine && source?.kind === "set" ? <FolderIcon /> : <FolderOpenIcon />}
              {source?.name && <SrcName isSet={source?.kind === "set"}>{source.name}</SrcName>}
              {values?.noDeck && <SNoSimIcon />}
            </Folder>
          </HintTooltip>
        </Between>
      )}
    </Wrap>
  );
};

export default FormMore;
