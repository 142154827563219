import { MenuItem, Select as SelectMui } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { flag } from "../../../../tools/langs";
import { sourceEditQuery } from "../__generated__/sourceEditQuery.graphql";

const NEW = "new";

const SSelect = styled(SelectMui)`
  margin-left: 8px;
  :before,
  :after {
    border-bottom: none !important;
  }
  .MuiSelect-select {
    background: none;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

type Props = {
  source: sourceEditQuery["response"]["node"];
  langNative: string;
  onNativeCreate: VoidFunction;
  setLangNative: (l: string) => void;
};

const SourceCardsLangs: React.FC<Props> = ({ source, langNative, onNativeCreate, setLangNative }) => {
  const { t } = useTranslation();

  if (!source?.natives || source.multiLang) return <>&nbsp;</>;

  const handleChange = (e) => {
    if (e.target.value === NEW) {
      onNativeCreate();
    } else {
      setLangNative(e.target.value);
    }
  };

  return (
    <SSelect
      readOnly={!source.sCards?.length}
      onClick={() => !source.sCards?.length && alert(t("Add cards before continuing"))}
      value={langNative}
      onChange={handleChange}
    >
      {source?.natives.map((native) => (
        <MenuItem key={native.langNative} value={native.langNative}>
          {flag(native.langNative)}&nbsp;
        </MenuItem>
      ))}
      <MenuItem value={NEW}>
        <AddIcon />
      </MenuItem>
    </SSelect>
  );
};

export default SourceCardsLangs;
