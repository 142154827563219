/**
 * @generated SignedSource<<a5afcc76f6b255b79a6ed3faf414411d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SubscriptionPlatform = "android" | "browser" | "ios" | "%future added value";
export type RefundMutation$variables = {
  transactionId: string;
};
export type RefundMutation$data = {
  readonly refund: {
    readonly subscriptions: ReadonlyArray<{
      readonly family: boolean;
      readonly platform: SubscriptionPlatform;
      readonly storeId: string;
      readonly timeToExpire: number;
      readonly transactionId: string;
    }>;
  } | null;
};
export type RefundMutation = {
  response: RefundMutation$data;
  variables: RefundMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "transactionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "transactionId",
    "variableName": "transactionId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "subscriptions",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "transactionId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeToExpire",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "platform",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "family",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RefundMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "refund",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RefundMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "refund",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8eaf382c83b9681a0757fecc5c3d5371",
    "id": null,
    "metadata": {},
    "name": "RefundMutation",
    "operationKind": "mutation",
    "text": "mutation RefundMutation(\n  $transactionId: String!\n) {\n  refund(transactionId: $transactionId) {\n    subscriptions {\n      transactionId\n      timeToExpire\n      platform\n      storeId\n      family\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6775fabf633b27e9355cb7d7fbb48f75";

export default node;
