import React from "react";
import styled from "styled-components/macro";

const Wrap = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LabelRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
  overflow: hidden;
`;

export const ActiveIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: green;
`;

const UrlText = styled.div`
  font-size: 12px;
  color: grey;
`;

type Props = {
  label: string;
  url: string;
  isActive: boolean;
};

const BaseURLItem = ({ label, url, isActive }: Props) => (
  <Wrap>
    <LabelRow>
      {isActive && <ActiveIndicator />}
      <div>{label}</div>
    </LabelRow>
    <UrlText>{url}</UrlText>
  </Wrap>
);

export default BaseURLItem;
