import { Trans } from "react-i18next";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import UndoIcon from "@mui/icons-material/Undo";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import SignalWifiStatusbarConnectedNoInternet4Icon from "@mui/icons-material/SignalWifiStatusbarConnectedNoInternet4";
import { STREAK_FREEZE_LIMIT_SUBSCRIBER } from "sharedJs__generated/constants";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import MenuBookRoundedIcon from "@mui/icons-material/MenuBookRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSolidMammoth } from "@awesome.me/kit-55349eb283/icons/kit/custom";
import { faPalette, faFileLines } from "@fortawesome/pro-solid-svg-icons";
import FilterIcon from "icons/FilterIcon";
import styled from "styled-components";

const MammothIcon = styled(FontAwesomeIcon)`
  width: 24px !important;
`;
const SFilterIcon = styled(FilterIcon)`
  fill: ${({ theme }) => theme.duo.color.primary};
  width: 18px !important;
  height: 18px !important;
  left: -2px;
`;

export const ShowMoreContent = () => {
  return (
    <>
      <tr>
        <td className="icon">
          <MenuBookRoundedIcon />
        </td>
        <td className="text">
          <Trans parent="span">Grammar</Trans>
        </td>
        <td className="premium" style={{ fontSize: "180%" }}>
          ∞
        </td>
      </tr>
      <tr>
        <td className="icon">
          <MammothIcon icon={faSolidMammoth} />
        </td>
        <td className="text">
          <Trans parent="span">AI messages</Trans>
        </td>
        <td className="premium" style={{ fontSize: "180%" }}>
          ∞
        </td>
      </tr>
      <tr>
        <td className="icon">
          <ViewInArIcon />
        </td>
        <td className="text">
          <Trans parent="span">Series freeze</Trans>
        </td>
        <td className="premium">{STREAK_FREEZE_LIMIT_SUBSCRIBER}</td>
      </tr>
      <tr>
        <td className="icon">
          <RecordVoiceOverIcon />
        </td>
        <td className="text">
          <Trans parent="span">Voices</Trans>
        </td>
        <td className="premium">2+</td>
      </tr>
      <tr>
        <td className="icon">
          <SFilterIcon />
        </td>
        <td className="text">
          <Trans parent="span">Filters</Trans>
        </td>
        <td className="premium">✓</td>
      </tr>
      <tr>
        <td className="icon">
          <UndoIcon />
        </td>
        <td className="text">
          <Trans parent="span">Undo button</Trans>
        </td>
        <td className="premium">✓</td>
      </tr>
      <tr>
        <td className="icon">
          <FontAwesomeIcon icon={faPalette} />
        </td>
        <td className="text">
          <Trans parent="span">Color themes</Trans>
        </td>
        <td className="premium">✓</td>
      </tr>
      <tr>
        <td className="icon">
          <FontAwesomeIcon icon={faFileLines} />
        </td>
        <td className="text">
          <Trans parent="span">Test from known cards</Trans>
        </td>
        <td className="premium">✓</td>
      </tr>
      <tr>
        <td className="icon">
          <SupportAgentOutlinedIcon />
        </td>
        <td className="text">
          <Trans parent="span">Priority support</Trans>
        </td>
        <td className="premium">✓</td>
      </tr>
      <tr>
        <td className="icon">
          <SignalWifiStatusbarConnectedNoInternet4Icon />
        </td>
        <td className="text">
          <Trans parent="span">Offline version</Trans>
        </td>
        <td className="premium">
          <Trans>Soon</Trans>
        </td>
      </tr>
    </>
  );
};
