import { useQuery, useFragment } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { loopQuery } from "./__generated__/loopQuery.graphql";
import { loopQuery_loop$key } from "./__generated__/loopQuery_loop.graphql";
import { useViewerDecks } from "../viewerQuery";
import { useGetState } from "components/ReduxProvider";

const loopFragment = graphql`
  fragment loopQuery_loop on Deck @argumentDefinitions(loopFilter: { type: LoopFilter }) {
    loop(loopFilter: $loopFilter) {
      id
      sCardId
      sBackId
      sourceId
      front
      pronunciation
      back
      hint
      flipped
      loopedAt
      knownAt
      knownUntil
      knownCount
      failCount
      source {
        kind
        course
      }
      svg {
        flatId
        url
      }
    }
  }
`;

const LoopQL = graphql`
  query loopQuery($deckId: ID!, $loopFilter: LoopFilter) {
    node(id: $deckId) {
      ...loopQuery_loop @arguments(loopFilter: $loopFilter)
    }
  }
`;

export const useLoopQuery = () => {
  const { deck } = useViewerDecks();
  const loopFilter = useGetState("loopFilter");

  const { data, retry, isLoading } = useQuery<loopQuery>(
    LoopQL,
    { deckId: deck?.id as string, loopFilter },
    { fetchPolicy: "network-only", skip: !deck?.id }
  );

  const loop = useFragment<loopQuery_loop$key>(loopFragment, data?.node || null);

  return { cards: loop?.loop, retry, isLoading };
};
