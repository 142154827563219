import { graphql } from "babel-plugin-relay/macro";

export const deleteSCardQL = graphql`
  mutation deleteSCardMutation($id: ID!, $backLang: String) {
    deleteSCard(id: $id) {
      id
      sCards(backLang: $backLang) {
        id
      }
    }
  }
`;
