import React from "react";
import styled from "styled-components/macro";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { devicePaddings } from "../../tools/device";

const { top } = devicePaddings();

const OverflowWorkaround = styled.div`
  position: absolute;
  background: white;
  width: 100%;
  height: 100%;
  z-index: 900;
  top: 0;
  left: 0;
  z-index: 1000;
`;
const Wrap = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: ${top + 55}px 0;
  z-index: 1000;
  background: white;
  z-index: 1000;
`;
const SIconButton = styled(IconButton)`
  position: absolute;
  top: ${top + 5}px;
  right: 5px;
  opacity: 0;
  transition: all 0.5s;
`;
const In = styled.div`
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
  height: calc(100% - 0px);
`;

type Props = { onClose: VoidFunction };

const AdPopup: React.FC<Props> = ({ onClose }) => {
  const exitRef: any = React.useRef(null);

  React.useEffect(() => {
    setTimeout(() => exitRef.current && (exitRef.current.style.opacity = 1), 2000);

    ((window as any).adsbygoogle || []).push({});
  }, []);

  return (
    <>
      <OverflowWorkaround />
      <Wrap>
        <SIconButton onClick={onClose} ref={exitRef}>
          <CloseIcon />
        </SIconButton>
        <In>
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-5972734163318470"
            data-ad-slot="2401551100"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </In>
      </Wrap>
    </>
  );
};

export default AdPopup;
