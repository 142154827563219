import { graphql } from "babel-plugin-relay/macro";

export const createSubscriptionQL = graphql`
  mutation createSubscriptionMutation($platform: SubscriptionPlatform!, $debug: String!) {
    createSubscription(platform: $platform, debug: $debug) {
      user {
        subscriptions {
          transactionId
          timeToExpire
          platform
          storeId
          family
        }
      }
      alreadySubscribedBy
    }
  }
`;
