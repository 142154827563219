import React from "react";
import { HeaderLayout } from "../../../components/header/Header";
import Cards from "./Cards";
import Loader from "components/other/Loader";
import { useLoopQuery } from "../../../queries/decks/loopQuery";
import HeaderMenu from "../../../components/header/HeaderMenu";
import { useSeeHint } from "../../../components/hint/Hint";
import Fire from "../../../components/fire/Fire";
import { useBurn, useSeePickedFewCards } from "../../../components/fire/xpHooks";
import LearnNoCards from "./LearnNoCards";
import CardsFilter from "./CardsFilter";
import styled from "styled-components/macro";
import { useGetState, useSetState } from "components/ReduxProvider";
import { useEffectOnce, usePrevious } from "react-use";
import { snackbar } from "tools/events";
import RenewLoopFilter from "root/main/learn/RenewLoopFilter";
import { usePreloadCards } from "./preloading/usePreloadCards";
import { useViewerDecks } from "queries/viewerQuery";
import useAppRating from "hooks/useAppRating";

export const FIRSTLOOPDONE = "firstLoop";

const WrapOut = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 55px;
  width: 100%;
  bottom: 0;
  overflow-y: auto;
  padding: 20px 0;
`;
const WrapIn = styled.div`
  width: 100%;
  margin: auto;
`;

const Top = styled.div`
  display: flex;
  position: relative;
  z-index: 20;
  align-items: center;
`;

const Learn: React.FC<{}> = () => {
  const { cards, isLoading } = useLoopQuery();
  const [lastCardOutUNRELYABLE, setLastCardOut] = React.useState(false);
  const seeHint = useSeeHint();
  const burn = useBurn();
  const loopFilter = useGetState("loopFilter");
  const loopFilterPrev = usePrevious(loopFilter);
  const setLoopFilter = useSetState("loopFilter");
  const savedLoopFilter = useGetState("savedLoopFilter");
  const setSavedLoopFilter = useSetState("savedLoopFilter");
  const { deck } = useViewerDecks();
  const loopFilterDeckId = useGetState("loopFilterDeckId");
  const prevCardsLength = usePrevious(cards?.length);
  const bottomCardActive = useGetState("bottomCardActive");

  useSeePickedFewCards();
  usePreloadCards(cards, deck?.front);

  const showRatingIfNeeded = useAppRating();

  // lastCardOutUNRELYABLE is faster
  const lastCardOut = lastCardOutUNRELYABLE || cards?.length === 0;

  React.useEffect(() => {
    if (lastCardOut) {
      seeHint(FIRSTLOOPDONE);
    }
    // loopFilterPrev differentiates how we got here, ether by swiping the last card or setting a filter (without it if somebody swipes all cards and then sets include-all filter, the filter would immediatelly unset here)
    if (lastCardOut && !!loopFilter && !!loopFilterPrev) {
      setLoopFilter(undefined);
      setSavedLoopFilter(undefined);
      setLastCardOut(false);
    }
  }, [loopFilterPrev, lastCardOut, seeHint, loopFilter, setSavedLoopFilter, setLoopFilter]);

  React.useEffect(() => burn(), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffectOnce(() => {
    if (!loopFilter && savedLoopFilter?.includeKnown && deck?.id === loopFilterDeckId) {
      snackbar(<RenewLoopFilter loopFilter={savedLoopFilter} />, { severity: "info", autoHide: false });
      setSavedLoopFilter(undefined);
    }

    return () => {
      setLoopFilter(undefined);
    };
  });

  React.useEffect(() => {
    if (loopFilter || !prevCardsLength || !cards) return;
    if (lastCardOut && prevCardsLength > cards?.length) {
      showRatingIfNeeded();
    }
  }, [lastCardOut, loopFilter, showRatingIfNeeded, cards, prevCardsLength]);

  return (
    <HeaderLayout
      transparent
      title={
        <Top>
          <HeaderMenu noLabel />
          <CardsFilter />
        </Top>
      }
      right={<Fire />}
    >
      {isLoading || !cards ? (
        <Loader />
      ) : (
        <>
          {(cards.length > 0 || bottomCardActive) && (
            <Cards cards={cards} onLastCardOutUNRELYABLE={() => setLastCardOut(true)} />
          )}
          {cards.length === 0 && !bottomCardActive && (
            <WrapOut>
              <Wrap>
                <WrapIn>
                  <LearnNoCards />
                </WrapIn>
              </Wrap>
            </WrapOut>
          )}
        </>
      )}
    </HeaderLayout>
  );
};

export default Learn;
