import Garden, { GardenXps } from "icons/garden/Garden";
import React from "react";
import { GARDENWIDTH } from "root/main/main/quests/Quests";
import styled from "styled-components/macro";

const Wrap = styled.div<{ $scale?: number }>`
  margin: 10px 0 25px;
  transform: scale(${({ $scale }) => ($scale ? $scale : 1)});
  transform-origin: left;
`;

type Props = {
  xps: GardenXps;
};

const FriendGarden: React.FC<Props> = ({ xps }) => {
  const ref = React.useRef<any>(null);

  const widthWithMargins = GARDENWIDTH + 64;
  const scale =
    widthWithMargins > window.innerWidth ? Number((window.innerWidth / widthWithMargins).toFixed(2)) : undefined;

  return (
    <Wrap ref={ref} $scale={scale}>
      <Garden xps={xps} />
    </Wrap>
  );
};

export default FriendGarden;
