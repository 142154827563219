import { Button } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";
import { useMutation } from "relay-hooks";
import styled from "styled-components/macro";
import { TextField } from "../../styled/TextField";
import { TTheory } from "types/TheoryTypes";
import { EditTheories } from "./theoryEditMutation";
import { theoryEditMutation } from "./__generated__/theoryEditMutation.graphql";
import UndoIcon from "@mui/icons-material/Undo";
import { brown } from "./theoryStyles";

export const THEORYTEXTID = "theoryTextId";

const Wrap = styled.div`
  .MuiFilledInput-multiline {
    padding: 7px 2px;
    font-size: 14.5px;
  }
  .MuiInputBase-root:before,
  .MuiFilledInput-underline:after {
    display: none;
  }
`;
const BlueButton = styled(Button)`
  margin: -5px auto 0;
  display: block;
`;
const GreenButton = styled(Button)`
  margin: 0 auto;
  display: block;
`;
const ThanksWrap = styled.div`
  margin-bottom: 15px;
  text-align: center;
`;
const Original = styled.div<{ $coursePreview: boolean }>`
  position: relative;
  margin: ${(p) => (p.$coursePreview ? "20px 0 0 0" : "-26px -7px 10px 23px")};
  padding: 7px 5px 3px 7px;
  border: 1px solid ${brown}66;
  background: ${brown}22;
  border-radius: 5px;
  font-size: 12px;
  line-height: 1.3em;
  color: #888;
`;
const EnTitle = styled.div`
  position: absolute;
  top: -7px;
  left: 10px;
  padding: 0 3px;
  background: linear-gradient(transparent, white, transparent);
  border: 1px solid ${brown}66;
  border-top: none;
  border-bottom: none;
  color: ${brown};
  font-size: 10px;
  line-height: 1.2em;
  font-weight: bold;
`;

type Props = {
  theory: TTheory;
  theoryText: string;
  lang: string;
  onContinue?: VoidFunction;
  bigger: boolean;
  isAutoTrans: boolean;
  coursePreview?: boolean;
};

const TheoryEditor: React.FC<Props> = ({
  theory,
  theoryText,
  lang,
  onContinue,
  bigger,
  isAutoTrans,
  coursePreview
}) => {
  const [value, setValue] = React.useState(theoryText);
  const [submitted, setSubmitted] = React.useState(false);
  const [editTheories] = useMutation<theoryEditMutation>(EditTheories);

  const handleSubmit = () => {
    if (theory.canEdit) {
      editTheories({
        variables: {
          id: theory.sCardId,
          theory: theory.lang === lang ? value : undefined,
          theoryNative: theory.lang !== lang ? value : undefined,
          backLang: theory.lang !== lang ? lang : theory.langNative,
          langNative: theory.langNative
        }
      });
      onContinue && onContinue();
    } else {
      setSubmitted(true);
    }
  };

  const theoryEn = theory.langNative === "en" ? theory.theoryNative : theory.theoryEn || theory.theory;

  return (
    <>
      {!submitted ? (
        <Wrap>
          {lang !== "en" && (
            <Original $coursePreview={!!coursePreview}>
              <EnTitle>English:</EnTitle>
              <div>
                {theoryEn.split(new RegExp("\\n")).map((line, i) => (
                  <React.Fragment key={i}>
                    {!!i && <br />}
                    {line}
                  </React.Fragment>
                ))}
              </div>
            </Original>
          )}
          <TextField id={THEORYTEXTID} multiline autoFocus value={value} onChange={(e) => setValue(e.target.value)} />
          <BlueButton
            color="primary"
            variant="contained"
            disabled={theoryText === value && !(!isAutoTrans && theory.canEdit)}
            onClick={handleSubmit}
          >
            {theory.canEdit ? <Trans>Save</Trans> : <Trans>Suggest an edit</Trans>}
          </BlueButton>
          <GreenButton
            onClick={theoryText === value ? handleSubmit : () => setValue(theoryText)}
            size="small"
            color="secondary"
          >
            {isAutoTrans &&
              (theoryText === value ? (
                <Trans>I don't see any mistakes</Trans>
              ) : (
                <UndoIcon style={{ margin: "-2px 0 -8px" }} />
              ))}
          </GreenButton>
        </Wrap>
      ) : (
        <ThanksWrap>
          <p>
            <Trans>Thank you</Trans>!
          </p>
          <p>
            <Trans>We will review your suggestion and publish or decline it within 24 hours.</Trans>
          </p>
          {bigger && (
            <Button color="primary" variant="contained" onClick={onContinue}>
              OK
            </Button>
          )}
        </ThanksWrap>
      )}
    </>
  );
};

export default TheoryEditor;
