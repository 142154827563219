import React from "react";
import * as Sentry from "@sentry/browser";

// TODO: DELETE THIS COMPONENT AS SOON AS THE IFRAME ISSUE IS RESOLVED
//   https://github.com/firebase/firebase-js-sdk/issues/4946

type Props = {};

const IframeCatcher: React.FC<Props> = () => {
  React.useEffect(() => {
    const interval = setInterval(() => {
      const els = document.getElementsByTagName("iframe");
      for (let el of els as any) {
        if (el.style.top === "0px" && el.style.height === "100%" && el.style.width === "100%") {
          console.warn("detected and removed firebase iframe!", el);
          Sentry.captureMessage("detected and removed firebase iframe!");
          el.remove();
        }
      }
    }, 3000);
    return () => clearInterval(interval);
  });

  // return (
  //   <iframe
  //     style={{
  //       position: "fixed",
  //       top: 0,
  //       left: 0,
  //       width: "100%",
  //       height: "100%",
  //       border: "none",
  //       zIndex: 2147483647
  //     }}
  //   ></iframe>
  // );

  return null;
};

export default IframeCatcher;
