import React from "react";
import styled from "styled-components/macro";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import PublicIcon from "@mui/icons-material/Public";
import { IconButton } from "@mui/material";
import LinkExt from "../../components/other/LinkExt";
import { isDesktop } from "../../tools/device";

const Links = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  svg {
    color: white;
    cursor: pointer;
    filter: drop-shadow(1px 1px 2px ${({ theme }) => theme.duo.color.primary});
    transform: translateZ(0); // fix ios filter-rendering
  }
  a {
    display: inherit;
  }
  button {
    padding: 10px;
  }
`;

export const SocialLinks: React.FC<{}> = () => {
  return (
    <Links>
      <IconButton>
        <LinkExt href={isDesktop() ? "https://www.facebook.com/pg/DuoCardsCom" : "fb://page/107180174194369"}>
          <FacebookIcon style={{ filter: "drop-shadow(1px 1px 2px #3b5999)" }} />
        </LinkExt>
      </IconButton>
      <IconButton>
        <LinkExt href="https://www.instagram.com/duocardscom">
          <InstagramIcon style={{ filter: "drop-shadow(1px 1px 2px #e4405f)" }} />
        </LinkExt>
      </IconButton>
      <IconButton>
        <LinkExt href="https://twitter.com/DuoCardsCom">
          <TwitterIcon style={{ filter: "drop-shadow(1px 1px 2px #55acee)" }} />
        </LinkExt>
      </IconButton>
      {/* we received request from youtube to update this icon by their guidelines: https://developers.google.com/youtube/terms/branding-guidelines
           - so let's remove it for now
      <IconButton>
        <LinkExt href="https://www.youtube.com/channel/UCKvfatNCNiS3ze3NNvgSfOg">
          <YouTubeIcon style={{ filter: "drop-shadow(1px 1px 2px #cd201f)" }} />
        </LinkExt>
      </IconButton> */}
      <IconButton>
        <LinkExt href="https://www.duocards.com">
          <PublicIcon />
        </LinkExt>
      </IconButton>
    </Links>
  );
};

// export const WebLinks: React.FC<{}> = () => {
//   return (
//     <Links>
//       <IconButton>
//         <LinkExt href="https://play.google.com/store/apps/details?id=com.duocards.app">
//           <ShopIcon />
//         </LinkExt>
//       </IconButton>
//       <IconButton>
//         <LinkExt href="https://apps.apple.com/us/app/duocards-learning-decks/id1508159917">
//           <AppleIcon />
//         </LinkExt>
//       </IconButton>
//     </Links>
//   );
// };
