import React from "react";
import styled from "styled-components/macro";
import { Paper, Button, IconButton } from "@mui/material";
import { devicePaddings, isIos } from "../../../../tools/device";
import Loader from "components/other/Loader";
import { TOO_LONG } from "../../../../queries/translateQuery";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import ExpandIcon from "@mui/icons-material/Expand";
import { useViewerQuery } from "../../../../queries/viewerQuery";
import Speech from "../../../../components/speech/Speech";
import { TUTORIALDONE } from "../../../tutorial/steps/Verdict";
import { useWindowSize } from "hooks/useWindowSize";
import useClickAway from "hooks/useClickAway";

export const SAVEWORDID = "saveWordId";
export const POPPEDWORDID = "poppedWordId";

const { top: deviceTop } = devicePaddings();

const SPaper = styled(Paper)`
  position: absolute;
  max-width: 90%;
  z-index: 1400; // over oxford popup
`;
const Cont = styled.div`
  padding: 12px 24px 7px;
  text-align: center;
`;
const Label = styled.div`
  font-size: 95%;
  padding: 0 4px;
  color: ${({ theme }) => theme.duo.color.textGrey};
`;
const Translate = styled.div`
  position: relative;
  width: fit-content;
  font-size: ${({ theme }) => theme.duo.fontSize.medium};
  line-height: 1.45em;
  margin: 2px auto 4px;
`;
const SButton = styled(Button)`
  &&& {
    border-radius: 0;
    padding: 9px 18px;
    font-size: 15px;
  }
`;
const Buttons = styled.div`
  border-top: 1px solid ${({ theme }) => theme.duo.color.grey};
  display: flex;
  justify-content: space-evenly;
`;
const SEditIcon = styled(EditIcon)`
  font-size: 18px;
`;
const SExpandIcon = styled(ExpandIcon)`
  font-size: 18px;
  transform: rotate(90deg);
`;
const Separator = styled.div`
  width: 0;
  border-left: 1px solid ${({ theme }) => theme.duo.color.grey};
`;

type Props = {
  translate: string | null;
  text?: string;
  anchorPos: { top?: number; left?: number };
  onSave: VoidFunction;
  onEdit: VoidFunction;
  onClickAway: VoidFunction;
  lang: string | undefined;
  onSelectSentence?: () => void;
};

const Word: React.FC<Props> = ({ text, translate, anchorPos, onSave, onClickAway, onEdit, lang, onSelectSentence }) => {
  const { t } = useTranslation();
  const [paperRect, setPaperRect] = React.useState({ width: 100, height: 100 });
  const { hintWasSeen } = useViewerQuery();

  const ref: React.RefObject<HTMLDivElement> = React.useRef(null);

  useClickAway(ref, onClickAway);

  React.useEffect(() => {
    const rect = ref.current?.getBoundingClientRect();
    rect && setPaperRect({ width: rect.width, height: rect.height });
  }, [anchorPos, translate]);

  const { width: windowWidth } = useWindowSize();
  const leftPos = (anchorPos.left || 0) - paperRect.width / 2;
  const maxLeft = windowWidth - paperRect.width - 10;
  const leftRaw = leftPos < maxLeft ? leftPos : maxLeft;
  const left = leftRaw < 10 ? 10 : leftRaw;

  const topPos = (anchorPos.top || 0) - paperRect.height - (isIos() ? 55 : 0);
  const top = topPos < deviceTop + 5 ? deviceTop + 5 : topPos;

  return (
    <SPaper id={POPPEDWORDID} ref={ref} elevation={8} style={{ left, top }} onClick={(e) => e.stopPropagation()}>
      {text && text.length > TOO_LONG ? (
        <Cont>{t("Text you selected is too long")}</Cont>
      ) : (
        <>
          <Cont>
            <Label>
              <Speech text={text} lang={lang} small />
              {text}:
            </Label>
            <Translate>{translate !== null ? translate || "" : <Loader size={33} />}</Translate>
          </Cont>
          <Buttons>
            {hintWasSeen(TUTORIALDONE) && (
              <>
                {onSelectSentence && (
                  <>
                    <IconButton
                      sx={{ padding: "8px 12px" }}
                      onMouseUp={(e) => {
                        e.preventDefault(); // we need to prevent default to avoid the click event
                        onSelectSentence();
                      }}
                      onTouchEnd={(e) => {
                        e.preventDefault(); // we need to prevent default to avoid the click event
                        onSelectSentence();
                      }}
                    >
                      <SExpandIcon color={translate === null ? "disabled" : "secondary"} />
                    </IconButton>
                    <Separator />
                  </>
                )}
                <IconButton sx={{ padding: "8px 12px" }} onClick={translate === null ? undefined : onEdit}>
                  <SEditIcon color={translate === null ? "disabled" : "secondary"} />
                </IconButton>
                <Separator />
              </>
            )}
            <SButton color="secondary" id={SAVEWORDID} disabled={translate === null} onClick={onSave}>
              {t("Save")}
            </SButton>
          </Buttons>
        </>
      )}
    </SPaper>
  );
};

export default Word;
