import React from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLocation, useNavigate } from "react-router";
import { useMutation } from "relay-hooks";
import { useTranslation } from "react-i18next";
import { useViewerQuery } from "../../queries/viewerQuery";
import { VerifyingMutation } from "./__generated__/VerifyingMutation.graphql";
import { useSearchParams } from "react-router-dom";
import { snackbar } from "tools/events";
import { useGetState } from "components/ReduxProvider";
import { VERIFY } from "../RootRouter.config";

const VerifyUser = graphql`
  mutation VerifyingMutation($hash: String!) {
    verifyUser(hash: $hash) {
      emailVerified
    }
  }
`;

type Props = {};

const Verifying: React.FC<Props> = () => {
  const { viewer } = useViewerQuery();
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = useGetState("token");

  const [searchParams] = useSearchParams();
  const verifyHash = searchParams.get(VERIFY.verifyParam);

  const [mutate] = useMutation<VerifyingMutation>(VerifyUser, {
    onCompleted: () => {
      if (location.pathname.indexOf(VERIFY.url()) !== -1) {
        if (!token) {
          snackbar(t("Your email was verified"));
          navigate("/#login", { replace: true });
        } else {
          navigate("/", { replace: true });
        }
      } else {
        navigate(location.pathname, { replace: true });
      }
      setLoading(false);
    }
  });

  React.useEffect(() => {
    if (verifyHash && !loading) {
      mutate({ variables: { hash: verifyHash } });
      setLoading(true);
    }
  }, [location, verifyHash, loading, mutate, viewer]);

  return null;
};

export default Verifying;
