import React from "react";
import { isBrowser } from "tools/device";
import { selectDeviceId, setDeviceId, useGetState, useSetState } from "../components/ReduxProvider";
import { useDispatch, useSelector } from "react-redux";

type Props = {};

const ReduxAfterInitializer: React.FC<Props> = () => {
  const storageDeviceId = useSelector(selectDeviceId);
  const dispatch = useDispatch();
  const storageDeviceOs = useGetState("deviceOs");
  const setDeviceOs = useSetState("deviceOs");

  // set deviceId on initialization and store it into redux/localstorage
  React.useEffect(() => {
    const device = (window as any).device;

    const deviceId = device
      ? `${device.model}-${device.uuid}`
      : !isBrowser()
        ? `random_${Math.random().toString(36).substr(2, 7)}`
        : undefined;

    if (device?.platform && device.platform !== storageDeviceOs) setDeviceOs(device.platform.toLowerCase());
    if (deviceId && deviceId !== storageDeviceId) dispatch(setDeviceId(deviceId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default ReduxAfterInitializer;
