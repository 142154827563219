/**
 * @generated SignedSource<<094a41b36dede6595cd8a9520d7ee855>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoopMode = "listening" | "native" | "%future added value";
export type LoopFilter = {
  filterId: string;
  filteredSIds: ReadonlyArray<string | null>;
  includeKnown: boolean;
  loopMode: LoopMode;
};
export type CardUndoButtonMutation$variables = {
  cardId: string;
  failCount: number;
  flipped: boolean;
  knownAt?: string | null;
  knownCount: number;
  knownUntil?: string | null;
  loopFilter?: LoopFilter | null;
  loopedAt: string;
};
export type CardUndoButtonMutation$data = {
  readonly loopUndo: {
    readonly " $fragmentSpreads": FragmentRefs<"loopQuery_loop">;
  };
};
export type CardUndoButtonMutation = {
  response: CardUndoButtonMutation$data;
  variables: CardUndoButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cardId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "failCount"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "flipped"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "knownAt"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "knownCount"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "knownUntil"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "loopFilter"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "loopedAt"
},
v8 = [
  {
    "kind": "Variable",
    "name": "cardId",
    "variableName": "cardId"
  },
  {
    "kind": "Variable",
    "name": "failCount",
    "variableName": "failCount"
  },
  {
    "kind": "Variable",
    "name": "flipped",
    "variableName": "flipped"
  },
  {
    "kind": "Variable",
    "name": "knownAt",
    "variableName": "knownAt"
  },
  {
    "kind": "Variable",
    "name": "knownCount",
    "variableName": "knownCount"
  },
  {
    "kind": "Variable",
    "name": "knownUntil",
    "variableName": "knownUntil"
  },
  {
    "kind": "Variable",
    "name": "loopedAt",
    "variableName": "loopedAt"
  }
],
v9 = [
  {
    "kind": "Variable",
    "name": "loopFilter",
    "variableName": "loopFilter"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CardUndoButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Deck",
        "kind": "LinkedField",
        "name": "loopUndo",
        "plural": false,
        "selections": [
          {
            "args": (v9/*: any*/),
            "kind": "FragmentSpread",
            "name": "loopQuery_loop"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "CardUndoButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "Deck",
        "kind": "LinkedField",
        "name": "loopUndo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v9/*: any*/),
            "concreteType": "Card",
            "kind": "LinkedField",
            "name": "loop",
            "plural": true,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sCardId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sBackId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sourceId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "front",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pronunciation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "back",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hint",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "flipped",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "loopedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "knownAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "knownUntil",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "knownCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "failCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Source",
                "kind": "LinkedField",
                "name": "source",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "kind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "course",
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Upload",
                "kind": "LinkedField",
                "name": "svg",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "flatId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7deebbd58d49046835fea99dbabf70eb",
    "id": null,
    "metadata": {},
    "name": "CardUndoButtonMutation",
    "operationKind": "mutation",
    "text": "mutation CardUndoButtonMutation(\n  $cardId: ID!\n  $loopedAt: String!\n  $knownAt: String\n  $knownUntil: String\n  $knownCount: Int!\n  $failCount: Int!\n  $flipped: Boolean!\n  $loopFilter: LoopFilter\n) {\n  loopUndo(cardId: $cardId, loopedAt: $loopedAt, knownAt: $knownAt, knownUntil: $knownUntil, knownCount: $knownCount, failCount: $failCount, flipped: $flipped) {\n    ...loopQuery_loop_gbhcV\n    id\n  }\n}\n\nfragment loopQuery_loop_gbhcV on Deck {\n  loop(loopFilter: $loopFilter) {\n    id\n    sCardId\n    sBackId\n    sourceId\n    front\n    pronunciation\n    back\n    hint\n    flipped\n    loopedAt\n    knownAt\n    knownUntil\n    knownCount\n    failCount\n    source {\n      kind\n      course\n      id\n    }\n    svg {\n      flatId\n      url\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "93c24e90f5da75cf10f432080edc5e86";

export default node;
