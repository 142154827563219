import React from "react";
import styled from "styled-components/macro";
import { usePrevious } from "react-use";

export const TogglerAnimationLength = 400;

const Wrap = styled.div`
  max-height: 0;
  overflow: hidden;
  transition: max-height ${TogglerAnimationLength}ms;
  width: 100%;
`;

type Props = { children: React.ReactNode; show: boolean; delay?: number };

const Toggler: React.FC<Props> = ({ show, children, delay }) => {
  const togglerRef = React.useRef<HTMLDivElement>(null);
  const showPrev = usePrevious(show);

  const setStyles = (val: "content" | string) => {
    if (togglerRef.current) {
      togglerRef.current.style.maxHeight = val === "content" ? togglerRef.current.scrollHeight + "px" : val;
      togglerRef.current.style.overflow = val === "none" ? "visible" : "hidden";
    }
  };

  const doShow = () => {
    setStyles("content");
    setTimeout(() => {
      setStyles("none");
    }, TogglerAnimationLength + 50);
  };

  React.useEffect(() => {
    if (!togglerRef.current) return;

    if (show && !showPrev) {
      if (delay) {
        setTimeout(doShow, delay);
      } else {
        doShow();
      }
    }

    if (!show && showPrev) {
      setStyles("content");
      setTimeout(() => {
        setStyles("0");
      });
    }
  });

  return <Wrap ref={togglerRef}>{children}</Wrap>;
};

export default Toggler;
