import React from "react";
import styled from "styled-components/macro";

const Svg = styled.svg`
  width: 64px;
  height: 64px;
  position: absolute;
  left: 18px;
  top: 11px;
`;

const Cave: React.FC<any> = (p) => (
  <Svg
    {...p}
    enableBackground="new 0 0 512.001 512.001"
    viewBox="0 0 512.001 512.001"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="m170.532 124.411-16.415-5.472 5.469-16.407c1.398-4.191-.867-8.723-5.055-10.121-4.227-1.414-8.719.871-10.117 5.059l-7.3 21.899-54.317 46.558c-3.359 2.875-3.75 7.926-.867 11.281 1.578 1.844 3.82 2.793 6.07 2.793 1.844 0 3.695-.633 5.203-1.926l52.562-45.051 19.703 6.566c4.227 1.398 8.719-.871 10.117-5.059 1.4-4.191-.865-8.722-5.053-10.12z"
        fill="#966a5b"
      ></path>
      <path
        d="m96.001 187.108v-68.891l22.859-38.099c2.273-3.789 1.047-8.703-2.742-10.977-3.797-2.273-8.703-1.043-10.977 2.742l-19.687 32.809-17.016-11.348c-3.688-2.461-8.656-1.457-11.094 2.219-2.453 3.676-1.461 8.645 2.219 11.094l20.437 13.625v70.773l-17.703-8.852-13.533-33.84 6.393-12.785c1.977-3.953.375-8.758-3.578-10.734-3.953-1.973-8.758-.375-10.734 3.578l-5.13 10.26-25.776-6.444c-4.305-1.098-8.633 1.539-9.695 5.82-1.078 4.289 1.531 8.633 5.82 9.703l28.031 7.008 14.477 36.203c.727 1.818 2.096 3.306 3.848 4.182l28.215 14.104 16.508 123.109 44.193-45.034-40.071-78.374c-3.461-6.764-5.264-14.253-5.264-21.851z"
        fill="#785353"
      ></path>
      <path
        d="m465.882 332.783c-1.213-5.663-4.434-10.696-9.067-14.171l-56.814-42.611-21.428-85.711c-1.67-6.681-5.451-12.645-10.781-17.005l-67.099-54.899c-.793-.649-1.63-1.209-2.498-1.681l-16.764 14.242-45.931 59.053-47 8-146 238h445.5z"
        fill="#d19a6e"
      ></path>
      <path
        d="m401.696 377.866.623-20.874c.22-7.361-2.952-14.415-8.604-19.135l-35.971-30.046c-7.562-6.317-17.19-9.62-27.037-9.276l-42.04 1.465 23.652 136h147.792l-49.217-38.518c-6.011-4.704-9.425-11.987-9.198-19.616z"
        fill="#c38c66"
      ></path>
      <path
        d="m226.326 206.28c8.725-1.454 16.458-6.455 21.365-13.814l50.505-75.762c-6.744-3.669-15.373-2.045-20.263 4.242l-45.932 59.055-40.628 6.771c-4.672.779-8.759 3.587-11.161 7.67l-76.211 129.558-36.616 12.205c-7.167 2.389-12.79 8.013-15.179 15.179l-28.205 84.616h112l27.309-81.928c2.875-8.624.63-18.132-5.798-24.56l-1.678-1.678c-7.11-7.11-9.034-17.898-4.82-27.028l33.893-73.434c4.438-9.616 13.347-16.414 23.794-18.155z"
        fill="#c38c66"
      ></path>
      <path
        d="m356.34 339.185c-2.788-9.757-9.179-18.089-17.88-23.31l-55.162-33.097c-7.036-4.222-15.741-4.556-23.08-.886l-26.452 13.226c-6.392 3.196-11.812 8.047-15.694 14.047l-82.071 126.835h248z"
        fill="#785353"
      ></path>
      <path
        d="m367.362 436-26.408-92.422c-1.68-5.871-5.484-10.836-10.734-13.988l-55.156-33.094c-2.359-1.418-5.242-1.531-7.688-.293l-26.453 13.227c-3.836 1.914-7.094 4.828-9.422 8.426l-76.446 118.144z"
        fill="#966a5b"
      ></path>
      <path
        d="m203.64 398.918c-2.353-7.057-7.09-13.074-13.398-17.017l-46.127-28.83c-4.228-2.643-9.816-.816-11.668 3.813l-12.446 31.116-96 48h192z"
        fill="#b57f5f"
      ></path>
      <path
        d="m504.001 444h-496c-4.422 0-8-3.582-8-8s3.578-8 8-8h496c4.422 0 8 3.582 8 8s-3.579 8-8 8z"
        fill="#f6d190"
      ></path>
    </g>
  </Svg>
);

export default Cave;
