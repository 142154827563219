import { useSetState, useGetState } from "components/ReduxProvider";
import { isEqual } from "lodash";
import React from "react";
import { useFragment } from "relay-hooks";
import { decksFrg, friendsFrg, useViewerQuery, xpsFrg } from "./viewerQuery";
import { viewerQuery_decks$key } from "./__generated__/viewerQuery_decks.graphql";
import { viewerQuery_friends$key } from "./__generated__/viewerQuery_friends.graphql";
import { viewerQuery_xps$key } from "./__generated__/viewerQuery_xps.graphql";

type Props = {};

const QueriesToRedux: React.FC<Props> = () => {
  const { viewerRelay: viewer } = useViewerQuery();
  const setXps = useSetState("xps");
  const setDecks = useSetState("decks");
  const setFriends = useSetState("friends");
  const setViewer = useSetState("viewer");

  const viewerRedux = useGetState("viewer");
  React.useEffect(() => {
    const filterViewer = (viewer) => ({ ...viewer, __fragmentOwner: undefined });
    if (viewer !== undefined && !isEqual(filterViewer(viewer), viewerRedux)) {
      setViewer(filterViewer(viewer));
    }
  }, [viewer]); // eslint-disable-line react-hooks/exhaustive-deps

  const decks = useFragment<viewerQuery_decks$key>(decksFrg, viewer || null)?.decks;
  const decksRedux = useGetState("decks");
  React.useEffect(() => {
    if (!isEqual(decks, decksRedux) && decks !== undefined) setDecks(decks);
  }, [decks]); // eslint-disable-line react-hooks/exhaustive-deps

  const xps = useFragment<viewerQuery_xps$key>(xpsFrg, viewer || null)?.xps;
  const xpsRedux = useGetState("xps");
  React.useEffect(() => {
    if (!isEqual(xps, xpsRedux) && xps !== undefined) setXps(xps);
  }, [xps]); // eslint-disable-line react-hooks/exhaustive-deps

  const friends = useFragment<viewerQuery_friends$key>(friendsFrg, viewer || null)?.friends;
  const friendsRedux = useGetState("friends");
  React.useEffect(() => {
    if (!isEqual(friends, friendsRedux) && friends !== undefined) setFriends(friends);
  }, [xps]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default QueriesToRedux;
