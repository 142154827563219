import React from "react";
import styled, { css } from "styled-components/macro";
import { sourceQuery$data } from "../../../queries/sources/__generated__/sourceQuery.graphql";
import Contextor from "./contextor/Contextor";
import { imgHeaderFormat, fromSourceOrDefault } from "../../../tools/unsplash";
import Metadata from "./Metadata";
import { useInterval } from "react-use";
import { useBurnWithSource } from "../../../components/fire/xpHooks";
import { parser } from "../../../tools/strings";
import { smallAlphabets, rightAlignLangs } from "../../../sharedJs__generated/langsList";
import { useViewerQuery } from "../../../queries/viewerQuery";
import Rate from "../../../components/other/Rate";
import { TitleOverImage } from "root/library/library/styled";
import { CONTENT_ID } from "../../../components/header/constants";
import { useFinishSource } from "../../../queries/sources/finishSource";
import RecommendedSourcesPaginated from "../../../components/RecommendedSources/RecommendedSourcesPaginated";
import Finish from "./Finish";
import _ from "lodash";

export const ArticleHeaderH = 240;

export const textCss = (lang: string) => `${
  smallAlphabets.includes(lang) &&
  css`
    font-size: ${lang === "ar" ? 135 : 115}%;
    line-height: 1.45em;
  `
}

`;

const Wrap = styled.div`
  margin: 0 18px 0 22px;
`;
const Img = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 0 0 0;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: ${ArticleHeaderH}px;
`;
const SubTitle = styled.span`
  display: block;
  margin-top: 10px;
  font-size: 14px;
`;
const Text = styled.div<{ lang: string }>`
  padding-bottom: 20px;
  font-size: ${({ lang }) => (!smallAlphabets.includes(lang) ? 110 : lang === "ar" ? 170 : 140)}%;
  font-weight: 100;
  line-height: 1.8em;
  text-align: ${({ lang }) => (rightAlignLangs.includes(lang) ? `right` : "left")};
  &:empty {
    padding-bottom: 0;
  }
`;

type Props = {
  source: NonNullable<sourceQuery$data["sourceByUriOrId"]>;
};

const Article: React.FC<Props> = ({ source }) => {
  const [moveIndicator, setMoveIndicator] = React.useState(0);
  const burnW = useBurnWithSource(source.lang);
  const moveRef = React.useRef(0);
  const { viewer } = useViewerQuery();
  const myRef = React.useRef<HTMLDivElement>(null);
  const { finishSource, loading: finishSourceLoading } = useFinishSource();
  const finishing = React.useRef(false);

  useInterval(() => {
    if (moveIndicator !== moveRef.current) {
      setMoveIndicator(moveRef.current);
      source?.lang && burnW();
    }
  }, 1500);

  const handleMove = () => (moveRef.current = Math.random());

  const handleFinishSource = (finished) => {
    finishing.current = true;
    if (source && source?.rating && !finishSourceLoading) {
      finishSource({ id: source.id, finished: finished }).then(() => {});
    }
  };

  const onScroll = _.throttle(() => {
    const el = document.getElementById(CONTENT_ID);
    if (myRef.current && el && !finishing.current) {
      const { offsetTop } = myRef.current;
      const { height } = el.getBoundingClientRect();
      if (el.scrollTop + height + 40 - offsetTop > 0 && !source?.rating?.finished && !finishSourceLoading) {
        finishing.current = true;
        setTimeout(() => {
          handleFinishSource(true);
        }, 1000);
      }
    }
  }, 200);

  React.useEffect(() => {
    const el = document.getElementById(CONTENT_ID);
    if (el && viewer) {
      el.addEventListener("scroll", onScroll);
    }

    window.addEventListener("wheel", handleMove, false);
    return () => {
      window.removeEventListener("wheel", handleMove);
      if (el && viewer) {
        el.removeEventListener("scroll", onScroll);
      }
    };
  });

  return (
    <>
      <div onMouseMove={handleMove} onTouchMove={handleMove}>
        <Contextor source={source}>
          <Img className="nofilter" style={{ backgroundImage: imgHeaderFormat(fromSourceOrDefault(source).url) }}>
            <TitleOverImage>
              {source.name}
              {!viewer && source.kind === "set" && <SubTitle>{source.seo}</SubTitle>}
            </TitleOverImage>
          </Img>

          <Metadata source={source} />

          <Wrap>
            <Text lang={source.lang}>{parser(source.text)}</Text>
          </Wrap>
        </Contextor>
      </div>

      {source.kind === "article" && (
        <>
          <Rate padded source={source} />
          <Finish source={source} myRef={myRef} finishSource={handleFinishSource} />
          <RecommendedSourcesPaginated lang={source.lang} sourceId={source?.id} />
        </>
      )}
    </>
  );
};

export default Article;
