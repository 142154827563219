/**
 * @generated SignedSource<<8a97714da4d5b6867d63b544a8f7bd64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CardSorterSourceQuery$variables = {
  langNative?: string | null;
  sourceId: string;
};
export type CardSorterSourceQuery$data = {
  readonly node: {
    readonly id?: string;
    readonly mine?: boolean;
    readonly name?: string;
  } | null;
};
export type CardSorterSourceQuery = {
  response: CardSorterSourceQuery$data;
  variables: CardSorterSourceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "langNative"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "sourceId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "langNative",
      "variableName": "langNative"
    }
  ],
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mine",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CardSorterSourceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "Source",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CardSorterSourceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "Source",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c63dc4514f969ac13ee878dd72f641a",
    "id": null,
    "metadata": {},
    "name": "CardSorterSourceQuery",
    "operationKind": "query",
    "text": "query CardSorterSourceQuery(\n  $sourceId: ID!\n  $langNative: String\n) {\n  node(id: $sourceId) {\n    __typename\n    ... on Source {\n      id\n      name(langNative: $langNative)\n      mine\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "14310e849f4af58d1967a37716f51fa3";

export default node;
