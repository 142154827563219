import { graphql } from "babel-plugin-relay/macro";

export const ratingEditQL = graphql`
  mutation ratingMutation($sourceId: ID!, $rating: Int, $continueFrom: Int) {
    ratingEdit(sourceId: $sourceId, rating: $rating, continueFrom: $continueFrom) {
      rating {
        rating
        continueFrom
      }
    }
  }
`;
