import React from "react";
import styled from "styled-components/macro";

const Svg = styled.svg`
  width: 66px;
  height: 66px;
  position: absolute;
  left: 39px;
  top: 84px;
`;

type Props = {
  className?: string;
};

const Pond: React.FC<Props> = ({ className }) => {
  return (
    <Svg
      className={className}
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <g>
            <g>
              <path
                d="m430 271.97s-4.829-104.476 47.384-170.989c3.967-5.153-1.872-12.077-7.621-9.037-27.152 14.362-70.62 56.08-81.647 195.874z"
                fill="#1ca56f"
              ></path>
            </g>
            <g>
              <path
                d="m178.564 312.437s4.182-90.476-39.456-149.743c-3.902-5.3 2.262-12.128 7.939-8.799 24.244 14.215 60.26 52.561 69.679 155.738z"
                fill="#1ca56f"
              ></path>
            </g>
            <g>
              <path
                d="m207.449 277.658c-.103 0-.205-.002-.309-.006-4.264-.168-7.583-3.761-7.416-8.024.035-.888 3.149-89.878-25.505-177.545-1.326-4.056.887-8.419 4.944-9.744 4.059-1.326 8.42.888 9.744 4.944 29.546 90.397 26.405 179.22 26.258 182.957-.166 4.158-3.591 7.418-7.716 7.418z"
                fill="#6c3328"
              ></path>
              <g>
                <g>
                  <g>
                    <path
                      d="m206.646 221.608c-10.339 0-19.208-7.825-20.288-18.334-2.848-27.706-7.587-54.194-14.085-78.725-2.889-10.902 3.608-22.081 14.51-24.969 10.908-2.889 22.081 3.608 24.968 14.51 7.043 26.586 12.169 55.187 15.233 85.008 1.153 11.219-7.007 21.248-18.226 22.401-.71.073-1.414.109-2.112.109z"
                      fill="#fcb44d"
                    ></path>
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d="m208.756 221.499c-.711.072-1.411.113-2.112.113-1.772 0-3.503-.227-5.151-.67 4.399-4.213 6.871-10.333 6.202-16.854-3.07-29.824-8.19-58.422-15.236-85.011-1.947-7.366-7.685-12.712-14.557-14.526 2.411-2.308 5.429-4.059 8.88-4.976 10.91-2.884 22.077 3.616 24.972 14.515 7.036 26.589 12.166 55.187 15.226 85.011 1.154 11.221-7.005 21.245-18.224 22.398z"
                      fill="#fb9927"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
            <g>
              <g>
                <path
                  d="m93.777 296.627s-4.111-88.929 39.482-145.544c3.377-4.386-1.594-10.28-6.487-7.692-23.112 12.225-60.111 47.735-69.497 150.554z"
                  fill="#1ca56f"
                ></path>
              </g>
              <g>
                <path
                  d="m59.451 296.627s4.111-88.929-39.482-145.544c-3.377-4.386 1.594-10.28 6.487-7.692 23.112 12.225 60.111 47.735 69.497 150.554z"
                  fill="#a3e440"
                ></path>
              </g>
              <path
                d="m35.14 311.395s3.56-77.012-33.585-127.46c-3.321-4.511 1.925-10.323 6.757-7.49 20.636 12.1 51.293 44.739 59.31 132.563z"
                fill="#1ca56f"
              ></path>
            </g>
            <g>
              <g>
                <path
                  d="m450.445 295.843s-4.111-88.929 39.482-145.544c3.377-4.386-1.594-10.28-6.487-7.692-23.112 12.225-60.111 47.735-69.497 150.554z"
                  fill="#a3e440"
                ></path>
              </g>
              <path
                d="m474.756 310.611s-3.56-77.012 33.585-127.46c3.321-4.511-1.925-10.323-6.757-7.49-20.636 12.1-51.293 44.739-59.31 132.563z"
                fill="#1ca56f"
              ></path>
            </g>
            <g>
              <g>
                <path
                  d="m235.882 268.118s-4.829-104.476 46.384-170.989c3.967-5.153-1.872-12.077-7.621-9.037-27.152 14.362-70.62 56.08-81.647 176.874z"
                  fill="#a3e440"
                ></path>
              </g>
              <path
                d="m264.443 285.468s-4.182-90.476 39.456-149.743c3.902-5.3-2.262-12.128-7.939-8.8-24.244 14.215-60.26 52.561-69.679 155.738z"
                fill="#1ca56f"
              ></path>
            </g>
            <g>
              <g>
                <path
                  d="m418.017 318.138c-4.006 0-7.394-3.093-7.697-7.152-2.288-30.701-11.142-110.625-43.862-189.531-1.635-3.942.235-8.462 4.177-10.097 3.945-1.634 8.462.236 10.097 4.177 33.579 80.977 42.655 162.857 44.999 194.302.317 4.256-2.875 7.962-7.131 8.28-.196.013-.391.021-.583.021z"
                  fill="#6c3328"
                ></path>
                <g>
                  <g>
                    <g>
                      <path
                        d="m404.656 232.911c-9.101.704-17.44-5.58-19.107-14.757-4.393-24.194-10.368-47.186-17.758-68.337-3.285-9.399 1.672-19.682 11.072-22.966 9.404-3.286 19.682 1.673 22.966 11.072 8.01 22.922 14.469 47.749 19.197 73.789 1.779 9.796-4.721 19.18-14.517 20.959-.621.112-1.238.192-1.853.24z"
                        fill="#ffd496"
                      ></path>
                    </g>
                  </g>
                  <g>
                    <g>
                      <path
                        d="m406.51 232.672c-.618.113-1.236.196-1.854.237-1.968.155-3.904-.021-5.749-.484 4.574-4.038 6.985-10.302 5.81-16.74-4.728-26.043-11.188-50.87-19.192-73.792-2.081-5.944-6.964-10.116-12.651-11.538 1.7-1.504 3.709-2.709 5.985-3.503 9.406-3.286 19.687 1.669 22.973 11.074 8.005 22.922 14.464 47.749 19.192 73.782 1.783 9.796-4.718 19.181-14.514 20.964z"
                        fill="#fcc16d"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
            <g>
              <path
                d="m512 319.101c0-33.229-114.615-60.166-256-60.166s-256 26.937-256 60.166c0 18.348 34.952 34.776 90.058 45.811 18.702 3.745 36.175 12.532 49.604 26.076 23.01 23.209 84.24 39.806 156.168 39.806 91.636 0 165.922-26.937 165.922-60.166 0-.049 0-.098 0-.148-.06-8.979 5.215-17.079 13.582-20.338 23.272-9.061 36.666-19.683 36.666-31.041z"
                fill="#64b9fc"
              ></path>
              <g fill="#31a7fb">
                <ellipse cx="252.623" cy="386.629" rx="38.682" ry="9.79"></ellipse>
                <ellipse cx="146.865" cy="328.411" rx="38.682" ry="9.79"></ellipse>
                <path d="m512 319.099c0 11.363-13.392 21.984-36.664 31.05-8.375 3.255-13.65 11.353-13.588 20.336v.144c0 31.287-65.839 56.99-150.015 59.884 61.172-10.786 103.708-34.697 103.708-62.46v-.165c-.062-10.24 5.563-19.47 14.474-23.189 24.817-10.333 39.095-22.437 39.095-35.387 0-12.063-12.393-23.406-34.171-33.254 47.605 10.92 77.161 26.166 77.161 43.041z"></path>
              </g>
            </g>
          </g>
        </g>
        {/* {lvl && lvl > 0 && ( */}
        <g>
          <g>
            <path
              d="m174.856 430.796h-52.256c-5.904 0-10.69-4.786-10.69-10.69v-61.407c0-20.334 16.484-36.818 36.818-36.818 20.334 0 36.818 16.484 36.818 36.818v61.407c0 5.904-4.786 10.69-10.69 10.69z"
              fill="#96756f"
            ></path>
            <path
              d="m185.546 358.701v61.409c0 5.903-4.79 10.683-10.693 10.683h-23.035c5.903 0 10.693-4.78 10.693-10.683v-61.409c0-10.168-4.121-19.367-10.786-26.033-4.018-4.028-8.973-7.129-14.515-8.942 3.616-1.195 7.489-1.844 11.517-1.844 10.168 0 19.378 4.121 26.033 10.786 6.665 6.665 10.786 15.865 10.786 26.033z"
              fill="#80635e"
            ></path>
          </g>
          <g>
            <path
              d="m136.808 397.185v22.922c0 5.903-4.78 10.693-10.683 10.693h-52.261c-5.903 0-10.693-4.79-10.693-10.693v-22.922c0-20.336 16.483-36.819 36.819-36.819 10.168 0 19.367 4.121 26.033 10.786 6.664 6.656 10.785 15.865 10.785 26.033z"
              fill="#d6a59d"
            ></path>
            <g fill="#ac8380">
              <path d="m136.808 397.188v22.922c0 5.903-4.78 10.683-10.683 10.683h-22.19c5.903 0 10.693-4.78 10.693-10.683v-22.922c0-10.168-4.121-19.367-10.786-26.033-4.121-4.131-9.23-7.283-14.938-9.086 3.503-1.102 7.222-1.7 11.085-1.7 10.168 0 19.367 4.121 26.033 10.786 6.665 6.666 10.786 15.865 10.786 26.033z"></path>
              <path d="m136.808 397.185v22.922c0 5.903-4.78 10.683-10.683 10.683h-6.985v-35.212c0-3.544-2.38-6.521-5.635-7.428-1.648-6.542-5.037-12.372-9.663-16.998-4.121-4.131-9.23-7.283-14.938-9.086 3.503-1.102 7.222-1.7 11.085-1.7 10.168 0 19.367 4.121 26.033 10.786 6.665 6.666 10.786 15.865 10.786 26.033z"></path>
            </g>
          </g>

          <g>
            <g>
              <path
                d="m148.895 371.537c-4.268 0-7.726-3.459-7.726-7.726v-7.576c0-4.267 3.459-7.726 7.726-7.726s7.726 3.459 7.726 7.726v7.576c.001 4.267-3.458 7.726-7.726 7.726z"
                fill="#d6a59d"
              ></path>
            </g>
          </g>
          <path
            d="m122.231 395.578v35.222h-15.453v-35.222c0-4.265 3.461-7.726 7.726-7.726 4.266 0 7.727 3.461 7.727 7.726z"
            fill="#80635e"
          ></path>
          <path
            d="m87.86 411.708c-4.268 0-7.726-3.459-7.726-7.726v-8.4c0-4.267 3.459-7.726 7.726-7.726s7.726 3.459 7.726 7.726v8.4c.001 4.267-3.458 7.726-7.726 7.726z"
            fill="#96756f"
          ></path>
        </g>
        {/* )} */}
        <path
          d="m100.495 311.081h-9.082c-4.268 0-7.726-3.459-7.726-7.726s3.459-7.726 7.726-7.726h9.082c4.268 0 7.726 3.459 7.726 7.726 0 4.266-3.458 7.726-7.726 7.726z"
          fill="#c4e1ff"
        ></path>
        <path
          d="m212.121 345.929h-9.083c-4.268 0-7.726-3.459-7.726-7.726s3.459-7.726 7.726-7.726h9.083c4.268 0 7.726 3.459 7.726 7.726s-3.459 7.726-7.726 7.726z"
          fill="#31a7fb"
        ></path>
        <path
          d="m313.473 403.818h-9.083c-4.268 0-7.726-3.459-7.726-7.726s3.459-7.726 7.726-7.726h9.083c4.268 0 7.726 3.459 7.726 7.726s-3.458 7.726-7.726 7.726z"
          fill="#31a7fb"
        ></path>
        <path
          d="m72.164 329.607h-9.082c-4.268 0-7.726-3.459-7.726-7.726s3.459-7.726 7.726-7.726h9.082c4.268 0 7.726 3.459 7.726 7.726s-3.458 7.726-7.726 7.726z"
          fill="#8dcafc"
        ></path>
        <path
          d="m335.804 352.593h-9.083c-4.268 0-7.726-3.459-7.726-7.726s3.459-7.726 7.726-7.726h9.083c4.268 0 7.726 3.459 7.726 7.726s-3.459 7.726-7.726 7.726z"
          fill="#c4e1ff"
        ></path>
        <path
          d="m216.662 314.855h-24.753c-4.268 0-7.726-3.459-7.726-7.726s3.459-7.726 7.726-7.726h24.753c4.268 0 7.726 3.459 7.726 7.726 0 4.266-3.458 7.726-7.726 7.726z"
          fill="#8dcafc"
        ></path>
        <path
          d="m372.037 388.525h-24.516c-4.268 0-7.726-3.459-7.726-7.726s3.459-7.726 7.726-7.726h24.516c4.268 0 7.726 3.459 7.726 7.726.001 4.267-3.458 7.726-7.726 7.726z"
          fill="#31a7fb"
        ></path>
      </g>
    </Svg>
  );
};

export default Pond;
