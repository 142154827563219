import React from "react";
import styled from "styled-components/macro";

const Green = styled.div`
  position: absolute;
  top: 35px;
  left: -20px;
  width: 380px;
  height: 380px;
  transform: scaleY(0.3);
  transform-origin: top center;
  background: #efd url("https://cdn.duocards.com/img/grass3.png");
  background-size: cover;
  border-radius: 50%;
  box-shadow: 1px 5px 7px #aaa4;
`;

type Props = {};

const Floor: React.FC<Props> = () => {
  return (
    <>
      <Green className="gardenFloor" />
      {/* <Path /> */}
      {/* <RoadImg src="https://cdn.duocards.com/img/road.png" /> */}
    </>
  );
};

export default Floor;
