import React, { FC, ReactNode } from "react";
import styled, { css } from "styled-components/macro";
import { IconButton, Dialog, DialogActions, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { devicePaddings } from "tools/device";

const { top, bottom } = devicePaddings();

const SDialog = styled(Dialog)<{ $wide?: boolean; $background?: boolean }>`
  ${({ $wide }) =>
    $wide &&
    css`
      .MuiDialog-paper {
        margin: ${top + 20}px 20px ${bottom + 20}px;
        max-height: calc(100% - ${40 + top + bottom}px);
        @media (max-width: 345px) {
          margin: ${top + 8}px 8px ${bottom + 8}px;
          max-height: calc(100% - ${16 + top + bottom}px);
        }
      }
    `}
  ${({ $background }) =>
    $background &&
    css`
      .MuiPaper-root {
        background: ${({ theme }) => theme.duo.color.lightGrey};
      }
    `}
`;
const SDialogContent = styled(DialogContent)`
  padding: 0;
`;
const SDialogActions = styled(DialogActions)`
  justify-content: center;
`;

const Wrap = styled.div<{ $noContentPadding?: boolean }>`
  text-align: center;
  ${(p) => (p.$noContentPadding ? "" : "padding: 0 15px 17px;")}
`;
const Header = styled.div<{ $consumeHeader: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
  ${(p) => (p.$consumeHeader ? `margin-bottom: -44px;` : "")}
`;
const Left = styled.div`
  width: 50px;
`;
const CloseWrap = styled.div<{ $closeButtonInCircle: boolean }>`
  position: relative;
  min-width: 40px;
  min-height: 40px;
  align-self: flex-start;
  ${(p) => (p.$closeButtonInCircle ? `top: 5px; right: 5px;` : "")}
`;
const FixedButton = styled(IconButton)<{ $closeButtonInCircle: boolean }>`
  position: fixed;
  z-index: 3;
  ${(p) =>
    p.$closeButtonInCircle &&
    `
    background: white;
    &:hover {
      background: ${p.theme.duo.color.textGrey};
    }
  `}
`;

type Props = {
  children?: ReactNode;
  open: boolean;
  title?: ReactNode;
  onClose?: (e: any) => void;
  className?: string;
  style?: React.CSSProperties;
  wide?: boolean;
  background?: boolean;
  consumeHeader?: boolean;
  noContentPadding?: boolean;
  closeButtonInCircle?: boolean;
  bottom?: ReactNode;
  fullWidth?: boolean;
};

const DialogWindow: FC<Props> = ({
  children,
  open,
  onClose,
  title = "",
  className,
  style,
  wide,
  background,
  consumeHeader,
  noContentPadding,
  closeButtonInCircle,
  bottom,
  fullWidth
}) => {
  return (
    <SDialog
      open={open}
      onClose={(e) => onClose && onClose(e)}
      className={className}
      style={style}
      fullWidth={fullWidth}
      $wide={wide}
      $background={background}
    >
      {(title || onClose) && (
        <Header $consumeHeader={!!consumeHeader}>
          <Left>&nbsp;</Left>
          <div>{title}</div>
          {onClose ? (
            <CloseWrap $closeButtonInCircle={!!closeButtonInCircle}>
              <FixedButton onClick={(e) => onClose(e)} $closeButtonInCircle={!!closeButtonInCircle}>
                <CloseIcon />
              </FixedButton>
            </CloseWrap>
          ) : (
            <div style={{ width: 50 }}></div>
          )}
        </Header>
      )}
      {title || bottom ? (
        <SDialogContent>
          <Wrap $noContentPadding={noContentPadding}>{children}</Wrap>
        </SDialogContent>
      ) : (
        <Wrap $noContentPadding={noContentPadding}>{children}</Wrap>
      )}

      {bottom && <SDialogActions>{bottom}</SDialogActions>}
    </SDialog>
  );
};

export default DialogWindow;
