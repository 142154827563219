import { isEqual } from "lodash";
import { useLibraryQuery } from "queries/sources/libraryQuery";
import { useViewerQuery } from "queries/viewerQuery";
import React from "react";
import { Trans } from "react-i18next";
import { useEffectOnce } from "react-use";
import LibraryRow from "root/library/library/LibraryRow";
import TutorialPointer from "root/tutorial/TutorialPointer";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";

const NOCARDSTT = "noCarsTT";

type Props = {};

const LearnNoCardsSources: React.FC<Props> = () => {
  const { lastVisited } = useLibraryQuery();
  const { hintWasSeen } = useViewerQuery();
  const rowRef = React.useRef<HTMLDivElement>(null);
  const [pointerPos, setPointerPos] = React.useState({ left: 0, top: 0 });

  useEffectOnce(() => {
    if (!rowRef?.current) return;
    const rect = rowRef.current.getBoundingClientRect();
    const newPos = rect && { left: rect.left + 70, top: rect.top + 135 };
    newPos && !isEqual(newPos, pointerPos) && setPointerPos(newPos);
  });

  return (
    <>
      <div ref={rowRef}>
        <LibraryRow sources={lastVisited} title={<Trans>Last opened</Trans>} isLatestVisited />
      </div>

      {!hintWasSeen(NOCARDSTT) && hintWasSeen(TUTORIALDONE) && <TutorialPointer pulsing style={pointerPos} />}
    </>
  );
};

export default LearnNoCardsSources;
