import React from "react";
import { Portal } from "@mui/base";
import { CSSTransition } from "react-transition-group";
import styled from "styled-components/macro";

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1100; // above tutorial, below Word
  background-color: #0002;
  transition: opacity 500ms;
  backdrop-filter: blur(6px);
  &.theoryWrap-enter,
  &.theoryWrap-appear {
    opacity: 0;
  }
  &.theoryWrap-enter-active,
  &.theoryWrap-appear-active {
    opacity: 1;
  }
  &.theoryWrap-exit {
    opacity: 1;
  }
  &.theoryWrap-exit-active {
    opacity: 0;
  }
`;

type Props = {
  children: React.ReactNode;
  open: boolean;
  onClick: (e: any) => void;
  style?: React.CSSProperties;
};

const TheoryWrapper: React.FC<Props> = ({ open, onClick, children, style }) => {
  return (
    <Portal>
      <CSSTransition in={open} timeout={500} classNames="theoryWrap" mountOnEnter unmountOnExit appear>
        <Wrap
          style={style}
          onClick={onClick}
          onTouchStart={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {children}
        </Wrap>
      </CSSTransition>
    </Portal>
  );
};

export default TheoryWrapper;
