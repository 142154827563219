/**
 * @generated SignedSource<<08557fffdefdfcf71dbac0662f7a6fdb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type usePreloadCardsMutation$variables = {
  cardIds: ReadonlyArray<string>;
};
export type usePreloadCardsMutation$data = {
  readonly cardsData: ReadonlyArray<{
    readonly front: string;
    readonly grammar: ReadonlyArray<{
      readonly grammar: string;
      readonly id: string;
      readonly lang: string;
    }> | null;
    readonly tts: {
      readonly id: string;
      readonly speech: string;
      readonly voiceId: string;
    } | null;
  }>;
};
export type usePreloadCardsMutation = {
  response: usePreloadCardsMutation$data;
  variables: usePreloadCardsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cardIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "cardIds",
    "variableName": "cardIds"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "front",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Tts",
  "kind": "LinkedField",
  "name": "tts",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "speech",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voiceId",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "GrammarReturnType",
  "kind": "LinkedField",
  "name": "grammar",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lang",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grammar",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePreloadCardsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Card",
        "kind": "LinkedField",
        "name": "cardsData",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePreloadCardsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Card",
        "kind": "LinkedField",
        "name": "cardsData",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ca0e35fcb44d2f61d648716080dfcdcc",
    "id": null,
    "metadata": {},
    "name": "usePreloadCardsMutation",
    "operationKind": "mutation",
    "text": "mutation usePreloadCardsMutation(\n  $cardIds: [String!]!\n) {\n  cardsData(cardIds: $cardIds) {\n    front\n    tts {\n      id\n      speech\n      voiceId\n    }\n    grammar {\n      id\n      lang\n      grammar\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9501ab9e30405fafec2b42b08d3d9c80";

export default node;
