import { MenuItem, Menu } from "@mui/material";
import React from "react";
import TranslateRoundedIcon from "@mui/icons-material/TranslateRounded";
import { Trans } from "react-i18next";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { selectJapAlphabeth, setJapAlphabeth } from "../../../components/ReduxProvider";
import { useDispatch, useSelector } from "react-redux";
import { xor } from "lodash";

type Props = { onClose: VoidFunction };

const JapaneseAlphabets: React.FC<Props> = ({ onClose }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const japAlphabet = useSelector(selectJapAlphabeth);
  const dispatch = useDispatch();

  return (
    <MenuItem onClick={(e) => setAnchorEl(e.currentTarget as any)}>
      <TranslateRoundedIcon /> &nbsp;<Trans>Alphabets</Trans>
      <Menu
        style={{ marginLeft: 130 }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={(e) => {
          setAnchorEl(null);
          onClose();
          (e as any).stopPropagation();
        }}
      >
        {["Hiragana", "Katakana", "Romaji"].map((alphabeth) => (
          <MenuItem
            key={alphabeth}
            onClick={() => dispatch(setJapAlphabeth(xor(japAlphabet, [alphabeth])))}
            onMouseDown={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
          >
            {japAlphabet?.includes(alphabeth) ? <VisibilityIcon /> : <VisibilityOffIcon />} &nbsp;
            {alphabeth}
          </MenuItem>
        ))}
      </Menu>
    </MenuItem>
  );
};

export default JapaneseAlphabets;
