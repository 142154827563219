import React from "react";
import styled from "styled-components/macro";
import LibraryRow from "./LibraryRow";
import { useLibraryQuery } from "../../../queries/sources/libraryQuery";
import Loader from "components/other/Loader";
import { Trans, useTranslation } from "react-i18next";
import { useViewerDecks, useViewerQuery } from "../../../queries/viewerQuery";
import LibLangPicker from "./LibLangPicker";
import Head from "../../../components/header/Head";
import useLangNative from "../../../hooks/useLangNative";
import { useLocation, useNavigate } from "react-router";
import { MYSOURCES, SOURCEFORM } from "./libraryUrls";
import { Kind } from "queries/sources/__generated__/sourceQuery.graphql";
import { useUpdateSearchParams } from "tools/url";
import { useSearchParams } from "react-router-dom";
import { useGetState } from "components/ReduxProvider";
import { TUTORIALDONE } from "root/tutorial/steps/Verdict";
import { useSeeHint } from "components/hint/Hint";
import { CONTENT_ID } from "components/header/constants";
import { useEvent } from "react-use";
import { EVENT } from "tools/events";
import { LIBRARY } from "../../RootRouter.config";
import { VIDEOFORM } from "root/library/library/libraryUrls";

export const VIDEOSID = "videosId";
export const ARTICLESID = "articlesId";

const Wrap = styled.div`
  padding: 10px 0;
  position: relative;
`;
const Seo = styled.div`
  margin: 6px 6px 12px;
  text-align: center;
  h1 {
    font-size: ${({ theme }) => theme.duo.fontSize.small};
    font-weight: bold;
    line-height: 1.4em;
    margin: 0 0 3px;
  }
  p {
    font-size: ${({ theme }) => theme.duo.fontSize.smallest};
    color: ${({ theme }) => theme.duo.color.textGrey};
    margin: 0;
    line-height: 1.3em;
  }
`;
const EmptySearch = styled.div`
  padding: 100px 40px;
  text-align: center;
  color: #555;
`;

type Props = {};

const LibraryRows: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { publicField, isLoading, mine, articles, sets, videos, lastVisited, courses, retry } = useLibraryQuery();
  const { viewer, hintWasSeen } = useViewerQuery();
  const { decks } = useViewerDecks();
  const libTopic = useGetState("libTopic");
  const langNative = useLangNative();
  const navigate = useNavigate();
  const location = useLocation();
  const lastScrollTop = React.useRef(0);
  const updateSearchParams = useUpdateSearchParams();
  const [searchParams] = useSearchParams();
  const seeHint = useSeeHint();

  useEvent(EVENT.refetchLibrary, () => {
    retry();
  });

  const queryParam = new URLSearchParams(location.search);
  const kind = queryParam.get("kind") as Kind | "mine" | "last" | "course" | null;
  const isSearching = searchParams.get(LIBRARY.searchParam);

  React.useEffect(() => {
    if (!isLoading && !sets?.data?.sources?.edges?.length && !hintWasSeen(TUTORIALDONE)) {
      console.info("Library empty == empty testing db -> exit tutorial and create some public sets first");
      seeHint(TUTORIALDONE);
    }
  });

  if (isLoading && !sets?.data?.sources?.edges?.length && !articles?.data?.sources?.edges?.length) {
    return <Loader />;
  }

  if (
    isSearching &&
    !sets?.data?.sources?.edges?.length &&
    !articles?.data?.sources?.edges?.length &&
    !videos?.data?.sources?.edges?.length &&
    !mine?.data?.sources?.edges?.length &&
    !courses?.data?.sources?.edges?.length
  ) {
    return <Trans parent={EmptySearch}>No results were found for this query.</Trans>;
  }

  const seoTitle = publicField?.librarySeo?.title;
  const seoDesc = publicField?.librarySeo?.description;

  const pushKind = (kind: Kind | "mine" | "last" | "course") => {
    if (searchParams.get(LIBRARY.kindParam) === kind) {
      updateSearchParams({ [LIBRARY.kindParam]: null }, { replace: true });
      setTimeout(() => {
        document.getElementById(CONTENT_ID)?.scrollTo({ top: lastScrollTop.current, behavior: "smooth" });
      }, 200);
    } else {
      updateSearchParams({ [LIBRARY.kindParam]: kind }, { replace: !!isSearching });
      lastScrollTop.current = document.getElementById(CONTENT_ID)?.scrollTop || 0;
      setTimeout(() => document.getElementById(CONTENT_ID)?.scrollTo({ top: 0 }), 100);
    }
  };

  return (
    <Wrap id="weirdWrap">
      <Head title={seoTitle} description={seoDesc} />

      {!viewer && seoTitle && (
        <Seo>
          <h1>{seoTitle}</h1>
          <p>{seoDesc}</p>
        </Seo>
      )}

      {!!lastVisited?.data?.sources?.edges?.length && !isSearching && (
        <LibraryRow
          expandOrCollapse={kind && kind === "last"}
          title={t("Last opened")}
          onClick={() => pushKind("last")}
          sources={lastVisited}
          isLatestVisited
        />
      )}

      {!!mine?.data?.sources?.edges?.length && (
        <LibraryRow
          expandOrCollapse={kind && kind === "mine"}
          title={t("My sources")}
          onClick={() => navigate(MYSOURCES.url())}
          sources={mine}
        />
      )}

      {!!courses?.data?.sources?.edges?.length && (
        <LibraryRow
          expandOrCollapse={kind && kind === "course"}
          title={t("Courses")}
          onClick={() => pushKind("course")}
          sources={courses}
        />
      )}

      <LibraryRow
        expandOrCollapse={kind && kind === "set"}
        title={t("Sets of cards")}
        right={!decks?.find((d) => d.front === libTopic) && <LibLangPicker />}
        disabled={libTopic === langNative}
        onClick={() => pushKind("set")}
        sources={sets}
        addNewLink={SOURCEFORM.url({ kind: "set" })}
      />

      {!!videos?.data?.sources?.edges?.length && (
        <LibraryRow
          expandOrCollapse={kind && kind === "video"}
          title={t("Videos")}
          onClick={() => pushKind("video")}
          sources={videos}
          id={VIDEOSID}
          addNewLink={VIDEOFORM.url()}
        />
      )}

      <LibraryRow
        expandOrCollapse={kind && kind === "article"}
        title={t("Articles")}
        onClick={() => pushKind("article")}
        sources={articles}
        id={ARTICLESID}
        addNewLink={SOURCEFORM.url({ kind: "article" })}
      />
    </Wrap>
  );
};

export default LibraryRows;
