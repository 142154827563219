import { useCallback, useState, useRef } from "react";
import { useEvent } from "react-use";
import { useUnmount } from "react-use";
import { useSttQuery } from "../queries/sttQuery";
import { dispatchEvent, EVENT } from "tools/events";
import { RecordingResultDetail } from "components/speech/AudioInput";
import { isMicrophoneSupported } from "tools/recorder";
import { RecordingOptions } from "components/speech/AudioInput";

const convertBlobToBase64 = (blob: Blob): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64Audio = reader.result?.toString().split(",")[1];
      resolve(base64Audio || null);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

type TranscriptionOptions = {
  languages?: string[];
  listeningOptions?: RecordingOptions;
};

const useSpeechToText = (onTranscribed: (text: string) => void, options?: TranscriptionOptions) => {
  const { isTranscribing: transcribing, transcribe } = useSttQuery();
  const [listening, setIsListening] = useState(false);
  const canceled = useRef(false);

  const handleRecordingDone = useCallback(
    async (e: CustomEvent<RecordingResultDetail>) => {
      if (!listening || canceled.current) return;
      const { audioBlob } = e.detail;
      const base64Audio = await convertBlobToBase64(audioBlob);
      if (base64Audio) {
        transcribe({
          audio: base64Audio,
          audioFormat: audioBlob.type,
          languages: options?.languages,
          onTranscribed: (text) => {
            onTranscribed(text);
          }
        });
      }
    },
    [transcribe, options, listening, canceled, onTranscribed]
  );

  useEvent(EVENT.recordingResult, handleRecordingDone);
  useEvent(EVENT.recordingStopped, () => {
    setIsListening(false);
  });

  const startListening = useCallback(() => {
    dispatchEvent(EVENT.startRecording, {
      ...options?.listeningOptions
    });
    canceled.current = false;
    setIsListening(true);
  }, [options?.listeningOptions]);

  const stopListening = useCallback(() => {
    dispatchEvent(EVENT.stopRecording);
  }, []);

  const cancelListening = useCallback(() => {
    canceled.current = true;
    dispatchEvent(EVENT.stopRecording);
  }, [canceled]);

  useUnmount(() => {
    if (listening) {
      cancelListening();
    }
  });

  return {
    transcribing,
    listening,
    startListening,
    stopListening,
    cancelListening,
    supported: isMicrophoneSupported()
  };
};

export default useSpeechToText;
