import { useEffect, useRef } from "react";

export const useDelayedAutoFocus = (delayMs: number) => {
  const emailRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => emailRef.current?.focus(), delayMs);
    return () => clearTimeout(timeout);
  }, [delayMs]);

  return emailRef;
};
