import { HeaderLayout } from "components/header/Header";
import Row from "components/other/Row";
import React from "react";
import { Route, Routes, useNavigate } from "react-router";
import AdminUsers from "root/admin/adminUsers/AdminUsers";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import styled from "styled-components/macro";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import AdminTranslations from "./translations/AdminTranslations";
import { useViewerQuery } from "queries/viewerQuery";
import CodeIcon from "@mui/icons-material/Code";
import { isApp } from "tools/device";
import BaseUrlSettings from "./switchAppSource/BaseUrlSettings";
import FeatureTests from "./featureTests/FeatureTests";
import BiotechIcon from "@mui/icons-material/Biotech";
import { ADMIN } from "../RootRouter.config";

const Rows = styled.div`
  svg {
    margin-right: 10px;
  }
`;

export const USERS = {
  relative: "users",
  url: () => ADMIN.url() + "/" + USERS.relative
};
export const TRANSLATIONS = {
  relative: "translations",
  url: () => ADMIN.url() + "/" + TRANSLATIONS.relative
};
export const SWITCH_APP_SOURCE = {
  relative: "switch-app-source",
  url: () => ADMIN.url() + "/" + SWITCH_APP_SOURCE.relative
};
export const FEATURE_TESTS = {
  relative: "featue-tests",
  url: () => ADMIN.url() + "/" + FEATURE_TESTS.relative
};

type Props = {};

const AdminRouter: React.FC<Props> = () => {
  const { viewer } = useViewerQuery();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!viewer?.admin) navigate("/");
  });

  return (
    <Routes>
      <Route path={USERS.relative} element={<AdminUsers />} />
      <Route path={TRANSLATIONS.relative} element={<AdminTranslations />} />
      <Route path={SWITCH_APP_SOURCE.relative} element={<BaseUrlSettings />} />
      <Route path={FEATURE_TESTS.relative} element={<FeatureTests />} />
      <Route
        index
        element={
          <HeaderLayout title="Admin">
            <Rows>
              <Row onClick={() => navigate(USERS.url())}>
                <PersonOutlineIcon /> User actions
              </Row>
              <Row onClick={() => navigate(TRANSLATIONS.url())}>
                <ViewHeadlineIcon /> Translations
              </Row>
              {isApp() && (
                <Row onClick={() => navigate(SWITCH_APP_SOURCE.url())}>
                  <CodeIcon />
                  Switch app source
                </Row>
              )}
              <Row onClick={() => navigate(FEATURE_TESTS.url())}>
                <BiotechIcon />
                Feature tests
              </Row>
            </Rows>
          </HeaderLayout>
        }
      />
    </Routes>
  );
};

export default AdminRouter;
