import React from "react";
import styled from "styled-components/macro";
import { Trans } from "react-i18next";
import Streak from "./Streak";
import { useViewerXps } from "queries/viewerQuery";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Grid } from "@mui/material";

const Wrap = styled.div``;
const Streaks = styled.div`
  display: flex;
  align-items: center;
  & > *  {
    margin: 0 4px;
  }
`;
const SArrowForwardIcon = styled(ArrowForwardIcon)`
  color: ${({ theme }) => theme.duo.color.gold};
  font-size: 16px;
`;

const StreakAchievement: React.FC = () => {
  const { xps } = useViewerXps();

  if (!xps) return null;

  return (
    <Wrap>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          {xps.currentStreak > 1 ? (
            <Trans>You increased your daily streak</Trans>
          ) : (
            <Trans>You started your daily streak</Trans>
          )}
        </Grid>
        <Grid item>
          <Streaks>
            {xps.currentStreak > 1 && (
              <>
                <Streak days={xps.currentStreak - 1} /> <SArrowForwardIcon />{" "}
              </>
            )}
            <Streak days={xps.currentStreak} />
          </Streaks>
        </Grid>
      </Grid>
    </Wrap>
  );
};

export default StreakAchievement;
