import Flames from "components/fire/Flames";
import React from "react";
import { FLAME_INDEXES, SUN_INDEX } from "../levelsHelper";
import Sun from "../sun/Sun";
import { Epoch } from "./NextItemDialog";

type Props = {
  itemIndex: number;
  component: React.ReactNode;
};

const RewardComponent: React.FC<Props> = ({ itemIndex, component }) => {
  return (
    <span className={FLAME_INDEXES.includes(itemIndex) ? undefined : "nofilter"}>
      {FLAME_INDEXES.includes(itemIndex) ? (
        <Flames
          className={
            itemIndex === FLAME_INDEXES[1] ? Epoch.gold : itemIndex === FLAME_INDEXES[2] ? Epoch.titan : undefined
          }
          strength={5}
          xps={null}
        />
      ) : SUN_INDEX === itemIndex ? (
        <Sun />
      ) : (
        component
      )}
    </span>
  );
};

export default RewardComponent;
