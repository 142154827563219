import { FetchPolicy, ReturnTypePagination, usePagination, useQuery } from "relay-hooks";
import { graphql } from "babel-plugin-relay/macro";
import { libraryQuery } from "./__generated__/libraryQuery.graphql";
import { libraryQuery_Public$data, libraryQuery_Public$key } from "./__generated__/libraryQuery_Public.graphql";
import useLangNative from "../../hooks/useLangNative";
import { useSearchParams } from "react-router-dom";
import { useGetState } from "components/ReduxProvider";
import { useViewerQuery } from "../viewerQuery";
import { MY_SORT_FLAG } from "../users/userFlagsModel";
import { SourcesSortEnum } from "../../sharedJs__generated/constants";
import { LIBRARY } from "../../root/RootRouter.config";
import { recommendedSourcesPaginatedQuery } from "components/RecommendedSources/__generated__/recommendedSourcesPaginatedQuery.graphql";
import {
  recommendedSourcesPaginatedQuery_Sources$data,
  recommendedSourcesPaginatedQuery_Sources$key
} from "components/RecommendedSources/__generated__/recommendedSourcesPaginatedQuery_Sources.graphql";

export type LibrarySources =
  | Partial<ReturnTypePagination<libraryQuery, libraryQuery_Public$key, libraryQuery_Public$data>>
  | ReturnTypePagination<
      recommendedSourcesPaginatedQuery,
      recommendedSourcesPaginatedQuery_Sources$key,
      recommendedSourcesPaginatedQuery_Sources$data
    >;

export const sourcesFrg = graphql`
  fragment libraryQuery_Public on Public
  @argumentDefinitions(
    count: { type: "Int!" }
    cursor: { type: "String" }
    kind: { type: "Kind" }
    course: { type: "Boolean" }
    lang: { type: "String" }
    langNative: { type: "String" }
    mine: { type: "Boolean" }
    lastVisited: { type: "Boolean" }
    search: { type: "String" }
    sort: { type: "String" }
  )
  @refetchable(queryName: "LibraryRefetchQuery") {
    sources(
      first: $count
      after: $cursor
      kind: $kind
      course: $course
      lang: $lang
      mine: $mine
      langNative: $langNative
      lastVisited: $lastVisited
      search: $search
      sort: $sort
    ) @connection(key: "Library_sources") {
      edges {
        node {
          id
          uri
          name(langNative: $langNative)
          kind
          course
          private
          mine
          difficulty
          source
          videoDuration
          textLength
          top
          avgRating
          image {
            url
            authorName
            authorApiName
          }
          rating {
            rating
            continueFrom
            picked
            dismissed
            finished
          }
          counts {
            total
            theories
          }
          contentTest {
            active
            admin {
              runningSince
              revenueOn
              revenueOff
              percent
              accuracy
            }
          }
        }
      }
    }
  }
`;

const query = graphql`
  query libraryQuery(
    $lang: String!
    $count: Int!
    $cursor: String
    $langNative: String!
    $search: String
    $sort: String
  ) {
    public {
      librarySeo(lang: $lang, langNative: $langNative) {
        title
        description
      }
    }
    lastVisited: public {
      ...libraryQuery_Public
        @arguments(count: 16, lang: $lang, langNative: $langNative, lastVisited: true, search: $search, sort: $sort)
    }
    mine: public {
      ...libraryQuery_Public
        @arguments(
          count: $count
          cursor: $cursor
          lang: $lang
          langNative: $langNative
          mine: true
          search: $search
          sort: $sort
        )
    }
    sets: public {
      ...libraryQuery_Public
        @arguments(
          count: $count
          cursor: $cursor
          kind: set
          lang: $lang
          langNative: $langNative
          search: $search
          sort: $sort
        )
    }
    courses: public {
      ...libraryQuery_Public
        @arguments(
          count: $count
          cursor: $cursor
          kind: set
          course: true
          lang: $lang
          langNative: $langNative
          search: $search
          sort: $sort
        )
    }
    articles: public {
      ...libraryQuery_Public
        @arguments(count: $count, cursor: $cursor, kind: article, lang: $lang, search: $search, sort: $sort)
    }
    videos: public {
      ...libraryQuery_Public
        @arguments(count: $count, cursor: $cursor, kind: video, lang: $lang, search: $search, sort: $sort)
    }
  }
`;

export const sourcesOnFirstPage = 10;
export const sourcesOnPage = 15;

export const useLibraryQuery = (fetchPolicy?: FetchPolicy) => {
  const langNative = useLangNative();
  const { getFlag } = useViewerQuery();
  const [searchParams] = useSearchParams();

  const lang = useGetState("libTopic") || "";
  const search = searchParams.get(LIBRARY.searchParam);
  const sort = getFlag(MY_SORT_FLAG)?.value ?? SourcesSortEnum.popularity;

  const { data, isLoading, retry } = useQuery<libraryQuery>(
    query,
    { lang, langNative, count: sourcesOnFirstPage, search, sort },
    { skip: !lang, fetchPolicy: fetchPolicy || "store-and-network" }
  );

  const mine = usePagination<libraryQuery, libraryQuery_Public$key>(sourcesFrg, data?.mine as libraryQuery_Public$key);
  const articles = usePagination<libraryQuery, libraryQuery_Public$key>(
    sourcesFrg,
    data?.articles as libraryQuery_Public$key
  );
  const sets = usePagination<libraryQuery, libraryQuery_Public$key>(sourcesFrg, data?.sets as libraryQuery_Public$key);
  const courses = usePagination<libraryQuery, libraryQuery_Public$key>(
    sourcesFrg,
    data?.courses as libraryQuery_Public$key
  );
  const videos = usePagination<libraryQuery, libraryQuery_Public$key>(
    sourcesFrg,
    data?.videos as libraryQuery_Public$key
  );
  const lastVisited = usePagination<libraryQuery, libraryQuery_Public$key>(
    sourcesFrg,
    data?.lastVisited as libraryQuery_Public$key
  );

  return { publicField: data?.public, mine, isLoading, articles, sets, videos, lastVisited, courses, retry };
};
