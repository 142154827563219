import React from "react";
import styled, { css } from "styled-components/macro";
import { HeaderLayout } from "../../../../components/header/Header";
import { useNavigate } from "react-router";
import { Backdrop, Button } from "@mui/material";
import SCardForm from "./SCardForm";
import { flagLabel } from "../../../../tools/langs";
import SCardItem from "./SCardItem";
import Loader from "components/other/Loader";
import LangMenu from "../../../../components/LangMenu/LangMenu";
import { useMutation, useQuery } from "relay-hooks";
import { Trans, useTranslation } from "react-i18next";
import { useViewerQuery } from "../../../../queries/viewerQuery";
import CodeIcon from "@mui/icons-material/Code";
import { sourceEditQL } from "../sourceEditQuery";
import { sourceEditQuery } from "../__generated__/sourceEditQuery.graphql";
import LangButton from "../../../../components/LangMenu/LangButton";
import { FRONT_ID } from "../../../main/card/CardForm";
import SourceCardsLangs from "./SourceCardsLangs";
import { sortSCardMutation } from "../../../../queries/sources/__generated__/sortSCardMutation.graphql";
import { SortSCardQL } from "../../../../queries/sources/sortSCard";
import { deleteSCardQL } from "../../../../queries/sources/deleteSCard";
import { deleteSCardMutation } from "../../../../queries/sources/__generated__/deleteSCardMutation.graphql";
import { SOURCEFORM } from "../../library/libraryUrls";
import { useSearchParams } from "react-router-dom";

const exportToJson = (objectData: any) => {
  let filename = "export.json";
  let contentType = "application/json;charset=utf-8;";
  if ((window.navigator as any) && (navigator as any).msSaveOrOpenBlob) {
    var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(objectData)))], {
      type: contentType
    });
    (navigator as any).msSaveOrOpenBlob(blob, filename);
  } else {
    var a = document.createElement("a");
    a.download = filename;
    a.href = "data:" + contentType + "," + encodeURIComponent(JSON.stringify(objectData));
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

const Wrap = styled.div`
  margin: 20px;
  text-align: center;
  line-height: 2em;
`;
const SortLine = styled.div`
  position: absolute;
  margin-top: -20px;
  width: 100%;
  height: 40px;
  border-radius: 40px;
  background: ${({ theme }) => theme.duo.color.lightBlue}44;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  &:after {
    content: " ";
    height: 3px;
    width: 90%;
    display: block;
    background: ${({ theme }) => theme.duo.color.primary}77;
  }
`;
const CancelSort = styled(Button)`
  position: absolute;
  color: ${({ theme }) => theme.duo.color.red};
  background: white;
  right: 50px;
  margin-top: -53px;
  z-index: 2;
`;
const SSCardItem = styled(SCardItem)<{ sorting: boolean }>`
  ${({ sorting, theme }) =>
    sorting &&
    css`
      position: relative;
      z-index: 1;
      box-shadow: 0 0 10px ${theme.duo.color.lightBlue};
    `}
`;
const In = styled.div`
  position: relative;
`;

type Props = {
  langNative: string;
  nativeCreate: VoidFunction;
  setLangNative: (l: string) => void;
};

const SourceCards: React.FC<Props> = ({ langNative, nativeCreate, setLangNative }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { viewer } = useViewerQuery();
  const [sortId, setSortId] = React.useState<string>();
  const [sortSCard, { loading }] = useMutation<sortSCardMutation>(SortSCardQL);
  const [deleteSCard, { loading: loadingD }] = useMutation<deleteSCardMutation>(deleteSCardQL);
  const [searchParams] = useSearchParams();
  const id = searchParams.get(SOURCEFORM.idParam);

  const { data } = useQuery<sourceEditQuery>(
    sourceEditQL,
    { id: id || "", backLang: langNative },
    {
      skip: !id,
      fetchPolicy: "store-and-network" // because changing langs
    }
  );
  const source = data?.node;

  const pickedSort = source?.sCards?.length && sortId ? source.sCards.findIndex((c) => c.id === sortId) : null;

  const downloadJson = () =>
    exportToJson(
      source?.sCards?.reduce((json, c) => ({ ...json, ...(c.hint ? { [c.hint]: c.back?.value } : {}) }), {})
    );

  const handleDone = () => {
    if (!source?.sCards?.length) {
      return alert(t("Add cards before continuing"));
    }
    const phrase = (document.getElementById(FRONT_ID) as any)?.value;
    const q = t("Sure you want to continue without saving the last card") + " '" + phrase + "'?";
    if (phrase && !window.confirm(q)) return false;

    navigate(SOURCEFORM.url({ id: source.id }), { replace: true });
    return true;
  };

  const sort = (sort: number) => {
    sortId && sortSCard({ variables: { id: sortId, sort, backLang: langNative } });
    setSortId(undefined);
  };

  if (!source) return <Loader />;

  return (
    <HeaderLayout
      title={source?.name}
      right={
        <Button onClick={handleDone} color="primary">
          {t("Done")}
        </Button>
      }
      left={
        <SourceCardsLangs
          source={source}
          langNative={langNative}
          onNativeCreate={() => {
            if (handleDone()) {
              nativeCreate();
            }
          }}
          setLangNative={setLangNative}
        />
      }
      onBack={handleDone}
    >
      {source?.multiLang && (
        <Wrap>
          {t("Language")}: {flagLabel(source?.lang)}
          <br />
          {t("Student's known language")}:{" "}
          <LangMenu title={<LangButton small lang={langNative} />} onSelect={(lang) => setLangNative(lang)} />
          {Number(viewer?.admin) >= 2 && (
            <Button onClick={downloadJson}>
              <CodeIcon /> &nbsp;JSON
            </Button>
          )}
        </Wrap>
      )}
      <In>
        {sortId && pickedSort !== null && pickedSort > -1 && source?.sCards && sortId !== source.sCards![0].id && (
          <SortLine onClick={() => sort(0)} />
        )}
        {source?.sCards &&
          source.sCards.map((sCard, i) => (
            <React.Fragment key={sCard.id}>
              <SSCardItem
                sorting={sCard.id === sortId}
                sCard={sCard}
                source={source}
                backLang={langNative}
                onSort={(id) => setSortId(id)}
                onDelete={(id) => deleteSCard({ variables: { id, backLang: langNative } })}
              />
              {sortId && sCard.id === sortId && (
                <Trans parent={CancelSort} onClick={() => setSortId(undefined)}>
                  Cancel
                </Trans>
              )}
              {sortId &&
                pickedSort !== null &&
                pickedSort > -1 &&
                sortId !== sCard.id &&
                (!source.sCards![i + 1] || sortId !== source.sCards![i + 1].id) && (
                  <SortLine onClick={() => sort(i + (pickedSort < i ? 0 : 1))} />
                )}
            </React.Fragment>
          ))}
      </In>
      {source.sCards && source.sCards.length >= 1200 && !viewer?.admin ? (
        <p style={{ textAlign: "center", padding: "0 15px 30px" }}>
          <Trans>You have reached the maximum of 1200 words</Trans>
        </p>
      ) : (
        source?.id && <SCardForm source={source} backLang={langNative} />
      )}
      <Backdrop open={loading || loadingD} style={{ zIndex: 1 }}>
        <Loader />
      </Backdrop>
    </HeaderLayout>
  );
};

export default SourceCards;
