import React from "react";
import { useLocation, useNavigate } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { MenuItem, Select } from "@mui/material";
import { HelpCenterQuestions } from "root/profile/HelpCenter/helpCenterDefinitions";
import Toggler from "components/other/Toggler";
import Button from "@mui/material/Button";
import DialogFullWindow from "components/other/DialogFullWindow";
import { useDeviceLang } from "hooks/deviceHooks";

import { FEEDBACK } from "../ProfileRouter.config";

const FAQ_HASH = "#faq";

const SSelect = styled(Select)`
  width: 100%;
  margin: 0 0 25px 0;
  background-color: white;
  display: block;

  .MuiSelect-select {
    white-space: normal;
    box-sizing: border-box;
  }
`;
const SMenuItem = styled(MenuItem)`
  white-space: normal;
`;
const Quiet = styled.span`
  color: ${(p) => p.theme.duo.color.textGrey};
`;
const SButton = styled(Button)`
  display: block;
  margin: 0 auto 25px;
`;
const SIframe = styled.iframe`
  height: 100%;
`;

type Props = { select: HelpCenterQuestions };

const HelpCenterSelect: React.FC<Props> = React.memo(({ select }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);
  const selectedQuestion = select.questions[selectedIndex];
  const [showFeedbackButton, setShowFeedbackButton] = React.useState(false);
  const { appLang } = useDeviceLang();
  const isLastLevel = !!selectedQuestion?.anchor;

  const showFAQ = location.hash === FAQ_HASH && isLastLevel;
  const faqLangs: { lang: string; path: string }[] = [
    { lang: "en", path: "faqs" },
    { lang: "es", path: "preguntas-frecuentes" },
    { lang: "de", path: "faqs" },
    { lang: "it", path: "domande-frequenti" },
    { lang: "cs", path: "nejcastejsi-dotazy" },
    { lang: "ko", path: "faq" },
    { lang: "pt-br", path: "faqs" },
    { lang: "sk", path: "casto-kladene-otazky" },
    { lang: "fr", path: "faqs" },
    { lang: "ja", path: "よくあるご質問" },
    { lang: "ru", path: "часто-задаваемые-вопросы" },
    { lang: "hr", path: "faq" },
    { lang: "sr", path: "факс" }
  ];
  const faqLang = faqLangs.find((obj) => obj.lang.startsWith(appLang)) || faqLangs[0];
  const faqSource = `https://duocards.com/${faqLang.lang}/${faqLang.path}/?view=just-content#${selectedQuestion?.anchor}`;

  // if the parent question changes, let's unselect this select
  React.useEffect(() => {
    setSelectedIndex(-1);
    setShowFeedbackButton(false);
  }, [select]);

  const handleShowFAQ = () => {
    navigate({ hash: FAQ_HASH });
  };

  const handleCloseFAQ = () => {
    navigate(-1);
    setShowFeedbackButton(true);
  };

  const handleOpenFeedback = () => {
    navigate(FEEDBACK.url(), {
      state: {
        relatedTo: {
          text: t(selectedQuestion.question)
        }
      }
    });
  };

  return (
    <>
      <SSelect
        onChange={(e) => {
          setSelectedIndex(Number(e.target.value));
          setShowFeedbackButton(false);
        }}
        value={selectedIndex}
      >
        <MenuItem key={-1} value={-1}>
          <Trans parent={Quiet}>Please select</Trans>
        </MenuItem>
        {select.questions.map((questionItem, index) => (
          <SMenuItem key={index} value={index}>
            <Trans>{questionItem.question}</Trans>
          </SMenuItem>
        ))}
      </SSelect>

      {selectedQuestion?.nextLevel && (
        <Toggler show={true} delay={200}>
          <HelpCenterSelect select={selectedQuestion.nextLevel} />
        </Toggler>
      )}

      {isLastLevel && (
        <SButton color="primary" variant="contained" onClick={handleShowFAQ}>
          <Trans>Show answer</Trans>
        </SButton>
      )}
      {(showFeedbackButton || selectedQuestion?.autoShowFeedback) && (
        <SButton variant={selectedQuestion?.autoShowFeedback ? "contained" : "text"} onClick={handleOpenFeedback}>
          <Trans>Ask the user support</Trans>
        </SButton>
      )}

      {showFAQ && (
        <DialogFullWindow open={true} onClose={handleCloseFAQ}>
          <SIframe src={faqSource}></SIframe>
        </DialogFullWindow>
      )}
    </>
  );
});

export default HelpCenterSelect;
