import { Trans, useTranslation } from "react-i18next";
import { Button, Paper, Stack } from "@mui/material";
import React from "react";
import styled from "styled-components/macro";
import FormatNumber from "../../formatters/FormatNumber";
import { MAX_FREE_AI_MESSAGES } from "../../../sharedJs__generated/constants";

const SFormatNumber = styled(FormatNumber)`
  font-weight: bold;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Wrap = styled(Paper)`
  margin: 10px 10px 20px 0;
  padding: 16px;
  background-color: #fff3f3;
`;

const Centered = styled.div`
  text-align: center;
`;

type Props = {
  onShowSubscriptionOptions: () => void;
};

export function AiMessagesDepletedWarning({ onShowSubscriptionOptions }: Props) {
  // a workaround for a simple component that does not require re-render in order to show possibly missing translations
  useTranslation();

  return (
    <Wrap>
      <Stack direction={"column"} spacing={1.5}>
        <Stack direction={"column"} spacing={1}>
          <Trans parent={Centered}>Oh, no! Your AI messages are depleted!</Trans>
          <Trans parent={Centered}>
            Only <SFormatNumber value={MAX_FREE_AI_MESSAGES} /> <Bold>messages</Bold> per week can be used in the free
            version of the chat. This limit <Bold>resets every Monday</Bold>.
          </Trans>
          <Trans parent={Centered}>
            Use AI chat without restrictions, <Bold>upgrade</Bold> to a <Bold>Premium account</Bold> and get many other
            benefits.
          </Trans>
        </Stack>

        <Centered>
          <Button variant="contained" color="primary" size="small" onClick={onShowSubscriptionOptions}>
            <Trans>Subscribe</Trans>
          </Button>
        </Centered>
      </Stack>
    </Wrap>
  );
}
