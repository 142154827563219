import React from "react";
import styled from "styled-components/macro";

const Svg = styled.svg`
  position: relative;
  display: block;
  width: 30px;
  height: 30px;
  margin: -2px 2px 8px 4px;
  opacity: 0.5;
`;

const FlameDead: React.FC<{}> = () => (
  <Svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 46" width="33" height="46">
    <path d="m0.4 42c0.2-0.9 6.6-3.4 14.4-2.8 7.8 0.6 17.6 2.5 17.6 2.9-0.1 0.8-9.7 3.5-15 3.6-4.9 0.2-17.5-2-17-3.7z" />
    <path d="m15.1-1.4c-2.7 14.4 8.3 15.3 7 22.5-0.5 3.1-4.5 5-7.2 9.7 5.9-17.8-4.7-13-3.9-19.5 0.5-3.8 3.3-3.8 4.1-12.7z" />
  </Svg>
);

export default FlameDead;
