import React from "react";
import { useCardsQuery, cardsOnPage, TCard } from "../../../../queries/cards/cardsQuery";
import Row from "../../../../components/other/Row";
import Loader from "components/other/Loader";
import styled from "styled-components/macro";
import { useViewerDecks } from "../../../../queries/viewerQuery";
import { useScroll } from "react-use";
import CardListIn from "./CardListIn";
import { ConnectionHandler } from "react-relay";
import { useMutation } from "relay-hooks";
import { deleteCardQL } from "../../../../queries/cards/deleteCard";
import { deleteCardMutation } from "../../../../queries/cards/__generated__/deleteCardMutation.graphql";
import { CONTENT_ID } from "components/header/constants";
import { resetCardMutation } from "../../../../queries/cards/__generated__/resetCardMutation.graphql";
import { resetCardQL } from "../../../../queries/cards/resetCard";
import { CardState } from "../../../../queries/cards/__generated__/CardsRefetchQuery.graphql";
import { MAXKNOWNCOUNT } from "../../../../sharedJs__generated/constants";

const Wrap = styled.div``;
const SRow = styled(Row)`
  position: relative;
  height: 100px;
`;
const NoCards = styled.div`
  padding: 10px 0 30px;
  text-align: center;
  font-size: 30px;
`;

export const scrollToListEnd = () => {
  setTimeout(() => {
    const el = document.getElementById("scrollToMe");
    if (el !== null && el.scrollIntoView) el.scrollIntoView({ behavior: "smooth" });
  });
};

type Props = {
  searchText: string;
  cardState: CardState | null;
};

const CardListItems: React.FC<Props> = ({ searchText, cardState }) => {
  const {
    data: cards,
    hasNext,
    loadNext,
    isLoading,
    isLoadingNext,
    refetch
  } = useCardsQuery({
    search: searchText,
    cardState: cardState
  });
  const { deck } = useViewerDecks();
  const [deleteCard] = useMutation<deleteCardMutation>(deleteCardQL);
  const [resetCard] = useMutation<resetCardMutation>(resetCardQL);
  const [hidden, setHidden] = React.useState(undefined);

  const wrapRef: React.RefObject<HTMLDivElement> = React.useRef(null);
  const scrollRef = { current: document.getElementById(CONTENT_ID) };
  const scroll = useScroll(scrollRef);

  React.useEffect(() => {
    const scrolledDown = wrapRef.current && wrapRef.current?.offsetHeight - scroll.y < 1000;
    if (scrolledDown && hasNext && !isLoadingNext) {
      loadNext(cardsOnPage);
    }
  }, [hasNext, isLoadingNext, loadNext, scroll, wrapRef]);

  const filtered = React.useMemo(
    () =>
      cards?.cards.edges?.filter(
        (c) =>
          c?.node?.front?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
          c?.node?.back?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
          c?.node?.hint?.toLowerCase()?.includes(searchText?.toLowerCase())
      ),
    [cards, searchText]
  );

  const handleDeleteCard = React.useCallback(
    (card: TCard) => {
      const updater = (store) => {
        const DeckRec = store.get(deck?.id || "");
        if (DeckRec) {
          const widgets = ConnectionHandler.getConnection(DeckRec, "Cards_cards", {
            search: searchText,
            cardState: cardState
          });
          widgets && ConnectionHandler.deleteNode(widgets, card?.id as string);
        }

        const stats = DeckRec.getLinkedRecord("stats");
        if (!card.waiting && Number(card.knownCount) < MAXKNOWNCOUNT && Number(deck?.stats.unknown) > 0) {
          stats?.setValue(Number(deck?.stats.unknown) - 1, "unknown");
        }
        if (card.waiting && Number(card.knownCount) < 5 && Number(deck?.stats.known) > 0) {
          stats?.setValue(Number(deck?.stats.known) - 1, "known");
        }
        if (
          ((card.waiting && Number(card.knownCount) >= 5) || Number(card.knownCount === MAXKNOWNCOUNT)) &&
          Number(deck?.stats.completed) > 0
        ) {
          stats?.setValue(Number(deck?.stats.completed) - 1, "completed");
        }
      };

      deleteCard({
        variables: { id: card?.id as string },
        onCompleted: () =>
          refetch({
            deckId: deck?.id || "",
            count: Number(cards.cards.pageInfo.endCursor) + 1
          }),
        optimisticUpdater: updater,
        updater
      });
    },
    [cards?.cards?.pageInfo, deck, deleteCard, refetch, cardState, searchText]
  );

  const handleResetCard = React.useCallback(
    (card) => {
      setHidden(card?.id);
      resetCard({
        variables: { id: card?.id as string },
        onCompleted: () => {
          refetch(
            {
              deckId: deck?.id || "",
              count: Number(cards.cards.pageInfo.endCursor) + 1
            },
            {
              onComplete: () => {
                setHidden(undefined);
              }
            }
          );
        }
      });
    },
    [cards?.cards?.pageInfo, deck?.id, resetCard, refetch]
  );

  // isLoading doesn't work for some reason
  const isLoadingFixed = isLoading || cards === null;

  if (!isLoadingFixed && (!filtered || !filtered.length)) {
    return <NoCards>{"🤷"}</NoCards>;
  }
  if (!deck) return null;

  return (
    <Wrap ref={wrapRef}>
      {isLoadingFixed && <SRow children={<Loader />} />}

      {filtered && (
        <CardListIn cards={filtered} hidden={hidden} onDeleteCard={handleDeleteCard} onResetCard={handleResetCard} />
      )}

      {isLoadingNext && <SRow children={<Loader />} />}
    </Wrap>
  );
};

export default CardListItems;
